import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../images/logoW.png";
import AuthFooter from "../components/Footer";
import LatestFeaturedPost from "../components/AuthComponent/LatestFeaturedPost/LatestFeaturedPost";
import { useDispatch } from "react-redux";
import { getFeaturedPostList } from "../redux/states/FeaturedPost/thunk";
import { useTranslation } from "react-i18next";

const AuthLayout = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="mainAuthCont p-0">
        <Container fluid className="flex-1 h-100 min-full-height ">
          <Row className="min-full-height  border-bottom">
            <Col
              lg="6"
              md="12"
              sm="12"
              className="d-flex flex-column justify-content-center align-items-center bg-black py-lg-4 h-100"
            >
              <div className="AuthInner text-center py-lg-5">
                <img src={Logo} alt="" className="img-fluid mb-lg-3 logo" />
                <h3 className="text-medium text-light d-lg-block d-none">
                  {t("Sign up to support your")} <br /> {t("favorite creators")}
                </h3>
              </div>
            </Col>
            <Col
              lg="6"
              md="12"
              sm="12"
              className="d-flex justify-content-center min-height-full authCont align-items-start"
            >
              {children}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {/* <LatestFeaturedPost /> */}
              <AuthFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AuthLayout;
