import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PostComponent from "../../common/Posts/PostComponent";
import { Link, useNavigate } from "react-router-dom";
import ArtistSection from "../../common/ArtistSection/ArtistSection";
import { useTranslation } from "react-i18next";
import {
  activeArtistList,
  getPostList,
  searchArtist,
  subscribedArtistList,
} from "../../../redux/actions";
import Pintohome from "../../common/Modal/Pintohome";
import Logintosubscribe from "../../common/Modal/Logintosubscribe";
import InfiniteScroll from "react-infinite-scroll-component";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { toast } from "react-toastify";
import VerifyEmail from "../../common/VerifyEmail/VerifyEmail";
import { BsSearch } from "react-icons/bs";
import { debounce } from "lodash";
import { FaArrowLeft } from "react-icons/fa";

const HomeUser = (props) => {
  const [showSendTip, setShowSendTip] = useState(false);
  const [pintohome, setpintohome] = useState(false);
  const [loginsub, setloginsub] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showProfileBanners, setShowProfileBanners] = useState(true);
  const [newList, setNewList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCounts, setTotalCount] = useState(10);
  const [showLockedPost, setShowLockedPost] = useState(true);
  const { pinnedCollection } = useSelector((s) => s.collection);
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedArtistId, setSelectedArtistId] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [entertainerSerach, setEntertainerSerach] = useState("");
  const { searchArtistList: userList } = useSelector((s) => s.artist);
  const { subscribedArtistList: followingList } = useSelector(
    (s) => s.collection
  );
  const [loading, setLoading] = useState(false);

  const onSearchArtist = useCallback(
    debounce((filter) => {
      dispatch(searchArtist(filter));
    }, 1000),
    []
  );
  useEffect(() => {
    const data = {
      search: entertainerSerach,
      order: -1,
      orderBy: "userName",
      role: "ARTIST",
    };
    onSearchArtist(data);
  }, [entertainerSerach]);
  const getActiveArtistList = () => {
    const data = {
      limit: 1000,
      status: "active",
      page: 1,
    };
    dispatch(activeArtistList(data));
  };
  useEffect(() => {
    getActiveArtistList();
  }, []);

  const fetchMorePost = () => {
    getPostLists(page + 1);
  };

  const getPostLists = (pageNo = page, lockedPostStatus = showLockedPost) => {
    setLoading(true);
    setPage(pageNo);
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      postType: ["quiz", "normal", "other", "poll"],
      filter: "following",
      type: lockedPostStatus ? "" : "free",
      artistIds: selectedArtistId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        if (pageNo === 1) {
          setNewList(data);
        } else {
          setNewList((prev) => [...prev, ...data]);
        }
        setLoading(false);
        setTotalCount(res?.totalcount);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPostLists();
  }, [selectedArtistId]);

  const handleLockedPost = () => {
    setShowLockedPost(!showLockedPost);
    getPostLists(1, !showLockedPost);
  };

  const handleTabSelection = (tab, dta) => {
    if (tab === "All") {
      setSelectedArtistId([]);
    } else {
      const allEntertainerId = dta?.artistId;
      setSelectedArtistId(allEntertainerId);
    }
    setSelectedTab(tab);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [window]);

  useEffect(() => {
    const query = {
      limit: 1000,
      page: 1,
    };
    dispatch(subscribedArtistList(query));
  }, []);

  const checkSubscribe = (userId) => {
    return followingList?.some((val) => val.followerId === userId) || false;
  };

  return (
    <>
      <Logintosubscribe show={loginsub} onhide={() => setloginsub(false)} />
      <Pintohome show={pintohome} onhide={() => setpintohome(false)} />
      <Row className="  h-100 align-items-start justify-content-between ">
        <Col lg="7" md="12" sm="12" className="p-0">
          <div
            className="storyTop rounded pt-4 pb-2 px-2 border "
            id="scrollableDiv"
          >
            {showSearch ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <FaArrowLeft
                    onClick={() => setShowSearch(!showSearch)}
                    style={{ fontSize: "24px" }}
                  />
                  <div
                    style={{ width: "93%" }}
                    className="searchBar loginInput align-items-center justify-content-between"
                  >
                    {/* <Searchpost setEntertainerSerach={setEntertainerSerach} /> */}
                    <input
                      style={{ outline: 0 }}
                      type="text"
                      placeholder={t("Search user")}
                      className="border-0 "
                      value={entertainerSerach}
                      onChange={(e) => setEntertainerSerach(e.target.value)}
                    />
                  </div>
                </div>
                {userList?.length ? (
                  <div
                    className="suggestion-list  w-100 bg-white p-4 rounded"
                    style={{
                      maxHeight: "250px",
                      overflowY: "scroll",
                      left: 10,
                    }}
                  >
                    {userList.map((user) => (
                      <div
                        key={user._id}
                        className="user-container"
                        onClick={() => {
                          navigate(`/${user.userName}`);
                        }}
                      >
                        {/* <img
                          src={user.profileImage}
                          alt="Profile"
                          className="profile-image"
                        /> */}
                        {user?.profileImage ? (
                          <div className="user_img">
                            <img
                              src={user?.profileImage}
                              alt=""
                              className="profile-image"
                            />
                          </div>
                        ) : (
                          <div
                            className="user_img_name shadow2"
                            style={{
                              height: "50px",
                              width: "50px",
                              fontSize: "16px",
                            }}
                          >
                            {user.firstName && user?.lastName
                              ? user?.firstName?.slice(0, 1) +
                                user?.lastName?.slice(0, 1)
                              : user.displayName?.slice(0, 1) ||
                                user.name?.slice(0, 1)}
                          </div>
                        )}
                        <div
                          className="user-details "
                          style={{ marginLeft: "16px" }}
                        >
                          <div className="user-name">
                            {user.name || user.firstName}
                          </div>
                          <div className="username">@{user.userName}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : (
              <div className="d-flex justify-content-between align-items-center px-2">
                <h4 className="text-bold">{t("Home")}</h4>
                <div className="d-flex align-items-center">
                  <span className="me-3">
                    <BsSearch onClick={() => setShowSearch(!showSearch)} />
                  </span>
                  <div className="three_dot_dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_87_10711)">
                            <path
                              d="M9.00002 4.00003C10.1046 4.00003 11 3.10459 11 2.00002C11 0.895438 10.1046 0 9.00002 0C7.89544 0 7 0.895438 7 2.00002C7 3.10459 7.89544 4.00003 9.00002 4.00003Z"
                              fill="black"
                            />
                            <path
                              d="M9.00002 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 9.00002 7C7.89544 7 7 7.89544 7 9.00002C7 10.1046 7.89544 11 9.00002 11Z"
                              fill="black"
                            />
                            <path
                              d="M9.00002 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9.00002 14C7.89544 14 7 14.8954 7 16C7 17.1046 7.89544 18 9.00002 18Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_87_10711">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </DropdownToggle>
                      <DropdownMenu>
                        {showLockedPost ? (
                          <DropdownItem onClick={handleLockedPost}>
                            {t("Hide All Locked Post")}
                          </DropdownItem>
                        ) : (
                          <DropdownItem onClick={handleLockedPost}>
                            {t("Show All Locked Post")}
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={() =>
                            toast.warning("No promotional post found !")
                          }
                        >
                          {t("Hide All Promotional Posts")}
                        </DropdownItem>
                        {showProfileBanners ? (
                          <DropdownItem
                            onClick={() =>
                              setShowProfileBanners(!showProfileBanners)
                            }
                          >
                            {t("Hide the Profile Banners")}
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            onClick={() =>
                              setShowProfileBanners(!showProfileBanners)
                            }
                          >
                            {t("Show the Profile Banners")}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            )}

            <div className="slider_tab my-1 px-1 d-flex">
              <h6
                className={`rounded-lg pointer text-small px-3 py-1 mx-1 bgcol text-dark ${
                  selectedTab === "All" && "activeTabs"
                }`}
                onClick={() => handleTabSelection("All", {})}
              >
                {t("All")}
              </h6>
              {pinnedCollection?.map((collection, index) => {
                return (
                  <h6
                    className={`rounded-lg pointer text-small px-3 py-1 mx-1 bgcol text-dark  ${
                      selectedTab === collection?.listName && "activeTabs"
                    }`}
                    key={index}
                    onClick={() =>
                      handleTabSelection(collection?.listName, collection)
                    }
                  >
                    {collection?.listName}
                  </h6>
                );
              })}

              <Link to="" onClick={() => setpintohome(true)}>
                <h6 className="rounded-circle text-small editIcon mx-2  text-light ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M7.03 4.51L7.49 4.97L2.96 9.5H2.5V9.04L7.03 4.51ZM8.83 1.5C8.705 1.5 8.575 1.55 8.48 1.645L7.565 2.56L9.44 4.435L10.355 3.52C10.4014 3.47374 10.4381 3.4188 10.4632 3.35831C10.4883 3.29783 10.5012 3.23298 10.5012 3.1675C10.5012 3.10202 10.4883 3.03717 10.4632 2.97669C10.4381 2.9162 10.4014 2.86126 10.355 2.815L9.185 1.645C9.085 1.545 8.96 1.5 8.83 1.5ZM7.03 3.095L1.5 8.625V10.5H3.375L8.905 4.97L7.03 3.095Z"
                      fill="black"
                    />
                  </svg>
                </h6>
              </Link>
            </div>
            <VerifyEmail showPrivacy={true} />
            <div
              id="scrollableDiv"
              style={{ height: "100vh", overflow: "auto" }}
            >
              <InfiniteScroll
                dataLength={newList.length}
                next={() => fetchMorePost()}
                hasMore={newList.length !== totalCounts}
                loader={
                  <div className="d-sm-flex justify-content-center d-none">
                    <SmallLoader
                      cstmClass={`d-sm-none`}
                      color={"text-secondary"}
                    />
                  </div>
                  // <div className="d-flex justify-content-center ">
                  //   <SmallLoader color={"text-secondary"} />
                  // </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <div className="NewsFeed">
                  {newList?.map((item, index) => {
                    return (
                      <PostComponent
                        key={index}
                        setShowSendTip={setShowSendTip}
                        data={item}
                        {...props}
                        showProfileBanners={showProfileBanners}
                        newList={newList}
                        setNewList={setNewList}
                        isSubscribed={checkSubscribe(item?.userId)}
                      />
                    );
                  })}
                </div>
                {isMobile && newList.length !== totalCounts && (
                  <div className="d-flex d-sm-none justify-content-center mt-2">
                    {loading ? (
                      <div className="loader m-2"></div>
                    ) : (
                      <Button
                        onClick={() => {
                          if (!loading) fetchMorePost();
                        }}
                        variant={"secondary"}
                        className="btnborder surjitload d-flex  align-items-center justify-content-center rounded-pill common-btn btnSm "
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </Col>
        <Col lg="5" md="12" sm="12" className="rightSidebar sticky-top">
          <ArtistSection />
        </Col>
      </Row>
    </>
  );
};

export default HomeUser;
