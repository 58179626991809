import React, { useEffect, useState } from "react";
import MainLayout from "../../../../layout/MainLayout";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { massMessageListing } from "../../../../redux/states/messages/thunk";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MassMessageStats = () => {
  
  const [chatSearch, setChatSearch] = useState();
  const dispatch = useDispatch();
  const { massMessageList } = useSelector((s) => s.message);
  const {t} = useTranslation();
  const handleChatSearch = () => {
    setChatSearch(!chatSearch);
  };

  const [messageList, setMessageList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const getMassMessageList = () => {
    const data = {
      limit: 1000,
      page: 1,
      search: "",
    };
    dispatch(massMessageListing());
  };

  useEffect(() => {
    getMassMessageList();
  }, []);

  useEffect(() => {
    setMessageList(massMessageList);
  }, [massMessageList]);

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInput(val);
    const filteredData = massMessageList?.filter((dta) => {
      return dta?.messages.toLowerCase().includes(val?.toLowerCase());
    });
    setMessageList(filteredData);
  };

  return (
    <>
      <MainLayout>
        <Row>
          <Col lg="12">
            <div className="shadow1 rounded border h-100 p-3 massMessage">
              <div
                className="topSecHead py-2 d-flex align-items-center justify-content-between gap-10 border-bottom pb-3 mb-3"
                style={{ height: "50px" }}
              >
                {chatSearch ? (
                  <>
                    <Form className=" w-100 position-relative h-100">
                      <Button
                        onClick={handleChatSearch}
                        className="border-0 p-0 position-absolute"
                        variant="transparent"
                        style={{ left: "0", top: "0", zIndex: "9" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M3.64016 2.27L7.50016 6.13L11.3402 2.29C11.425 2.19972 11.5272 2.12749 11.6406 2.07766C11.754 2.02783 11.8763 2.00141 12.0002 2C12.2654 2 12.5197 2.10536 12.7073 2.29289C12.8948 2.48043 13.0002 2.73478 13.0002 3C13.0025 3.1226 12.9797 3.24439 12.9333 3.35788C12.8869 3.47138 12.8178 3.57419 12.7302 3.66L8.84016 7.5L12.7302 11.39C12.895 11.5512 12.9916 11.7696 13.0002 12C13.0002 12.2652 12.8948 12.5196 12.7073 12.7071C12.5197 12.8946 12.2654 13 12.0002 13C11.8727 13.0053 11.7456 12.984 11.6268 12.9375C11.508 12.8911 11.4002 12.8204 11.3102 12.73L7.50016 8.87L3.65016 12.72C3.56567 12.8073 3.46473 12.8769 3.35316 12.925C3.2416 12.9731 3.12163 12.9986 3.00016 13C2.73495 13 2.48059 12.8946 2.29306 12.7071C2.10552 12.5196 2.00016 12.2652 2.00016 12C1.99783 11.8774 2.02058 11.7556 2.06701 11.6421C2.11344 11.5286 2.18257 11.4258 2.27016 11.34L6.16016 7.5L2.27016 3.61C2.10535 3.44876 2.0087 3.23041 2.00016 3C2.00016 2.73478 2.10552 2.48043 2.29306 2.29289C2.48059 2.10536 2.73495 2 3.00016 2C3.24016 2.003 3.47016 2.1 3.64016 2.27Z"
                            fill="black"
                          />
                        </svg>
                      </Button>
                      <div className="position-relative searchForm icon-with-text">
                        <input
                          type="Search"
                          placeholder="Search...."
                          className="form-control border-0 rounded-0 bg-transparent"
                          value={searchInput}
                          onChange={(e) => handleSearch(e)}
                        />
                        <span className="icn position-absolute d-inline-block ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="left d-flex align-items-center gap-10">
                      <Link
                        to="/messages"
                        className="d-flex me-2 back align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 12L11 6M5 12L11 18M5 12L19 12"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </Link>
                      <h6 className="m-0 fw-bold">{t("MASS MESSAGES STATISTICS")}</h6>
                    </div>
                    <div className="right d-flex align-items-center gap-10">
                      <Button
                        onClick={handleChatSearch}
                        className="border-0 p-0 "
                        variant="transparent"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                            fill="black"
                          ></path>
                        </svg>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="table-responsive py-2">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th className="border-0 p-2 text-muted fw-sbold">
                        Date & Time
                      </th>
                      <th className="border-0 p-2 text-muted fw-sbold">Text</th>
                      <th className="border-0 p-2 text-muted fw-sbold">
                        Attachment
                      </th>
                      <th className="border-0 p-2 text-muted fw-sbold">
                        Price
                      </th>
                      <th className="border-0 p-2 text-muted fw-sbold">Sent</th>
                      <th className="border-0 p-2 text-muted fw-sbold">
                        Viewed
                      </th>
                      <th className="border-0 p-2 text-muted fw-sbold">
                        Purchased
                      </th>
                      {/* <th className="border-0 p-2 text-muted fw-sbold">
                        Unsend
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="noLastBorder">
                    {messageList?.map((itm, index) => {
                      return (
                        <tr>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            {moment(itm?.createdAt).format(
                              "MMMM DD YYYY HH:mm"
                            )}
                          </td>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            {itm?.messages}
                          </td>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            {itm?.attachmentCount !== 0 ? "Yes" : "No"}
                          </td>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            $ {itm?.priceCount}
                          </td>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            {itm?.ids?.length}
                          </td>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            {itm?.readCount}
                          </td>
                          <td className="border-0 p-2 text-dark fw-sbold">
                            {itm?.purchasedCount}
                          </td>
                          {/* <td className="border-0 p-2 text-dark fw-sbold">
                            <Button
                              className="border-0 fw-bold p-0"
                              variant="transparent"
                            >
                              Unsend
                            </Button>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </MainLayout>
    </>
  );
};

export default MassMessageStats;
