import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import ReactPinField from "react-pin-field";
import { toast } from "react-toastify";
import { forgotPassword, verifyOtp } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

const VerifyOtpComponents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [event, setEvent] = useState("verifyOtp");

  const { buttonLoader, selectedEmail } = useSelector((state) => state.login);

  const formik = useFormik({
    initialValues: {
      email: "",
      OTP: "",
    },
    onSubmit: async function (values) {
      setEvent("verifyOtp");
      dispatch(verifyOtp(values))
        .unwrap()
        .then((res) => {
          toast.success(t("OTP verified"));
          navigate("/reset-password");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },

    validationSchema: Yup.object({
      OTP: Yup.string(t("OTP must be a string"))
        .required(t("OTP is required"))
        .min(6, t("Invalid OTP"))
        .max(6, t("Invalid OTP")),
    }),
  });

  const handleOtpChange = (val) => {
    formik.setFieldValue("OTP", val);
  };

  useEffect(() => {
    if (selectedEmail) {
      formik.setFieldValue("email", selectedEmail);
    }
  }, [selectedEmail]);

  const handleResendOtp = () => {
    setEvent("resendOtp");
    const data = {
      email: selectedEmail,
    };
    dispatch(forgotPassword(data))
      .unwrap()
      .then((res) => {
        toast.success(t("OTP has been send to your mail"));
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <Container className=" w-100 px-5 py-4 d-flex justify-content-center">
      <Row className="loginCont">
        <Col lg="12" md="12" className="mt-4 d-flex flex-column my-auto">
          <h3 className="text-black text-medium mb-4">{t("Verify OTP")}</h3>

          <div className="cstm-otp d-flex align-items-center justify-content-center">
            <ReactPinField
              style={{ width: "50px", height: "40px", margin: "0px 10px" }}
              class="pin-field"
              validate="0123456789"
              length="6"
              onChange={handleOtpChange}
            />
          </div>
          {formik.touched.OTP && formik.errors.OTP && (
            <span className="text-danger mt-3">{formik.errors.OTP}</span>
          )}
          <div className="mt-2"></div>

          <div className="mt-4">
            {buttonLoader && event === "verifyOtp" ? (
              <button className="loginBtn2 text-light" ß>
                <ButtonLoader />
              </button>
            ) : (
              <button
                className="loginBtn2 text-light"
                onClick={formik.handleSubmit}
              >
                {t("Verify OTP")}
              </button>
            )}
          </div>
          <div className="mt-4 px-5 d-flex justify-content-between align-items-center">
            <h4 className="text-black text-normal pointer">
              {" "}
              {t("Not Receive OTP?")}
            </h4>
            <h4 className="text-black text-normal pointer"></h4>
          </div>
          <div className="mt-4">
            {buttonLoader && event === "resendOtp" ? (
              <button className="loginBtn2 text-light">
                <ButtonLoader />
              </button>
            ) : (
              <button className="loginBtn2 text-light" onClick={handleResendOtp}>
                {t("Resend OTP")}
              </button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyOtpComponents;
