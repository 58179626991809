import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { renewSubscription } from "../../../redux/states/card/thunk";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { IoMdWallet } from "react-icons/io";
import { toast } from "react-toastify";
import { getProfile } from "../../../redux/states/settings/thunk";
import { AiFillCloseCircle } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import SubscriptionPlanSection from "../SubscriptionPlanSection/SubscriptionPlanSection";

const RenewSubscriptionModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [payData, setPayData] = useState({
    subscriptionId: "",
    paymentMethod: "",
    paymentSourceRefNo: "",
  });

  const { cardList, buttonLoader } = useSelector((s) => s.card);
  const [selectedPackage, setSelectedPackage] = useState({});

  const refreshForm = () => {
    setPayData({
      subscriptionId: "",
      paymentMethod: "",
      paymentSourceRefNo: "",
    });
  };

  const renewSubscriptions = () => {
    dispatch(renewSubscription(payData))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        props.onHide();
        refreshForm();
        dispatch(getProfile());
      })
      .catch((err) => {
        toast.error(err?.message);
        props.onHide();
      });
  };

  const handleCardSelection = (card, paymentType) => {
    if (paymentType === "card") {
      setPayData((rest) => ({
        ...rest,
        paymentSourceRefNo: card?._id,
        paymentMethod: "card",
      }));
    } else if (paymentType === "wallet") {
      setPayData((rest) => ({ ...rest, paymentMethod: "wallet" }));
    }
  };

  const close = () => {
    refreshForm();
    props.onHide();
  };

  const handlePackageSelect = (dta) => {
    setSelectedPackage(dta);
    setPayData((rest) => ({ ...rest, subscriptionId: dta?._id }));
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal_header d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <h6>{t("RENEW SUBSCRIPTION")} </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 3v2H3V3h9Zm4 16v2H3v-2h13Zm6-8v2H3v-2h19Z"
              />
            </svg>
          </span>
        </div>

        <div className="middle_content px-3 py-2">
          <div>
            <div className="d-flex align-items-center   justify-content-center">
              <div className="text-small text-center my-2">
                {t(
                  "Your subscription has been expired , Please renew to get all features"
                )}{" "}
                <AiFillCloseCircle color="#eb4034" size={24} className="mb-0" />
              </div>
            </div>

            <Row>
              <Col lg="12" className="my-2">
                <div className="text-bold my-2">
                  {t("Select Subscription Plan")}
                </div>
                <div className="NewsFeed my-3 mt-4">
                  <SubscriptionPlanSection
                    selectedPackage={selectedPackage}
                    handlePackageSelect={handlePackageSelect}
                  />
                </div>
              </Col>
              <Col lg="12" className="mb-2">
                <div className="text-bold ">{t("Select Payment Method")}</div>
                <div className="d-flex align-items-center mt-4 flex-wrap justify-content-between gap-10">
                  {cardList?.map((itm, ind) => {
                    return (
                      <div
                        className="payementCrdd shadow2"
                        key={ind}
                        onClick={() => handleCardSelection(itm, "card")}
                      >
                        <input type="radio" id={itm?.token} name="payment" />
                        <label
                          htmlFor={itm?.token}
                          className="d-flex align-items-center"
                        >
                          <img src={itm?.logo} alt="" className="mx-3" />
                          <p className="mb-0 text-small d-flex align-items-center">
                            {" "}
                            <span style={{ fontSize: "30px" }}>
                              {" "}
                              ···· ···· ····
                            </span>{" "}
                            <p className="mb-0 mx-2">{itm?.lastd}</p>
                          </p>
                        </label>
                      </div>
                    );
                  })}

                  <div
                    className="payementCrdd shadow2 py-2 pointer"
                    onClick={() => handleCardSelection({}, "wallet")}
                  >
                    <input type="radio" id={"wallet"} name="payment" />
                    <label
                      htmlFor="wallet"
                      className="d-flex align-items-center "
                    >
                      <IoMdWallet size={23} color="#5e5c5c" className="mx-4" />
                      <h6 className="text-small mb-0 mx-2">
                        {t("Pay from wallet")}
                      </h6>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-flex  justify-content-center">
              {buttonLoader ? (
                <button className="updateNotificationBtn">
                  <SmallLoader />
                </button>
              ) : (
                <button
                  className={`updateNotificationBtn ${
                    (payData?.subscriptionId === "" ||
                      payData?.paymentMethod === "") &&
                    "btn-disable "
                  }updateNotificationBtn`}
                  disabled={
                    payData?.subscriptionId === "" ||
                    payData?.paymentMethod === ""
                  }
                  onClick={renewSubscriptions}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="modal_footer d-flex align-items-center justify-content-end px-3 py-2 border-top">
          <Button onClick={close}>{t("Close")}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RenewSubscriptionModal;
