// import React, { useState, useEffect } from "react";
// import { BiSearch } from "react-icons/bi";
// import MediaCard from "../common/MediaCard/MediaCard";
// import { useTranslation } from "react-i18next";

// const Media = (props) => {
//   const { t } = useTranslation();
//   const { data, getArtistPostList } = props;
//   const [postData, setPostData] = useState([data]);
//   const [searchInput, setSearchInput] = useState("");

//   const handleSearch = (e) => {
//     setSearchInput(e.target.value);
//     const value = e.target.value.toLowerCase();
//     const filterData = data?.filter((itm) => {
//       return itm?.caption.toLowerCase().includes(value);
//     });
//     setPostData(filterData);
//   };

//   useEffect(() => {
//     setPostData(data);
//   }, [data]);

//   return (
//     <div className="px-3 py-3">
//       <div className="d-flex justify-content-between align-items-center">
//         <h6 className="text-custom">{t("RECENT")}</h6>
//         <div className="inputSearch">
//           <input
//             type="text"
//             placeholder={t("Search...")}
//             value={searchInput}
//             onChange={(e) => handleSearch(e)}
//           />
//           <BiSearch color="#636363" size={20} />
//         </div>
//       </div>
//       <div className="my-1 mx-1 d-flex">
//         <h6 className="rounded-lg text-small px-3 py-1 mx-1 bg-black text-light ">
//           {t("All")}
//         </h6>
//         <h6 className="rounded-lg text-small px-3 py-1 mx-2 bg-black text-light ">
//           {t("Videos")}
//         </h6>
//         <h6 className="rounded-lg text-small px-3 py-1 mx-2 bg-black text-light ">
//           {t("Images")}
//         </h6>
//       </div>
//       <div>
//         <div className="NewsFeed">
//           {postData?.map((item, index) => {
//             return (
//               <MediaCard
//                 data={item}
//                 key={index}
//                 getArtistPostList={getArtistPostList}
//               />
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Media;

import React, { Fragment, useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from "reactstrap";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import ImageVideoViewer from "../../components/common/Modal/ImageVideoViewer";
import { useTranslation } from "react-i18next";
import DummyPostImg from "../../images/dummyPost.png";
import { CiLock } from "react-icons/ci";
import CustomVideoPlayer from "../CustomVideoPlayer";

// components;

const Media = (props) => {
  const { t } = useTranslation();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const { allMedia, tab, isSubscribed } = props;
  const [showViewer, setShowViewer] = useState(false);

  const handleShowMedia = (dta) => {
    setSelectedMedia({
      url: dta?.url,
      mediaType: dta?.type,
      userName: "",
    });
    setShowViewer(true);
  };

  const { isAuthenticated } = useSelector((s) => s.login);

  return (
    <div>
      {tab === "media" && (
        <>
          <div className=" lg-px-3 mt-3">
            <Row className="align-items-center align-middle position-relative">
              {allMedia?.length === 0 && (
                <div className="no_bookmarks_yet text-center h-100 vh-100 mt-5">
                  <img
                    src="/images/allmedia.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p>{t("This category is empty")}</p>
                </div>
              )}
              <div className="no_bookmarks_yet text-center h-100 vh-100 homeMiddleCont">
                <Row className="">
                  {allMedia?.map((data, index) => {
                    const showMedia =
                      data?.type !== "paid" && isAuthenticated && isSubscribed;
                    return (
                      <>
                        {data?.media?.map((media, ind) => {
                          return (
                            <>
                              {showMedia ? (
                                <Col sm="6" className="my-2">
                                  {media?.type === "image" && (
                                    <div
                                      className="mediaItem"
                                      onClick={() => handleShowMedia(media)}
                                    >
                                      <img
                                        src={media?.url}
                                        alt=""
                                        className="item"
                                      />
                                    </div>
                                  )}
                                  {media?.type === "video" && (
                                    <div
                                      className="mediaItem"
                                      onClick={() => handleShowMedia(media)}
                                    >
                                      <CustomVideoPlayer mediaItem={media} />
                                      {/* <ReactPlayer
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                        url={media?.url}
                                        width={"100%"}
                                        // height={"100%"}
                                        controls={false}
                                        className="custom-player"
                                      /> */}
                                    </div>
                                  )}
                                  {media?.type === "audio" && (
                                    <div className="mediaItem">
                                      <audio src="" controls></audio>
                                    </div>
                                  )}
                                </Col>
                              ) : (
                                <Col sm="6" className="my-2">
                                  <div className="mediaItem position-relative">
                                    <img src={DummyPostImg} alt="" />
                                    <CiLock
                                      className="postLockIcon"
                                      style={{ top: "50%" }}
                                      size={45}
                                      color="#b8b8b8"
                                    />
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </div>
          <ImageVideoViewer
            show={showViewer}
            onHide={() => setShowViewer(false)}
            mediaType={selectedMedia?.mediaType}
            url={selectedMedia?.url}
            userName={selectedMedia?.userName}
          />
        </>
      )}
    </div>
  );
};

export default Media;
