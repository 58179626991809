import { Routes, Route, useParams } from "react-router-dom";
import Login from "../pages/AuthPages/Login";
import SignUp from "../pages/AuthPages/SignUp";
import Home from "../pages/SideTabs/Home/Home";
import Notification from "../pages/SideTabs/Notification/Notification";
import Messages from "../pages/SideTabs/Messages/Messages";
import Collections from "../pages/SideTabs/Collections/Collections";
import Subscriptions from "../pages/SideTabs/Subscriptions/Subscriptions";
import MyProfile from "../pages/SideTabs/MyProfile/MyProfile";
import NewPost from "../pages/SideTabs/NewPost/NewPost";
import EditProfile from "../pages/SideTabs/Settings/EditProfile";
import AddCard from "../pages/SideTabs/AddCard/AddCard";
import HelpSupport from "../pages/SideTabs/HelpSupport/HelpSupport";
import Account from "../pages/SideTabs/Settings/Account";
import PrivacyAndSafety from "../pages/SideTabs/Settings/PrivacyAndSafety";
import Notifications from "../pages/SideTabs/Settings/Notifications";
import Display from "../pages/SideTabs/Settings/Display";
import ForgotPassword from "../pages/AuthPages/ForgotPassword";
import ChangePassword from "../pages/AuthPages/ChangePassword";
import VeriftyOtp from "../pages/AuthPages/VeriftyOtp";
import ResetPassword from "../pages/AuthPages/ResetPassword";
import UserDetails from "../pages/UserDetails/UserDetails";
import ChangePasswords from "../pages/SideTabs/Settings/ChangePasswords";
import PrivateRoute from "./PrivateRoute";
import UserName from "../pages/SideTabs/Settings/Accounts/UserName";
import Email from "../pages/SideTabs/Settings/Accounts/Email";
import ChangePasswordss from "../pages/SideTabs/Settings/Accounts/ChangePassword";
import PhoneNumber from "../pages/SideTabs/Settings/Accounts/PhoneNumber";
import Twitter from "../pages/SideTabs/Settings/Accounts/Twitter";
import Google from "../pages/SideTabs/Settings/Accounts/Google";
import ConnectAccount from "../pages/SideTabs/Settings/Accounts/ConnectAccount";
import LoginSession from "../pages/SideTabs/Settings/Accounts/LoginSession";
import TwoStepAuthentications from "../pages/SideTabs/Settings/Accounts/TwoStepAuthentications";
import PushNotification from "../pages/SideTabs/Settings/Notification/PushNotification";
import SiteNotification from "../pages/SideTabs/Settings/Notification/SiteNotification";
import ToastNotification from "../pages/SideTabs/Settings/Notification/ToastNotification";
import SmsNotification from "../pages/SideTabs/Settings/Notification/SmsNotification";
import TelegramBot from "../pages/SideTabs/Settings/Notification/TelegramBot";
import EmailNotification from "../pages/SideTabs/Settings/Notification/EmailNotification";
import Privacy from "../pages/common/Privacy";
// import USC from "../pages/ContentPages/USC/Index";
import Brand from "../pages/ContentPages/Brand/Index";
// import Contract from "../pages/ContentPages/Contract/Index";
import Terms from "../pages/common/Terms";
import PostDetails from "../pages/PostDetails/PostDetails";
import Vault from "../pages/SideTabs/Vault/Vault";
import Queue from "../pages/SideTabs/Queue/Queue";
import Statements from "../pages/SideTabs/Statements/Statements";
import Payouts from "../pages/SideTabs/Payouts/Payouts";
import Statistics from "../pages/SideTabs/Statistics/Statistics";
import BecomeCreator from "../pages/SideTabs/BecomeCreator/Index";
import SubscriptionPriceBundle from "../pages/SideTabs/Settings/SubscriptionPriceBundle";
import FansFollowing from "../pages/SideTabs/Settings/FansFollowing";
import Chats from "../pages/SideTabs/Settings/Chats";
import Story from "../pages/SideTabs/Settings/Story";
import QRCode from "../pages/SideTabs/Settings/QRCode";
import SocialAccount from "../pages/SideTabs/Settings/Accounts/SocialAccount";
import PostEdit from "../pages/SideTabs/PostEdit/PostEdit";
import MassMessageStats from "../pages/SideTabs/Messages/MassMessageStats/Index";
import NewMessage from "../pages/SideTabs/Messages/NewMessage/Index";
import MyRefferals from "../components/SideTabs/Statements/Refferals/Index";
import ReleaseForm from "../components/SideTabs/ReleaseForm/Index";
import VerificationStatus from "../pages/SideTabs/VerificationStatus";
import AddBank from "../pages/SideTabs/AddBank/AddBank";
import AddBankComponent from "../components/SideTabs/AddBank";
import BankingPage from "../pages/SideTabs/BankingPage/BankingPage";
import CookiesPolicy from "../pages/ContentPages/CookiesPolicy/Index";
import SafetyTransparent from "../pages/ContentPages/SafetyTransparent/Index";
import DMCA from "../pages/ContentPages/DMCA/Index";
import About from "../pages/common/About/About";
// import AntiCrime from "../pages/ContentPages/AntiCrime/AntiCrime";
import Payments from "../pages/SideTabs/Payments/Payments";
import Message2 from "../pages/SideTabs/Messages2/Message2";
import Contact from "../pages/common/contact/Contact";
import ContentPage from "../pages/common/contentPage";
import LegalInquiry from "../pages/common/legalInquiry";
import Help from "../pages/common/help";
import Faq from "../pages/common/faq";
import Becomeamodel from "../pages/ContentPages/Becomeamodel/Index";

const publicRoutes = [
  {
    path: "/",
    name: "Login",
    component: <Login />,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: <SignUp />,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: <ForgotPassword />,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: <ChangePassword />,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: <ResetPassword />,
  },
  {
    path: "/verify-otp",
    name: "Verify OTP",
    component: <VeriftyOtp />,
  },
  // {
  //   path: "/application",
  //   name: "Become a model",
  //   component: <Becomeamodel />,
  // },
  // {
  //   path: "/privacy",
  //   name: "Privacy",
  //   component: <Privacy />,
  // },
  // {
  //   path: "/USC",
  //   name: "USC",
  //   component: <USC />,
  // },
  {
    path: "/brand",
    name: "brand",
    component: <Brand />,
  },
  // {
  //   path: "/contract",
  //   name: "contract",
  //   component: <Contract />,
  // },
  // {
  //   path: "/terms",
  //   name: "Terms",
  //   component: <Terms />,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: <About />,
  // },

  // {
  //   path: "/:slug",
  //   name: "User Details",
  //   component: <UserDetails />,
  // },
  // {
  //   path: "/cookie-policy",
  //   name: "Cookies Policy",
  //   component: <CookiesPolicy />,
  // },
  // {
  //   path: "/safety-transparency",
  //   name: "Safety & Transparency",
  //   component: <SafetyTransparent />,
  // },
  // {
  //   path: "/dmca",
  //   name: "DMCA",
  //   component: <DMCA />,
  // },
  {
    path: "/contact",
    name: "CONTACT",
    component: <Contact />,
  },
  {
    path: "/legalinquiry",
    name: "LEGALINQUIRY",
    component: <LegalInquiry />,
  },
  // {
  //   path: "/antitraffickingstatement",
  //   name: "Anti Trafficking Statement",
  //   component: <AntiCrime />,
  // },
  {
    path: "/post-details/:slug",
    name: "Post Details",
    component: <PostDetails />,
  },
  {
    path: "/help",
    name: "Help And Support",
    // component: <HelpSupport />,
    component: <Help />,
  },
  {
    path: "/faq",
    name: "Faq",
    // component: <HelpSupport />,
    component: <Faq />,
  },
];

const privateRoutes = [
  {
    path: "/home",
    name: "Home",
    component: <Home />,
    roles: ["user", "entertainer"],
  },
  {
    path: "/notification",
    name: "Notification",
    component: <Notification />,
  },
  {
    path: "/messages",
    name: "Messages",
    component: <Messages />,
  },
  {
    path: "/mass-chats",
    name: "Mass Messages",
    component: <MassMessageStats />,
  },
  {
    path: "/messages/new",
    name: "New Messages",
    component: <NewMessage />,
  },

  {
    path: "/messages/:artistId",
    name: "Messages",
    component: <Messages />,
  },
  {
    path: "/messages/:artistId/chat/:chatId",
    name: "Messages",
    component: <Message2 />,
  },
  {
    path: "/collections",
    name: "Collections",
    component: <Collections />,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: <Subscriptions />,
  },
  {
    path: "/vault",
    name: "Vault",
    component: <Vault />,
  },
  {
    path: "/queue",
    name: "Queue",
    component: <Queue />,
  },
  {
    path: "/statements",
    name: "Statements",
    component: <Statements />,
  },
  // {
  //   path: "/payouts",
  //   name: "Payouts",
  //   component: <Payouts />,
  // },
  {
    path: "/refferrals",
    name: "Refferals",
    component: <MyRefferals />,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: <Statistics />,
  },
  {
    path: "/profile",
    name: "Profile",
    component: <MyProfile />,
  },
  {
    path: "/new-post",
    name: "New Post",
    component: <NewPost />,
  },
  {
    path: "/post/edit/:id",
    name: "Post Edit",
    component: <PostEdit />,
  },
  {
    path: "/settings/edit-profile",
    name: "Settings Edit Profile",
    component: <EditProfile />,
  },
  {
    path: "/settings/change-password",
    name: "Settings change Password",
    component: <ChangePasswords />,
  },
  {
    path: "/settings/account",
    name: "Settings Account",
    component: <Account />,
  },
  {
    path: "/settings/account/username",
    name: "Settings Account Username",
    component: <UserName />,
  },
  {
    path: "/settings/account/change-password",
    name: "Settings Account Change Password",
    component: <ChangePasswordss />,
  },
  {
    path: "/settings/account/email",
    name: "Settings Account email",
    component: <Email />,
  },
  {
    path: "/settings/account/mobileNumber",
    name: "Settings Account mobile Number",
    component: <PhoneNumber />,
  },
  {
    path: "/settings/account/twitter",
    name: "Settings Account twitter",
    component: <Twitter />,
  },
  {
    path: "/settings/account/google",
    name: "Settings Account google",
    component: <Google />,
  },
  {
    path: "/settings/account/connectAccount",
    name: "Settings Account connectAccount",
    component: <ConnectAccount />,
  },
  {
    path: "/settings/account/socialAccount",
    name: "Settings Account socialAccount",
    component: <SocialAccount />,
  },
  {
    path: "/settings/account/loginSession",
    name: "Settings Account Login Session",
    component: <LoginSession />,
  },
  {
    path: "/settings/account/twoStepVerification",
    name: "Settings Account Two Step Verification",
    component: <TwoStepAuthentications />,
  },
  {
    path: "/settings/notification/push-notifications",
    name: "Settings Push Notification",
    component: <PushNotification />,
  },
  {
    path: "/settings/notification/email-notifications",
    name: "Settings Email Notification",
    component: <EmailNotification />,
  },
  {
    path: "/settings/notification/site-notifications",
    name: "Settings Site Notification",
    component: <SiteNotification />,
  },
  {
    path: "/settings/notification/toast-notifications",
    name: "Settings Toast Notification",
    component: <ToastNotification />,
  },
  {
    path: "/settings/notification/SMS-notifications",
    name: "Settings SMS Notification",
    component: <SmsNotification />,
  },
  {
    path: "/settings/notification/telegram-bot",
    name: "Settings Telegram Bot ",
    component: <TelegramBot />,
  },
  {
    path: "/settings/notifications",
    name: "Settings Notifications",
    component: <Notifications />,
  },
  {
    path: "/settings/display",
    name: "Settings Display",
    component: <Display />,
  },
  {
    path: "/settings/privacy-safety",
    name: "Privacy Safety",
    component: <PrivacyAndSafety />,
  },
  {
    path: "/settings/subscription-price-bundle",
    name: "Subscription Price Bundle",
    component: <SubscriptionPriceBundle />,
  },
  {
    path: "/settings/fans-following",
    name: "Fans Following",
    component: <FansFollowing />,
  },
  {
    path: "/settings/chats",
    name: "Chats",
    component: <Chats />,
  },
  {
    path: "/settings/story",
    name: "Story",
    component: <Story />,
  },
  {
    path: "/settings/qrcode",
    name: "QRCode",
    component: <QRCode />,
  },
  {
    path: "/add-card",
    name: "Add Card",
    component: <AddCard />,
  },

  {
    path: "/become-creator",
    name: "Become a Creator",
    component: <BecomeCreator />,
  },
  {
    path: "/verification-status",
    name: "Verification Status",
    component: <VerificationStatus />,
  },
  {
    path: "/release-form",
    name: "Release Form",
    component: <ReleaseForm />,
  },
  {
    path: "/banking",
    name: "Banking",
    component: <AddBank />,
  },
  {
    path: "/banking/w9form",
    name: "Banking w9form",
    component: <BankingPage />,
  },
  {
    path: "/payments",
    name: "Payments",
    component: <Payments />,
  },
];

const MyRoutes = () => {
  return (
    <>
      <Routes>
        {publicRoutes.map((item, index) => (
          <Route path={item.path} element={item.component} key={index} />
        ))}

        {privateRoutes.map((item, index) => (
          <Route
            path={item.path}
            element={<PrivateRoute>{item.component}</PrivateRoute>}
            key={index}
          />
        ))}
        <Route path={":slug"} element={<ContentPage />} />
      </Routes>
    </>
  );
};

export default MyRoutes;
