import React from 'react'

const Visscards = () => {
  return (
    <div className="payment_Card mt-3">
               <ul className='p-0 m-0 d-flex align-items-center flex-wrap'>
                <li>
                    <div className="card_img">
                      <img src="https://splash-ark.s3.us-east-2.amazonaws.com/16608181755941614744039133visacard.png" alt="" className="img-fluid"/>
                    </div>
                </li>
                <li>
                    <div className="card_img">
                      <img src="https://fansroom.s3.ap-south-1.amazonaws.com/1697708129991" alt="" className="img-fluid"/>
                    </div>
                </li>

                <li>
                    <div className="card_img">
                      <img src="/images/discover.png" alt="" className="img-fluid"/>
                    </div>
                </li>

                <li>
                    <div className="card_img">
                      <img src="/images/amex.png" alt="" className="img-fluid"/>

                    </div>
                </li>

               </ul>
            </div>
  )
}

export default Visscards