import { createSlice } from "@reduxjs/toolkit";
import {
  activeArtistList,
  addArtistToList,
  allArtistGroupList,
  artistDetails,
  artistDetailsByUsername,
  artistList,
  subscriberList,
  artistPost,
  createNewList,
  getCreatedListForArtist,
  removeArtistFromList,
  searchArtist,
} from "./thunk";
import { status } from "../../constants";

const initialState = {
  loader: false,
  list: [],
  artistDetailss: {},
  status: status.IDLE,
  artistPostList: [],
  showButtonLoader: false,
  allCreatedLists: [],
  allGroupList: [],
  activeArtistLists: [],
  searchArtistList: [],
  subscriberList:[]
};

const slice = createSlice({
  name: "artistList",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(artistList.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(artistList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.list = action.payload?.data;
    });
    builder.addCase(artistList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.list = initialState.list;
    });
    builder.addCase(subscriberList.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(subscriberList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.subscriberList = action.payload?.data;
    });
    builder.addCase(subscriberList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.subscriberList = initialState.subscriberList;
    });
    builder.addCase(activeArtistList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(activeArtistList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.activeArtistLists = action.payload?.data;
    });
    builder.addCase(activeArtistList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.activeArtistLists = initialState.activeArtistLists;
    });
    builder.addCase(artistDetails.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(artistDetails.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.artistDetailss = action.payload?.data?.[0];
    });
    builder.addCase(artistDetails.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.artistDetailss = initialState.artistDetailss;
    });
    builder.addCase(artistDetailsByUsername.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(artistDetailsByUsername.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.artistDetailss = action.payload?.data?.[0];
    });
    builder.addCase(artistDetailsByUsername.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.artistDetailss = initialState.artistDetailss;
    });
    builder.addCase(artistPost.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(artistPost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.artistPostList = action.payload?.data;
    });
    builder.addCase(artistPost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.artistPostList = initialState.artistPostList;
    });

    builder.addCase(createNewList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
      state.showButtonLoader = true;
    });
    builder.addCase(createNewList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.showButtonLoader = false;
    });
    builder.addCase(createNewList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.showButtonLoader = false;
    });
    builder.addCase(addArtistToList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(addArtistToList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(addArtistToList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(removeArtistFromList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(removeArtistFromList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(removeArtistFromList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(getCreatedListForArtist.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getCreatedListForArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.allCreatedLists = action?.payload?.data;
    });
    builder.addCase(getCreatedListForArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.allCreatedLists = initialState.allCreatedLists;
    });

    builder.addCase(allArtistGroupList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(allArtistGroupList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.allGroupList = action?.payload?.data;
    });
    builder.addCase(allArtistGroupList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.allGroupList = initialState.allGroupList;
    });

    builder.addCase(searchArtist.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(searchArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.searchArtistList = action?.payload?.data;
    });
    builder.addCase(searchArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.searchArtistList = initialState.searchArtistList;
    });
  },
});

export default slice.reducer;
