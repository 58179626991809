import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";
import UserList from "./UserList";
import Bookmarks from "./Bookmarks";
import Users from "./Users";
import Posts from "./Posts";
import { useDispatch, useSelector } from "react-redux";
import SaveToList from "../../common/Modal/SaveToList";
import { useNavigate } from "react-router-dom";
import {
  allArtistGroupList,
  allBookMarkedPost,
  archivePostList,
  artistList,
  blockedArtistList,
  getPostList,
  getSelectedGoupList,
  restirctedArtistList,
  subscribedArtistList,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AllBookmarks from "./AllBookmarks.jsx";
import Postlabels from "./PostLabels";
import Achrive from "./Achrive";
import PrivateArchive from "./PrivateArchive";
import AddList from "../../common/Modal/AddList";

const EntertainerCollectionComponent = (props) => {
  const { t } = useTranslation();
  const [activeBoxx, setActiveBoxx] = useState("all");
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const userId = user?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState("userList");
  const { list, loader } = useSelector((s) => s.artist);
  const allPosts = useSelector((s) => s.home);
  const [showBlockedList, setShowBlockedList] = useState(false);
  const [postLabelActiveBox, setPostLabelActiveBox] =
    useState("privateArchive");
  const [archiveItemCount, setArchiveItemCount] = useState(0);
  const [privateArchiveItemCount, setPrivateArchiveItemCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showAddList, setShowAddList] = useState(false);

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
  };

  const [userList, setUserList] = useState([]);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    setUserList(list);
  }, [list]);

  useEffect(() => {
    setPostList(allPosts?.list);
  }, [allPosts?.list]);

  const showAll = () => {
    setPostList(allPosts?.list);
    setUserList(list);
  };

  const handleFollowing = () => {
    setShowBlockedList(false);
    setActiveBoxx("following");
    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(subscribedArtistList(data))
      .unwrap()
      .then((res) => {
        const subcribeArtistList = res?.data?.map((itm) => {
          return itm?.followerDetails;
        });
        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });

        const flattenedData = [].concat(...postList);

        setPostList(flattenedData);
        setUserList(subcribeArtistList);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleRestriction = () => {
    setShowBlockedList(false);
    setActiveBoxx("restricted");

    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(restirctedArtistList(data))
      .unwrap()
      .then((res) => {
        const restrictedList = res?.data?.map((itm) => {
          return itm?.artistDetails;
        });

        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });
        const flattenedData = [].concat(...postList);
        setPostList(flattenedData);
        setUserList(restrictedList);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleBlocked = () => {
    setShowBlockedList(true);
    setActiveBoxx("blocked");
    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(blockedArtistList(data))
      .unwrap()
      .then((res) => {
        const restrictedList = res?.data?.map((itm) => {
          return itm?.blockedUserDetails;
        });
        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });

        const flattenedData = [].concat(...postList);
        setPostList(flattenedData);
        setUserList(restrictedList);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleGroupList = (dta) => {
    setShowBlockedList(false);
    const data = {
      limit: 1000,
      page: 1,
      fieldName: "listName",
      fieldValue: dta ? dta?.listName : activeBoxx,
    };

    dispatch(getSelectedGoupList(data))
      .unwrap()
      .then((res) => {
        const list = res?.data?.map((itm) => {
          return itm?.artistDetails;
        });
        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });
        const flattenedData = [].concat(...postList);
        setPostList(flattenedData);
        setUserList(list?.[0]);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const getArtistList = () => {
    const data = {
      limit: 1000,
      order: 1,
      orderBy: "createdAt",
      page: 1,
      search: "",
    };
    dispatch(artistList(data));
  };

  useEffect(() => {
    getArtistList();
  }, []);

  const getPostLists = () => {
    const data = {
      limit: "",
      orderBy: "createdAt",
      order: 1,
      page: 1,
      filter: "following",
    };
    dispatch(getPostList(data));
  };

  useEffect(() => {
    getPostLists();
  }, []);

  const commonCall = () => {
    if (activeBoxx === "all") {
      getArtistList();
      getPostLists();
    } else if (activeBoxx === "following") {
      handleFollowing();
    } else if (activeBoxx === "blocked") {
      handleBlocked();
    } else if (activeBoxx === "restricted") {
      handleRestriction();
    } else if (activeBoxx === "bookmark") {
      getAllBookmarkedPost();
    } else {
      handleGroupList();
    }
    const data = {
      limit: 100,
      page: 1,
    };
    dispatch(allArtistGroupList(data));
  };

  const getAllBookmarkedPost = () => {
    const data = {
      limit: 100,
      page: 1,
      userId: user?._id,
    };
    dispatch(allBookMarkedPost(data));
  };

  const getArchivePostList = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["normal", "poll", "other", "quiz"],
      userId: userId,
      status: "archive",
    };
    dispatch(archivePostList(data))
      .unwrap()
      .then((res) => {
        setArchiveItemCount(res?.data?.length);
      });
  };
  const getPrivateArchivePostList = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["normal", "poll", "other", "quiz"],
      userId: userId,
      status: "priveteArchive",
    };
    dispatch(archivePostList(data))
      .unwrap()
      .then((res) => {
        setPrivateArchiveItemCount(res?.data?.length);
      });
  };

  useEffect(() => {
    getPostList();
  }, []);

  useEffect(() => {
    getArchivePostList();
    getPrivateArchivePostList();
  }, []);

  const commonFunction = () => {
    if (postLabelActiveBox === "archive") {
      getArchivePostList();
    } else if (postLabelActiveBox === "privateArchive") {
      getPrivateArchivePostList();
    }
  };
  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInput(val);
  };

  const handleSearchclose = () => {
    setShowSearch(false);
    setSearchInput("");
  };

  return (
    <Row className="  h-100 d-flex justify-content-between ">
      <Col lg="5" md="12" sm="12">
        <div className="shadow1 rounded border h-100 py-4 border_hide">
          {showSearch ? (
            <Form className="shadow2 px-3 position-relative  mx-2">
              <Button
                onClick={handleSearchclose}
                className="border-0 p-0 position-absolute"
                variant="transparent"
                style={{ left: "6px", top: "2px", zIndex: "9" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M3.64016 2.27L7.50016 6.13L11.3402 2.29C11.425 2.19972 11.5272 2.12749 11.6406 2.07766C11.754 2.02783 11.8763 2.00141 12.0002 2C12.2654 2 12.5197 2.10536 12.7073 2.29289C12.8948 2.48043 13.0002 2.73478 13.0002 3C13.0025 3.1226 12.9797 3.24439 12.9333 3.35788C12.8869 3.47138 12.8178 3.57419 12.7302 3.66L8.84016 7.5L12.7302 11.39C12.895 11.5512 12.9916 11.7696 13.0002 12C13.0002 12.2652 12.8948 12.5196 12.7073 12.7071C12.5197 12.8946 12.2654 13 12.0002 13C11.8727 13.0053 11.7456 12.984 11.6268 12.9375C11.508 12.8911 11.4002 12.8204 11.3102 12.73L7.50016 8.87L3.65016 12.72C3.56567 12.8073 3.46473 12.8769 3.35316 12.925C3.2416 12.9731 3.12163 12.9986 3.00016 13C2.73495 13 2.48059 12.8946 2.29306 12.7071C2.10552 12.5196 2.00016 12.2652 2.00016 12C1.99783 11.8774 2.02058 11.7556 2.06701 11.6421C2.11344 11.5286 2.18257 11.4258 2.27016 11.34L6.16016 7.5L2.27016 3.61C2.10535 3.44876 2.0087 3.23041 2.00016 3C2.00016 2.73478 2.10552 2.48043 2.29306 2.29289C2.48059 2.10536 2.73495 2 3.00016 2C3.24016 2.003 3.47016 2.1 3.64016 2.27Z"
                    fill="black"
                  />
                </svg>
              </Button>
              <div className="position-relative searchForm icon-with-text">
                <input
                  type="Search"
                  placeholder="Search...."
                  className="form-control border-0 rounded-0 bg-transparent"
                  value={searchInput}
                  onChange={(e) => handleSearch(e)}
                />
                <span className="icn position-absolute d-inline-block bg-light">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </Form>
          ) : (
            <div className="d-flex justify-content-between align-items-center px-4">
              <h4 className="text-bold">
                <span onClick={() => navigate(-1)} className="pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12L11 6M5 12L11 18M5 12L19 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span style={{ marginLeft: "7px" }}>{t("COLLECTIONS")}</span>
              </h4>

              <h6 className="d-flex align-items-center">
                <span className="mx-3" onClick={() => {setShowSearch(true)
                setTab("userList")}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M15.3536 14.6465L11.1614 10.4544C12.0557 9.39963 12.5969 8.0365 12.5969 6.54862C12.5968 3.21338 9.8835 0.5 6.54838 0.5C3.21325 0.5 0.5 3.21338 0.5 6.54862C0.5 9.88375 3.21325 12.597 6.54838 12.597C8.03638 12.597 9.39963 12.0559 10.4543 11.1615L14.6465 15.3536C14.7441 15.4513 14.8721 15.5001 15.0001 15.5001C15.1281 15.5001 15.256 15.4513 15.3538 15.3536C15.4475 15.2598 15.5001 15.1326 15.5001 15C15.5001 14.8674 15.4474 14.7403 15.3536 14.6465ZM1.5 6.54862C1.5 3.76475 3.76463 1.5 6.54838 1.5C9.33212 1.5 11.5969 3.76475 11.5969 6.54862C11.5969 9.33237 9.33212 11.597 6.54838 11.597C3.76463 11.5969 1.5 9.33225 1.5 6.54862Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span
                  className="collPlus pointer "
                  onClick={() => setShowAddList(true)}
                >
                  +
                </span>
              </h6>
            </div>
          )}

          <div className="CardBody py-3 commonTabs2">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="LikePosts"
              className="d-flex justify-content-between align-items-center"
            >
              <Nav variant="pills" className="">
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small"
                    eventKey="userList"
                    onClick={() => handleTabSelectedtion("userList")}
                    active={tab === "userList" || tab === "commentPost"}
                  >
                    {t("USER LISTS")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small "
                    eventKey="bookmark"
                    onClick={() => {
                      handleTabSelectedtion("bookmark");
                      getAllBookmarkedPost();
                      setActiveBoxx("bookmark");
                    }}
                    active={tab === "bookmark"}
                  >
                    {t("BOOKMARKS")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small "
                    eventKey="Postlabels"
                    onClick={() => handleTabSelectedtion("postlabels")}
                    active={tab === "postlabels"}
                  >
                    POST LABELS
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
          <Tab.Content className="">
            <Tab.Pane
              eventKey={"userList" || "commentPost"}
              active={tab === "userList" || tab === "commentPost"}
            >
              <UserList
                setUserList={setUserList}
                setPostList={setPostList}
                allPosts={allPosts?.list}
                allUsers={list}
                showAll={showAll}
                setShowBlockedList={setShowBlockedList}
                handleFollowing={handleFollowing}
                handleRestriction={handleRestriction}
                handleBlocked={handleBlocked}
                handleGroupList={handleGroupList}
                activeBoxx={activeBoxx}
                setActiveBoxx={setActiveBoxx}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="bookmark"
              active={tab === "bookmark"}
              onClick={() => handleTabSelectedtion("bookmark")}
            >
              <Bookmarks setPostList={setPostList} />
            </Tab.Pane>
            <Tab.Pane
              eventKey="postlabels"
              active={tab === "postlabels"}
              onClick={() => handleTabSelectedtion("postlabels")}
            >
              <Postlabels
                setPostLabelActiveBox={setPostLabelActiveBox}
                postLabelActiveBox={postLabelActiveBox}
                archiveItemCount={archiveItemCount}
                privateArchiveItemCount={privateArchiveItemCount}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Col>
      <Col lg="7" md="12" sm="12">
        {tab === "bookmark" && (
          <div className="shadow1 rounded border h-100 py-4 border_hide">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h4 className="text-bold">
                <span onClick={() => navigate(-1)} className="pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12L11 6M5 12L11 18M5 12L19 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span style={{ marginLeft: "10px" }}>{t("ALL BOOKMARK")}</span>
              </h4>

              <h6 className="d-flex align-items-center">
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g opacity="0.5" clip-path="url(#clip0_96_11791)">
                      <path
                        d="M9 14C7.89542 14 6.99998 14.8954 6.99998 16C6.99998 17.1046 7.89542 18 9 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9 14Z"
                        fill="black"
                      />
                      <path
                        d="M9 6.99997C7.89542 6.99997 6.99998 7.89541 6.99998 8.99998C6.99998 10.1046 7.89542 11 9 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 9 6.99997Z"
                        fill="black"
                      />
                      <path
                        d="M9 -3.21229e-05C7.89542 -3.22195e-05 6.99998 0.895405 6.99998 1.99998C6.99998 3.10456 7.89542 4 9 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 9 -3.21229e-05Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_96_11791">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(18 18) rotate(-180)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </h6>
            </div>
            <div className="CardBody py-3 commonTabs">
              <div className="bookmarkScroll">
                <AllBookmarks
                  postList={postList}
                  getAllBookmarkedPost={getAllBookmarkedPost}
                />
              </div>
            </div>
          </div>
        )}
        {(tab === "userList" || tab === "commentPost") && (
          <div className="shadow1 rounded  border-2 border-danger h-100   py-4 ">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h4 className="text-bold">
                <span onClick={() => navigate(-1)} className="pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12L11 6M5 12L11 18M5 12L19 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span style={{ marginLeft: "10px" }}>{t("FOLLOWING")}</span>
              </h4>
              <h6 className="d-flex align-items-center">
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g opacity="0.5" clip-path="url(#clip0_96_11791)">
                      <path
                        d="M9 14C7.89542 14 6.99998 14.8954 6.99998 16C6.99998 17.1046 7.89542 18 9 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9 14Z"
                        fill="black"
                      />
                      <path
                        d="M9 6.99997C7.89542 6.99997 6.99998 7.89541 6.99998 8.99998C6.99998 10.1046 7.89542 11 9 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 9 6.99997Z"
                        fill="black"
                      />
                      <path
                        d="M9 -3.21229e-05C7.89542 -3.22195e-05 6.99998 0.895405 6.99998 1.99998C6.99998 3.10456 7.89542 4 9 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 9 -3.21229e-05Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_96_11791">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(18 18) rotate(-180)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </h6>
            </div>
            <div className="CardBody py-3 commonTabs">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="userList"
                className="d-flex justify-content-between align-items-center"
              >
                <Nav variant="pills" className="">
                  <Nav.Item className="">
                    <Nav.Link
                      className="bg-transparent border-0 py-3 px-2 position-relative text-small"
                      eventKey={"userList"}
                      onClick={() => handleTabSelectedtion("userList")}
                      active={tab === "userList"}
                    >
                      {t("USERS")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="">
                    <Nav.Link
                      className="bg-transparent border-0 py-3 px-2 position-relative text-small "
                      eventKey="commentPost"
                      onClick={() => handleTabSelectedtion("commentPost")}
                      active={tab === "commentPost"}
                    >
                      {t("POSTS")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
            <Tab.Content className="collectionScrol">
              <Tab.Pane eventKey="userList" active={tab === "userList"}>
                <Users
                  userList={userList}
                  showBlockedList={showBlockedList}
                  commonCall={commonCall}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="commentPost" active={tab === "commentPost"}>
                <Posts postList={postList} commonCall={commonCall} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        )}
        {tab === "postlabels" && (
          <div className="shadow1 rounded border h-100 border_hide">
            <div className="d-flex justify-content-between align-items-center px-4"></div>
            <div className="CardBody commonTabs">
              <div className="bookmarkScroll">
                {postLabelActiveBox === "archive" && (
                  <Achrive
                    title="ARCHIVE"
                    getArchivePostList={getArchivePostList}
                    commonFunction={commonFunction}
                  />
                )}
                {postLabelActiveBox === "privateArchive" && (
                  <PrivateArchive
                    title="PRIVATE ARCHIVE"
                    getPrivateArchivePostList={getPrivateArchivePostList}
                    commonFunction={commonFunction}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <AddList
          show={showAddList}
          setShowAddList={setShowAddList}
          selectedArtist={{}}
          commonCall={commonCall}
        />
      </Col>
    </Row>
  );
};

export default EntertainerCollectionComponent;
