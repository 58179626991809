import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteChat } from "../../../redux/actions";
import { getTimeAgo } from "../../../helpers/time";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ConfirmDeleteChat from "../../common/Modal/ConfirmDeleteChat";
import { Button, Form } from "react-bootstrap";
import { clearChatHistory } from "../../../redux/states/messages/slice";

const ChatList = (props) => {
  const { isNewChat, getChatList, setIsNewChat, allFollowedArtist } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { artistId, chatId } = useParams();
  const { chatLists, lastMessage } = useSelector((s) => s.message);
  const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);

  const { user } = useSelector((s) => s.login);
  const userId = user?._id;
  const [activeTab, setActiveTab] = useState("all");
  const [unreadList, setUnreadList] = useState();
  const [list, setList] = useState([]);
  const [chatSelected, setChatSelected] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [followerListToBeShown, setFollowerListToBeShown] = useState([]);
  const { role } = useSelector((s) => s.setting);

  useEffect(() => {
    const unreadMsg = chatLists?.filter((itm) => {
      return itm?.isReadMessage === false;
    });
    setUnreadList(unreadMsg);
    setList(chatLists);
  }, [chatLists]);

  const showAllMsg = () => {
    setList(chatLists);
    setActiveTab("all");
  };

  const handleChat = (dta) => {
    setIsNewChat(true);
    const path = generatePath("/messages/:artistId/chat/:chatId", {
      artistId: dta?.userId === userId ? dta?.otherUserId : dta?.userId,
      chatId: dta?._id,
    });
    navigate(path);
  };

  useEffect(() => {
    if (!artistId) {
      // if (list?.length != 0) {
      //   const dta = list?.[0];
      //   const path = generatePath("/messages/:artistId/chat/:chatId", {
      //     artistId: dta?.userId === userId ? dta?.otherUserId : dta?.userId,
      //     chatId: dta?._id,
      //   });
      //   navigate(path);
      // }
    } else if (artistId) {
      const selectedArtist = list.find(
        (item) => item?.userId === artistId || item?.otherUserId === artistId
      );
      if (selectedArtist) {
        const path = generatePath("/messages/:artistId/chat/:chatId", {
          artistId:
            selectedArtist?.userId === userId
              ? selectedArtist?.otherUserId
              : selectedArtist?.userId,
          chatId: selectedArtist?._id,
        });
        navigate(path);
      }
    }
  }, [isNewChat, list]);

  const handleChat2 = (dta) => {
    const path = generatePath("/messages/:artistId", { artistId: dta?._id });
    navigate(path);
  };

  const deleteSelectedChat = () => {
    dispatch(deleteChat(chatId))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getChatList();
        setChatSelected({});
        setShowDeleteChatModal(false);
        dispatch(clearChatHistory());
      })
      .catch((err) => {
        setShowDeleteChatModal(false);
        toast.error(err?.message);
      });
  };

  const [chatSearch, setChatSearch] = useState();
  const handleChatSearch = () => {
    setChatSearch(!chatSearch);
  };

  const handleUserSearch = (e) => {
    const val = e.target.value;
    setSearchInput(val);
    const filteredData = chatLists.filter((chat, index) => {
      const userDetails =
        chat?.userId === userId ? chat?.otherUserDetails : chat?.userDetails;
      if (userDetails?.name?.toLowerCase().includes(val?.toLowerCase())) {
        return chat;
      }
    });
    setList(filteredData);
    const filteredData2 = allFollowedArtist?.filter((user, index) => {
      if (user?.name?.toLowerCase().includes(val?.toLowerCase())) {
        const id = user?._id;
        const dta = list?.find((item) => {
          return item?.userId === id || item?.otherUserId === id;
        });
        if (!dta) {
          return user;
        }
      }
    });

    setFollowerListToBeShown(filteredData2);

    if (val === "") {
      setList(chatLists);
      setFollowerListToBeShown(allFollowedArtist);
    }
  };

  useEffect(() => {
    setFollowerListToBeShown(allFollowedArtist);
  }, [allFollowedArtist]);

  return (
    <div className="shadow1 rounded border h-100 border_hide">
      <div className="chatTop border-bottom py-2 px-3">
        <div
          className="d-flex justify-content-between align-items-center border-bottom"
          style={{ height: "50px" }}
        >
          {chatSearch ? (
            <>
              <Form className=" w-100 position-relative h-100">
                <Button
                  onClick={handleChatSearch}
                  className="border-0 p-0 position-absolute"
                  variant="transparent"
                  style={{ left: "0", top: "0", zIndex: "9" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M3.64016 2.27L7.50016 6.13L11.3402 2.29C11.425 2.19972 11.5272 2.12749 11.6406 2.07766C11.754 2.02783 11.8763 2.00141 12.0002 2C12.2654 2 12.5197 2.10536 12.7073 2.29289C12.8948 2.48043 13.0002 2.73478 13.0002 3C13.0025 3.1226 12.9797 3.24439 12.9333 3.35788C12.8869 3.47138 12.8178 3.57419 12.7302 3.66L8.84016 7.5L12.7302 11.39C12.895 11.5512 12.9916 11.7696 13.0002 12C13.0002 12.2652 12.8948 12.5196 12.7073 12.7071C12.5197 12.8946 12.2654 13 12.0002 13C11.8727 13.0053 11.7456 12.984 11.6268 12.9375C11.508 12.8911 11.4002 12.8204 11.3102 12.73L7.50016 8.87L3.65016 12.72C3.56567 12.8073 3.46473 12.8769 3.35316 12.925C3.2416 12.9731 3.12163 12.9986 3.00016 13C2.73495 13 2.48059 12.8946 2.29306 12.7071C2.10552 12.5196 2.00016 12.2652 2.00016 12C1.99783 11.8774 2.02058 11.7556 2.06701 11.6421C2.11344 11.5286 2.18257 11.4258 2.27016 11.34L6.16016 7.5L2.27016 3.61C2.10535 3.44876 2.0087 3.23041 2.00016 3C2.00016 2.73478 2.10552 2.48043 2.29306 2.29289C2.48059 2.10536 2.73495 2 3.00016 2C3.24016 2.003 3.47016 2.1 3.64016 2.27Z"
                      fill="black"
                    />
                  </svg>
                </Button>
                <div className="position-relative searchForm icon-with-text">
                  <input
                    type="Search"
                    placeholder="Search...."
                    className="form-control border-0 rounded-0"
                    value={searchInput}
                    onChange={(e) => handleUserSearch(e)}
                  />
                  <span className="icn position-absolute d-inline-block ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </div>
              </Form>
            </>
          ) : (
            <>
              <div className="d-flex align-items-center msgTitle gap-10 justify-content-between">
                <span onClick={() => navigate("/home")} className="pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12L11 6M5 12L11 18M5 12L19 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <h6 className="mb-0 ms-0">{t("Message")}</h6>
              </div>
              <div className="right">
                <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10">
                  {role === "ARTIST" && (
                    <li className="">
                      <Link to="/queue" className="border-0 p-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M1.6665 15.8333C1.6665 17.25 2.74984 18.3333 4.1665 18.3333H15.8332C17.2498 18.3333 18.3332 17.25 18.3332 15.8333V9.16662H1.6665V15.8333ZM15.8332 3.33329H14.1665V2.49996C14.1665 1.99996 13.8332 1.66663 13.3332 1.66663C12.8332 1.66663 12.4998 1.99996 12.4998 2.49996V3.33329H7.49984V2.49996C7.49984 1.99996 7.1665 1.66663 6.6665 1.66663C6.1665 1.66663 5.83317 1.99996 5.83317 2.49996V3.33329H4.1665C2.74984 3.33329 1.6665 4.41663 1.6665 5.83329V7.49996H18.3332V5.83329C18.3332 4.41663 17.2498 3.33329 15.8332 3.33329Z"
                            fill="black"
                          />
                        </svg>
                      </Link>
                    </li>
                  )}
                  {role === "ARTIST" && (
                    <li className="">
                      <Link to="/mass-chats" className="border-0 p-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M13.3332 9.81667L16.8665 3.70833L18.3082 4.54167L13.9498 12.0833L8.52484 8.95833L4.54984 15.8333H18.3332V17.5H1.6665V2.5H3.33317V14.6167L7.9165 6.66667L13.3332 9.81667Z"
                            fill="black"
                          />
                        </svg>
                      </Link>
                    </li>
                  )}
                  <li className="">
                    <Button
                      onClick={handleChatSearch}
                      variant="transparent"
                      className="border-0 p-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </li>
                  {role === "ARTIST" && (
                    <li className="">
                      <Link to="/messages/new" className="border-0 p-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_10_25)">
                            <path
                              d="M8.75016 16.6667C8.75016 16.9982 8.88186 17.3162 9.11628 17.5506C9.3507 17.785 9.66864 17.9167 10.0002 17.9167C10.3317 17.9167 10.6496 17.785 10.884 17.5506C11.1185 17.3162 11.2502 16.9982 11.2502 16.6667V11.25H16.6668C16.9984 11.25 17.3163 11.1183 17.5507 10.8839C17.7851 10.6495 17.9168 10.3316 17.9168 10C17.9168 9.66852 17.7851 9.35058 17.5507 9.11616C17.3163 8.88174 16.9984 8.75004 16.6668 8.75004H11.2502V3.33337C11.2502 3.00185 11.1185 2.68391 10.884 2.44949C10.6496 2.21507 10.3317 2.08337 10.0002 2.08337C9.66864 2.08337 9.3507 2.21507 9.11628 2.44949C8.88186 2.68391 8.75016 3.00185 8.75016 3.33337V8.75004H3.3335C3.00198 8.75004 2.68403 8.88174 2.44961 9.11616C2.21519 9.35058 2.0835 9.66852 2.0835 10C2.0835 10.3316 2.21519 10.6495 2.44961 10.8839C2.68403 11.1183 3.00198 11.25 3.3335 11.25H8.75016V16.6667Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_10_25">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center justify-content-center">
            <h6
              className={`text-small msgTab pointer m-0 ${
                activeTab === "all" && ""
              }`}
              onClick={showAllMsg}
            >
              {t("All")} {chatLists?.length}{" "}
            </h6>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <div className=" msgEdit" onClick={() => navigate("/messages/new")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M7.03 4.51L7.49 4.97L2.96 9.5H2.5V9.04L7.03 4.51ZM8.83 1.5C8.705 1.5 8.575 1.55 8.48 1.645L7.565 2.56L9.44 4.435L10.355 3.52C10.4014 3.47374 10.4381 3.4188 10.4632 3.35831C10.4883 3.29783 10.5012 3.23298 10.5012 3.1675C10.5012 3.10202 10.4883 3.03717 10.4632 2.97669C10.4381 2.9162 10.4014 2.86126 10.355 2.815L9.185 1.645C9.085 1.545 8.96 1.5 8.83 1.5ZM7.03 3.095L1.5 8.625V10.5H3.375L8.905 4.97L7.03 3.095Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="three_dot_dropdown"></div>
          </div>
        </div>
      </div>

      {/* chat list */}
      <div className="chatCont">
        {list?.map((itm, index) => {
          const userDetails =
            itm?.userId === userId ? itm?.otherUserDetails : itm?.userDetails;
          const newMsgCount =
            itm?.userId === userId
              ? itm?.userUnreadMessagesCount
              : itm?.otherUserUnreadMessagesCount;

          return (
            <Fragment key={index}>
              <div
                className={`my-2 px-2 d-flex justify-content-between align-items-center pointer  py-2 ${
                  artistId === userDetails?._id && "shadow2 activetab"
                }`}
                key={index}
                onClick={() => handleChat(itm)}
              >
                <div className="d-flex align-items-center">
                  {/* <img
                    src={userDetails?.profileImage || User}
                    alt=""
                    className="mx-2"
                  /> */}
                  {userDetails?.profileImage ? (
                    <div className="sidebar_img mx-2">
                      <img src={userDetails?.profileImage} alt="" />
                    </div>
                  ) : (
                    <div className="sidebar_img shadow2 mx-2">
                      {userDetails?.firstName && userDetails?.lastName
                        ? userDetails?.firstName?.slice(0, 1) +
                          userDetails?.lastName?.slice(0, 1)
                        : userDetails?.displayName?.slice(0, 1) ||
                          userDetails?.name?.slice(0, 1)}
                    </div>
                  )}
                  <div className="">
                    <div className="d-flex align-items-center gap-2">
                      <h5 className="msgUserName capitalize">
                        {userDetails?.firstName &&
                          userDetails?.firstName +
                            " " +
                            (userDetails?.lastName || " ")}
                      </h5>
                      <p className="user_nameside m-0">
                        @{userDetails?.userName}
                      </p>
                    </div>

                    {itm?.byCustomer === false &&
                    itm?.isReadMessage === false ? (
                      <h6 className="text-small text-primary">
                        {itm?.lastMessage}
                      </h6>
                    ) : (
                      <h6 className="userchat">
                        {index === 0
                          ? lastMessage || itm?.lastMessage
                          : itm?.lastMessage}
                      </h6>
                    )}
                  </div>
                </div>
                <div className="chatTime d-flex flex-column justify-content-end">
                  <div className="d-flex align-items-center justify-content-end">
                    {newMsgCount > 0 && (
                      <h6 className="msgCounts text-light  rounded-lg text-sm mx-2">
                        {newMsgCount}
                      </h6>
                    )}
                    <button
                      className="border-0 bg-white userchat_close"
                      onClick={() => {
                        setChatSelected(itm);
                        setShowDeleteChatModal(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </button>
                  </div>

                  <h6 className="userchat">{getTimeAgo(itm?.createdAt)}</h6>
                </div>
              </div>
            </Fragment>
          );
        })}

        {followerListToBeShown?.map((itm, index) => {
          const userDetails = itm;
          return (
            <>
              <div
                className={`my-2 px-2 d-flex justify-content-between align-items-center  pointer  py-2 ${
                  artistId === userDetails?._id && "shadow2 activetab"
                }`}
                key={index}
                onClick={() => handleChat2(userDetails)}
              >
                <div className="d-flex align-items-center">
                  {/* <img
                    src={userDetails?.profileImage || User}
                    alt=""
                    className="mx-2"
                  /> */}

                  {userDetails?.profileImage ? (
                    <div className="sidebar_img mx-2">
                      <img src={userDetails?.profileImage} alt="" />
                    </div>
                  ) : (
                    <div
                      className="user_img_name shadow2 mx-2"
                      style={{
                        height: "50px",
                        width: "50px",
                        fontSize: "16px",
                      }}
                    >
                      {userDetails?.firstName && userDetails?.lastName
                        ? userDetails?.firstName?.slice(0, 1) +
                          userDetails?.lastName?.slice(0, 1)
                        : userDetails?.displayName?.slice(0, 1) ||
                          userDetails?.name?.slice(0, 1)}
                    </div>
                  )}
                  <div className="">
                    <div className="d-flex align-items-center gap-2">
                      <h5 className="msgUserName">
                        {userDetails?.firstName
                          ? userDetails?.firstName +
                            " " +
                            (userDetails?.lastName || "")
                          : userDetails?.name}
                      </h5>
                      <p className="user_nameside m-0">
                        @{userDetails?.userName}
                      </p>
                    </div>
                    {itm?.byCustomer === false &&
                    itm?.isReadMessage === false ? (
                      <h6 className="text-small text-primary">
                        {itm?.lastMessage}
                      </h6>
                    ) : (
                      <h6 className="userchat">start chatting..</h6>
                    )}
                  </div>
                </div>
                <div className="chatTime d-flex flex-column justify-content-end">
                  {/* <button className="border-0 bg-white userchat">X</button> */}
                  {/* <h6 className="userchat">{getTimeAgo(itm?.createdAt)}</h6> */}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <ConfirmDeleteChat
        confirmDelete={deleteSelectedChat}
        show={showDeleteChatModal}
        onHide={() => setShowDeleteChatModal(false)}
      />
    </div>
  );
};

export default ChatList;
