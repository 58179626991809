import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.uploadFile, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const getPrivacyPolicy = createAsyncThunk(
  "privacypolicy",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.common.privacyPolicy);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const getTermsCondition = createAsyncThunk(
  "termsCondition",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.common.termsCondition);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const planList = createAsyncThunk("planList", async (payload, Thunk) => {
  try {
    const response = await axios.post(endpoints.common.planlist);
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});

export const verifyEmail = createAsyncThunk(
  "verifyEmail",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.verifyEmail, {});
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const contactUs = createAsyncThunk(
  "contactUs",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.contactUs, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const fetchFaq = createAsyncThunk(
  "common/fetchFaq",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.fetchFaq, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const campaignDetail = createAsyncThunk(
  "common/campaignDetail",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.campaignDetail,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const announcementList = createAsyncThunk(
  "common/announcementList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.announcementList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const announcementRead = createAsyncThunk(
  "common/announcementRead",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.announcementRead + payload.id,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const subscriptionDetails = createAsyncThunk(
  "common/subscriptionDetails",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.subscriptionDetail,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
