// This is the username section inside the acount section;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import { getProfile, updateProfile } from "../../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const LoginSession = () => {

  const {t} = useTranslation()

  const { user, buttonLoader } = useSelector((s) => s.setting);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userName: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      userName: Yup.string().required(t("userName is required")),
    }),
  });

  useEffect(() => {
    formik.setFieldValue("userName", user.userName);
  }, [user]);

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("LOGIN SESSIONS")}</span>
        </h4>
      </div>
      <div className="endline my-2"></div>
      <div className="mx-4">
        <div className="px-3 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-small">Chrome 116.0, Mac 10.15, Apple</h6>
            <h6 className="text-small text-primary">Active Now</h6>
          </div>
          <h6 className="light-text">14.99.95.122 • India</h6>
        </div>
      </div>
      <div className="endline "></div>
      <dix className="px-4 d-flex justify-content-center">
        <button className="loginBtn text-light " style={{ width: "94%" }}>
          {t("CLOSE ALL SESSIONS")}
        </button>
      </dix>
    </Settings>
  );
};

export default LoginSession;
