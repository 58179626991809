import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Storymodal from "../../common/Modal/EntertainerModal/Storymodal";
import { BsPlusCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createPost, getStoryList, uploadFile } from "../../../redux/actions";
import User from "../../../images/user.png";
import SmallLoader from "../ButtonLoader/SmallLoader";
import PinToHomePop from "../Modal/PinToHomePop";
import StoryPromotionPop from "../Modal/StoryPromotionPop";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StoryViewerModal from "../Modal/StoryViewerModal";

const Addstory = (props) => {
  const { t } = useTranslation();
  const { storyList } = useSelector((s) => s.home);
  const [storymod, setstorymod] = useState(false);
  const [storyimg, setStoryImages] = useState([]);
  const { buttonLoader } = useSelector((s) => s.post);
  const { imageLoader } = useSelector((s) => s.common);
  const { user } = useSelector((s) => s.setting);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState({});
  const [showStoryViewerModal, setShowStoryViewerModal] = useState(false);

  const [allStoryUser, setAllStoryUser] = useState([]);

  useEffect(() => {
    const data = Object.keys(storyList);
    setAllStoryUser(data);
  }, [storyList]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const makeStoryLive = (url, type) => {
    const storyData = {
      type: type,
      videoKey: "thumbnail",
      url: url,
    };
    setSelectedMedia(storyData);
    setShowStoryViewerModal(true);
  };

  const uploadStory = (e) => {
    const files = e.target?.files?.[0];
    const fileType = files.type;
    const formdata = new FormData();
    formdata.append("imageProfile", files);
    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        const url = res?.data?.url;
        if (fileType.startsWith("image/")) {
          makeStoryLive(url, "image");
        } else if (fileType.startsWith("video/")) {
          makeStoryLive(url, "video");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getAllStory = () => {
    const data = {
      limit: 20,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["story"],
    };
    dispatch(getStoryList(data));
  };

  useEffect(() => {
    getAllStory();
  }, []);

  const handleStoryShow = (dta) => {
    setStoryImages(dta);
    setstorymod(true);
  };
  const [pinPop, setPinPop] = useState();

  const [StoryPromotion, setStoryPromotion] = useState();

  const submitStory = () => {
    const data = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      postType: "story",
      media: [selectedMedia],
    };

    dispatch(createPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Story posted"));
        getAllStory();
        setSelectedMedia({});
        setShowStoryViewerModal(false);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <>
      <Storymodal
        show={storymod}
        onhide={() => setstorymod(false)}
        data={storyimg}
        getAllStory={getAllStory}
      />
      <PinToHomePop pinPop={pinPop} setPinPop={setPinPop} />
      <StoryPromotionPop
        StoryPromotion={StoryPromotion}
        setStoryPromotion={setStoryPromotion}
      />
      <StoryViewerModal
        show={showStoryViewerModal}
        mediaType={selectedMedia?.type}
        url={selectedMedia?.url}
        onHide={() => setShowStoryViewerModal(false)}
        submitStory={submitStory}
        setSelectedMedia={setSelectedMedia}
      />
      <section className="add_story my-3 text-center px-3 px-lg-0">
        <div className="story_slider_here mt-3">
          <Slider {...settings}>
            <div className="p-1">
              <div className="add_story_card">
                <div className="storyprofile_Show border position-relative">
                  <label
                    htmlFor="uploadStory"
                    className="w-full h-100 d-flex justify-content-center align-items-center"
                  >
                    {imageLoader || buttonLoader ? (
                      <SmallLoader color={"text-primary"} />
                    ) : (
                      <>
                        {/* <img
                        src={user?.profileImage || User}
                       alt=""
                       className="img-fluid"
                      /> */}

                        {user?.profileImage ? (
                          <div
                            className="user_img"
                            style={{ height: "100%", width: "100%" }}
                          >
                            <img
                              src={user?.profileImage}
                              alt=""
                              className="img-fluid "
                            />
                          </div>
                        ) : (
                          <div
                            className="user_img_name shadow2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {user.firstName && user?.lastName
                              ? user?.firstName?.slice(0, 1) +
                                user?.lastName?.slice(0, 1)
                              : user.displayName?.slice(0, 1) ||
                                user.name?.slice(0, 1)}
                          </div>
                        )}
                      </>
                    )}
                    <BsPlusCircle
                      color="black"
                      size={23}
                      className="bg-light rounded-circle pointer t-4 plus-iconss"
                    />
                  </label>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="uploadStory"
                    onChange={(e) => uploadStory(e)}
                    accept="image/*,video/*"
                  />
                </div>
                <h4 className="addstroy_head">{t("Add to story")}</h4>
              </div>
            </div>
            <div className="p-1">
              <div
                className="add_story_card pointer"
                onClick={() => navigate("/settings/subscription-price-bundle")}
              >
                <div className="storyprofile_Show border position-relative">
                  <label className="w-full h-100 d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_7_17)">
                        <path
                          d="M20.0399 8.70994V3.99994H15.3399L11.9999 0.689941L8.70994 3.99994H3.99994V8.70994L0.689941 11.9999L3.99994 15.3399V20.0399H8.70994L11.9999 23.3499L15.3399 20.0399H20.0399V15.3399L23.3499 11.9999L20.0399 8.70994ZM8.82994 7.04994C9.80994 7.04994 10.5999 7.83994 10.5999 8.82994C10.5999 9.29937 10.4135 9.74958 10.0815 10.0815C9.74958 10.4135 9.29937 10.5999 8.82994 10.5999C7.83994 10.5999 7.04994 9.80994 7.04994 8.82994C7.04994 7.83994 7.83994 7.04994 8.82994 7.04994ZM15.2199 16.9999C14.2399 16.9999 13.4499 16.1999 13.4499 15.2199C13.4499 14.7505 13.6364 14.3003 13.9684 13.9684C14.3003 13.6364 14.7505 13.4499 15.2199 13.4499C16.1999 13.4499 16.9999 14.2399 16.9999 15.2199C16.9999 15.692 16.8124 16.1448 16.4786 16.4786C16.1448 16.8124 15.692 16.9999 15.2199 16.9999ZM8.49994 17.0299L6.99994 15.5299L15.5299 6.99994L17.0299 8.49994L8.49994 17.0299Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7_17">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </label>
                </div>
                <h4 className="addstroy_head">
                  {t("Start Profile Promotion")}
                </h4>
              </div>
            </div>

            {allStoryUser?.map((artist, idx) => {
              const userDetails = storyList?.[artist]?.[0]?.userDetails;
              return (
                <div className="p-1">
                  <div
                    key={idx}
                    className="add_story_card pointer"
                    onClick={() => handleStoryShow(storyList?.[artist])}
                  >
                    <div className="storyprofile_Show border ">
                      <img
                        src={userDetails?.profileImage || User}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h4 className="addstroy_head">{userDetails?.name}</h4>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Addstory;
