import React from "react";
import Earnings from "./Earnings";
import Paymentrqst from "./Paymentrqst";
import Earningsstatics from "./Earningsstatics";
import Referrals from "./Referrals";
import { useTranslation } from "react-i18next";

export default function Vaultdata({ isShowData }) {
  const { t } = useTranslation();
  return (
    <section className="Dashboradright-main text-start">
      {isShowData === 1 && <Earnings title={t("Earning")} />}
      {isShowData === 2 && <Paymentrqst title={t("Payout Requests")} />}
      {isShowData === 3 && <Earningsstatics title={t("Earning Statistics")} />}
      {isShowData === 4 && (
        <Referrals title={t("Referral Earnings Statement")} />
      )}
    </section>
  );
}
