import React from 'react'
import MainLayout from '../../../layout/MainLayout'
import AddBankComponent from '../../../components/SideTabs/AddBank'

const AddBank = () => {
  return (
  <MainLayout>
    <AddBankComponent /> 
  </MainLayout>
  )
}

export default AddBank