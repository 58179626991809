import { Button, Modal } from "react-bootstrap";
import { BiCamera, BiCameraOff } from "react-icons/bi";
import {
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
} from "react-icons/bs";
import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import { saveAs } from "file-saver";
import ReactPlayer from "react-player";
import { GrResume, GrPause } from "react-icons/gr";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsFillPlayCircleFill } from "react-icons/bs";
import SmallLoader from "../../ButtonLoader/SmallLoader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const VideoRecorder = (props) => {
  const { t } = useTranslation();
  const { videoData, setVideoData, saveVideo } = props;
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isRecordingPause, setIsRecordingPause] = useState(false);
  const [showCamera, setShowCamera] = useState(true);
  const [showDownloadedVideo, setShowDownloadedVideo] = useState(false);
  const { imageLoader } = useSelector((s) => s.common);

  const handleToggleCamera = () => {
    if (webcamRef.current) {
      const videoTrack = webcamRef.current.video.srcObject.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
    }
    setShowCamera(!showCamera);
  };

  const handleStartCapture = () => {
    setVideoData([]);

    const webcam = webcamRef?.current;
    if (!webcam || !webcam.stream) {
      toast.warning(t("Webcam not available or not connected."));
      return;
    }
    const mediaRecorder = new MediaRecorder(webcam.stream);

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        setVideoData((prev) => [...prev, e.data]);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(videoData, { type: "video/webm" });
    };

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();
    setCapturing(true);
  };

  const handleStopCapture = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setCapturing(false);
    setTimeout(() => {
      setPlaying(true);
      setShowDownloadedVideo(true);
    }, 1000);
  };

  const handleToggleRecording = () => {
    if (isRecordingPause) {
      mediaRecorderRef.current.resume();
    } else {
      mediaRecorderRef.current.pause();
    }
    setIsRecordingPause(!isRecordingPause);
  };

  const closeVideoRecorder = () => {
    setShowCamera(true);
    setIsRecordingPause(false);
    setPlaying(false);
    setShowDownloadedVideo(false);
    setCapturing(false);
    setVideoData([]);
    props.onhide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="videoPop"
    >
      <Modal.Body className="videoRecorderContainer">
        <div className="calednder_Mod">
          <div className="d-flex justify-content-between align-items-center">
            <p>{t("Video Recorder")}</p>

            <div className="d-flex justify-content-center align-items">
              {showDownloadedVideo && (
                <>
                  {imageLoader ? (
                    <Button className="mx-3">
                      <SmallLoader />
                    </Button>
                  ) : (
                    <Button className="mx-3" onClick={saveVideo}>
                      {t("Save")}
                    </Button>
                  )}
                </>
              )}
              <Button
                className="border-0 p-0  position-absolute closeBtn"
                style={{ top: "10px", right: "10px" }}
                variant="transparent"
                onClick={closeVideoRecorder}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M12.2812 1.71875L1.71875 12.2812M1.71875 1.71875L12.2812 12.2812"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </div>
          {!showDownloadedVideo && (
            <>
              <div className="callWrpper">
                <Webcam ref={webcamRef} className="img-fluid" />
              </div>

              <div className="d-flex align-items-center justify-content-center gap-10 callBtns">
                {showCamera && (
                  <Button
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    onClick={handleToggleCamera}
                  >
                    <BiCamera size={25} color="white" />
                  </Button>
                )}
                {!showCamera && (
                  <Button
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    onClick={handleToggleCamera}
                  >
                    <BiCameraOff size={25} color="white" />
                  </Button>
                )}
                {capturing && (
                  <Button
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    onClick={handleStopCapture}
                  >
                    <BsFillCameraVideoFill size={25} color="white" />
                  </Button>
                )}
                {!capturing && (
                  <Button
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    onClick={handleStartCapture}
                  >
                    <BsFillCameraVideoOffFill size={25} color="white" />
                  </Button>
                )}

                {capturing && isRecordingPause && (
                  <Button
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    onClick={handleToggleRecording}
                  >
                    <GrResume size={25} color="white" />
                  </Button>
                )}
                {capturing && !isRecordingPause && (
                  <Button
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    onClick={handleToggleRecording}
                  >
                    <GrPause size={25} color="white" />
                  </Button>
                )}
              </div>
            </>
          )}
          {showDownloadedVideo && (
            <>
              <div className="callWrpper">
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  onContextMenu={e => e.preventDefault()}
                  url={URL.createObjectURL(
                    new Blob(videoData, { type: "video/webm" })
                  )}
                  controls
                  className="img-fluid custom-player"
                />
              </div>
              <div className="d-flex align-items-center justify-content-center gap-10 callBtns">
                <Button onClick={() => setShowDownloadedVideo(false)}>
                  {t("Retake")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VideoRecorder;
