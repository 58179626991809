import { connect } from "formik";
import moment from "moment/moment";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { connectStripe } from "../../../redux/states/card/thunk";
// import W9TaxForm from "./w9-tax-form";
import W9Form from "./w9-form";

const BankingPageComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.login?.user)?._id;
  const [loading, setLoading] = useState(false);

  // const handleSubmit = () => {
  //   dispatch(connectStripe(userId))
  //     .unwrap()
  //     .then((res) => {
  //       setLoading(false)
  //       const redirectUrl = res?.data;
  //       window.location.href = redirectUrl;
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //     });
  // };

  return (
    <Row className="  h-100 d-flex justify-content-between ">
      <Col lg="12" md="12" sm="12">
        <div className="shadow1 rounded border h-100 px-3 py-4 ">
          <div className="d-flex justify-content-between align-items-center ">
            <h4 className="text-bold">
              <span onClick={() => navigate(-1)} className="pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span style={{ marginLeft: "7px" }}>
                {t("Please fill the below form")}
              </span>
            </h4>
          </div>
          <div className="endline"></div>
          <div className="my-2">
            <W9Form
              // onSend={handleSubmit}
              onSend={(values) => console.log("first", values)}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BankingPageComponent;
