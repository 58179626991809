import React from "react";
import MainLayout from "../../../layout/MainLayout";
import StatementComponent from "../../../components/SideTabs/Statements";

const Statements = (props) => {
  return (
    <MainLayout>
      <StatementComponent />
    </MainLayout>
  );
};

export default Statements;
