import React, { Fragment, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthFooter from "../../../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../../components/common/ButtonLoader/SmallLoader";
import { contactUs } from "../../../redux/states/common/thunk";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { legalEnquiry } from "../../../redux/actions";
import { uploadFile } from "../../../redux/states/common/thunk";

export const LegalInquiryForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { buttonLoader } = useSelector((s) => s.common);
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      law_enforcement: "",
      description: "",
      address: "",
      isHarmIndividual: false,
      file: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(legalEnquiry(values))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          resetForm();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required").email("Invalid Email"),
      phone: Yup.string().required("Phone is required"),
      law_enforcement: Yup.string().required("Field is required"),
      description: Yup.string().required("Description is required"),
      address: Yup.string().required("Address is required"),
    }),
  });

  const handleFile = (e) => {
    const files = e.target.files[0];
    const formdata = new FormData();
    formdata.append("imageProfile", files);

    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        formik.setFieldValue("file", res?.data?.url);
        setFile(files);
        toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };


  return (
    <Fragment>
      <div className="endline my-3"></div>
      <div className="formCstm">
        <p className="text-sm text-center my-3" style={{ fontSize: "18px" }}>
          {t(
            "Note: Use of this contact form is limited exclusively to law enforcement agencies. If you are not a law enforcement representative, please contact us here: "
          )}
          <Link to="/contact">https://fansroom.com/contact</Link>
          {t(" or send an email to ")}
          <a href="mailto:support@fansroom.com">support@fansroom.com</a>
        </p>
        <div className="contactform">
          <Row>
            <Col lg="6" className="my-2">
              <div className="Login_input_warp form-floating">
                <input
                  type="text"
                  placeholder={t("Enter your name")}
                  class="form-control"
                  id="floatingInput"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name="name"
                  onBlur={formik.onBlur}
                />
                <label for="floatingInput">{t("Enter your name")}</label>
                {formik.touched.name && formik.errors.name && (
                  <span className="text-danger errorText">
                    {formik.errors.name}
                  </span>
                )}
              </div>
              <div className="Login_input_warp form-floating mt-3">
                <input
                  type="email"
                  placeholder={t("Enter your e-mail")}
                  class="form-control"
                  id="floatingInput"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  onBlur={formik.onBlur}
                />
                <label for="floatingInput">{t("Enter your e-mail")}</label>
                {formik.touched.email && formik.errors.email && (
                  <span className="text-danger errorText">
                    {formik.errors.email}
                  </span>
                )}
              </div>

              <div className="Login_input_warp form-floating mt-3">
                <input
                  type="text"
                  placeholder={t("Law enforcement agency")}
                  class="form-control"
                  id="floatingInput"
                  value={formik.values.law_enforcement}
                  onChange={formik.handleChange}
                  name="law_enforcement"
                  onBlur={formik.onBlur}
                />
                <label for="floatingInput">{t("Law enforcement agency")}</label>
                {formik.touched.law_enforcement &&
                  formik.errors.law_enforcement && (
                    <span className="text-danger errorText">
                      {formik.errors.law_enforcement}
                    </span>
                  )}
              </div>
              <div className="Login_input_warp form-floating mt-3">
                <textarea
                  type="text"
                  placeholder={t("Enter your e-mail")}
                  class="form-control"
                  id="floatingInput"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  name="address"
                  onBlur={formik.onBlur}
                  rows={5}
                />
                <label for="floatingInput">{t("Address")}</label>
                {formik.touched.address && formik.errors.address && (
                  <span className="text-danger errorText">
                    {formik.errors.address}
                  </span>
                )}
              </div>

              <div className="Login_input_warp mt-3">
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="uploadFile"
                  onChange={(e) => handleFile(e)}
                  accept=".png,.jpg,.jpeg,.gif,.pdf,.docx"
                />
                <label
                  htmlFor="uploadFile"
                  className="form-label m-0 text-muted"
                  style={{ opacity: 1, cursor: "pointer" }}
                >
                  Attach any relevant documentation (PNG, JPG, GIF, PDF, DOCX)
                </label>
                {formik.values.file &&
                  typeof formik.values.file === "string" && (
                    <div className="d-flex mt-2 align-items-center gap-10">
                      <p className="text-dark m-0 formText">
                        {file?.name?.split("/")?.pop()}
                      </p>
                      <Button
                        className="border-0 p-0 closeBtn rounded-circle d-flex align-itmes-center justify-content-center text-white"
                        onClick={() => (
                          formik.setFieldValue("file", ""), setFile(null)
                        )}
                      >
                        X
                      </Button>
                    </div>
                  )}
              </div>
            </Col>
            <Col lg="6" className="my-2">
              <div className="Login_input_warp">
                <div className="border rounded">
                  <PhoneInput
                    // disableCountryGuess={location.country?.toLowerCase()}
                    value={formik.values.phone}
                    onChange={(value, other) => {
                      formik.setFieldValue("phone", value);
                      formik.setFieldValue("countryCode", other.dialCode);
                    }}
                    className="phoneInputCont"
                  />
                </div>
                {formik.touched.phone && formik.errors.phone && (
                  <span className="text-danger errorText">
                    {formik.errors.phone}
                  </span>
                )}
              </div>
              <div className="Login_input_warp form-floating mt-3">
                <textarea
                  type="text"
                  placeholder={t("Description of Legal Inquiry")}
                  class="form-control"
                  id="floatingInput"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  onBlur={formik.onBlur}
                  rows={5}
                />
                <label for="floatingInput">
                  {t("Description of Legal Inquiry")}
                </label>

                {formik.touched.description && formik.errors.description && (
                  <span className="text-danger errorText">
                    {formik.errors.description}
                  </span>
                )}
              </div>
              <div className="Login_input_warp mt-3">
                <label
                  htmlFor=""
                  className="form-label m-0 text-muted"
                  style={{ opacity: "1" }}
                >
                  Does this inquiry involve a risk of imminent harm to an
                  individual
                </label>
                <ul className="list-unstyled ps-0 mb-0 d-flex mt-3 align-items-center gap-10">
                  <li className="w-50">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="yes/no"
                        className="form-check me-2"
                        checked={formik.values.isHarmIndividual}
                        onChange={() =>
                          formik.setFieldValue("isHarmIndividual", true)
                        }
                      />
                      <label htmlFor="" className="form-label m-0">
                        Yes
                      </label>
                    </div>
                  </li>
                  <li className="w-50">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="yes/no"
                        className="form-check  me-2"
                        checked={!formik.values.isHarmIndividual}
                        onChange={() =>
                          formik.setFieldValue("isHarmIndividual", false)
                        }
                      />
                      <label htmlFor="" className="form-label m-0">
                        No
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mx-2 mt-4 ">
            {buttonLoader ? (
              <button className="sendMsg">
                <SmallLoader />
              </button>
            ) : (
              <button className="sendMsg" onClick={formik.handleSubmit}>
                {t("Send")}
              </button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function LegalInquiry() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <MainLayout>
        <Row className="  h-100 align-items-start justify-content-between ">
          <Col lg="12" md="12" sm="12">
            <div className="shadow1 rounded border h-100 px-3 py-4 ">
              {" "}
              <div className="d-flex justify-content-between align-items-center px-2">
                <h4 className="text-bold">
                  <span onClick={() => navigate(-1)} className="pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 12L11 6M5 12L11 18M5 12L19 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ marginLeft: "7px" }}>
                    {t("LAW ENFORCEMENT CONTACT FORM")}
                  </span>
                </h4>
              </div>
              <LegalInquiryForm />
            </div>
          </Col>
        </Row>
      </MainLayout>
      <AuthFooter />
    </>
  );
}

export default LegalInquiry;
