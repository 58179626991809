import React from "react";
import { Fade } from "react-reveal";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Subscriptioncard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((s) => s.setting);
  return (
    <Fade duration={1000}>
      <section
        className="subscription_card card_boader pointer"
        onClick={() => navigate("/settings/subscription-price-bundle")}
      >
        <div className=" w-100 d-flex align-items-center justify-content-between py-3 px-4">
          <div className="subscription_head text-start">
            <h5 style={{ color: "#8a96a3", fontSize: "18px" }}>
              {t("SUBSCRIPTION")}
            </h5>
            <p>{t("Subscription price and promotions")}</p>
            <span>
              {user?.subscribePrice > 0
                ? "$" +
                  user?.subscribePrice +
                  " " +
                  t("per month") +
                  `${
                    user?.subscriptionBundles?.length > 0
                      ? ", " +
                        `${
                          user?.subscriptionBundles.length +
                          " " +
                          t("subscription bundles")
                        }`
                      : ""
                  }`
                : t("Free subscription")}
            </span>
          </div>
          <div className="arrow_subscription">
            <Link
              to="/settings/subscription-price-bundle"
              className="text-dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g opacity="0.5">
                  <path
                    d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                    fill="black"
                  />
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Subscriptioncard;
