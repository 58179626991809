// This is for the confirmation of deleting the list ;

import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SmallLoader from "../ButtonLoader/SmallLoader";
import Logo from "../../../images/logoW.png";
import Offcanvas from "react-bootstrap/Offcanvas";

const Notifymodal = (props) => {
  const { t } = useTranslation();
  const { loading, onHide, setNotifymod, show } = props;
  const { buttonLoader } = useSelector((s) => s.setting);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
      backdrop="static"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <div className="modal_buy-cont">
            <div className="notify_logo">
              <img src={Logo} alt="" className="img-fluid" />
            </div>
            <h5 className="fw-sbold mt-3 mb-1">
              {t("Age Restricted Content")}
            </h5>

            <div className="age-modal-content pt-2">
              <p className="fw-sbold small text-center">
                {t(
                  "This website (Fansroom) contains age-restricted content. If you are under the age of 18 years or under the age of majority in the location from where you are accessing this website, you do not have authorization or permission to enter this website or access any of its content. If you are over the age of 18 years or over the age of majority in the location from where you are accessing this website by entering the website you hereby agree to comply with the Fansroom Terms of Service. By clicking on the Enter button, and by entering this website you agree with all the above and certify under penalty of perjury that you are above the age of 18 or the age of majority in your location, whichever is greater."
                )}
              </p>
            </div>
            <div className="modal_buy_btn d-flex justify-content-end pt-2">
              <Button
                className="mod_cancelbtn blackBtn border-0"
                style={{ marginRight: "16px" }}
                onClick={onHide}
              >
                {t("Cancel")}
              </Button>
              {buttonLoader ? (
                <Button
                  className="mod_confirmbtn blackBtn border-0"
                  style={{ minWidth: "100px" }}
                >
                  <SmallLoader color={"text-ligth"} />
                </Button>
              ) : (
                <Button
                  className="mod_confirmbtn blackBtn border-0"
                  onClick={() => {
                    setNotifymod(false);
                    localStorage.setItem("seenAgeRestriction", "true");
                  }}
                >
                  {t("Confirm")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    // <Offcanvas
    //   {...props}
    //   show={show}
    //   placement="down"
    //   // backdrop={false}
    //   className="likecokiee_modal"
    // >
    //   {/* <Offcanvas.Header closeButton>
    //       <Offcanvas.Title>Offcanvas</Offcanvas.Title>
    //     </Offcanvas.Header> */}
    //   <Offcanvas.Body>
    //     <div className="modal_inner_main position-relative">
    //       <div className="modal_buy-cont">
    //         <div className="notify_logo">
    //           <img src={Logo} alt="" className="img-fluid" />
    //         </div>
    //         <h5 className="fw-sbold mt-3 mb-1">
    //           {t("Age Restricted Content")}
    //         </h5>

    //         <div className="age-modal-content pt-2">
    //           <p className="fw-sbold small text-center">
    //             {t(
    //               "This website (Fansroom) contains age-restricted content. If you are under the age of 18 years or under the age of majority in the location from where you are accessing this website, you do not have authorization or permission to enter this website or access any of its content. If you are over the age of 18 years or over the age of majority in the location from where you are accessing this website by entering the website you hereby agree to comply with the Fansroom Terms of Service. By clicking on the Enter button, and by entering this website you agree with all the above and certify under penalty of perjury that you are above the age of 18 or the age of majority in your location, whichever is greater."
    //             )}
    //           </p>
    //         </div>
    //         <div className="modal_buy_btn d-flex justify-content-end pt-2">
    //           <Button
    //             className="mod_cancelbtn blackBtn border-0"
    //             style={{ marginRight: "16px" }}
    //             onClick={() => {
    //               setNotifymod(false);
    //             }}
    //           >
    //             {t("Cancel")}
    //           </Button>
    //           {buttonLoader ? (
    //             <Button
    //               className="mod_confirmbtn blackBtn border-0"
    //               style={{ minWidth: "100px" }}
    //             >
    //               <SmallLoader color={"text-ligth"} />
    //             </Button>
    //           ) : (
    //             <Button
    //               className="mod_confirmbtn blackBtn border-0"
    //               onClick={() => {
    //                 setNotifymod(false);

    //                 localStorage.setItem("seenAgeRestriction", "true");
    //               }}
    //             >
    //               {t("Confirm")}
    //             </Button>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </Offcanvas.Body>
    // </Offcanvas>
  );
};

export default Notifymodal;
