import React, { useEffect } from "react";
import MainLayout from "../../../layout/MainLayout";
import VaultComponent from "../../../components/SideTabs/Vault";
import { useDispatch } from "react-redux";
import { updatePostScreen } from "../../../redux/states/post/slice";

const Vault = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updatePostScreen(""));
  }, []);
  return (
    <MainLayout>
      <VaultComponent />
    </MainLayout>
  );
};

export default Vault;
