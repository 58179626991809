// This is the edit profile system ;
import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import BackImg from "../../../images/backImg2.png";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../../components/common/ButtonLoader/ButtonLoader";
import { FiEdit } from "react-icons/fi";
import { getProfile, updateProfile, uploadFile } from "../../../redux/actions";
import { toast } from "react-toastify";
import { AiOutlineCamera } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import RichFullTextEditor from "../../../components/RichFullTextEditor";

const EditProfile = () => {
  const { t } = useTranslation();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const { imageLoader } = useSelector((s) => s.common);
  const [imgUploadEvent, setImgUploadEvent] = useState("");
  const [bioValue, setBioValue] = useState();
  const dispatch = useDispatch();

  const noNumbersRegex = /^[^\d]+$/; // Regular expression to match strings without numbers

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      // displayName: "",
      bio: "",
      websiteLink: "",
      amazonWishlist: "",
      profileImage: "",
      address: "",
      name: "",
      coverImage: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(noNumbersRegex, {
          message: "First name should not contain numbers",
        })
        .required("First name is required")
        .min(3, "First name should be at least 3 character long")
        .max(24, "First name should not exceed 24 characters"),

      lastName: Yup.string()
        .matches(noNumbersRegex, {
          message: "Last name should not contain numbers",
        })
        .required("Last name is required")
        .min(3, "Last name should be at least 3 character long")
        .max(24, "Last name should not exceed 24 characters"),
      userName: Yup.string().required(t("Username is required")),
      // displayName: Yup.string().required(t("Display name is required")),
      bio: Yup.string().required(t("bio is required")),
      websiteLink: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        t("Enter correct url!")
      ),
      amazonWishlist: Yup.string(),
      profileImage: Yup.string(),
      address: Yup.string().required(t("Address is required")),
    }),
  });

  useEffect(() => {
    formik.setValues({
      firstName: user?.firstName,
      lastName: user?.lastName,
      userName: user?.userName,
      // displayName: user?.displayName,
      bio: user?.bio,
      websiteLink: user?.websiteLink,
      amazonWishlist: user?.amazonWishlist,
      profileImage: user?.profileImage,
      coverImage: user?.coverImage,
      address: user?.address,
      name: user?.name,
    });
    setBioValue(user?.bio);
  }, [user]);

  const handleImgUpload = (e) => {
    const files = e.target.files[0];
    setImgUploadEvent("profile");
    const formdata = new FormData();
    formdata.append("imageProfile", files);

    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        formik.setFieldValue("profileImage", res?.data?.url);
        // toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleBgImage = (e) => {
    const files = e.target.files[0];
    setImgUploadEvent("back");
    const formdata = new FormData();
    formdata.append("imageProfile", files);

    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        formik.setFieldValue("coverImage", res?.data?.url);
        // toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleRemoveProfile = () => {
    formik.setFieldValue("profileImage", "");
  };

  const handleBioChange = (value) => {
    setBioValue(value);
    formik.setFieldValue("bio", value);
    formik.setFieldTouched("bio", true);
    if (value.trim().length === 0) {
      formik.setFieldError("bio", t("Bio is required"));
    } else {
      formik.setFieldError("bio", "");
    }
  };

  return (
    <Settings>
      <div className="mt-1 ">
        <div className="mx-3 d-flex justify-content-between align-items-center px-3 py-3">
          <h6 className=" text-bold">{t("Edit Profile")}</h6>
          {buttonLoader ? (
            <button className="sendMsg">
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="sendMsg p-1"
              type="submit"
              onClick={formik.handleSubmit}
            >
              {t("Save")}
            </button>
          )}
        </div>
        <div className="editProfileBack position-relative">
          {imageLoader && imgUploadEvent === "back" ? (
            <div
              className=" w-100 d-flex justify-content-center"
              style={{ height: "100px" }}
            >
              <ButtonLoader color="text-primary" />
            </div>
          ) : (
            <img
              src={
                formik.values.coverImage ? formik.values.coverImage : BackImg
              }
              alt=""
              className="profileback"
            />
          )}
          <div className="position-absolute cameraIcon pointer">
            <label htmlFor="camera" className="pointer">
              <AiOutlineCamera color="white" size={33} />
            </label>
            <input type="file" id="camera" onChange={handleBgImage} />
          </div>

          <div className="profileIcn">
            {imageLoader && imgUploadEvent === "profile" ? (
              <ButtonLoader color="text-primary" />
            ) : (
              <>
                {formik.values.profileImage ? (
                  <img src={formik.values.profileImage} alt="" className="" />
                ) : (
                  <div className="customProfile">
                    {formik.values.name?.slice(0, 2)}
                  </div>
                )}
              </>
            )}
            <div className="profileImgEdit shadow2">
              <label htmlFor="editProfile">
                <FiEdit size={18} className="pointer" />
              </label>
              <input
                type="file"
                id="editProfile"
                onChange={(e) => handleImgUpload(e)}
              />
            </div>
          </div>
        </div>
        {formik.values.profileImage ? (
          <div
            className="d-flex justify-content-end w-full mx-3 text-sm mt-2"
            onClick={() => handleRemoveProfile()}
          >
            <a href="#">Remove profile</a>
          </div>
        ) : (
          <div className="d-flex justify-content-end w-full mx-3 text-sm mt-2 invisible">
            <a href="#">dd</a>
          </div>
        )}

        <div className="editProfileForm lg-px-5 p-2 py-5 mt-2 underline">
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("First Name")}
              name="firstName"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            <label for="floatingInput">{t("First Name")}</label>
            {formik.touched.firstName && formik.errors.firstName && (
              <span className="text-danger errorText">
                {formik.errors.firstName}
              </span>
            )}
          </div>
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Last Name")}
              name="lastName"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            <label for="floatingInput">{t("Last Name")}</label>
            {formik.touched.lastName && formik.errors.lastName && (
              <span className="text-danger errorText">
                {formik.errors.lastName}
              </span>
            )}
          </div>
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Username")}
              name="userName"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userName}
            />
            <label for="floatingInput">{t("Username")}</label>
            {formik.touched.userName && formik.errors.userName && (
              <span className="text-danger errorText">
                {formik.errors.userName}
              </span>
            )}
          </div>
          {/* <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Display Name")}
              name="displayName"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.displayName}
            />
            <label for="floatingInput">{t("Display Name")}</label>
            {formik.touched.displayName && formik.errors.displayName && (
              <span className="text-danger errorText">
                {formik.errors.displayName}
              </span>
            )}
          </div> */}
          <div className="Login_input_warp form-floating mb-3">
            <RichFullTextEditor
              value={bioValue}
              handleChange={handleBioChange}
              id="EditProfile_Setting"
            />

            {formik.touched.bio && formik.errors.bio && (
              <span className="text-danger errorText">{formik.errors.bio}</span>
            )}
          </div>
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Location")}
              name="address"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            <label for="floatingInput">{t("Location")}</label>
            {formik.touched.address && formik.errors.address && (
              <span className="text-danger errorText">
                {formik.errors.address}
              </span>
            )}
          </div>
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Website Url")}
              name="websiteLink"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.websiteLink}
            />
            <label for="floatingInput">{t("Website Url")}</label>
            {formik.touched.websiteLink && formik.errors.websiteLink && (
              <span className="text-danger errorText">
                {formik.errors.websiteLink}
              </span>
            )}
          </div>
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Amazon Wishlist")}
              name="amazonWishlist"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amazonWishlist}
            />
            <label for="floatingInput">{t("Amazon Wishlist")}</label>
            {formik.touched.amazonWishlist && formik.errors.amazonWishlist && (
              <span className="text-danger errorText">
                {formik.errors.amazonWishlist}
              </span>
            )}
          </div>
        </div>
      </div>
    </Settings>
  );
};

export default EditProfile;
