import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../endpoints";
import axios from "axios";

export const getEngagement = createAsyncThunk(
  "getEngagement",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.engagement,
        payload
      );
      return response?.data;
    } catch (err) {
      Thunk.rejectWithValue(err);
    }
  }
);

export const getEngagementMessage = createAsyncThunk(
  "getEngagementMessage",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.engagementMessage,
        payload
      );
      return response?.data;
    } catch (err) {
      Thunk.rejectWithValue(err);
    }
  }
);

export const getSinglePostStats = createAsyncThunk(
  "getSinglePostStats",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.singlePostStats + payload?.id,
        payload
      );
      return response?.data;
    } catch (err) {
      Thunk.rejectWithValue(err);
    }
  }
);

export const getStatements = createAsyncThunk(
  "getStatements",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.statements,
        payload
      );
      return response?.data;
    } catch (err) {
      Thunk.rejectWithValue(err);
    }
  }
);

export const getFansStatistics = createAsyncThunk(
  "getFansStatistics",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.fansStatistics,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getTopFansStats = createAsyncThunk(
  "getTopFansStats",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.topFansStats,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getFansSubscriptions = createAsyncThunk(
  "getFansSubscriptions",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statistics.fansSubscription,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
