import React from 'react'
import AuthLayout from '../../layout/AuthLayout'
import SignupComponent from '../../components/AuthComponent/SignUp'


const SignUp = () => {
  return (
    <>
      <AuthLayout>
        <SignupComponent/>
    </AuthLayout>
    </>
    
  )
}

export default SignUp