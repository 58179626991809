import React, { useEffect, useState } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { generatePath, useNavigate } from "react-router-dom";
import { deletePost, getPostList } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import EntertainerPostSection from "./EntertainerPostSection";
import ConfirmDeletePost from "../../../common/Modal/EntertainerModal/ConfirmDeletePost";
import { toast } from "react-toastify";
import MediaSection from "./MediaSection";
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import PostComponent from "../../../common/Posts/PostComponent";
import InfiniteScroll from "react-infinite-scroll-component";
// components

const Profiletabbing = (props) => {
  const [profiletab, setprofiletab] = useState("Noposts");
  const handleTabSelectedtion = (tabs) => {
    setprofiletab(tabs);
  };
  const { list } = useSelector((s) => s.home);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.setting?.user)?._id;
  const { buttonLoader } = useSelector((s) => s.post);
  const [selectedPost, setSelectedPost] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allMedia, setAllMedia] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [totalCounts, setTotalCounts] = useState(0);
  const [page, setPage] = useState(1);

  const [allPost, setAllPost] = useState([]);

  const getPostLists = (pageNo = page, bollean) => {
    setPage(pageNo);
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      postType: ["quiz", "normal", "other", "poll"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const list = res?.data;

        if (Array.isArray(list)) {
          if (bollean) {
            setAllPost(list);
          } else {
            setAllPost((prev) => [...prev, ...list]);
          }

          var mediaList = list?.map((item) => {
            const media = item?.media;
            return media;
          });
          mediaList = mediaList?.flat();
          if (bollean) {
            setAllMedia(mediaList);
          } else {
            setAllMedia((prev) => [...prev, ...mediaList]);
          }
        }
        setTotalCounts(res?.totalcount);
      })
      .catch((err) => {
        console.log(err, "error here");
      });
  };

  useEffect(() => {
    getPostLists(1, true);
  }, []);

  const handleDelete = (dta) => {
    setSelectedPost(dta);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    const data = {
      postId: selectedPost?._id,
    };
    dispatch(deletePost(data))
      .unwrap()
      .then((res) => {
        setAllPost(allPost?.filter((val) => val?._id !== selectedPost?._id));
        toast.success(res?.message);
        setSelectedPost({});
        // getPostLists();
        setShowDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handlePostEdit = (dta) => {
    const path = generatePath("/post/edit/:id", { id: dta?._id });
    navigate(path);
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInput(val);
    const filteredData = list?.filter((itm) => {
      return itm?.description.toLowerCase().includes(val.toLowerCase());
    });
    setAllPost(filteredData);
  };

  const fetchMorePost = () => {
    getPostLists(page + 1, false);
  };

  return (
    <section className="custom_order px-0 pb-3">
      <div className="CardBody pb-3 pt-4 commonTabs Profiler_tabs ">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="Users"
          className="d-flex justify-content-between align-items-center"
        >
          <Nav variant="pills" className="">
            <Nav.Item className="">
              <Nav.Link
                className={`bg-transparent border-0 position-relative text-small ${
                  profiletab == "users" && "active"
                }`}
                eventKey="Noposts"
                onClick={() => handleTabSelectedtion("Noposts")}
                active={profiletab === "Noposts"}
              >
                {t("Posts")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small "
                eventKey="Media"
                onClick={() => handleTabSelectedtion("Media")}
                active={profiletab === "Media"}
              >
                {t("Media")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Tab.Container>
      </div>

      <Tab.Content className="lg-p-4 p-2 storyTop" id="scrollableDiv">
        <Tab.Pane eventKey="Noposts" active={profiletab === "Noposts"}>
          <div className="NewsFeed">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="text-custom">{t("RECENT")}</h6>
              <div className="inputSearch">
                <input
                  type="text"
                  placeholder={t("Search...")}
                  value={searchInput}
                  onChange={(e) => handleSearch(e)}
                />
                <BiSearch color="#636363" size={20} />
              </div>
            </div>
            {allPost?.map((item, index) => {
              return (
                <EntertainerPostSection
                  key={index}
                  data={item}
                  handleDelete={handleDelete}
                  handlePostEdit={handlePostEdit}
                  getPostLists={getPostLists}
                  newList={allPost}
                  setNewList={setAllPost}
                />
              );
            })}
          </div>

          <Button
            className="create_new-post"
            onClick={() => navigate("/new-post")}
          >
            {t("CREATE NEW POST")}
          </Button>
        </Tab.Pane>
        <InfiniteScroll
          dataLength={allPost?.length}
          next={() => fetchMorePost()}
          hasMore={allPost?.length !== totalCounts}
          scrollableTarget="scrollableDiv"
        >
          <Tab.Pane eventKey="Media" active={profiletab === "Media"}>
            <MediaSection allMedia={allMedia} />
          </Tab.Pane>
        </InfiniteScroll>
      </Tab.Content>
      <ConfirmDeletePost
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
        loading={buttonLoader}
      />
    </section>
  );
};

export default Profiletabbing;
