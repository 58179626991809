import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const getTransaction = createAsyncThunk(
  "transactions",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statements.transaction,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getEarningStatistics = createAsyncThunk(
  "getEarningStatistics",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statements.earningStatistics,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getLastOneYearTransaction = createAsyncThunk(
  "getLastOneYearTransaction",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.statements.lastOneYearTransaction,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
