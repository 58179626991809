import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import { notificationList, readNotification } from "./thunk";

const initialState = {
  loader: false,
  status: status.IDLE,
  allNotification: [],
};

const slice = createSlice({
  name: "notification",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notificationList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(notificationList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.allNotification = action.payload?.data;
    });
    builder.addCase(notificationList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.allNotification = initialState.allNotification;
    });
    builder.addCase(readNotification.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(readNotification.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(readNotification.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
  },
});

export default slice.reducer;
