import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Postpricemod(props) {
  const navigate = useNavigate();
  const { formik } = props;
  const { t } = useTranslation();
  const handlePrice = (e) => {
    if (e.target.value === 0) {
      formik.setFieldValue("type", "free");
    } else {
      formik.setFieldValue("type", "paid");
    }

    formik.setFieldValue("price", e.target.value);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="expiration_modal"
    >
      <Modal.Body>
        <div className="calednder_Mod">
          <div className="add_heading d-flex px-4 pt-4 justify-content-between">
            <h5>{t("POST PRICE")}</h5>
            <a href="javascript:void(0)" onClick={props.onhide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </a>
          </div>

          <div className="Expiraion_form px-4 mt-3">
            <Form>
              <FormGroup>
                <Label for="postprice">{t("Price")}</Label>
                <Input
                  type="number"
                  name="postprice"
                  id="postprice"
                  placeholder="$ Free"
                  value={formik.values.price}
                  min={3}
                  onChange={(e) => handlePrice(e)}
                  onFocus={() => {
                    if (
                      formik.values.price === 0 ||
                      formik.values.price === "0"
                    ) {
                      formik.setFieldValue("price", "");
                    }
                  }}
                />
              </FormGroup>

              <p className="selcting_line">{t("Minimum $3 USD or free")}</p>

              <div className="d-flex justify-content-end gap-3 py-3 mt-4">
                <Button className="cancel_btn" onClick={props.onhide}>
                  {t("Cancel")}
                </Button>
                <Button className="cancel_btn" onClick={props.onhide}>
                  {t("Save")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
