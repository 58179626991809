import React, {  useState, useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bookmarkPost,
  commentPost,
  getPostList,
  likePost,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { BiSolidSend, BiSearch } from "react-icons/bi";
import NoData from "../../../images/nodata.svg";
import { useTranslation } from "react-i18next";

const Posts = (props) => {
  const { t } = useTranslation();
  const { postList } = props;
  const [showCommentBox, setShowCommentBox] = useState(false);
  const userId = useSelector((s) => s.setting)?.user?._id;
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("all");
  const [allList, setAllList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const value = e.target.value.toLowerCase();
    const filterData = allList?.filter((itm) => {
      return itm?.caption?.toLowerCase().includes(value);
    });
    setAllList(filterData);
    if (e.target.value == "") {
      if (activeTab == "all") {
        setAllList(postList);
      } else if (activeTab === "photos") {
        showPhotos();
      } else if (activeTab === "videos") {
        showVideos();
      }
    }
  };

  const handleUserDetails = (dta) => {
    const userDetails = dta?.userDetails;
    const path = generatePath("/:slug", { slug: userDetails?.userName });
    navigate(path);
  };

  const getPostLists = () => {
    const data = {
      limit: "",
      orderBy: "createdAt",
      order: 1,
      page: 1,
    };
    dispatch(getPostList(data));
  };
  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post liked"));
        getPostLists();
      })
      .catch((err) => {});
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post unliked"));
        getPostLists();
      })
      .catch((err) => {});
  };
  const handleBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post Bookmarked"));
        getPostLists();
      })
      .catch((err) => {});
  };

  const handleUnBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Removed from bookmark"));
        getPostLists();
      })
      .catch((err) => {});
  };

  const sendMsg = (dta) => {
    if (msg === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: msg,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          setMsg("");
          toast.success(t("comment posted"));
          getPostLists();
        })
        .catch((err) => {
          toast.error(err.message);
          setMsg("");
        });
    }
  };

  useEffect(() => {
    getPostLists();
  }, []);

  useEffect(() => {
    setAllList(postList);
  }, [postList]);

  const showAllPost = () => {
    setAllList(postList);
    setActiveTab("all");
  };

  const showPhotos = () => {
    const post = postList?.filter((item, index) => {
      if (item?.media?.[0]?.type === "image") {
        return item;
      }
    });
    setActiveTab("photos");
    setAllList(post);
  };

  const showVideos = () => {
    const media = postList?.filter((item, index) => {
      if (item?.media?.[0]?.type === "video") {
        return item;
      }
    });
    setActiveTab("videos");
    setAllList(media);
  };

  return (
    <div className="py-1">
      <div className="px-4">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h5 className="text-custom">{t("RECENT")}</h5>
          <h6 className="d-flex align-items-center">
            <div className="inputSearch">
              <input
                type="text"
                placeholder={t("Search...")}
                value={searchInput}
                onChange={(e) => handleSearch(e)}
              />
              <BiSearch color="#636363" size={20} />
            </div>
          </h6>
        </div>
        <div className="d-flex align-items-center usersTitle my-4">
          <h5
            className={`${activeTab == "all" && "activeTabs"}`}
            onClick={showAllPost}
          >
            {t("All")}
          </h5>
          <h5
            className={`${activeTab == "photos" && "activeTabs"}`}
            onClick={showPhotos}
          >
            {t("Photos")}
          </h5>
          <h5
            className={`${activeTab == "videos" && "activeTabs"}`}
            onClick={showVideos}
          >
            {t("Videos")}
          </h5>
          {/* <h5 className={`${activeTab == "all" && "activeTabs"}`}>Other</h5>
          <h5 className={`${activeTab == "all" && "activeTabs"}`}>Locked</h5>*/}
        </div>
        {/* <div>
          {allList.map((data, index) => {
            const userDetials = data?.userDetails;
            const media = data?.media;

            return (
              <Fragment>
                <div key={index}>
                  <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                    <div className="d-flex align-items-center">
                      <img src={LogoW} alt="" className="postUserPic" />
                      <div className="mx-3 fansDetails">
                        <h6 className="text-bold mb-0 ">Fansroom</h6>
                        <h6 className="text-small mb-0">@fansroom23</h6>
                      </div>
                    </div>
                    <div className="d-flex">
                      <h6 className="text-small mx-2">
                        {getTimeAgo(data?.createdAt)}
                      </h6>
                      <h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                            <path
                              d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                              fill="black"
                            />
                            <path
                              d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                              fill="black"
                            />
                            <path
                              d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_87_10716">
                              <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="translate(18) rotate(90)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </h6>
                    </div>
                  </div>
                  <div className="px-3 my-3">
                    <h6 className="text-small" style={{ fontSize: "15px" }}>
                      {data?.caption}
                    </h6>
                  </div>
                  {media?.map((mediaItem, index) => {
                    return (
                      <div className="my-4 rounded overflow-hidden">
                        {mediaItem?.type === "image" && (
                          <div
                            className=" w-100 px-2 my-2 mainPost overflow-hidden rounded"
                            key={index}
                          >
                            <img
                              src={mediaItem?.url}
                              alt=""
                              className="rounded"
                            />
                          </div>
                        )}
                        {mediaItem?.type === "video" && (
                          <ReactPlayer
                           config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        onContextMenu={e => e.preventDefault()}
                            url={mediaItem?.url}
                            width={"100%"}
                            style={{ minHeight: "500px" }}
                            controls
                            volume={true}
                          />
                        )}
                      </div>
                    );
                  })}

                  <div
                    className=" w-100 px-2 my-1 position-relative mainPost pointer"
                    onClick={() => handleUserDetails(data)}
                  >
                    <img
                      src={
                        userDetials?.coverImage
                          ? userDetials?.coverImage
                          : Post3
                      }
                      alt=""
                      className="rounded object-cover"
                      style={{ height: "150px" }}
                    />
                    <div className="position-absolute postUserDetails">
                      <img
                        src={
                          userDetials?.profileImage
                            ? userDetials?.profileImage
                            : User
                        }
                        alt=""
                      />
                    </div>
                    <div className="postUserText">
                      <h6>{userDetials?.name}</h6>
                      <h6>{userDetials?.userName}</h6>
                    </div>
                  </div>

                  <div className="my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative">
                    <div className="d-flex align-items-center postaction">
                      <span>
                        {data?.isLiked ? (
                          <AiFillHeart
                            size={28}
                            color="#ff5050"
                            onClick={() => handleUnLikePost(data)}
                            className="pointer"
                          />
                        ) : (
                          <AiOutlineHeart
                            size={28}
                            color={"rgb(0, 0, 0 , 0.5)"}
                            onClick={() => handleLikePost(data)}
                            className="pointer"
                          />
                        )}
                      </span>
                      <span
                        onClick={() => setShowCommentBox(true)}
                        className="pointer"
                      >
                        <FaRegComment size={24} color={"rgb(0, 0, 0 , 0.5)"} />
                      </span>

                      <span className="pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g opacity="0.5" clip-path="url(#clip0_87_10701)">
                            <path
                              d="M11 22C8.0618 22 5.29942 20.8558 3.2218 18.7782C1.14417 16.7006 0 13.9382 0 11C0 8.0618 1.14421 5.29947 3.2218 3.2218C5.29938 1.14413 8.0618 0 11 0C13.9382 0 16.7006 1.14417 18.7782 3.2218C20.8558 5.29942 22 8.0618 22 11C22 13.9382 20.8558 16.7005 18.7782 18.7782C16.7006 20.8559 13.9382 22 11 22ZM11 1.375C5.69276 1.375 1.375 5.69276 1.375 11C1.375 16.3072 5.69276 20.625 11 20.625C16.3072 20.625 20.625 16.3072 20.625 11C20.625 5.69276 16.3072 1.375 11 1.375Z"
                              fill="black"
                            />
                            <path
                              d="M11 10.3125C10.0523 10.3125 9.28125 9.54147 9.28125 8.59375C9.28125 7.64603 10.0523 6.875 11 6.875C11.9477 6.875 12.7188 7.64603 12.7188 8.59375C12.7188 8.97342 13.0265 9.28125 13.4062 9.28125C13.786 9.28125 14.0938 8.97342 14.0938 8.59375C14.0938 7.12418 13.0634 5.89153 11.6875 5.57795V4.8125C11.6875 4.43283 11.3797 4.125 11 4.125C10.6203 4.125 10.3125 4.43283 10.3125 4.8125V5.57795C8.93655 5.89153 7.90625 7.12418 7.90625 8.59375C7.90625 10.2997 9.2941 11.6875 11 11.6875C11.9477 11.6875 12.7188 12.4585 12.7188 13.4062C12.7188 14.354 11.9477 15.125 11 15.125C10.0523 15.125 9.28125 14.354 9.28125 13.4062C9.28125 13.0266 8.97346 12.7188 8.59375 12.7188C8.21404 12.7188 7.90625 13.0266 7.90625 13.4062C7.90625 14.8758 8.93655 16.1085 10.3125 16.4221V17.1875C10.3125 17.5672 10.6203 17.875 11 17.875C11.3797 17.875 11.6875 17.5672 11.6875 17.1875V16.4221C13.0634 16.1085 14.0938 14.8758 14.0938 13.4062C14.0938 11.7003 12.7059 10.3125 11 10.3125Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_87_10701">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span>SEND TIPS </span>
                    </div>
                    <div className="">
                      {data?.isBookmarked ? (
                        <BsFillBookmarkFill
                          size={20}
                          color="ff5050"
                          onClick={() => handleUnBookmarkPost(data)}
                          className="pointer"
                        />
                      ) : (
                        <BsBookmark
                          size={20}
                          color={"rgb(0, 0, 0 , 0.5)"}
                          onClick={() => handleBookmarkPost(data)}
                          className="pointer"
                        />
                      )}
                    </div>

                    {showCommentBox && (
                      <div className="commentSection rounded shadow2">
                        <div
                          className="close-chat"
                          onClick={() => setShowCommentBox(false)}
                        >
                          <AiOutlineCloseCircle size={17} />
                        </div>
                        <div className="d-flex justify-content-between px-3">
                          <input
                            type="text"
                            className="inputComment"
                            onChange={(e) => setMsg(e.target.value)}
                            value={msg}
                          />
                          <div
                            onClick={() => sendMsg(data)}
                            className="rounded-circle d-flex justify-content-center align-items-center chatIcn shadow2"
                          >
                            <BiSolidSend size={20} color />
                          </div>
                        </div>
                        <div className="px-3 py-2 commentList">
                          {data?.comments?.map((comment, index) => {
                            const userDetails = comment?.userDetails[0];
                            return (
                              <div className="commentItem mb-2">
                                <div className="commentUser">
                                  <img
                                    src={userDetails?.profileImage || User}
                                    alt=""
                                  />
                                </div>
                                <div className="commentUserDtls">
                                  <h6 className="mb-1">
                                    {userDetails?.userName}
                                  </h6>
                                  <h6 className="contnent">
                                    {comment?.comment}
                                  </h6>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="light-text">
                      {data?.likes?.length} Likes
                    </div>
                    <div className="light-text mx-2">
                      {data?.comments?.length} comments
                    </div>
                  </div>
                  <div className="my-4 endline w-100"></div>
                </div>
              </Fragment>
            );
          })}
        </div> */}
        <div className="nodataFound">
          <img src={NoData} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Posts;
