import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import {
  allBookMarkedPost,
  blockArtist,
  blockedArtistList,
  deleteList,
  getSelectedGoupList,
  restirctArtist,
  restirctedArtistList,
  subscribedArtistList,
  unBlockArtist,
} from "./thunk";

const initialState = {
  loader: false,
  status: status.IDLE,
  bookmarkList: [],
  subscribedArtistList: [],
  restrictedArtistList: [],
  blockedArtistList: [],
  buttonLoader : false ,
  pinnedCollection : []
};

const slice = createSlice({
  name: "collection",
  initialState: { ...initialState },
  reducers: {
    updatePinnedCollection : (state , action) =>{
      state.pinnedCollection = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(allBookMarkedPost.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(allBookMarkedPost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.bookmarkList = action.payload.data;
    });
    builder.addCase(allBookMarkedPost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.bookmarkList = initialState.bookmarkList;
    });
    builder.addCase(blockArtist.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(blockArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(blockArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(unBlockArtist.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(unBlockArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(unBlockArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(restirctArtist.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(restirctArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(restirctArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(subscribedArtistList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(subscribedArtistList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.subscribedArtistList = action.payload.data;
    });
    builder.addCase(subscribedArtistList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.subscribedArtistList = initialState.subscribedArtistList;
    });
    builder.addCase(restirctedArtistList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(restirctedArtistList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.restrictedArtistList = action.payload.data;
    });
    builder.addCase(restirctedArtistList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.restrictedArtistList = initialState.restrictedArtistList;
    });
    builder.addCase(blockedArtistList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(blockedArtistList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.blockedArtistList = action.payload.data;
    });
    builder.addCase(blockedArtistList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.blockedArtistList = initialState.blockedArtistList;
    });
    builder.addCase(getSelectedGoupList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getSelectedGoupList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(getSelectedGoupList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(deleteList.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(deleteList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(deleteList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
  },
});

export default slice.reducer;
export const {updatePinnedCollection} = slice.actions;
