import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";

// components

const Paymentrqst = (props) => {
  const { t } = useTranslation();
  return (
    <section className="custom_order px-0 pb-3">
      {/* topheaderbar */}
      <div className="notification_topheader d-flex align-items-center justify-content-between pt-4">
        {/* <Link to="/home"> */}
        <div className="notification_left_heading d-flex align-items-center">
          <h5>{props.title}</h5>
        </div>
        {/* </Link> */}

        <div className="collection_header d-flex align-items-center gap-3"></div>
      </div>
      {/* topheaderbar */}

      <div className="transaction_info_div position-relative mt-3">
        <p>{t("Transactions can take up to 3-5 business days")}</p>
      </div>

      <div className="common_table earning_table mt-4 table-responsive border_hide">
        <Table borderless>
          <thead>
            <tr>
              <th>{t("DATE & TIME")}</th>
              <th>{t("INVOICE")}</th>
              <th>{t("AMOUNT")}</th>
              <th>{t("STATUS")}</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>

              <td>Mark</td>
            </tr> */}
          </tbody>
        </Table>
        <div className="No_earnings">
          <p>{t("No earnings")}</p>
        </div>
      </div>
    </section>
  );
};

export default Paymentrqst;
