import React, { useState } from "react";
import Settings from "./Settings";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Display = () => {
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handlelanguageChange = (e) => {
    const value = e.target.value;
    localStorage.setItem("I18N_LANGUAGE", value);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
  };

  return (
    <Settings>
      <div className="mt-1 ">
        <div className="mx-3 d-flex justify-content-between align-items-center px-3 py-3">
          <h6 className=" text-bold">{t("Display")}</h6>
        </div>
        <div className="endline"></div>
        <div className="mx-4 px-2 my-2 py-2">
          <h6 className="text-small fw-sbold mb-0">
            {t("Customize Your View")}
          </h6>
        </div>
        <div className="endline"></div>
        <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2">
          <h6 className="text-small  mb-0 px-2">{t("Choose Language")}</h6>
        </div>

        <select
          name=""
          id=""
          className="languageCont "
          style={{ width: "100%", border: "none", outline: "none" }}
          onChange={(e) => handlelanguageChange(e)}
          value={selectedLanguage}
        >
          <option value="en">English</option>
          <option value="hn">Hindi</option>
          <option value="chn">Chinese</option>
          <option value="spn">Spanish</option>
        </select>
      </div>
      <div className="endline"></div>
    </Settings>
  );
};

export default Display;
