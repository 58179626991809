import React, { useState, useEffect, useCallback } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Composefooter from "./ComposeFooter";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createPost, searchArtist, updatePost } from "../../../redux/actions";
import { toast } from "react-toastify";
import SmallLoader from "../ButtonLoader/SmallLoader";
import { useSelector } from "react-redux";
import {
  removePostFromClipboard,
  removeScheduleDate,
  updateSaveForLater,
} from "../../../redux/states/post/slice";
import CustomVideoPlayer from "../../CustomVideoPlayer";
import { BsSearch, BsX } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { debounce } from "lodash";

const AddCont = (props) => {
  const { t } = useTranslation();
  const { handleInputRemove, index, handleInputChange, value } = props;
  return (
    <div className="polling_divinner">
      <div className="default_poll">
        <Row>
          <Col lg={12}>
            <FormGroup className="position-relative">
              <Input
                type="text"
                name="input"
                id="exampleEmail"
                placeholder={t("Enter Another Option...")}
                value={value}
                onChange={(e) => handleInputChange(e, index)}
              />
              <div className="poll_input_btns">
                <Button className="close_poll" onClick={handleInputRemove}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#ccc"
                      d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                    />
                  </svg>
                </Button>

                <Button className="close_poll">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="#ccc"
                      d="M104 60a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm60 12a12 12 0 1 0-12-12a12 12 0 0 0 12 12Zm-72 44a12 12 0 1 0 12 12a12 12 0 0 0-12-12Zm72 0a12 12 0 1 0 12 12a12 12 0 0 0-12-12Zm-72 68a12 12 0 1 0 12 12a12 12 0 0 0-12-12Zm72 0a12 12 0 1 0 12 12a12 12 0 0 0-12-12Z"
                    />
                  </svg>
                </Button>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const Composepsotcard = (props) => {
  const {
    postDetailsDta,
    setShowProfileBanners,
    showProfileBanners,
    getPostLists,
    setRefreshPostList,
    showLockedPost,
    setShowLockedPost,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [attachmentType, setAttachmentType] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState(null);
  const [allMedia, setAllMedia] = useState([]);
  const [polldiv, setPoll] = useState([]);
  const [pollouter, setpollouter] = useState(false);
  const [quizdiv, setQuiz] = useState([]);
  const [quizOuter, setQuizOuter] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [valutmod, setvalutmod] = useState(false);
  const [saveForLater, setSaveForLater] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState();
  const [postShow, setPostShow] = useState({
    all: false,
    onlySubscriber: true,
  });
  const [showSearch, setShowSearch] = useState(false);
  const [entertainerSerach, setEntertainerSerach] = useState("");
  const [uploadedPercent, setUploadedPercent] = useState([]);

  const navigate = useNavigate();
  const {
    buttonLoader,
    preSelectedPost,
    isPreSelected,
    preSelectedPoll,
    scheduledDates,
    saveForLaterAfter,
    isDateScheduled,
    preSelectedQuiz,
    type,
  } = useSelector((s) => s.post);

  // const { subscribedArtistList: userList } = useSelector((s) => s.collection);
  const { searchArtistList: userList } = useSelector((s) => s.artist);

  const onSearchArtist = useCallback(
    debounce((filter) => {
      dispatch(searchArtist(filter));
    }, 1000),
    []
  );

  useEffect(() => {
    const data = {
      search: entertainerSerach,
      order: -1,
      orderBy: "userName",
      role: "ARTIST",
    };
    onSearchArtist(data);
  }, [entertainerSerach]);

  useEffect(() => {
    if (isPreSelected) {
      if (type === "media") {
        setAllMedia(preSelectedPost);
      } else if (type === "quiz") {
        setQuiz(preSelectedQuiz?.quizOption);
        formik.setFieldValue("description", preSelectedQuiz?.description);
        formik.setFieldValue("postType", preSelectedQuiz?.postType);
        for (var i = 0; i < 2; i++) {
          if (polldiv?.length < 2) {
            handleAddDivClick("poll");
          }
        }
        setQuizOuter(true);
        setpollouter(false);
      } else if (type === "poll") {
        setPoll(preSelectedPoll?.pollOption);
        formik.setFieldValue("description", preSelectedPoll?.description);
        formik.setFieldValue("postType", preSelectedPoll?.postType);
        for (var i = 0; i < 2; i++) {
          if (quizdiv?.length < 2) {
            handleAddDivClick("quiz");
          }
        }
        setQuizOuter(false);
        setpollouter(true);
      }
    } else {
      setAllMedia([]);
    }
    dispatch(removePostFromClipboard());
  }, [valutmod]);

  // Validation function to check if URL starts with 'https://fansroom.com/'
  const validateUrl = (value) => {
    if (!value) return true; // Allow empty value
    const urls = value.split(" "); // Split the input by spaces to get individual URLs
    for (let url of urls) {
      if (
        url.startsWith("https://") &&
        !url.startsWith("https://fansroom.com/")
      ) {
        return false;
      }
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      type: "free",
      price: 0,
      currency: "USD",
      currencySymbol: "$",
      postType: "normal",
      scheduledType: "instant",
      pollOption: [],
      quizOption: [],
      scheduleDate: "",
      scheduleTime: "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      scheduled: 0,
      description: "",
      media: [],
      savForLater: "",
      tagUser: [],
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Required")
        .test(
          "is-fansroom-url",
          "URL must start with https://fansroom.com/",
          (value) => validateUrl(value)
        ),
    }),
    onSubmit: async function (values, { resetForm }) {
      let tagUser = values.tagUser.map((i) => {
        const name = i.name ? i.name : i.firstName + " " + i.lastName;
        return {
          userId: i._id,
          userDetails: i._id,
          userName: i.userName,
          profileImage: i.profileImage,
          name,
          tagAt: new Date(),
        };
      });
      if (props.screen === "edit-post") {
        const data = {
          ...values,
          tagUser,
          postId: props?.postId,
          postShown: postShow.all ? "all" : "onlySubscriber",
        };
        dispatch(updatePost(data))
          .unwrap()
          .then((res) => {
            toast.success(t("Post updated successfully"));
            clearPost();
            if (getPostLists) {
              getPostLists();
            }
            navigate("/home");
            resetForm();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        dispatch(
          createPost({
            ...values,
            tagUser,
            postShown: postShow.all ? "all" : "onlySubscriber",
          })
        )
          .unwrap()
          .then((res) => {
            toast.success(res?.message);
            clearPost();
            resetForm();
            if (setRefreshPostList) {
              setRefreshPostList(true);
            }
            if (getPostLists) {
              getPostLists(1);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    },
  });

  const clearPost = () => {
    formik.setValues({
      type: "free",
      price: 0,
      currency: "USD",
      currencySymbol: "$",
      postType: "normal",
      scheduledType: "instant",
      pollOption: [],
      quizOption: [],
      scheduleDate: "",
      scheduleTime: "",
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      scheduled: 0,
      description: "",
      media: [],
    });
    setQuiz([]);
    setQuizOuter(false);
    setpollouter(false);
    setPoll([]);
    setAllMedia([]);
    setAudioData(null);
    setVideoData([]);
    setAttachment(null);
  };

  useEffect(() => {
    formik.setFieldValue("pollOption", polldiv);
    formik.setFieldValue("quizOption", quizdiv);
    formik.setFieldValue("media", allMedia);
    const allImage = allMedia?.filter((itm) => {
      return itm?.type === "image";
    });
    if (allImage?.length > 0) {
      setHasImage(true);
    } else {
      setHasImage(false);
    }
  }, [polldiv, quizdiv, allMedia]);

  useEffect(() => {
    if (formik.values.price > 0) {
      setPostShow({
        all: false,
        onlySubscriber: true,
      });
    }
  }, [formik.values.price]);

  const handleAddDivClick = (dta) => {
    if (dta === "poll") {
      setPoll([...polldiv, { option: "" }]);
    } else if (dta === "quiz") {
      setQuiz([...quizdiv, { option: "" }]);
    }
  };

  const handleRemoveMedia = (id) => {
    const filteredData = allMedia?.filter((itm, index) => {
      return index !== id;
    });
    setAllMedia(filteredData);
  };

  const handlePollRemove = (ind) => {
    const filteredData = polldiv?.filter((itm, index) => {
      return index !== ind;
    });
    setPoll(filteredData);
  };

  const handlePollInputChange = (e, ind) => {
    const val = e.target.value;
    const newArray = [...polldiv];
    const index = newArray.findIndex((item, index) => index === ind);

    if (index !== -1) {
      newArray[index].option = val;
      setPoll(newArray);
    }
  };

  const handleQuizInputChange = (e, ind) => {
    const val = e.target.value;
    const newArray = [...quizdiv];
    const index = newArray?.findIndex((item, index) => index === ind);

    if (index !== -1) {
      newArray[index].option = val;
      setQuiz(newArray);
    }
  };

  const handleQuizRemove = (id) => {
    const filteredData = quizdiv?.filter((itm, index) => {
      return index !== id;
    });
    setQuiz(filteredData);
  };

  useEffect(() => {
    if (isDateScheduled) {
      formik.setFieldValue("scheduleDate", scheduledDates?.date);
      formik.setFieldValue("scheduleTime", scheduledDates?.time);
      formik.setFieldValue("scheduledType", "scheduled");
      dispatch(removeScheduleDate());
    }
  }, [isDateScheduled]);

  // here we are writing the code for edit ;

  useEffect(() => {
    if (props.screen === "edit-post") {
      if (postDetailsDta) {
        const mediaDta = postDetailsDta?.media?.map((item) => {
          const { _id, ...others } = item;
          return others;
        });
        setAllMedia(mediaDta);
        formik.setFieldValue("description", postDetailsDta?.description);
        formik.setFieldValue("price", postDetailsDta?.price);
        formik.setFieldValue("scheduleDate", postDetailsDta?.scheduleDate);
        formik.setFieldValue("scheduleTime", postDetailsDta?.scheduleTime);
        formik.setFieldValue("scheduledType", postDetailsDta?.scheduledType);
        formik.setFieldValue("postType", postDetailsDta?.postType);
        formik.setFieldValue("type", postDetailsDta?.type);
        formik.setFieldValue("savForLater", postDetailsDta?.savForLater);
        formik.setFieldValue("scheduled", postDetailsDta?.scheduled || 0);

        if (postDetailsDta?.postType === "quiz") {
          const allQuiz = postDetailsDta?.quizOption?.map((item) => {
            return { option: item?.option };
          });
          setQuiz(allQuiz);
          setQuizOuter(true);
        } else if (postDetailsDta?.postType === "poll") {
          const allPoll = postDetailsDta?.quizOption?.map((item) => {
            return { option: item?.option };
          });
          setPoll(allPoll);
          setpollouter(true);
        }
      }
    }
  }, [props.screen, postDetailsDta]);

  // handling savefor later options;

  useEffect(() => {
    if (saveForLaterAfter) {
      formik.setFieldValue("savForLater", saveForLaterAfter);
      formik.setFieldValue("scheduledType", "scheduled");
      setSaveForLater(saveForLaterAfter);
    }
    dispatch(updateSaveForLater(""));
  }, []);

  const handleLockedPost = () => {
    setShowLockedPost(!showLockedPost);
    getPostLists(1, !showLockedPost);
  };

  const handleTextareaChange = (e) => {
    const text = e.target.value;
    formik.setFieldValue("description", text);
    formik.setTouched({ ...formik.touched, description: true });
    setCursorPosition(e.target.selectionStart);
    const lastWord = text.split(/\s+/).pop();

    if (lastWord.includes("@") || text.endsWith("@")) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

    const remainingUsers = formik.values.tagUser.filter((user) =>
      text.includes(`@${user?.userName}`)
    );
    formik.setFieldValue("tagUser", remainingUsers);
  };
  const handleUserSelection = (userName, id, profileImage, name) => {
    const updatedText = insertAtCursor(
      formik.values.description,
      `${userName} `,
      cursorPosition
    );

    formik.setFieldValue("description", updatedText);

    const updatedSelectedUsers = formik.values.tagUser.map((user) =>
      user._id === id ? { ...user, userName } : user
    );

    formik.setFieldValue("tagUser", [
      ...updatedSelectedUsers,
      { _id: id, userName, profileImage, position: cursorPosition, name },
    ]);

    setShowSuggestions(false);
  };

  const insertAtCursor = (text, value, position) => {
    return text.slice(0, position) + value + text.slice(position);
  };

  return (
    <>
      <section className="compose_post_card sticky-top bg-white py-2 px-3 px-lg-0">
        {props.screen === "home" && (
          <>
            {showSearch ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <FaArrowLeft
                    onClick={() => setShowSearch(!showSearch)}
                    style={{ fontSize: "24px" }}
                  />
                  <div
                    style={{ width: "93%" }}
                    className="searchBar loginInput align-items-center justify-content-between"
                  >
                    {/* <Searchpost setEntertainerSerach={setEntertainerSerach} /> */}
                    <input
                      style={{ outline: 0 }}
                      type="text"
                      placeholder={t("Search user")}
                      className="border-0 "
                      value={entertainerSerach}
                      onChange={(e) => setEntertainerSerach(e.target.value)}
                    />
                  </div>
                </div>
                {userList?.length ? (
                  <div
                    className="suggestion-list position-absolute w-100 bg-white p-4 rounded"
                    style={{
                      maxHeight: "250px",
                      overflowY: "scroll",
                      left: 10,
                    }}
                  >
                    {userList.map((user) => (
                      <div
                        key={user._id}
                        className="user-container"
                        onClick={() => {
                          navigate(`/${user.userName}`);
                        }}
                      >
                        {/* <img
                          src={user.profileImage}
                          alt="Profile"
                          className="profile-image"
                        /> */}
                        {user?.profileImage ? (
                          <div className="user_img">
                            <img
                              src={user?.profileImage}
                              alt=""
                              className="profile-image"
                            />
                          </div>
                        ) : (
                          <div
                            className="user_img_name shadow2"
                            style={{
                              height: "50px",
                              width: "50px",
                              fontSize: "16px",
                            }}
                          >
                            {user.firstName && user?.lastName
                              ? user?.firstName?.slice(0, 1) +
                                user?.lastName?.slice(0, 1)
                              : user.displayName?.slice(0, 1) ||
                                user.name?.slice(0, 1)}
                          </div>
                        )}
                        <div
                          className="user-details "
                          style={{ marginLeft: "16px" }}
                        >
                          <div className="user-name">
                            {user.name || user.firstName}
                          </div>
                          <div className="username">@{user.userName}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : (
              <div className="compose_top_heading d-flex align-items-center justify-content-between topHead">
                <h4 className="m-0 head">{t("Home")}</h4>

                <div className="compose_top_right d-flex align-items-center">
                  <h6 className="mb-0 mx-3" />

                  <span
                    className="pointer me-3"
                    onClick={() => navigate("/queue")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M3.02246 20H16.978C18.441 20 19.6299 18.8111 19.6299 17.3481V4.13331C19.6299 2.67035 18.441 1.48145 16.978 1.48145H15.9262V0.740727C15.9262 0.333315 15.5928 0 15.1854 0C14.778 0 14.4447 0.333315 14.4447 0.740727V1.48145H5.55582V0.740727C5.55582 0.333315 5.22244 0 4.81503 0C4.40762 0 4.0743 0.333315 4.0743 0.740727V1.48145H3.02246C1.5595 1.48145 0.370605 2.67035 0.370605 4.13331V17.3481C0.370605 18.8111 1.5595 20 3.02246 20ZM1.85212 4.13331C1.85212 3.48889 2.37804 2.96297 3.02246 2.96297H4.0743V3.7037C4.0743 4.11111 4.40762 4.44442 4.81503 4.44442C5.22244 4.44442 5.55576 4.11111 5.55576 3.7037V2.96297H14.4447V3.7037C14.4447 4.11111 14.778 4.44442 15.1854 4.44442C15.5928 4.44442 15.9261 4.11111 15.9261 3.7037V2.96297H16.978C17.6224 2.96297 18.1483 3.48889 18.1483 4.13331V6.2963H1.85212V4.13331ZM1.85212 7.77776H18.1484V17.3481C18.1484 17.9926 17.6225 18.5185 16.9781 18.5185H3.02246C2.37804 18.5185 1.85212 17.9926 1.85212 17.3481V7.77776Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>

                  <span className="me-3">
                    <BsSearch onClick={() => setShowSearch(!showSearch)} />
                  </span>

                  <div className="three_dot_btnvar">
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_708_75)">
                            <path
                              d="M9.00002 4.00003C10.1046 4.00003 11 3.10459 11 2.00002C11 0.895438 10.1046 0 9.00002 0C7.89544 0 7 0.895438 7 2.00002C7 3.10459 7.89544 4.00003 9.00002 4.00003Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.00002 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 9.00002 7C7.89544 7 7 7.89544 7 9.00002C7 10.1046 7.89544 11 9.00002 11Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.00002 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9.00002 14C7.89544 14 7 14.8954 7 16C7 17.1046 7.89544 18 9.00002 18Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_708_75">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </DropdownToggle>
                      <DropdownMenu>
                        {showLockedPost ? (
                          <DropdownItem onClick={handleLockedPost}>
                            {t("Hide All Locked Post")}
                          </DropdownItem>
                        ) : (
                          <DropdownItem onClick={handleLockedPost}>
                            {t("Show All Locked Post")}
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={() =>
                            toast.warning("No promotional post found !")
                          }
                        >
                          {t("Hide All Promotional Posts")}
                        </DropdownItem>
                        {showProfileBanners ? (
                          <DropdownItem
                            onClick={() =>
                              setShowProfileBanners(!showProfileBanners)
                            }
                          >
                            {t("Hide the Profile Banners")}
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            onClick={() =>
                              setShowProfileBanners(!showProfileBanners)
                            }
                          >
                            {t("Show the Profile Banners")}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </section>
      <section className="compose_post_card px-3 px-lg-0">
        {props.screen === "new-post" && (
          <div className="new_post_wrap d-flex justify-content-between align-items-center px-2">
            <h4 className="text-bold pointer" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 12L11 6M5 12L11 18M5 12L19 12"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ marginLeft: "7px" }}>{t("NEW POST")}</span>
            </h4>
            <h6>
              <button className="clearBtn mx-3" onClick={clearPost}>
                {t("Clear")}
              </button>

              {buttonLoader ? (
                <button className="sendMsg">
                  <SmallLoader />
                </button>
              ) : (
                <button
                  className="sendMsg"
                  onClick={formik.handleSubmit}
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {t("Post")}
                </button>
              )}
            </h6>
          </div>
        )}
        {props.screen === "edit-post" && (
          <div className="d-flex justify-content-between align-items-center px-2">
            <h4 className="text-bold pointer" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 12L11 6M5 12L11 18M5 12L19 12"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ marginLeft: "7px" }}>{t("EDIT POST")}</span>
            </h4>
            <h6>
              <button className="clearBtn mx-3" onClick={clearPost}>
                {t("Clear")}
              </button>

              {buttonLoader ? (
                <button className="sendMsg">
                  <SmallLoader />
                </button>
              ) : (
                <button
                  className="sendMsg"
                  onClick={formik.handleSubmit}
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {t("Update Post")}
                </button>
              )}
            </h6>
          </div>
        )}
        <div className="compose_newpost_typing mt-2 rounded border">
          <div className="px-1">
            {allMedia?.map((media, index) => {
              return (
                <>
                  {media?.type === "video" && (
                    <>
                      <ul className="list-unstyled mb-0 d-flex align-items-center gap-5 ComposeFiles px-4  position-relative">
                        <Button
                          variant="transparent"
                          className="border-0 p-0 position-absolute"
                          style={{ top: "2px", right: "2px", zIndex: "9" }}
                        >
                          <AiOutlineCloseCircle
                            size={23}
                            onClick={() => handleRemoveMedia(index)}
                          />
                        </Button>
                        <li>
                          <CustomVideoPlayer mediaItem={media} />
                          {/* <ReactPlayer
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                            url={media?.url}
                            className="img-fluid postVideoCont custom-player"
                          /> */}
                        </li>
                      </ul>
                    </>
                  )}
                </>
              );
            })}

            {allMedia?.map((media, index) => {
              return (
                <>
                  {media?.type === "audio" && (
                    <>
                      <ul
                        className="list-unstyled mb-0 d-flex align-items-center gap-5 my-1  px-3 pb-0 position-relative"
                        style={{ border: "1px solid ##cacfcc" }}
                      >
                        <Button
                          variant="transparent"
                          className="border-0 p-0 position-absolute"
                          style={{ top: "2px", right: "2px", zIndex: "9" }}
                        >
                          <AiOutlineCloseCircle
                            size={23}
                            onClick={() => handleRemoveMedia(index)}
                          />
                        </Button>
                        <li className="d-flex align-items-center">
                          <audio
                            src={media?.url}
                            controls
                            style={{ background: "white" }}
                          />
                        </li>
                      </ul>
                    </>
                  )}
                </>
              );
            })}
            {hasImage && (
              <ul className="list-unstyled mb-0 d-flex align-items-center ComposeFiles p-1 pb-2 my-1  position-relative d-flex align-items-center">
                {allMedia?.map((media, index) => {
                  return (
                    <>
                      {media?.type === "image" && (
                        <div className="createPostAttach">
                          <img src={media?.url} alt="" className="" />
                          <IoCloseCircleOutline
                            className="closeIconss"
                            color="black"
                            size={23}
                            onClick={() => handleRemoveMedia(index)}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </ul>
            )}
            {formik.values.scheduled !== 0 && (
              <ul className="list-unstyled mb-0 d-flex align-items-center ComposeFiles p-1 p-2 my-1  position-relative d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h6 className="text-small mb-0">
                    {t("Expiration days")} : {formik.values.scheduled}{" "}
                    {t("days")}
                  </h6>
                  <IoCloseCircleOutline
                    className="closeIconss pointer"
                    color="black"
                    size={23}
                    onClick={() => formik.setFieldValue("scheduled", 0)}
                  />
                </div>
              </ul>
            )}
            {formik.values.price !== 0 && (
              <ul className="list-unstyled mb-0 d-flex align-items-center ComposeFiles p-1 p-2 my-1  position-relative d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h6 className="text-small mb-0">
                    {t("Post Price")} : {formik.values.price} $
                  </h6>
                  <IoCloseCircleOutline
                    className="closeIconss pointer"
                    color="black"
                    size={23}
                    onClick={() => {
                      formik.setFieldValue("price", 0);
                      formik.setFieldValue("type", "free");
                    }}
                  />
                </div>
              </ul>
            )}
            {formik.values.scheduleDate && (
              <ul className="list-unstyled mb-0 d-flex align-items-center ComposeFiles p-1 p-2 my-1  position-relative d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h6 className="text-small mb-0">
                    <span>
                      {t("Scheduled Date")} : {formik.values.scheduleDate}
                    </span>{" "}
                    <br />
                    <span>
                      {t("Scheduled Time")} : {formik.values.scheduleTime}
                    </span>
                  </h6>

                  <IoCloseCircleOutline
                    className="closeIconss pointer"
                    color="black"
                    size={23}
                    onClick={() => {
                      formik.setFieldValue("scheduleDate", "");
                      formik.setFieldValue("scheduleTime", "");
                      formik.setFieldValue("scheduledType", "instant");
                    }}
                  />
                </div>
              </ul>
            )}
            {saveForLater && (
              <ul className="list-unstyled mb-0 d-flex align-items-center ComposeFiles p-1 p-2 my-1  position-relative d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center full-width">
                  <h6 className="text-small mb-0">
                    <span>{t("Scheduled : Saved for later")}</span> <br />
                  </h6>

                  <IoCloseCircleOutline
                    className="closeIconss pointer"
                    color="black"
                    size={23}
                    onClick={() => {
                      setSaveForLater("");
                      formik.setFieldValue("savForLater", "");
                      formik.setFieldValue("scheduledType", "instant");
                    }}
                  />
                </div>
              </ul>
            )}
            <div className="polling_wrap">
              {pollouter && (
                <Form>
                  <div className="polling_inner">
                    <div className="default_poll">
                      <div className="poll_header d-flex align-items-center justify-content-between mb-2">
                        <div className="left_poll d-flex align-items-center">
                          <span className="me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 19"
                              fill="none"
                            >
                              <g opacity="0.5">
                                <rect
                                  x="0.5"
                                  y="2.5"
                                  width="11"
                                  height="5"
                                  stroke="black"
                                ></rect>
                                <rect
                                  x="0.5"
                                  y="11.5"
                                  width="11"
                                  height="5"
                                  stroke="black"
                                ></rect>
                                <rect
                                  x="1"
                                  width="19"
                                  height="0.999999"
                                  transform="rotate(90 1 0)"
                                  fill="black"
                                ></rect>
                                <rect
                                  x="0.5"
                                  y="7.5"
                                  width="15"
                                  height="4"
                                  stroke="black"
                                ></rect>
                              </g>
                            </svg>
                          </span>
                          <p className="m-0">{t("Poll")}</p>
                        </div>
                        <div className="right_poll d-flex align-items-center">
                          <p className="m-0 me-2">7 {t("days")}</p>
                          <Button
                            className="header_Closecta"
                            onClick={() => {
                              setpollouter(false);
                              setPoll([]);
                              formik.setFieldValue("postType", "normal");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ccc"
                                d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                              />
                            </svg>
                          </Button>
                        </div>
                      </div>
                    </div>
                    {polldiv?.map((div, idx) => {
                      return (
                        <>
                          <div className="">
                            <div
                              key={idx + 2}
                              id={idx + 2}
                              className="polling_div"
                            >
                              <AddCont
                                handleInputRemove={() => handlePollRemove(idx)}
                                index={idx}
                                handleInputChange={handlePollInputChange}
                                value={div?.option}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <Link
                      to=""
                      onClick={() => handleAddDivClick("poll")}
                      className="another_add"
                    >
                      {t("Add another")}
                    </Link>
                  </div>
                </Form>
              )}
            </div>
            <div className="polling_wrap">
              {quizOuter && (
                <Form>
                  <div className="polling_inner">
                    <div className="default_poll">
                      <div className="poll_header d-flex align-items-center justify-content-between mb-2">
                        <div className="left_poll d-flex align-items-center">
                          <span className="me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 19"
                              fill="none"
                            >
                              <g opacity="0.5">
                                <rect
                                  x="0.5"
                                  y="2.5"
                                  width="11"
                                  height="5"
                                  stroke="black"
                                ></rect>
                                <rect
                                  x="0.5"
                                  y="11.5"
                                  width="11"
                                  height="5"
                                  stroke="black"
                                ></rect>
                                <rect
                                  x="1"
                                  width="19"
                                  height="0.999999"
                                  transform="rotate(90 1 0)"
                                  fill="black"
                                ></rect>
                                <rect
                                  x="0.5"
                                  y="7.5"
                                  width="15"
                                  height="4"
                                  stroke="black"
                                ></rect>
                              </g>
                            </svg>
                          </span>
                          <p className="m-0">{t("Quiz")}</p>
                        </div>
                        <div className="right_poll d-flex align-items-center">
                          <p className="m-0 me-2">7 {t("Quiz")}</p>
                          <Button
                            className="header_Closecta"
                            onClick={() => {
                              setQuizOuter(false);
                              setQuiz([]);
                              formik.setFieldValue("postType", "normal");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ccc"
                                d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                              />
                            </svg>
                          </Button>
                        </div>
                      </div>
                    </div>
                    {quizdiv?.map((div, idx) => {
                      return (
                        <>
                          <div className="">
                            <div
                              key={idx + 2}
                              id={idx + 2}
                              className="polling_div"
                            >
                              <AddCont
                                handleInputRemove={() => handleQuizRemove(idx)}
                                index={idx}
                                handleInputChange={handleQuizInputChange}
                                value={div?.option}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <Link
                      to=""
                      onClick={() => handleAddDivClick("quiz")}
                      className="another_add"
                    >
                      {t("Add another")}
                    </Link>
                  </div>
                </Form>
              )}
            </div>
          </div>
          <div className="typing_post_form p-2">
            <Form>
              <FormGroup>
                <Input
                  type="textarea"
                  name="text"
                  id="exampleText"
                  onBlur={formik.handleBlur}
                  placeholder={t("Compose new post..")}
                  value={formik.values.description}
                  onChange={
                    (e) => handleTextareaChange(e)
                    // formik.setFieldValue("description", e.target.value)
                  }
                />
                {showSuggestions && (
                  <div
                    className="suggestion-list"
                    style={{ maxHeight: "200px", overflowY: "scroll" }}
                  >
                    {userList
                      .filter(
                        (user) =>
                          !formik.values.tagUser.find(
                            (val) => val._id === user._id
                          ) || formik.values.tagUser.length === 0
                      )
                      .map((user) => (
                        <div
                          key={user._id}
                          className="user-container"
                          onClick={() => {
                            const name = user.name ? user.name : user.firstName;
                            handleUserSelection(
                              user.userName,
                              user._id,
                              user.profileImage,
                              name
                            );
                          }}
                        >
                          <img
                            src={user.profileImage}
                            alt="Profile"
                            className="profile-image"
                          />
                          <div className="user-details">
                            <div className="user-name">
                              {user.name || user.firstName}
                            </div>
                            <div className="username">@{user.userName}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </FormGroup>
            </Form>
          </div>
          <Composefooter
            uploadedPercent={uploadedPercent}
            setUploadedPercent={setUploadedPercent}
            selectedUsers={selectedUsers}
            attachment={attachment}
            setAttachment={setAttachment}
            setAttachmentType={setAttachmentType}
            attachmentType={attachmentType}
            videoData={videoData}
            setVideoData={setVideoData}
            setAllMedia={setAllMedia}
            audioData={audioData}
            setAudioData={setAudioData}
            handleAddDivClick={handleAddDivClick}
            setpollouter={setpollouter}
            setQuizOuter={setQuizOuter}
            formik={formik}
            setPoll={setPoll}
            valutmod={valutmod}
            setvalutmod={setvalutmod}
            setQuiz={setQuiz}
            {...props}
          />
        </div>
        {formik.touched.description && formik.errors.description && (
          <span className="text-danger errorTextDescription">
            {formik.errors.description}
          </span>
        )}
        <div
          style={{ display: "flex", alignContent: "center" }}
          className="check_wrap_home"
        >
          <div style={{ marginRight: "10px" }}>
            <input
              checked={postShow.all}
              type="checkbox"
              name="showEveryone"
              style={{ marginRight: "6px" }}
              onChange={() =>
                setPostShow({
                  onlySubscriber: !postShow.onlySubscriber,
                  all: !postShow.all,
                })
              }
            />
            <label for="myCheckbox">Show Everyone</label>
          </div>
          <div>
            <input
              checked={postShow.onlySubscriber}
              type="checkbox"
              name="showSubscriber"
              style={{ marginRight: "6px" }}
              onChange={() =>
                setPostShow({
                  onlySubscriber: !postShow.onlySubscriber,
                  all: !postShow.all,
                })
              }
            />
            <label for="myCheckbox">Show Only Subscribers</label>
          </div>
        </div>

        {uploadedPercent?.length > 0 && (
          <>
            {uploadedPercent.map((i) => (
              <div className="fw-sbold text-small my-3 progress_file d-flex align-items-center">
                {/* <div className="uploading_img">
                  <img src={i?.ulpoadimg} alt="" />
                </div> */}

                <div className="filename w-100">
                  <p className="m-0">{i?.name}</p>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{ width: `${i.percentage}%` }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        <h6 className="my-2 d-flex justify-content-end">
          {buttonLoader ? (
            <button className="sendMsg">
              <SmallLoader />
            </button>
          ) : (
            <Button
              style={{ height: "35px", padding: "0px 20px" }}
              className={`d-flex align-items-center justify-content-center rounded-pill ${
                !formik.isValid ||
                !formik.dirty ||
                uploadedPercent?.length !== 0
                  ? "common-btn"
                  : "post-common-btn"
              }  `}
              onClick={formik.handleSubmit}
              disabled={
                !formik.isValid ||
                !formik.dirty ||
                uploadedPercent?.length !== 0
              }
            >
              {t("Post")}
            </Button>
          )}
        </h6>
      </section>
    </>
  );
};

export default Composepsotcard;
