import { createSlice } from "@reduxjs/toolkit";
import { getFeaturedPostList, uploadFile } from "./thunk";
import { status } from "../../constants";

const initialState = {
  loader: false,
  status: status.IDLE,
  list: [],
};

const slice = createSlice({
  name: "featuredPostList",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeaturedPostList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(getFeaturedPostList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.imageLoader = false;
      state.list = action.payload?.data?.resdata;
    });
    builder.addCase(getFeaturedPostList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.list = initialState.list;
    });
  },
});

export default slice.reducer;
