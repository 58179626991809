import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getProfile, getTransaction } from "../../../../redux/actions";
import moment from "moment";
import EngagementSummary from "./EngagementSummary";
import FansSummary from "./FansSummary";

const Staticsbalnce = ({ tab, subTab }) => {
  const { t } = useTranslation();
  const { user } = useSelector((s) => s.setting);
  const { transaction, earningStatistics } = useSelector((s) => s.statement);
  const [data, setData] = useState();

  const userId = user?._id;
  const dispatch = useDispatch();
  const getTransactionList = () => {
    const data = {
      limit: 1000,
      page: 1,
      userId: userId,
      type: "earnings",
    };
    dispatch(getTransaction(data));
  };

  useEffect(() => {
    getTransactionList();
    getProfile();
  }, []);

  useEffect(() => {
    if (earningStatistics?.length) {
      const array = earningStatistics?.[0]?.data;
      const posts = array?.find((i) => i.transactionType === "Posts");
      const subscribe = array?.find((i) => i.transactionType === "Subscribe");
      const tips = array?.find((i) => i.transactionType === "Tips");
      const messages = array?.find((i) => i.transactionType === "Messages");
      setData({
        posts,
        subscribe,
        tips,
        messages,
      });
    }
  }, [earningStatistics]);

  const transactionList = () => {
    return (
      <div className="transaction_section">
        {transaction?.data?.map((val) => (
          <div className="border-bottom py-2">
            <div class="row">
              <div class="col">
                <h5 className="sidebar_subheading">{t("Date/Time")}</h5>
                <h5 className="transactions_value">
                  {moment(val?.createdAt).format("lll")}
                </h5>
              </div>
              <div class="col">
                <h5 className="sidebar_subheading">{t("Transaction Type")}</h5>
                <h5 className="transactions_value">{val?.transactionType}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h5 className="sidebar_subheading">{t("Net Amount")}</h5>
                <h5 className="transactions_value">
                  ${val?.netAmount ? val?.netAmount?.toFixed(2) : "0.00"}
                </h5>
              </div>
              <div class="col">
                <h5 className="sidebar_subheading">{t("Status")}</h5>
                {val?.status === "success" ? (
                  <span className="success_status">{val?.status}</span>
                ) : (
                  <span className="success_status">{val?.status}</span>
                )}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h5 className="sidebar_subheading">{t("Description")}</h5>
                <h5 className="transactions_value">{val?.text}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {(tab === "statement" || tab === "overview") && (
        <>
          <section className="Staticsbalnce commonBox border">
            <div className="Staticsbalnce_inner">
              <div className="d-flex justify-content-center">
                <div className="current_balnce p-2 border-end">
                  <h3>
                    {/* $ {walletCredit ? walletCredit?.toFixed(2) : 0.0} */}${" "}
                    {user?.currentBalance
                      ? user?.currentBalance?.toFixed(2)
                      : 0.0}
                  </h3>
                  <p>{t("Current balance")}</p>
                </div>
                <div className="current_balnce p-2">
                  <h3>
                    ${" "}
                    {user?.pendingBalance
                      ? user?.pendingBalance?.toFixed(2)
                      : 0.0}
                  </h3>
                  <p>{t("Pending balance")}</p>
                </div>
              </div>
              {/* <div className="weekly_payout p-2 border-top">
                <h5 className="sidebar_head">{t("Weekly payouts")}</h5>
                <p>
                  {t(
                    "Once your balance is over $20 next payout will be scheduled on: Aug 14, 2023"
                  )}
                </p>
              </div> */}
            </div>
          </section>
        </>
      )}

      {tab == "statement" && (
        <>
          <div className="earniing_div">
            <div className=" p-2 border-top card_boader my-3">
              <h5 className="sidebar_head border-bottom py-1">
                {t("Earnings")}
              </h5>
              {transaction?.data?.length > 0 ? (
                transactionList()
              ) : (
                <p className="m-0">{t("No earnings for this period")}</p>
              )}
            </div>
          </div>
        </>
      )}

      {tab == "overview" && (
        <>
          <div className="earniing_div">
            <div className="p-2 border-top commonBox border  my-3">
              <div
                className={`${
                  transaction?.data?.length !== 0 && "mb-4"
                } py-2 border-bottom`}
              >
                <h5 className="sidebar_head border-bottom py-1">
                  {t("Earnings")}
                </h5>
                {transaction?.data?.length > 0 ? (
                  transactionList()
                ) : (
                  <p className="m-0">{t("No earnings for this period")}</p>
                )}
              </div>
              <div className="border-bottom py-2">
                <div class="row">
                  <div class="col">
                    <h5 className="sidebar_subheading">
                      {t("Profile visitors")}
                    </h5>
                    {data?.subscribe && (
                      <h5 className="sidebar_head">{user?.viewCount || "0"}</h5>
                    )}
                  </div>
                  {data?.subscribe && (
                    <div class="col">
                      <h5 className="sidebar_subheading">
                        {t("Subscriptions earnings")}
                      </h5>
                      <h5 className="sidebar_head">
                        $
                        {data?.subscribe
                          ? data?.subscribe?.netAmount?.toFixed(2)
                          : "0.00"}
                      </h5>
                    </div>
                  )}
                </div>
                {data?.subscribe && (
                  <div class="row">
                    <div class="col">
                      <h5 className="sidebar_subheading">
                        {t("New subs/Renews")}
                      </h5>
                      <h5 className="sidebar_head">{"0 / 0"}</h5>
                    </div>
                    <div class="col"></div>
                  </div>
                )}

                {Number(user?.viewCount) === 0 && !data?.subscribe && (
                  <p className="m-0">{t("No earnings for this period")}</p>
                )}
              </div>
              <div className=" border-bottom py-2">
                <div class="row">
                  <div class="col">
                    <h5 className="sidebar_subheading">{t("Posts")}</h5>
                    {data?.posts && (
                      <h5 className="sidebar_head">
                        {data?.posts ? data?.posts?.records?.length : 0}
                      </h5>
                    )}
                  </div>
                  {data?.posts && (
                    <div class="col">
                      <h5 className="sidebar_subheading">
                        {t("Posts earnings")}
                      </h5>
                      <h5 className="sidebar_head">
                        $
                        {data?.posts
                          ? data?.posts?.netAmount?.toFixed(2)
                          : "0.00"}
                      </h5>
                    </div>
                  )}
                </div>
                {data?.posts && (
                  <div class="row">
                    <div class="col">
                      <h5 className="sidebar_subheading">{t("Posts views")}</h5>
                      <h5 className="sidebar_head">{0}</h5>
                    </div>
                  </div>
                )}
                {!data?.posts && (
                  <p className="m-0">
                    {t("No Post activity during selected period")}
                  </p>
                )}
              </div>
              <div className=" border-bottom py-2">
                <div class="row">
                  <div class="col">
                    <h5 className="sidebar_subheading">{t("Messages")}</h5>
                    {data?.messages && (
                      <h5 className="sidebar_head">
                        {data?.messages ? data?.messages?.records?.length : 0}
                      </h5>
                    )}
                  </div>
                  {data?.messages && (
                    <div class="col">
                      <h5 className="sidebar_subheading">
                        {t("Messages earnings")}
                      </h5>
                      <h5 className="sidebar_head">
                        ${" "}
                        {data?.messages
                          ? data?.messages?.netAmount?.toFixed(2)
                          : "0.00"}
                      </h5>
                    </div>
                  )}
                </div>
                {!data?.messages && (
                  <p className="m-0">
                    {t("No Mass-message activity during selected period")}
                  </p>
                )}
              </div>

              <div className=" border-bottom py-2">
                <div class="row">
                  <div class="col">
                    <h5 className="sidebar_subheading">{t("Tips")}</h5>
                    {data?.tips && (
                      <h5 className="sidebar_head">
                        {data?.tips ? data?.tips?.records?.length : 0}
                      </h5>
                    )}
                  </div>
                  {data?.tips && (
                    <div class="col">
                      <h5 className="sidebar_subheading">
                        {t("Tips earnings")}
                      </h5>
                      <h5 className="sidebar_head">
                        ${" "}
                        {data?.tips
                          ? data?.tips?.netAmount?.toFixed(2)
                          : "0.00"}
                      </h5>
                    </div>
                  )}
                </div>

                {!data?.tips && (
                  <p className="m-0">
                    {t("No Mass-message activity during selected period")}
                  </p>
                )}
              </div>

              {/* <div className=" border-bottom py-2">
                <h5 className="sidebar_subheading">{t("Streaming")}</h5>
                <p className="m-0">
                  {t("No Streaming activity during selected period")}
                </p>
              </div> */}
            </div>
          </div>
        </>
      )}

      {tab == "engagment" && <EngagementSummary subTab={subTab} />}

      {tab == "fans" && <FansSummary />}
    </>
  );
};

export default Staticsbalnce;
