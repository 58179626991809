import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BsBookmark, BsFillSendFill, BsFillBookmarkFill } from "react-icons/bs";
import { Col, Container, Row } from "react-bootstrap";
import User1 from "../../../images/user1.svg";
import LogoW from "../../../images/logoW.png";
import Post1 from "../../../images/post1.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import User5 from "../../../images/user3.png";
import { getTimeAgo } from "../../../helpers/time";
import User from "../../../images/user.png";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PiDotsThreeCircleVerticalBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { BiSolidSend } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import DummmyImg from "../../../images/dummyBack.jpeg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CgRemove, CgUnblock } from "react-icons/cg";
import { BiBlock } from "react-icons/bi";
import { MdAddLink } from "react-icons/md";
import { VscVerified } from "react-icons/vsc";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  addPostToArchive,
  addPostToPrivateArchive,
} from "../../../redux/actions";
import PostViewer from "../../common/PostViewer/PostViewer";
import { formatText } from "../../../helpers/formatPostText";

const ArchivePostComponent = (props) => {
  const {
    data,
    key,
    screen,
    restorePost,
    getPrivateArchivePostList,
    getArchivePostList,
    commonFunction,
  } = props;
  const media = data?.media;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const baseUrl = window.location.protocol + "//" + window.location.host;

  const handleAddPostToPrivateArchive = (dta) => {
    const data = {
      postId: [dta?._id],
    };
    dispatch(addPostToPrivateArchive(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getArchivePostList();
        commonFunction();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleAddPostToArchive = (dta) => {
    const data = {
      postId: [dta?._id],
    };
    dispatch(addPostToArchive(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPrivateArchivePostList();
        commonFunction();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const [showCommentPop, setCommentPop] = useState(false);

  const handleCommentPop = () => setCommentPop(!showCommentPop);

  return (
    <div className="Post" key={key}>
      {data && (
        <>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <div className="d-flex align-items-center">
              <img src={LogoW} alt="" className="postUserPic" />
              <div className="mx-3 fansDetails">
                <h6 className="text-bold mb-0 ">{t("Fansroom")}</h6>
                <h6 className="text-small mb-0">@fansroom23</h6>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="text-small mx-2 mb-0">
                {getTimeAgo(data?.createdAt)}
              </h6>

              <div className="three_dot_dropdown">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                        <path
                          d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                          fill="black"
                        />
                        <path
                          d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                          fill="black"
                        />
                        <path
                          d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_87_10716">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <CopyToClipboard
                      text={baseUrl + "/post-details/" + data?.slug}
                      onCopy={() => toast.success(t("Link to post has been copied to clipboard!"))}
                    >
                      <DropdownItem>
                        <span>{t("Copy Link to post")}</span>
                      </DropdownItem>
                    </CopyToClipboard>
                    {screen === "archive" && (
                      <DropdownItem
                        onClick={() => handleAddPostToPrivateArchive(data)}
                      >
                        <span>{t("Private Archive Post")}</span>
                      </DropdownItem>
                    )}

                    <DropdownItem onClick={() => restorePost(data)}>
                      <span>{t("Restore Post")}</span>
                    </DropdownItem>
                    {screen === "privateArchive" && (
                      <DropdownItem
                        onClick={() => handleAddPostToArchive(data)}
                      >
                        <span>{t("Archive Post")}</span>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className="PostQuiz shadow1 p-3 mb-3">
            <div className="top my-2">
              <h6 className="text-bold ">
                {data?.caption}
                {formatText(data?.description)}
              </h6>
            </div>
            {data?.postType === "quiz" && (
              <div className="mb-2">
                {data?.quizOption?.map((option, index) => {
                  var totalValue = 0;
                  const quizValue = option?.quizCount || 0;
                  totalValue = totalValue + quizValue;

                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative "
                    >
                      <input
                        type="radio"
                        className="position-absolute text-white  w-100 h-100"
                        id={option?.option}
                        disabled
                      />

                      <label
                        className=" label px-3 py-2 d-block  w-100 position-relative"
                        htmlFor={option?.option}
                      >
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            {data?.postType === "poll" && (
              <div className="mb-2">
                {data?.pollOption?.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative "
                    >
                      <input
                        type="radio"
                        // name="poll"
                        className="position-absolute text-white  w-100 h-100"
                        id={option?.option}
                        disabled
                      />
                      <label
                        htmlFor={option?.option}
                        className=" label px-3 py-2 d-block  w-100 position-relative"
                      >
                        <span
                          className="fill position-absolute  w-100 h-100"
                          style={{ maxWidth: option?.option }}
                        ></span>
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <PostViewer media={media} data={data}/>

          <div className="my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative">
            <div className="d-flex align-items-center postaction">
              <span>
                <AiOutlineHeart
                  size={28}
                  color={"rgb(0, 0, 0 , 0.3)"}
                  className="pointer"
                />
              </span>
              <span className="pointer">
                <FaRegComment size={24} color={"rgb(0, 0, 0 , 0.3)"} />
              </span>

              <span className="pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g opacity="0.5" clip-path="url(#clip0_87_10701)">
                    <path
                      d="M11 22C8.0618 22 5.29942 20.8558 3.2218 18.7782C1.14417 16.7006 0 13.9382 0 11C0 8.0618 1.14421 5.29947 3.2218 3.2218C5.29938 1.14413 8.0618 0 11 0C13.9382 0 16.7006 1.14417 18.7782 3.2218C20.8558 5.29942 22 8.0618 22 11C22 13.9382 20.8558 16.7005 18.7782 18.7782C16.7006 20.8559 13.9382 22 11 22ZM11 1.375C5.69276 1.375 1.375 5.69276 1.375 11C1.375 16.3072 5.69276 20.625 11 20.625C16.3072 20.625 20.625 16.3072 20.625 11C20.625 5.69276 16.3072 1.375 11 1.375Z"
                      fill="gray"
                    />
                    <path
                      d="M11 10.3125C10.0523 10.3125 9.28125 9.54147 9.28125 8.59375C9.28125 7.64603 10.0523 6.875 11 6.875C11.9477 6.875 12.7188 7.64603 12.7188 8.59375C12.7188 8.97342 13.0265 9.28125 13.4062 9.28125C13.786 9.28125 14.0938 8.97342 14.0938 8.59375C14.0938 7.12418 13.0634 5.89153 11.6875 5.57795V4.8125C11.6875 4.43283 11.3797 4.125 11 4.125C10.6203 4.125 10.3125 4.43283 10.3125 4.8125V5.57795C8.93655 5.89153 7.90625 7.12418 7.90625 8.59375C7.90625 10.2997 9.2941 11.6875 11 11.6875C11.9477 11.6875 12.7188 12.4585 12.7188 13.4062C12.7188 14.354 11.9477 15.125 11 15.125C10.0523 15.125 9.28125 14.354 9.28125 13.4062C9.28125 13.0266 8.97346 12.7188 8.59375 12.7188C8.21404 12.7188 7.90625 13.0266 7.90625 13.4062C7.90625 14.8758 8.93655 16.1085 10.3125 16.4221V17.1875C10.3125 17.5672 10.6203 17.875 11 17.875C11.3797 17.875 11.6875 17.5672 11.6875 17.1875V16.4221C13.0634 16.1085 14.0938 14.8758 14.0938 13.4062C14.0938 11.7003 12.7059 10.3125 11 10.3125Z"
                      fill="gray"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_87_10701">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span>{t("SEND TIPS")} </span>
            </div>
            <div className="">
              <BsBookmark
                size={20}
                color={"rgb(0, 0, 0 , 0.3)"}
                className="pointer"
              />
            </div>
          </div>

          <div className="my-4 endline w-100"></div>
        </>
      )}
    </div>
  );
};

export default ArchivePostComponent;
