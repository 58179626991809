import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const AddNewCategory = ({ addCategory, setAddCategory }) => {
  const handleAddCategory = () => {
    setAddCategory(!addCategory);
  };
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={addCategory}
        onHide={handleAddCategory}
        backdrop="static"
        keyboard={false}
        centered
        className="addNewPop"
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>{t("Create New Category")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label">
                  {t("Category Name")}
                </label>
                <input type="text" className="form-control" />
                <p className="text-end formText pt-2">0/64</p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button
            onClick={handleAddCategory}
            variant="transparent"
            className="border-0 p-0 fw-bold px-3"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleAddCategory}
            variant="transparent"
            className="border-0 p-0 fw-bold px-3"
          >
            {t("Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewCategory;
