import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import AuthFooter from "../../../components/Footer";

const Brand = () => {
  return (
    <>
      <section className="CookiesPolicy brand_resources position-relative p-0">
        <div className="header_only_logo py-3 px-4">
          <div className="logo_box">
            <a href="/">
              <img src={Logo} alt="" className="img-fluid" />
            </a>
          </div>

          <div className="brand_heading">
            <h1>Brand</h1>
            <h1>Resources</h1>

            <div className="line_divider my-4"></div>

            <p>
              Logos, social icons and other templates will help you use our
              brand.
            </p>

            <Button className="brand_download">Download</Button>
          </div>
        </div>

        <div className="section_resources">
          <Container fluid>
            <Row className="align-items-center pt-5">
              <Col lg={6} md={6} sm={12}>
                <div className="left_img">
                  <img
                    src="/images/brands/Brand Guidelines.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="right-content">
                  <h2>Brand </h2>
                  <h2>Guidelines</h2>
                  <div className="line_divider bg-dark my-4"></div>
                  <p>
                    We love that you love Fansroom. We want to make it easy for
                    you to use our brand in the right way. Explore this quick
                    guide to our basic design elements to see how to do it.
                  </p>
                  <Button className="brand_download bg-dark text-light">
                    Download
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center pt-5">
              <Col lg={6} md={6} sm={12} className="order-lg-2">
                <div className="left_img">
                  <img
                    src="/images/brands/Fansroom-logo.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} className="order-lg-1">
                <div className="right-content">
                  <h2>Fansroom logo </h2>

                  <div className="line_divider bg-dark my-4"></div>
                  <p>
                    Looking to download the Fansroom logo? Artwork files for
                    print (.EPS), broadcast, and web (.SVG and .PNG) can be
                    downloaded from here. For proper usage, refer to the Logo
                    section in our Brand Guidelines.
                  </p>
                  <Button className="brand_download bg-dark text-light">
                    Download
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center pt-5">
              <Col lg={6} md={6} sm={12}>
                <div className="left_img">
                  <img
                    src="/images/brands/Social-icons.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="right-content">
                  <h2>Social icons </h2>
                  <div className="line_divider bg-dark my-4"></div>
                  <p>
                    Looking to represent your presence on Fansroom, either alone
                    or alongside other social icons? It’s preferred that our
                    logo is free of a container, but you’ll also find the
                    options to use a circle, square, or square with rounded
                    corners. For more details check out our Brand Guidelines.
                  </p>
                  <Button className="brand_download bg-dark text-light">
                    Download
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center pt-5">
              <Col lg={6} md={6} sm={12} className="order-lg-2">
                <div className="left_img">
                  <img
                    src="/images/brands/Logo-pairing.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>

              <Col lg={6} md={6} sm={12} className="order-lg-1">
                <div className="right-content">
                  <h2>Logo pairing</h2>

                  <div className="line_divider bg-dark my-4"></div>
                  <p>
                    We use the logo pairing lockups to clearly show an account
                    on Fansroom. When pairing name and username with the
                    Fansroom logo, make sure to use our logo once, in blue or
                    white, and we recommend pairing it with the name and
                    username in black. Observe our clear space rules, and scale
                    the text to 100% of the height of the logo. Feel free to use
                    a typeface that’s from your brand’s design system. Check out
                    our Brand Guidelines for more details.
                  </p>
                  <Button className="brand_download bg-dark text-light">
                    Download
                  </Button>
                </div>
              </Col>
            </Row>

            {/* <Row className="align-items-center pt-5">
            <Col lg={6} md={6} sm={12}>
              <div className="left_img">
                   <img src="/images/brands/Brand Guidelines.png" alt="" className="img-fluid"/>
              </div>
           </Col> 

           <Col lg={6} md={6} sm={12}>
                 <div className="right-content">
                    <h2>Our old logo</h2>
                  
                     <div className="line_divider bg-dark my-4"></div>
                    <p>Please follow our Brand Guidelines and try to avoid using the old Fansroom logo. But here it is if you desperately need it.</p>
                    <Button className="brand_download bg-dark text-light">Download</Button>
                 </div>
            </Col>  
      </Row> */}
          </Container>
        </div>
      </section>
      <AuthFooter />
    </>
  );
};

export default Brand;
