import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import {
  chatDetails,
  chatHistory,
  chatList,
  deleteChat,
  massMessageListing,
  sendMassMessage,
} from "./thunk";

const initialState = {
  loader: false,
  status: status.IDLE,
  chatDetail: {},
  messageHistory: [],
  chatLists: [],
  buttonLoader: false,
  massMessageList: [],
  lastMessage: "",
  currentChatId : "",
  allNewMsgCount : 0,
  newMsg : null
};

const slice = createSlice({
  name: "chat",
  initialState: { ...initialState },
  reducers: {
    updateChatHistory: (state, action) => {
      state.messageHistory = [...state.messageHistory, action.payload];
    },
    updateLastMessage: (state, action) => {
      state.lastMessage = action.payload;
    },
    clearChatHistory: (state, action) => {
      state.messageHistory = initialState.messageHistory;
    },
    updateChatId : (state , action) =>{
      state.currentChatId =  action.payload
    },
    updateMsgCount : (state , action) =>{
      state.allNewMsgCount = action.payload
    },
    updateNewMsg : (state , action) =>{
      state.newMsg = action.payload
    },
    clearNewMsg : (state , action) =>{
      state.newMsg = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(chatDetails.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(chatDetails.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.chatDetail = action.payload?.data;
    });
    builder.addCase(chatDetails.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.chatDetail = initialState.chatDetail;
    });
    builder.addCase(chatHistory.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(chatHistory.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.messageHistory = action.payload?.data?.reverse();
    });
    builder.addCase(chatHistory.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.messageHistory = initialState.messageHistory;
    });
    builder.addCase(chatList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(chatList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.chatLists = action.payload?.data;
    });
    builder.addCase(chatList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.chatLists = initialState.chatLists;
    });
    builder.addCase(deleteChat.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(deleteChat.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(deleteChat.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(sendMassMessage.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(sendMassMessage.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(sendMassMessage.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(massMessageListing.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(massMessageListing.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.massMessageList = action.payload?.data;
    });
    builder.addCase(massMessageListing.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.massMessageList = initialState.massMessageList;
    });
  },
});

export default slice.reducer;
export const { updateChatHistory,updateChatId,updateMsgCount, updateLastMessage, clearChatHistory ,updateNewMsg , clearNewMsg } =
  slice.actions;
