import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          if (tooltipItem.dataset.label === "Earnings") {
            return `Earnings : $ ${tooltipItem.raw}`;
          }
          return `Subscribers : ${tooltipItem.raw}`;
        },
      },
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      ticks: {
        callback: function (value) {
          return value.toFixed(2);
        },
      },
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        callback: function (value) {
          return `$${value.toFixed(2)}`;
        },
      },
    },
  },
};
const Subscriptions = () => {
  const [tab, setTab] = useState("all");
  const { t } = useTranslation();
  const [labelData, setLabelData] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const { fansSubscription } = useSelector((s) => s.statistics);

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
    const allLabel = fansSubscription?.graphData?.map((itm) => {
      return itm?._id;
    });
    setLabelData(allLabel);
    const subscriptionCount = fansSubscription?.graphData?.map((itm) => {
      return itm?.count;
    });
    const netSubscriptionAmount = fansSubscription?.graphData?.map((itm) => {
      return itm?.totalNetAmount;
    });

    const datasets = [
      {
        label: "Subscribers",
        data: subscriptionCount,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
      {
        label: "Earnings",
        data: netSubscriptionAmount,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y1",
      },
    ];
    setDataSets(datasets);
  };

  useEffect(() => {
    handleTabSelectedtion("all");
  }, [fansSubscription]);

  const data = {
    labels: labelData,
    datasets: dataSets,
  };

  return (
    <section className="fansSubscription">
      <div className="common_table border-0">
        <div className="CardBody py-1  innner_tabs">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="all"
            className="d-flex  align-items-center"
          >
            <Nav variant="pills" className="">
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="all"
                  onClick={() => handleTabSelectedtion("all")}
                  active={tab === "all"}
                >
                  {t("All")}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small "
                  eventKey="renew"
                  onClick={() => handleTabSelectedtion("renew")}
                  active={tab === "renew"}
                >
                  {t("Renews")}
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item className="">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small "
                      eventKey="Newsubscribers"
                      onClick={() => handleTabSelectedtion("Newsubscribers")}
                      active={tab === "Newsubscribers"}
                    >
                      New Subscribers
                    </Nav.Link>
                  </Nav.Item> */}
            </Nav>
          </Tab.Container>
        </div>
        <Tab.Content className="">
          <Tab.Pane eventKey="all" active={tab === "all"}>
            {fansSubscription?.graphData?.length > 0 ? (
              <div className="my-3">
                <Line options={options} data={data} />
              </div>
            ) : (
              <div className="noGraphData">
                {t("No data during selected period")}
              </div>
            )}
          </Tab.Pane>

          {/* <Tab.Pane eventKey="renew" active={tab === "renew"}>
            <div className="my-3">
              <Line options={options} data={data} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Newsubscribers" active={tab === "Newsubscribers"}>
            <div className="my-3">{t("Newsubscribers")}</div>
          </Tab.Pane> */}
        </Tab.Content>
      </div>
      {fansSubscription?.records?.length > 0 && (
        <div className="my-2">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-muted fw-normal">Name</th>
                  <th className="text-muted fw-normal">Price</th>
                  <th className="text-muted fw-normal">Total duration</th>
                </tr>
              </thead>
              <tbody>
                {fansSubscription?.records?.map((i) => {
                  const user = i?.userDetails;
                  const earn = i?.earntransactionData;
                  if (user) {
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-10">
                            <div className="imgWrp flex-shrink-0">
                              {user?.profileImage ? (
                                <img
                                  src={user?.profileImage}
                                  alt=""
                                  style={{ height: 50, width: 50 }}
                                  className="img-fluid rounded-circle object-fit-cover"
                                />
                              ) : (
                                <div className="user_img_fansstats shadow2">
                                  {user?.firstName && user?.lastName
                                    ? `${user?.firstName.slice(
                                        0,
                                        1
                                      )}${user?.lastName.slice(0, 1)}`
                                    : user?.name?.slice(0, 1)}
                                </div>
                              )}
                            </div>
                            <div className="content">
                              <p className="m-0 text-dark fw-bold">
                                {user?.firstName
                                  ? user?.firstName +
                                    " " +
                                    (user?.lastName || "")
                                  : user?.name}
                              </p>
                              <p className="m-0 text-muted">
                                @{user?.userName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="fw-bold">
                          {earn && earn?.netAmount > 0
                            ? `$${earn?.netAmount?.toFixed(2)}`
                            : "Free"}
                        </td>
                        <td>{moment(i?.createdAt).fromNow()}</td>
                      </tr>
                    );
                  }
                  return;
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </section>
  );
};

export default Subscriptions;
