import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const becameCreator = createAsyncThunk(
  "becameCreator",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.becomeCreator,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getVerificationStatus = createAsyncThunk(
  "getVerificationStatus",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.getVerificationStatus,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
