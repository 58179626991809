import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const CustomDatefillter = ({
  toggledropdown,
  setStartDateValue,
  startDateValue,
  setEndDateValue,
  endDateValue,
}) => {
  const { t } = useTranslation();
  const [showCustom, setShowCustom] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [startD, setStartD] = useState("");
  const [endD, setEndD] = useState("");
  const [lable, setLable] = useState("Last 7 days");

  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const yearBeforeLast = currentYear - 2;

  const formatDateRange = (startDate, endDate) => {
    const start = moment(startDate).format("h:mm a, MMM DD, YYYY");
    const end = moment(endDate).format("h:mm a, MMM DD, YYYY");
    const timezone = moment.tz.guess();
    const timezoneOffset = moment().format("Z");
    setSelectedRange(
      `${start} - ${end} (local time ${timezone} ${timezoneOffset})`
    );
    return `${start} - ${end} (local time ${timezone} ${timezoneOffset})`;
  };
  const calculateDateRange = (days) => {
    const currentDate = new Date();
    let endDate = new Date();
    let startDate = new Date(endDate);
    if (typeof days === "string" && days.includes("year")) {
      const year = parseInt(days.split(" ")[1], 10);
      startDate.setFullYear(year, 0, 1);
      endDate.setFullYear(year, 11, 31);
      if (endDate > currentDate) {
        endDate = currentDate;
      }
    } else if (days === "All time") {
      startDate.setFullYear(
        yearBeforeLast,
        currentDate.getMonth(),
        currentDate.getDate()
      );
    } else {
      startDate.setDate(startDate.getDate() - days);
    }

    formatDateRange(startDate, endDate);
    return { startDate, endDate };
  };

  const handleSelect = (days) => {
    const { startDate, endDate } = calculateDateRange(days);
    setStartDateValue(startDate);
    setEndDateValue(endDate);
  };

  useEffect(() => {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000);

    setStartDateValue(startDate);
    setEndDateValue(endDate);
    formatDateRange(startDate, endDate);
  }, []);

  const save = () => {
    if (!startD) {
      toast.warning(t("Start date is required"));
    } else if (!endD) {
      toast.warning(t("End date is required"));
    } else {
      const formattedStartD = moment(startD)
        .startOf("day")
        .format("YYYY-MM-DD");
      const formattedEndD = moment(endD).endOf("day").format("YYYY-MM-DD");

      setStartDateValue(formattedStartD);
      setEndDateValue(formattedEndD);
      formatDateRange(formattedStartD, formattedEndD);
      setLable("All time");
      setShowCustom(false);
    }
  };

  return (
    <>
      <div className="date_fillter commonBox border px-1 py-2 mt-3">
        <UncontrolledDropdown>
          <DropdownToggle
            className="w-100 d-flex align-items-center justify-content-between"
            onClick={toggledropdown}
          >
            <div className="date_heading text-start">
              <h5>{lable}</h5>
              <p>{selectedRange}</p>
            </div>
            <div className="downarrow">{arrowdown}</div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setShowCustom(!showCustom)}>
              Custom
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() => {
                handleSelect(1);
                setLable("Last 24 hours");
              }}
            >
              Last 24 hours
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(7);
                setLable("Last 7 days");
              }}
            >
              Last 7 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(30);
                setLable("Last 30 days");
              }}
            >
              Last 30 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(90);
                setLable("Last 90 days");
              }}
            >
              Last 90 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(180);
                setLable("Last 180 days");
              }}
            >
              Last 180 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(365);
                setLable("Last 365 days");
              }}
            >
              Last 365 days
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() => {
                handleSelect(`year ${currentYear}`);
                setLable(`${currentYear} year`);
              }}
            >
              {currentYear}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(`year ${lastYear}`);
                setLable(`${lastYear} year`);
              }}
            >
              {lastYear}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect(`year ${yearBeforeLast}`);
                setLable(`${yearBeforeLast} year`);
              }}
            >
              {yearBeforeLast}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSelect("All time");
                setLable("All time");
              }}
            >
              All time
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <Modal
        show={showCustom}
        onHide={() => setShowCustom(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="expiration_modal"
      >
        <Modal.Body>
          <div className="calednder_Mod">
            <div className="add_heading d-flex px-4 pt-4 justify-content-between">
              <h5>{t("CHOOSE DATE")}</h5>
              <a href="javascript:void(0)" onClick={() => setShowCustom(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                  />
                </svg>
              </a>
            </div>

            <div className="Expiraion_form px-4 mt-3">
              <Form>
                <FormGroup>
                  <Label for="postprice">{t("Start Date")}</Label>
                  <DatePicker
                    className="mb-3"
                    placeholderText={t("Start Date")}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    wrapperClassName="commonDatepickerWrapper"
                    selected={startD}
                    onChange={(date) => setStartD(date)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="postprice">{t("End Date")}</Label>
                  <DatePicker
                    minDate={startD}
                    maxDate={new Date()}
                    className="mb-3"
                    placeholderText={t("End Date")}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    wrapperClassName="commonDatepickerWrapper"
                    selected={endD}
                    onChange={(date) => setEndD(date)}
                  />
                </FormGroup>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel_btn" onClick={() => setShowCustom(false)}>
            Close
          </Button>
          <Button onClick={save}>{t("Save")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomDatefillter;

const arrowdown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-chevron-down"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
    />
  </svg>
);
