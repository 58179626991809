import React from "react";
import Settings from "./Settings";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Settings>
      <div className="mt-1 ">
        <div className="mx-3 d-flex justify-content-between align-items-center px-3 py-3">
          <h6 className=" text-bold">{t("Notifications")}</h6>
        </div>
        <div className="endline"></div>
        <div className="mx-4 px-2 my-2 py-2">
          <h6 className="text-small fw-sbold mb-0">{t("Preferences")}</h6>
        </div>
        <div className="endline"></div>
        <div
          className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer"
          onClick={() => navigate("/settings/notification/push-notifications")}
        >
          <h6 className="text-small  mb-0 px-2">{t("Push Notifications")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/notification/email-notifications")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small  mb-0 px-2">{t("Email Notifications")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/notification/site-notifications")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small  mb-0 px-2">{t("Site Notifications")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/notification/toast-notifications")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small  mb-0 px-2">{t("Toast Notifications")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/notification/SMS-notifications")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small  mb-0 px-2">{t("SMS Notifications")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
        <div className="mx-4 px-2 my-2 py-2">
          <h6 className="text-small fw-sbold mb-0">{t("Other")}</h6>
        </div>
        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/notification/telegram-bot")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small  mb-0 px-2">{t("Telegram bot")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
      </div>
    </Settings>
  );
};

export default Notifications;
