import React, { useEffect, useState } from "react";
import DummyPostImg from "../../images/dummyPost.png";
import { CiLock } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import PaymentMethodModal from "./Modal/PaymentMethodModa";
import {
  generatePath,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";

const DummyPost = ({ handleSubscribe, type, price, data, getPostDetailss }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { artistDetailss } = useSelector((s) => s.artist);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const location = useLocation();
  const { subscribedArtistList: followingList } = useSelector(
    (s) => s.collection
  );

  const checkSubscribe = (userId) => {
    setIsSubscribed(
      followingList?.some((val) => val.followerId === userId) || false
    );
  };

  useEffect(() => {
    if (data?.userId) {
      checkSubscribe(data?.userId);
    }
  }, [data]);

  const { pathname, search } = location;

  const handleUnlock = (dta) => {
    const isPostDetailsPath = pathname.includes("/post-details/");

    if (isPostDetailsPath) {
      setOpenPaymentMethodModal(true);
      setSelectedPost(dta);
    } else {
      var path = generatePath("/post-details/:slug", { slug: data?.slug });

      navigate({
        pathname: path,
        search: createSearchParams({
          unlock: true,
        }).toString(),
      });
    }
  };

  useEffect(() => {
    if (search === "?unlock=true") {
      // setOpenPaymentMethodModal(true);
      setSelectedPost(data);
      if (!isSubscribed) {
        handleSubscribe(data);
      } else {
        setOpenPaymentMethodModal(true);
      }
    }
  }, [search, isSubscribed]);

  return (
    <>
      <div className="w-100 dummyPostCont">
        <img src={DummyPostImg} alt="" className="img-fluid" />
        <CiLock className="postLockIcon" size={45} color="#b8b8b8" />
        <div className="dummyPostaction">
          <div className="my-3 d-flex justify-content-center">
            {!isSubscribed ? (
              <button
                className="addMoreBtn "
                onClick={() => handleSubscribe(data)}
              >
                {artistDetailss?.subscribePrice > 0
                  ? `Subscribe for $${artistDetailss?.subscribePrice}`
                  : `Subscribe for free`}
              </button>
            ) : (
              <button
                className="addMoreBtn "
                onClick={() => handleUnlock(data)}
              >
                {"Unlock for"} $ {price}
              </button>
            )}
          </div>
        </div>
      </div>
      <PaymentMethodModal
        show={openPaymentMethodModal}
        onHide={() => setOpenPaymentMethodModal(false)}
        data={selectedPost}
        getPostDetailss={getPostDetailss}
      />
    </>
  );
};

export default DummyPost;
