import React, { Fragment, useState, useEffect, useRef } from "react";
import User2 from "../../../images/user7.png";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPoll,
  addQuiz,
  bookmarkPost,
  commentPost,
  getPostList,
  likePost,
  unlikePost,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { BiSolidSend, BiSearch, BiDotsVerticalRounded } from "react-icons/bi";
import LogoW from "../../../images/logoW.png";
import { getTimeAgo } from "../../../helpers/time";
import User from "../../../images/user.png";
import DummmyImg from "../../../images/dummyBack.jpeg";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { useTranslation } from "react-i18next";
import SendTip from "../../common/Modal/SendTip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heart from "../../../images/heart.svg";
import FilledHeart from "../../../images/filledHeart.svg";
import Bookmark from "../../../images/bookmark.svg";
import FilledBookmark from "../../../images/filledBookmark.svg";
import Tip from "../../../images/dollar.svg";
import Comment from "../../../images/comment.svg";
import { VscVerifiedFilled } from "react-icons/vsc";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CommentSection from "../../common/CommentSection/CommentSection";
import DummyPost from "../../common/DummyPost";
import PostViewer from "../../common/PostViewer/PostViewer";
import { Dropdown } from "react-bootstrap";

const BookmarkPosts = (props) => {

  const { t } = useTranslation();
  const { getAllBookmarkedPost, postList } = props;
  const [showSendTip, setShowSendTip] = useState(false);
  const commentBoxRef = useRef();
  const [showCommentBox, setShowCommentBox] = useState(false);
  const userId = useSelector((s) => s.setting)?.user?._id;
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("all");
  const [allList, setAllList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const value = e.target.value.toLowerCase();
    const filterData = allList?.filter((itm) => {
      return itm?.caption?.toLowerCase().includes(value);
    });
    setAllList(filterData);
    if (e.target.value == "") {
      if (activeTab == "all") {
        setAllList(postList);
      } else if (activeTab === "photos") {
        showPhotos();
      } else if (activeTab === "videos") {
        showVideos();
      }
    }
  };

  const handleUserDetails = (dta) => {
    const userDetails = dta?.userDetails;
    const path = generatePath("/:slug", { slug: userDetails?.userName });
    navigate(path);
  };

  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post liked"));
        getAllBookmarkedPost();
      })
      .catch((err) => {});
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post unliked"));
        getAllBookmarkedPost();
      })
      .catch((err) => {});
  };
  const handleBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post Bookmarked"));
        getAllBookmarkedPost();
      })
      .catch((err) => {});
  };

  const handleUnBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Removed from bookmark"));
        getAllBookmarkedPost();
      })
      .catch((err) => {});
  };

  const sendMsg = (dta) => {
    if (msg === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: msg,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          setMsg("");
          toast.success(t("comment posted"));
          getAllBookmarkedPost();
        })
        .catch((err) => {
          toast.error(err.message);
          setMsg("");
        });
    }
  };

  useEffect(() => {
    getAllBookmarkedPost();
  }, []);

  useEffect(() => {
    setAllList(postList);
  }, [postList]);

  const showAllPost = () => {
    setAllList(postList);
    setActiveTab("all");
  };

  const showPhotos = () => {
    const post = postList?.filter((item, index) => {
      if (item?.media?.[0]?.type === "image") {
        return item;
      }
    });
    setActiveTab("photos");
    setAllList(post);
  };

  const showVideos = () => {
    const media = postList?.filter((item, index) => {
      if (item?.media?.[0]?.type === "video") {
        return item;
      }
    });
    setActiveTab("videos");
    setAllList(media);
  };

  const handleKeyPress = (event, dta) => {
    if (event.key === "Enter") {
      sendMsg(dta);
    }
  };

  const handlePollSubmit = (poll, post) => {
    const data = {
      postId: post?._id,
      optionId: poll?._id,
    };
    dispatch(addPoll(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getAllBookmarkedPost();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleQuizSubmit = (quiz, post) => {
    const data = {
      postId: post?._id,
      optionId: quiz?._id,
    };
    dispatch(addQuiz(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getAllBookmarkedPost();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const baseUrl = window.location.protocol + "//" + window.location.host;
  const [showCommentPop, setCommentPop] = useState(false);

  const [selectedPostForTip, setSelectedPostForTip] = useState({});
  const [selectedPostForComment , setSelectedPostForComment] = useState([])

  const handleTip = (dta) => {
    setSelectedPostForTip(dta);
    setShowSendTip(true);
  };
  const handleCommentPop = (dta) => {
    setCommentPop(!showCommentPop);
    setSelectedPostForComment(dta)
  };
  return (
    <div className="py-1">
      <CommentSection
        showCommentPop={showCommentPop}
        handleCommentPop={handleCommentPop}
        commentBoxRef={commentBoxRef}
        msg={msg}
        setMsg={setMsg}
        setCommentPop={setCommentPop}
        data={selectedPostForComment}
        handleKeyPress={handleKeyPress}
        sendMsg={sendMsg}
      />
      <div className="px-4">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h5 className="text-custom">{t("RECENT")}</h5>
          <h6 className="d-flex align-items-center">
            <div className="inputSearch">
              <input
                type="text"
                placeholder={t("Search...")}
                value={searchInput}
                onChange={(e) => handleSearch(e)}
              />
              <BiSearch color="#636363" size={20} />
            </div>
          </h6>
        </div>
        <div className="d-flex align-items-center usersTitle my-4">
          <h5
            className={`${activeTab == "all" && "activeTabs"}`}
            onClick={showAllPost}
          >
            {t("All")} {postList?.length}
          </h5>
          {/* <h5
            className={`${activeTab == "photos" && "activeTabs"}`}
            onClick={showPhotos}
          >
            {t("Photos")}
          </h5>
          <h5
            className={`${activeTab == "videos" && "activeTabs"}`}
            onClick={showVideos}
          >
            {t("Videos")}
          </h5>
          <h5 className={`${activeTab == "Other" && "activeTabs"}`}>Other</h5>
          <h5 className={`${activeTab == "Locked" && "activeTabs"}`}>Locked</h5> */}
        </div>
        <div>
          {allList.map((data, index) => {
            const userDetials = data?.postDetails?.userDetails;
            var media = data?.postDetails?.media;

            media = media && media?.flat();
            const postDetails = data?.postDetails;
            return (
              <Fragment>
                <div key={index}>
                  <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={userDetials?.profileImage || User}
                        alt=""
                        className="postUserPic"
                      />
                      <div className="mx-3 fansDetails">
                        <h6 className="text-bold mb-0 ">{userDetials?.name}</h6>
                        <h6 className="text-small mb-0">
                          {userDetials?.userName}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex">
                      <h6 className="text-small mx-2">
                        {getTimeAgo(data?.createdAt)}
                      </h6>
                      <h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                            <path
                              d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                              fill="black"
                            />
                            <path
                              d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                              fill="black"
                            />
                            <path
                              d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_87_10716">
                              <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="translate(18) rotate(90)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </h6>
                    </div>
                  </div>
                  <div className="px-3 my-3">
                    <h6 className="text-small" style={{ fontSize: "15px" }}>
                      {data?.caption}
                    </h6>
                  </div>
                  {data?.type === "paid" && data?.isBought === false ? (
                    <DummyPost
                      type={data?.type}
                      price={data?.price}
                      data={data}
                      isSubscribed={true}
                    />
                  ) : (
                    <div className="NewsFeed" id={data?._id}>
                      <PostViewer media={media || []} data={data}/>
                    </div>
                  )}

                  <div className=" w-100 lg-px-2 my-1 position-relative mainPost">
                    <img
                      src={
                        userDetials?.coverImage
                          ? userDetials?.coverImage
                          : DummmyImg
                      }
                      alt=""
                      className="rounded object-cover"
                      style={{ height: "150px" }}
                    />
                    <div
                      className="position-absolute postUserDetails pointer "
                      onClick={() => handleUserDetails(postDetails)}
                    >
                      <img
                        src={
                          userDetials?.profileImage
                            ? userDetials?.profileImage
                            : User
                        }
                        alt=""
                        className="img-fliud"
                      />
                    </div>
                    <div
                      className="postUserText pointer"
                      onClick={() => handleUserDetails(postDetails)}
                    >
                      <div>
                        <h6 className="capitalize text-small ">
                          {userDetials?.name}{" "}
                          <VscVerifiedFilled
                            color="var(--primaryDarkColor)"
                            size={23}
                            className="verifiedIcons mx-2 rounded-circle"
                          />
                        </h6>
                        <h6>{userDetials?.userName}</h6>
                      </div>
                    </div>
                    <Dropdown
                      className="position-absolute"
                      style={{ right: "10px", top: "10px" }}
                    >
                      <Dropdown.Toggle
                        variant="transaprent"
                        className="border-0 p-0"
                        id="dropdown-basic"
                      >
                        <BiDotsVerticalRounded
                          color="white"
                          size={18}
                          className="threeDots"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <ul className="list-unstyled ps-0 mb-0">
                          <li>
                            <Dropdown.Item
                              onClick={() => handleUserDetails(postDetails)}
                            >
                              {t("View Artist Details")}
                            </Dropdown.Item>
                          </li>
                          <li>
                            <CopyToClipboard
                              text={
                                baseUrl + "/" + userDetials?.userName
                              }
                            >
                              <Dropdown.Item>
                                <span>{t("Copy Link to profile")}</span>
                              </Dropdown.Item>
                            </CopyToClipboard>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative">
                    <div className="d-flex align-items-center postaction">
                      <span>
                        {postDetails?.isLiked ? (
                          <img
                            src={FilledHeart}
                            className="actionIcon"
                            onClick={() => handleUnLikePost(postDetails)}
                          />
                        ) : (
                          <img
                            src={Heart}
                            className="actionIcon"
                            onClick={() => handleLikePost(postDetails)}
                          />
                        )}
                      </span>
                      <span
                        onClick={() => handleCommentPop(postDetails)}
                        className="pointer"
                      >
                        <img src={Comment} className="actionIcon" />
                      </span>

                      <span
                        className="pointer"
                        onClick={() => handleTip(postDetails)}
                      >
                        <img src={Tip} className="actionIcon" />
                      </span>
                      <span>{t("SEND TIPS")} </span>
                    </div>
                    <div className="">
                      <img
                        src={FilledBookmark}
                        className="actionIcon"
                        onClick={() => handleUnBookmarkPost(postDetails)}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="light-text">
                      {postDetails?.likes?.length} {t("Likes")}
                    </div>
                    <div className="light-text mx-2">
                      {postDetails?.comments?.length} {t("comments")}
                    </div>
                  </div>
                  <div className="my-4 endline w-100"></div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <SendTip
        show={showSendTip}
        onHide={() => setShowSendTip(false)}
        data={selectedPostForTip}
      />
    </div>
  );
};

export default BookmarkPosts;
