import React, { Profiler, useEffect } from "react";
import Settings from "./Settings";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getProfile, updateProfile } from "../../../redux/actions";
import { toast } from "react-toastify";
import ButtonLoader from "../../../components/common/ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";

const PrivacyAndSafety = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const formik = useFormik({
    initialValues: {
      userStatus: false,
      showSubscriptionOffer: false,
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  useEffect(() => {
    formik.setValues({
      userStatus: user?.userStatus == "false" ? false : true,
      showSubscriptionOffer: user?.showSubscriptionOffer,
    });
  }, [user]);

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Settings>
      <div className="mt-1 ">
        <div className="mx-3 d-flex justify-content-between align-items-center px-3 py-3">
          <h6 className="text-bold">{t("Privacy and Safety")}</h6>
        </div>
        <div className="endline"></div>
        <div className="mx-4 px-2 my-2 py-2">
          <h6 className="text-small fw-sbold mb-0">{t("Profile")}</h6>
        </div>
        <div className="endline"></div>
        <div className="mx-4 px-2  py-2 d-flex justify-content-between align-items-center">
          <h6 className="text-small  mb-0">{t("Show activity status")} </h6>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className="custom-switch"
            checked={formik.values?.userStatus}
            onChange={() =>
              formik.setFieldValue(
                "userStatus",
                formik?.values?.userStatus ? false : true
              )
            }
          />
        </div>
        <div className="endline"></div>
        <div className="mx-4 px-2  py-2 d-flex justify-content-between align-items-center">
          <h6 className="text-small  mb-0">
            {t("Show Subscriptions Offers")}{" "}
          </h6>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className="custom-switch"
            checked={formik.values?.showSubscriptionOffer}
            onChange={() =>
              formik.setFieldValue(
                "showSubscriptionOffer",
                formik?.values?.showSubscriptionOffer ? false : true
              )
            }
          />
        </div>
        <div className="endline"></div>
        <div className="d-flex my-3 justify-content-center align-items-center w-full">
          {buttonLoader ? (
            <button className="updateNotificationBtn text-light">
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="updateNotificationBtn"
              onClick={formik.handleSubmit}
            >
              {t("Update")}
            </button>
          )}
        </div>
      </div>
    </Settings>
  );
};

export default PrivacyAndSafety;
