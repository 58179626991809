import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ChatList from "./ChatList";
import useSocket from "../../../customHooks/useSocket";
import { artistList, chatList } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import UserMessageSection from "./UserMessageSection";
import EntertainerMessageSection from "./EntertainerMessageSection";
import { useParams } from "react-router-dom";
import {
  updateMsgCount,
  updateNewMsg,
} from "../../../redux/states/messages/slice";
import useResponsive from "../../../customHooks/useResponsive";

const MessageComponent = () => {
  const dispatch = useDispatch();
  const { io } = useSocket();
  const [socketMessages, setSocketMessages] = useState([]);
  const [isNewChat, setIsNewChat] = useState(true);
  const [allFollowedArtist, setAllFollowedArtist] = useState([]);
  const { role } = useSelector((s) => s.setting);
  const userId = useSelector((s) => s.setting?.user)?._id;
  const { isMobileView, isDesktopView } = useResponsive();
  const { artistId, chatId } = useParams();
  const { chatLists } = useSelector((s) => s.message);
  const artistDetails = useSelector((s) => s.artist);
  const artistLists = artistDetails?.list;

  const getChatList = (props) => {
    const data = {
      limit: 100,
      orderBy: "createdAt",
      order: -1,
    };
    dispatch(chatList(data))
      .unwrap()
      .then((res) => {
        const chatList = res?.data;
        var newMsgCount = 0;
        chatList.map((itm) => {
          var msgCount =
            itm?.userId === userId
              ? itm?.userUnreadMessagesCount
              : itm?.otherUserUnreadMessagesCount;
          newMsgCount = newMsgCount + msgCount;
        });
        dispatch(updateMsgCount(newMsgCount));
      })
      .catch((err) => {
        console.log(err, "chatlist here");
      });
  };

  useEffect(() => {
    if (io && !io.hasListeners("newMessage")) {
      console.trace("socketall");
      io.on("newMessage", (response) => {
        getChatList();
        if ("msg" in response) {
          setSocketMessages((prev) => [response, ...prev]);
          const isChatCreatedbyMe = response?.customerId === userId;
          if (isChatCreatedbyMe) {
            if (response?.byCustomer) {
            } else {
              dispatch(updateNewMsg(response));
            }
          } else {
            if (response?.byCustomer) {
              dispatch(updateNewMsg(response));
            }
          }
        }
      });
    }
  }, [io]);

  useEffect(() => {
    getChatList();
  }, []);

  const getArtistList = () => {
    const data = {
      limit: 100,
      page: 1,
      isFollowing: true,
    };
    dispatch(artistList(data));
  };

  useEffect(() => {
    getArtistList();
  }, []);

  useEffect(() => {
    setAllFollowedArtist([]);
    var followingList = artistLists?.filter((item) => {
      return item?.isFollowing === true;
    });

    followingList?.map((itm) => {
      const id = itm?._id;
      const dta = chatLists?.find((item) => {
        return item?.userId === id || item?.otherUserId === id;
      });
      if (!dta) {
        setAllFollowedArtist((prev) => {
          return [...prev, itm];
        });
      }
    });
  }, [artistLists, chatLists]);

  return (
    <Row className="message_wrap  h-100 d-flex justify-content-between ">
      {(!(isMobileView && artistId) || isDesktopView) && (
        <Col lg="5" md="12" sm="12" className="padding_tabet">
          <div className="firstChatList">
            <ChatList
              isNewChat={isNewChat}
              allFollowedArtist={allFollowedArtist}
              getChatList={getChatList}
              setIsNewChat={setIsNewChat}
              setAllFollowedArtist={setAllFollowedArtist}
            />
          </div>
        </Col>
      )}
      {((isMobileView && artistId) || isDesktopView) && (
        <Col lg="7" md="12" sm="12" className="firstMsgSectionCol">
          {role === "USER" && (
            <div className="firstMsgSection">
              <UserMessageSection
                isNewChat={isNewChat}
                setIsNewChat={setIsNewChat}
                getChatList={getChatList}
                allFollowedArtist={allFollowedArtist}
              />
            </div>
          )}
          {role === "ARTIST" && (
            <div className="firstMsgSection">
              <EntertainerMessageSection
                isNewChat={isNewChat}
                setIsNewChat={setIsNewChat}
                getChatList={getChatList}
                allFollowedArtist={allFollowedArtist}
              />
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

export default MessageComponent;
