import React from "react";
import MainLayout from "../../layout/MainLayout";
import PostDetailsComponent from "../../components/PostDetails/PostDetailsComponent";

const PostDetails = () => {
  return (
    <MainLayout>
      <PostDetailsComponent />
    </MainLayout>
  );
};

export default PostDetails;
