export const getDateFormat = (countryCode) => {
  const dateFormats = {
    US: "MM/dd/yyyy",
    CA: "MM/dd/yyyy",
    UK: "dd/MM/yyyy",
    AU: "dd/MM/yyyy",
    NZ: "dd/MM/yyyy",
    IN: "dd/MM/yyyy",
    DE: "dd/MM/yyyy",
    FR: "dd/MM/yyyy",
    IT: "dd/MM/yyyy",
    ES: "dd/MM/yyyy",
    MX: "dd/MM/yyyy",
    BR: "dd/MM/yyyy",
    JP: "yyyy/MM/dd",
    KR: "yyyy/MM/dd",
    CN: "yyyy/MM/dd",
    RU: "dd/MM/yyyy",
    SA: "dd/MM/yyyy",
    AE: "dd/MM/yyyy",
    ZA: "dd/MM/yyyy",
    NG: "dd/MM/yyyy",
    KE: "dd/MM/yyyy",
    EG: "dd/MM/yyyy",
    TR: "dd/MM/yyyy",
    SE: "yyyy/MM/dd",
    NO: "dd/MM/yyyy",
    DK: "dd/MM/yyyy",
    FI: "dd/MM/yyyy",
    NL: "dd/MM/yyyy",
    BE: "dd/MM/yyyy",
    CH: "dd/MM/yyyy",
    AT: "dd/MM/yyyy",
    GR: "dd/MM/yyyy",
    PT: "dd/MM/yyyy",
    PL: "dd/MM/yyyy",
    CZ: "dd/MM/yyyy",
    HU: "yyyy/MM/dd",
    SG: "dd/MM/yyyy",
    MY: "dd/MM/yyyy",
    PH: "MM/dd/yyyy",
    ID: "dd/MM/yyyy",
    VN: "dd/MM/yyyy",
    TH: "dd/MM/yyyy",
    AR: "dd/MM/yyyy",
    CL: "dd/MM/yyyy",
    CO: "dd/MM/yyyy",
    PE: "dd/MM/yyyy",
    VE: "dd/MM/yyyy",
    EC: "dd/MM/yyyy",
    BO: "dd/MM/yyyy",
    PY: "dd/MM/yyyy",
    UY: "dd/MM/yyyy",
  };

  return dateFormats[countryCode?.toUpperCase()] || "MM/dd/yyyy";
};
