// This is the username section inside the acount section;

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import {
  changePassword,
  getProfile,
  updateProfile,
} from "../../../../redux/actions";
import { toast } from "react-toastify";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buttonLoader, selectedEmail } = useSelector((s) => s.login);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      currentPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string("E-mail must be a string")
        .required("Password is required")
        .min(3, "Password must be greater than 3 character"),
      // .max(10, "Password must be less than 10 character"),
      password: Yup.string("E-mail must be a string")
        .required("Password is required")
        .min(3, "Password must be greater than 3 character"),
      // .max(10, "Password must be less than 10 character"),
      confirmPassword: Yup.string()
        .required("Confirm Password isn't matched with password")
        .oneOf([Yup.ref("password"), null], "Password does not match")
        .min(3, "Password must be greater than 3 character"),
      // .max(10, "Password must be less than 10 character"),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(changePassword(values))
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Password changed successfully");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });
  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>CHANGE PASSWORD</span>
        </h4>
      </div>
      <div className="endline my-2"></div>
      <div className="mx-4">
        <div className="mt-4 position-relative loginInput">
          <input
            type={showCurrentPassword ? "text" : "password"}
            className="loginInputPassword"
            placeholder="Current Password"
            name="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="eyeIcon">
            {showCurrentPassword ? (
              <AiOutlineEye
                color=""
                size={23}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                color=""
                size={23}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            )}
          </div>
        </div>
        {formik.touched.currentPassword && formik.errors.currentPassword && (
          <span className="text-danger errorText">
            {formik.errors.currentPassword}
          </span>
        )}
        <div className="mt-4 position-relative loginInput">
          <input
            type={showPassword ? "text" : "password"}
            className="loginInputPassword"
            placeholder="New Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="eyeIcon">
            {showPassword ? (
              <AiOutlineEye
                color=""
                size={23}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                color=""
                size={23}
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <span className="text-danger errorText">
            {formik.errors.password}
          </span>
        )}
        <div className="mt-4 position-relative loginInput">
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="loginInputPassword"
            placeholder="Confirm New Password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="eyeIcon">
            {showConfirmPassword ? (
              <AiOutlineEye
                color=""
                size={23}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                color=""
                size={23}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </div>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <span className="text-danger errorText">
            {formik.errors.confirmPassword}
          </span>
        )}
        <div className="mt-4">
          {buttonLoader ? (
            <button className="loginBtn text-light" ß>
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="loginBtn text-light"
              onClick={formik.handleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </Settings>
  );
};

export default ChangePassword;
