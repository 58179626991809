import React from "react";
import MainLayout from "../../../layout/MainLayout";
import QueueComponent from "../../../components/SideTabs/Queue";

const Queue = () => {
  return (
    <MainLayout>
      <QueueComponent />
    </MainLayout>
  );
};

export default Queue;
