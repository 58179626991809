import React, { useState, useEffect } from "react";
import { Tab, Nav, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { formatText } from "../../../../../helpers/formatPostText";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${value?.toFixed(2)}`;
        },
      },
    },
  },
};

const Messages = (props) => {
  const [tab, setTab] = useState("Purchases");
  const { t } = useTranslation();
  const { engagementMessage } = useSelector((s) => s.statistics);
  const [labelData, setLabelData] = useState([]);
  const [dataSets, setDataSets] = useState([]);

  const graphStatsData = () => {
    if (!engagementMessage || !Array.isArray(engagementMessage)) {
      return;
    }
    const uniqueDates = [
      ...new Set(
        engagementMessage?.map((i) => moment(i.createdAt).format("ll"))
      ),
    ];
    const dateCounts = uniqueDates.reduce((acc, date) => {
      acc[date] = {
        messageCount: 0,
        amount: 0,
        fee: 0,
        netAmount: 0,
      };
      return acc;
    }, {});

    engagementMessage?.forEach((item) => {
      const date = moment(item.createdAt).format("ll");
      if (dateCounts[date]) {
        dateCounts[date].messageCount += 1;
        if (Array.isArray(item.messagebuyers)) {
          item.messagebuyers.forEach((i) => {
            dateCounts[date].amount += i.amount || 0;
            dateCounts[date].fee += i.fee || 0;
            dateCounts[date].netAmount += i.netAmount || 0;
          });
        }
      }
    });

    const labels = Object.keys(dateCounts);
    const message = labels.map((date) => dateCounts[date].messageCount);
    const netAmount = labels.map((date) => dateCounts[date].netAmount);
    const amount = labels.map((date) => dateCounts[date].amount);
    const fee = labels.map((date) => dateCounts[date].fee);

    setLabelData(labels);

    const allDatasets = [
      {
        label: "Messages",
        data: message,
        borderColor: "#3b8ca0",
        backgroundColor: "#5cc3f0",
      },
      {
        label: "Net Purchases",
        data: netAmount,
        borderColor: "#7e8e4f",
        backgroundColor: "#9dc97c",
      },
    ];

    setDataSets(allDatasets);
  };

  const data = {
    labels: labelData,
    datasets: dataSets,
  };

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
  };

  useEffect(() => {
    graphStatsData();
    handleTabSelectedtion("Purchases");
  }, [engagementMessage]);

  return (
    <section className="Earninginnerall_tab">
      <div className="common_table statearning_table alltime_table mt-4 border-0">
        <div className="postEngage">
          <div className="CardBody py-1  innner_tabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="Purchases"
              className="d-flex justify-content-between align-items-center"
            >
              <Nav variant="pills" className="">
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-small"
                    eventKey="Purchases"
                    onClick={() => handleTabSelectedtion("Purchases")}
                    active={tab === "Purchases"}
                  >
                    {t("Purchases")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
          {engagementMessage?.length > 0 ? (
            <>
              <Tab.Content className="">
                <Tab.Pane eventKey="Purchases" active={tab === "Purchases"}>
                  <div className="my-3 w-full">
                    <Line options={options} data={data} />
                  </div>
                </Tab.Pane>
              </Tab.Content>
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>{t("Date")}</th>
                      <th>{t("Sent")}</th>
                      <th>{t("Viewed")}</th>
                      <th>{t("Price")}</th>
                      <th>{t("Purchases")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {engagementMessage?.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td>{moment(item?.createdAt).format("lll")}</td>
                            <td>{item?.sentCount || 1}</td>
                            <td>{item?.seenCount || 0}</td>
                            <td>
                              {"$"}
                              {item?.price > 0
                                ? item?.price?.toFixed(2)
                                : "0.00"}
                            </td>
                            <td>
                              {"$"}
                              {item?.messagebuyers?.length > 0
                                ? item?.messagebuyers
                                    ?.reduce((a, b) => a + b.amount, 0)
                                    ?.toFixed(2)
                                : "0.00"}
                            </td>
                          </tr>

                          <td colSpan="5">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="statics_imgset d-flex align-items-center">
                                <div className="static_img_wrap">
                                  {item?.attachment?.length ? (
                                    <img
                                      src={
                                        item?.attachment?.length
                                          ? item?.attachment[0]?.url
                                          : ""
                                      }
                                      className="rounded"
                                    />
                                  ) : null}
                                </div>

                                <div className="statics_contentset">
                                  <p className="px-4 m-0">
                                    {formatText(item?.msg)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <div className="noGraphData">
              {t("No data during selected period")}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Messages;
