import React , {useState} from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WithdrawlMoneyModal from "../../common/Modal/WithdrawlMoneyModal";

const Currentrefferal = () => {
  
  const { transaction } = useSelector((s) => s.statement);
  const { t } = useTranslation();
  const [showWithdrawlModal , setShowWithdrawlModal] = useState(false);
  const { walletCredit } = useSelector((s) => s.setting?.user);
  return (
    <section className="pt-4 px-4">
      <div className="current_refer_earnings">
        <div className="current_box_top border-bottom px-2 py-3">
          <div className="current_box_head d-flex align-items-center justify-space-between">
            <h5>{t("CURRENT REFERRAL EARNINGS")}</h5>
            <h4 className="mx-2">
              $
              {transaction?.currentNetBalance &&
                transaction?.currentNetBalance.toFixed(2)}
            </h4>
          </div>
          <p>
            {t(
              "Please note, if you do not reach the minimum payout ($20), your earnings will roll over to the next monthly payout."
            )}
          </p>
        </div>
        <div className="cureent_box_top px-2 py-3">
          <div className="current_box_head d-flex align-items-start justify-space-between">
            <div className="heding_wth_link">
              <h5>{t("YOUR PERSONAL REFERRAL URL")}</h5>
              <p>https://fansroom.com/? ref=3345554878</p>
            </div>
            <Button className="copy_btn">{t("Copy link")}</Button>
          </div>

          <div className="text-end mt-3">
            <Link to="/refferrals" className="view_btn">
              {t("View referred users")}
            </Link>
          </div>
        </div>
      </div>

      <div className="danger_alert position-relative my-3">
        <span className="danger_icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <g clip-path="url(#clip0_756_1116)">
              <path
                d="M10.9998 15.0769C10.3847 15.0769 9.86914 15.5925 9.86914 16.2075C9.86914 16.8226 10.3847 17.3381 10.9998 17.3381C11.5922 17.3381 12.1304 16.8226 12.1032 16.2346C12.1304 15.5879 11.6193 15.0769 10.9998 15.0769Z"
                fill="#FF0000"
              />
              <path
                d="M21.4647 19.0838C22.1747 17.8583 22.1792 16.3975 21.4737 15.1765L14.3916 2.9116C13.6906 1.67697 12.4243 0.944336 11.0042 0.944336C9.5842 0.944336 8.31792 1.68149 7.61694 2.90708L0.525755 15.1855C-0.179745 16.4201 -0.175223 17.8899 0.539323 19.1155C1.24482 20.3275 2.50658 21.0556 3.91759 21.0556H18.0638C19.4793 21.0556 20.7501 20.3185 21.4647 19.0838ZM19.927 18.1974C19.5336 18.8758 18.8371 19.2783 18.0593 19.2783H3.91306C3.14425 19.2783 2.45231 18.8849 2.06791 18.2201C1.67898 17.5462 1.67446 16.7412 2.06339 16.0629L9.15457 3.78895C9.53898 3.11511 10.2264 2.71713 11.0042 2.71713C11.7776 2.71713 12.4695 3.11963 12.8539 3.79347L19.9406 16.0674C20.3205 16.7277 20.316 17.5236 19.927 18.1974Z"
                fill="#FF0000"
              />
              <path
                d="M10.7195 7.14007C10.1813 7.29384 9.84668 7.78226 9.84668 8.3747C9.87381 8.73197 9.89643 9.09377 9.92356 9.45104C10.0004 10.8123 10.0773 12.1464 10.1542 13.5077C10.1813 13.969 10.5386 14.3036 10.9999 14.3036C11.4612 14.3036 11.823 13.9463 11.8456 13.4805C11.8456 13.2001 11.8456 12.9424 11.8727 12.6574C11.9225 11.7846 11.9767 10.9118 12.0265 10.039C12.0536 9.47365 12.1034 8.90835 12.1305 8.34304C12.1305 8.13953 12.1034 7.95863 12.0265 7.77774C11.7959 7.27122 11.2577 7.01344 10.7195 7.14007Z"
                fill="#FF0000"
              />
            </g>
            <defs>
              <clipPath id="clip0_756_1116">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <p>{t("Please complete filling out your")}</p>
        <h5 className="mt-1">{t("Banking information")}</h5>
      </div>

      <div className="common_table statearning_table mt-4 border-0">
        <Accordion defaultActiveKey="0" className="px-3 mt-3 ">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="all_times d-flex justify-content-between  w-100">
                <h4>{t("Current Balance")}</h4>
                <h4>$ {walletCredit || 0}</h4>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="current_balnce_card">
                <div className="pending_balnce d-flex align-items-center justify-content-between border-bottom py-2">
                  <h6>{t("PENDING BALANCE")}</h6>
                  <h6>$0.00</h6>
                </div>

                <Form>
                  <FormGroup></FormGroup>
                  <p className="mininum_info text-center">
                    {t("Minimum withdrawal amount is $20")}
                  </p>
                  <div className="text-end mt-3">
                    <Button
                      className="request_widraw"
                      onClick={() => setShowWithdrawlModal(true)}
                    >
                      {t("Request Withdrawal")}
                    </Button>
                  </div>
                </Form>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <WithdrawlMoneyModal
       show={showWithdrawlModal} onHide={()=> setShowWithdrawlModal(false)}/>
    </section>
  );
};

export default Currentrefferal;
