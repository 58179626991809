// User card has been designed here ;

import React, { useState } from "react";
import { Col } from "react-bootstrap";
import DummyImg from "../../../images/dummyBack.jpeg";
import User from "../../../images/user.png";
import { generatePath, useNavigate } from "react-router-dom";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  allArtistGroupList,
  blockArtist,
  getProfile,
  restirctArtist,
  subscribeArtist,
  unBlockArtist,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { PiDotsThreeCircleVerticalBold } from "react-icons/pi";
import { CgRemove, CgUnblock } from "react-icons/cg";
import { BiBlock } from "react-icons/bi";
import { MdAddLink } from "react-icons/md";
import { useTranslation } from "react-i18next";
import SubscriptionPayment from "../../common/Modal/SubscriptionPayment";

const UserCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, showBlockedList, commonCall } = props;
  const navigate = useNavigate();
  const { user } = useSelector((s) => s.setting);
  const [showActionBox, setShowActionBox] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handleSendMsg = (dta) => {
    const path = generatePath("/messages/:artistId", { artistId: dta?._id });
    navigate(path);
  };

  const getGroupList = () => {
    const data = {
      limit: 100,
      page: 1,
    };
    dispatch(allArtistGroupList(data));
  };
  const onSubscribeSuccess = () => {
    commonCall();
    getGroupList();
    dispatch(getProfile());
  };
  const handleSubscribe = (dta) => {
    const data = {
      followerId: dta?._id,
    };
    dispatch(subscribeArtist(data))
      .unwrap()
      .then((res) => {
        onSubscribeSuccess();
        toast.success(res?.message);
      })
      .catch((err) => {
        onSubscribeSuccess();
        toast.error(err?.message);
      });
  };

  const restrictSelectedArtist = (dta) => {
    const data = {
      artistId: dta?._id,
    };
    dispatch(restirctArtist(data))
      .unwrap()
      .then((res) => {
        commonCall();
        getGroupList();
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const blockSelectedArtist = (dta) => {
    const data = {
      blockedUserId: dta?._id,
    };
    dispatch(blockArtist(data))
      .unwrap()
      .then((res) => {
        commonCall();
        getGroupList();
        toast.success("Entertainer has been blocked");
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  const unblockSelectedArtist = (dta) => {
    const data = {
      blockedUserId: dta?._id,
    };
    dispatch(unBlockArtist(data))
      .unwrap()
      .then((res) => {
        commonCall();
        getGroupList();
        toast.success("Entertainer has been unblocked");
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  const unSubBtnName = () => {
    const artist = user?.purchasedSubscriptions.find(
      (i) => i.artistId === data?._id
    );
    if (!!artist === true) {
      return artist?.status === "active"
        ? t("Cancel Subscription")
        : t("Subscription Cancelled");
    } else {
      return t("UnSubscribe");
    }
  };

  return (
    <>
      <SubscriptionPayment
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        data={data}
        onSubscribeSuccess={onSubscribeSuccess}
        followerId={data?._id}
      />
      {showBlockedList ? (
        <Col lg="6" md="6" sm="12">
          <div className="userCrdCont shadow2 rounded">
            <div className="userBg">
              <img
                src={data?.coverImage || DummyImg}
                alt=""
                className="userBg-img"
              />
              <img
                src={data?.profileImage || User}
                alt=""
                className="userbg-prof"
              />

              <div
                className="threeDotsCont"
                onMouseOver={() => setShowActionBox(true)}
                onMouseOut={() => setShowActionBox(false)}
              >
                <PiDotsThreeCircleVerticalBold
                  color="black"
                  size={18}
                  className="threeDots"
                />
              </div>
              {showActionBox && (
                <div
                  className="blockSecBox shadow2"
                  onMouseOver={() => setShowActionBox(true)}
                  onMouseOut={() => setShowActionBox(false)}
                >
                  {!data?.isRestrict ? (
                    <div
                      className="sectin d-flex align-items-center py-2"
                      onClick={() => restrictSelectedArtist(data)}
                    >
                      <CgRemove size={16} color="black" className="" />
                      <h6 className="text-sm mb-0 mx-2 "> {t("Restrict")}</h6>
                    </div>
                  ) : (
                    <div
                      className="sectin d-flex align-items-center py-2"
                      onClick={() => restrictSelectedArtist(data)}
                    >
                      <MdAddLink size={16} color="red" className="" />
                      <h6 className="text-sm text-danger mb-0 mx-2 ">
                        {" "}
                        {t("Remove Restrict")}
                      </h6>
                    </div>
                  )}

                  <div
                    className="sectin d-flex align-items-center py-2"
                    onClick={() => unblockSelectedArtist(data)}
                  >
                    <CgUnblock size={18} color="red" className="" />
                    <h6 className="text-sm text-danger mb-0 mx-2 ">
                      {" "}
                      {t("Unblock")}
                    </h6>
                  </div>
                </div>
              )}
            </div>
            <div className="usrdCrdDet d-flex justify-content-center align-items-center flex-column mb-4">
              <h6 className="text-small my-1 ">
                {data?.name
                  ? data?.name
                  : data?.firstName + " " + data?.lastName}
              </h6>
              <h6>{data?.userName}</h6>
            </div>
          </div>
        </Col>
      ) : (
        <Col lg="6" md="6" sm="12">
          <div className="userCrdCont shadow2 rounded">
            <div className="userBg">
              <img
                src={data?.coverImage || DummyImg}
                alt=""
                className="userBg-img"
              />
              {/* <img
                src={data?.profileImage || User}
                alt=""
                className="userbg-prof"
              /> */}

              {data?.profileImage ? (
                <img src={data?.profileImage} alt="" className="userbg-prof" />
              ) : (
                <div
                  className="userbg-prof"
                  style={{
                    fontSize: "24px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data?.firstName && data?.lastName
                    ? data?.firstName?.slice(0, 1) + data?.lastName?.slice(0, 1)
                    : data?.displayName?.slice(0, 1) || data?.name?.slice(0, 1)}
                </div>
              )}

              <div
                className="threeDotsCont"
                onMouseOver={() => setShowActionBox(true)}
                onMouseOut={() => setShowActionBox(false)}
              >
                <PiDotsThreeCircleVerticalBold
                  color="black"
                  size={18}
                  className="threeDots"
                />
              </div>
              {showActionBox && (
                <div
                  className="blockSecBox shadow2"
                  onMouseOver={() => setShowActionBox(true)}
                  onMouseOut={() => setShowActionBox(false)}
                >
                  {!data?.isRestrict ? (
                    <div
                      className="sectin d-flex align-items-center py-2"
                      onClick={() => restrictSelectedArtist(data)}
                    >
                      <CgRemove size={16} color="black" className="" />
                      <h6 className="text-sm mb-0 mx-2 "> {t("Restrict")}</h6>
                    </div>
                  ) : (
                    <div
                      className="sectin d-flex align-items-center py-2"
                      onClick={() => restrictSelectedArtist(data)}
                    >
                      <MdAddLink size={16} color="red" className="" />
                      <h6 className="text-sm text-danger mb-0 mx-2 ">
                        {" "}
                        {t("Remove Restrict")}
                      </h6>
                    </div>
                  )}
                  {!data?.isBlocked ? (
                    <div
                      className="sectin d-flex align-items-center py-2"
                      onClick={() => blockSelectedArtist(data)}
                    >
                      <BiBlock size={16} color="black" className="" />
                      <h6 className="text-sm mb-0 mx-2 "> {t("Block")}</h6>
                    </div>
                  ) : (
                    <div
                      className="sectin d-flex align-items-center py-2"
                      onClick={() => unblockSelectedArtist(data)}
                    >
                      <CgUnblock size={18} color="red" className="" />
                      <h6 className="text-sm text-danger mb-0 mx-2 ">
                        {" "}
                        {t("UnBlock")}
                      </h6>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="usrdCrdDet d-flex justify-content-center align-items-center flex-column ">
              <h6 className="text-small my-1 ">
                {data?.name
                  ? data?.name
                  : data?.firstName + " " + data?.lastName}
              </h6>
              <h6>{data?.userName}</h6>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {/* <img src={Star} alt="" /> */}
              {data?.isFavorite ? (
                <AiFillStar
                  color="orange"
                  size={20}
                  className="mx-2 pointer"
                  onClick={() => props.handleAddToFavourite(data)}
                />
              ) : (
                <AiOutlineStar
                  color="orange"
                  size={20}
                  className="mx-2 pointer"
                  onClick={() => props.handleAddToFavourite(data)}
                />
              )}
              <span className="text-sm my-2" style={{ fontSize: "12px" }}>
                {" "}
                {t("Add to favorite and other list")}
              </span>
            </div>
            <div className="d-flex my-2 justify-content-around align-items-center">
              {data?.isFollowing ? (
                <button
                  className="userCrdBtn pointer"
                  onClick={() => handleSendMsg(data)}
                >
                  {t("Message")}
                </button>
              ) : (
                <button
                  className="userCrdBtn pointer"
                  onClick={() => toast.warning("Please subscribe To chat")}
                >
                  {t("Message")}
                </button>
              )}

              {/* <button className="userCrdBtn">{t("Send a Tip")}</button> */}
            </div>
            <div className="d-flex justify-content-center ">
              {data?.isFollowing ? (
                <button
                  disabled={
                    user?.purchasedSubscriptions.find(
                      (i) => i.artistId === data?._id
                    )?.status === "cancelled"
                  }
                  className="usercrdsubbtn bg-primary"
                  onClick={() => handleSubscribe(data)}
                >
                  {unSubBtnName()}
                </button>
              ) : (
                <button
                  className="usercrdsubbtn "
                  onClick={() => {
                    if (data?.subscribePrice > 0) {
                      setShowPaymentModal(true);
                    } else {
                      handleSubscribe(data);
                    }
                  }}
                >
                  {t("Subscribe")}
                  {data?.subscribePrice > 0
                    ? ` for $ ${data?.subscribePrice}`
                    : " for free"}
                </button>
              )}
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default UserCard;
