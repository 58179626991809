import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Nav, Row, Tab, Button } from "react-bootstrap";
import { subscriptionDetails } from "../../../../../redux/actions";
import moment from "moment";

const FansSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState("Total");
  const [subTab, setSubTab] = useState("Subscription");
  const [topFansData, setTopFansData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const { fansSubscription, topFansStats } = useSelector((s) => s.statistics);
  const { subscriptionData } = useSelector((s) => s.common);
  const artistId = useSelector((s) => s.login)?.user?._id;

  useEffect(() => {
    const transactionType = tab === "Subscriptions" ? "Subscribe" : tab;
    let filteredStats = topFansStats?.map((i) => {
      let filterRecords;
      if (transactionType !== "Total") {
        filterRecords = i.transactions?.filter(
          (j) => j.transactionType === transactionType
        );
      } else {
        filterRecords = i.transactions;
      }

      const totalAmount =
        filterRecords?.reduce((a, b) => a + b.netAmount, 0) || 0;

      return { ...i, totalAmount };
    });

    console.log("filteredStats", filteredStats);

    const largestRecord = filteredStats?.reduce(
      (max, record) =>
        record.totalAmount > (max?.totalAmount || 0) ? record : max,
      filteredStats[0]
    );

    setTopFansData(largestRecord);
  }, [topFansStats, tab]);

  useEffect(() => {
    if (
      topFansData &&
      topFansData?.otherUserDetails &&
      topFansData?.otherUserDetails?._id
    ) {
      let payload = {
        artistId: artistId,
        userId: topFansData?.otherUserDetails?._id,
      };
      dispatch(subscriptionDetails(payload));
    }
  }, [subTab, topFansData]);

  useEffect(() => {
    const data = [
      {
        label: "Current subscription",
        value: subscriptionData?.data?.subscriptionBundles?.price
          ? `$${subscriptionData?.data?.subscriptionBundles?.price?.toFixed(2)}`
          : "Free",
      },
      {
        label: "Started",
        value: subscriptionData?.data?.subscriptionStart
          ? moment(subscriptionData?.data?.subscriptionStart).format("lll")
          : moment(subscriptionData?.followDetail?.createdAt).format("lll"),
      },
      {
        label: "Total Duration",
        value: subscriptionData?.data?.subscriptionBundles
          ? `${subscriptionData?.data?.subscriptionBundles?.duration} months`
          : moment(subscriptionData?.followDetail?.createdAt).fromNow(),
      },
      {
        label: "Renews",
        value: subscriptionData?.data?.subscriptionEnd
          ? moment(subscriptionData?.data?.subscriptionEnd).format("lll")
          : "--",
      },
      {
        label: "Rebill",
        value: subscriptionData?.data
          ? subscriptionData?.data?.status === "active"
            ? "ON"
            : "OFF"
          : "--",
      },
    ];
    setSubscription(data);

    let sortedData = [];

    if (topFansData?.transactions && topFansData?.transactions?.length > 0) {
      sortedData = [...topFansData.transactions].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    }

    const Subscribe =
      topFansData?.transactions?.filter(
        (j) => j.transactionType === "Subscribe"
      ) || [];
    const Posts =
      topFansData?.transactions?.filter((j) => j.transactionType === "Posts") ||
      [];
    const Tips =
      topFansData?.transactions?.filter((j) => j.transactionType === "Tips") ||
      [];
    const Messages =
      topFansData?.transactions?.filter(
        (j) => j.transactionType === "Messages"
      ) || [];

    const earn = [
      {
        label: "Last Activity",
        value:
          sortedData?.length > 0
            ? moment(sortedData?.[0]?.createdAt).format("lll")
            : moment(new Date()).format("lll"),
      },
      {
        label: "Subscriptions",
        value:
          Subscribe?.length > 0
            ? "$" + Subscribe?.reduce((a, b) => a + b.netAmount, 0)?.toFixed(2)
            : "$0.00",
      },
      {
        label: "Posts",
        value:
          Posts?.length > 0
            ? "$" + Posts?.reduce((a, b) => a + b.netAmount, 0)?.toFixed(2)
            : "$0.00",
      },
      {
        label: "Messages",
        value:
          Messages?.length > 0
            ? "$" + Messages?.reduce((a, b) => a + b.netAmount, 0)?.toFixed(2)
            : "$0.00",
      },
      {
        label: "Tips",
        value:
          Tips?.length > 0
            ? "$" + Tips?.reduce((a, b) => a + b.netAmount, 0)?.toFixed(2)
            : "$0.00",
      },
      {
        label: "Total",
        value:
          topFansData?.transactions?.length > 0
            ? "$" +
              topFansData?.transactions
                ?.reduce((a, b) => a + b.netAmount, 0)
                ?.toFixed(2)
            : "$0.00",
      },
    ];
    setEarnings(earn);
  }, [subscriptionData, subTab, topFansData]);

  const topFansCard = () => {
    return (
      <>
        <div className="earniing_div">
          <div className="p-3 border-top card_boader my-3">
            <h5 className="sidebar_head">{t("Top Fans")} </h5>
            <div className="innner_tabs border-bottom pb-2 px-2 mb-2">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Total"
                className="d-flex justify-content-between align-items-center"
              >
                <Nav
                  variant="pills"
                  className="flex-nowrap overflow-scroll"
                  style={{ scrollbarWidth: "none" }}
                >
                  <Nav.Item className="me-1">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small"
                      eventKey="Total"
                      onClick={() => setTab("Total")}
                    >
                      {t("Total")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small"
                      eventKey="Subscriptions"
                      onClick={() => setTab("Subscriptions")}
                    >
                      {t("Subscriptions")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small"
                      eventKey="Tips"
                      onClick={() => setTab("Tips")}
                    >
                      {t("Tips")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small"
                      eventKey="Messages"
                      onClick={() => setTab("Messages")}
                    >
                      {t("Messages")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small"
                      eventKey="Posts"
                      onClick={() => setTab("Posts")}
                    >
                      {t("Posts")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
            {/* <p className="m-0">{t("No Fans yet")}</p> */}
            <div className="cardBody">
              <div className="d-flex align-items-start justify-content-between">
                <div className="d-flex align-items-center gap-10">
                  <div className="imgWrp flex-shrink-0">
                    {topFansData?.otherUserDetails?.profileImage ? (
                      <img
                        src={topFansData?.otherUserDetails?.profileImage}
                        alt=""
                        style={{ height: 35, width: 35 }}
                        className="img-fluid rounded-circle object-fit-cover"
                      />
                    ) : (
                      <div className="user_img_fansstats shadow2">
                        {topFansData?.otherUserDetails?.firstName &&
                        topFansData?.otherUserDetails?.lastName
                          ? `${topFansData?.otherUserDetails?.firstName.slice(
                              0,
                              1
                            )}${topFansData?.otherUserDetails?.lastName.slice(
                              0,
                              1
                            )}`
                          : topFansData?.otherUserDetails?.name?.slice(0, 1)}
                      </div>
                    )}
                  </div>
                  <div className="content">
                    <p className="m-0 text-dark fw-bold">
                      {topFansData?.otherUserDetails?.firstName +
                        (topFansData?.otherUserDetails?.lastName || "")}
                    </p>
                    <p className="m-0 text-muted">
                      @{topFansData?.otherUserDetails?.userName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Button
                  variant="transparent"
                  style={{ fontSize: 12 }}
                  className="d-flex border-0 p-0 align-items-center gap-10"
                >
                  <span className="icn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <path
                        d="M10 13.39L6.24 15.66L7.23 11.38L3.91 8.5L8.29 8.13L10 4.09L11.71 8.13L16.09 8.5L12.77 11.38L13.76 15.66M20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.45 11.97L3.82 19L10 15.27L16.18 19L14.54 11.97L20 7.24Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Add to favourite and other lists
                </Button>
              </div>
              {/* <div className="innner_tabs my-2">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="Message"
                  className="d-flex justify-content-between align-items-center"
                >
                  <Nav
                    variant="pills"
                    className="flex-nowrap overflow-scroll"
                    style={{ scrollbarWidth: "none" }}
                  >
                    <Nav.Item className="me-1">
                      <Nav.Link
                        className="bg-transparent border-0 position-relative text-small"
                        eventKey="Message"
                        // onClick={() => handleTabSelectedtion("Purchases")}
                      >
                        {t("Message")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="me-1">
                      <Nav.Link
                        className="bg-transparent border-0 position-relative text-small"
                        eventKey="Restrict"
                        // onClick={() => handleTabSelectedtion("Purchases")}
                      >
                        {t("Restrict")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="me-1">
                      <Nav.Link
                        className="bg-transparent border-0 position-relative text-small"
                        eventKey="AddNotes"
                        // onClick={() => handleTabSelectedtion("Purchases")}
                      >
                        {t("Add notes")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
              </div> */}
              <div className="commonTabs2 border-top mt-2">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="Subscription"
                  className="d-flex justify-content-between align-items-center"
                >
                  <Nav
                    variant="pills"
                    className="flex-nowrap overflow-scroll"
                    style={{ scrollbarWidth: "none" }}
                  >
                    <Nav.Item className="me-1">
                      <Nav.Link
                        className="bg-transparent border-0 position-relative text-small"
                        eventKey="Subscription"
                        onClick={() => setSubTab("Subscription")}
                      >
                        {t("Subscription")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="me-1">
                      <Nav.Link
                        className="bg-transparent border-0 position-relative text-small"
                        eventKey="Earning"
                        onClick={() => setSubTab("Earning")}
                      >
                        {t("Earning")}
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="me-1">
                      <Nav.Link
                        className="bg-transparent border-0 position-relative text-small"
                        eventKey="Offers"
                        onClick={() => setSubTab("Offers")}
                      >
                        {t("Offers")}
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="Subscription">
                      <ul className="list-unstyled ps-0 mb-0">
                        {subscription?.map((i) => (
                          <li className="py-2 px-2 border-bottom d-flex align-items-center justify-content-between gap-2">
                            <p
                              className="m-0 text-muted"
                              style={{ fontSize: 12 }}
                            >
                              {i.label}
                            </p>
                            <p
                              className="m-0 text-dark"
                              style={{ fontSize: 12 }}
                            >
                              {i.value}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Earning">
                      <ul className="list-unstyled ps-0 mb-0">
                        {earnings?.map((i) => (
                          <li className="py-2 px-2 border-bottom d-flex align-items-center justify-content-between gap-2">
                            <p
                              className="m-0 text-muted"
                              style={{ fontSize: 12 }}
                            >
                              {i.label}
                            </p>
                            <p
                              className="m-0 text-dark"
                              style={{ fontSize: 12 }}
                            >
                              {i.value}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="Offers">
                      <ul className="list-unstyled ps-0 mb-0">
                        {subscription?.map((i) => (
                          <li className="py-2 px-2 border-bottom d-flex align-items-center justify-content-between gap-2">
                            <p
                              className="m-0 text-muted"
                              style={{ fontSize: 12 }}
                            >
                              {i.label}
                            </p>
                            <p
                              className="m-0 text-dark"
                              style={{ fontSize: 12 }}
                            >
                              {i.value}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="earniing_div">
        <div className="py-2 px-2 border-top card_boader">
          <h5 className="sidebar_head">{t("Summary")}</h5>
          <Row>
            <Col sm="6" className="my-2">
              <p className="text-muted m-0">Subscribers</p>
              <p className="m-0 fw-bold text-dark">
                {fansSubscription?.records?.length || 0}
              </p>
            </Col>
            <Col sm="6" className="my-2">
              <p className="text-muted m-0">New subs/Renews</p>
              <p className="m-0 fw-bold text-dark">
                {fansSubscription?.records?.length || 0}
              </p>
            </Col>
            <Col sm="6" className="my-2">
              <p className="text-muted m-0">Subscriptions earnings</p>
              <p className="m-0 fw-bold text-dark">
                $
                {fansSubscription?.records?.length > 0
                  ? fansSubscription?.records
                      ?.reduce(
                        (a, b) => a + (b?.earntransactionData?.netAmount || 0),
                        0
                      )
                      ?.toFixed(2)
                  : "0.00"}
              </p>
            </Col>
          </Row>
        </div>
      </div>

      {topFansCard()}
    </>
  );
};

export default FansSummary;
