// This is the main setting page;
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SettingsComponent = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((s) => s.setting);
  const { role } = useSelector((s) => s.setting);

  return (
    <Row className="  h-100 d-flex justify-content-between align-items-start ">
      <Col lg="5" md="12" sm="12" className="my-2">
        <div className="shadow1 rounded  border  h-100 px-3 py-4 border_hide">
          <div className="d-flex justify-content-between align-items-center mx-3">
            <h4 className="text-bold">
              <span className="pointer" onClick={() => navigate("/home")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span style={{ marginLeft: "8px" }}>{t("Settings")}</span>
            </h4>
          </div>
          <div className="endline my-2"></div>
          <div>
            <div className="pointer">
              <h6 className="text-bold mx-3 my-3 " style={{ fontSize: "17px" }}>
                {user?.userName}
              </h6>
              <div className="endline my-0"></div>
            </div>
            <div className="">
              <div
                onClick={() => navigate("/settings/edit-profile")}
                className="settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
              >
                <h6 className="text-small fw-sbold mb-0"> {t("Profile")}</h6>
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="endline "></div>
            </div>

            <div>
              <div
                onClick={() => navigate("/settings/account")}
                className=" settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
              >
                <h6 className="text-small fw-sbold mb-0"> {t("Account")}</h6>
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="endline "></div>
            </div>
            <div>
              <div
                onClick={() => navigate("/settings/privacy-safety")}
                className="settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
              >
                <h6 className="text-small fw-sbold mb-0">
                  {" "}
                  {t("Privacy and safety")}
                </h6>
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="endline "></div>
            </div>
            {role === "ARTIST" && (
              <>
                <div
                  onClick={() =>
                    navigate("/settings/subscription-price-bundle")
                  }
                  className="settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
                >
                  <h6 className="text-small fw-sbold mb-0">
                    {" "}
                    {t("Subscription price and bundle")}
                  </h6>
                  <span className="mx-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </div>
                <div className="endline "></div>

                <div
                  onClick={() => navigate("/settings/fans-following")}
                  className="settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
                >
                  <h6 className="text-small fw-sbold mb-0">
                    {" "}
                    {t("Fans and following")}
                  </h6>
                  <span className="mx-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </div>
                <div className="endline "></div>
              </>
            )}
            <div>
              <div
                onClick={() => navigate("/settings/notifications")}
                className=" settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
              >
                <h6 className="text-small fw-sbold mb-0">
                  {" "}
                  {t("Notifications")}
                </h6>
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="endline "></div>
            </div>
            {role === "ARTIST" && (
              <>
                <div
                  onClick={() => navigate("/settings/chats")}
                  className="settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
                >
                  <h6 className="text-small fw-sbold mb-0"> {t("Chats")}</h6>
                  <span className="mx-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </div>
                <div className="endline "></div>
                <div
                  onClick={() => navigate("/settings/story")}
                  className="settingTitle px-3 py-3 d-flex justify-content-between pointer align-items-center"
                >
                  <h6 className="text-small fw-sbold mb-0"> {t("Story")}</h6>
                  <span className="mx-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </div>
                <div className="endline "></div>
              </>
            )}
            <div>
              <div className=" px-3 py-3 d-flex justify-content-between align-items-center pointer">
                <h6 className="text-bold  " style={{ fontSize: "17px" }}>
                  {" "}
                  {t("General")}
                </h6>
                <span className="mx-3"></span>
              </div>
              <div className="endline "></div>
            </div>
            <div>
              <div
                onClick={() => navigate("/settings/display")}
                className="settingTitle px-3 py-3 d-flex justify-content-between align-items-center pointer"
              >
                <h6 className="text-small fw-sbold mb-0"> {t("Display")}</h6>
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="endline "></div>
              {role === "ARTIST" && (
                <>
                  <div
                    onClick={() => navigate("/settings/qrcode")}
                    className="settingTitle px-3 py-3 d-flex justify-content-between align-items-center pointer"
                  >
                    <h6 className="text-small fw-sbold mb-0">
                      {" "}
                      {t("QR Code")}
                    </h6>
                    <span className="mx-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9497L13.172 11.9997L13.172 12.0007Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="endline "></div>
                </>
              )}
            </div>
          </div>
        </div>
      </Col>

      <Col lg="7" md="12" sm="12" className="my-2">
        <div className="shadow1 rounded  border h-100">{children}</div>
      </Col>
    </Row>
  );
};

export default SettingsComponent;
