import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { addSubscriptionBundle } from "../../../redux/actions";
import { toast } from "react-toastify";
import SmallLoader from "../ButtonLoader/SmallLoader";

const SubscriptionBundlePop = (props) => {
  const { t } = useTranslation();
  const [discount, setDiscount] = useState(0);
  const [duration, setDuration] = useState();
  const dispatch = useDispatch();
  const { buttonLoader } = useSelector((s) => s.setting);
  const durationArray = [
    {
      id: 1,
      name: `3 ${t("months")}`,
      value: 3,
    },
    {
      id: 2,
      name: `6 ${t("months")}`,
      value: 6,
    },
    {
      id: 3,
      name: `12 ${t("months")}`,
      value: 12,
    },
  ];

  useEffect(() => {
    if (props.subscriptionBundle?.length > 0) {
      const dataValue = durationArray?.filter(
        (k) => !props.subscriptionBundle.some((i) => k.value === i.duration)
      );

      if (dataValue?.length) {
        setDuration(dataValue[0]?.value);
      }
    } else {
      setDuration(3);
    }
  }, [props.subscriptionBundle]);

  const saveSubscriptionBundle = () => {
    const data = {
      discount: Number(discount),
      duration: Number(duration),
    };
    dispatch(addSubscriptionBundle(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        props.getUserData();
        props.onHide();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <Modal
      {...props}
      keyboard={false}
      centered
      size="md"
      className="single_subscribe"
    >
      <Modal.Header className="pt-3 pb-0 border-0" closeButton>
        <Modal.Title>Subscription Bundle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="border-top mt-2 pt-3 mx-3">
          <Row>
            <Col lg="12" className="my-1">
              <label htmlFor="" className="form-label">
                Discount Percent
              </label>
              <Input
                type="select"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              >
                <option value={0}>0 %</option>
                <option value={5}>5 %</option>
                <option value={10}>10 %</option>
                <option value={15}>15 %</option>
                <option value={20}>20 %</option>
                <option value={25}>25 %</option>
                <option value={30}>30 %</option>
                <option value={35}>35 %</option>
                <option value={40}>40 %</option>
                <option value={45}>45 %</option>
                <option value={50}>50 %</option>
              </Input>
            </Col>
            <Col lg="12" className="my-1">
              <label htmlFor="" className="form-label" value>
                {t("Duration")}
              </label>
              <Input
                type="select"
                value={duration}
                onChange={(e) => setDuration(Number(e.target.value))}
              >
                {!props?.subscriptionBundle ||
                props.subscriptionBundle.length === 0
                  ? durationArray?.map((j) => (
                      <option key={j.value} value={j.value}>
                        {j.name}
                      </option>
                    ))
                  : durationArray
                      ?.filter(
                        (k) =>
                          !props.subscriptionBundle.some(
                            (i) => k.value === i.duration
                          )
                      )
                      .map((j) => (
                        <option key={j.value} value={j.value}>
                          {j.name}
                        </option>
                      ))}
              </Input>
            </Col>
            <Col
              lg="12"
              className="my-4 d-flex justify-content-end align-items-center"
            >
              <button onClick={() => props.onHide()} className="clearBtn mx-3">
                {t("Cancel")}
              </button>
              {buttonLoader ? (
                <button className="sendMsg">
                  <SmallLoader color={"text-light"} />
                </button>
              ) : (
                <button className="sendMsg" onClick={saveSubscriptionBundle}>
                  {t("Save")}
                </button>
              )}
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionBundlePop;
