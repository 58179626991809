import React, { useState, useEffect } from "react";
import MainLayout from "../../../layout/MainLayout";
import PaymentsComponent from "../../../components/SideTabs/Payments";
// import {
//   useStripe,
//   useElements,
//   CardNumberElement,
//   CardExpiryElement,
//   CardCvcElement,
//   CardElement,
// } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import Addpaymentmod from "../../../components/common/Modal/Addpaymentmod";
import { useSelector } from "react-redux";
import { getCardList } from "../../../redux/states/card/thunk";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// const stripePromise = loadStripe(
//   "pk_test_51O2gNNLI2EI2TtmrOCtzqsiaFTbzEA6Pn3JlN1vhFWTm9mQU6zIWgjuxXbWTFBHOMf0fGN5EsBcMysihhxwztlcm00nl8e4dlh"
// );
const Payments = () => {
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const getCardListt = async () => {
    const data = {
      page: 1,
      limit: 100,
    };
    dispatch(getCardList(data)).then((res) => {
      if (res.payload.data?.length === 0) {
        navigate("/add-card");
      }
    });
  };
  useEffect(() => {
    getCardListt();
  }, []);
  // const { cardList } = useSelector((s) => s.card);

  // useEffect(() => {
  //   if (cardList?.length === 0) {
  // navigate("/add-card");
  //   }
  // }, [cardList]);

  return (
    <MainLayout>
      <PaymentsComponent
        setShowAddCardModal={setShowAddCardModal}
        showAddCardModal={setShowAddCardModal}
      />
      {/* <Elements stripe={stripePromise}> */}
      <Addpaymentmod
        show={showAddCardModal}
        onhide={() => setShowAddCardModal(false)}
        getCardList={getCardList}
      />
      {/* </Elements> */}
    </MainLayout>
  );
};

export default Payments;
