import React, { useState } from "react";
import { Container, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import Fade from "react-reveal";
import { Link } from "react-router-dom";
import Staticsbalnce from "./RightSideCard";
import Statement from "./Statement";
import Overviewtab from "./Overviewtab";
import Engagement from "./Engagement";
import Reachtab from "./Reachtab";
import Fans from "./Fans";
import { useTranslation } from "react-i18next";
import Earningsstatics from "../Statements/Earningsstatics";

const StatisticsComponent = (props) => {
  // statement
  const [tab, setTab] = useState("statement");
  const [subTab, setSubTab] = useState("");
  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
  };
  const { t } = useTranslation();

  return (
    <Fade>
      <section className="collection_tabbing text-start">
        <Container fluid>
          <Row>
            <Col lg={7} md={12} sm={12} className="padding_tabet ps-0">
              <div className="statics_height">
                {/* topheaderbar */}
                <div className="Statics_wrap commonBox border border_hide">
                  <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
                    <Link to="/home">
                      <div className="notification_left_heading d-flex align-items-center">
                        <span className="me-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                          >
                            <path
                              d="M1 7L7 1M1 7L7 13M1 7L15 7"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <h5>{t("Statistics")}</h5>
                      </div>
                    </Link>
                  </div>
                  {/* topheaderbar */}
                  <div className="CardBody pb-1 pt-4 commonTabs2 statics_tabs">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Userlist"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <Nav
                        variant="pills"
                        className=""
                        style={{ flexWrap: "unset " }}
                      >
                        <Nav.Item className="">
                          <Nav.Link
                            className="bg-transparent border-0 position-relative text-small rounded-0"
                            eventKey="Statement"
                            onClick={() => handleTabSelectedtion("statement")}
                            active={tab === "statement"}
                          >
                            {t("STATEMENTS")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                          <Nav.Link
                            className="bg-transparent border-0 position-relative text-small rounded-0 "
                            eventKey="Overview"
                            onClick={() => handleTabSelectedtion("overview")}
                            active={tab === "overview"}
                          >
                            {t("OVERVIEW")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                          <Nav.Link
                            className="bg-transparent border-0 position-relative text-small rounded-0 "
                            eventKey="Engagment"
                            onClick={() => handleTabSelectedtion("engagment")}
                            active={tab === "engagment"}
                          >
                            {t("ENGAGEMENT")}
                          </Nav.Link>
                        </Nav.Item>

                        {/* <Nav.Item className="">
                          <Nav.Link
                            className="bg-transparent border-0 position-relative text-small rounded-0 "
                            eventKey="Reach"
                            onClick={() => handleTabSelectedtion("reach")}
                            active={tab === "reach"}
                          >
                            REACH
                          </Nav.Link>
                        </Nav.Item> */}

                        <Nav.Item className="">
                          <Nav.Link
                            className="bg-transparent border-0 position-relative text-small rounded-0 "
                            eventKey="Fans"
                            onClick={() => handleTabSelectedtion("fans")}
                            active={tab === "fans"}
                          >
                            {t("FANS")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div>
                </div>
                <Tab.Content className="">
                  <Tab.Pane eventKey="statement" active={tab === "statement"}>
                    <Statement />
                  </Tab.Pane>
                  <Tab.Pane eventKey="overview" active={tab === "overview"}>
                    {/* <Overviewtab /> */}
                    <Earningsstatics title="Overview" />
                  </Tab.Pane>

                  <Tab.Pane eventKey="engagment" active={tab === "engagment"}>
                    <Engagement setSubTab={setSubTab} />
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="reach" active={tab === "reach"}>
                    <Reachtab />
                  </Tab.Pane> */}

                  <Tab.Pane eventKey="fans" active={tab === "fans"}>
                    <Fans />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
            <Col lg={5} md={12} sm={12}>
              <div className="card_boader h-100 min-vh-100 border-0">
                {tab === "statement" && (
                  <Staticsbalnce tab={tab} subTab={subTab} />
                )}
                {tab === "overview" && (
                  <Staticsbalnce tab={tab} subTab={subTab} />
                )}
                {tab === "engagment" && (
                  <Staticsbalnce tab={tab} subTab={subTab} />
                )}
                {tab === "reach" && <Staticsbalnce tab={tab} subTab={subTab} />}
                {tab === "fans" && <Staticsbalnce tab={tab} subTab={subTab} />}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default StatisticsComponent;
