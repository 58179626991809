import "./w9.css";
import "./extra-fonts/stylesheet.css";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addW9Form } from "../../../redux/states/card/thunk";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { useEffect, useState, useRef, createRef } from "react";

export default function W9Form({ onSend, loading, setLoading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socialSecurityRef = useRef(
    Array(10)
      .fill(null)
      .map(() => createRef())
  );
  const employeeIdentificationNumberRef = useRef(
    Array(10)
      .fill(null)
      .map(() => createRef())
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      businessName: "",
      taxClassification: "",
      llcClassification: "",
      taxClassificationOtherText: "",
      exemptPayeeCode: "",
      fatcaReportingCode: "",
      address: "",
      cityStateZip: "",
      accountNumbers: "",
      requesterNameAndAddress: "",
      socialSecurityNumber: [],
      employeeIdentificationNumber: [],
      signature: "",
      date: new Date().toISOString(),
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Name is required")),
      businessName: Yup.string().required(t("Business name is required")),
      taxClassification: Yup.string().required(
        t("Please select tax classification")
      ),
      llcClassification: Yup.string().required(
        t("Please select Limited liability company")
      ),
      address: Yup.string().required(t("Please enter address")),
      cityStateZip: Yup.string().required(t("Please enter city &  state")),
      accountNumbers: Yup.string().required(t("Account number is required")),
      socialSecurityNumber: Yup.number()
        .required("Please enter social security number")
        .min(9, "Please enter valid social security number"),
      employeeIdentificationNumber: Yup.number()
        .required("Please enter employee Identfication number")
        .min(9, "Please enter valid employee identification number"),
      signature: Yup.string().required(t("Signature is required")),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { socialSecurityNumber , employeeIdentificationNumber , ...otherValuess } = values;
      const data = {
        ...otherValuess , socialSecurityNumber : socialSecurityNumber.unshift() , employeeIdentificationNumber : employeeIdentificationNumber.unshift()
      }
      setLoading(true);
      dispatch(addW9Form(data))
        .unwrap()
        .then((res) => {
          onSend();
          toast.success(res?.message);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.message);
        });
    },
  });

  const checkValidation = () => {
    if (Object.keys(errors).length > 0) {
      const error = Object.values(errors)?.[0];
      toast.warning(error);
    }
  };

  const handleFormSubmit = () => {
    checkValidation();
    handleSubmit();
  };

  const handleSocialSecurityChange = (e, index) => {
    const val = e.target.value;
    var preValue = values?.socialSecurityNumber;
    preValue[index ] = val;
    setFieldValue(`socialSecurityNumber`, preValue);

    if (index < 9 && e.target.value !== "") {
      socialSecurityRef.current[index + 1].current.focus();
    }

    if (index > 0 && e.target.value === "") {
      socialSecurityRef.current[index - 1].current.focus();
    }
  };

  const handleEmployeeIdentificationChange = (e, index) => {
    const val = e.target.value;
    var preValue = values?.employeeIdentificationNumber;
    preValue[index] = val;
    setFieldValue(`employeeIdentificationNumber`, preValue);

    if (index < 9 && e.target.value !== "") {
      employeeIdentificationNumberRef.current[index + 1].current.focus();
    }

    if (index > 0 && e.target.value === "") {
      employeeIdentificationNumberRef.current[index - 1].current.focus();
    }
  };



  return (
    <form>
      <div className="w9-form-content">
        <div className="pdf-page">
          <div className="pdf-page-inner pdf-page-0">
            <div className="pdf-page-text">
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 96,
                  width: 31,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.837838) scaleY(0.625)",
                }}
              >
                <span>
                  Form
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 117,
                  top: 72,
                  width: 79,
                  height: 35,
                  fontSize: 48,
                  transform: "scaleX(0.849462) scaleY(0.607143)",
                }}
              >
                <span>
                  W-9
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 116,
                  width: 136,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.866242) scaleY(0.9375)",
                }}
              >
                <span>
                  (Rev. October 2018)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 131,
                  width: 155,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.790816) scaleY(0.875)",
                }}
              >
                <span>
                  Department of the Treasury
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 470,
                  top: 74,
                  width: 261,
                  height: 27,
                  fontSize: 28,
                  transform: "scaleX(0.875839) scaleY(0.787879)",
                }}
              >
                <span>
                  Request for Taxpayer
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 362,
                  top: 100,
                  width: 476,
                  height: 23,
                  fontSize: 28,
                  transform: "scaleX(0.867031) scaleY(0.666667)",
                }}
              >
                <span>
                  Identification Number and Certification
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 972,
                  top: 87,
                  width: 140,
                  height: 18,
                  fontSize: 18,
                  transform: "scaleX(0.927152) scaleY(0.809524)",
                }}
              >
                <span>
                  Give Form to the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 972,
                  top: 104,
                  width: 147,
                  height: 24,
                  fontSize: 18,
                  transform: "scaleX(0.948387) scaleY(1.09524)",
                }}
              >
                <span>
                  requester. Do not
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 972,
                  top: 127,
                  width: 133,
                  height: 16,
                  fontSize: 18,
                  transform: "scaleX(0.93662) scaleY(0.714286)",
                }}
              >
                <span>
                  send to the IRS.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 146,
                  width: 140,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.786517) scaleY(0.625)",
                }}
              >
                <span>
                  Internal Revenue Service
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 329,
                  top: 144,
                  width: 541,
                  height: 16,
                  fontSize: 12,
                  transform: "scaleX(1.23799) scaleY(1.07143)",
                }}
              >
                <span>
                  ▶ Go to www.irs.gov/FormW9 for instructions and the latest
                  information.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 121,
                  top: 168,
                  width: 639,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.867029) scaleY(0.8125)",
                }}
              >
                <span>
                  1 Name (as shown on your income tax return). Name is required
                  on this line; do not leave this line blank.
                  <br />
                </span>
              </div>
              <input
                name="name"
                value={values?.name}
                onChange={handleChange}
                type="text"
                className="pde-form-field pdf-annot obj_1 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 121,
                  top: 215,
                  width: 405,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.865385) scaleY(0.8125)",
                }}
              >
                <span>
                  2 Business name/disregarded entity name, if different from
                  above
                  <br />
                </span>
              </div>
              <input
                name="businessName"
                value={values.businessName}
                onChange={handleChange}
                type="text"
                className="pde-form-field pdf-annot obj_2 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 75,
                  top: 341,
                  width: 15,
                  height: 7,
                  fontSize: 16,
                  transform: "scaleX(0.144231) scaleY(0.315789)",
                }}
              >
                <span>
                  Print or type.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 93,
                  top: 347,
                  width: 16,
                  height: 174,
                  fontSize: 16,
                  transform: "scaleX(0.0561404) scaleY(9.10526)",
                }}
              >
                <span>
                  See Specific Instructions on page 3.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 120,
                  top: 266,
                  width: 760,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.873563) scaleY(0.875)",
                }}
              >
                <span>
                  3 Check appropriate box for federal tax classification of the
                  person whose name is entered on line 1. Check only one of the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 136,
                  top: 280,
                  width: 140,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.875) scaleY(0.875)",
                }}
              >
                <span>
                  following seven boxes.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 898,
                  top: 265,
                  width: 216,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.892562) scaleY(0.9375)",
                }}
              >
                <span>
                  4 Exemptions (codes apply only to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 899,
                  top: 280,
                  width: 215,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.856574) scaleY(0.8125)",
                }}
              >
                <span>
                  certain entities, not individuals; see
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 899,
                  top: 297,
                  width: 143,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.856287) scaleY(0.75)",
                }}
              >
                <span>
                  instructions on page 3):
                  <br />
                </span>
              </div>
              <input
                name="taxClassification"
                value={"Individual/sole proprietor or single-member LLC"}
                checked={
                  values.taxClassification ===
                  "Individual/sole proprietor or single-member LLC"
                }
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field"
                defaultValue={1}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 156,
                  top: 317,
                  width: 169,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.853535) scaleY(0.75)",
                }}
              >
                <span>
                  Individual/sole proprietor or
                  <br />
                </span>
              </div>
              <input
                name="taxClassification"
                value={"C-Corporation"}
                checked={values.taxClassification === "C-Corporation"}
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field pde-form-field pdf-annot obj_4 pdf-obj-fixed acroform-field"
                defaultValue={2}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 379,
                  top: 315,
                  width: 87,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.878788) scaleY(0.8125)",
                }}
              >
                <span>
                  C Corporation
                  <br />
                </span>
              </div>
              <input
                name="taxClassification"
                value={"S-Corporation"}
                checked={values.taxClassification === "S-Corporation"}
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field pde-form-field pdf-annot obj_5 pdf-obj-fixed acroform-field"
                defaultValue={3}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 518,
                  top: 315,
                  width: 85,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.867347) scaleY(0.8125)",
                }}
              >
                <span>
                  S Corporation
                  <br />
                </span>
              </div>
              <input
                name="taxClassification"
                value={"Partnership"}
                checked={values.taxClassification === "Partnership"}
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field pde-form-field pdf-annot obj_6 pdf-obj-fixed acroform-field"
                defaultValue={4}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 662,
                  top: 315,
                  width: 70,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.875) scaleY(0.8125)",
                }}
              >
                <span>
                  Partnership
                  <br />
                </span>
              </div>
              <input
                name="taxClassification"
                value={"Trust/Estate"}
                checked={values.taxClassification === "Trust/Estate"}
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field pde-form-field pdf-annot obj_7 pdf-obj-fixed acroform-field"
                defaultValue={5}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 800,
                  top: 315,
                  width: 74,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.891566) scaleY(0.625)",
                }}
              >
                <span>
                  Trust/estate
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 155,
                  top: 332,
                  width: 121,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.876812) scaleY(0.8125)",
                }}
              >
                <span>
                  single-member LLC
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 898,
                  top: 336,
                  width: 161,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.829897) scaleY(0.8125)",
                }}
              >
                <span>
                  Exempt payee code (if any)
                  <br />
                </span>
              </div>
              <input
                name="exemptPayeeCode"
                value={values.exemptPayeeCode}
                onChange={handleChange}
                type="text"
                className="pde-form-field pdf-annot obj_8 pdf-obj-fixed acroform-field"
              />
              <input
                name="taxClassification"
                value={"Limited Liability Company"}
                checked={
                  values.taxClassification === "Limited Liability Company"
                }
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field pde-form-field pdf-annot obj_9 pdf-obj-fixed acroform-field"
                defaultValue={6}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 156,
                  top: 362,
                  width: 651,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.865692) scaleY(0.8125)",
                }}
              >
                <span>
                  Limited liability company. Enter the tax classification (C=C
                  corporation, S=S corporation, P=Partnership) ▶<br />
                </span>
              </div>
              <input
                name="llcClassification"
                value={values.llcClassification}
                onChange={handleChange}
                maxLength={1}
                type="text"
                className="pde-form-field pdf-annot obj_10 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 156,
                  top: 383,
                  width: 726,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.873646) scaleY(0.8125)",
                }}
              >
                <span>
                  Note: Check the appropriate box in the line above for the tax
                  classification of the single-member owner. Do not check
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 156,
                  top: 399,
                  width: 721,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.879268) scaleY(0.875)",
                }}
              >
                <span>
                  LLC if the LLC is classified as a single-member LLC that is
                  disregarded from the owner unless the owner of the LLC is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 155,
                  top: 413,
                  width: 734,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.875895) scaleY(0.9375)",
                }}
              >
                <span>
                  another LLC that is not disregarded from the owner for U.S.
                  federal tax purposes. Otherwise, a single-member LLC that
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 898,
                  top: 385,
                  width: 204,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.87931) scaleY(1.125)",
                }}
              >
                <span>
                  Exemption from FATCA reporting
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 900,
                  top: 403,
                  width: 74,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.840909) scaleY(1.0625)",
                }}
              >
                <span>
                  code (if any)
                  <br />
                </span>
              </div>
              <input
                name="fatcaReportingCode"
                value={values.fatcaReportingCode}
                onChange={handleChange}
                type="text"
                className="pde-form-field pdf-annot obj_11 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 156,
                  top: 431,
                  width: 622,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.866295) scaleY(0.75)",
                }}
              >
                <span>
                  is disregarded from the owner should check the appropriate box
                  for the tax classification of its owner.
                  <br />
                </span>
              </div>
              <input
                name="taxClassification"
                value={"Other"}
                checked={values.taxClassification === "Other"}
                onChange={handleChange}
                type="radio"
                data-v-498d3fd0=""
                className="pde-form-field pdf-annot obj_3 pdf-obj-fixed acroform-field pde-form-field pdf-annot obj_12 pdf-obj-fixed acroform-field"
                defaultValue={7}
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 155,
                  top: 453,
                  width: 156,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.866667) scaleY(0.875)",
                }}
              >
                <span>
                  Other (see instructions) ▶<br />
                </span>
              </div>
              <input
                name="taxClassificationOtherText"
                value={values.taxClassificationOtherText}
                onChange={handleChange}
                type="text"
                className="pde-form-field pdf-annot obj_13 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 898,
                  top: 453,
                  width: 215,
                  height: 10,
                  fontSize: 10,
                  transform: "scaleX(0.881148) scaleY(0.818182)",
                }}
              >
                <span>
                  (Applies to accounts maintained outside the U.S.)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 121,
                  top: 474,
                  width: 403,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.877996) scaleY(0.75)",
                }}
              >
                <span>
                  5 Address (number, street, and apt. or suite no.) See
                  instructions.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 771,
                  top: 474,
                  width: 251,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.862543) scaleY(0.75)",
                }}
              >
                <span>
                  Requester’s name and address (optional)
                  <br />
                </span>
              </div>
              <input
                name="address"
                value={values.address}
                onChange={handleChange}
                type="text"
                required="required"
                className="pde-form-field pdf-annot obj_16 obj_16-x pdf-obj-fixed acroform-field"
              />
              <textarea
                name="requesterNameAndAddress"
                value={values.requesterNameAndAddress}
                onChange={handleChange}
                className="pde-form-field pdf-annot obj_15 pdf-obj-fixed acroform-field"
                defaultValue={""}
              />{" "}
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 121,
                  top: 521,
                  width: 167,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.912568) scaleY(0.75)",
                }}
              >
                <span>
                  6 City, state, and ZIP code
                  <br />
                </span>
              </div>
              <input
                name="cityStateZip"
                value={values.cityStateZip}
                onChange={handleChange}
                type="text"
                required="required"
                className="pde-form-field pdf-annot obj_16 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 121,
                  top: 568,
                  width: 246,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.866197) scaleY(0.75)",
                }}
              >
                <span>
                  7 List account number(s) here (optional)
                  <br />
                </span>
              </div>
              <input
                name="accountNumbers"
                value={values.accountNumbers}
                onChange={handleChange}
                type="text"
                className="pde-form-field pdf-annot obj_17 pdf-obj-fixed acroform-field"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 82,
                  top: 615,
                  width: 48,
                  height: 15,
                  fontSize: 20,
                  transform: "scaleX(0.941177) scaleY(0.583333)",
                }}
              >
                <span>
                  Part I<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 178,
                  top: 615,
                  width: 343,
                  height: 18,
                  fontSize: 20,
                  transform: "scaleX(0.927027) scaleY(0.708333)",
                }}
              >
                <span>
                  Taxpayer Identification Number (TIN)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 640,
                  width: 712,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.869353) scaleY(0.789474)",
                }}
              >
                <span>
                  Enter your TIN in the appropriate box. The TIN provided must
                  match the name given on line 1 to avoid
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 827,
                  top: 638,
                  width: 150,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.920245) scaleY(0.8125)",
                }}
              >
                <span>
                  Social security number
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 658,
                  width: 717,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878677) scaleY(0.842105)",
                }}
              >
                <span>
                  backup withholding. For individuals, this is generally your
                  social security number (SSN). However, for a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 674,
                  width: 680,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867347) scaleY(0.947368)",
                }}
              >
                <span>
                  resident alien, sole proprietor, or disregarded entity, see
                  the instructions for Part I, later. For other
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 692,
                  width: 710,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.865854) scaleY(0.842105)",
                }}
              >
                <span>
                  entities, it is your employer identification number (EIN). If
                  you do not have a number, see How to get a<br />
                </span>
              </div>
              <span className="ssn-wrapper">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((itm, ind) => {
                  return (
                    <input
                      name={`socialSecurityNumber${[itm]}`}
                      value={values?.socialSecurityNumber?.[itm]}
                      onChange={(e) => handleSocialSecurityChange(e, itm)}
                      type="text"
                      data-refid={0}
                      data-ref="ssn"
                      min={0}
                      step={1}
                      max={9}
                      maxLength={1}
                      pattern="[0-9]"
                      className={`acroform-field pdf-obj-fixed pdf-annot ssn-part ssn-${ind}`}
                      ref={socialSecurityRef.current[ind]}
                    />
                  );
                })}
              </span>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 918,
                  top: 682,
                  width: 9,
                  height: 2,
                  fontSize: 16,
                  transform: "scaleX(1.125) scaleY(0.0526316)",
                }}
              >
                <span>
                  –<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 1003,
                  top: 682,
                  width: 8,
                  height: 2,
                  fontSize: 16,
                  transform: "scaleX(1) scaleY(0.0526316)",
                }}
              >
                <span>
                  –<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 711,
                  width: 67,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.858974) scaleY(0.684211)",
                }}
              >
                <span>
                  TIN, later.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 819,
                  top: 714,
                  width: 18,
                  height: 11,
                  fontSize: 18,
                  transform: "scaleX(1) scaleY(0.47619)",
                }}
              >
                <span>
                  or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 734,
                  width: 718,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.872418) scaleY(0.736842)",
                }}
              >
                <span>
                  Note: If the account is in more than one name, see the
                  instructions for line 1. Also see What Name and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 827,
                  top: 732,
                  width: 205,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.911111) scaleY(0.8125)",
                }}
              >
                <span>
                  Employer identification number
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 752,
                  width: 508,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.877375) scaleY(0.736842)",
                }}
              >
                <span>
                  Number To Give the Requester for guidelines on whose number to
                  enter.
                  <br />
                </span>
              </div>
              <span className="ssn-wrapper">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((itm, ind) => {
                  return (
                    <input
                      name={`employeeIdentificationNumber${[itm]}`}
                      value={values?.employeeIdentificationNumber?.[ind]}
                      onChange={(e) =>
                        handleEmployeeIdentificationChange(e, itm)
                      }
                      type="text"
                      data-refid={0}
                      data-ref="employer"
                      min={0}
                      step={1}
                      max={9}
                      maxLength={1}
                      pattern="[0-9]"
                      className={`acroform-field pdf-obj-fixed pdf-annot ssn-part employer-part employer-${ind}`}
                      ref={employeeIdentificationNumberRef.current[ind]}
                    />
                  );
                })}
              </span>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 890,
                  top: 776,
                  width: 9,
                  height: 3,
                  fontSize: 16,
                  transform: "scaleX(1.125) scaleY(0.105263)",
                }}
              >
                <span>
                  –<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 79,
                  top: 803,
                  width: 54,
                  height: 15,
                  fontSize: 20,
                  transform: "scaleX(0.947368) scaleY(0.583333)",
                }}
              >
                <span>
                  Part II
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 179,
                  top: 803,
                  width: 114,
                  height: 15,
                  fontSize: 20,
                  transform: "scaleX(0.926829) scaleY(0.583333)",
                }}
              >
                <span>
                  Certification
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 828,
                  width: 276,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.857143) scaleY(0.789474)",
                }}
              >
                <span>
                  Under penalties of perjury, I certify that:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 852,
                  width: 931,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.862836) scaleY(0.736842)",
                }}
              >
                <span>
                  1. The number shown on this form is my correct taxpayer
                  identification number (or I am waiting for a number to be
                  issued to me); and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 871,
                  width: 1034,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.87037) scaleY(0.842105)",
                }}
              >
                <span>
                  2. I am not subject to backup withholding because: (a) I am
                  exempt from backup withholding, or (b) I have not been
                  notified by the Internal Revenue
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 88,
                  top: 887,
                  width: 1034,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.868178) scaleY(0.947368)",
                }}
              >
                <span>
                  Service (IRS) that I am subject to backup withholding as a
                  result of a failure to report all interest or dividends, or
                  (c) the IRS has notified me that I am
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 89,
                  top: 905,
                  width: 315,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.879888) scaleY(0.842105)",
                }}
              >
                <span>
                  no longer subject to backup withholding; and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 931,
                  width: 437,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.874) scaleY(1)",
                }}
              >
                <span>
                  3. I am a U.S. citizen or other U.S. person (defined below);
                  and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 950,
                  width: 775,
                  height: 25,
                  fontSize: 16,
                  transform: "scaleX(0.873732) scaleY(1.26316)",
                }}
              >
                <span>
                  4. The FATCA code(s) entered on this form (if any) indicating
                  that I am exempt from FATCA reporting is correct.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 974,
                  width: 1056,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.846154) scaleY(1.10526)",
                }}
              >
                <span>
                  Certification instructions. You must cross out item 2 above if
                  you have been notified by the IRS that you are currently
                  subject to backup withholding because
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 995,
                  width: 986,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.827181) scaleY(0.947368)",
                }}
              >
                <span>
                  you have failed to report all interest and dividends on your
                  tax return. For real estate transactions, item 2 does not
                  apply. For mortgage interest paid,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1013,
                  width: 1028,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.823058) scaleY(0.894737)",
                }}
              >
                <span>
                  acquisition or abandonment of secured property, cancellation
                  of debt, contributions to an individual retirement arrangement
                  (IRA), and generally, payments
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1030,
                  width: 1026,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.824759) scaleY(0.842105)",
                }}
              >
                <span>
                  other than interest and dividends, you are not required to
                  sign the certification, but you must provide your correct TIN.
                  See the instructions for Part II, later.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1061,
                  width: 40,
                  height: 19,
                  fontSize: 20,
                  transform: "scaleX(0.888889) scaleY(0.75)",
                }}
              >
                <span>
                  Sign
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 153,
                  top: 1070,
                  width: 80,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.91954) scaleY(0.8125)",
                }}
              >
                <span>
                  Signature of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1081,
                  width: 44,
                  height: 15,
                  fontSize: 20,
                  transform: "scaleX(0.93617) scaleY(0.583333)",
                }}
              >
                <span>
                  Here
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 153,
                  top: 1086,
                  width: 90,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.947368) scaleY(0.8125)",
                }}
              >
                <span>
                  U.S. person ▶<br />
                </span>
              </div>
              <input
                name="signature"
                value={values.signature}
                onChange={handleChange}
                type="text"
                className="acroform-field pdf-obj-fixed pdf-annot obj_23"
              />
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 760,
                  top: 1086,
                  width: 45,
                  height: 12,
                  fontSize: 14,
                  transform: "scaleX(0.918367) scaleY(0.6875)",
                }}
              >
                <span>
                  Date ▶<br />
                </span>
              </div>
              <div className="w9-form-date">
                {" "}
                {moment(values.date).format("MM/DD/Y")}{" "}
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1120,
                  width: 265,
                  height: 21,
                  fontSize: 28,
                  transform: "scaleX(0.926573) scaleY(0.606061)",
                }}
              >
                <span>
                  General Instructions
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1153,
                  width: 488,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.871429) scaleY(0.789474)",
                }}
              >
                <span>
                  Section references are to the Internal Revenue Code unless
                  otherwise
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1168,
                  width: 44,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.862745) scaleY(1.10526)",
                }}
              >
                <span>
                  noted.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1189,
                  width: 490,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.889292) scaleY(1.15789)",
                }}
              >
                <span>
                  Future developments. For the latest information about
                  developments
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1211,
                  width: 476,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.881481) scaleY(0.894737)",
                }}
              >
                <span>
                  related to Form W-9 and its instructions, such as legislation
                  enacted
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1228,
                  width: 380,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.875576) scaleY(0.894737)",
                }}
              >
                <span>
                  after they were published, go to www.irs.gov/FormW9.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 1260,
                  width: 186,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.944162) scaleY(0.75)",
                }}
              >
                <span>
                  Purpose of Form
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1291,
                  width: 481,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.869801) scaleY(0.842105)",
                }}
              >
                <span>
                  An individual or entity (Form W-9 requester) who is required
                  to file an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1307,
                  width: 456,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.863636) scaleY(0.894737)",
                }}
              >
                <span>
                  information return with the IRS must obtain your correct
                  taxpayer
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1324,
                  width: 484,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867384) scaleY(0.947368)",
                }}
              >
                <span>
                  identification number (TIN) which may be your social security
                  number
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1342,
                  width: 443,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.868627) scaleY(0.947368)",
                }}
              >
                <span>
                  (SSN), individual taxpayer identification number (ITIN),
                  adoption
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1360,
                  width: 505,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.863248) scaleY(0.894737)",
                }}
              >
                <span>
                  taxpayer identification number (ATIN), or employer
                  identification number
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1377,
                  width: 506,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.857627) scaleY(0.947368)",
                }}
              >
                <span>
                  (EIN), to report on an information return the amount paid to
                  you, or other
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1395,
                  width: 480,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.857143) scaleY(0.947368)",
                }}
              >
                <span>
                  amount reportable on an information return. Examples of
                  information
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1413,
                  width: 359,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.862981) scaleY(0.842105)",
                }}
              >
                <span>
                  returns include, but are not limited to, the following.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1436,
                  width: 289,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.86527) scaleY(0.736842)",
                }}
              >
                <span>
                  • Form 1099-INT (interest earned or paid)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1120,
                  width: 467,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.87782) scaleY(0.842105)",
                }}
              >
                <span>
                  • Form 1099-DIV (dividends, including those from stocks or
                  mutual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1136,
                  width: 43,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.86) scaleY(0.842105)",
                }}
              >
                <span>
                  funds)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1163,
                  width: 485,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.885036) scaleY(0.842105)",
                }}
              >
                <span>
                  • Form 1099-MISC (various types of income, prizes, awards, or
                  gross
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1179,
                  width: 69,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.8625) scaleY(0.842105)",
                }}
              >
                <span>
                  proceeds)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1202,
                  width: 422,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.875519) scaleY(0.842105)",
                }}
              >
                <span>
                  • Form 1099-B (stock or mutual fund sales and certain other
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1218,
                  width: 169,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.875648) scaleY(0.842105)",
                }}
              >
                <span>
                  transactions by brokers)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1241,
                  width: 386,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.867416) scaleY(0.736842)",
                }}
              >
                <span>
                  • Form 1099-S (proceeds from real estate transactions)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1262,
                  width: 476,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.865455) scaleY(0.789474)",
                }}
              >
                <span>
                  • Form 1099-K (merchant card and third party network
                  transactions)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1284,
                  width: 486,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.858657) scaleY(0.842105)",
                }}
              >
                <span>
                  • Form 1098 (home mortgage interest), 1098-E (student loan
                  interest),
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1300,
                  width: 105,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.860656) scaleY(0.842105)",
                }}
              >
                <span>
                  1098-T (tuition)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1323,
                  width: 217,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.878543) scaleY(0.789474)",
                }}
              >
                <span>
                  • Form 1099-C (canceled debt)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1345,
                  width: 445,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.85249) scaleY(1.05263)",
                }}
              >
                <span>
                  • Form 1099-A (acquisition or abandonment of secured property)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 636,
                  top: 1365,
                  width: 449,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.880392) scaleY(1.10526)",
                }}
              >
                <span>
                  Use Form W-9 only if you are a U.S. person (including a
                  resident
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1386,
                  width: 238,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.862319) scaleY(1.10526)",
                }}
              >
                <span>
                  alien), to provide your correct TIN.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 635,
                  top: 1407,
                  width: 477,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.870438) scaleY(1.10526)",
                }}
              >
                <span>
                  If you do not return Form W-9 to the requester with a TIN, you
                  might
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1428,
                  width: 470,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.886793) scaleY(0.894737)",
                }}
              >
                <span>
                  be subject to backup withholding. See What is backup
                  withholding,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1445,
                  width: 33,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.804878) scaleY(0.684211)",
                }}
              >
                <span>
                  later.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 458,
                  top: 1491,
                  width: 101,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.885965) scaleY(0.625)",
                }}
              >
                <span>
                  Cat. No. 10231X
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 963,
                  top: 1485,
                  width: 166,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.954023) scaleY(1.0625)",
                }}
              >
                <span>
                  Form W-9 (Rev. 10-2018)
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pdf-page">
          <div className="pdf-page-inner pdf-page-1">
            <div className="pdf-page-text">
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 75,
                  width: 154,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.885057) scaleY(0.8125)",
                }}
              >
                <span>
                  Form W-9 (Rev. 10-2018)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 1084,
                  top: 72,
                  width: 46,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.978723) scaleY(1.0625)",
                }}
              >
                <span>
                  Page 2<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 85,
                  top: 109,
                  width: 239,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.869091) scaleY(0.789474)",
                }}
              >
                <span>
                  By signing the filled-out form, you:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 132,
                  width: 491,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.861404) scaleY(0.894737)",
                }}
              >
                <span>
                  1. Certify that the TIN you are giving is correct (or you are
                  waiting for a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 149,
                  width: 151,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.862857) scaleY(0.842105)",
                }}
              >
                <span>
                  number to be issued),
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 174,
                  width: 417,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.87605) scaleY(0.736842)",
                }}
              >
                <span>
                  2. Certify that you are not subject to backup withholding, or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 197,
                  width: 488,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.876122) scaleY(0.842105)",
                }}
              >
                <span>
                  3. Claim exemption from backup withholding if you are a U.S.
                  exempt
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 213,
                  width: 491,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.873665) scaleY(0.947368)",
                }}
              >
                <span>
                  payee. If applicable, you are also certifying that as a U.S.
                  person, your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 231,
                  width: 502,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871528) scaleY(0.947368)",
                }}
              >
                <span>
                  allocable share of any partnership income from a U.S. trade or
                  business
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 249,
                  width: 452,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.872587) scaleY(0.894737)",
                }}
              >
                <span>
                  is not subject to the withholding tax on foreign partners'
                  share of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 266,
                  width: 239,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.869091) scaleY(1.10526)",
                }}
              >
                <span>
                  effectively connected income, and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 287,
                  width: 472,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.872458) scaleY(1.05263)",
                }}
              >
                <span>
                  4. Certify that FATCA code(s) entered on this form (if any)
                  indicating
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 307,
                  width: 491,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875223) scaleY(0.947368)",
                }}
              >
                <span>
                  that you are exempt from the FATCA reporting, is correct. See
                  What is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 325,
                  width: 323,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.86828) scaleY(1.10526)",
                }}
              >
                <span>
                  FATCA reporting, later, for further information.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 346,
                  width: 485,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.870736) scaleY(1.05263)",
                }}
              >
                <span>
                  Note: If you are a U.S. person and a requester gives you a
                  form other
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 366,
                  width: 508,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.874355) scaleY(0.947368)",
                }}
              >
                <span>
                  than Form W-9 to request your TIN, you must use the
                  requester’s form if
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 384,
                  width: 291,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.881818) scaleY(1.05263)",
                }}
              >
                <span>
                  it is substantially similar to this Form W-9.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 404,
                  width: 436,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.898969) scaleY(1.10526)",
                }}
              >
                <span>
                  Definition of a U.S. person. For federal tax purposes, you are
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 425,
                  width: 250,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.874126) scaleY(0.842105)",
                }}
              >
                <span>
                  considered a U.S. person if you are:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 450,
                  width: 402,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.883517) scaleY(0.736842)",
                }}
              >
                <span>
                  • An individual who is a U.S. citizen or U.S. resident alien;
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 474,
                  width: 453,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.872832) scaleY(0.842105)",
                }}
              >
                <span>
                  • A partnership, corporation, company, or association created
                  or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 490,
                  width: 485,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.873874) scaleY(0.842105)",
                }}
              >
                <span>
                  organized in the United States or under the laws of the United
                  States;
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 515,
                  width: 298,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.851429) scaleY(0.736842)",
                }}
              >
                <span>
                  • An estate (other than a foreign estate); or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 538,
                  width: 460,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.877863) scaleY(1)",
                }}
              >
                <span>
                  • A domestic trust (as defined in Regulations section
                  301.7701-7).
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 557,
                  width: 484,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.906367) scaleY(1.10526)",
                }}
              >
                <span>
                  Special rules for partnerships. Partnerships that conduct a
                  trade or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 578,
                  width: 506,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.872414) scaleY(0.947368)",
                }}
              >
                <span>
                  business in the United States are generally required to pay a
                  withholding
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 596,
                  width: 469,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866913) scaleY(0.894737)",
                }}
              >
                <span>
                  tax under section 1446 on any foreign partners’ share of
                  effectively
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 613,
                  width: 504,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.878049) scaleY(0.894737)",
                }}
              >
                <span>
                  connected taxable income from such business. Further, in
                  certain cases
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 630,
                  width: 498,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.87522) scaleY(0.947368)",
                }}
              >
                <span>
                  where a Form W-9 has not been received, the rules under
                  section 1446
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 648,
                  width: 497,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.859862) scaleY(0.947368)",
                }}
              >
                <span>
                  require a partnership to presume that a partner is a foreign
                  person, and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 666,
                  width: 506,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870912) scaleY(0.947368)",
                }}
              >
                <span>
                  pay the section 1446 withholding tax. Therefore, if you are a
                  U.S. person
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 684,
                  width: 489,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.871658) scaleY(0.894737)",
                }}
              >
                <span>
                  that is a partner in a partnership conducting a trade or
                  business in the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 701,
                  width: 480,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.880734) scaleY(0.947368)",
                }}
              >
                <span>
                  United States, provide Form W-9 to the partnership to
                  establish your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 719,
                  width: 448,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.88189) scaleY(0.947368)",
                }}
              >
                <span>
                  U.S. status and avoid section 1446 withholding on your share
                  of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 737,
                  width: 139,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.863354) scaleY(1.05263)",
                }}
              >
                <span>
                  partnership income.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 757,
                  width: 473,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.880819) scaleY(1.10526)",
                }}
              >
                <span>
                  In the cases below, the following person must give Form W-9 to
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 778,
                  width: 475,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.8829) scaleY(0.894737)",
                }}
              >
                <span>
                  partnership for purposes of establishing its U.S. status and
                  avoiding
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 795,
                  width: 481,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869801) scaleY(0.947368)",
                }}
              >
                <span>
                  withholding on its allocable share of net income from the
                  partnership
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 813,
                  width: 363,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878935) scaleY(0.842105)",
                }}
              >
                <span>
                  conducting a trade or business in the United States.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 838,
                  width: 492,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.875445) scaleY(0.842105)",
                }}
              >
                <span>
                  • In the case of a disregarded entity with a U.S. owner, the
                  U.S. owner
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 854,
                  width: 301,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.86) scaleY(0.842105)",
                }}
              >
                <span>
                  of the disregarded entity and not the entity;
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 879,
                  width: 491,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.865961) scaleY(0.842105)",
                }}
              >
                <span>
                  • In the case of a grantor trust with a U.S. grantor or other
                  U.S. owner,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 895,
                  width: 490,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.862676) scaleY(0.947368)",
                }}
              >
                <span>
                  generally, the U.S. grantor or other U.S. owner of the grantor
                  trust and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 913,
                  width: 119,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.856115) scaleY(0.789474)",
                }}
              >
                <span>
                  not the trust; and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 938,
                  width: 479,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.863063) scaleY(0.842105)",
                }}
              >
                <span>
                  • In the case of a U.S. trust (other than a grantor trust),
                  the U.S. trust
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 954,
                  width: 444,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.852207) scaleY(1.10526)",
                }}
              >
                <span>
                  (other than a grantor trust) and not the beneficiaries of the
                  trust.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 975,
                  width: 469,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.884906) scaleY(1.05263)",
                }}
              >
                <span>
                  Foreign person. If you are a foreign person or the U.S. branch
                  of a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 995,
                  width: 503,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.876307) scaleY(0.947368)",
                }}
              >
                <span>
                  foreign bank that has elected to be treated as a U.S. person,
                  do not use
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1013,
                  width: 483,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.881387) scaleY(0.947368)",
                }}
              >
                <span>
                  Form W-9. Instead, use the appropriate Form W-8 or Form 8233
                  (see
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1031,
                  width: 449,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.890873) scaleY(0.894737)",
                }}
              >
                <span>
                  Pub. 515, Withholding of Tax on Nonresident Aliens and Foreign
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1048,
                  width: 59,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.855072) scaleY(1.10526)",
                }}
              >
                <span>
                  Entities).
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1069,
                  width: 484,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.916667) scaleY(1.05263)",
                }}
              >
                <span>
                  Nonresident alien who becomes a resident alien. Generally,
                  only a
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1089,
                  width: 496,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.862609) scaleY(0.947368)",
                }}
              >
                <span>
                  nonresident alien individual may use the terms of a tax treaty
                  to reduce
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1107,
                  width: 473,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871087) scaleY(0.947368)",
                }}
              >
                <span>
                  or eliminate U.S. tax on certain types of income. However,
                  most tax
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1125,
                  width: 473,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.882463) scaleY(0.894737)",
                }}
              >
                <span>
                  treaties contain a provision known as a “saving clause.”
                  Exceptions
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1142,
                  width: 469,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.866913) scaleY(0.947368)",
                }}
              >
                <span>
                  specified in the saving clause may permit an exemption from
                  tax to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1160,
                  width: 501,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.863793) scaleY(0.894737)",
                }}
              >
                <span>
                  continue for certain types of income even after the payee has
                  otherwise
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1177,
                  width: 324,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.873315) scaleY(1.10526)",
                }}
              >
                <span>
                  become a U.S. resident alien for tax purposes.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1198,
                  width: 426,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.871166) scaleY(1.05263)",
                }}
              >
                <span>
                  If you are a U.S. resident alien who is relying on an
                  exception
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1218,
                  width: 471,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.865809) scaleY(0.789474)",
                }}
              >
                <span>
                  contained in the saving clause of a tax treaty to claim an
                  exemption
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1233,
                  width: 493,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.869489) scaleY(0.842105)",
                }}
              >
                <span>
                  from U.S. tax on certain types of income, you must attach a
                  statement
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1249,
                  width: 355,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.878713) scaleY(1)",
                }}
              >
                <span>
                  to Form W-9 that specifies the following five items.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1268,
                  width: 472,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.864469) scaleY(0.947368)",
                }}
              >
                <span>
                  1. The treaty country. Generally, this must be the same treaty
                  under
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1286,
                  width: 433,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.869478) scaleY(0.789474)",
                }}
              >
                <span>
                  which you claimed exemption from tax as a nonresident alien.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 1309,
                  width: 303,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.865714) scaleY(0.736842)",
                }}
              >
                <span>
                  2. The treaty article addressing the income.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 1330,
                  width: 470,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.857664) scaleY(0.789474)",
                }}
              >
                <span>
                  3. The article number (or location) in the tax treaty that
                  contains the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1345,
                  width: 231,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.885057) scaleY(1)",
                }}
              >
                <span>
                  saving clause and its exceptions.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 1364,
                  width: 462,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.865169) scaleY(0.947368)",
                }}
              >
                <span>
                  4. The type and amount of income that qualifies for the
                  exemption
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1382,
                  width: 62,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.837838) scaleY(0.894737)",
                }}
              >
                <span>
                  from tax.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 1399,
                  width: 487,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.866548) scaleY(1.10526)",
                }}
              >
                <span>
                  5. Sufficient facts to justify the exemption from tax under
                  the terms of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1420,
                  width: 117,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.841727) scaleY(0.789474)",
                }}
              >
                <span>
                  the treaty article.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 634,
                  top: 109,
                  width: 461,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.884837) scaleY(0.789474)",
                }}
              >
                <span>
                  Example. Article 20 of the U.S.-China income tax treaty allows
                  an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 124,
                  width: 460,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.872865) scaleY(0.842105)",
                }}
              >
                <span>
                  exemption from tax for scholarship income received by a
                  Chinese
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 140,
                  width: 484,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.876812) scaleY(0.842105)",
                }}
              >
                <span>
                  student temporarily present in the United States. Under U.S.
                  law, this
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 156,
                  width: 502,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.870017) scaleY(0.789474)",
                }}
              >
                <span>
                  student will become a resident alien for tax purposes if his
                  or her stay in
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 171,
                  width: 489,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.874776) scaleY(0.842105)",
                }}
              >
                <span>
                  the United States exceeds 5 calendar years. However, paragraph
                  2 of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 187,
                  width: 489,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.874776) scaleY(0.842105)",
                }}
              >
                <span>
                  the first Protocol to the U.S.-China treaty (dated April 30,
                  1984) allows
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 203,
                  width: 493,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.874114) scaleY(0.789474)",
                }}
              >
                <span>
                  the provisions of Article 20 to continue to apply even after
                  the Chinese
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 218,
                  width: 457,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.88054) scaleY(0.736842)",
                }}
              >
                <span>
                  student becomes a resident alien of the United States. A
                  Chinese
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 232,
                  width: 488,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.866785) scaleY(0.947368)",
                }}
              >
                <span>
                  student who qualifies for this exception (under paragraph 2 of
                  the first
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 250,
                  width: 506,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.869416) scaleY(0.789474)",
                }}
              >
                <span>
                  protocol) and is relying on this exception to claim an
                  exemption from tax
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 265,
                  width: 475,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878004) scaleY(0.842105)",
                }}
              >
                <span>
                  on his or her scholarship or fellowship income would attach to
                  Form
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 281,
                  width: 460,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.872865) scaleY(0.736842)",
                }}
              >
                <span>
                  W-9 a statement that includes the information described above
                  to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 295,
                  width: 168,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.870466) scaleY(1.05263)",
                }}
              >
                <span>
                  support that exemption.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 638,
                  top: 315,
                  width: 489,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.854895) scaleY(1)",
                }}
              >
                <span>
                  If you are a nonresident alien or a foreign entity, give the
                  requester the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 334,
                  width: 337,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.877604) scaleY(0.789474)",
                }}
              >
                <span>
                  appropriate completed Form W-8 or Form 8233.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 362,
                  width: 224,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.941177) scaleY(0.75)",
                }}
              >
                <span>
                  Backup Withholding
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 393,
                  width: 506,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.90681) scaleY(0.842105)",
                }}
              >
                <span>
                  What is backup withholding? Persons making certain payments to
                  you
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 409,
                  width: 500,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.883392) scaleY(0.947368)",
                }}
              >
                <span>
                  must under certain conditions withhold and pay to the IRS 24%
                  of such
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 427,
                  width: 493,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.888288) scaleY(0.947368)",
                }}
              >
                <span>
                  payments. This is called “backup withholding.” Payments that
                  may be
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 445,
                  width: 472,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.877323) scaleY(0.894737)",
                }}
              >
                <span>
                  subject to backup withholding include interest, tax-exempt
                  interest,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 462,
                  width: 476,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870201) scaleY(0.947368)",
                }}
              >
                <span>
                  dividends, broker and barter exchange transactions, rents,
                  royalties,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 480,
                  width: 489,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.863958) scaleY(0.947368)",
                }}
              >
                <span>
                  nonemployee pay, payments made in settlement of payment card
                  and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 498,
                  width: 506,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866438) scaleY(0.894737)",
                }}
              >
                <span>
                  third party network transactions, and certain payments from
                  fishing boat
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 515,
                  width: 429,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.877301) scaleY(0.947368)",
                }}
              >
                <span>
                  operators. Real estate transactions are not subject to backup
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 533,
                  width: 85,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.885417) scaleY(1.05263)",
                }}
              >
                <span>
                  withholding.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 634,
                  top: 553,
                  width: 445,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.891784) scaleY(1.10526)",
                }}
              >
                <span>
                  You will not be subject to backup withholding on payments you
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 574,
                  width: 465,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.861111) scaleY(0.947368)",
                }}
              >
                <span>
                  receive if you give the requester your correct TIN, make the
                  proper
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 592,
                  width: 499,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870855) scaleY(0.947368)",
                }}
              >
                <span>
                  certifications, and report all your taxable interest and
                  dividends on your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 610,
                  width: 71,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.835294) scaleY(0.947368)",
                }}
              >
                <span>
                  tax return.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 628,
                  width: 463,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.941057) scaleY(1.10526)",
                }}
              >
                <span>
                  Payments you receive will be subject to backup withholding if:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 638,
                  top: 658,
                  width: 329,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.872679) scaleY(0.736842)",
                }}
              >
                <span>
                  1. You do not furnish your TIN to the requester,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 681,
                  width: 480,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.874317) scaleY(0.789474)",
                }}
              >
                <span>
                  2. You do not certify your TIN when required (see the
                  instructions for
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 699,
                  width: 122,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.853147) scaleY(1)",
                }}
              >
                <span>
                  Part II for details),
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 718,
                  width: 453,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.867816) scaleY(1.21053)",
                }}
              >
                <span>
                  3. The IRS tells the requester that you furnished an incorrect
                  TIN,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 741,
                  width: 438,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.881288) scaleY(1.10526)",
                }}
              >
                <span>
                  4. The IRS tells you that you are subject to backup
                  withholding
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 762,
                  width: 486,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.872531) scaleY(0.947368)",
                }}
              >
                <span>
                  because you did not report all your interest and dividends on
                  your tax
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 780,
                  width: 364,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.856471) scaleY(1.05263)",
                }}
              >
                <span>
                  return (for reportable interest and dividends only), or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 800,
                  width: 446,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.871094) scaleY(1.10526)",
                }}
              >
                <span>
                  5. You do not certify to the requester that you are not
                  subject to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 821,
                  width: 496,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.868652) scaleY(0.894737)",
                }}
              >
                <span>
                  backup withholding under 4 above (for reportable interest and
                  dividend
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 838,
                  width: 239,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.869091) scaleY(1.10526)",
                }}
              >
                <span>
                  accounts opened after 1983 only).
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 859,
                  width: 474,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.869725) scaleY(1.10526)",
                }}
              >
                <span>
                  Certain payees and payments are exempt from backup
                  withholding.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 880,
                  width: 473,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.86789) scaleY(0.894737)",
                }}
              >
                <span>
                  See Exempt payee code, later, and the separate Instructions
                  for the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 897,
                  width: 315,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.867769) scaleY(1.10526)",
                }}
              >
                <span>
                  Requester of Form W-9 for more information.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 634,
                  top: 918,
                  width: 323,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.87062) scaleY(1)",
                }}
              >
                <span>
                  Also see Special rules for partnerships, earlier.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 952,
                  width: 298,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.96129) scaleY(0.75)",
                }}
              >
                <span>
                  What is FATCA Reporting?
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 983,
                  width: 429,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.899371) scaleY(0.842105)",
                }}
              >
                <span>
                  The Foreign Account Tax Compliance Act (FATCA) requires a
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 999,
                  width: 458,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867424) scaleY(0.947368)",
                }}
              >
                <span>
                  participating foreign financial institution to report all
                  United States
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1017,
                  width: 458,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.879079) scaleY(0.894737)",
                }}
              >
                <span>
                  account holders that are specified United States persons.
                  Certain
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1034,
                  width: 496,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.885714) scaleY(0.947368)",
                }}
              >
                <span>
                  payees are exempt from FATCA reporting. See Exemption from
                  FATCA
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1052,
                  width: 475,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869963) scaleY(0.947368)",
                }}
              >
                <span>
                  reporting code, later, and the Instructions for the Requester
                  of Form
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1070,
                  width: 180,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.857143) scaleY(0.684211)",
                }}
              >
                <span>
                  W-9 for more information.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1101,
                  width: 297,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.939873) scaleY(0.75)",
                }}
              >
                <span>
                  Updating Your Information
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1132,
                  width: 466,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.877589) scaleY(0.842105)",
                }}
              >
                <span>
                  You must provide updated information to any person to whom you
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1148,
                  width: 485,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.864528) scaleY(0.947368)",
                }}
              >
                <span>
                  claimed to be an exempt payee if you are no longer an exempt
                  payee
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1166,
                  width: 470,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.860806) scaleY(0.894737)",
                }}
              >
                <span>
                  and anticipate receiving reportable payments in the future
                  from this
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1183,
                  width: 486,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870968) scaleY(0.947368)",
                }}
              >
                <span>
                  person. For example, you may need to provide updated
                  information if
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1201,
                  width: 492,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.87234) scaleY(0.947368)",
                }}
              >
                <span>
                  you are a C corporation that elects to be an S corporation, or
                  if you no
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1219,
                  width: 494,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.871252) scaleY(0.894737)",
                }}
              >
                <span>
                  longer are tax exempt. In addition, you must furnish a new
                  Form W-9 if
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1236,
                  width: 508,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.861017) scaleY(0.947368)",
                }}
              >
                <span>
                  the name or TIN changes for the account; for example, if the
                  grantor of a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1254,
                  width: 124,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.861111) scaleY(0.842105)",
                }}
              >
                <span>
                  grantor trust dies.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1285,
                  width: 102,
                  height: 18,
                  fontSize: 24,
                  transform: "scaleX(0.93578) scaleY(0.607143)",
                }}
              >
                <span>
                  Penalties
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1316,
                  width: 447,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.888668) scaleY(0.842105)",
                }}
              >
                <span>
                  Failure to furnish TIN. If you fail to furnish your correct
                  TIN to a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1332,
                  width: 464,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.86406) scaleY(0.947368)",
                }}
              >
                <span>
                  requester, you are subject to a penalty of $50 for each such
                  failure
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1350,
                  width: 498,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.870629) scaleY(1.10526)",
                }}
              >
                <span>
                  unless your failure is due to reasonable cause and not to
                  willful neglect.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1371,
                  width: 501,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.927778) scaleY(1.05263)",
                }}
              >
                <span>
                  Civil penalty for false information with respect to
                  withholding. If you
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1391,
                  width: 461,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.869811) scaleY(0.842105)",
                }}
              >
                <span>
                  make a false statement with no reasonable basis that results
                  in no
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1407,
                  width: 382,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878161) scaleY(0.947368)",
                }}
              >
                <span>
                  backup withholding, you are subject to a $500 penalty.
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pdf-page">
          <div className="pdf-page-inner pdf-page-2">
            <div className="pdf-page-text">
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 75,
                  width: 154,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.885057) scaleY(0.8125)",
                }}
              >
                <span>
                  Form W-9 (Rev. 10-2018)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 1084,
                  top: 72,
                  width: 46,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.978723) scaleY(1.0625)",
                }}
              >
                <span>
                  Page 3<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 109,
                  width: 435,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.908142) scaleY(0.842105)",
                }}
              >
                <span>
                  Criminal penalty for falsifying information. Willfully
                  falsifying
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 125,
                  width: 458,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.865785) scaleY(0.947368)",
                }}
              >
                <span>
                  certifications or affirmations may subject you to criminal
                  penalties
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 143,
                  width: 254,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.869863) scaleY(1.05263)",
                }}
              >
                <span>
                  including fines and/or imprisonment.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 163,
                  width: 486,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.898336) scaleY(1.10526)",
                }}
              >
                <span>
                  Misuse of TINs. If the requester discloses or uses TINs in
                  violation of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 184,
                  width: 500,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.866551) scaleY(0.842105)",
                }}
              >
                <span>
                  federal law, the requester may be subject to civil and
                  criminal penalties.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 219,
                  width: 268,
                  height: 26,
                  fontSize: 28,
                  transform: "scaleX(0.943662) scaleY(0.757576)",
                }}
              >
                <span>
                  Specific Instructions
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 258,
                  width: 57,
                  height: 17,
                  fontSize: 22,
                  transform: "scaleX(0.850746) scaleY(0.64)",
                }}
              >
                <span>
                  Line 1<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 287,
                  width: 485,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.881818) scaleY(0.894737)",
                }}
              >
                <span>
                  You must enter one of the following on this line; do not leave
                  this line
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 304,
                  width: 418,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.861856) scaleY(0.842105)",
                }}
              >
                <span>
                  blank. The name should match the name on your tax return.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 328,
                  width: 421,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.871636) scaleY(0.894737)",
                }}
              >
                <span>
                  If this Form W-9 is for a joint account (other than an account
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 345,
                  width: 466,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.861368) scaleY(0.894737)",
                }}
              >
                <span>
                  maintained by a foreign financial institution (FFI)), list
                  first, and then
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 362,
                  width: 482,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.862254) scaleY(0.947368)",
                }}
              >
                <span>
                  circle, the name of the person or entity whose number you
                  entered in
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 380,
                  width: 508,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.885017) scaleY(0.947368)",
                }}
              >
                <span>
                  Part I of Form W-9. If you are providing Form W-9 to an FFI to
                  document
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 398,
                  width: 486,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.875676) scaleY(0.894737)",
                }}
              >
                <span>
                  a joint account, each holder of the account that is a U.S.
                  person must
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 415,
                  width: 143,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.882716) scaleY(1.10526)",
                }}
              >
                <span>
                  provide a Form W-9.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 436,
                  width: 480,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.880734) scaleY(1.05263)",
                }}
              >
                <span>
                  a. Individual. Generally, enter the name shown on your tax
                  return. If
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 456,
                  width: 499,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.873905) scaleY(0.947368)",
                }}
              >
                <span>
                  you have changed your last name without informing the Social
                  Security
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 474,
                  width: 502,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.864028) scaleY(0.947368)",
                }}
              >
                <span>
                  Administration (SSA) of the name change, enter your first
                  name, the last
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 492,
                  width: 487,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.87276) scaleY(1.05263)",
                }}
              >
                <span>
                  name as shown on your social security card, and your new last
                  name.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 512,
                  width: 488,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.887273) scaleY(1.10526)",
                }}
              >
                <span>
                  Note: ITIN applicant: Enter your individual name as it was
                  entered on
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 533,
                  width: 502,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.883803) scaleY(0.894737)",
                }}
              >
                <span>
                  your Form W-7 application, line 1a. This should also be the
                  same as the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 550,
                  width: 497,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.873462) scaleY(0.947368)",
                }}
              >
                <span>
                  name you entered on the Form 1040/1040A/1040EZ you filed with
                  your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 568,
                  width: 80,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.869565) scaleY(1.10526)",
                }}
              >
                <span>
                  application.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 589,
                  width: 457,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.921371) scaleY(1.05263)",
                }}
              >
                <span>
                  b. Sole proprietor or single-member LLC. Enter your individual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 609,
                  width: 491,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.881508) scaleY(0.842105)",
                }}
              >
                <span>
                  name as shown on your 1040/1040A/1040EZ on line 1. You may
                  enter
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 628,
                  width: 449,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.880392) scaleY(0.789474)",
                }}
              >
                <span>
                  your business, trade, or “doing business as” (DBA) name on
                  line
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 523,
                  top: 629,
                  width: 12,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.8) scaleY(0.578947)",
                }}
              >
                <span>
                  2.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 662,
                  width: 412,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.95814) scaleY(0.842105)",
                }}
              >
                <span>
                  c. Partnership, LLC that is not a single-member LLC, C<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 678,
                  width: 508,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.899115) scaleY(0.842105)",
                }}
              >
                <span>
                  corporation, or S corporation. Enter the entity's name as
                  shown on the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 697,
                  width: 481,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.871377) scaleY(0.789474)",
                }}
              >
                <span>
                  entity's tax return on line 1 and any business, trade, or DBA
                  name on
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 715,
                  width: 24,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.857143) scaleY(0.578947)",
                }}
              >
                <span>
                  line
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 99,
                  top: 715,
                  width: 12,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.8) scaleY(0.578947)",
                }}
              >
                <span>
                  2.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 738,
                  width: 480,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.872727) scaleY(0.789474)",
                }}
              >
                <span>
                  d. Other entities. Enter your name as shown on required U.S.
                  federal
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 756,
                  width: 508,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.858108) scaleY(0.736842)",
                }}
              >
                <span>
                  tax documents on line 1. This name should match the name shown
                  on the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 770,
                  width: 481,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.846831) scaleY(0.947368)",
                }}
              >
                <span>
                  charter or other legal document creating the entity. You may
                  enter any
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 791,
                  width: 255,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.861487) scaleY(0.736842)",
                }}
              >
                <span>
                  business, trade, or DBA name on line
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 330,
                  top: 791,
                  width: 12,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.8) scaleY(0.578947)",
                }}
              >
                <span>
                  2.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 814,
                  width: 490,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.904059) scaleY(0.894737)",
                }}
              >
                <span>
                  e. Disregarded entity. For U.S. federal tax purposes, an
                  entity that is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 831,
                  width: 442,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.864971) scaleY(0.842105)",
                }}
              >
                <span>
                  disregarded as an entity separate from its owner is treated as
                  a
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 850,
                  width: 505,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878261) scaleY(0.842105)",
                }}
              >
                <span>
                  “disregarded entity.” See Regulations section
                  301.7701-2(c)(2)(iii). Enter
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 866,
                  width: 474,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.860254) scaleY(0.947368)",
                }}
              >
                <span>
                  the owner's name on line 1. The name of the entity entered on
                  line 1<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 884,
                  width: 498,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.87522) scaleY(0.894737)",
                }}
              >
                <span>
                  should never be a disregarded entity. The name on line 1
                  should be the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 901,
                  width: 489,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.873214) scaleY(0.842105)",
                }}
              >
                <span>
                  name shown on the income tax return on which the income should
                  be
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 917,
                  width: 493,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.869489) scaleY(1)",
                }}
              >
                <span>
                  reported. For example, if a foreign LLC that is treated as a
                  disregarded
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 936,
                  width: 473,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875926) scaleY(0.947368)",
                }}
              >
                <span>
                  entity for U.S. federal tax purposes has a single owner that
                  is a U.S.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 954,
                  width: 483,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.876588) scaleY(0.947368)",
                }}
              >
                <span>
                  person, the U.S. owner's name is required to be provided on
                  line 1. If
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 972,
                  width: 496,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.864112) scaleY(0.894737)",
                }}
              >
                <span>
                  the direct owner of the entity is also a disregarded entity,
                  enter the first
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 989,
                  width: 449,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866795) scaleY(0.894737)",
                }}
              >
                <span>
                  owner that is not disregarded for federal tax purposes. Enter
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1009,
                  width: 233,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.872659) scaleY(0.736842)",
                }}
              >
                <span>
                  disregarded entity's name on line
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 308,
                  top: 1009,
                  width: 12,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.8) scaleY(0.684211)",
                }}
              >
                <span>
                  2,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 326,
                  top: 1008,
                  width: 245,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.878136) scaleY(0.789474)",
                }}
              >
                <span>
                  “Business name/disregarded entity
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1026,
                  width: 477,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.861011) scaleY(0.789474)",
                }}
              >
                <span>
                  name.” If the owner of the disregarded entity is a foreign
                  person, the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1044,
                  width: 498,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878307) scaleY(0.842105)",
                }}
              >
                <span>
                  owner must complete an appropriate Form W-8 instead of a Form
                  W-9.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1060,
                  width: 401,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.879386) scaleY(0.842105)",
                }}
              >
                <span>
                  This is the case even if the foreign person has a U.S. TIN.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 1091,
                  width: 60,
                  height: 17,
                  fontSize: 22,
                  transform: "scaleX(0.895522) scaleY(0.64)",
                }}
              >
                <span>
                  Line 2<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1120,
                  width: 482,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.868468) scaleY(0.789474)",
                }}
              >
                <span>
                  If you have a business name, trade name, DBA name, or
                  disregarded
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1138,
                  width: 253,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.85473) scaleY(0.736842)",
                }}
              >
                <span>
                  entity name, you may enter it on line
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 328,
                  top: 1138,
                  width: 13,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.866667) scaleY(0.578947)",
                }}
              >
                <span>
                  2.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 1167,
                  width: 60,
                  height: 17,
                  fontSize: 22,
                  transform: "scaleX(0.895522) scaleY(0.64)",
                }}
              >
                <span>
                  Line 3<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1197,
                  width: 414,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.873418) scaleY(0.842105)",
                }}
              >
                <span>
                  Check the appropriate box on line 3 for the U.S. federal tax
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1213,
                  width: 506,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878472) scaleY(0.947368)",
                }}
              >
                <span>
                  classification of the person whose name is entered on line 1.
                  Check only
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1231,
                  width: 124,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.873239) scaleY(0.631579)",
                }}
              >
                <span>
                  one box on line 3.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 630,
                  top: 115,
                  width: 229,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.934694) scaleY(0.842105)",
                }}
              >
                <span>
                  IF the entity/person on line 1 is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 629,
                  top: 131,
                  width: 53,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.854839) scaleY(0.842105)",
                }}
              >
                <span>
                  a(n) . . .<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 883,
                  top: 115,
                  width: 204,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.931507) scaleY(0.631579)",
                }}
              >
                <span>
                  THEN check the box for . . .<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 162,
                  width: 99,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.908257) scaleY(0.736842)",
                }}
              >
                <span>
                  • Corporation
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 883,
                  top: 162,
                  width: 83,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.873684) scaleY(0.736842)",
                }}
              >
                <span>
                  Corporation
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 186,
                  width: 82,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.911111) scaleY(0.578947)",
                }}
              >
                <span>
                  • Individual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 884,
                  top: 187,
                  width: 243,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.874101) scaleY(0.789474)",
                }}
              >
                <span>
                  Individual/sole proprietor or single-
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 207,
                  width: 173,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.896373) scaleY(0.789474)",
                }}
              >
                <span>
                  • Sole proprietorship, or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 884,
                  top: 205,
                  width: 90,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.873786) scaleY(0.631579)",
                }}
              >
                <span>
                  member LLC
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 229,
                  width: 227,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.886719) scaleY(0.842105)",
                }}
              >
                <span>
                  • Single-member limited liability
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 625,
                  top: 245,
                  width: 201,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.885463) scaleY(0.894737)",
                }}
              >
                <span>
                  company (LLC) owned by an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 262,
                  width: 241,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.873188) scaleY(0.947368)",
                }}
              >
                <span>
                  individual and disregarded for U.S.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 625,
                  top: 280,
                  width: 147,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.864706) scaleY(0.842105)",
                }}
              >
                <span>
                  federal tax purposes.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 311,
                  width: 240,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.888889) scaleY(0.842105)",
                }}
              >
                <span>
                  • LLC treated as a partnership for
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 327,
                  width: 181,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.874396) scaleY(0.842105)",
                }}
              >
                <span>
                  U.S. federal tax purposes,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 884,
                  top: 313,
                  width: 244,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.868327) scaleY(0.842105)",
                }}
              >
                <span>
                  Limited liability company and enter
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 883,
                  top: 329,
                  width: 232,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.868914) scaleY(0.842105)",
                }}
              >
                <span>
                  the appropriate tax classification.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 350,
                  width: 238,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.898113) scaleY(0.789474)",
                }}
              >
                <span>
                  • LLC that has filed Form 8832 or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 625,
                  top: 365,
                  width: 241,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.870036) scaleY(1.05263)",
                }}
              >
                <span>
                  2553 to be taxed as a corporation,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 625,
                  top: 385,
                  width: 15,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.882353) scaleY(0.631579)",
                }}
              >
                <span>
                  or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 883,
                  top: 348,
                  width: 242,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.870504) scaleY(0.842105)",
                }}
              >
                <span>
                  (P= Partnership; C= C corporation;
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 883,
                  top: 364,
                  width: 142,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.865854) scaleY(0.842105)",
                }}
              >
                <span>
                  or S= S corporation)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 407,
                  width: 221,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.898374) scaleY(0.842105)",
                }}
              >
                <span>
                  • LLC that is disregarded as an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 625,
                  top: 423,
                  width: 236,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.861314) scaleY(0.947368)",
                }}
              >
                <span>
                  entity separate from its owner but
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 625,
                  top: 441,
                  width: 224,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.88189) scaleY(0.842105)",
                }}
              >
                <span>
                  the owner is another LLC that is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 457,
                  width: 246,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.866197) scaleY(1.10526)",
                }}
              >
                <span>
                  not disregarded for U.S. federal tax
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 478,
                  width: 68,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.871795) scaleY(0.736842)",
                }}
              >
                <span>
                  purposes.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 507,
                  width: 96,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.914286) scaleY(0.736842)",
                }}
              >
                <span>
                  • Partnership
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 884,
                  top: 507,
                  width: 80,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.879121) scaleY(0.736842)",
                }}
              >
                <span>
                  Partnership
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 626,
                  top: 530,
                  width: 100,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.925926) scaleY(0.631579)",
                }}
              >
                <span>
                  • Trust/estate
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 883,
                  top: 530,
                  width: 84,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.893617) scaleY(0.631579)",
                }}
              >
                <span>
                  Trust/estate
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 560,
                  width: 194,
                  height: 20,
                  fontSize: 22,
                  transform: "scaleX(0.92823) scaleY(0.76)",
                }}
              >
                <span>
                  Line 4, Exemptions
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 589,
                  width: 475,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878004) scaleY(0.842105)",
                }}
              >
                <span>
                  If you are exempt from backup withholding and/or FATCA
                  reporting,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 605,
                  width: 486,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.86631) scaleY(0.842105)",
                }}
              >
                <span>
                  enter in the appropriate space on line 4 any code(s) that may
                  apply to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 628,
                  width: 29,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.852941) scaleY(0.578947)",
                }}
              >
                <span>
                  you.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 648,
                  width: 160,
                  height: 16,
                  fontSize: 17,
                  transform: "scaleX(0.935673) scaleY(0.75)",
                }}
              >
                <span>
                  Exempt payee code.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 671,
                  width: 501,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.871304) scaleY(0.894737)",
                }}
              >
                <span>
                  • Generally, individuals (including sole proprietors) are not
                  exempt from
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 688,
                  width: 140,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.880503) scaleY(0.842105)",
                }}
              >
                <span>
                  backup withholding.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 713,
                  width: 472,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.883895) scaleY(0.842105)",
                }}
              >
                <span>
                  • Except as provided below, corporations are exempt from
                  backup
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 729,
                  width: 464,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.873823) scaleY(0.842105)",
                }}
              >
                <span>
                  withholding for certain payments, including interest and
                  dividends.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 754,
                  width: 493,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878788) scaleY(0.842105)",
                }}
              >
                <span>
                  • Corporations are not exempt from backup withholding for
                  payments
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 770,
                  width: 502,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.861064) scaleY(0.842105)",
                }}
              >
                <span>
                  made in settlement of payment card or third party network
                  transactions.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 795,
                  width: 505,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.881326) scaleY(0.842105)",
                }}
              >
                <span>
                  • Corporations are not exempt from backup withholding with
                  respect to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 811,
                  width: 487,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875899) scaleY(0.947368)",
                }}
              >
                <span>
                  attorneys’ fees or gross proceeds paid to attorneys, and
                  corporations
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 829,
                  width: 507,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866667) scaleY(0.894737)",
                }}
              >
                <span>
                  that provide medical or health care services are not exempt
                  with respect
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 846,
                  width: 315,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.884832) scaleY(1.10526)",
                }}
              >
                <span>
                  to payments reportable on Form 1099-MISC.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 634,
                  top: 867,
                  width: 459,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.872624) scaleY(1.10526)",
                }}
              >
                <span>
                  The following codes identify payees that are exempt from
                  backup
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 888,
                  width: 429,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.871951) scaleY(1.05263)",
                }}
              >
                <span>
                  withholding. Enter the appropriate code in the space in line
                  4.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 632,
                  top: 908,
                  width: 486,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.86941) scaleY(1.10526)",
                }}
              >
                <span>
                  1—An organization exempt from tax under section 501(a), any
                  IRA, or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 929,
                  width: 490,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.870338) scaleY(0.894737)",
                }}
              >
                <span>
                  a custodial account under section 403(b)(7) if the account
                  satisfies the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 946,
                  width: 224,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.842105) scaleY(1.10526)",
                }}
              >
                <span>
                  requirements of section 401(f)(2)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 967,
                  width: 441,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.875) scaleY(1.21053)",
                }}
              >
                <span>
                  2—The United States or any of its agencies or
                  instrumentalities
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 990,
                  width: 431,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.883197) scaleY(1.10526)",
                }}
              >
                <span>
                  3—A state, the District of Columbia, a U.S. commonwealth or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1011,
                  width: 471,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.878731) scaleY(1.10526)",
                }}
              >
                <span>
                  possession, or any of their political subdivisions or
                  instrumentalities
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 1032,
                  width: 487,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.879061) scaleY(1.05263)",
                }}
              >
                <span>
                  4—A foreign government or any of its political subdivisions,
                  agencies,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1052,
                  width: 133,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.858065) scaleY(1)",
                }}
              >
                <span>
                  or instrumentalities
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 1071,
                  width: 119,
                  height: 26,
                  fontSize: 16,
                  transform: "scaleX(0.881481) scaleY(1.31579)",
                }}
              >
                <span>
                  5—A corporation
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 1096,
                  width: 464,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.868914) scaleY(1.10526)",
                }}
              >
                <span>
                  6—A dealer in securities or commodities required to register
                  in the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1117,
                  width: 470,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.878505) scaleY(0.894737)",
                }}
              >
                <span>
                  United States, the District of Columbia, or a U.S.
                  commonwealth or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1134,
                  width: 78,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.906977) scaleY(1.10526)",
                }}
              >
                <span>
                  possession
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 1155,
                  width: 468,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.874766) scaleY(1.10526)",
                }}
              >
                <span>
                  7—A futures commission merchant registered with the Commodity
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1176,
                  width: 201,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.893333) scaleY(1.05263)",
                }}
              >
                <span>
                  Futures Trading Commission
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 1196,
                  width: 229,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.867424) scaleY(1.15789)",
                }}
              >
                <span>
                  8—A real estate investment trust
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 631,
                  top: 1218,
                  width: 447,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.862934) scaleY(1.15789)",
                }}
              >
                <span>
                  9—An entity registered at all times during the tax year under
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1240,
                  width: 234,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.873134) scaleY(1.10526)",
                }}
              >
                <span>
                  Investment Company Act of 1940
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1261,
                  width: 468,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.869889) scaleY(1.21053)",
                }}
              >
                <span>
                  10—A common trust fund operated by a bank under section 584(a)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1284,
                  width: 177,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.876238) scaleY(1.15789)",
                }}
              >
                <span>
                  11—A financial institution
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1306,
                  width: 499,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.870855) scaleY(1.21053)",
                }}
              >
                <span>
                  12—A middleman known in the investment community as a nominee
                  or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1329,
                  width: 69,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.896104) scaleY(0.947368)",
                }}
              >
                <span>
                  custodian
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1347,
                  width: 492,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.87234) scaleY(1.10526)",
                }}
              >
                <span>
                  13—A trust exempt from tax under section 664 or described in
                  section
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1371,
                  width: 35,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.875) scaleY(0.631579)",
                }}
              >
                <span>
                  4947
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pdf-page">
          <div className="pdf-page-inner pdf-page-3">
            <div className="pdf-page-text">
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 75,
                  width: 154,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.885057) scaleY(0.8125)",
                }}
              >
                <span>
                  Form W-9 (Rev. 10-2018)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 1083,
                  top: 72,
                  width: 46,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.978723) scaleY(1.0625)",
                }}
              >
                <span>
                  Page 4<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 85,
                  top: 109,
                  width: 462,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.875) scaleY(0.842105)",
                }}
              >
                <span>
                  The following chart shows types of payments that may be exempt
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 125,
                  width: 501,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.874346) scaleY(0.947368)",
                }}
              >
                <span>
                  from backup withholding. The chart applies to the exempt
                  payees listed
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 143,
                  width: 145,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.868264) scaleY(0.842105)",
                }}
              >
                <span>
                  above, 1 through 13.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 170,
                  width: 178,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.908163) scaleY(0.736842)",
                }}
              >
                <span>
                  IF the payment is for . . .<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 170,
                  width: 218,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.931624) scaleY(0.842105)",
                }}
              >
                <span>
                  THEN the payment is exempt
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 186,
                  width: 47,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.886793) scaleY(0.684211)",
                }}
              >
                <span>
                  for . . .<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 217,
                  width: 220,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.869565) scaleY(0.736842)",
                }}
              >
                <span>
                  Interest and dividend payments
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 217,
                  width: 181,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.878641) scaleY(0.842105)",
                }}
              >
                <span>
                  All exempt payees except
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 233,
                  width: 32,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.842105) scaleY(0.684211)",
                }}
              >
                <span>
                  for 7<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 263,
                  width: 136,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.871795) scaleY(0.578947)",
                }}
              >
                <span>
                  Broker transactions
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 333,
                  top: 263,
                  width: 236,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.870849) scaleY(0.789474)",
                }}
              >
                <span>
                  Exempt payees 1 through 4 and 6<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 278,
                  width: 239,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875458) scaleY(0.947368)",
                }}
              >
                <span>
                  through 11 and all C corporations.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 296,
                  width: 231,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871698) scaleY(0.947368)",
                }}
              >
                <span>
                  S corporations must not enter an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 314,
                  width: 238,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.878229) scaleY(0.894737)",
                }}
              >
                <span>
                  exempt payee code because they
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 331,
                  width: 195,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.866667) scaleY(0.947368)",
                }}
              >
                <span>
                  are exempt only for sales of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 333,
                  top: 349,
                  width: 219,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.87251) scaleY(0.947368)",
                }}
              >
                <span>
                  noncovered securities acquired
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 333,
                  top: 367,
                  width: 93,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.853211) scaleY(0.842105)",
                }}
              >
                <span>
                  prior to 2012.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 396,
                  width: 235,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.863971) scaleY(0.842105)",
                }}
              >
                <span>
                  Barter exchange transactions and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 412,
                  width: 143,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.877301) scaleY(0.842105)",
                }}
              >
                <span>
                  patronage dividends
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 333,
                  top: 396,
                  width: 192,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.868778) scaleY(0.736842)",
                }}
              >
                <span>
                  Exempt payees 1 through 4<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 440,
                  width: 246,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.869258) scaleY(0.894737)",
                }}
              >
                <span>
                  Payments over $600 required to be
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 457,
                  width: 210,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867769) scaleY(0.947368)",
                }}
              >
                <span>
                  reported and direct sales over
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 475,
                  width: 52,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.8) scaleY(1)",
                }}
              >
                <span>
                  $5,0001
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 332,
                  top: 441,
                  width: 182,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.870813) scaleY(0.842105)",
                }}
              >
                <span>
                  Generally, exempt payees
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 333,
                  top: 457,
                  width: 85,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.825243) scaleY(1.05263)",
                }}
              >
                <span>
                  1 through 52
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 512,
                  width: 226,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.869231) scaleY(0.842105)",
                }}
              >
                <span>
                  Payments made in settlement of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 528,
                  width: 252,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.860068) scaleY(0.947368)",
                }}
              >
                <span>
                  payment card or third party network
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 546,
                  width: 86,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.877551) scaleY(0.684211)",
                }}
              >
                <span>
                  transactions
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 333,
                  top: 512,
                  width: 192,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.868778) scaleY(0.736842)",
                }}
              >
                <span>
                  Exempt payees 1 through 4<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 577,
                  width: 473,
                  height: 20,
                  fontSize: 12,
                  transform: "scaleX(1.16502) scaleY(1.35714)",
                }}
              >
                <span>
                  1 See Form 1099-MISC, Miscellaneous Income, and its
                  instructions.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 607,
                  width: 435,
                  height: 20,
                  fontSize: 12,
                  transform: "scaleX(1.15079) scaleY(1.35714)",
                }}
              >
                <span>
                  2 However, the following payments made to a corporation and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 79,
                  top: 626,
                  width: 422,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.875519) scaleY(0.894737)",
                }}
              >
                <span>
                  reportable on Form 1099-MISC are not exempt from backup
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 79,
                  top: 643,
                  width: 490,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871886) scaleY(0.947368)",
                }}
              >
                <span>
                  withholding: medical and health care payments, attorneys’
                  fees, gross
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 79,
                  top: 661,
                  width: 464,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.862454) scaleY(0.947368)",
                }}
              >
                <span>
                  proceeds paid to an attorney reportable under section 6045(f),
                  and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 79,
                  top: 679,
                  width: 404,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.868817) scaleY(1.05263)",
                }}
              >
                <span>
                  payments for services paid by a federal executive agency.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 699,
                  width: 495,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.920074) scaleY(1.10526)",
                }}
              >
                <span>
                  Exemption from FATCA reporting code. The following codes
                  identify
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 720,
                  width: 466,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.877589) scaleY(0.947368)",
                }}
              >
                <span>
                  payees that are exempt from reporting under FATCA. These codes
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 738,
                  width: 492,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.877005) scaleY(0.894737)",
                }}
              >
                <span>
                  apply to persons submitting this form for accounts maintained
                  outside
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 755,
                  width: 502,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867012) scaleY(0.947368)",
                }}
              >
                <span>
                  of the United States by certain foreign financial
                  institutions. Therefore, if
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 773,
                  width: 493,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.869489) scaleY(0.894737)",
                }}
              >
                <span>
                  you are only submitting this form for an account you hold in
                  the United
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 790,
                  width: 435,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878788) scaleY(0.947368)",
                }}
              >
                <span>
                  States, you may leave this field blank. Consult with the
                  person
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 808,
                  width: 467,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.861624) scaleY(0.947368)",
                }}
              >
                <span>
                  requesting this form if you are uncertain if the financial
                  institution is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 826,
                  width: 492,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.870796) scaleY(0.894737)",
                }}
              >
                <span>
                  subject to these requirements. A requester may indicate that a
                  code is
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 843,
                  width: 504,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.879581) scaleY(0.947368)",
                }}
              >
                <span>
                  not required by providing you with a Form W-9 with “Not
                  Applicable” (or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 861,
                  width: 443,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875494) scaleY(0.947368)",
                }}
              >
                <span>
                  any similar indication) written or printed on the line for a
                  FATCA
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 879,
                  width: 117,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.873134) scaleY(1.05263)",
                }}
              >
                <span>
                  exemption code.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 85,
                  top: 899,
                  width: 451,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.868979) scaleY(1.10526)",
                }}
              >
                <span>
                  A—An organization exempt from tax under section 501(a) or any
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 920,
                  width: 405,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.856237) scaleY(1.05263)",
                }}
              >
                <span>
                  individual retirement plan as defined in section 7701(a)(37)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 940,
                  width: 442,
                  height: 25,
                  fontSize: 16,
                  transform: "scaleX(0.875248) scaleY(1.26316)",
                }}
              >
                <span>
                  B—The United States or any of its agencies or
                  instrumentalities
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 964,
                  width: 433,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.885481) scaleY(1.05263)",
                }}
              >
                <span>
                  C—A state, the District of Columbia, a U.S. commonwealth or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 984,
                  width: 471,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.878731) scaleY(1.10526)",
                }}
              >
                <span>
                  possession, or any of their political subdivisions or
                  instrumentalities
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1005,
                  width: 455,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.875) scaleY(1.10526)",
                }}
              >
                <span>
                  D—A corporation the stock of which is regularly traded on one
                  or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1026,
                  width: 455,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.881783) scaleY(0.894737)",
                }}
              >
                <span>
                  more established securities markets, as described in
                  Regulations
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1043,
                  width: 163,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.848958) scaleY(1.10526)",
                }}
              >
                <span>
                  section 1.1472-1(c)(1)(i)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1064,
                  width: 466,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.867784) scaleY(1.05263)",
                }}
              >
                <span>
                  E—A corporation that is a member of the same expanded
                  affiliated
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1084,
                  width: 505,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.872193) scaleY(1.10526)",
                }}
              >
                <span>
                  group as a corporation described in Regulations section
                  1.1472-1(c)(1)(i)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1105,
                  width: 425,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.870902) scaleY(1.05263)",
                }}
              >
                <span>
                  F—A dealer in securities, commodities, or derivative financial
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 1125,
                  width: 479,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867754) scaleY(0.947368)",
                }}
              >
                <span>
                  instruments (including notional principal contracts, futures,
                  forwards,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1143,
                  width: 469,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.873371) scaleY(0.947368)",
                }}
              >
                <span>
                  and options) that is registered as such under the laws of the
                  United
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1161,
                  width: 132,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.874172) scaleY(1.05263)",
                }}
              >
                <span>
                  States or any state
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 1181,
                  width: 232,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.87218) scaleY(1.15789)",
                }}
              >
                <span>
                  G—A real estate investment trust
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1203,
                  width: 476,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.873395) scaleY(1.21053)",
                }}
              >
                <span>
                  H—A regulated investment company as defined in section 851 or
                  an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1226,
                  width: 481,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.857398) scaleY(0.894737)",
                }}
              >
                <span>
                  entity registered at all times during the tax year under the
                  Investment
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1243,
                  width: 153,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.884393) scaleY(1.10526)",
                }}
              >
                <span>
                  Company Act of 1940
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1264,
                  width: 363,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.870504) scaleY(1.21053)",
                }}
              >
                <span>
                  I—A common trust fund as defined in section 584(a)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 1287,
                  width: 250,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.892857) scaleY(1.15789)",
                }}
              >
                <span>
                  J—A bank as defined in section 581
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1309,
                  width: 85,
                  height: 25,
                  fontSize: 16,
                  transform: "scaleX(0.894737) scaleY(1.26316)",
                }}
              >
                <span>
                  K—A broker
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 1333,
                  width: 483,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.873418) scaleY(1.15789)",
                }}
              >
                <span>
                  L—A trust exempt from tax under section 664 or described in
                  section
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 1355,
                  width: 68,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.809524) scaleY(0.842105)",
                }}
              >
                <span>
                  4947(a)(1)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 109,
                  width: 474,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.869725) scaleY(0.842105)",
                }}
              >
                <span>
                  M—A tax exempt trust under a section 403(b) plan or section
                  457(g)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 125,
                  width: 29,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.852941) scaleY(1.10526)",
                }}
              >
                <span>
                  plan
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 146,
                  width: 489,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.884268) scaleY(1.05263)",
                }}
              >
                <span>
                  Note: You may wish to consult with the financial institution
                  requesting
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 166,
                  width: 490,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878136) scaleY(0.947368)",
                }}
              >
                <span>
                  this form to determine whether the FATCA code and/or exempt
                  payee
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 184,
                  width: 191,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.884259) scaleY(0.842105)",
                }}
              >
                <span>
                  code should be completed.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 215,
                  width: 60,
                  height: 17,
                  fontSize: 22,
                  transform: "scaleX(0.895522) scaleY(0.64)",
                }}
              >
                <span>
                  Line 5<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 244,
                  width: 476,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.854578) scaleY(0.842105)",
                }}
              >
                <span>
                  Enter your address (number, street, and apartment or suite
                  number).
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 260,
                  width: 490,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871886) scaleY(0.947368)",
                }}
              >
                <span>
                  This is where the requester of this Form W-9 will mail your
                  information
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 278,
                  width: 502,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.862543) scaleY(0.947368)",
                }}
              >
                <span>
                  returns. If this address differs from the one the requester
                  already has on
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 296,
                  width: 481,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.874545) scaleY(0.894737)",
                }}
              >
                <span>
                  file, write NEW at the top. If a new address is provided,
                  there is still a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 313,
                  width: 456,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.880309) scaleY(0.947368)",
                }}
              >
                <span>
                  chance the old address will be used until the payor changes
                  your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 331,
                  width: 169,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.866667) scaleY(0.684211)",
                }}
              >
                <span>
                  address in their records.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 362,
                  width: 60,
                  height: 17,
                  fontSize: 22,
                  transform: "scaleX(0.895522) scaleY(0.64)",
                }}
              >
                <span>
                  Line 6<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 391,
                  width: 250,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.87108) scaleY(0.789474)",
                }}
              >
                <span>
                  Enter your city, state, and ZIP code.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 420,
                  width: 483,
                  height: 23,
                  fontSize: 24,
                  transform: "scaleX(0.925287) scaleY(0.785714)",
                }}
              >
                <span>
                  Part I. Taxpayer Identification Number (TIN)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 448,
                  width: 491,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.894353) scaleY(0.842105)",
                }}
              >
                <span>
                  Enter your TIN in the appropriate box. If you are a resident
                  alien and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 464,
                  width: 502,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.88225) scaleY(0.947368)",
                }}
              >
                <span>
                  you do not have and are not eligible to get an SSN, your TIN
                  is your IRS
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 482,
                  width: 470,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.863971) scaleY(0.947368)",
                }}
              >
                <span>
                  individual taxpayer identification number (ITIN). Enter it in
                  the social
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 500,
                  width: 490,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.876565) scaleY(0.894737)",
                }}
              >
                <span>
                  security number box. If you do not have an ITIN, see How to
                  get a TIN
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 517,
                  width: 45,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.882353) scaleY(1)",
                }}
              >
                <span>
                  below.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 536,
                  width: 486,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.861702) scaleY(1.15789)",
                }}
              >
                <span>
                  If you are a sole proprietor and you have an EIN, you may
                  enter either
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 558,
                  width: 119,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.901515) scaleY(1.10526)",
                }}
              >
                <span>
                  your SSN or EIN.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 579,
                  width: 441,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.873267) scaleY(1.05263)",
                }}
              >
                <span>
                  If you are a single-member LLC that is disregarded as an
                  entity
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 599,
                  width: 505,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869191) scaleY(0.947368)",
                }}
              >
                <span>
                  separate from its owner, enter the owner’s SSN (or EIN, if the
                  owner has
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 617,
                  width: 508,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878893) scaleY(0.947368)",
                }}
              >
                <span>
                  one). Do not enter the disregarded entity’s EIN. If the LLC is
                  classified as
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 635,
                  width: 355,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.863747) scaleY(1.05263)",
                }}
              >
                <span>
                  a corporation or partnership, enter the entity’s EIN.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 655,
                  width: 477,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.880074) scaleY(1.10526)",
                }}
              >
                <span>
                  Note: See What Name and Number To Give the Requester, later,
                  for
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 676,
                  width: 354,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.863415) scaleY(1)",
                }}
              >
                <span>
                  further clarification of name and TIN combinations.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 695,
                  width: 495,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.88551) scaleY(1.15789)",
                }}
              >
                <span>
                  How to get a TIN. If you do not have a TIN, apply for one
                  immediately.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 717,
                  width: 487,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.900185) scaleY(0.947368)",
                }}
              >
                <span>
                  To apply for an SSN, get Form SS-5, Application for a Social
                  Security
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 735,
                  width: 400,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.87146) scaleY(0.894737)",
                }}
              >
                <span>
                  Card, from your local SSA office or get this form online at
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 752,
                  width: 490,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.894161) scaleY(0.947368)",
                }}
              >
                <span>
                  www.SSA.gov. You may also get this form by calling
                  1-800-772-1213.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 770,
                  width: 476,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.88806) scaleY(0.947368)",
                }}
              >
                <span>
                  Use Form W-7, Application for IRS Individual Taxpayer
                  Identification
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 788,
                  width: 483,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.878182) scaleY(0.894737)",
                }}
              >
                <span>
                  Number, to apply for an ITIN, or Form SS-4, Application for
                  Employer
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 805,
                  width: 472,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.877323) scaleY(0.947368)",
                }}
              >
                <span>
                  Identification Number, to apply for an EIN. You can apply for
                  an EIN
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 823,
                  width: 475,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.892857) scaleY(0.894737)",
                }}
              >
                <span>
                  online by accessing the IRS website at www.irs.gov/Businesses
                  and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 840,
                  width: 455,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869981) scaleY(0.947368)",
                }}
              >
                <span>
                  clicking on Employer Identification Number (EIN) under
                  Starting a
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 858,
                  width: 482,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.890943) scaleY(0.947368)",
                }}
              >
                <span>
                  Business. Go to www.irs.gov/Forms to view, download, or print
                  Form
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 876,
                  width: 493,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.90128) scaleY(0.894737)",
                }}
              >
                <span>
                  W-7 and/or Form SS-4. Or, you can go to www.irs.gov/OrderForms
                  to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 893,
                  width: 500,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.881834) scaleY(0.947368)",
                }}
              >
                <span>
                  place an order and have Form W-7 and/or SS-4 mailed to you
                  within 10
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 911,
                  width: 103,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.895652) scaleY(1.10526)",
                }}
              >
                <span>
                  business days.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 932,
                  width: 478,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.881919) scaleY(1.05263)",
                }}
              >
                <span>
                  If you are asked to complete Form W-9 but do not have a TIN,
                  apply
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 952,
                  width: 501,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878947) scaleY(0.947368)",
                }}
              >
                <span>
                  for a TIN and write “Applied For” in the space for the TIN,
                  sign and date
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 970,
                  width: 439,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869307) scaleY(0.947368)",
                }}
              >
                <span>
                  the form, and give it to the requester. For interest and
                  dividend
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 988,
                  width: 494,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866667) scaleY(0.894737)",
                }}
              >
                <span>
                  payments, and certain payments made with respect to readily
                  tradable
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1005,
                  width: 489,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870107) scaleY(0.947368)",
                }}
              >
                <span>
                  instruments, generally you will have 60 days to get a TIN and
                  give it to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 1023,
                  width: 437,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.874) scaleY(0.894737)",
                }}
              >
                <span>
                  the requester before you are subject to backup withholding on
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1040,
                  width: 492,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.87234) scaleY(0.947368)",
                }}
              >
                <span>
                  payments. The 60-day rule does not apply to other types of
                  payments.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 1058,
                  width: 479,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.890335) scaleY(0.947368)",
                }}
              >
                <span>
                  You will be subject to backup withholding on all such payments
                  until
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 1076,
                  width: 266,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.869281) scaleY(1.05263)",
                }}
              >
                <span>
                  you provide your TIN to the requester.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1096,
                  width: 499,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.876977) scaleY(1.10526)",
                }}
              >
                <span>
                  Note: Entering “Applied For” means that you have already
                  applied for a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 1117,
                  width: 310,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.873239) scaleY(1.05263)",
                }}
              >
                <span>
                  TIN or that you intend to apply for one soon.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1137,
                  width: 486,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.883636) scaleY(1.10526)",
                }}
              >
                <span>
                  Caution: A disregarded U.S. entity that has a foreign owner
                  must use
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 1158,
                  width: 187,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.882076) scaleY(0.842105)",
                }}
              >
                <span>
                  the appropriate Form W-8.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1189,
                  width: 216,
                  height: 18,
                  fontSize: 24,
                  transform: "scaleX(0.931035) scaleY(0.607143)",
                }}
              >
                <span>
                  Part II. Certification
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 620,
                  top: 1216,
                  width: 471,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.883677) scaleY(0.894737)",
                }}
              >
                <span>
                  To establish to the withholding agent that you are a U.S.
                  person, or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1233,
                  width: 473,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.885768) scaleY(0.894737)",
                }}
              >
                <span>
                  resident alien, sign Form W-9. You may be requested to sign by
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1250,
                  width: 466,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.871028) scaleY(1.10526)",
                }}
              >
                <span>
                  withholding agent even if item 1, 4, or 5 below indicates
                  otherwise.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 1271,
                  width: 450,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.884086) scaleY(1.05263)",
                }}
              >
                <span>
                  For a joint account, only the person whose TIN is shown in
                  Part I
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1291,
                  width: 471,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.867403) scaleY(0.947368)",
                }}
              >
                <span>
                  should sign (when required). In the case of a disregarded
                  entity, the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1309,
                  width: 499,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.860345) scaleY(0.947368)",
                }}
              >
                <span>
                  person identified on line 1 must sign. Exempt payees, see
                  Exempt payee
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1327,
                  width: 87,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.828571) scaleY(1.05263)",
                }}
              >
                <span>
                  code, earlier.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1347,
                  width: 471,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.892045) scaleY(1.10526)",
                }}
              >
                <span>
                  Signature requirements. Complete the certification as
                  indicated in
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1368,
                  width: 173,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.873737) scaleY(0.842105)",
                }}
              >
                <span>
                  items 1 through 5 below.
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pdf-page">
          <div className="pdf-page-inner pdf-page-4">
            <div className="pdf-page-text">
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 75,
                  width: 154,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.885057) scaleY(0.8125)",
                }}
              >
                <span>
                  Form W-9 (Rev. 10-2018)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 1084,
                  top: 72,
                  width: 46,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.978723) scaleY(1.0625)",
                }}
              >
                <span>
                  Page 5<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 109,
                  width: 443,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.924843) scaleY(0.842105)",
                }}
              >
                <span>
                  1. Interest, dividend, and barter exchange accounts opened
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 125,
                  width: 479,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.922929) scaleY(0.947368)",
                }}
              >
                <span>
                  before 1984 and broker accounts considered active during 1983.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 143,
                  width: 446,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.881423) scaleY(0.947368)",
                }}
              >
                <span>
                  You must give your correct TIN, but you do not have to sign
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 161,
                  width: 85,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.85) scaleY(0.684211)",
                }}
              >
                <span>
                  certification.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 186,
                  width: 442,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.928571) scaleY(0.736842)",
                }}
              >
                <span>
                  2. Interest, dividend, broker, and barter exchange accounts
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 203,
                  width: 496,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.928839) scaleY(0.842105)",
                }}
              >
                <span>
                  opened after 1983 and broker accounts considered inactive
                  during
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 219,
                  width: 504,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.885765) scaleY(0.947368)",
                }}
              >
                <span>
                  1983. You must sign the certification or backup withholding
                  will apply. If
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 237,
                  width: 476,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875) scaleY(0.947368)",
                }}
              >
                <span>
                  you are subject to backup withholding and you are merely
                  providing
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 255,
                  width: 464,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.868914) scaleY(0.894737)",
                }}
              >
                <span>
                  your correct TIN to the requester, you must cross out item 2
                  in the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 272,
                  width: 252,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.857143) scaleY(0.842105)",
                }}
              >
                <span>
                  certification before signing the form.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 297,
                  width: 492,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.909427) scaleY(0.842105)",
                }}
              >
                <span>
                  3. Real estate transactions. You must sign the certification.
                  You may
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 313,
                  width: 247,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.863636) scaleY(1)",
                }}
              >
                <span>
                  cross out item 2 of the certification.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 332,
                  width: 476,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.893058) scaleY(1.21053)",
                }}
              >
                <span>
                  4. Other payments. You must give your correct TIN, but you do
                  not
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 355,
                  width: 471,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.869004) scaleY(0.894737)",
                }}
              >
                <span>
                  have to sign the certification unless you have been notified
                  that you
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 372,
                  width: 457,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.872137) scaleY(0.947368)",
                }}
              >
                <span>
                  have previously given an incorrect TIN. “Other payments”
                  include
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 390,
                  width: 484,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.867384) scaleY(0.894737)",
                }}
              >
                <span>
                  payments made in the course of the requester’s trade or
                  business for
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 407,
                  width: 483,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.865591) scaleY(0.947368)",
                }}
              >
                <span>
                  rents, royalties, goods (other than bills for merchandise),
                  medical and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 425,
                  width: 495,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.868421) scaleY(0.947368)",
                }}
              >
                <span>
                  health care services (including payments to corporations),
                  payments to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 443,
                  width: 492,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.864675) scaleY(0.894737)",
                }}
              >
                <span>
                  a nonemployee for services, payments made in settlement of
                  payment
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 460,
                  width: 487,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.868093) scaleY(0.947368)",
                }}
              >
                <span>
                  card and third party network transactions, payments to certain
                  fishing
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 478,
                  width: 446,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.87451) scaleY(0.947368)",
                }}
              >
                <span>
                  boat crew members and fishermen, and gross proceeds paid to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 496,
                  width: 329,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.86579) scaleY(1.10526)",
                }}
              >
                <span>
                  attorneys (including payments to corporations).
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 86,
                  top: 517,
                  width: 480,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.932039) scaleY(1.15789)",
                }}
              >
                <span>
                  5. Mortgage interest paid by you, acquisition or abandonment
                  of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 539,
                  width: 477,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.928016) scaleY(0.894737)",
                }}
              >
                <span>
                  secured property, cancellation of debt, qualified tuition
                  program
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 556,
                  width: 504,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.924771) scaleY(0.947368)",
                }}
              >
                <span>
                  payments (under section 529), ABLE accounts (under section
                  529A),
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 574,
                  width: 421,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.952489) scaleY(0.894737)",
                }}
              >
                <span>
                  IRA, Coverdell ESA, Archer MSA or HSA contributions or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 591,
                  width: 489,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.920904) scaleY(0.947368)",
                }}
              >
                <span>
                  distributions, and pension distributions. You must give your
                  correct
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 609,
                  width: 342,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.87468) scaleY(0.842105)",
                }}
              >
                <span>
                  TIN, but you do not have to sign the certification.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 70,
                  top: 640,
                  width: 506,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.881533) scaleY(0.75)",
                }}
              >
                <span>
                  What Name and Number To Give the Requester
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 107,
                  top: 672,
                  width: 181,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.937824) scaleY(0.736842)",
                }}
              >
                <span>
                  For this type of account:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 366,
                  top: 671,
                  width: 171,
                  height: 13,
                  fontSize: 16,
                  transform: "scaleX(0.934426) scaleY(0.631579)",
                }}
              >
                <span>
                  Give name and SSN of:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 81,
                  top: 694,
                  width: 73,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.848837) scaleY(0.625)",
                }}
              >
                <span>
                  1. Individual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 694,
                  width: 85,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.85) scaleY(0.625)",
                }}
              >
                <span>
                  The individual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 95,
                  top: 717,
                  width: 180,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.865385) scaleY(0.9375)",
                }}
              >
                <span>
                  Two or more individuals (joint
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 98,
                  top: 732,
                  width: 192,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.860987) scaleY(1.0625)",
                }}
              >
                <span>
                  account) other than an account
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 98,
                  top: 749,
                  width: 128,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.864865) scaleY(1)",
                }}
              >
                <span>
                  maintained by an FFI
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 717,
                  width: 230,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.867925) scaleY(0.875)",
                }}
              >
                <span>
                  The actual owner of the account or, if
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 731,
                  width: 234,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.863469) scaleY(1.125)",
                }}
              >
                <span>
                  combined funds, the first individual on
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 749,
                  width: 77,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.827957) scaleY(1.0625)",
                }}
              >
                <span>
                  the account1
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 80,
                  top: 778,
                  width: 177,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.903061) scaleY(1)",
                }}
              >
                <span>
                  3. Two or more U.S. persons
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 794,
                  width: 218,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.858268) scaleY(0.9375)",
                }}
              >
                <span>
                  (joint account maintained by an FFI)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 337,
                  top: 780,
                  width: 165,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.863874) scaleY(0.625)",
                }}
              >
                <span>
                  Each holder of the account
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 80,
                  top: 826,
                  width: 193,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.873303) scaleY(0.875)",
                }}
              >
                <span>
                  4. Custodial account of a minor
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 840,
                  width: 168,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.875) scaleY(1.0625)",
                }}
              >
                <span>
                  (Uniform Gift to Minors Act)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 821,
                  width: 66,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.835443) scaleY(0.9375)",
                }}
              >
                <span>
                  The minor²
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 80,
                  top: 866,
                  width: 236,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.870849) scaleY(0.9375)",
                }}
              >
                <span>
                  5. a. The usual revocable savings trust
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 881,
                  width: 140,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.848485) scaleY(1)",
                }}
              >
                <span>
                  (grantor is also trustee)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 862,
                  width: 123,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.836735) scaleY(1.125)",
                }}
              >
                <span>
                  The grantor-trustee1
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 97,
                  top: 901,
                  width: 223,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.877953) scaleY(0.875)",
                }}
              >
                <span>
                  b. So-called trust account that is not
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 915,
                  width: 218,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.858268) scaleY(1.0625)",
                }}
              >
                <span>
                  a legal or valid trust under state law
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 898,
                  width: 110,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.833333) scaleY(0.9375)",
                }}
              >
                <span>
                  The actual owner1
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 80,
                  top: 946,
                  width: 226,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.862595) scaleY(1)",
                }}
              >
                <span>
                  6. Sole proprietorship or disregarded
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 962,
                  width: 178,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.859903) scaleY(0.9375)",
                }}
              >
                <span>
                  entity owned by an individual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 947,
                  width: 69,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.851852) scaleY(1)",
                }}
              >
                <span>
                  The owner³
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 81,
                  top: 990,
                  width: 220,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.856031) scaleY(1)",
                }}
              >
                <span>
                  7. Grantor trust filing under Optional
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 97,
                  top: 1006,
                  width: 194,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.873874) scaleY(1.0625)",
                }}
              >
                <span>
                  Form 1099 Filing Method 1 (see
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 97,
                  top: 1023,
                  width: 211,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.850807) scaleY(1.125)",
                }}
              >
                <span>
                  Regulations section 1.671-4(b)(2)(i)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 1041,
                  width: 20,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.8) scaleY(0.9375)",
                }}
              >
                <span>
                  (A))
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 991,
                  width: 76,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.835165) scaleY(0.75)",
                }}
              >
                <span>
                  The grantor*
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 107,
                  top: 1066,
                  width: 181,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.937824) scaleY(0.736842)",
                }}
              >
                <span>
                  For this type of account:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 369,
                  top: 1066,
                  width: 165,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.926966) scaleY(0.578947)",
                }}
              >
                <span>
                  Give name and EIN of:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 80,
                  top: 1087,
                  width: 234,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.866667) scaleY(1)",
                }}
              >
                <span>
                  8. Disregarded entity not owned by an
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 97,
                  top: 1103,
                  width: 57,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.826087) scaleY(0.75)",
                }}
              >
                <span>
                  individual
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 1087,
                  width: 66,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.88) scaleY(0.625)",
                }}
              >
                <span>
                  The owner
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 80,
                  top: 1128,
                  width: 238,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.875) scaleY(0.75)",
                }}
              >
                <span>
                  9. A valid trust, estate, or pension trust
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 337,
                  top: 1124,
                  width: 76,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.826087) scaleY(1.0625)",
                }}
              >
                <span>
                  Legal entity4
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 1156,
                  width: 192,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.868778) scaleY(0.9375)",
                }}
              >
                <span>
                  10. Corporation or LLC electing
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 1171,
                  width: 207,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.869748) scaleY(1.125)",
                }}
              >
                <span>
                  corporate status on Form 8832 or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 97,
                  top: 1189,
                  width: 65,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.844156) scaleY(0.75)",
                }}
              >
                <span>
                  Form 2553
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 1156,
                  width: 97,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.850877) scaleY(0.75)",
                }}
              >
                <span>
                  The corporation
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 1219,
                  width: 190,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.87156) scaleY(0.9375)",
                }}
              >
                <span>
                  11. Association, club, religious,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 1234,
                  width: 223,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.857692) scaleY(1.0625)",
                }}
              >
                <span>
                  charitable, educational, or other tax-
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 96,
                  top: 1251,
                  width: 125,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.85034) scaleY(1)",
                }}
              >
                <span>
                  exempt organization
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 1219,
                  width: 102,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.85) scaleY(0.75)",
                }}
              >
                <span>
                  The organization
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 1285,
                  width: 228,
                  height: 17,
                  fontSize: 14,
                  transform: "scaleX(0.873563) scaleY(1)",
                }}
              >
                <span>
                  12. Partnership or multi-member LLC
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 1301,
                  width: 213,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.852) scaleY(1.0625)",
                }}
              >
                <span>
                  13. A broker or registered nominee
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 1284,
                  width: 97,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.858407) scaleY(1.0625)",
                }}
              >
                <span>
                  The partnership
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 336,
                  top: 1301,
                  width: 140,
                  height: 15,
                  fontSize: 14,
                  transform: "scaleX(0.853659) scaleY(0.875)",
                }}
              >
                <span>
                  The broker or nominee
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 657,
                  top: 116,
                  width: 181,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.937824) scaleY(0.736842)",
                }}
              >
                <span>
                  For this type of account:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 919,
                  top: 116,
                  width: 165,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.926966) scaleY(0.578947)",
                }}
              >
                <span>
                  Give name and EIN of:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 623,
                  top: 138,
                  width: 219,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.869048) scaleY(0.9375)",
                }}
              >
                <span>
                  14. Account with the Department of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 646,
                  top: 153,
                  width: 210,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.853659) scaleY(1.0625)",
                }}
              >
                <span>
                  Agriculture in the name of a public
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 646,
                  top: 170,
                  width: 182,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.862559) scaleY(1.125)",
                }}
              >
                <span>
                  entity (such as a state or local
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 646,
                  top: 188,
                  width: 188,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.87037) scaleY(1.125)",
                }}
              >
                <span>
                  government, school district, or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 647,
                  top: 206,
                  width: 194,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.839827) scaleY(1.125)",
                }}
              >
                <span>
                  prison) that receives agricultural
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 647,
                  top: 224,
                  width: 115,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.845588) scaleY(0.9375)",
                }}
              >
                <span>
                  program payments
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 886,
                  top: 138,
                  width: 102,
                  height: 13,
                  fontSize: 14,
                  transform: "scaleX(0.864407) scaleY(0.75)",
                }}
              >
                <span>
                  The public entity
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 623,
                  top: 255,
                  width: 221,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.818519) scaleY(0.9375)",
                }}
              >
                <span>
                  15. Grantor trust filing under the Form
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 647,
                  top: 270,
                  width: 201,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.82377) scaleY(1.125)",
                }}
              >
                <span>
                  1041 Filing Method or the Optional
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 647,
                  top: 288,
                  width: 184,
                  height: 19,
                  fontSize: 14,
                  transform: "scaleX(0.828829) scaleY(1.125)",
                }}
              >
                <span>
                  Form 1099 Filing Method 2 (see
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 647,
                  top: 306,
                  width: 220,
                  height: 16,
                  fontSize: 14,
                  transform: "scaleX(0.805861) scaleY(0.9375)",
                }}
              >
                <span>
                  Regulations section 1.671-4(b)(2)(i)(B))
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 886,
                  top: 255,
                  width: 55,
                  height: 11,
                  fontSize: 14,
                  transform: "scaleX(0.873016) scaleY(0.625)",
                }}
              >
                <span>
                  The trust
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 337,
                  width: 499,
                  height: 18,
                  fontSize: 10,
                  transform: "scaleX(1.40563) scaleY(1.54545)",
                }}
              >
                <span>
                  1 List first and circle the name of the person whose number
                  you furnish.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 354,
                  width: 505,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.885965) scaleY(0.894737)",
                }}
              >
                <span>
                  If only one person on a joint account has an SSN, that
                  person’s number
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 371,
                  width: 129,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.86) scaleY(1)",
                }}
              >
                <span>
                  must be furnished.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 390,
                  width: 381,
                  height: 20,
                  fontSize: 10,
                  transform: "scaleX(1.41111) scaleY(1.72727)",
                }}
              >
                <span>
                  2 Circle the minor’s name and furnish the minor’s SSN.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 424,
                  width: 472,
                  height: 18,
                  fontSize: 10,
                  transform: "scaleX(1.41742) scaleY(1.54545)",
                }}
              >
                <span>
                  3 You must show your individual name and you may also enter
                  your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 441,
                  width: 464,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.878788) scaleY(0.894737)",
                }}
              >
                <span>
                  business or DBA name on the “Business name/disregarded entity”
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 458,
                  width: 506,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.870912) scaleY(0.894737)",
                }}
              >
                <span>
                  name line. You may use either your SSN or EIN (if you have
                  one), but the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 475,
                  width: 263,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.888514) scaleY(0.842105)",
                }}
              >
                <span>
                  IRS encourages you to use your SSN.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 506,
                  width: 492,
                  height: 20,
                  fontSize: 10,
                  transform: "scaleX(1.39377) scaleY(1.72727)",
                }}
              >
                <span>
                  4 List first and circle the name of the trust, estate, or
                  pension trust. (Do
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 525,
                  width: 489,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.862434) scaleY(1)",
                }}
              >
                <span>
                  not furnish the TIN of the personal representative or trustee
                  unless the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 544,
                  width: 499,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.875439) scaleY(1.05263)",
                }}
              >
                <span>
                  legal entity itself is not designated in the account title.)
                  Also see Special
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 564,
                  width: 203,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.852941) scaleY(1.15789)",
                }}
              >
                <span>
                  rules for partnerships, earlier.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 586,
                  width: 478,
                  height: 25,
                  fontSize: 16,
                  transform: "scaleX(0.875458) scaleY(1.26316)",
                }}
              >
                <span>
                  *Note: The grantor also must provide a Form W-9 to trustee of
                  trust.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 610,
                  width: 464,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.870544) scaleY(1.10526)",
                }}
              >
                <span>
                  Note: If no name is circled when more than one name is listed,
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 631,
                  width: 420,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.865979) scaleY(0.684211)",
                }}
              >
                <span>
                  number will be considered to be that of the first name listed.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 662,
                  width: 497,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.93597) scaleY(0.75)",
                }}
              >
                <span>
                  Secure Your Tax Records From Identity Theft
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 693,
                  width: 470,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.867159) scaleY(0.842105)",
                }}
              >
                <span>
                  Identity theft occurs when someone uses your personal
                  information
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 709,
                  width: 493,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.872566) scaleY(0.947368)",
                }}
              >
                <span>
                  such as your name, SSN, or other identifying information,
                  without your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 727,
                  width: 488,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.863717) scaleY(0.947368)",
                }}
              >
                <span>
                  permission, to commit fraud or other crimes. An identity thief
                  may use
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 745,
                  width: 499,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.875439) scaleY(0.894737)",
                }}
              >
                <span>
                  your SSN to get a job or may file a tax return using your SSN
                  to receive
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 762,
                  width: 61,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.835616) scaleY(1)",
                }}
              >
                <span>
                  a refund.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 636,
                  top: 781,
                  width: 139,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.88535) scaleY(1.10526)",
                }}
              >
                <span>
                  To reduce your risk:
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 811,
                  width: 137,
                  height: 15,
                  fontSize: 16,
                  transform: "scaleX(0.883871) scaleY(0.736842)",
                }}
              >
                <span>
                  • Protect your SSN,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 834,
                  width: 362,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.876513) scaleY(0.789474)",
                }}
              >
                <span>
                  • Ensure your employer is protecting your SSN, and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 860,
                  width: 299,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.866667) scaleY(1)",
                }}
              >
                <span>
                  • Be careful when choosing a tax preparer.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 638,
                  top: 879,
                  width: 456,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.860377) scaleY(1.05263)",
                }}
              >
                <span>
                  If your tax records are affected by identity theft and you
                  receive a<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 899,
                  width: 504,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.868966) scaleY(0.947368)",
                }}
              >
                <span>
                  notice from the IRS, respond right away to the name and phone
                  number
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 917,
                  width: 236,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.867647) scaleY(1.10526)",
                }}
              >
                <span>
                  printed on the IRS notice or letter.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 638,
                  top: 938,
                  width: 476,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.857658) scaleY(1.05263)",
                }}
              >
                <span>
                  If your tax records are not currently affected by identity
                  theft but you
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 958,
                  width: 501,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.872822) scaleY(0.947368)",
                }}
              >
                <span>
                  think you are at risk due to a lost or stolen purse or wallet,
                  questionable
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 976,
                  width: 506,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869416) scaleY(0.947368)",
                }}
              >
                <span>
                  credit card activity or credit report, contact the IRS
                  Identity Theft Hotline
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 994,
                  width: 295,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.877976) scaleY(1)",
                }}
              >
                <span>
                  at 1-800-908-4490 or submit Form 14039.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 637,
                  top: 1013,
                  width: 465,
                  height: 23,
                  fontSize: 16,
                  transform: "scaleX(0.864312) scaleY(1.15789)",
                }}
              >
                <span>
                  For more information, see Pub. 5027, Identity Theft
                  Information for
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1035,
                  width: 76,
                  height: 21,
                  fontSize: 16,
                  transform: "scaleX(0.894118) scaleY(1.05263)",
                }}
              >
                <span>
                  Taxpayers.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 636,
                  top: 1055,
                  width: 461,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.866541) scaleY(1.10526)",
                }}
              >
                <span>
                  Victims of identity theft who are experiencing economic harm
                  or a
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1076,
                  width: 478,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870674) scaleY(0.947368)",
                }}
              >
                <span>
                  systemic problem, or are seeking help in resolving tax
                  problems that
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1094,
                  width: 479,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866185) scaleY(0.894737)",
                }}
              >
                <span>
                  have not been resolved through normal channels, may be
                  eligible for
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1111,
                  width: 478,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.896811) scaleY(0.947368)",
                }}
              >
                <span>
                  Taxpayer Advocate Service (TAS) assistance. You can reach TAS
                  by
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 1129,
                  width: 499,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.886323) scaleY(0.842105)",
                }}
              >
                <span>
                  calling the TAS toll-free case intake line at 1-877-777-4778
                  or TTY/TDD
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1148,
                  width: 117,
                  height: 12,
                  fontSize: 16,
                  transform: "scaleX(0.879699) scaleY(0.578947)",
                }}
              >
                <span>
                  1-800-829-4059.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1172,
                  width: 459,
                  height: 16,
                  fontSize: 16,
                  transform: "scaleX(0.940574) scaleY(0.789474)",
                }}
              >
                <span>
                  Protect yourself from suspicious emails or phishing schemes.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1187,
                  width: 466,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.879245) scaleY(0.947368)",
                }}
              >
                <span>
                  Phishing is the creation and use of email and websites
                  designed to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1205,
                  width: 494,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.875887) scaleY(0.947368)",
                }}
              >
                <span>
                  mimic legitimate business emails and websites. The most common
                  act
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1223,
                  width: 456,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871893) scaleY(0.947368)",
                }}
              >
                <span>
                  is sending an email to a user falsely claiming to be an
                  established
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1241,
                  width: 483,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.857904) scaleY(0.894737)",
                }}
              >
                <span>
                  legitimate enterprise in an attempt to scam the user into
                  surrendering
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 1258,
                  width: 367,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.855478) scaleY(0.842105)",
                }}
              >
                <span>
                  private information that will be used for identity theft.
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pdf-page">
          <div className="pdf-page-inner pdf-page-5">
            <div className="pdf-page-text">
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 75,
                  width: 154,
                  height: 14,
                  fontSize: 14,
                  transform: "scaleX(0.885057) scaleY(0.8125)",
                }}
              >
                <span>
                  Form W-9 (Rev. 10-2018)
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 1084,
                  top: 72,
                  width: 46,
                  height: 18,
                  fontSize: 14,
                  transform: "scaleX(0.978723) scaleY(1.0625)",
                }}
              >
                <span>
                  Page 6<br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 109,
                  width: 488,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.882459) scaleY(0.842105)",
                }}
              >
                <span>
                  The IRS does not initiate contacts with taxpayers via emails.
                  Also, the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 125,
                  width: 504,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871972) scaleY(0.947368)",
                }}
              >
                <span>
                  IRS does not request personal detailed information through
                  email or ask
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 143,
                  width: 474,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.879406) scaleY(0.894737)",
                }}
              >
                <span>
                  taxpayers for the PIN numbers, passwords, or similar secret
                  access
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 160,
                  width: 458,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.859287) scaleY(1.10526)",
                }}
              >
                <span>
                  information for their credit card, bank, or other financial
                  accounts.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 89,
                  top: 181,
                  width: 434,
                  height: 22,
                  fontSize: 16,
                  transform: "scaleX(0.864542) scaleY(1.10526)",
                }}
              >
                <span>
                  If you receive an unsolicited email claiming to be from the
                  IRS,
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 202,
                  width: 496,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.879433) scaleY(0.947368)",
                }}
              >
                <span>
                  forward this message to phishing@irs.gov. You may also report
                  misuse
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 220,
                  width: 489,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.874776) scaleY(0.894737)",
                }}
              >
                <span>
                  of the IRS name, logo, or other IRS property to the Treasury
                  Inspector
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 237,
                  width: 472,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.887218) scaleY(0.947368)",
                }}
              >
                <span>
                  General for Tax Administration (TIGTA) at 1-800-366-4484. You
                  can
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 255,
                  width: 438,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.884849) scaleY(0.894737)",
                }}
              >
                <span>
                  forward suspicious emails to the Federal Trade Commission at
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 71,
                  top: 272,
                  width: 463,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.878558) scaleY(0.947368)",
                }}
              >
                <span>
                  spam@uce.gov or report them at www.ftc.gov/complaint. You can
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 290,
                  width: 507,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.887916) scaleY(0.947368)",
                }}
              >
                <span>
                  contact the FTC at www.ftc.gov/idtheft or 877-IDTHEFT
                  (877-438-4338).
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 308,
                  width: 492,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.866197) scaleY(0.894737)",
                }}
              >
                <span>
                  If you have been the victim of identity theft, see
                  www.IdentityTheft.gov
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 72,
                  top: 325,
                  width: 106,
                  height: 20,
                  fontSize: 16,
                  transform: "scaleX(0.883333) scaleY(1)",
                }}
              >
                <span>
                  and Pub. 5027.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 87,
                  top: 344,
                  width: 477,
                  height: 24,
                  fontSize: 16,
                  transform: "scaleX(0.868852) scaleY(1.21053)",
                }}
              >
                <span>
                  Visit www.irs.gov/IdentityTheft to learn more about identity
                  theft and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 73,
                  top: 367,
                  width: 168,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.865979) scaleY(0.842105)",
                }}
              >
                <span>
                  how to reduce your risk.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 109,
                  width: 206,
                  height: 22,
                  fontSize: 24,
                  transform: "scaleX(0.944954) scaleY(0.75)",
                }}
              >
                <span>
                  Privacy Act Notice
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 142,
                  width: 503,
                  height: 17,
                  fontSize: 16,
                  transform: "scaleX(0.874783) scaleY(0.842105)",
                }}
              >
                <span>
                  Section 6109 of the Internal Revenue Code requires you to
                  provide your
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 158,
                  width: 494,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.86819) scaleY(0.947368)",
                }}
              >
                <span>
                  correct TIN to persons (including federal agencies) who are
                  required to
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 176,
                  width: 467,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.866419) scaleY(0.947368)",
                }}
              >
                <span>
                  file information returns with the IRS to report interest,
                  dividends, or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 194,
                  width: 456,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.86692) scaleY(0.894737)",
                }}
              >
                <span>
                  certain other income paid to you; mortgage interest you paid;
                  the
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 211,
                  width: 476,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871795) scaleY(0.947368)",
                }}
              >
                <span>
                  acquisition or abandonment of secured property; the
                  cancellation of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 229,
                  width: 482,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.887661) scaleY(0.947368)",
                }}
              >
                <span>
                  debt; or contributions you made to an IRA, Archer MSA, or HSA.
                  The
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 247,
                  width: 461,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.864916) scaleY(0.894737)",
                }}
              >
                <span>
                  person collecting this form uses the information on the form
                  to file
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 264,
                  width: 454,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.859849) scaleY(0.947368)",
                }}
              >
                <span>
                  information returns with the IRS, reporting the above
                  information.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 282,
                  width: 485,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.870736) scaleY(0.947368)",
                }}
              >
                <span>
                  Routine uses of this information include giving it to the
                  Department of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 300,
                  width: 502,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.880702) scaleY(0.894737)",
                }}
              >
                <span>
                  Justice for civil and criminal litigation and to cities,
                  states, the District of
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 317,
                  width: 448,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.890656) scaleY(0.947368)",
                }}
              >
                <span>
                  Columbia, and U.S. commonwealths and possessions for use in
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 335,
                  width: 504,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.871972) scaleY(0.947368)",
                }}
              >
                <span>
                  administering their laws. The information also may be
                  disclosed to other
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 353,
                  width: 485,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.864528) scaleY(0.894737)",
                }}
              >
                <span>
                  countries under a treaty, to federal and state agencies to
                  enforce civil
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 370,
                  width: 450,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.863724) scaleY(0.947368)",
                }}
              >
                <span>
                  and criminal laws, or to federal law enforcement and
                  intelligence
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 388,
                  width: 483,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.875) scaleY(0.894737)",
                }}
              >
                <span>
                  agencies to combat terrorism. You must provide your TIN
                  whether or
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 405,
                  width: 470,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.862385) scaleY(0.947368)",
                }}
              >
                <span>
                  not you are required to file a tax return. Under section 3406,
                  payers
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 423,
                  width: 495,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.8669) scaleY(0.947368)",
                }}
              >
                <span>
                  must generally withhold a percentage of taxable interest,
                  dividend, and
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 441,
                  width: 506,
                  height: 18,
                  fontSize: 16,
                  transform: "scaleX(0.869416) scaleY(0.894737)",
                }}
              >
                <span>
                  certain other payments to a payee who does not give a TIN to
                  the payer.
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 621,
                  top: 458,
                  width: 452,
                  height: 19,
                  fontSize: 16,
                  transform: "scaleX(0.869231) scaleY(0.947368)",
                }}
              >
                <span>
                  Certain penalties may also apply for providing false or
                  fraudulent
                  <br />
                </span>
              </div>
              <div
                className="pdf-obj-fixed"
                style={{
                  left: 622,
                  top: 476,
                  width: 82,
                  height: 14,
                  fontSize: 16,
                  transform: "scaleX(0.836735) scaleY(0.684211)",
                }}
              >
                <span>
                  information.
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-end">
          <button className="sendMsg">
            <SmallLoader color={"text-light"} />
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-end" 
        // onClick={handleFormSubmit}
        onClick={() => onSend()}
        >
          <button className="sendMsg">{t("Send")}</button>
        </div>
      )}
    </form>
  );
}
