import React, { useEffect, useState } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { generatePath, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Calendermod from "../../common/Modal/Calendermod";
import moment from "moment";
import { deletePost, getPostList, publishPost } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import QueuePostSection from "./QueuePostSection";
import ConfirmDeletePost from "../../common/Modal/EntertainerModal/ConfirmDeletePost";
import { toast } from "react-toastify";

const QueueComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date, updateDate] = useState(new Date());
  const [calendermod, setcalendermod] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsByDate, setEventsByDate] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { buttonLoader } = useSelector((s) => s.post);

  const getPostLists = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["quiz", "normal", "other", "poll"],
      scheduledType: "scheduled",
      isPosted: false,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res.data;
        const dta = data?.map((itm) => {
          return { ...itm, date: itm?.scheduleDate };
        });
        setEvents(dta);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    getPostLists();
  }, []);

  const tileContent = ({ date }) => {
    const eventsForDate = events.filter((event) => {
      const desiredDate = new Date(event?.date);
      return (
        date.getDate() === desiredDate.getDate() &&
        date.getMonth() === desiredDate.getMonth() &&
        date.getFullYear() === desiredDate.getFullYear()
      );
    });
    if (eventsForDate.length > 0) {
      return (
        <div>
          <div className="bg-primary rounded text-light py-1">
            {eventsForDate?.length}
          </div>
        </div>
      );
    }
    return null;
  };

  const handleDateChange = (val) => {
    updateDate(val);
    const date1 = moment(val, "YYYY-MM-DD");
    const allEvents = events?.filter((itm) => {
      const date2 = moment(new Date(itm?.scheduleDate), "YYYY-MM-DD");
      return moment(date1).isSame(date2);
    });
    setEventsByDate(allEvents);
  };

  const handleDelete = (dta) => {
    setSelectedPost(dta);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    const data = {
      postId: selectedPost?._id,
    };
    dispatch(deletePost(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setSelectedPost({});
        setShowDeleteModal(false);
        getPostLists();
        handleDateChange(new Date());
        setEventsByDate([]);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handlePostEdit = (dta) => {
    const path = generatePath("/post/edit/:id", { id: dta?._id });
    navigate(path);
  };

  const handlePublishPost = (dta) => {
    const data = {
      postId: dta?._id,
    };
    dispatch(publishPost(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
        handleDateChange(date);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const [showCal, setShowCal] = useState(true);
  const handleShowCal = () => {
    setShowCal(!showCal);
  };

  return (
    <Row className="  h-100 align-items-start justify-content-between ">
      <Col lg="7" md="12" sm="12" className="padding_tabet">
        <div className="shadow1 rounded border h-100  border_hide">
          {/* topheaderbar */}
          <div className="notification_topheader d-flex align-items-center justify-content-between px-4 py-4 border-bottom">
            <Link to="" onClick={() => navigate(-1)}>
              <div className="notification_left_heading d-flex align-items-center">
                <span className="me-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                  >
                    <path
                      d="M1 7L7 1M1 7L7 13M1 7L15 7"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <h5>{t("Queue")}</h5>
              </div>
            </Link>

            <div className="collection_header d-flex align-items-center gap-3">
              <Button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_711_1697)">
                    <path
                      d="M15.3536 14.6465L11.1614 10.4544C12.0557 9.39963 12.5969 8.0365 12.5969 6.54862C12.5968 3.21338 9.8835 0.5 6.54838 0.5C3.21325 0.5 0.5 3.21338 0.5 6.54862C0.5 9.88375 3.21325 12.597 6.54838 12.597C8.03638 12.597 9.39963 12.0559 10.4543 11.1615L14.6465 15.3536C14.7441 15.4513 14.8721 15.5001 15.0001 15.5001C15.1281 15.5001 15.256 15.4513 15.3538 15.3536C15.4475 15.2598 15.5001 15.1326 15.5001 15C15.5001 14.8674 15.4474 14.7403 15.3536 14.6465ZM1.5 6.54862C1.5 3.76475 3.76463 1.5 6.54838 1.5C9.33212 1.5 11.5969 3.76475 11.5969 6.54862C11.5969 9.33237 9.33212 11.597 6.54838 11.597C3.76463 11.5969 1.5 9.33225 1.5 6.54862Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_711_1697">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
              <Button onClick={() => setcalendermod(true)}>+</Button>
            </div>
          </div>
          {/* topheaderbar */}
          <div className="CardBody pb-3 pt-4 px-4 commonTabs colleciton_tabs">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip">{showCal ? "Hide" : "Show"}</Tooltip>
              }
            >
              <Button
                className="border-0 p-0"
                variant="transparent"
                onClick={handleShowCal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M2 19C2 20.7 3.3 22 5 22H19C20.7 22 22 20.7 22 19V11H2V19ZM19 4H17V3C17 2.4 16.6 2 16 2C15.4 2 15 2.4 15 3V4H9V3C9 2.4 8.6 2 8 2C7.4 2 7 2.4 7 3V4H5C3.3 4 2 5.3 2 7V9H22V7C22 5.3 20.7 4 19 4Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
            </OverlayTrigger>

            <div className="calender_table mt-3">
              {showCal ? (
                <Calendar
                  onChange={handleDateChange}
                  value={date}
                  minDate={new Date()}
                  tileContent={tileContent}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={5} md={12} sm={12} className="sticky-top rightSidebar">
        <div className="shadow1 rounded border full-height px-5 pt-4 pb-4 CardBox">
          <h5>{moment(date).format("DD MMMM YYYY")}</h5>
          <div className="calender_right">
            <Button
              className="achrive_btn mt-5"
              onClick={() => setcalendermod(true)}
            >
              +
            </Button>
            {eventsByDate?.length === 0 && (
              <div className="no-schedule_event text-center py-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                >
                  <g opacity="0.5" clip-path="url(#clip0_719_139)">
                    <path
                      d="M7.85773 52H44.1422C47.9459 52 51.037 48.9089 51.037 45.1052V10.7466C51.037 6.94292 47.9459 3.85178 44.1422 3.85178H41.4074V1.92589C41.4074 0.866618 40.5406 0 39.4813 0C38.4221 0 37.5554 0.866618 37.5554 1.92589V3.85178H14.4444V1.92589C14.4444 0.866618 13.5777 0 12.5184 0C11.4591 0 10.5925 0.866618 10.5925 1.92589V3.85178H7.85773C4.05403 3.85178 0.962891 6.94292 0.962891 10.7466V45.1052C0.962891 48.9089 4.05403 52 7.85773 52ZM4.81483 10.7466C4.81483 9.07111 6.18222 7.70372 7.85773 7.70372H10.5925V9.62961C10.5925 10.6889 11.4591 11.5555 12.5184 11.5555C13.5777 11.5555 14.4443 10.6889 14.4443 9.62961V7.70372H37.5554V9.62961C37.5554 10.6889 38.4221 11.5555 39.4813 11.5555C40.5406 11.5555 41.4072 10.6889 41.4072 9.62961V7.70372H44.142C45.8175 7.70372 47.1849 9.07111 47.1849 10.7466V16.3704H4.81483V10.7466ZM4.81483 20.2222H47.1852V45.1052C47.1852 46.7807 45.8178 48.1481 44.1423 48.1481H7.85773C6.18222 48.1481 4.81483 46.7807 4.81483 45.1052V20.2222Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_719_139">
                      <rect width="52" height="52" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>{t("There are no scheduled events for this day")}</p>
              </div>
            )}
            <div className="NewsFeed">
              {eventsByDate?.map((event, index) => {
                return (
                  <QueuePostSection
                    data={event}
                    key={index}
                    handleDelete={handleDelete}
                    handleEdit={handlePostEdit}
                    handlePublishPost={handlePublishPost}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Col>
      <Calendermod
        show={calendermod}
        onhide={() => setcalendermod(false)}
        selectedDate={date}
        updateDate={updateDate}
      />
      <ConfirmDeletePost
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
        loading={buttonLoader}
      />
    </Row>
  );
};

export default QueueComponent;
