import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";
import makeSignature from "./createSignature";
import oauthSignature from "oauth-signature";

export const login = createAsyncThunk("login", async (payload, Thunk) => {
  try {
    const response = await axios.post(endpoints.auth.login, payload);
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});

export const socialLogin = createAsyncThunk(
  "auth/login",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.auth.socialLogin, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getGoogleProfile = createAsyncThunk(
  "auth/getGoogleProfile",
  async (payload, Thunk) => {
    try {
      const Axios = axios.create();
      const response = await Axios.post(
        endpoints.auth.getGoogleProfile,
        payload,
        {
          headers: {
            "x-cors-grida-api-key": "875c0462-6309-4ddf-9889-5227b1acc82c",
          },
        }
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getTwitterProfile = createAsyncThunk(
  "auth/getTwitterProfile",
  async (payload, Thunk) => {
    try {
      const params = {
        oauth_consumer_key: payload.consumerKey,
        oauth_version: "1.0",
        oauth_signature_method: "HMAC-SHA1",
        oauth_token: payload.token,
        oauth_timestamp: (Date.now() / 1000).toFixed(),
        oauth_nonce: Math.random()
          .toString(36)
          .replace(/[^a-z]/, "")
          .substring(2),
        include_email: true,
      };

      var httpMethod = "GET";
      var url = "https://api.twitter.com/1.1/account/verify_credentials.json";
      // generates a BASE64 encode HMAC-SHA1 hash
      var signature = oauthSignature.generate(
        httpMethod,
        url,
        params,
        payload.consumerSecret,
        payload.token_secret,
        { encodeSignature: false }
      );

      params.oauth_signature = signature;

      const Axios = axios.create();
      const response = await Axios.get(endpoints.auth.getTwitterProfile, {
        params,
        headers: {
          "x-cors-grida-api-key": "875c0462-6309-4ddf-9889-5227b1acc82c",
        },
      });
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const signUp = createAsyncThunk("signUp", async (payload, Thunk) => {
  try {
    const response = await axios.post(endpoints.auth.signup, payload);
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.auth.forgotPassword, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.auth.verifyOtp, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.auth.resetPassword, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const changePassword = createAsyncThunk(
  "changePassword",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.auth.changePassword, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
