import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Button, Col, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { searchArtist } from "../../../../redux/actions";
import { debounce } from "lodash";
import User from "../../../../images/user.png";

export default function Tagcreatormod(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [chatSearch, setChatSearch] = useState();
  const dispatch = useDispatch();
  const { searchArtistList } = useSelector((s) => s.artist);
  const [tags, setTags] = useState([]);
  const addTag = (item) => setTags((prev) => [...prev, item]);
  const removeTag = (item) =>
    setTags((prev) => prev.filter((itm) => itm._id !== item._id));
  const [sortFilter, setSortFilter] = useState({
    orderBy: "name",
    order: -1 || 1,
  });
  const setSortFilterValue = (name, value) =>
    setSortFilter((prev) => ({ ...prev, [name]: value }));
  var sortedTags = tags;

  sortedTags.sort((a, b) => {
    if (sortFilter.orderBy === "name") {
      if (sortFilter.order === 1) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      } else {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      }
    } else {
      if (sortFilter.order === 1) {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      } else {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  });

  const [filter, setFilter] = useState({
    role: "ARTIST",
    search: "",
    orderBy: "userName",
    order: -1 || 1,
  });

  const setFilterValue = (name, value) =>
    setFilter((prev) => ({ ...prev, [name]: value }));

  const handleChatSearch = () => {
    setChatSearch(!chatSearch);
  };

  const onSearchArtist = useCallback(
    debounce((filter) => {
      dispatch(searchArtist(filter));
    }, 1000),
    []
  );

  useEffect(() => {
    onSearchArtist(filter);
  }, [filter]);

  useEffect(() => {
    if (props.show) {
      setTags(props.tags || []);
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="Tagcreatormod_modal"
    >
      <Modal.Body className="p-0">
        {chatSearch ? (
          <>
            <Form className=" w-100 position-relative h-100 px-3 pt-2">
              <Button
                onClick={handleChatSearch}
                className="border-0 p-0 position-absolute"
                variant="transparent"
                style={{ left: "10px", top: "16px", zIndex: "9" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
              <div className="position-relative searchForm icon-with-text">
                <input
                  type="Search"
                  placeholder="Search...."
                  className="form-control border-0 rounded-0"
                  value={filter.search}
                  onChange={(e) => setFilterValue("search", e.target.value)}
                />
                <span className="icn position-absolute d-inline-block ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </Form>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center add_heading px-3 pt-3">
              <div className="d-flex align-items-center msgTitle gap-10">
                <h5>{t("TAG OTHER CREATORS")}</h5>
              </div>
              <div className="right">
                <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10">
                  <li className="">
                    <Button
                      onClick={handleChatSearch}
                      variant="transparent"
                      className="border-0 p-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </li>
                  <li className="">
                    <div className="three_dot_dropdown">
                      <Dropdown className="border-bottom border-0">
                        <Dropdown.Toggle
                          variant="transparent"
                          className="border-0 p-0"
                          id="dropdown-basic"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                              fill="black"
                            />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="px-3">
                          <ul className="list-unstyled ps-0 mb-0 radioSelect">
                            {/* <li className="py-2 position-relative my-2">
                              <input
                                type="radio"
                                className="position-absolute  w-100 h-100"
                              />
                              <div className="cardCstm">
                                <p className="m-0 fw-bold">All</p>
                              </div>
                            </li>
                            <li className="py-2 position-relative my-2">
                              <input
                                type="radio"
                                className="position-absolute  w-100 h-100"
                              />
                              <div className="cardCstm">
                                <p className="m-0 fw-bold">Pending</p>
                              </div>
                            </li> */}
                            <li
                              className="py-2 position-relative my-2"
                              onClick={() => setSortFilterValue("orderBy", "")}
                            >
                              <input
                                type="radio"
                                className="position-absolute  w-100 h-100"
                                checked={sortFilter.orderBy === ""}
                              />
                              <div className="cardCstm">
                                <p className="m-0 fw-bold">Recent</p>
                              </div>
                            </li>
                            <li
                              className="py-2 position-relative my-2"
                              onClick={() =>
                                setSortFilterValue("orderBy", "name")
                              }
                            >
                              <input
                                type="radio"
                                className="position-absolute  w-100 h-100"
                                checked={sortFilter.orderBy === "name"}
                              />
                              <div className="cardCstm">
                                <p className="m-0 fw-bold">Name</p>
                              </div>
                            </li>
                            <li
                              className="py-2 position-relative my-2"
                              onClick={() => setSortFilterValue("order", 1)}
                            >
                              <input
                                type="radio"
                                className="position-absolute  w-100 h-100"
                                checked={sortFilter.order > 0}
                              />
                              <div className="cardCstm">
                                <p className="m-0 fw-bold">Ascending</p>
                              </div>
                            </li>
                            <li
                              className="py-2 position-relative my-2"
                              onClick={() => setSortFilterValue("order", -1)}
                            >
                              <input
                                type="radio"
                                className="position-absolute  w-100 h-100"
                                checked={sortFilter.order < 0}
                              />
                              <div className="cardCstm">
                                <p className="m-0 fw-bold">Descending</p>
                              </div>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tagcontent_box px-3 pt-2">
              <p>
                {t(
                  "The use of third-party content without legal permission is a violation of our Acceptable Use Policy"
                )}
              </p>
            </div>
          </>
        )}
        <div
          className="calednder_Mod"
          style={{ height: "400px", maxHeight: "400px", overflowY: "scroll" }}
        >
          {/* <div className="tag_content_here">
            <div className="tag_btnteice d-flex gap-3 px-3 py-3">
              <Button className="taguser">{t("Tag user")}</Button>
              <Button className="release_btn ">{t("Release form")}</Button>
            </div>
          </div> */}

          {!chatSearch && tags.length === 0 && (
            <div className="no_mentions border-top border-5">
              <div className="innertag text-center">
                <h1>@</h1>
                <p>{t("No mentions or tags yet")}</p>
              </div>
            </div>
          )}

          {(chatSearch ? searchArtistList : sortedTags)
            ?.filter(
              (user) =>
                !props.selectedUsers.find((val) => val.id === user._id) ||
                props.selectedUsers.length === 0
            )
            .map((item, index) => (
              <div
                className={`my-2 px-2 d-flex justify-content-between align-items-center mx-2 pointer  py-2`}
                key={index}
              >
                <div className="d-flex align-items-center suggestion-list">
                  <img
                    src={item?.profileImage || User}
                    alt="Profile"
                    className="profile-image "
                  />

                  <div className="user-details">
                    <div className="user-name">
                      {item.name || item.firstName}
                    </div>
                    <div className="username">@{item.userName}</div>
                  </div>
                </div>
                <div className="chatTime d-flex flex-column justify-content-end">
                  <div className="d-flex align-items-center justify-content-end">
                    {tags?.some((itm) => itm._id === item._id) ? (
                      <button
                        className="border-0 bg-white userchat"
                        onClick={() => removeTag(item)}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="border-0 bg-white userchat"
                        onClick={() => addTag(item)}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {tags.length > 0 && (
          <div className="px-3 my-2">
            <div className="rounded-pill d-flex align-items-center justify-content-between gap-10 addBtnWrpper">
              <div className="left d-flex align-items-center">
                <Button
                  className="rounded-circle btnSm d-flex align-items-center justify-content-center me-2"
                  onClick={() => setTags([])}
                >
                  X
                </Button>
                <p className="fw-sbold m-0">{tags.length} selected</p>
              </div>
              <div className="right">
                <Button
                  className="d-flex align-items-center justify-content-center rounded-pill common-btn btnSm"
                  onClick={() => (props.setTags(tags), props.onhide())}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="btns_twice d-flex align-items-center justify-content-between px-4 py-3 border-top">
          {/* <Button
              className="cancel_btn"
              onClick={() => addTag({ _id: "123" })}
            >
              + {t("INVITE NEW USER")}
            </Button> */}
          <span></span>
          <Button className="cancel_btn" onClick={props.onhide}>
            {t("CLOSE")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
