import React from "react";
import AuthLayout from "../../layout/AuthLayout";
import VerifyOtpComponent from "../../components/AuthComponent/VerifyOtp";

const VeriftyOtp = () => {
  return (
    <AuthLayout>
      <VerifyOtpComponent />
    </AuthLayout>
  );
};

export default VeriftyOtp;
