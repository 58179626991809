import React, { useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthFooter from "../../../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../../components/common/ButtonLoader/SmallLoader";
import { contactUs, fetchFaq } from "../../../redux/states/common/thunk";
import { toast } from "react-toastify";
import HelpSupport from "../../SideTabs/HelpSupport/HelpSupport";
import Accordion from "react-bootstrap/Accordion";
import { useEffect } from "react";

const Faq = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { faqList } = useSelector((s) => s.common);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    limit: 100,
    orderBy: "createdAt",
    order: -1,
    page: 1,
    status: "",
  });

  useEffect(() => {
    dispatch(fetchFaq(filter));
  }, [filter]);

  return (
    <>
      <MainLayout>
        <Row className="  h-100 align-items-start justify-content-between ">
          <Col lg="12" md="12" sm="12">
            <div className="shadow1 rounded border h-100 px-3 py-4 ">
              <div className="d-flex justify-content-between align-items-center px-2">
                <h4 className="text-bold">
                  <span onClick={() => navigate(-1)} className="pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 12L11 6M5 12L11 18M5 12L19 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ marginLeft: "7px" }}>
                    {t("Frequently Asked Questions")}
                  </span>
                </h4>
              </div>
              <div className="endline my-3"></div>
              {faqList?.length > 0 && (
                <div>
                  <Accordion defaultActiveKey="">
                    {faqList?.map((item, index) => (
                      <Accordion.Item eventKey={String(index)} key={index}>
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body>{item.answer}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )}
              {/* <div className="contactCont">
                <p
                  className="text-sm text-center my-3"
                  style={{ fontSize: "18px" }}
                >
                  {t(
                    "If you have any questions, please fill out the form below to contact us."
                  )}
                </p>
                <div className="contactform"></div>
              </div> */}
            </div>
          </Col>
        </Row>
      </MainLayout>
      <AuthFooter />
    </>
  );
};

export default Faq;
