import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import User from "../../../images/user.png";
import DummyImg from "../../../images/dummyBack.jpeg";
import { useTranslation } from "react-i18next";

const Subscribemod = (props) => {
  const { t } = useTranslation();
  const { artistDetailss } = props;
  return (
    <Modal
      {...props}
      keyboard={false}
      centered
      size="md"
      className="single_subscribe"
    >
      <Modal.Body>
        <div className="subscriber_mod">
          <div className="">
            <div className="personal_logincard">
              <div className="subscribe_abnner">
                <img
                  src={artistDetailss?.coverImage || DummyImg}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="subscriber_Content">
                <div className="subsciber_profile d-flex align-items-end gap-4">
                  <div className="subsciber_profileimg">
                    <img
                      src={artistDetailss?.profileImage || User}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="subsciber_profilename">
                    <h4>
                      {artistDetailss?.firstName
                        ? artistDetailss?.firstName +
                          " " +
                          (artistDetailss?.lastName || " ")
                        : artistDetailss?.name}
                    </h4>
                    <p>@{artistDetailss?.userName}</p>
                  </div>
                </div>

                <div className="subsciber_details px-3 pb-3">
                  <h5>{t("SUBSCRIBE AND GET THESE BENEFITS:")}</h5>

                  <ul className="benefits m-0">
                    <li>{t("Full access to this user's content")}</li>
                    <li>{t("Direct message with this user")}</li>
                    <li>{t("Cancel your subscription at any time")}</li>
                  </ul>
                  <Button
                    className="w-100 py-2 add-payment mt-3"
                    onClick={props.addtopayment}
                  >
                    {t("Please add a payment card")}
                  </Button>
                </div>

                <Modal.Footer className="border-top position-relative">
                  <a
                    href="javascript:void(0)"
                    onClick={props.onhide}
                    className="close_text"
                  >
                    {t("Close")}
                  </a>
                </Modal.Footer>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Subscribemod;
