import { createSlice } from "@reduxjs/toolkit";
import {
  bookmarkPost,
  commentPost,
  getPostList,
  getStoryList,
  likePost,
  unlikePost,
} from "./thunk";
import { status } from "../../constants";

const initialState = {
  list: [],
  listCount: 0,
  showLoader: false,
  buttonLoader: false,
  status: status.IDLE,
  selectedPost: {},
  storyList: [],
};

const slice = createSlice({
  name: "post",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPostList.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(getPostList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showLoader = false;
      state.list = action?.payload?.data;
      state.listCount = action?.payload?.totalCount;
    });
    builder.addCase(getPostList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showLoader = false;
      state.list = initialState.list;
      state.listCount = initialState.listCount;
    });
    builder.addCase(getStoryList.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(getStoryList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.storyList = action?.payload?.data;
    });
    builder.addCase(getStoryList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.storyList = initialState.list;
    });

    builder.addCase(likePost.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(likePost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
    });
    builder.addCase(likePost.rejected, (state, action) => {
      state.status = status.FAILED;
    });

    builder.addCase(unlikePost.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(unlikePost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
    });
    builder.addCase(unlikePost.rejected, (state, action) => {
      state.status = status.FAILED;
    });

    builder.addCase(commentPost.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(commentPost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
    });
    builder.addCase(commentPost.rejected, (state, action) => {
      state.status = status.FAILED;
    });

    builder.addCase(bookmarkPost.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(bookmarkPost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
    });
    builder.addCase(bookmarkPost.rejected, (state, action) => {
      state.status = status.FAILED;
    });
  },
});

export default slice.reducer;
