import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AuthLayout from "../../../layout/AuthLayout";
import "../../../css/style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, login } from "../../../redux/actions";
import { toast } from "react-toastify";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { selectEmail } from "../../../redux/states/auth/slice";
import { useTranslation } from "react-i18next";
import SocialLogin from "../SocialLogin/SocialLogin";

const ForgotPasswordComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { buttonLoader } = useSelector((s) => s.login);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid Email")),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(forgotPassword(values))
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(selectEmail(formik.values.email));
            navigate("/verify-otp");
            toast.success(t(res?.message));
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  return (
    <Container className=" w-100 px-lg-5 py-4 d-flex justify-content-center">
      <Row className="loginCont">
        <Col lg="12" md="12" className="mt-4">
          <h3 className="text-black text-medium mb-4">
            {t("Forgot Password")}
          </h3>
          <div className="">
            <input
              type="text"
              className="loginInput"
              placeholder={t("Email")}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <span className="text-danger errorText">
                {formik.errors.email}
              </span>
            )}
          </div>

          <div className="mt-4">
            {buttonLoader ? (
              <button className="loginBtn2 text-light">
                <ButtonLoader />
              </button>
            ) : (
              <button
                className={` text-light ${
                  !formik.isValid || !formik.dirty ? "disabled-btn" : "loginBtn2"
                }`}
                onClick={formik.handleSubmit}
              >
                {t("Submit")}
              </button>
            )}
          </div>
          <div className="mt-4 px-5 d-flex justify-content-between align-items-center">
            <h4
              className="text-black text-normal pointer"
              onClick={() => navigate("/")}
            >
              {" "}
              {t("Login")}
            </h4>
            <h4
              className="text-black text-normal pointer"
              onClick={() => navigate("/signup")}
            >
              {t("Sign up for Fansroom")}
            </h4>
          </div>
          {/* <SocialLogin /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordComponent;
