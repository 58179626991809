import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Settings from "./Settings";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, login } from "../../../redux/actions";
import { toast } from "react-toastify";
import ButtonLoader from "../../../components/common/ButtonLoader/ButtonLoader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const ChangePasswords = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buttonLoader, selectedEmail } = useSelector((s) => s.login);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      currentPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string(t())
        .required(t("Password is required"))
        .min(3, t("Password must be greater than 3 character")),
      // .max(10, t("Password must be less than 10 character")),
      password: Yup.string()
        .required(t("Password is required"))
        .min(3, t("Password must be greater than 3 character")),
      // .max(10, t("Password must be less than 10 character")),
      confirmPassword: Yup.string()
        .required(t("Confirm Password isn't matched with password"))
        .oneOf([Yup.ref("password"), null], t("Password does not match"))
        .min(3, t("Password must be greater than 3 character")),
      // .max(10, t("Password must be less than 10 character")),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(changePassword(values))
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success(t("Password changed successfully"));
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });
  return (
    <Settings>
      <Col lg="12" md="12" className=" px-5 py-3">
        <h3 className="text-black text-normal mb-4">{t("Change Password")}</h3>

        <div className="mt-4 position-relative loginInput">
          <input
            type={showCurrentPassword ? "text" : "password"}
            className="loginInputPassword"
            placeholder="Current Password"
            name="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="eyeIcon">
            {showCurrentPassword ? (
              <AiOutlineEye
                color=""
                size={23}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                color=""
                size={23}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            )}
          </div>
        </div>
        {formik.touched.currentPassword && formik.errors.currentPassword && (
          <span className="text-danger errorText">
            {formik.errors.currentPassword}
          </span>
        )}
        <div className="mt-4 position-relative loginInput">
          <input
            type={showPassword ? "text" : "password"}
            className="loginInputPassword"
            placeholder="New Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="eyeIcon">
            {showPassword ? (
              <AiOutlineEye
                color=""
                size={23}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                color=""
                size={23}
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <span className="text-danger errorText">
            {formik.errors.password}
          </span>
        )}
        <div className="mt-4 position-relative loginInput">
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="loginInputPassword"
            placeholder="Confirm New Password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="eyeIcon">
            {showConfirmPassword ? (
              <AiOutlineEye
                color=""
                size={23}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                color=""
                size={23}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </div>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <span className="text-danger errorText">
            {formik.errors.confirmPassword}
          </span>
        )}
        <div className="mt-4">
          {buttonLoader ? (
            <button className="loginBtn text-light" ß>
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="loginBtn text-light"
              onClick={formik.handleSubmit}
            >
              {t("Submit")}
            </button>
          )}
        </div>
      </Col>
    </Settings>
  );
};

export default ChangePasswords;
