// This is the push notification section.j
// This is the username section inside the acount section;

import React, { useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import { getProfile, updateProfile } from "../../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const TelegramBot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("TELEGRAM BOT")}</span>
        </h4>
      </div>
      <div className="endline mt-2"></div>

      <div className="mx-3 px-2 py-1">
        <div className="d-flex pointer justify-content-between align-items-center py-1">
          <div className="">
            <h6 className="text-small fw-sbold mb-0 px-2">
              {t("Get Bot Link")}
            </h6>
            <h6 className="light-text pt-2 mb-0">
              {t("Please do not share this link with anyone")}
            </h6>
          </div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className="endline"></div>
    </Settings>
  );
};

export default TelegramBot;
