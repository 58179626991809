import React, { useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import AddCardComponent from "../../../components/SideTabs/AddCard";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import Addpaymentmod from "../../../components/common/Modal/Addpaymentmod";
import { useSelector } from "react-redux";

// const stripePromise = loadStripe(
//   "pk_test_51O2gNNLI2EI2TtmrOCtzqsiaFTbzEA6Pn3JlN1vhFWTm9mQU6zIWgjuxXbWTFBHOMf0fGN5EsBcMysihhxwztlcm00nl8e4dlh"
// );

const AddCard = () => {
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  return (
    <MainLayout>
      {/* <Elements stripe={stripePromise}> */}
      <AddCardComponent
        showAddCardModal={showAddCardModal}
        setShowAddCardModal={setShowAddCardModal}
      />
      {/* </Elements>

      <Elements stripe={stripePromise}> */}
      <Addpaymentmod
        show={showAddCardModal}
        onhide={() => setShowAddCardModal(false)}
      />
      {/* </Elements> */}
    </MainLayout>
  );
};

export default AddCard;
