import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBwVpND5kS3zEEsfsomDO-yBwLSw3mUDd8",
  authDomain: "fansroom-71104.firebaseapp.com",
  projectId: "fansroom-71104",
  storageBucket: "fansroom-71104.appspot.com",
  messagingSenderId: "821133504938",
  appId: "1:821133504938:web:3f0f2427aeeec11264bbd3",
  measurementId: "G-JZJZRZCHR4",
};

var firebaseApp;
var messaging;
try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (error) {}

export const getFirebaseToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BIn6ByFMANSF1Xy4gWk26c3GAUsEDKJ2VP4bHQp4DVmNRt2RAI7-ivu4kFxYx7eUKIDl6ldZL4hKnRhQSzHkMhc",
    });
    if (currentToken) {
      setTokenFound(true);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      setTokenFound(false);
    }
    return currentToken;
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export function showForegroundNotification({ icon, title, body }) {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    //   alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification(title, { icon, body });
    // …
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification(title, { icon, body });
      }
    });
  }
}
