import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  transactionList,
  withDrawMoneyFromWallet,
} from "../../../redux/states/card/thunk";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { toast } from "react-toastify";
import { getProfile } from "../../../redux/states/settings/thunk";

const WithdrawlMoneyModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [payData, setPayData] = useState({
    bankAccountId: "",
    amount: "",
  });
  const { cardList, buttonLoader, accountList } = useSelector((s) => s.card);
  const { walletCredit } = useSelector((s) => s.setting?.user);

  const refreshForm = () => {
    setPayData({
      bankAccountId: "",
      amount: "",
    });
  };

  const getTransactionList = () => {
    dispatch(transactionList());
    dispatch(getProfile());
  };

  const withDrawMoney = () => {
    dispatch(withDrawMoneyFromWallet(payData))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        props.onHide();
        refreshForm();
        getTransactionList();
      })
      .catch((err) => {
        toast.error(err?.message);
        props.onHide();
      });
  };

  const close = () => {
    refreshForm();
    props.onHide();
  };

  useEffect(() => {
    if (accountList?.length > 0) {
      const id = accountList[0];
      setPayData((rest) => ({ ...rest, bankAccountId: accountList[0]?.id }));
    }
  }, [accountList]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pin_modal"
    >
      <Modal.Body>
        <div className="modal_header d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <h6 className="text-small mt-2">{t("WITHDRAW MONEY")} </h6>
        </div>

        <div className="middle_content px-3 py-2">
          <div>
            <FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                <label className="text-small my-2">{t("Enter Amount")}</label>
                <h6 className="text-small mb-0">
                  {t("Current Balance")} : $ <span>{walletCredit || 0} </span>
                </h6>
              </div>
              <Input
                type="number"
                name="radio1"
                className="text-small mt-3"
                placeholder="Enter Amount in $"
                value={payData?.amount}
                onChange={(e) =>
                  setPayData((rest) => ({ ...rest, amount: e.target.value }))
                }
              />{" "}
              <p className="mininum_info text-left mt-1 mx-2">
                {t("Minimum withdrawal amount is $20")}
              </p>
            </FormGroup>

            <div className="d-flex mt-2 justify-content-center">
              {buttonLoader ? (
                <button className="updateNotificationBtn">
                  <SmallLoader />
                </button>
              ) : (
                <button
                  className={`updateNotificationBtn ${
                    (payData?.amount === "" || payData?.amount < 20) &&
                    "btn-disable "
                  }updateNotificationBtn`}
                  disabled={payData?.amount === "" || payData?.amount < 20}
                  onClick={withDrawMoney}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="modal_footer d-flex align-items-center justify-content-end px-3 py-2 border-top">
          <Button onClick={close}>{t("Close")}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawlMoneyModal;
