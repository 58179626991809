import React, { useState, useEffect, useCallback } from "react";
import SocketIo from "socket.io-client";
import config, { REACT_APP_API_BASE_URL } from "../config";
import { useSelector } from "react-redux";

const baseApiUrl = REACT_APP_API_BASE_URL;

function handleErrors(err) {
  console.error("Socket Error", err);
}

export default function useSocket(url = baseApiUrl, data = {}) {
  const { user } = useSelector((s) => s.login);
  const [socket, setSocket] = useState({});
  const connect = useCallback(
    ({ userId }) => {
      try {
        const io = SocketIo(url, { transports: ["polling"] });
        if (io) {
          if (io && !io?.hasListeners("connect")) {
            io.on("connect", () => {
              io.emit(
                "customersocket",
                { customerId: userId, ...data },
                (response) => {
                  setSocket({
                    ...response,
                    io,
                  });
                }
              );

              if (io && !io?.hasListeners("customersocket")) {
                io.on("customersocket", (response) => {
                  setSocket({
                    ...response,
                    io,
                  });
                });
              }
            });
          }
          io.on("connect_error", (err) => handleErrors(err));
          io.on("connect_failed", (err) => handleErrors(err));
          io.on("disconnect", (err) => handleErrors(err));
        } else {
          throw new Error("Socket Not Found");
        }
      } catch (error) {
        setSocket({});
      }
    },
    [url, data, user]
  );

  useEffect(() => {
    if (user?._id && typeof user?._id === "string") {
      connect({ userId: user?._id });
    } else {
      socket?.io?.close?.();
    }
    return () => socket?.io?.close?.();
  }, [user?._id]);

  return socket;
}
