import React from "react";
import MainLayout from "../../../layout/MainLayout";
import PostEditComponent from "../../../components/SideTabs/PostEdit/PostEdit";

const PostEdit = () => {
  return (
    <MainLayout>
      <PostEditComponent />
    </MainLayout>
  );
};

export default PostEdit;
