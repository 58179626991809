// This is the account section;
import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getProfile, updateProfile, uploadFile } from "../../../redux/actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteAccount from "../../../components/common/Modal/ConfirmDeleteAccount";
import { logout } from "../../../redux/states/auth/slice";
import { useTranslation } from "react-i18next";

const Account = () => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const [showBox, setShowBox] = useState("");

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      userName: "",
      displayName: "",
      bio: "",
      websiteLink: "",
      amazonWishlist: "",
      profileImage: "",
      address: "",
      coverImage: "",
      password: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getUserData();
          handleCut();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Username is required"),
      displayName: Yup.string().required("Display name is required"),
      bio: Yup.string().required("bio is required"),
      websiteLink: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
      amazonWishlist: Yup.string(),
      profileImage: Yup.string(),
      address: Yup.string().required("Address is required"),
    }),
  });

  useEffect(() => {
    formik.setValues({
      userName: user?.userName,
      displayName: user?.displayName,
      bio: user?.bio,
      websiteLink: user?.websiteLink,
      amazonWishlist: user?.amazonWishlist,
      profileImage: user?.profileImage,
      coverImage: user?.coverImage,
      address: user?.address,
      password: "",
      email: user?.email,
      mobileNumber: user?.mobileNumber,
    });
  }, [user]);

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleCut = () => {
    setShowBox("");
    getUserData();
  };

  const deleteMyAccount = () => {
    dispatch(deleteMyAccount())
      .unwrap()
      .then((res) => {
        toast.success("Your account has been deleted");
        dispatch(logout());
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <Settings>
      <div className="mt-1 ">
        <div className="mx-3 d-flex justify-content-between align-items-center px-3 py-3">
          <h6 className="text-bold">{t("Account")}</h6>
        </div>
        <div className="endline"></div>

        <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
          <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
            {t("Account info")}
          </h6>
        </div>

        <div className="endline"></div>

        <div
          className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer"
          onClick={() => navigate("/settings/account/username")}
        >
          <h6 className="text-small fw-sbold mb-0 px-2">{t("Username")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>

        <div className="endline"></div>

        <div
          className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer"
          onClick={() => navigate("/settings/account/email")}
        >
          <h6 className="text-small fw-sbold mb-0 px-2">{t("Email")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div
          className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer"
          onClick={() => navigate("/settings/account/mobileNumber")}
        >
          <h6 className="text-small fw-sbold mb-0 px-2">{t("Phone Number")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
          <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
            {t("Linked Accounts")}
          </h6>
        </div>

        <div className="endline"></div>

        <div
          onClick={() => navigate("/settings/account/twitter")}
          className="mx-3 pointer d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Twitter accounts")}
          </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div
          onClick={() => navigate("/settings/account/google")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">Google accounts</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/account/socialAccount")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small bold mb-0 px-2">Social accounts</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
          <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
            {t("Connected Accounts")}
          </h6>
        </div>

        <div className="endline"></div>
        <div
          onClick={() => navigate("/settings/account/connectAccount")}
          className="mx-3 pointer d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Connect another fansroom account")}
          </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
          <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
            {t("Security")}
          </h6>
        </div>
        <div className="endline"></div>

        <div
          onClick={() => navigate("/settings/account/change-password")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">{t("Password")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div
          onClick={() => navigate("/settings/account/loginSession")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Login sessions")}
          </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        <div
          onClick={() => navigate("/settings/account/twoStepVerification")}
          className="pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Two Step Authentications")}
          </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>

        {/* <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2">
          <h6 className="text-small fw-sbold mb-0 px-2">
            Windows Hello Authentication
          </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div> */}

        <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
          <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
            {t("Account Management")}
          </h6>
        </div>

        <div className="endline"></div>

        <div
          onClick={() => setShowDeleteModal(true)}
          className=" pointer mx-3 d-flex justify-content-between align-items-center px-2 py-2"
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Delete Account")}
          </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M4.94983 7.00072L-0.000167908 2.05072L1.41383 0.636718L7.77783 7.00072L1.41383 13.3647L-0.000168341 11.9497L4.94983 6.99972L4.94983 7.00072Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="endline"></div>
      </div>

      <ConfirmDeleteAccount
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        deleteAccount={deleteMyAccount}
      />
    </Settings>
  );
};

export default Account;
