// This is the username section inside the acount section;

import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import {
  getProfile,
  updateProfile,
  verifictionWithEmail,
  verifyEmailOtpForAuthentication,
} from "../../../../redux/actions";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const TwoStepAuthentications = () => {
  const { t } = useTranslation();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const [selectedTab, setSelectedTab] = useState("");
  const [smsVerification, setSmsVerification] = useState(false);
  const [appAuthentication, setAppAuthentication] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpFormik = useFormik({
    initialValues: {
      OTP: "",
      email: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(verifyEmailOtpForAuthentication(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          resetForm();
          setSelectedTab("");
          setAppAuthentication(false);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      OTP: Yup.string()
        .required(t("OTP is required"))
        .min(4, t("Invalid OTP"))
        .max(4, t("Invalid OTP")),
    }),
  });

  const mobileFormik = useFormik({
    initialValues: {
      mobileNumber: "",
      countryCode: "+91",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(t("Sms Verified successfully"));
          resetForm();
          setSelectedTab("");
          setSmsVerification(false);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string().required(t("Phone Number is required")),
    }),
  });

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    otpFormik.setFieldValue("email", user?.email);
  }, [user]);

  useEffect(() => {
    getUserData();
  }, []);

  const sendEmailAuthentication = () => {
    dispatch(verifictionWithEmail({}))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleTab = (dta) => {
    setSelectedTab(dta);
    if (dta === "smsVerification") {
      setSmsVerification(!smsVerification);
      setAppAuthentication(false);
    } else if (dta === "appAuthentication") {
      if (!appAuthentication) {
        sendEmailAuthentication();
      }
      setAppAuthentication(!appAuthentication);
      setSmsVerification(false);
    }
  };

  const handlePhoneNumberChange = (value, country) => {
    mobileFormik.setFieldValue("countryCode", "+" + country?.dialCode);
    mobileFormik.setFieldValue(
      "mobileNumber",
      value.substring(country?.dialCode.length)
    );
  };

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>
            {t("TWO STEP AUTHENTICATION")}
          </span>
        </h4>
      </div>
      <div className="endline mt-2"></div>
      <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
        <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
          {t("Primary Options")}
        </h6>
      </div>
      <div className="endline"></div>
      <div className="mx-3 px-2 py-1">
        <div
          className="pointer d-flex justify-content-between align-items-center py-1"
          onClick={() => handleTab("appAuthentication")}
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Authenticator App")}
          </h6>
          <Form>
            <Form.Check
              type="switch"
              id="toggle-switch"
              checked={appAuthentication}
            />
          </Form>
        </div>
        <h6 className="light-text pb-2">
          {t(
            "You can use Microsoft or Google Authenticator application for iOS or Android"
          )}
        </h6>
      </div>
      <div className="endline"></div>
      {selectedTab === "appAuthentication" && appAuthentication && (
        <>
          <div className="mx-3 px-2 py-1">
            <div className=" pointer py-2">
              <h6 className="text-small fw-sbold mb-0 px-2">
                {t("Enter Verfication Code")}
              </h6>
              <div className="formSection ">
                <div className="d-flex justify-content-center mt-3">
                  <input
                    value={otpFormik.values.OTP}
                    placeholder={t("Enter Code")}
                    onChange={otpFormik.handleChange}
                    onBlur={otpFormik.handleBlur}
                    name="OTP"
                    id="OTP"
                    className="loginInput"
                    maxLength={4}
                    type="text"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center w-full">
                <span className="py-2 light-text">
                  {t("Code has been send to your email")}
                </span>
                <span
                  className="py-2 light-text pointer text-primary"
                  onClick={sendEmailAuthentication}
                >
                  {t("RESEND CODE")}
                </span>
              </div>

              {otpFormik.touched.OTP && otpFormik.errors.OTP && (
                <span className="text-danger errorText">
                  {otpFormik.errors.OTP}
                </span>
              )}
            </div>
            <div className="my-1">
              {buttonLoader ? (
                <button className="loginBtn text-light" ß>
                  <ButtonLoader />
                </button>
              ) : (
                <button
                  className="loginBtn text-light"
                  onClick={otpFormik.handleSubmit}
                >
                  {t("Verify Code")}
                </button>
              )}
            </div>
          </div>
          <div className="endline"></div>
        </>
      )}
      <div className="mx-3 px-2 py-1">
        <div
          className=" pointer d-flex justify-content-between align-items-center py-2"
          onClick={() => handleTab("smsVerification")}
        >
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Verification via SMS")}
          </h6>
          <Form>
            <Form.Check
              type="switch"
              id="toggle-switch"
              checked={smsVerification}
              // onChange={handleToggle}
            />
          </Form>
        </div>
      </div>
      <div className="endline"></div>
      {selectedTab === "smsVerification" && smsVerification && (
        <>
          <div className="mx-3 px-2 py-1">
            <div className=" pointer py-2">
              <h6 className="text-small fw-sbold mb-0 px-2">
                {t("Phone Number")}
              </h6>
              <div className="formSection ">
                <div className="d-flex justify-content-center mt-3">
                  <PhoneInput
                    country={"in"}
                    value={
                      mobileFormik.values.countryCode +
                      mobileFormik.values.mobileNumber
                    }
                    onChange={handlePhoneNumberChange}
                    placeholder="Enter Phone No."
                    className="phoneInputCont"
                  />
                </div>
              </div>
              {mobileFormik.touched.mobileNumber &&
                mobileFormik.errors.mobileNumber && (
                  <span className="text-danger errorText">
                    {mobileFormik.errors.mobileNumber}
                  </span>
                )}
            </div>
            <div className="my-1">
              {buttonLoader ? (
                <button className="loginBtn text-light" ß>
                  <ButtonLoader />
                </button>
              ) : (
                <button
                  className="loginBtn text-light"
                  onClick={mobileFormik.handleSubmit}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </div>
          <div className="endline"></div>
        </>
      )}
    </Settings>
  );
};

export default TwoStepAuthentications;
