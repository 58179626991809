import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PostComponent from "../common/Posts/PostComponent";
import ArtistSection from "../common/ArtistSection/ArtistSection";
import { useDispatch, useSelector } from "react-redux";
import { artistDetails, getPostDetails } from "../../redux/actions";
import SendTip from "../common/Modal/SendTip";
import EntertainerActivity from "../common/EntertainerActivity/index";

const PostDetailsComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postDetails } = useSelector((s) => s.postDetails);
  const [showSendTip, setShowSendTip] = useState(false);
  const [data, setData] = useState({});
  const { slug } = useParams();
  const { role } = useSelector((s) => s.setting);
  const userId = useSelector((s) => s.login?.user)?._id;
  const [isSubscribed, setIsSubscribed] = useState(false);

  const getPostDetailss = () => {
    dispatch(getPostDetails(slug));
  };
  useEffect(() => {
    if (slug) {
      getPostDetailss();
    }
  }, [slug]);

  const refreshData = (type) => {
    if (type === "like") {
      const ddd = {
        ...postDetails,
        isLiked: true,
        likes: [...postDetails?.likes, { userId: userId }],
      };
      setData(ddd);
    } else if (type === "unlike") {
      const filterData = postDetails?.likes?.filter((itm) => {
        return itm.userId !== userId;
      });
      const ddd = {
        ...postDetails,
        isLiked: false,
        likes: filterData,
      };
      setData(ddd);
    } else if (type === "bookmark") {
      const ddd = {
        ...postDetails,
        isBookmarked: true,
      };
      setData(ddd);
    } else if (type === "unBookmark") {
      const ddd = {
        ...postDetails,
        isBookmarked: false,
      };
      setData(ddd);
    } else if (type === "comment") {
      getPostDetailss();
    }
  };

  useEffect(() => {
    if (postDetails) {
      setData(postDetails);
    }
  }, [postDetails]);

  // this is the code just for checking either post artist is subscribed or not;

  useEffect(() => {
    const artistId = postDetails?.userDetails?._id;
    if (artistId) {
      dispatch(artistDetails(artistId))
        .unwrap()
        .then((res) => {
          const artistData = res?.data?.[0];
          setIsSubscribed(artistData?.isFollowing);
        })
        .catch((err) => {});
    }
  }, [postDetails]);

  useEffect(() => {
    if (userId) {
      if (
        postDetails &&
        !postDetails?.isPosted &&
        postDetails?.userDetails?.userName
      ) {
        navigate(`/${postDetails?.userDetails?.userName}`);
      }
    } 
    // else {
    //   navigate(`/?link=/post-details/${slug}`);
    // }
  }, [postDetails, userId]);

  return (
    <Row className="  h-100 align-items-start justify-content-between ">
      <Col lg="7" md="12" sm="12">
        <div className="shadow1 rounded  border-2 border-danger h-100  py-4  homeMiddleCont">
          <div className="d-flex justify-content-between align-items-center px-4 mb-2">
            <h4 className="text-bold pointer" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 12L11 6M5 12L11 18M5 12L19 12"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ marginLeft: "7px" }}>{t("POST")}</span>
            </h4>
          </div>
          <div className="endline"></div>
          <div className=" mx-4">
            <PostComponent
              data={data}
              refreshData={refreshData}
              showProfileBanners={true}
              setShowSendTip={setShowSendTip}
              isSubscribed={isSubscribed}
              getPostDetailss={getPostDetailss}
            />
          </div>
        </div>
      </Col>
      <Col lg="5" md="12" sm="12" className="sticky-top">
        {role === "USER" && <ArtistSection />}
        {role === "ARTIST" && <EntertainerActivity />}
      </Col>
      <SendTip show={showSendTip} onHide={() => setShowSendTip(false)} />
    </Row>
  );
};

export default PostDetailsComponent;
