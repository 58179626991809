// This will be used for viewing the image and video;

import React from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactPlayer from "react-player";
import useWatermark from "../../../helpers/add-watermark";
import CustomVideoPlayer from "../../CustomVideoPlayer";

const ImageVideoViewer = (props) => {
  const { mediaType, url, userName } = props;
  const watermark = useWatermark();
  const handleClose = () => {
    props.onHide();
  };

  const mediaItem = {
    type: mediaType,
    url,
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="imgPostViewerpop"
    >
      <Modal.Body className="viewerCont bg-transparent">
        <div className="viewerUnder">
          {mediaType === "video" && (
            // <CustomVideoPlayer mediaItem={mediaItem} />
            <ReactPlayer
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              onContextMenu={(e) => e.preventDefault()}
              url={url}
              width={"100%"}
              controls={true}
              volume={true}
              className="custom-player"
            />
          )}
          {mediaType === "image" && (
            <img
              src={watermark(url, "fansroom.com/" + userName) || url}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              className="img-fluid"
              alt=""
            />
          )}
        </div>
        <AiOutlineCloseCircle
          className="closeIcons2 mx-auto"
          size={24}
          color="white"
          style={{ left: "0", right: "0" }}
          onClick={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImageVideoViewer;
