import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EarningStatisticsDatePicker(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getEarnningStasticsList,
  } = props;

  const save = () => {
    if (!startDate) {
      toast.warning(t("Start date is required"));
    } else if (!endDate) {
      toast.warning(t("End date is required"));
    } else {
      getEarnningStasticsList();
      props.onhide();
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="expiration_modal"
    >
      <Modal.Body>
        <div className="calednder_Mod">
          <div className="add_heading d-flex px-4 pt-4 justify-content-between">
            <h5>{t("CHOOSE DATE")}</h5>
            <a href="javascript:void(0)" onClick={props.onhide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </a>
          </div>

          <div className="Expiraion_form px-4 mt-3">
            <Form>
              <FormGroup>
                <Label for="postprice">{t("Start Date")}</Label>
                {/* <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  placeholder={t("Start Date")}
                  value={startDate}
                  className="mb-3"
                  onChange={(e) => setStartDate(e.target.value)}
                /> */}
                <DatePicker
                  className="mb-3"
                  placeholderText={t("Start Date")}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  wrapperClassName="commonDatepickerWrapper"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="postprice">{t("End Date")}</Label>
                {/* <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  placeholder={t("End Date")}
                  value={endDate}
                  min={startDate}
                  onChange={(e) => setEndDate(e.target.value)}
                /> */}
                <DatePicker
                  minDate={startDate}
                  className="mb-3"
                  placeholderText={t("End Date")}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  wrapperClassName="commonDatepickerWrapper"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </FormGroup>

              <div className="d-flex justify-content-end gap-3 py-3 mt-4">
                <Button className="cancel_btn" onClick={props.onhide}>
                  {t("Cancel")}
                </Button>
                <Button className="cancel_btn" onClick={save}>
                  {t("Save")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
