// This is the normal post component ;

import React, { useState, useRef, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { getTimeAgo } from "../../../helpers/time";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPoll,
  addQuiz,
  artistDetails,
  blockArtist,
  bookmarkPost,
  commentPost,
  getProfile,
  likePost,
  postViewed,
  restirctArtist,
  subscribeArtist,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DummmyImg from "../../../images/dummyBack.jpeg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { VscVerified } from "react-icons/vsc";
import SendTip from "../Modal/SendTip";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { updateViewedPostList } from "../../../redux/states/post/slice";
import DummyPost from "../DummyPost";
import CommentSection from "../CommentSection/CommentSection";
import PostViewer from "../PostViewer/PostViewer";
import Heart from "../../../images/heart.svg";
import SendTipSVG from "../../../images/tip.svg";
import FilledHeart from "../../../images/filledHeart.svg";
import Bookmark from "../../../images/bookmark.svg";
import FilledBookmark from "../../../images/filledBookmark.svg";
import Comment from "../../../images/comment.svg";
import DisableHeart from "../../../images/disableHeart.svg";
import DisableComment from "../../../images/disableComment.svg";
import SaveToList from "../Modal/SaveToList";
import AddList from "../Modal/AddList";
import Addpaymentmod from "../Modal/Addpaymentmod";
import Logintosubscribe from "../Modal/Logintosubscribe";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { formatText } from "../../../helpers/formatPostText";
import TagUserseList from "../Modal/EntertainerModal/TagUsersList";
import SubscriptionPayment from "../Modal/SubscriptionPayment";

// const stripePromise = loadStripe(
//   "pk_test_51O2gNNLI2EI2TtmrOCtzqsiaFTbzEA6Pn3JlN1vhFWTm9mQU6zIWgjuxXbWTFBHOMf0fGN5EsBcMysihhxwztlcm00nl8e4dlh"
// );

const PostComponent = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const {
    data,
    key,
    getPostLists,
    showProfileBanners,
    getPostDetailss,
    newList,
    setNewList,
    isSubscribed,
    refreshData,
    showViewer,
    setShowViewer,
    setSelectedMedia,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const targetItemRef = useRef();
  const commentBoxRef = useRef();

  const userDetials = data?.userDetails;
  const media = data?.media;
  var currentPost = "";
  const baseUrl = window.location.protocol + "//" + window.location.host;

  const userId = useSelector((s) => s.setting)?.user?._id;
  const loginUserId = useSelector((s) => s.login?.user)?._id;
  const { allViewedPost } = useSelector((s) => s.post);
  const { isCardAdded } = useSelector((s) => s.card);
  const { user, isAuthenticated } = useSelector((s) => s.login);
  const isEmailVerified = useSelector((s) => s.setting?.user)?.isEmailVerified;

  const [msg, setMsg] = useState("");
  const [tagcreator, setTagcreator] = useState(false);
  const [showCommentPop, setCommentPop] = useState(false);
  const [showSendTip, setShowSendTip] = useState(false);
  const [selectedPostForTip, setSelectedPostForTip] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [artistDetailss, setArtistDetail] = useState({});
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState({});
  const [showAddList, setShowAddList] = useState(false);
  const [addpaymentmod, setaddpaymentmod] = useState(false);

  const handleCommentPop = () => setCommentPop(!showCommentPop);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleUserDetails = (dta) => {
    if (dta?.userDetails?._id) {
      const userDetails = dta?.userDetails;
      const path = generatePath("/:slug", {
        slug: userDetails?.userName,
      });
      navigate(path);
    }
  };

  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    if (refreshData) refreshData("like");
    if (newList) {
      const ddd = newList?.map((itm) => {
        if (itm?._id === dta?._id) {
          const dta = {
            ...itm,
            isLiked: true,
            likes: [...itm.likes, { userId: userId }],
          };
          return dta;
        } else return itm;
      });
      setNewList(ddd);
    }
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        if (newList) {
          const ddd = newList?.map((itm) => {
            if (itm?._id === dta?._id) {
              const filteredDta = newList((it) => it?._id !== dta?._id);
              const dta = { ...itm, isLiked: false, likes: [filteredDta] };
              return dta;
            } else return itm;
          });
          setNewList(ddd);
        }
      });
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    if (refreshData) refreshData("unlike");
    if (newList) {
      const ddd = newList?.map((itm) => {
        if (itm?._id === dta?._id) {
          const likesDta = itm?.likes?.filter((ii) => ii?.userId !== userId);
          const dta = { ...itm, isLiked: false, likes: likesDta };
          return dta;
        } else return itm;
      });
      setNewList(ddd);
    }
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {});
  };

  const handleBookmarkPost = (dta) => {
    const data = { postSlug: dta?.slug };
    if (refreshData) refreshData("bookmark");
    if (newList) {
      const ddd = newList?.map((itm) => {
        if (itm?._id === dta?._id) {
          const dta = { ...itm, isBookmarked: true };
          return dta;
        } else return itm;
      });
      setNewList(ddd);
    }
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        if (newList) {
          const ddd = newList?.map((itm) => {
            if (itm?._id === dta?._id) {
              const dta = { ...itm, isBookmarked: false };
              return dta;
            } else {
              return itm;
            }
          });
          setNewList(ddd);
        }
      });
  };

  const handleUnBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    if (refreshData) refreshData("unBookmark");
    if (newList) {
      const ddd = newList?.map((itm) => {
        if (itm?._id === dta?._id) {
          const dta = { ...itm, isBookmarked: false };
          return dta;
        } else {
          return itm;
        }
      });
      setNewList(ddd);
    }
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        if (newList) {
          const ddd = newList?.map((itm) => {
            if (itm?._id === dta?._id) {
              const dta = { ...itm, isBookmarked: true };
              return dta;
            } else {
              return itm;
            }
          });
          setNewList(ddd);
        }
      });
  };

  const sendMsg = (dta) => {
    if (msg === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: msg,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          if (refreshData) refreshData("comment");
          setMsg("");
          if (newList) {
            const ddd = newList?.map((itm) => {
              if (itm?._id === dta?._id) {
                const dta = { ...itm, comments: [...itm.comments, res?.data] };
                return dta;
              } else return itm;
            });
            setNewList(ddd);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setMsg("");
        });
    }
  };

  const handleKeyPress = (event, dta) => {
    if (event.key === "Enter") {
      sendMsg(dta);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  const clickOutside = (e) => {
    if (!commentBoxRef.current?.contains(e.target)) {
      setShowCommentBox(false);
    } else {
      setShowCommentBox(true);
    }
  };

  const handlePollSubmit = (poll, post) => {
    const data = {
      postId: post?._id,
      optionId: poll?._id,
    };
    dispatch(addPoll(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleQuizSubmit = (quiz, post) => {
    const data = {
      postId: post?._id,
      optionId: quiz?._id,
    };
    dispatch(addQuiz(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const restrictSelectedArtist = (dta) => {
    const data = {
      artistId: dta?._id,
    };
    dispatch(restirctArtist(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const blockSelectedArtist = (dta) => {
    const data = {
      blockedUserId: dta?._id,
    };
    dispatch(blockArtist(data))
      .unwrap()
      .then((res) => {
        toast.success("Entertainer has been blocked");
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      const targetItem = targetItemRef?.current;
      const targetItemId = targetItem?.id;

      const boundingBox = targetItem?.getBoundingClientRect();
      if (boundingBox?.top >= 0 && boundingBox?.bottom <= window?.innerHeight) {
        if (currentPost !== targetItem?.id) {
          if (!allViewedPost?.includes(targetItemId)) {
            const data = {
              postId: targetItemId,
              type: "post",
            };
            dispatch(postViewed(data))
              .unwrap()
              .then((res) => {
                dispatch(updateViewedPostList(targetItemId));
              })
              .catch((err) => {});
          }
        }
        currentPost = targetItem?.id;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTip = (dta) => {
    setSelectedPostForTip(dta);
    setShowSendTip(true);
  };

  const handleSubscribe = (artistDetailss, onSuccess) => {
    if (loginUserId) {
      if (userDetials?.subscribePrice > 0) {
        setShowPaymentModal(true);
      } else if (artistDetailss?.userDetials) {
        setArtistDetail(artistDetailss?.userDetials);
        const data = {
          followerId: artistDetailss?.userDetials?._id,
        };
        dispatch(subscribeArtist(data))
          .unwrap()
          .then((res) => {
            getPostDetailss();
            // getArtistDetails();
            // toast.success(res?.message);
            if (typeof onSuccess === "function") {
              onSuccess();
            }
          })
          .catch((err) => {
            toast.error(err?.message);
          });
      }
    } else {
      setArtistDetail(artistDetailss?.userDetials);
      setShowLoginModal(true);
    }
    // if (isAuthenticated && artistDetailss) {
    //   // if (isCardAdded) {
    //   const data = {
    //     followerId: artistDetailss?._id,
    //   };
    //   dispatch(subscribeArtist(data))
    //     .unwrap()
    //     .then((res) => {
    //       getPostDetailss();
    //       // getArtistDetails();
    //       // toast.success(res?.message);
    //       if (typeof onSuccess === "function") {
    //         onSuccess();
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error(err?.message);
    //     });
    //   // } else {
    //   //   setShowPaymentModal(true);
    //   // }
    // } else {
    //   // setShowLoginModal(true);
    // }
  };

  const handleAddOrRemoveFromList = (dta) => {
    setSelectedArtist(dta);
    setShowSaveToList(true);
  };

  const commonCall = () => {};

  function onSuccessAddCard() {
    const data = {
      followerId: artistDetailss?._id,
    };
    dispatch(subscribeArtist(data))
      .unwrap()
      .then((res) => {
        getPostDetailss();
        // getArtistDetails();
        // toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  }
  const onSubscribeSuccess = () => {
    dispatch(artistDetails(userDetials?._id));
    dispatch(getProfile());
  };

  return (
    <>
      <SubscriptionPayment
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        data={userDetials}
        onSubscribeSuccess={onSubscribeSuccess}
        followerId={userDetials?._id}
      />
      {/* {stripePromise && (
        <Elements stripe={stripePromise}> */}
      <Addpaymentmod
        show={addpaymentmod}
        onhide={() => setaddpaymentmod(false)}
        onSuccess={() => {
          onSuccessAddCard();
        }}
        hideBack
      />
      {/* </Elements>
      )} */}
      <Logintosubscribe
        show={showLoginModal}
        onhide={() => setShowLoginModal(false)}
        artistDetailss={artistDetailss}
      />
      <CommentSection
        showCommentPop={showCommentPop}
        handleCommentPop={handleCommentPop}
        commentBoxRef={commentBoxRef}
        msg={msg}
        setMsg={setMsg}
        setCommentPop={setCommentPop}
        data={data}
        handleKeyPress={handleKeyPress}
        sendMsg={sendMsg}
      />
      <SendTip
        show={showSendTip}
        onHide={() => setShowSendTip(false)}
        data={selectedPostForTip}
        postId={data?._id}
        type="postTip"
      />
      <div className="postCard" key={key}>
        {/* {data?.isPosted && ( */}
        <>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3 px-4 px-lg-0">
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                if (data?.userDetails?.userName) {
                  navigate(`/${data?.userDetails?.userName}`);
                }
              }}
            >
              {data?.userDetails?.profileImage ? (
                <div className="user_img width50user">
                  <img
                    src={data?.userDetails?.profileImage}
                    alt=""
                    className={`img-fluid logo `}
                  />
                </div>
              ) : (
                <div className="user_img_name shadow2">
                  {data?.userDetails?.firstName && data?.userDetails?.lastName
                    ? data?.userDetails?.firstName?.slice(0, 1) +
                      data?.userDetails?.lastName?.slice(0, 1)
                    : data?.userDetails?.displayName?.slice(0, 1) ||
                      data?.userDetails?.name?.slice(0, 1)}
                </div>
              )}

              <div className="mx-3 fansDetails">
                <h6 className="text-bold mb-0 ">
                  {data?.userDetails?.firstName
                    ? data?.userDetails?.firstName +
                      " " +
                      (data?.userDetails?.lastName || " ")
                    : data?.userDetails?.name}
                </h6>
                <h6 className="text-small mb-0">
                  @{data?.userDetails?.userName}
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="text-small mx-2 mb-0 days_ago">
                {getTimeAgo(data?.createdAt)}
              </h6>

              <div className="three_dot_dropdown">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                        <path
                          d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                          fill="currentColor"
                        />
                        <path
                          d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                          fill="currentColor"
                        />
                        <path
                          d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_87_10716">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="list-unstyled ps-0 mb-0">
                      <li>
                        <CopyToClipboard
                          text={baseUrl + "/post-details/" + data?.slug}
                          onCopy={() =>
                            toast.success(
                              t("Link to post has been copied to clipboard!")
                            )
                          }
                        >
                          <DropdownItem>
                            <span>{t("Copy Link to post")}</span>
                          </DropdownItem>
                        </CopyToClipboard>
                      </li>
                      <li>
                        <DropdownItem
                          onClick={() => {
                            if (loginUserId) {
                              handleAddOrRemoveFromList(data?.userDetails);
                            } else {
                              navigate(`/?link=/post-details/${data?.slug}`);
                            }
                          }}
                        >
                          <span>{t("Add / Remove from list")}</span>
                        </DropdownItem>
                      </li>
                    </ul>
                    <ul className="list-unstyled ps-0 mb-0">
                      {data?.isLiked ? (
                        <li>
                          <DropdownItem
                            onClick={() => {
                              if (loginUserId) {
                                handleUnLikePost(data);
                              } else {
                                navigate(`/?link=/post-details/${data?.slug}`);
                              }
                            }}
                          >
                            {t("I don't like this post")}
                          </DropdownItem>
                        </li>
                      ) : (
                        <li>
                          <DropdownItem
                            onClick={() => {
                              if (loginUserId) {
                                handleLikePost(data);
                              } else {
                                navigate(`/?link=/post-details/${data?.slug}`);
                              }
                            }}
                          >
                            {t("I like this post")}
                          </DropdownItem>
                        </li>
                      )}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className="PostQuiz shadow1 py-2 px-2 px-lg-2 mb-3">
            <div className="my-2 top">
              <h6 className="m-0 ">
                {data?.caption}
                {formatText(data?.description)}
              </h6>
            </div>
            {data?.postType === "quiz" && (
              <div className="my-2">
                {data?.quizOption?.map((option, index) => {
                  var totalValue = 0;
                  const quizValue = option?.quizCount || 0;
                  totalValue = totalValue + quizValue;
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative"
                    >
                      <input
                        type="radio"
                        className="position-absolute  w-100 h-100"
                        name="quiz"
                        checked={option?.isSelected}
                        id={option?.option}
                        onChange={() => handleQuizSubmit(option, data)}
                      />
                      <label
                        htmlFor={option?.option}
                        className=" label px-3 py-2 d-block  w-100 position-relative"
                      >
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            {data?.postType === "poll" && (
              <div className="mb-2">
                {data?.pollOption?.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative "
                    >
                      <input
                        type="radio"
                        className="position-absolute text-white  w-100 h-100"
                        name="poll"
                        id={option?.option}
                        onChange={() => handlePollSubmit(option, data)}
                        checked={option?.isSelected}
                      />
                      <label
                        className=" label px-3 py-2 text-white d-block  w-100 position-relative"
                        htmlFor={option?.option}
                      >
                        <span
                          className="fill position-absolute  w-100 h-100"
                          style={{ maxWidth: option?.option }}
                        ></span>
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {user?._id !== data?.userDetails?._id &&
          ((data?.type === "paid" && !data?.isBought) ||
            !isAuthenticated ||
            !isSubscribed) ? (
            <DummyPost
              type={data?.type}
              price={data?.price}
              data={data}
              isSubscribed={isSubscribed}
              getPostDetailss={getPostDetailss}
              handleSubscribe={handleSubscribe}
              // handleSubscribe={() => {
              //   if (loginUserId) {
              //     if (userDetials?.subscribePrice > 0) {
              //       setShowPaymentModal(true);
              //     } else if (data?.userDetials) {
              //       setArtistDetail(data?.userDetials);
              //       handleSubscribe(data?.userDetials);
              //     }
              //   } else {
              //     setArtistDetail(data?.userDetials);
              //     setShowLoginModal(true);
              //   }
              // }}
            />
          ) : (
            <>
              {isLoading ? (
                <div
                  className="skeleton skeleton-image"
                  style={{ height: "300px", width: "100%" }}
                ></div>
              ) : (
                <div className="NewsFeed" ref={targetItemRef} id={data?._id}>
                  <PostViewer
                    data={data}
                    media={media}
                    showViewer={showViewer}
                    setShowViewer={setShowViewer}
                    setSelectedMedia={setSelectedMedia}
                  />
                </div>
              )}
            </>
          )}

          {showProfileBanners && (
            <div className=" w-100 lg-px-2 my-1 position-relative mainPost">
              <img
                src={
                  userDetials?.coverImage ? userDetials?.coverImage : DummmyImg
                }
                alt=""
                className="rounded object-cover"
                style={{ height: "150px" }}
              />
              <div
                className="position-absolute postUserDetails pointer "
                onClick={() => handleUserDetails(data)}
              >
                {userDetials?.profileImage ? (
                  <div
                    className="user_img"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <img
                      src={userDetials?.profileImage}
                      alt=""
                      className="img-fluid "
                    />
                  </div>
                ) : (
                  <div
                    className="user_img_name shadow2"
                    style={{ height: "100%", width: "100%" }}
                  >
                    {userDetials?.firstName && userDetials?.lastName
                      ? userDetials?.firstName?.slice(0, 1) +
                        userDetials?.lastName?.slice(0, 1)
                      : userDetials?.displayName?.slice(0, 1) ||
                        userDetials?.name?.slice(0, 1)}
                  </div>
                )}
              </div>
              <div
                className="postUserText pointer"
                onClick={() => handleUserDetails(data)}
              >
                <div>
                  <h6 className="capitalize text-small ">
                    {data?.userDetails?.firstName
                      ? data?.userDetails?.firstName +
                        " " +
                        (data?.userDetails?.lastName || " ")
                      : data?.userDetails?.name}{" "}
                    <VscVerified
                      color="white"
                      size={23}
                      className="verifiedIcons mx-2 rounded-circle"
                    />
                  </h6>
                  <h6>@{userDetials?.userName}</h6>
                </div>
              </div>
              <Dropdown
                className="position-absolute"
                style={{ right: "10px", top: "10px" }}
              >
                <Dropdown.Toggle
                  variant="transaprent"
                  className="border-0 p-0"
                  id="dropdown-basic"
                >
                  <BiDotsVerticalRounded
                    color="white"
                    size={18}
                    className="threeDots"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <ul className="list-unstyled ps-0 mb-0">
                    <li>
                      <Dropdown.Item onClick={() => handleUserDetails(data)}>
                        {t("View Artist Details")}
                      </Dropdown.Item>
                    </li>
                    <li>
                      <CopyToClipboard
                        text={baseUrl + "/" + userDetials?.userName}
                      >
                        <Dropdown.Item>
                          <span>{t("Copy Link to profile")}</span>
                        </Dropdown.Item>
                      </CopyToClipboard>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {data?.tagUser?.length > 0 && (
            <>
              {/* <div>Tag Users</div> */}
              <div
                style={{
                  display: "flex",
                  padding: "0rem 0.8rem",
                  marginTop: "16px",
                  color: "#8a96a3",
                  fontWeight: 600,
                }}
                className="px-4 px-lg-0"
              >
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  onClick={() => setTagcreator(!tagcreator)}
                >
                  <span style={{ color: "#00aff0" }}>@ </span> Tags
                </div>
                <div
                  style={{ flexWrap: "wrap", width: "100%", display: "flex" }}
                >
                  {data?.tagUser?.map((val) => (
                    <div style={{ marginRight: "3px" }}>
                      {/* <img
                        src={val.profileImage}
                        alt="Profile"
                        onClick={() => navigate(`/${val?.userName}`)}
                        style={{
                          height: "24px",
                          width: "24px",
                          borderRadius: "50%",
                          marginRight: "3px",
                          cursor: "pointer",
                        }}
                      /> */}
                      <div className="username">@{val.userName}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="postaction_wrap my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative px-2 px-lg-0">
            {isEmailVerified ? (
              <div className="d-flex align-items-center postaction">
                <span className="pointer">
                  {data?.isLiked ? (
                    <img
                      src={FilledHeart}
                      className="actionIcon"
                      onClick={() => handleUnLikePost(data)}
                    />
                  ) : (
                    <img
                      src={Heart}
                      className="actionIcon"
                      onClick={() => handleLikePost(data)}
                    />
                  )}
                </span>
                <span
                  className="pointer tagcol"
                  onClick={() => handleCommentPop(data)}
                >
                  <img src={Comment} className="actionIcon" />
                </span>
                <span
                  className="pointer tagcol"
                  onClick={() => handleTip(data?.userDetails)}
                >
                  <img src={SendTipSVG} className="actionIcon" /> {"  SEND TIP"}
                </span>
              </div>
            ) : (
              <div className="d-flex align-items-center postaction">
                <span className="pointer">
                  <img src={DisableHeart} className="actionIcon" />
                </span>
                <span className="pointer tagcol">
                  <img src={DisableComment} className="actionIcon" />
                </span>
              </div>
            )}
            <div className="pointer">
              {data?.isBookmarked ? (
                <img
                  src={FilledBookmark}
                  className="actionIcon"
                  onClick={() => handleUnBookmarkPost(data)}
                />
              ) : (
                <img
                  src={Bookmark}
                  className="actionIcon"
                  onClick={() => handleBookmarkPost(data)}
                />
              )}
            </div>
          </div>
          <div className="cmt_wrap d-flex">
            <div className="light-text">
              <h6 className="text-muted m-0">
                {data?.likes?.length} {t("Likes")}
              </h6>
            </div>
            <div className="light-text mx-2">
              <h6 className="text-muted m-0">
                {data?.comments?.length} {t("comments")}
              </h6>
            </div>
          </div>
          <div className="my-4 endline w-100"></div>
        </>
        {/* )} */}
        <TagUserseList
          show={tagcreator}
          onhide={() => setTagcreator(false)}
          data={data?.tagUser}
        />

        <SaveToList
          show={showSaveToList}
          onHide={() => setShowSaveToList(false)}
          selectedArtist={selectedArtist}
          setShowAddList={setShowAddList}
          commonCall={commonCall}
        />
        <AddList
          show={showAddList}
          setShowAddList={setShowAddList}
          selectedArtist={selectedArtist}
          commonCall={commonCall}
        />
      </div>
    </>
  );
};

export default PostComponent;
