// This will be used for viewing the image and video;

import React from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactPlayer from "react-player";
import User4 from "../../../images/user10.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SmallLoader from "../ButtonLoader/SmallLoader";
import CustomVideoPlayer from "../../CustomVideoPlayer";

const StoryViewerModal = (props) => {
  const { mediaType, url, submitStory, setSelectedMedia } = props;
  const { buttonLoader } = useSelector((s) => s.post);
  const handleClose = () => {
    props.onHide();
    setSelectedMedia({});
  };
  const { t } = useTranslation();
  const mediaItem = {
    type: mediaType,
    url,
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="imgPostViewerpop"
    >
      <Modal.Body className="viewerCont bg-transparent">
        <div className="viewerUnder">
          {mediaType === "video" && (
            <CustomVideoPlayer mediaItem={mediaItem} />
            // <ReactPlayer
            //   config={{
            //     file: { attributes: { controlsList: "nodownload" } },
            //   }}
            //   onContextMenu={e => e.preventDefault()}
            //   url={url}
            //   width={"100%"}
            //   controls={true}
            //   volume={true}
            //   className="custom-player"
            // />
          )}
          {mediaType === "image" && (
            <img src={url} alt="" className="viewerImg" />
          )}
        </div>
        <AiOutlineCloseCircle
          className="closeIcons2 mx-auto"
          size={24}
          color="white"
          style={{ left: "0", right: "0" }}
          onClick={handleClose}
        />
        <div
          className="submitbtn position-absolute"
          style={{ top: "15px", right: "20px" }}
        >
          {buttonLoader ? (
            <button className="sendMsg">
              <SmallLoader />
            </button>
          ) : (
            <button className="sendMsg" onClick={() => submitStory()}>
              {t("Save")}
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StoryViewerModal;
