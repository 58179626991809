import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import User2 from "../../../images/user7.png";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bookmarkPost,
  commentPost,
  getPostList,
  likePost,
  unlikePost,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { BiSolidSend, BiSearch } from "react-icons/bi";

import { useTranslation } from "react-i18next";
import SendTip from "../../common/Modal/SendTip";
import PostComponent from "./PostComponent";

const Posts = (props) => {
  const { t } = useTranslation();
  const [showSendTip, setShowSendTip] = useState(false);

  const { postList, commonCall } = props;
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("all");
  const [allList, setAllList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const value = e.target.value.toLowerCase();
    const filterData = allList?.filter((itm) => {
      return itm?.caption?.toLowerCase().includes(value);
    });
    setAllList(filterData);
    if (e.target.value == "") {
      if (activeTab == "all") {
        setAllList(postList);
      } else if (activeTab === "photos") {
        showPhotos();
      } else if (activeTab === "videos") {
        showVideos();
      }
    }
  };

  const handleUserDetails = (dta) => {
    const userDetails = dta?.userDetails;
    const path = generatePath("/:slug", { slug: userDetails?.userName });
    navigate(path);
  };

  const getPostLists = () => {
    const data = {
      limit: "",
      orderBy: "createdAt",
      order: 1,
      page: 1,
    };
    dispatch(getPostList(data));
  };

  useEffect(() => {
    getPostLists();
  }, []);

  useEffect(() => {
    setAllList(postList);
  }, [postList]);

  const showAllPost = () => {
    setAllList(postList);
    setActiveTab("all");
  };

  const showPhotos = () => {
    const post = postList?.filter((item, index) => {
      if (item?.media?.[0]?.type === "image") {
        return item;
      }
    });
    setActiveTab("photos");
    setAllList(post);
  };

  const showVideos = () => {
    const media = postList?.filter((item, index) => {
      if (item?.media?.[0]?.type === "video") {
        return item;
      }
    });
    setActiveTab("videos");
    setAllList(media);
  };

  return (
    <div className="py-1">
      <div className="px-4">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h5 className="text-custom">{t("RECENT")}</h5>
          <h6 className="d-flex align-items-center">
            <div className="inputSearch">
              <input
                type="text"
                placeholder={t("Search...")}
                value={searchInput}
                onChange={(e) => handleSearch(e)}
              />
              <BiSearch color="#636363" size={20} />
            </div>
          </h6>
        </div>
        <div className="d-flex align-items-center usersTitle my-4">
          <h5
            className={`${activeTab == "all" && "activeTabs"}`}
            onClick={showAllPost}
          >
            {t("All")} {postList?.length}
          </h5>
          <h5
            className={`${activeTab == "photos" && "activeTabs"}`}
            onClick={showPhotos}
          >
            {t("Photos")}
          </h5>
          <h5
            className={`${activeTab == "videos" && "activeTabs"}`}
            onClick={showVideos}
          >
            {t("Videos")}
          </h5>
          <h5 className={`${activeTab == "Other" && "activeTabs"}`}>Other</h5>
          <h5 className={`${activeTab == "Locked" && "activeTabs"}`}>Locked</h5>
        </div>
        <div>
          {allList.map((data, index) => {
            return (
              <PostComponent
                data={data}
                showProfileBanners={true}
                handleUserDetails={handleUserDetails}
                getPostLists={getPostLists}
                showAllPost={showAllPost}
                setShowSendTip={setShowSendTip}
                commonCall={commonCall}
              />
            );
          })}
        </div>
      </div>
      <SendTip show={showSendTip} onHide={() => setShowSendTip(false)} />
    </div>
  );
};

export default Posts;
