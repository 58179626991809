import React, { useState } from "react";
import Settings from "./Settings";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import QRCode from "react-qr-code";
import User from "../../../images/user.svg";
import Logo from "../../../images/logoW.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const QRCodeComponent = () => {
  const [showAvtar, setShowAvtar] = useState(false);
  const [showUrl, setShowUrl] = useState(false);
  const { user } = useSelector((s) => s?.setting);
  const path = `https://fansroom.com/${user?.userName}`;
  const { t } = useTranslation();
  const handleUrl = () => {
    window.location.href = path;
  };

  return (
    <Settings>
      <section className="custom_order px-0 pb-3">
        <Form>
          <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
            <Link to="/home">
              <div className="notification_left_heading d-flex align-items-center">
                <h5>{t("QR Code")}</h5>
              </div>
            </Link>
          </div>

          <div className="privacy_safety mt-3 pt-3 border-top">
            <div className="qr_CodeIMg px-4">
              <p>
                {t("This QR code will lead to your profile page when scanned.")}
              </p>

              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 180,
                  width: "100%",
                }}
                className="mt-5 position-relative"
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={path}
                  viewBox={`0 0 256 256`}
                  level={"Q"}
                />
                <img
                  src={showAvtar ? user?.profileImage : Logo}
                  alt=""
                  className="img-fluid qrcodeImg"
                />
              </div>
              <div
                className="d-flex justify-content-center w-full mt-1 mb-5"
                style={{ height: "30px" }}
              >
                {showUrl && (
                  <a onClick={handleUrl} className="text-center m-auto pointer">
                    fansroom.com
                  </a>
                )}
              </div>
            </div>

            <div className="profile_toggle border-top border-bottom d-flex justify-content-between align-items-center px-4 py-3">
              <p>{t("Show profile URL")}</p>
              <div className="switches">
                <label class="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => setShowUrl(!showUrl)}
                    checked={showUrl}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div className="profile_toggle border-bottom d-flex justify-content-between align-items-center px-4 py-3">
              <p>{t("Show avatar")}</p>
              <div className="switches">
                <label class="switch">
                  <input
                    type="checkbox"
                    className="form-check"
                    onChange={(e) => setShowAvtar(!showAvtar)}
                    checked={showAvtar}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Settings>
  );
};

export default QRCodeComponent;
