import { useState } from "react";

export default function useWatermark() {
  const [marked, setMarked] = useState({});
  const add = (url, image) => {
    setMarked((prev) => ({ ...prev, [url]: image }));
  };

  return function watermark(url, text) {
    var img = new Image();

    img.crossOrigin = "anonymous";
    
    img.onload = () => {
      if (!url) {
        return;
      } else if (!marked?.[url]) {
        setTimeout(() => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0);
          const fontSize =
            parseInt(img.width / 25) > 10 ? parseInt(img.width / 25) : 10;

          ctx.font = fontSize + "px Arial";
          ctx.fillStyle = "rgba(218, 223, 225,0.8)";

          // Draw the text with a border
          // ctx.lineWidth = 1; // Border width
          // ctx.strokeStyle = "rgba(0, 0, 0,0.8)"; // Border color

          // Draw the text with a border
          // ctx.strokeText(text || "Your Watermark Text", 10, img.height - 10);
          ctx.fillText(text || "Your Watermark Text", 10, img.height - 10);

          add(url, canvas.toDataURL());
        }, 0);
      }
    };

    img.src = url;

    return marked?.[url] || url;
  };
}

// export default function useWatermark() {
//   const [marked, setMarked] = useState({});
//   const add = (url, image) => {
//     setMarked((prev) => ({ ...prev, [url]: image }));
//   };

//   return function watermark(imageTagOrString, text) {
//     var img = imageTagOrString;

//     function convert() {
//       const imageUrlInput = img?.src;
//       if (!imageUrlInput) {
//         return;
//       } else if (marked?.[imageUrlInput]) {
//         img.crossOrigin = "anonymous";
//         img.src = marked?.[imageUrlInput];
//       } else {
//         setTimeout(() => {
//           var canvas = document.createElement("canvas");
//           // Set canvas dimensions to match the image
//           canvas.width = img.width;
//           canvas.height = img.height;

//           var ctx = canvas.getContext("2d");

//           // Draw the original image on the canvas
//           ctx.drawImage(img, 0, 0);
//           const fontSize = parseInt(img.width / 30);
//           ctx.font = fontSize + "px Arial";
//           ctx.fillStyle = "rgba(0,0,0,0.3)"; // Adjust transparency as needed
//           ctx.fillText(text || "Your Watermark Text", 10, img.height - 10);

//           // ctx.fillStyle = "rgba(0, 0, 0, 0)";
//           // ctx.fillRect(0, 0, img.width, 500);
//           // ctx.textAlign = "center";

//           add(imageUrlInput, canvas.toDataURL());

//           // const ctx = canvas.getContext("2d");
//           // ctx.fillStyle = "rgba(0, 0, 0, 0)";
//           // ctx.fillRect(0, 0, 100, 100);
//           // ctx.font = "12px Arial";
//           // ctx.fillStyle = "red";
//           // ctx.textAlign = "center";
//           // ctx.fillText("Watermark", 50, 55);
//           // img.src=canvas.toDataURL();
//           // add(imageUrlInput, canvas.toDataURL());
//         }, 1000);
//       }
//     }
//     if (typeof imageTagOrString === "string") {
//       img = new Image();
//       img.src = imageTagOrString;
//       img.onload = convert;
//     } else {
//       convert();
//     }

//     return marked?.[imageUrlInput];
//   };
// }
