import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BsBookmark, BsFillSendFill, BsFillBookmarkFill } from "react-icons/bs";
import { Col, Container, Row } from "react-bootstrap";
import User1 from "../../../images/user1.svg";
import LogoW from "../../../images/logoW.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addPoll,
  addQuiz,
  bookmarkPost,
  commentPost,
  getPostDetails,
  likePost,
  postViewed,
  unlikePost,
} from "../../../redux/actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Post5 from "../../../images/post5.png";
import User5 from "../../../images/user3.png";
import { getTimeAgo } from "../../../helpers/time";
import User from "../../../images/user.png";
import { toast } from "react-toastify";
import { generatePath, useNavigate } from "react-router-dom";
import { BiSolidSend } from "react-icons/bi";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { updateViewedPostList } from "../../../redux/states/post/slice";
import DummyPost from "../DummyPost";
import CommentSection from "../CommentSection/CommentSection";
import PostViewer from "../PostViewer/PostViewer";
import SendTip from "../Modal/SendTip";
import Heart from "../../../images/heart.svg";
import FilledHeart from "../../../images/filledHeart.svg";
import Bookmark from "../../../images/bookmark.svg";
import FilledBookmark from "../../../images/filledBookmark.svg";
import Tip from "../../../images/dollar.svg";
import Comment from "../../../images/comment.svg";
import DisableHeart from "../../../images/disableHeart.svg";
import DisableComment from "../../../images/disableComment.svg";
import DisableTip from "../../../images/disableDollar.svg";
import { formatText } from "../../../helpers/formatPostText";

const PostCard = (props) => {
  const { t } = useTranslation();
  const commentBoxRef = useRef();
  const {
    data,
    key,
    getArtistPostList,
    getArtistDetails,
    isSubscribed,
    newList,
    handleSubscribe,
    setNewList,
  } = props;

  const userDetials = data?.userDetails;
  const media = data?.media;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  var currentPost = "";
  const { allViewedPost } = useSelector((s) => s.post);

  const userId = useSelector((s) => s.setting)?.user?._id;

  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const dta = {
          ...itm,
          isLiked: true,
          likes: [...itm?.likes, { userId: userId }],
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        getArtistDetails();
      })
      .catch((err) => {
        if (newList) {
          const ddd = newList?.map((itm) => {
            if (itm?._id === dta?._id) {
              const filteredDta = newList((it) => it?._id !== dta?._id);
              const dta = { ...itm, isLiked: false, likes: filteredDta };
              return dta;
            } else return itm;
          });
          setNewList(ddd);
        }
      });
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };

    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const filterDta = itm?.likes?.filter((it) => it?.userId !== userId);
        const dta = {
          ...itm,
          isLiked: false,
          likes: filterDta,
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {});
  };
  const handleBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const dta = {
          ...itm,
          isBookmarked: true,
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        const ddd = newList?.map((itm) => {
          if (itm?._id === dta?._id) {
            const dta = {
              ...itm,
              isBookmarked: false,
            };
            return dta;
          } else return itm;
        });
        setNewList(ddd);
      });
  };

  const handleUnBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const dta = {
          ...itm,
          isBookmarked: false,
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        const ddd = newList?.map((itm) => {
          if (itm?._id === dta?._id) {
            const dta = {
              ...itm,
              isBookmarked: true,
            };
            return dta;
          } else return itm;
        });
        setNewList(ddd);
      });
  };

  var isLiked = false;
  var isBookmarked = false;

  const bookmarkdta = data?.bookmarks?.find((item, id) => {
    return item?.userId == userId;
  });

  const { isAuthenticated } = useSelector((s) => s.login);

  const userDta = data?.likes?.find((item, id) => {
    return item?.userId == userId;
  });

  if (userDta?.userId) {
    isLiked = true;
  } else {
    isLiked = false;
  }

  if (bookmarkdta?.userId) {
    isBookmarked = true;
  } else {
    isBookmarked = false;
  }

  const sendMsg = (dta) => {
    if (msg === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: msg,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          setMsg("");
          if (newList) {
            const ddd = newList?.map((itm) => {
              if (itm?._id === dta?._id) {
                // const dta = {...itm , comments: [res?.data, ...itm.comments  ], };
                var commnetArray = [...itm?.comments];
                commnetArray.unshift(res?.data);
                const dta = {
                  ...itm,
                  comments: commnetArray,
                };
                return dta;
              } else return itm;
            });
            setNewList(ddd);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setMsg("");
        });
    }
  };

  const handleKeyPress = (event, dta) => {
    if (event.key === "Enter") {
      sendMsg(dta);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });

  const clickOutside = (e) => {
    if (!commentBoxRef.current?.contains(e.target)) {
      setShowCommentBox(false);
    } else {
      setShowCommentBox(true);
    }
  };

  const baseUrl = window.location.protocol + "//" + window.location.host;

  const handlePollSubmit = (poll, post) => {
    const data = {
      postId: post?._id,
      optionId: poll?._id,
    };
    dispatch(addPoll(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getArtistPostList();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleQuizSubmit = (quiz, post) => {
    const data = {
      postId: post?._id,
      optionId: quiz?._id,
    };
    dispatch(addQuiz(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getArtistPostList();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  // here we are writing the code for viewing the post ;
  const targetItemRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const targetItem = targetItemRef.current;
      const targetItemId = targetItem?.id;

      const boundingBox = targetItem.getBoundingClientRect();
      if (boundingBox.top >= 0 && boundingBox.bottom <= window.innerHeight) {
        if (currentPost !== targetItem?.id) {
          if (!allViewedPost.includes(targetItemId)) {
            const data = {
              postId: targetItemId,
              type: "post",
            };
            dispatch(postViewed(data))
              .unwrap()
              .then((res) => {
                dispatch(updateViewedPostList(targetItemId));
              })
              .catch((err) => {});
          }
        }
        currentPost = targetItem?.id;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showCommentPop, setCommentPop] = useState(false);

  const handleCommentPop = () => {
    setCommentPop(!showCommentPop);
  };
  const [showSendTip, setShowSendTip] = useState(false);
  const [selectedPostForTip, setSelectedPostForTip] = useState({});

  const handleTip = (dta) => {
    setSelectedPostForTip(dta);
    setShowSendTip(true);
  };
  const isEmailVerified = useSelector((s) => s.setting?.user)?.isEmailVerified;

  return (
    <div key={key}>
      <CommentSection
        showCommentPop={showCommentPop}
        handleCommentPop={handleCommentPop}
        commentBoxRef={commentBoxRef}
        msg={msg}
        setMsg={setMsg}
        data={data}
        handleKeyPress={handleKeyPress}
        sendMsg={sendMsg}
      />
      <SendTip
        show={showSendTip}
        onHide={() => setShowSendTip(false)}
        data={selectedPostForTip}
      />
      {data && (
        <>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3 px-lg-0 px-4">
            <div className="d-flex align-items-center">
              <img
                src={data?.userDetails?.profileImage || User}
                alt=""
                className="postUserPic"
              />
              <div className="mx-3 fansDetails">
                <h6 className="text-bold mb-0 ">
                  {data?.userDetails?.firstName
                    ? data?.userDetails?.firstName +
                      " " +
                      (data?.userDetails?.lastName || " ")
                    : data?.userDetails?.name}
                </h6>
                <h6 className="text-small mb-0">
                  {data?.userDetails?.userName}
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="text-small mx-2 mb-0 days_ago">
                {getTimeAgo(data?.createdAt)}
              </h6>
              <div className="three_dot_dropdown">
                <UncontrolledDropdown>
                  <DropdownToggle className="text-dark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                        <path
                          d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                          fill="black"
                        />
                        <path
                          d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                          fill="black"
                        />
                        <path
                          d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_87_10716">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <CopyToClipboard
                      text={baseUrl + "/post-details/" + data?.slug}
                      onCopy={() =>
                        toast.success(
                          t("Link to post has been copied to clipboard!")
                        )
                      }
                    >
                      <DropdownItem>
                        <span>{t("Copy Link to post")}</span>
                      </DropdownItem>
                    </CopyToClipboard>
                    {/* <DropdownItem>Add to / Remove from List</DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className="PostQuiz shadow1 py-3 px-4 px-lg-0 mb-3">
            <div className="my-2 top">
              <h6 className="m-0 fw-bold">
                {data?.caption}
                {formatText(data?.description)}
              </h6>
            </div>
            {data?.postType === "quiz" && (
              <div className="my-2">
                {data?.quizOption?.map((option, index) => {
                  var totalValue = 0;
                  const quizValue = option?.quizCount || 0;
                  totalValue = totalValue + quizValue;
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative"
                    >
                      <input
                        type="radio"
                        className="position-absolute  w-100 h-100"
                        name="quiz"
                        checked={option?.isSelected}
                        id={option?.option}
                        onChange={() => handleQuizSubmit(option, data)}
                      />
                      <label
                        htmlFor={option?.option}
                        className=" label px-3 py-2 d-block  w-100 position-relative"
                      >
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            {data?.postType === "poll" && (
              <div className="mb-2">
                {data?.pollOption?.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative "
                    >
                      <input
                        type="radio"
                        className="position-absolute text-white  w-100 h-100"
                        name="poll"
                        id={option?.option}
                        onChange={() => handlePollSubmit(option, data)}
                        checked={option?.isSelected}
                      />
                      <label
                        className=" label px-3 py-2 text-white d-block  w-100 position-relative"
                        htmlFor={option?.option}
                      >
                        <span
                          className="fill position-absolute  w-100 h-100"
                          style={{ maxWidth: option?.option }}
                        ></span>
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="GallaryMedia" ref={targetItemRef} id={data?._id}>
            {data?.userId !== userId &&
            ((data?.type === "paid" && !data?.isBought) ||
              !isAuthenticated ||
              !isSubscribed) ? (
              <DummyPost
                handleSubscribe={handleSubscribe}
                type={data?.type}
                price={data?.price}
                data={data}
                isSubscribed={isSubscribed}
              />
            ) : (
              <PostViewer data={data} media={data?.media} />
            )}
          </div>

          <div className="px-4 px-lg-0">
          {data?.tagUser?.length > 0 && (
              <div style={{ display: "flex",marginTop: "16px",justifyContent:"end"  }}>
                {data?.tagUser?.map((val) => (
                  <div style={{ display: "flex" }}>
                    <img
                      src={val.profileImage}
                      alt="Profile"
                      onClick={() => navigate(`/${val?.userName}`)}
                      style={{
                        height: "24px",
                        width: "24px",
                        borderRadius: "50%",
                        marginRight: "3px",
                        cursor: "pointer",
                      }}
                    />
                    {/* <div className="username">@{val.userName}</div> */}
                  </div>
                ))}
              </div>
            )}
            </div>

          <div className="my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative px-4 px-lg-0">
            {isEmailVerified ? (
              <div className="d-flex align-items-center postaction">
                <span className="pointer">
                  {data?.isLiked ? (
                    <img
                      src={FilledHeart}
                      className="actionIcon"
                      onClick={() => handleUnLikePost(data)}
                    />
                  ) : (
                    <img
                      src={Heart}
                      className="actionIcon"
                      onClick={() => handleLikePost(data)}
                    />
                  )}
                </span>
                <span
                  className="pointer tagcol"
                  onClick={() => handleCommentPop(data)}
                >
                  <img src={Comment} className="actionIcon" />
                </span>
                {/* 
                <span className="pointer" onClick={() => handleTip(data)}>
                  <img src={Tip} className="actionIcon" />
                </span>
                <span className="text-muted">{t("SEND TIPS")} </span> */}
              </div>
            ) : (
              <div className="d-flex align-items-center postaction">
                <span className="pointer">
                  <img src={DisableHeart} className="actionIcon" />
                </span>
                <span className="pointer tagcol">
                  <img src={DisableComment} className="actionIcon" />
                </span>

                {/* <span className="pointer">
                  <img src={DisableTip} className="actionIcon" />
                </span>
                <span className="text-muted">{t("SEND TIPS")} </span> */}
              </div>
            )}
            <div className="">
              {data?.isBookmarked ? (
                <img
                  src={FilledBookmark}
                  className="actionIcon"
                  onClick={() => handleUnBookmarkPost(data)}
                />
              ) : (
                <img
                  src={Bookmark}
                  className="actionIcon"
                  onClick={() => handleBookmarkPost(data)}
                />
              )}
            </div>
          </div>

          <div className="d-flex px-4 px-lg-0">
            <div className="light-text">
              {data?.likes?.length} {t("Likes")}
            </div>
            <div className="light-text mx-2">
              {data?.comments?.length} {t("comments")}
            </div>
          </div>
          <div className="my-4 endline w-100"></div>
        </>
      )}
    </div>
  );
};

export default PostCard;
