import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const addCardData = createAsyncThunk(
  "addCard",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.addCard, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getCardList = createAsyncThunk(
  "cardList",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.common.cardList);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const sendPostTipAmount = createAsyncThunk(
  "sendPostTipAmount",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.sendTip, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const sendTipToProfile = createAsyncThunk(
  "sendTipToProfile",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.sendTipToProfile,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const connectStripe = createAsyncThunk(
  "connectStripe",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(
        endpoints.common.connectStripe + payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const deleteCard = createAsyncThunk(
  "deleteCard",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.deleteCard, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const transactionList = createAsyncThunk(
  "transactionList",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.common.transactionList);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const unlockPost = createAsyncThunk(
  "unlockPost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.unlockPost, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const unlockMessage = createAsyncThunk(
  "unlockPost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.unlockMessage,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const addW9Form = createAsyncThunk(
  "addW9Form",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.addW9FormData,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const addMoneyToWallet = createAsyncThunk(
  "addMoneyToWallet",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.addMoneyToWallet,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const disconnectStripe = createAsyncThunk(
  "disconnectStripe",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.disconnectStripe,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const withDrawMoneyFromWallet = createAsyncThunk(
  "withDrawMoneyFromWallet",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.withdrawMoneyFromWallet,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const bankAccountList = createAsyncThunk(
  "bankAccountList",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.common.bankAccountList);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const renewSubscription = createAsyncThunk(
  "renewSubscription",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.common.renewSubscription,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
