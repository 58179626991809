import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/eng/translation.json";
import translationAr from "./locales/ar/translation.json";
import translationUR from "./locales/ur/translation.json";
import translationHn from "./locales/hn/translation.json";
import translationChn from "./locales/chn/translation.json";
import translationSpn from "./locales/spn/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAr,
  },
  ur: {
    translation: translationUR,
  },
  hn: {
    translation: translationHn,
  },
  chn: {
    translation: translationChn,
  },
  spn: {
    translation: translationSpn,
  },
};

let language;

if (typeof window !== "undefined") {
  language = localStorage.getItem("I18N_LANGUAGE");

  if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "en");
    language = "en";
  }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
