import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AuthFooter from "../../../components/Footer";
import Logo from "../../../images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { endpoints } from "../../../endpoints";
import { REACT_APP_API_BASE_URL, REACT_APP_API_VERSION } from "../../../config";
import parse from "html-react-parser";

function Terms() {
  
  const navigate = useNavigate();
  const API_URL = REACT_APP_API_BASE_URL;
  const API_VERSION = REACT_APP_API_VERSION;
  const baseURL = API_URL + API_VERSION;

  const [data, setData] = useState("");

  const getTermsCondition = () => {
    const url = fetch(baseURL + endpoints.common.termsCondition, {
      method: "GET",
    })
      .then((res) => res.text())
      .then((result) => {
        setData(result);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTermsCondition();
  }, []);

  return (
    <section className="CookiesPolicy privay_poilicy position-relative p-0">
      <div className="header_only_logo py-3 px-4">
        <div className="logo_box">
          <a href="/">
            <img src={Logo} alt="" className="img-fluid" />
          </a>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col lg="12" className="my-2 sticky-top bg-white">
            <div className="top border-bottom pb-2">
              <h4 className="m-0 py-3 fw-bold">Terms and Conditions</h4>
            </div>
          </Col>
          <Col lg="12" className="my-2">
            <div className="py-4 content_pages_text">{data && parse(data)}</div>
          </Col>
        </Row>
        <AuthFooter />
      </Container>
    </section>
  );
}

export default Terms;
