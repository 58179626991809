import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import AllPostListModal from "../../common/Modal/EntertainerModal/AllPostListModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addPostToPrivateArchive,
  archivePostList,
  restorePost,
} from "../../../redux/actions";
import ArchivePostComponent from "./ArchivePostComponent";
import { useTranslation } from "react-i18next";

const PrivateArchive = (props) => {
  const { t } = useTranslation();
  const { commonFunction, getPrivateArchivePostList } = props;
  const dispatch = useDispatch();
  const [showPostModal, setShowPostModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { list } = useSelector((s) => s.postLabel);
  const { user } = useSelector((s) => s.setting);
  const userId = user?._id;

  const handleAddPost = () => {
    const data = {
      postId: selectedItems,
    };
    dispatch(addPostToPrivateArchive(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPrivateArchivePostList();
        setShowPostModal(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getPrivateArchivePostList();
  }, []);

  const restoryPostToList = (dta) => {
    const data = {
      postId: dta?._id,
    };
    dispatch(restorePost(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPrivateArchivePostList();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <section className="custom_order px-0 pb-3">
      {/* topheaderbar */}
      <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
        <Link to="/home">
          <div className="notification_left_heading d-flex align-items-center">
            <span className="me-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
              >
                <path
                  d="M1 7L7 1M1 7L7 13M1 7L15 7"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <h5>{props.title}</h5>
          </div>
        </Link>

        <div className="collection_header d-flex align-items-center gap-3">
          <div className="three_dot_btnvar">
            <UncontrolledDropdown>
              <DropdownToggle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <g opacity="0.5" clip-path="url(#clip0_711_1706)">
                    <path
                      d="M8.99998 14C7.89541 14 6.99997 14.8954 6.99997 16C6.99997 17.1046 7.89541 18 8.99998 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 8.99998 14Z"
                      fill="black"
                    />
                    <path
                      d="M8.99998 6.99997C7.89541 6.99997 6.99997 7.89541 6.99997 8.99998C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 8.99998 6.99997Z"
                      fill="black"
                    />
                    <path
                      d="M8.99998 -3.21229e-05C7.89541 -3.22195e-05 6.99997 0.895405 6.99997 1.99998C6.99997 3.10456 7.89541 4 8.99998 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 8.99998 -3.21229e-05Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_711_1706">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(18 18) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
      {/* topheaderbar */}

      <div className="Achrive_part px-5 mt-5">
        <Button
          className="achrive_btn cursor"
          onClick={() => setShowPostModal(true)}
        >
          +
        </Button>
        <Row className="align-items-center align-middle position-relative">
          {Array.isArray(list) && list.length === 0 ? (
            <div className="no_bookmarks_yet text-center h-100 vh-100 mt-5">
              <img
                src="/images/empty-labels.png"
                alt=""
                className="img-fluid"
              />
              <p>{t("There are no posts assigned to this label")}</p>
            </div>
          ) : (
            <div className="NewsFeed">
              {list?.map((item, index) => {
                return (
                  <ArchivePostComponent
                    key={index}
                    data={item}
                    screen="privateArchive"
                    restorePost={restoryPostToList}
                    getPrivateArchivePostList={getPrivateArchivePostList}
                    commonFunction={commonFunction}
                  />
                );
              })}
            </div>
          )}
        </Row>
      </div>
      <AllPostListModal
        show={showPostModal}
        onhide={() => setShowPostModal(false)}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        handleAddPost={handleAddPost}
      />
    </section>
  );
};

export default PrivateArchive;
