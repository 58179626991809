import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const { user, isAuthenticated } = useSelector((state) => state.login) ?? {};
  const token = user?.token;
  if (!token) {
    return <Navigate to="/" />;
  }
  return children;
};

export default PrivateRoute;
