import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const addPostToArchive = createAsyncThunk(
  "addPostToArchive",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.postLabel.addPostToArchive,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const addPostToPrivateArchive = createAsyncThunk(
  "addPostToPrivateArchive",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.postLabel.addPostToPrivateArchive,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const archivePostList = createAsyncThunk(
  "archivePostList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.postLabel.archivePostList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const restorePost = createAsyncThunk(
  "restorePost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.postLabel.restorePost,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
