import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const chatDetails = createAsyncThunk("chat", async (payload, Thunk) => {
  try {
    const response = await axios.get(endpoints.messages.chatDetails + payload);
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});

export const chatHistory = createAsyncThunk(
  "chat/history",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.messages.messageHistory,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const chatList = createAsyncThunk(
  "chat/list",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.messages.chatList, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const deleteChat = createAsyncThunk(
  "chat/delete",
  async (payload, Thunk) => {
    try {
      const response = await axios.delete(
        endpoints.messages.deleteChat + payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const sendMassMessage = createAsyncThunk(
  "chat/massMessage",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.messages.massMessage,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const massMessageListing = createAsyncThunk(
  "chat/massMessageListing",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.messages.massMessageList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
