import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  createSheduleDate,
  updateSaveForLater,
} from "../../../redux/states/post/slice";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Calendermod(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment(new Date()).format("l"));
  const [saveforlaterValue, setSaveforlaterValue] = useState(6);
  const { selectedDate, updateDate } = props;
  const [time, setTime] = useState(
    moment(new Date().getTime()).format("HH:mm")
  );

  const navigate = useNavigate();
  const [calendertab, setcalendertab] = useState("Scheduled");

  const handleTabSelectedtion = (tabs) => {
    setcalendertab(tabs);
  };
  const handleDateChange = (value) => {
    const val = new Date(value);
    if (updateDate) {
      updateDate(val);
    }
    setDate(val);
  };

  const handleTimeChange = (e) => {
    const val = e.target.value;
    setTime(val);
  };

  var tt = new Date().getTime();
  tt = moment(tt).format("HH:mm");

  const SaveThePost = () => {
    if (!date) {
      toast.warning("Please select date");
    } else if (!time) {
      toast.warning("Please select time");
    } else {
      const data = {
        date: moment(date).format("YYYY-MM-DD"),
        time: time,
      };
      dispatch(createSheduleDate(data));
      navigate("/new-post");
    }
  };

  const saveForLater = () => {
    dispatch(updateSaveForLater(saveforlaterValue));
    navigate("/new-post");
  };

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    }
  }, [selectedDate]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="calender_mod"
    >
      <Modal.Body>
        <div className="calednder_Mod pb-3">
          <div className="add_heading d-flex px-4 pt-4 justify-content-between">
            <h5>{t("Add To Queue")}</h5>
            <a href="javascript:void(0)" onClick={props.onhide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </a>
          </div>

          <div className="calender_tabbing">
            <div className="CardBody pb-3 pt-2 commonTabs calender_tabs">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Users"
                className="d-flex justify-content-between align-items-center"
              >
                <Nav variant="pills" className="">
                  <Nav.Item className="">
                    <Nav.Link
                      className={`bg-transparent border-0 position-relative text-small ${
                        calendertab == "Scheduled" && "active"
                      }`}
                      eventKey="Scheduled"
                      onClick={() => handleTabSelectedtion("Scheduled")}
                      active={calendertab === "Scheduled"}
                    >
                      {t("Scheduled")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="">
                    <Nav.Link
                      className="bg-transparent border-0 position-relative text-small "
                      eventKey="SaveForLater"
                      onClick={() => handleTabSelectedtion("SaveForLater")}
                      active={calendertab === "SaveForLater"}
                    >
                      {t("Save For Later")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>

            <Tab.Content className="">
              <Tab.Pane
                eventKey="Scheduled"
                active={calendertab === "Scheduled"}
              >
                <div className="Scheduled_tab px-4">
                  <p>
                    {t(
                      "Please choose a date and time for your action to be executed."
                    )}
                  </p>

                  <Form className="calender_form">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          {/* <Input
                            type="date"
                            name="email"
                            id="exampleEmail"
                            value={moment(date).format("YYYY-MM-DD")}
                            onChange={handleDateChange}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                          /> */}
                          <DatePicker
                            minDate={date}
                            placeholderText="mm/dd/yyyy"
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            wrapperClassName="addQueueDatepickerWrapper"
                            selected={date}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={6}>
                        <FormGroup>
                          <Input
                            type="time"
                            name="time"
                            id="exampleEmail"
                            value={time}
                            onChange={(e) =>
                              moment(e.target.value, "HH:mm").isAfter(
                                moment(tt, "HH:mm")
                              )
                                ? handleTimeChange(e)
                                : toast.warning(t("Select Time in Future"))
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <div className="py-2">
                    <Button
                      className="Scheduled_btn mt-3"
                      onClick={SaveThePost}
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_722_3200)">
                            <path
                              d="M4.65381 21.4712C4.47153 21.471 4.29677 21.3985 4.16787 21.2696C4.03898 21.1407 3.96649 20.966 3.96631 20.7837V17.3462H1.90381C0.766684 17.3462 -0.158691 16.4208 -0.158691 15.2837V4.28369C-0.158691 3.14657 0.766684 2.22119 1.90381 2.22119H18.4038C19.5409 2.22119 20.4663 3.14657 20.4663 4.28369V15.2837C20.4663 16.4208 19.5409 17.3462 18.4038 17.3462H10.0514L5.0835 21.3206C4.96158 21.4183 4.81 21.4714 4.65381 21.4712ZM1.90381 3.59619C1.52431 3.59619 1.21631 3.90488 1.21631 4.28369V15.2837C1.21631 15.6625 1.52431 15.9712 1.90381 15.9712H4.65381C4.83614 15.9712 5.01101 16.0436 5.13994 16.1726C5.26888 16.3015 5.34131 16.4764 5.34131 16.6587V19.3537L9.38037 16.1218C9.50234 16.0242 9.65389 15.9711 9.81006 15.9712H18.4038C18.7833 15.9712 19.0913 15.6625 19.0913 15.2837V4.28369C19.0913 3.90488 18.7833 3.59619 18.4038 3.59619H1.90381Z"
                              fill="black"
                            />
                            <path
                              d="M15.6538 9.09619H4.65381C4.47147 9.09619 4.2966 9.02376 4.16767 8.89483C4.03874 8.7659 3.96631 8.59103 3.96631 8.40869C3.96631 8.22636 4.03874 8.05149 4.16767 7.92256C4.2966 7.79362 4.47147 7.72119 4.65381 7.72119H15.6538C15.8361 7.72119 16.011 7.79362 16.1399 7.92256C16.2689 8.05149 16.3413 8.22636 16.3413 8.40869C16.3413 8.59103 16.2689 8.7659 16.1399 8.89483C16.011 9.02376 15.8361 9.09619 15.6538 9.09619ZM10.1538 11.8462H4.65381C4.47147 11.8462 4.2966 11.7738 4.16767 11.6448C4.03874 11.5159 3.96631 11.341 3.96631 11.1587C3.96631 10.9764 4.03874 10.8015 4.16767 10.6726C4.2966 10.5436 4.47147 10.4712 4.65381 10.4712H10.1538C10.3361 10.4712 10.511 10.5436 10.6399 10.6726C10.7689 10.8015 10.8413 10.9764 10.8413 11.1587C10.8413 11.341 10.7689 11.5159 10.6399 11.6448C10.511 11.7738 10.3361 11.8462 10.1538 11.8462Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_722_3200">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Post
                    </Button>
                  </div>
                  <div className="py-2">
                    <Link
                      to="/messages/new"
                      className="Scheduled_btn d-flex align-items-center justify-content-center rounded-pill border"
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_722_3200)">
                            <path
                              d="M4.65381 21.4712C4.47153 21.471 4.29677 21.3985 4.16787 21.2696C4.03898 21.1407 3.96649 20.966 3.96631 20.7837V17.3462H1.90381C0.766684 17.3462 -0.158691 16.4208 -0.158691 15.2837V4.28369C-0.158691 3.14657 0.766684 2.22119 1.90381 2.22119H18.4038C19.5409 2.22119 20.4663 3.14657 20.4663 4.28369V15.2837C20.4663 16.4208 19.5409 17.3462 18.4038 17.3462H10.0514L5.0835 21.3206C4.96158 21.4183 4.81 21.4714 4.65381 21.4712ZM1.90381 3.59619C1.52431 3.59619 1.21631 3.90488 1.21631 4.28369V15.2837C1.21631 15.6625 1.52431 15.9712 1.90381 15.9712H4.65381C4.83614 15.9712 5.01101 16.0436 5.13994 16.1726C5.26888 16.3015 5.34131 16.4764 5.34131 16.6587V19.3537L9.38037 16.1218C9.50234 16.0242 9.65389 15.9711 9.81006 15.9712H18.4038C18.7833 15.9712 19.0913 15.6625 19.0913 15.2837V4.28369C19.0913 3.90488 18.7833 3.59619 18.4038 3.59619H1.90381Z"
                              fill="black"
                            />
                            <path
                              d="M15.6538 9.09619H4.65381C4.47147 9.09619 4.2966 9.02376 4.16767 8.89483C4.03874 8.7659 3.96631 8.59103 3.96631 8.40869C3.96631 8.22636 4.03874 8.05149 4.16767 7.92256C4.2966 7.79362 4.47147 7.72119 4.65381 7.72119H15.6538C15.8361 7.72119 16.011 7.79362 16.1399 7.92256C16.2689 8.05149 16.3413 8.22636 16.3413 8.40869C16.3413 8.59103 16.2689 8.7659 16.1399 8.89483C16.011 9.02376 15.8361 9.09619 15.6538 9.09619ZM10.1538 11.8462H4.65381C4.47147 11.8462 4.2966 11.7738 4.16767 11.6448C4.03874 11.5159 3.96631 11.341 3.96631 11.1587C3.96631 10.9764 4.03874 10.8015 4.16767 10.6726C4.2966 10.5436 4.47147 10.4712 4.65381 10.4712H10.1538C10.3361 10.4712 10.511 10.5436 10.6399 10.6726C10.7689 10.8015 10.8413 10.9764 10.8413 11.1587C10.8413 11.341 10.7689 11.5159 10.6399 11.6448C10.511 11.7738 10.3361 11.8462 10.1538 11.8462Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_722_3200">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Mass Message
                    </Link>
                  </div>

                  {/* <Button className="Scheduled_btn mt-3" onClick={SaveThePost}>
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_722_3200)">
                          <path
                            d="M4.65381 21.4712C4.47153 21.471 4.29677 21.3985 4.16787 21.2696C4.03898 21.1407 3.96649 20.966 3.96631 20.7837V17.3462H1.90381C0.766684 17.3462 -0.158691 16.4208 -0.158691 15.2837V4.28369C-0.158691 3.14657 0.766684 2.22119 1.90381 2.22119H18.4038C19.5409 2.22119 20.4663 3.14657 20.4663 4.28369V15.2837C20.4663 16.4208 19.5409 17.3462 18.4038 17.3462H10.0514L5.0835 21.3206C4.96158 21.4183 4.81 21.4714 4.65381 21.4712ZM1.90381 3.59619C1.52431 3.59619 1.21631 3.90488 1.21631 4.28369V15.2837C1.21631 15.6625 1.52431 15.9712 1.90381 15.9712H4.65381C4.83614 15.9712 5.01101 16.0436 5.13994 16.1726C5.26888 16.3015 5.34131 16.4764 5.34131 16.6587V19.3537L9.38037 16.1218C9.50234 16.0242 9.65389 15.9711 9.81006 15.9712H18.4038C18.7833 15.9712 19.0913 15.6625 19.0913 15.2837V4.28369C19.0913 3.90488 18.7833 3.59619 18.4038 3.59619H1.90381Z"
                            fill="black"
                          />
                          <path
                            d="M15.6538 9.09619H4.65381C4.47147 9.09619 4.2966 9.02376 4.16767 8.89483C4.03874 8.7659 3.96631 8.59103 3.96631 8.40869C3.96631 8.22636 4.03874 8.05149 4.16767 7.92256C4.2966 7.79362 4.47147 7.72119 4.65381 7.72119H15.6538C15.8361 7.72119 16.011 7.79362 16.1399 7.92256C16.2689 8.05149 16.3413 8.22636 16.3413 8.40869C16.3413 8.59103 16.2689 8.7659 16.1399 8.89483C16.011 9.02376 15.8361 9.09619 15.6538 9.09619ZM10.1538 11.8462H4.65381C4.47147 11.8462 4.2966 11.7738 4.16767 11.6448C4.03874 11.5159 3.96631 11.341 3.96631 11.1587C3.96631 10.9764 4.03874 10.8015 4.16767 10.6726C4.2966 10.5436 4.47147 10.4712 4.65381 10.4712H10.1538C10.3361 10.4712 10.511 10.5436 10.6399 10.6726C10.7689 10.8015 10.8413 10.9764 10.8413 11.1587C10.8413 11.341 10.7689 11.5159 10.6399 11.6448C10.511 11.7738 10.3361 11.8462 10.1538 11.8462Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_722_3200">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {t("Post")}
                  </Button> */}
                  {/* 
                  <Button className="Scheduled_btn mt-3">
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_722_3200)">
                          <path
                            d="M4.65381 21.4712C4.47153 21.471 4.29677 21.3985 4.16787 21.2696C4.03898 21.1407 3.96649 20.966 3.96631 20.7837V17.3462H1.90381C0.766684 17.3462 -0.158691 16.4208 -0.158691 15.2837V4.28369C-0.158691 3.14657 0.766684 2.22119 1.90381 2.22119H18.4038C19.5409 2.22119 20.4663 3.14657 20.4663 4.28369V15.2837C20.4663 16.4208 19.5409 17.3462 18.4038 17.3462H10.0514L5.0835 21.3206C4.96158 21.4183 4.81 21.4714 4.65381 21.4712ZM1.90381 3.59619C1.52431 3.59619 1.21631 3.90488 1.21631 4.28369V15.2837C1.21631 15.6625 1.52431 15.9712 1.90381 15.9712H4.65381C4.83614 15.9712 5.01101 16.0436 5.13994 16.1726C5.26888 16.3015 5.34131 16.4764 5.34131 16.6587V19.3537L9.38037 16.1218C9.50234 16.0242 9.65389 15.9711 9.81006 15.9712H18.4038C18.7833 15.9712 19.0913 15.6625 19.0913 15.2837V4.28369C19.0913 3.90488 18.7833 3.59619 18.4038 3.59619H1.90381Z"
                            fill="black"
                          />
                          <path
                            d="M15.6538 9.09619H4.65381C4.47147 9.09619 4.2966 9.02376 4.16767 8.89483C4.03874 8.7659 3.96631 8.59103 3.96631 8.40869C3.96631 8.22636 4.03874 8.05149 4.16767 7.92256C4.2966 7.79362 4.47147 7.72119 4.65381 7.72119H15.6538C15.8361 7.72119 16.011 7.79362 16.1399 7.92256C16.2689 8.05149 16.3413 8.22636 16.3413 8.40869C16.3413 8.59103 16.2689 8.7659 16.1399 8.89483C16.011 9.02376 15.8361 9.09619 15.6538 9.09619ZM10.1538 11.8462H4.65381C4.47147 11.8462 4.2966 11.7738 4.16767 11.6448C4.03874 11.5159 3.96631 11.341 3.96631 11.1587C3.96631 10.9764 4.03874 10.8015 4.16767 10.6726C4.2966 10.5436 4.47147 10.4712 4.65381 10.4712H10.1538C10.3361 10.4712 10.511 10.5436 10.6399 10.6726C10.7689 10.8015 10.8413 10.9764 10.8413 11.1587C10.8413 11.341 10.7689 11.5159 10.6399 11.6448C10.511 11.7738 10.3361 11.8462 10.1538 11.8462Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_722_3200">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Mass Message
                  </Button> */}

                  <div className="d-flex justify-content-end mt-4">
                    <Button className="cancel_btn" onClick={props.onhide}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="SaveForLater"
                active={calendertab === "SaveForLater"}
              >
                <div className="px-4">
                  <div className="Login_input_warp form-floating">
                    <select
                      type="select"
                      id="floatingInput"
                      value={saveforlaterValue}
                      onChange={(e) => setSaveforlaterValue(e.target.value)}
                      class="form-control"
                    >
                      <option value={6}>{t("6h since my last post")} </option>
                      <option value={12}>{t("12h since my last post")} </option>
                      <option value={24}>{t("24h since my last post")} </option>
                      <option value={48}>{t("48h since my last post")} </option>
                    </select>
                    <label for="floatingInput text-sm">
                      {t("Publish my posts saved for later every")}
                    </label>
                  </div>
                  <Button className="Scheduled_btn mt-3" onClick={saveForLater}>
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_722_3200)">
                          <path
                            d="M4.65381 21.4712C4.47153 21.471 4.29677 21.3985 4.16787 21.2696C4.03898 21.1407 3.96649 20.966 3.96631 20.7837V17.3462H1.90381C0.766684 17.3462 -0.158691 16.4208 -0.158691 15.2837V4.28369C-0.158691 3.14657 0.766684 2.22119 1.90381 2.22119H18.4038C19.5409 2.22119 20.4663 3.14657 20.4663 4.28369V15.2837C20.4663 16.4208 19.5409 17.3462 18.4038 17.3462H10.0514L5.0835 21.3206C4.96158 21.4183 4.81 21.4714 4.65381 21.4712ZM1.90381 3.59619C1.52431 3.59619 1.21631 3.90488 1.21631 4.28369V15.2837C1.21631 15.6625 1.52431 15.9712 1.90381 15.9712H4.65381C4.83614 15.9712 5.01101 16.0436 5.13994 16.1726C5.26888 16.3015 5.34131 16.4764 5.34131 16.6587V19.3537L9.38037 16.1218C9.50234 16.0242 9.65389 15.9711 9.81006 15.9712H18.4038C18.7833 15.9712 19.0913 15.6625 19.0913 15.2837V4.28369C19.0913 3.90488 18.7833 3.59619 18.4038 3.59619H1.90381Z"
                            fill="black"
                          />
                          <path
                            d="M15.6538 9.09619H4.65381C4.47147 9.09619 4.2966 9.02376 4.16767 8.89483C4.03874 8.7659 3.96631 8.59103 3.96631 8.40869C3.96631 8.22636 4.03874 8.05149 4.16767 7.92256C4.2966 7.79362 4.47147 7.72119 4.65381 7.72119H15.6538C15.8361 7.72119 16.011 7.79362 16.1399 7.92256C16.2689 8.05149 16.3413 8.22636 16.3413 8.40869C16.3413 8.59103 16.2689 8.7659 16.1399 8.89483C16.011 9.02376 15.8361 9.09619 15.6538 9.09619ZM10.1538 11.8462H4.65381C4.47147 11.8462 4.2966 11.7738 4.16767 11.6448C4.03874 11.5159 3.96631 11.341 3.96631 11.1587C3.96631 10.9764 4.03874 10.8015 4.16767 10.6726C4.2966 10.5436 4.47147 10.4712 4.65381 10.4712H10.1538C10.3361 10.4712 10.511 10.5436 10.6399 10.6726C10.7689 10.8015 10.8413 10.9764 10.8413 11.1587C10.8413 11.341 10.7689 11.5159 10.6399 11.6448C10.511 11.7738 10.3361 11.8462 10.1538 11.8462Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_722_3200">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {t("Post")}
                  </Button>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
