import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { artistList } from "../../../redux/actions";
import User from "../../../images/user.png";
import DummyImg from "../../../images/dummyBack.jpeg";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { TbTag, TbTagOff } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { VscVerified } from "react-icons/vsc";
import SmallLoader from "../ButtonLoader/SmallLoader";
import { BsSearch } from "react-icons/bs";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        data-tooltip-id="Next"
      >
        <path
          d="M16.5 12C16.5001 12.0985 16.4808 12.196 16.4431 12.287C16.4054 12.378 16.35 12.4606 16.2803 12.5302L8.7803 20.0302C8.48723 20.3233 8.01267 20.3233 7.7198 20.0302C7.42692 19.7371 7.42673 19.2626 7.7198 18.9697L14.6895 12L7.7198 5.0302C7.42673 4.73714 7.42673 4.26258 7.7198 3.9697C8.01286 3.67683 8.48742 3.67664 8.7803 3.9697L16.2803 11.4697C16.35 11.5393 16.4054 11.6219 16.4431 11.7129C16.4808 11.8039 16.5001 11.9015 16.5 12Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        data-tooltip-id="Previous"
      >
        <g opacity="1">
          <path
            d="M7.49995 12C7.49985 11.9015 7.51921 11.804 7.55692 11.713C7.59464 11.622 7.64995 11.5394 7.7197 11.4698L15.2197 3.9698C15.5128 3.67673 15.9873 3.67673 16.2802 3.9698C16.5731 4.26286 16.5733 4.73742 16.2802 5.0303L9.31045 12L16.2802 18.9698C16.5733 19.2629 16.5733 19.7374 16.2802 20.0303C15.9871 20.3232 15.5126 20.3234 15.2197 20.0303L7.7197 12.5303C7.64995 12.4607 7.59464 12.3781 7.55692 12.2871C7.51921 12.1961 7.49985 12.0985 7.49995 12Z"
            fill="black"
          />
        </g>
      </svg>
    </div>
  );
}

const ArtistSection = ({ entertainerSerach }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { list, loader, showButtonLoader } = useSelector((s) => s.artist);
  const { user, isAuthenticated } = useSelector((s) => s.login);
  const [artistLists, setArtistLists] = useState([]);
  const [data, setData] = useState({
    limit: 1000,
    order: -1,
    orderBy: "createdAt",
    page: 1,
    search: "",
    fieldName: "isFree",
    fieldValue: false,
  });

  const [totalCount, setTotalCount] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const getArtistList = () => {
    dispatch(artistList(data))
      .unwrap()
      .then((res) => {
        setTotalCount(res?.totalcount);
      })
      .catch((err) => {
        console.log(err, "aut");
      });
  };

  useEffect(() => {
    if (isAuthenticated && user?.token) {
      setTimeout(() => {
        getArtistList();
      }, 500);
    }
  }, [data]);

  const handleArtistDetails = (dta) => {
    const path = generatePath("/:slug", { slug: dta?.userName });
    navigate(path);
  };

  useEffect(() => {
    const chunkSize = 4; // Number of objects per subarray
    const result = [];

    for (let i = 0; i < list?.length; i += chunkSize) {
      const chunk = list?.slice(i, i + chunkSize);
      result.push(chunk);
    }
    setArtistLists(result);
  }, [list]);

  const handleSearch = (e) => {
    const val = e.target.value;
    setData((rest) => ({ ...rest, search: val }));
    if (val === "") {
      getArtistList();
    }
  };

  useEffect(() => {
    if (!!entertainerSerach !== false) {
      setData((rest) => ({ ...rest, search: entertainerSerach }));
    }
  }, [entertainerSerach]);

  return (
    <div className="shadow1 rounded border h-100 px-3 py-2 ">
      {!!entertainerSerach === false && (
        <div className="d-flex justify-content-between align-items-center searchBar loginInput">
          <input
            style={{ outline: 0, width: "90%" }}
            type="text"
            placeholder={t("Search user")}
            className="border-0 "
            value={data?.search}
            onChange={(e) => handleSearch(e)}
          />
          <span className="ml-auto">
            <BsSearch />
          </span>
        </div>
      )}

      <div className="my-4">
        <div className="suggestion_bar d-flex justify-content-between align-items-center">
          <h6 className="sugestText">{t("SUGGESTIONS")}</h6>
          <div
            className="d-flex align-items-center suggestBox "
            style={{ marginRight: "-10px" }}
          >
            <span className="mx-3 pointer">
              {data?.fieldValue ? (
                <TbTag
                  size={26}
                  color="black"
                  onClick={() =>
                    setData((prev) => ({ ...prev, fieldValue: false }))
                  }
                  data-tooltip-id="Show All"
                />
              ) : (
                <TbTagOff
                  size={26}
                  color="black"
                  onClick={() =>
                    setData((prev) => ({ ...prev, fieldValue: true }))
                  }
                  data-tooltip-id="Show Only Free"
                />
              )}
            </span>
            <span
              className="pointer"
              onClick={getArtistList}
              data-tooltip-id="Refresh"
            >
              {showButtonLoader ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ margin: "1px" }}
                >
                  <SmallLoader color={"text-primary"} />
                </div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M21.2186 3.03484C20.7459 2.99174 20.3279 3.34 20.2848 3.81266L20.1709 5.06172C18.2391 2.02787 14.8856 0 11 0C6.81994 0 3.13617 2.38571 1.3075 5.96638C1.09162 6.38907 1.25929 6.90671 1.68197 7.12259C2.10474 7.33846 2.6223 7.17084 2.83818 6.74811C4.38579 3.71778 7.4936 1.71875 11 1.71875C14.1959 1.71875 17.0801 3.38297 18.7324 5.99019L17.507 5.11504C17.1208 4.83923 16.5841 4.92869 16.3082 5.31489C16.0324 5.70114 16.1219 6.23782 16.5081 6.51368L20.2238 9.16747C20.7684 9.55569 21.5196 9.2003 21.5791 8.54618L21.9964 3.96872C22.0395 3.49602 21.6913 3.07794 21.2186 3.03484ZM20.318 14.8774C19.8953 14.6616 19.3777 14.8292 19.1618 15.2519C17.6142 18.2822 14.5064 20.2812 11 20.2812C7.8041 20.2812 4.91994 18.617 3.26761 16.0098L4.49296 16.885C4.8792 17.1608 5.41588 17.0713 5.69174 16.6851C5.96756 16.2989 5.8781 15.7622 5.4919 15.4863L1.77616 12.8325C1.23823 12.4482 0.481033 12.793 0.420877 13.4538L0.00360542 18.0313C-0.0394924 18.5039 0.308771 18.922 0.781386 18.9651C1.25465 19.0081 1.67217 18.6595 1.71523 18.1873L1.8291 16.9382C3.76089 19.9721 7.11436 22 11 22C15.1801 22 18.8638 19.6143 20.6925 16.0336C20.9084 15.6109 20.7407 15.0933 20.318 14.8774Z"
                    fill="black"
                  />
                </svg>
              )}
            </span>
          </div>
        </div>

        <div
          className="slider_suggestion"
          // style={{
          //   overflowY: "scroll",
          //   overflowX: "hidden",
          //   maxHeight: "75vh",
          // }}
        >
          <Slider {...settings}>
            {artistLists.map((lst) => {
              return (
                <Fragment>
                  {lst?.length != 0 &&
                    lst?.map((item) => {
                      return (
                        <>
                          <div
                            key={item?._id}
                            className=" w-100 postCoverImg my-4 pointer position-relative"
                            onClick={() => handleArtistDetails(item)}
                          >
                            <img
                              src={item?.coverImage || DummyImg}
                              alt=""
                              className=" w-100"
                            />
                            <div className="position-absolute postUserDetails2">
                              {/* <img src={item?.profileImage || User} alt="" /> */}
                              {item?.profileImage ? (
                                <div
                                  className="user_img"
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  <img
                                    src={item?.profileImage}
                                    alt=""
                                    className="img-fluid "
                                  />
                                </div>
                              ) : (
                                <div
                                  className="user_img_name shadow2"
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  {item.firstName && item?.lastName
                                    ? item?.firstName?.slice(0, 1) +
                                      item?.lastName?.slice(0, 1)
                                    : item.displayName?.slice(0, 1) ||
                                      item.name?.slice(0, 1)}
                                </div>
                              )}
                            </div>
                            {(!item?.subscribePrice ||
                              (item?.subscribePrice &&
                                item?.subscribePrice === 0)) && (
                              <h6 className="freeText">{t("Free")}</h6>
                            )}
                            <div className="postUserText2">
                              <h6 className="capitalize">
                                {item?.firstName
                                  ? item?.firstName +
                                    " " +
                                    (item?.lastName || " ")
                                  : item.name}{" "}
                                <VscVerified
                                  color="white"
                                  size={23}
                                  className="verifiedIcons mx-2 rounded-circle"
                                />
                              </h6>
                              <h6>{item?.userName}</h6>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </Fragment>
              );
            })}
          </Slider>
        </div>
      </div>

      {/* here we are adding all the tooltip  */}
      <ReactTooltip id="Show All" place="top" content={t("Show All")} />
      <ReactTooltip
        id="Show Only Free"
        place="top"
        content={t("Show Only Free")}
      />
      <ReactTooltip id="Next" place="top" content={t("Next")} />
      <ReactTooltip id="Previous" place="top" content={t("Previous")} />
      <ReactTooltip id="Refresh" place="top" content={t("Refresh")} />
    </div>
  );
};

export default ArtistSection;
