import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageVideoViewer from "../Modal/ImageVideoViewer";
import ReactPlayer from "react-player";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import useWatermark from "../../../helpers/add-watermark";
import CustomVideoPlayer from "../../CustomVideoPlayer";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.62 2.99a1.25 1.25 0 0 0-1.77 0L6.54 11.3a.996.996 0 0 0 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
        />
      </svg>
    </div>
  );
}

const PostViewer = (props) => {
  const { media, data } = props;
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    height: "auto",
  };

  const [showViewer, setShowViewer] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const watermark = useWatermark();

  const [photoIndex, setPhotoIndex] = useState(-1);
  const images = media;
  const handleShowMedia = (dta) => {
    setSelectedMedia({
      url: dta?.url,
      mediaType: dta?.type,
      userName: data?.userDetails?.userName,
    });
    setShowViewer(true);
  };

  return (
    <>
      <div className="postCard Post">
        <div className="my-2 rounded overflow-hidden">
          <Slider
            {...settings}
            className="border-1 d-flex justify-content-center align-items-center"
          >
            {media?.map((mediaItem, index) => (
              <div className="inner" key={mediaItem?.url}>
                <div className="PostMedia d-flex align-items-center position-relative">
                  {mediaItem?.type === "image" && (
                    <>
                      <div
                        className="MediaBg position-absolute  w-100 h-100 d-none"
                        onClick={() => handleShowMedia(mediaItem)}
                      ></div>
                      <div
                        className=" px-2 my-1 overflow-hidden rounded bg-transparent  w-100 position-relative"
                        style={{ zIndex: "9", cursor: "pointer" }}
                        key={index}
                        onClick={() => setPhotoIndex(index)}
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        <img
                          src={
                            watermark(
                              mediaItem?.url,
                              "fansroom.com/" + data?.userDetails?.userName
                            ) || mediaItem?.url
                          }
                          className="rounded img-fluid  w-100 h-100"
                        />
                      </div>
                    </>
                  )}
                  {mediaItem?.type === "video" && (
                    <>
                      {/* <div
                          onClick={() => {
                            handleShowMedia(mediaItem);
                          }}
                        >
                          <CustomVideoPlayer
                            mediaItem={mediaItem}
                            setMediaData={setSelectedMedia}
                            setVisible={setShowViewer}
                          />
                        </div> */}
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                        url={mediaItem?.url}
                        width={"100%"}
                        controls
                        volume={true}
                        // onClick={() => handleShowMedia(mediaItem)}
                        className="custom-player"
                      />
                    </>
                  )}
                  {mediaItem?.type === "audio" && (
                    <audio src={mediaItem?.url} controls />
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <ImageVideoViewer
        show={showViewer}
        onHide={() => setShowViewer(false)}
        mediaType={selectedMedia?.mediaType}
        url={selectedMedia?.url}
        userName={selectedMedia?.userName}
      />
      {photoIndex > -1 && (
        <Lightbox
          mainSrc={
            watermark(
              images[photoIndex]?.url,
              "fansroom.com/" + data?.userDetails?.userName
            ) || images[photoIndex]?.url
          }
          // nextSrc={images[(photoIndex + 1) % images.length]?.url || undefined}
          // prevSrc={
          //   images[(photoIndex + images.length - 1) % images.length]?.url
          // }
          onCloseRequest={() => setPhotoIndex(-1)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imagePadding={0}
        />
      )}
    </>
  );
};

export default PostViewer;
