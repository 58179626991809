import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getStatements } from "../../../../../redux/states/statistics/thunk";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${value?.toFixed(2)}`;
        },
      },
    },
  },
};

const Referrals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [labelData, setLabelData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const userId = useSelector((s) => s.user)?._id;

  const [tab, setTab] = useState("All");
  // const handleTabSelectedtion = (tabs) => {
  //   setTab(tabs);
  // };

  const getStatementsData = () => {
    const data = {
      userId: userId,
      transactionType: ["Referrals"],
    };
    dispatch(getStatements(data))
      .unwrap()
      .then((res) => {
        const statementData = res?.data?.[0]?.data?.[0];
        var labeldd = statementData?.data?.map((itm) => {
          return itm?._id?.date;
        });
        setLabelData(labeldd);
        const graphdd = statementData?.data?.map((itm) => {
          // return itm?.netAmount;
          return itm?.grossAmount;
        });
        setGraphData(graphdd);
      });
  };

  useEffect(() => {
    getStatementsData();
  }, []);

  const data = {
    labels: labelData,
    datasets: [
      {
        label: "Referrals",
        data: graphData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <section className="Earninginnerall_tab">
      <div className="CardBody pb-1 pt-4 innner_tabs">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="Userlist"
          className="d-flex justify-content-between align-items-center"
        >
          <Nav.Item className="">
            <Nav.Link
              className="bg-transparent border-0 position-relative text-small"
              active
            >
              {t("Referrals")}
            </Nav.Link>
          </Nav.Item>{" "}
        </Tab.Container>
      </div>
      <Tab.Content className="">
        <Tab.Pane eventKey="All" active={tab === "All"}>
          <div className="my-3">
            <Line options={options} data={data} />
          </div>
        </Tab.Pane>
      </Tab.Content>
    </section>
  );
};

export default Referrals;
