import React, { useEffect, useTransition } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction } from "../../../redux/states/statements/thunk";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Referrals = (props) => {
  const { transaction } = useSelector((s) => s.statement);
  const { t } = useTranslation();
  const { user } = useSelector((s) => s.setting);
  const userId = user?._id;
  const dispatch = useDispatch();
  const getRefferalsList = () => {
    const data = {
      limit: 1000,
      page: 1,
      userId: userId,
      transactionType: "Referrals",
    };
    dispatch(getTransaction(data));
  };

  useEffect(() => {
    getRefferalsList();
  }, []);

  return (
    <section className="custom_order px-0 pb-3">
      {/* topheaderbar */}
      <div className="notification_topheader d-flex align-items-center justify-content-between pt-4">
        {/* <Link to="/home"> */}
        <div className="notification_left_heading d-flex align-items-center">
          <h5>{props.title}</h5>
        </div>
        {/* </Link> */}

        <div className="collection_header d-flex align-items-center gap-3"></div>
      </div>
      {/* topheaderbar */}

      <div className="common_table earning_table mt-4 table-responsive border_hide">
        <Table>
          <thead>
            <tr>
              <th>{t("DATE & TIME")}</th>
              <th>({t("AMOUNT")})</th>
              <th>{t("FEE")}</th>
              <th>{t("NET AMOUNT")}</th>
              <th>{t("STATUS")}</th>
              <th>{t("DESCRIPTION")}</th>
            </tr>
          </thead>
          <tbody>
            {transaction.length !== 0 &&
              transaction?.data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {moment(item?.createdAt).format("YYYY/MM/DD hh:mm")}
                    </td>
                    <td>{item?.amount}</td>
                    <td>{item?.fee}</td>
                    <td>{item?.netAmount}</td>
                    <td>{item?.status}</td>
                    <td>{item?.text}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {transaction?.length === 0 && (
          <div className="No_earnings">
            <p>{t("No referrals")}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Referrals;
