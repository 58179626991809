import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostViewer from "../PostViewer/PostViewer";
import { formatText } from "../../../helpers/formatPostText";

const EntertainerPostComponent = (props) => {
  const { data, key, setSelectedItems, selectedItems } = props;
  const media = data?.media;
  const handlePostSelection = (dta) => {
    if (selectedItems.includes(dta?._id)) {
      const filteredData = selectedItems?.filter((itm) => itm !== dta?._id);
      setSelectedItems(filteredData);
    } else {
      setSelectedItems((prev) => [...prev, dta?._id]);
    }
  };

  return (
    <div key={key} className="post">
      {data && (
        <div className="lg-mx-4 border-1 lg-px-3 entertainerPostComp">
          <div className="px-3 my-2">
            <h6 className="text-bold " style={{ fontSize: "15px" }}>
              {data?.caption}
              {formatText(data?.description)}
            </h6>
          </div>
          {data?.postType === "quiz" && (
            <div className="mb-2">
              {data?.quizOption?.map((option, index) => {
                var totalValue = 0;
                const quizValue = option?.quizCount || 0;
                totalValue = totalValue + quizValue;

                return (
                  <div
                    key={index}
                    className="mx-3 d-flex align-items-center justify-content-between"
                  >
                    <div className="">
                      <input type="radio" id={option?.option} />

                      <label htmlFor={option?.option} className="mx-2">
                        {option?.option}
                      </label>
                    </div>

                    <div className="d-flex">
                      <input
                        type="range"
                        name=""
                        id=""
                        value={Math.round((quizValue * 100) / totalValue) || 0}
                        className="mx-2"
                      />
                      <h6 style={{ width: "40px" }} className="text-sm ">
                        {Math.round((quizValue * 100) / totalValue) || 0} %
                      </h6>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {data?.postType === "poll" && (
            <div className="mb-2">
              {data?.pollOption?.map((option, index) => {
                return (
                  <div key={index} className="mx-3 d-flex align-items-center ">
                    <input
                      type="radio"
                      // name="poll"
                      id={option?.option}
                    />
                    <label htmlFor={option?.option} className="mx-2">
                      {option?.option}
                    </label>
                  </div>
                );
              })}
            </div>
          )}

          <PostViewer media={media} data={data}/>
          <input
            type="checkbox"
            className="itemCheck"
            onChange={() => handlePostSelection(data)}
          />
        </div>
      )}
      <div className="my-4 endline w-100"></div>
    </div>
  );
};

export default EntertainerPostComponent;
