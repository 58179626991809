import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import HomeComponent from "../../../components/SideTabs/Home";


const Home = () => {
 
  return (
    <MainLayout>
      <HomeComponent />
    </MainLayout>
  );
};

export default Home;
