import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import EntertainerActivity from "../../common/EntertainerActivity";
import Fade from "react-reveal";
import Profilefeedbox from "./EntertainerProfileComponent/Profilefeedbox";

const EntertainerProfile = () => {
  return (
    <Fade>
      <section className="dashboard_first">
        <Container fluid>
          <Row className="align-items-start">
            <Col lg={7} md={12} sm={12} className="padding_tabet">
              <Profilefeedbox title="Profile" />
            </Col>
            <Col lg={5} md={12} sm={12} className="sticky-top">
              <EntertainerActivity />
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default EntertainerProfile;
