import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useTranslation } from "react-i18next";

const Ppmessagesbox = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Fade duration={4000}>
      <section
        className="Ppmessagesbox_event_card commonBox border mt-3 py-3 px-4 position-relative pointer"
        onClick={() => navigate("/messages")}
      >
        <div className="ppmsg_card text-start">
          <h5>{t("P-P-V Messages")}</h5>
          <div className="msgbox_img">
            <img src="/images/messageboximg.png" alt="" className="img-fluid" />
          </div>
          <div className="logo_botom">
            <img src="/images/logo.png" alt="" className="img-fluid" />
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Ppmessagesbox;
