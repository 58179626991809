import React, { Fragment, useEffect } from "react";
import EntertainerCompose from "../../common/EntertainerCreatePost/EntertainerCompose";
import EntertainerActivity from "../../common/EntertainerActivity";
import ArtistSection from "../../common/ArtistSection/ArtistSection";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPostDetails, postDetails } from "../../../redux/actions";

const PostEditComponent = () => {
  const { role } = useSelector((s) => s.setting);

  const { postDetailsDta } = useSelector((s) => s.post);
  const dispatch = useDispatch();
  const { id } = useParams();

  const getPostDetails = () => {
    const dta = `${id}/view`;
    dispatch(postDetails(dta));
  };

  useEffect(() => {
    if (id) {
      getPostDetails();
    }
  }, [id]);

  return (
    <Fragment>
      <Row className="full-height align-items-start justify-content-between ">
        <Col lg="7" md="12" sm="12">
          <div className="shadow1 rounded  border full-height p-3 ">
            {role === "ARTIST" && (
              <EntertainerCompose
                screen="edit-post"
                postDetailsDta={postDetailsDta}
                postId={id}
              />
            )}
          </div>
        </Col>
        <Col lg="5" md="12" sm="12" className="sticky-top">
          {role === "ARTIST" && <EntertainerActivity />}
          {role === "USER" && <ArtistSection />}
        </Col>
      </Row>
    </Fragment>
  );
};

export default PostEditComponent;
