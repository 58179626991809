import React, { Fragment, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { Button, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import ImageVideoViewer from "../../../common/Modal/ImageVideoViewer";
import { addPostToClipboard } from "../../../../redux/states/post/slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWatermark from "../../../../helpers/add-watermark";
import CustomVideoPlayer from "../../../CustomVideoPlayer";
// components;

const MediaSection = (props) => {
  const { t } = useTranslation();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const { allMedia, title } = props;
  const [showViewer, setShowViewer] = useState(false);
  const watermark = useWatermark();
  const handleShowMedia = (dta) => {
    setSelectedMedia({
      url: dta?.url,
      mediaType: dta?.type,
      userName: "",
    });
    setShowViewer(true);
  };

  return (
    <div>
      <div className="Achrive_part lg-px-3 mt-3">
        <Row className="align-items-center align-middle position-relative">
          {allMedia?.length === 0 && (
            <div className="no_bookmarks_yet text-center h-100 vh-100 mt-5">
              <img src="/images/allmedia.png" alt="" className="img-fluid" />
              <p>{t("This category is empty")}</p>
            </div>
          )}
          <div className="no_bookmarks_yet text-center h-100 vh-100 homeMiddleCont">
            <Row className="">
              {allMedia?.map((media, index) => {
                return (
                  <Col sm="6" className="my-2">
                    {media?.type === "image" && (
                      <div
                        className="mediaItem"
                        onClick={() => handleShowMedia(media)}
                      >
                        <img
                          src={
                            watermark(media?.url, "fansroom.com/" + "") ||
                            media?.url
                          }
                          alt=""
                          className="item"
                        />
                      </div>
                    )}
                    {media?.type === "video" && (
                      <div
                        className="mediaItem"
                        onClick={() => handleShowMedia(media)}
                      >
                        <CustomVideoPlayer mediaItem={media} />
                        {/* <ReactPlayer
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          onContextMenu={(e) => e.preventDefault()}
                          url={media?.url}
                          width={"100%"}
                          // height={"100%"}
                          controls={false}
                          className="custom-player"
                        /> */}
                      </div>
                    )}
                    {media?.type === "audio" && (
                      <div className="mediaItem">
                        <audio src="" controls></audio>
                      </div>
                    )}
                  </Col>
                );
              })}
            </Row>
          </div>
        </Row>
      </div>
      <ImageVideoViewer
        show={showViewer}
        onHide={() => setShowViewer(false)}
        mediaType={selectedMedia?.mediaType}
        url={selectedMedia?.url}
        userName={selectedMedia?.userName}
      />
    </div>
  );
};

export default MediaSection;
