import React, { useEffect, useState } from "react";
import Allmedia from "./Allmedia";
import Stories from "./Stories";
import Posts from "./Posts";
import Streams from "./Poll";
import { getPostList } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Quiz from "./Quiz";
import Poll from "./Poll";

export default function Vaultdata({ isShowData, onhide }) {
  const dispatch = useDispatch();
  const [allMedia, setAllMedia] = useState([]);
  const [quizPoll, setQuizPoll] = useState([]);
  const [title, setTitle] = useState("All Media");
  const { list, showLoader, listCount } = useSelector((s) => s.home);
  const { user } = useSelector((s) => s.setting);
  const userId = user?._id;

  const getAllMedia = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["normal", "story", "other"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        var mediaList = data?.map((item) => {
          const media = item?.media;
          return media;
        });
        mediaList = mediaList?.flat();
        setAllMedia(mediaList);
      });
  };

  const getStoryList = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["story"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        var mediaList = data?.map((item) => {
          const media = item?.media;
          return media;
        });
        mediaList = mediaList?.flat();
        setAllMedia(mediaList);
      });
  };
  const getPostsList = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["normal", "other"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        var mediaList = data?.map((item) => {
          const media = item?.media;
          return media;
        });
        mediaList = mediaList?.flat();
        setAllMedia(mediaList);
      });
  };
  const getAllQuiz = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["quiz"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        var quizList = data;
        setQuizPoll(quizList);
      });
  };
  const getAllPoll = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["poll"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        var pollList = data;
        setQuizPoll(pollList);
      });
  };

  useEffect(() => {
    getPostsList();
  }, []);

  useEffect(() => {
    if (isShowData === 1) {
      getAllMedia();
      setTitle("All Media");
    } else if (isShowData === 2) {
      getStoryList();
      setTitle("Stories");
    } else if (isShowData === 3) {
      setTitle("Posts");
      getPostsList();
    } else if (isShowData === 4) {
      setTitle("All Quiz");
      getAllQuiz();
    } else if (isShowData === 5) {
      setTitle("All Polls");
      getAllPoll();
    }
  }, [isShowData]);

  return (
    <section className="Dashboradright-main text-start">
      {(isShowData === 1 || isShowData === 2 || isShowData === 3) && (
        <Allmedia title={title} allMedia={allMedia} onhide={onhide} />
      )}

      {isShowData === 4 && (
        <Quiz title={title} listData={quizPoll} onhide={onhide} />
      )}
      {isShowData === 5 && (
        <Poll title={title} listData={quizPoll} onhide={onhide} />
      )}
    </section>
  );
}
