import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Container, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getEngagement,
  getEngagementMessage,
} from "../../../../redux/states/statistics/thunk";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomDatefillter from "../../../CustomDatefillter";
import Posts from "./Posts";
import Messages from "./Messages";

const Engagement = ({ setSubTab }) => {
  const [tab, setTab] = useState(null);
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.setting?.user)?._id;
  const [opendropdown, setopendropdown] = useState(false);
  const [postType, setPostType] = useState(["quiz", "poll", "normal"]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setTab("Posts");
  }, []);

  useEffect(() => {
    setSubTab(tab);
  }, [tab]);

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);

    if (tabs === "Posts") {
      setPostType(["quiz", "poll", "normal"]);
    } else if (tabs === "Stories") {
      setPostType(["story"]);
    }
  };

  const getEngagementData = () => {
    let data = {
      limit: 10000,
      page: 1,
    };
    let date = {};
    if (startDate && endDate) {
      date = {
        startDate,
        endDate,
      };
    }
    if (tab === "Posts") {
      data = {
        ...data,
        userId: userId,
        postType: postType,
      };

      dispatch(getEngagement({ ...data, ...date }));
    } else {
      data = {
        ...data,
        customerId: userId,
        byCustomer: true,
        order: 1,
      };
      dispatch(getEngagementMessage({ ...data, ...date }));
    }
  };

  useEffect(() => {
    getEngagementData();
  }, [tab, startDate, endDate, userId]);

  const toggledropdown = () => {
    setopendropdown(!opendropdown);
  };

  return (
    <>
      {opendropdown && (
        <div className="backdrops" onClick={toggledropdown}></div>
      )}{" "}
      <section className="Overviewtab_tab">
        <div className="CardBody px-2 mt-3 py-3 innner_tabs commonBox border">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Userlist"
            className="d-flex justify-content-between align-items-center"
          >
            <Nav variant="pills" className="">
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Posts"
                  onClick={() => handleTabSelectedtion("Posts")}
                  active={tab === "Posts"}
                >
                  {t("Posts")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small "
                  eventKey="Messages"
                  onClick={() => handleTabSelectedtion("Messages")}
                  active={tab === "Messages"}
                >
                  {t("Messages")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>

        <CustomDatefillter
          toggledropdown={toggledropdown}
          setStartDateValue={setStartDate}
          startDateValue={startDate}
          setEndDateValue={setEndDate}
          endDateValue={endDate}
        />
        <Tab.Content className="">
          <Tab.Pane eventKey="Posts" active={tab === "Posts"}>
            <div className="my-3">
              <Posts />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Messages" active={tab === "Messages"}>
            <div className="my-3">
              <Messages />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </section>
    </>
  );
};

export default Engagement;
