import { createSlice } from "@reduxjs/toolkit";
import {
  addPostToArchive,
  addPostToPrivateArchive,
  archivePostList,
  getPrivacyPolicy,
  getTermsCondition,
  restorePost,
  uploadFile,
} from "./thunk";
import { status } from "../../constants";

const initialState = {
  status: status.IDLE,
  buttonLoader: false,
  loader: false,
  list: [],
};

const slice = createSlice({
  name: "post label",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPostToArchive.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addPostToArchive.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addPostToArchive.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addPostToPrivateArchive.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addPostToPrivateArchive.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addPostToPrivateArchive.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(restorePost.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(restorePost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(restorePost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(archivePostList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(archivePostList.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.list = action.payload?.data;
    });
    builder.addCase(archivePostList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.list = initialState.list;
    });
  },
});

export default slice.reducer;
