// This is the username section inside the acount section;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import { getProfile, updateProfile } from "../../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Twitter = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("SIGN IN WITH TWITTER")}</span>
        </h4>
      </div>
      <div className="endline my-2"></div>
      <div className="mx-4">
        <div className="mt-4">
          <button className="socialBtn twitt">
            <svg
              className="socailIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
            >
              <g clip-path="url(#clip0_126_3140)">
                <path
                  d="M38 7.21763C36.5869 7.8375 35.0811 8.24837 33.5112 8.44788C35.1262 7.48362 36.3589 5.96837 36.9384 4.142C35.4326 5.03975 33.7701 5.67388 31.9984 6.02775C30.5686 4.50538 28.5309 3.5625 26.3079 3.5625C21.9949 3.5625 18.5226 7.06325 18.5226 11.3549C18.5226 11.9724 18.5749 12.5661 18.7031 13.1314C12.2265 12.8155 6.49563 9.71138 2.64575 4.98275C1.97362 6.14887 1.57938 7.48362 1.57938 8.9205C1.57938 11.6185 2.96875 14.0101 5.03975 15.3947C3.78813 15.371 2.56025 15.0076 1.52 14.4353V14.5208C1.52 18.3065 4.22037 21.451 7.7615 22.1754C7.12737 22.3487 6.43625 22.4319 5.719 22.4319C5.22025 22.4319 4.71675 22.4034 4.24412 22.2989C5.2535 25.384 8.11775 27.6521 11.5235 27.7257C8.873 29.7991 5.50763 31.0484 1.86438 31.0484C1.2255 31.0484 0.61275 31.0199 0 30.9415C3.45087 33.1669 7.54063 34.4375 11.951 34.4375C26.2865 34.4375 34.124 22.5625 34.124 12.2692C34.124 11.9249 34.1121 11.5924 34.0955 11.2623C35.6416 10.165 36.9408 8.79462 38 7.21763Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_126_3140">
                  <rect width="38" height="38" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("Sign In With Twitter")}
          </button>
        </div>
      </div>
    </Settings>
  );
};

export default Twitter;
