import React, { useEffect, useState } from "react";
import DummyPostImg from "../../../images/dummyPost.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageVideoViewer from "../../../components/common/Modal/ImageVideoViewer";
import { CiLock } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import UnlockMessagePaymentModal from "../../common/Modal/UnlockMessagePaymentModal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import useWatermark from "../../../helpers/add-watermark";
import { useSelector } from "react-redux";
import CustomVideoPlayer from "../../CustomVideoPlayer";
import ReactPlayer from "react-player";

function SampleNextArrow(props) {
  const { className, style, onClick, storyimg, data } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.62 2.99a1.25 1.25 0 0 0-1.77 0L6.54 11.3a.996.996 0 0 0 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
        />
      </svg>
    </div>
  );
}

const ReceiverSidePostViewer = (props) => {
  const { t } = useTranslation();
  const { attachment, price, data, refreshList } = props;
  const media = Array.isArray(attachment) ? attachment : [];
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [freePhotoIndex, setFreePhotoIndex] = useState(-1);
  const { artistDetailss } = useSelector((s) => s.artist);
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
  };

  const [showViewer, setShowViewer] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [hasPaidPost, setHasPaidPost] = useState(false);
  const [allFreePost, setAllFreePost] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(data);
  const watermark = useWatermark();
  const images = media;
  const imagesFree = allFreePost;
  const handleShowMedia = (dta) => {
    setSelectedMedia({
      url: dta?.url,
      mediaType: dta?.type,
      userName: artistDetailss?.userName,
    });
    setShowViewer(true);
  };

  useEffect(() => {
    if (media?.length > 0) {
      const filterPaidPost = media?.filter((item) => {
        return item?.priceType === "paid";
      });
      if (filterPaidPost?.length > 0) {
        setHasPaidPost(true);
      } else {
        setHasPaidPost(false);
      }
      const filterFreePost = media?.filter((item) => {
        return item?.priceType === "free";
      });
      setAllFreePost(filterFreePost);
    }
  }, [media]);

  return (
    <div style={{ width: "350px" }}>
      <div className="NewsFeed">
        <div className="postCard Post">
          <div className="my-2 rounded overflow-hidden border-0">
            {!!data?.isBought === false && (
              <Slider
                {...settings}
                className="border-1 d-flex justify-content-center align-items-center"
              >
                {allFreePost?.map((mediaItem, index) => (
                  <div className="inner">
                    <div className="PostMedia d-flex align-items-center position-relative">
                      {mediaItem?.type === "image" &&
                        mediaItem?.priceType === "free" && (
                          <>
                            <div
                              className="MediaBg position-absolute  w-100 h-100 d-none"
                              onClick={() => handleShowMedia(mediaItem)}
                            >
                              <img
                                src={
                                  watermark(
                                    mediaItem?.url,
                                    "fansroom.com/" +
                                      data?.userDetails?.userName
                                  ) || mediaItem?.url
                                }
                                alt=""
                                className="rounded img-fluid  w-100 h-100"
                              />
                            </div>
                            <div
                              className=" /px-2 /my-1 overflow-hidden rounded bg-transparent  w-100 position-relative border-0"
                              style={{ zIndex: "9" }}
                              key={index}
                              onClick={() => setFreePhotoIndex(index)}
                            >
                              <img
                                src={watermark(
                                  mediaItem?.url,
                                  "fansroom.com/" + artistDetailss?.userName
                                )}
                                alt=""
                                className="rounded img-fluid img-msg x-pe"
                              />
                            </div>
                          </>
                        )}
                      <>
                        {mediaItem?.type === "video" &&
                          mediaItem?.priceType === "free" && (
                            // <CustomVideoPlayer mediaItem={mediaItem} />
                            <ReactPlayer
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                              onContextMenu={(e) => e.preventDefault()}
                              url={mediaItem?.url}
                              width={"100%"}
                              controls
                              volume={true}
                              // onClick={() => handleShowMedia(mediaItem)}
                              className="custom-player"
                            />
                          )}
                      </>
                      <>
                        {mediaItem?.type === "audio" &&
                          mediaItem?.priceType === "free" && (
                            <audio src={mediaItem?.url} controls />
                          )}
                      </>
                    </div>
                  </div>
                ))}
                {hasPaidPost && (
                  <div className="inner">
                    <div
                      className="PostMedia d-flex align-items-center position-relative"
                      style={{ aspectRatio: "1/1" }}
                    >
                      {hasPaidPost && (
                        <div className="w-100 dummyPostCont">
                          <img
                            src={
                              watermark(
                                DummyPostImg,
                                "fansroom.com/" + data?.userDetails?.userName
                              ) || DummyPostImg
                            }
                            alt=""
                            className="img-fluid"
                          />
                          <CiLock
                            className="postLockIcon"
                            size={45}
                            color="#b8b8b8"
                          />
                          <div className="dummyPostaction">
                            <div className="my-3 d-flex justify-content-center">
                              <button
                                className="addMoreBtn "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedMessage(data);
                                  setShowPaymentModal(true);
                                }}
                              >
                                {t("Unlock more for")} $ {price}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Slider>
            )}
            {!!data?.isBought === true && (
              <Slider
                {...settings}
                className="border-1 d-flex justify-content-center align-items-center"
              >
                {media?.map((mediaItem, index) => (
                  <div className="inner">
                    <div
                      className="PostMedia d-flex align-items-center position-relative"
                      // style={{ aspectRatio: "1/1" }}
                    >
                      {mediaItem?.type === "image" && (
                        <>
                          <div
                            className="MediaBg position-absolute  w-100 h-100 d-none"
                            onClick={() => handleShowMedia(mediaItem)}
                          >
                            <img
                              src={
                                watermark(
                                  mediaItem?.url,
                                  "fansroom.com/" + artistDetailss?.userName
                                ) || mediaItem?.url
                              }
                              alt=""
                              className="rounded img-fluid  w-100 h-100"
                            />
                          </div>
                          <div
                            className=" /px-2 /my-1 overflow-hidden rounded border bg-transparent  w-100 position-relative border-0"
                            style={{ zIndex: "9" }}
                            key={index}
                            onClick={() => setPhotoIndex(index)}
                          >
                            <img
                              src={watermark(
                                mediaItem?.url,
                                "fansroom.com/" + artistDetailss?.userName
                              )}
                              alt=""
                              className="rounded img-fluid x-pe"
                            />
                          </div>
                        </>
                      )}
                      <>
                        {mediaItem?.type === "video" && (
                          // <CustomVideoPlayer mediaItem={mediaItem} />
                          <ReactPlayer
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                            url={mediaItem?.url}
                            width={"100%"}
                            controls
                            volume={true}
                            // onClick={() => handleShowMedia(mediaItem)}
                            className="custom-player"
                          />
                        )}
                      </>
                      <>
                        {mediaItem?.type === "audio" && (
                          <audio src={mediaItem?.url} controls />
                        )}
                        {/* {mediaItem?.priceType === "free" && (
                          <p className="freetag msgPostTagss">Free</p>
                        )} */}
                        {mediaItem?.priceType === "paid" && (
                          <p className="paidtag msgPostTagss">Unlocked</p>
                        )}
                      </>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
        <ImageVideoViewer
          show={showViewer}
          onHide={() => setShowViewer(false)}
          mediaType={selectedMedia?.mediaType}
          url={selectedMedia?.url}
          userName={selectedMedia?.userName}
        />
        <UnlockMessagePaymentModal
          show={showPaymentModal}
          onHide={() => setShowPaymentModal(false)}
          selectedMessage={selectedMessage}
          refreshList={refreshList}
        />
        {photoIndex > -1 && (
          <Lightbox
            mainSrc={watermark(
              images[photoIndex]?.url,
              "fansroom.com/" + artistDetailss?.userName
            )}
            onCloseRequest={() => setPhotoIndex(-1)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
            imagePadding={0}
          />
        )}
        {freePhotoIndex > -1 && (
          <Lightbox
            mainSrc={watermark(
              images[freePhotoIndex]?.url,
              "fansroom.com/" + artistDetailss?.userName
            )}
            onCloseRequest={() => setFreePhotoIndex(-1)}
            onMovePrevRequest={() =>
              setFreePhotoIndex(
                (freePhotoIndex + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setFreePhotoIndex((freePhotoIndex + 1) % images.length)
            }
            imagePadding={0}
          />
        )}
      </div>
    </div>
  );
};

export default ReceiverSidePostViewer;
