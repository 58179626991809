import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import {
  changePassword,
  forgotPassword,
  login,
  socialLogin,
  resetPassword,
  signUp,
  verifyOtp,
} from "./thunk";
import { toast } from "react-toastify";

const initialState = {
  user: {},
  status: status.IDLE,
  showLoader: false,
  buttonLoader: false,
  isAuthenticated: false,
  selectedEmail: "",
  role: "",
  isDarkMode: false,
};

const slice = createSlice({
  name: "login",
  initialState: { ...initialState },
  reducers: {
    clearUser: () => {
      return initialState;
    },
    selectEmail: (state, action) => {
      state.selectedEmail = action.payload;
    },
    logout: (state, action) => {
      localStorage.removeItem("persist:root");
      // toast.success("User Loged out");
      state.user = initialState.user;
      state.isAuthenticated = false;
      state.status = status.IDLE;
      state.showLoader = false;
      state.role = "";
    },
    updateRole: (state, action) => {
      state.role = action.payload;
    },
    udpateThemeMode: (state, action) => {
      state.isDarkMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action?.payload?.data;
      state.buttonLoader = false;
      state.status = status.SUCCESS;
      state.isAuthenticated = true;
      state.selectedEmail = initialState.selectedEmail;
      state.role = action.payload?.data?.role;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user = initialState.user;
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
      state.isAuthenticated = false;
      state.selectedEmail = initialState.selectedEmail;
    });
    builder.addCase(socialLogin.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      state.user = action?.payload?.data;
      state.buttonLoader = false;
      state.status = status.SUCCESS;
      state.isAuthenticated = true;
      state.selectedEmail = initialState.selectedEmail;
      state.role = action.payload?.data?.role;
    });
    builder.addCase(socialLogin.rejected, (state, action) => {
      state.user = initialState.user;
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
      state.isAuthenticated = false;
      state.selectedEmail = initialState.selectedEmail;
    });



    builder.addCase(signUp.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCESS;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
    });

    builder.addCase(forgotPassword.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCESS;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCESS;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
    });

    builder.addCase(verifyOtp.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCESS;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
    });
    builder.addCase(changePassword.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCESS;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.showLoader = initialState.showLoader;
      state.buttonLoader = initialState.buttonLoader;
      state.status = status.FAILED;
    });
  },
});

export default slice.reducer;
export const { clearUser, selectEmail, logout, updateRole, udpateThemeMode } =
  slice.actions;
