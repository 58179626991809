import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../endpoints";
import axios from "axios";

export const updateProfile = createAsyncThunk(
  "uploadProfile",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.updateProfile,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getProfile = createAsyncThunk(
  "getProfile",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.settings.getProfile);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const connectOtherAccount = createAsyncThunk(
  "connectOtherAccount",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.connectOtherAccount,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.deleteAccount,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const verifictionWithEmail = createAsyncThunk(
  "verifictionWithEmail",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.verifictionWithEmail,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const verifyEmailOtpForAuthentication = createAsyncThunk(
  "verifyEmailOtpForAuthentication",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.verifyEmailOtp,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const verifyPhoneForSms = createAsyncThunk(
  "verifyPhoneForSms",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.verifyEmailOtp,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const updateNotification = createAsyncThunk(
  "updateNotification",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.updateNotification,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const handlePublicPrivate = createAsyncThunk(
  "handlePublicPrivate",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.publicPrivate,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const updateEntertainerSetting = createAsyncThunk(
  "updateEntertainerSetting",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.entertainerSetting,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const handleSubscribePrice = createAsyncThunk(
  "handleSubscribePrice",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.subscribePrice,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const addSubscriptionBundle = createAsyncThunk(
  "addSubscriptionBundle",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.addSubscriptionBundle,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const removeSubscriptionBundle = createAsyncThunk(
  "removeSubscriptionBundle",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.removeSubscriptionBundle,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const addTrail = createAsyncThunk("addTrail", async (payload, Thunk) => {
  try {
    const response = await axios.post(endpoints.settings.addTrail, payload);
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});
export const trailListing = createAsyncThunk(
  "trailListing",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.trailListing,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const removeTrail = createAsyncThunk(
  "removeTrail",
  async (payload, Thunk) => {
    try {
      const response = await axios.delete(
        endpoints.settings.removeTrailLink,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const addStoryPromotion = createAsyncThunk(
  "addStoryPromotion",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.addStoryPromotion,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const stopPromotion = createAsyncThunk(
  "stopPromotion",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.settings.stopPromotion,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const removePromtion = createAsyncThunk(
  "removePromotion",
  async (payload, Thunk) => {
    try {
      const response = await axios.delete(
        endpoints.settings.removePromotion,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
