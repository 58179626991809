import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import {
  getEngagement,
  getFansStatistics,
  getStatements,
  getSinglePostStats,
  getFansSubscriptions,
  getEngagementMessage,
  getTopFansStats,
} from "./thunk";

const initialState = {
  status: status.IDLE,
  engagementData: [],
  loading: false,
  statementData: [],
  fansStatistics: [],
  singlePostStats: {},
  fansSubscription: [],
  engagementMessage: [],
  topFansStats: [],
};

const slice = createSlice({
  name: "statistics",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEngagement.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getEngagement.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.engagementData = action.payload?.data;
    });
    builder.addCase(getEngagement.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.engagementData = initialState.engagementData;
    });
    builder.addCase(getStatements.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getStatements.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.statementData = action.payload?.data?.[0];
    });
    builder.addCase(getStatements.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.statementData = initialState.statementData;
    });
    builder.addCase(getFansStatistics.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getFansStatistics.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.fansStatistics = action.payload?.data?.[0];
    });
    builder.addCase(getFansStatistics.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.fansStatistics = initialState.statementData;
    });

    builder.addCase(getSinglePostStats.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getSinglePostStats.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.singlePostStats = action.payload?.data;
    });
    builder.addCase(getSinglePostStats.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.singlePostStats = initialState.singlePostStats;
    });

    builder.addCase(getFansSubscriptions.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getFansSubscriptions.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.fansSubscription = action.payload?.data;
    });
    builder.addCase(getFansSubscriptions.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.fansSubscription = initialState.fansSubscription;
    });

    builder.addCase(getEngagementMessage.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getEngagementMessage.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.engagementMessage = action.payload?.data;
    });
    builder.addCase(getEngagementMessage.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.engagementMessage = initialState.engagementMessage;
    });

    builder.addCase(getTopFansStats.pending, (state, action) => {
      state.status = status.LOADING;
      state.loading = true;
    });
    builder.addCase(getTopFansStats.fulfilled, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.topFansStats = action.payload?.data;
    });
    builder.addCase(getTopFansStats.rejected, (state, action) => {
      state.status = status.LOADING;
      state.loading = false;
      state.topFansStats = initialState.topFansStats;
    });
  },
});

export default slice.reducer;
