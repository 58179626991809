// This is the edit profile system ;
import React, { useEffect, useState, Fragment } from "react";
import BackImg from "../../../images/backImg2.png";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../../components/common/ButtonLoader/ButtonLoader";
import { FiEdit } from "react-icons/fi";
import { getProfile, updateProfile, uploadFile } from "../../../redux/actions";
import { toast } from "react-toastify";
import { AiOutlineCamera } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { IoWarningOutline } from "react-icons/io5";
import RichFullTextEditor from "../../../components/RichFullTextEditor";

const EditProfile = () => {
  const { t } = useTranslation();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const { imageLoader } = useSelector((s) => s.common);
  const [bioValue, setBioValue] = useState();
  const [imgUploadEvent, setImgUploadEvent] = useState("");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      userName: "",
      bio: "",
      profileImage: "",
      coverImage: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      userName: Yup.string().required(t("Username is required")),
      bio: Yup.string().required(t("Bio is required")),
      profileImage: Yup.string().required("Profile Image is required"),
      coverImage: Yup.string().required("Cover Image is required"),
    }),
  });

  useEffect(() => {
    formik.setValues({
      userName: user?.userName,
      bio: user?.bio,
      profileImage: user?.profileImage,
      coverImage: user?.coverImage,
    });
    setBioValue(user?.bio);
  }, [user]);

  const handleImgUpload = (e) => {
    const files = e.target.files[0];
    setImgUploadEvent("profile");
    const formdata = new FormData();
    formdata.append("imageProfile", files);

    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        formik.setFieldValue("profileImage", res?.data?.url);
        // toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleBgImage = (e) => {
    const files = e.target.files[0];
    setImgUploadEvent("back");
    const formdata = new FormData();
    formdata.append("imageProfile", files);

    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        formik.setFieldValue("coverImage", res?.data?.url);
        toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleRemoveProfile = () => {
    formik.setFieldValue("profileImage", "");
  };

  const handleBioChange = (value) => {
    setBioValue(value);
    formik.setFieldValue("bio", value);
    formik.setFieldTouched("bio", true);
    if (value.trim().length === 0) {
      formik.setFieldError("bio", t("Bio is required"));
    } else {
      formik.setFieldError("bio", "");
    }
  };

  return (
    <Fragment>
      <div className=" rounded-1 py-2 px-2 my-4 ">
        <div className="d-flex align-items-center">
          <IoWarningOutline color="#ed332d" size={28} className="mx-1" />
          <p className="text-small mx-2 mb-0">
            {t("Please complete filling out the following details")}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <IoWarningOutline
            color="#ed332d"
            size={28}
            className="mx-1 invisible"
          />
          <p className="text-small mx-2 mb-0">
            <div>- avatar image</div>
            <div>- cover image</div>
            <div>- username</div>
            <div>- bio details</div>
          </p>
        </div>
      </div>
      <div className="mt-1 ">
        <div className="editProfileBack position-relative">
          {imageLoader && imgUploadEvent === "back" ? (
            <div
              className=" w-100 d-flex justify-content-center"
              style={{ height: "100px" }}
            >
              <ButtonLoader color="text-primary" />
            </div>
          ) : (
            <img
              src={
                formik.values.coverImage ? formik.values.coverImage : BackImg
              }
              alt=""
              className="profileback"
            />
          )}
          <div className="position-absolute cameraIcon pointer">
            <label htmlFor="camera" className="pointer">
              <AiOutlineCamera color="white" size={33} />
            </label>
            <input type="file" id="camera" onChange={handleBgImage} />
          </div>

          <div className="profileIcn">
            {imageLoader && imgUploadEvent === "profile" ? (
              <ButtonLoader color="text-primary" />
            ) : (
              <>
                {formik.values.profileImage ? (
                  <img src={formik.values.profileImage} alt="" className="" />
                ) : (
                  <div className="customProfile">
                    {formik.values.name?.slice(0, 2)}
                  </div>
                )}
              </>
            )}
            <div className="profileImgEdit shadow2">
              <label htmlFor="editProfile">
                <FiEdit size={18} className="pointer" />
              </label>
              <input
                type="file"
                id="editProfile"
                onChange={(e) => handleImgUpload(e)}
              />
            </div>
          </div>
        </div>
        {formik.values.profileImage ? (
          <div
            className="d-flex justify-content-end w-full mx-3 text-sm mt-2"
            onClick={() => handleRemoveProfile()}
          >
            <a href="#">Remove profile</a>
          </div>
        ) : (
          <div className="d-flex justify-content-end w-full mx-3 text-sm mt-2 invisible">
            <a href="#">dd</a>
          </div>
        )}

        <div className="editProfileForm lg-px-5 p-2 py-5 mt-2 underline">
          <div className="Login_input_warp form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder={t("Username")}
              name="userName"
              id="floatingInput"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userName}
            />
            <label for="floatingInput">{t("Username")}</label>
            {formik.touched.userName && formik.errors.userName && (
              <span className="text-danger errorText">
                {formik.errors.userName}
              </span>
            )}
          </div>
          <div className="Login_input_warp form-floating mb-3">
            <RichFullTextEditor
              value={bioValue}
              handleChange={handleBioChange}
              id="EditProfile_Creator"
            />

            {formik.touched.bio && formik.errors.bio && (
              <span className="text-danger errorText">{formik.errors.bio}</span>
            )}
          </div>
        </div>
        {Object.keys(formik.errors).length > 0 &&
          Object.keys(formik.touched).length === 4 &&
          !Object.keys(formik.touched).some((item) => !item) && (
            <div className="border rounded-1 py-2 px-2mx-2">
              <div className="d-flex align-items-center">
                <IoWarningOutline color="#ed332d" size={28} className="mx-1" />
                <p className="text-small mx-2 mb-0 text-danger">
                  {Object.values(formik.errors)?.[0]}
                </p>
              </div>
            </div>
          )}
        <div className="mx-3 d-flex justify-content-between align-items-center px-3 py-3">
          {/* <h6 className=" text-bold">{t("Edit Profile")}</h6> */}
          <span></span>
          {buttonLoader ? (
            <button className="sendMsg">
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="sendMsg p-1"
              type="submit"
              onClick={formik.handleSubmit}
            >
              {t("Save")}
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditProfile;
