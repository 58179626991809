// This is for the confirmation of deleting the list ;

import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
// import { Button } from "react-bootstrap";
// import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { allArtistGroupList } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import User from "../../../images/user.png";
import { updatePinnedCollection } from "../../../redux/states/collection/slice";

const Pintohome = (props) => {

  const { t } = useTranslation();
  const { allGroupList } = useSelector((s) => s.artist);
  const { pinnedCollection } = useSelector((s) => s.collection);
  const dispatch = useDispatch();
  const getAllArtistGroupList = () => {
    const data = {
      limit: 100,
      page: 1,
    };
    dispatch(allArtistGroupList(data));
  };

  useEffect(() => {
    getAllArtistGroupList();
  }, []);

  const handleListSelected = (dta) => {
    const findDta = pinnedCollection?.find((itm) => itm?.listName === dta?.listName);
    if(findDta){
      const filteredData = pinnedCollection?.filter((itm) => itm?.listName !== dta?.listName)
      dispatch(updatePinnedCollection(filteredData))
    }
    else {
      const ddd = [...pinnedCollection , dta]
      dispatch(updatePinnedCollection(ddd))
    }
  };


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pin_modal"
    >
      <Modal.Body>
        <div className="modal_header d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <h6>{t("PIN TO HOME")}</h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 3v2H3V3h9Zm4 16v2H3v-2h13Zm6-8v2H3v-2h19Z"
              />
            </svg>
          </span>
        </div>

        <div className="middle_content px-3 py-2">
          <Form>
            {allGroupList?.favList?.map((itm, index) => {
              return (
                <FormGroup
                  check
                  className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer "
                >
                  <Input
                    type="checkbox"
                    name="radio1"
                    onChange={() => handleListSelected(itm)}
                    className="mx-0 "
                    id={index}
                    checked={pinnedCollection?.find((item) => item?.listName === itm?.listName)}
                  />
                  <Label check className="w-100" htmlFor={index}>
                    <div className="d-flex justify-content-between align-items-center mx-3 ">
                      <div className="custmUserbox d-flex align-items-center ">
                        <div>
                          <h6 className="text-small mb-0">{itm?.listName}</h6>
                          <h6 className="mb-0">
                            <span>
                              {itm?.artistDetails?.length} {t("users")}{" "}
                              {itm?.post?.length} {t("posts")}
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div className="cstomImgbox ">
                        {itm?.artistDetails?.slice(0, 3)?.map((artist) => {
                          return (
                            <img
                              src={artist?.profileImage || User}
                              alt=""
                              className="user1 shadow2"
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Label>
                </FormGroup>
              );
            })}
          </Form>
        </div>

        <div className="modal_footer d-flex align-items-center justify-content-end px-3 py-2 border-top">
          <Link to="" onClick={props.onhide}>
            {t("Close")}
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Pintohome;
