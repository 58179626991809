import React from 'react'
import MainLayout from '../../../layout/MainLayout'
import BankingPageComponent from '../../../components/SideTabs/BankingPage'

const BankingPage = () => {
  return (
    <MainLayout>
      <BankingPageComponent/>
    </MainLayout>
  )
}

export default BankingPage