import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

export default function Expirationmod(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [calendertab, setcalendertab] = useState("Scheduled");
  const handleTabSelectedtion = (tabs) => {
    setcalendertab(tabs);
  };
  const { formik } = props;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
  };

  const handleExpirationDate = (val) => {
    formik.setFieldValue("scheduled", val);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="expiration_modal"
    >
      <Modal.Body>
        <div className="calednder_Mod">
          <div className="add_heading d-flex px-4 pt-4 justify-content-between">
            <h5>{t("EXPIRATION PERIOD")}</h5>
            <a href="javascript:void(0)" onClick={props.onhide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </a>
          </div>

          <div className="Expiraion_form px-4 mt-3">
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  name="email"
                  id="exampleEmail"
                  placeholder={t("No limit")}
                  value={
                    formik.values.scheduled === 0
                      ? "No limit"
                      : formik.values.scheduled + " " + "days"
                  }
                />
              </FormGroup>

              <p className="selcting_line">
                {t("Select from below or enter your own")}
              </p>

              <div className="button_slect">
                <div className="no_limit_tabs">
                  <Slider {...settings}>
                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 0}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(0)}
                      >
                        {t("No limit")}
                      </div>
                    </label>

                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 1}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(1)}
                      >
                        {t("1 day")}
                      </div>
                    </label>

                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 3}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(3)}
                      >
                        {t("3 day")}
                      </div>
                    </label>

                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 7}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(7)}
                      >
                        {t("7 day")}
                      </div>
                    </label>

                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 30}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(30)}
                      >
                        {t("30 day")}
                      </div>
                    </label>

                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 90}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(90)}
                      >
                        {t("90 days")}
                      </div>
                    </label>

                    <label className="tab">
                      <input
                        type="radio"
                        name="tab-input"
                        className="tab-input"
                        checked={formik.values.scheduled === 120}
                      />
                      <div
                        className="tab-box"
                        onClick={() => handleExpirationDate(120)}
                      >
                        {t("120 days")}
                      </div>
                    </label>
                  </Slider>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 py-3 mt-4">
                <Button className="cancel_btn" onClick={props.onhide}>
                  {t("Cancel")}
                </Button>
                <Button className="cancel_btn" onClick={props.onhide}>
                  {t("Save")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
