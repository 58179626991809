import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import UserCard from "./UserCard";
import { useSelector, useDispatch } from "react-redux";
import { artistList } from "../../../redux/actions";
import SaveToList from "../../common/Modal/SaveToList";
import AddList from "../../common/Modal/AddList";
import NoData from "../../../images/nodata.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Users = (props) => {
  const { userList } = props;
  const navigate = useNavigate();
  const [data, setData] = useState({
    limit: 100,
    order: 1,
    orderBy: "createdAt",
    page: 1,
    search: "",
  });
  const { t } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("expired");
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [showAddList, setShowAddList] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState({});

  const getArtistList = () => {
    dispatch(artistList(data))
      .unwrap()
      .then((res) => {
        setTotalCount(res?.totalcount);
      })
      .catch((err) => {
        console.log(err, "error here");
      });
  };

  useEffect(() => {
    getArtistList();
  }, [data]);

  const handleAddToFavourite = (dta) => {
    setSelectedArtist(dta);
    setShowSaveToList(true);
  };

  const showCollection = () => {
    navigate("/collections");
  };

  return (
    <div className="px-3 py-1">
      <div className="d-flex justify-content-between align-items-center px-2">
        <h5 className="text-custom">{t("CUSTOM ORDER")}</h5>
        <h6 className="d-flex align-items-center">
          <span className="mx-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M15.3536 14.6465L11.1614 10.4544C12.0557 9.39963 12.5969 8.0365 12.5969 6.54862C12.5968 3.21338 9.8835 0.5 6.54838 0.5C3.21325 0.5 0.5 3.21338 0.5 6.54862C0.5 9.88375 3.21325 12.597 6.54838 12.597C8.03638 12.597 9.39963 12.0559 10.4543 11.1615L14.6465 15.3536C14.7441 15.4513 14.8721 15.5001 15.0001 15.5001C15.1281 15.5001 15.256 15.4513 15.3538 15.3536C15.4475 15.2598 15.5001 15.1326 15.5001 15C15.5001 14.8674 15.4474 14.7403 15.3536 14.6465ZM1.5 6.54862C1.5 3.76475 3.76463 1.5 6.54838 1.5C9.33212 1.5 11.5969 3.76475 11.5969 6.54862C11.5969 9.33237 9.33212 11.597 6.54838 11.597C3.76463 11.5969 1.5 9.33225 1.5 6.54862Z"
                fill="black"
              />
            </svg>
          </span>
          <span className="collPlus ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                  fill="black"
                />
              </g>
            </svg>
          </span>
        </h6>
      </div>
      <div className="d-flex align-items-center usersTitle my-4">
        <h5
          onClick={showCollection}
          className={`${activeTab == "all" && "activeTab"}`}
        >
          {t("All")} {userList.length}
        </h5>
        <h5
          onClick={showCollection}
          className={`${activeTab == "active" && "activeTab"}`}
        >
          {t("Active")} 4
        </h5>
        <h5
          onClick={() => setActiveTab("expired")}
          className={`${activeTab == "expired" && "activeTab"}`}
        >
          {t("Expired")}
        </h5>
        <h5
          onClick={() => setActiveTab("attention")}
          className={`${activeTab == "attention" && "activeTab"}`}
        >
          {t("Attention request")} 0
        </h5>
      </div>

      {/*  */}
      <Row>
        {/* {userList.length != 0 &&
          userList?.map((item, index) => {
            return (
              <UserCard
                key={index}
                data={item}
                getArtistList={getArtistList}
                handleAddToFavourite={handleAddToFavourite}
              />
            );
          })} */}

        <div className="nodataFound">
          <img src={NoData} alt="" />
        </div>
      </Row>
      <SaveToList
        show={showSaveToList}
        onHide={() => setShowSaveToList(false)}
        selectedArtist={selectedArtist}
        setShowAddList={setShowAddList}
      />
      <AddList
        show={showAddList}
        setShowAddList={setShowAddList}
        selectedArtist={selectedArtist}
      />
    </div>
  );
};

export default Users;
