import React, { Fragment , useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { planList } from "../../../redux/actions";


function SampleNextArrow(props) {

  const { className, style, onClick, storyimg, data } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.62 2.99a1.25 1.25 0 0 0-1.77 0L6.54 11.3a.996.996 0 0 0 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
        />
      </svg>
    </div>
  );
}

const SubscriptionPlanSection = (props) => {

  const dispatch = useDispatch();
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const { handlePackageSelect, selectedPackage } = props;
  const { planListt } = useSelector((s) => s.common);
  const [newList , setNewList] = useState([])

  const getPlanList = () => {
    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(planList(data));
  };

  useEffect(() => {
    getPlanList();
  }, []);

  useEffect(() =>{
    const filterData = planListt?.filter((item) => item?.status === "active")
    setNewList(filterData)
  },[planListt])


  return (
    <div className="postCard Post">
      <div className="my-2 rounded overflow-hidden">
        <Slider
          {...settings}
          className=" d-flex justify-content-center align-items-center"
        >
          {newList?.map((item, index) => {
            return (
              <Fragment key={index}>
                {item.status === "active" && (
                  <Fragment>
                    <div
                      onClick={() => handlePackageSelect(item)}
                      className={`border-1  rounded-1 p-3 d-flex justify-content-center flex-column subscriptionBx pointer mx-2 ${
                        selectedPackage?._id === item?._id
                          ? "activeSubscription"
                          : "disableSubcription"
                      }`}
                    >
                      <div className="border-1 rounded-1 p-2 text-normal text-center btnnn">
                        {item?.name}
                      </div>
                      <h5 className="text-normal text-center my-3">
                        {item?.fee} $ {item?.type}
                      </h5>
                      <p className=" text-center mb-3">{item?.description}</p>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            );
          })}
          {/* </div> */}
        </Slider>
      </div>
    </div>
  );
};

export default SubscriptionPlanSection;
