import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PostComponent from "../../common/Posts/PostComponent";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  activeArtistList,
  getPostList,
  subscribedArtistList,
} from "../../../redux/actions";
import Pintohome from "../../common/Modal/Pintohome";
import EntertainerActivity from "../../common/EntertainerActivity";
import EntertainerCompose from "../../common/EntertainerCreatePost/EntertainerCompose";
import Addstory from "../../common/EntertainerCreatePost/AddStory";
import InfiniteScroll from "react-infinite-scroll-component";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import ImageVideoViewer from "../../common/Modal/ImageVideoViewer";
import RenewSubscriptionModal from "../../common/Modal/RenewSubscriptionModal";

const HomeEntertainer = (props) => {
  const [showSendTip, setShowSendTip] = useState(false);
  const [pintohome, setpintohome] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showProfileBanners, setShowProfileBanners] = useState(true);
  const [showViewer, setShowViewer] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [page, setPage] = useState(1);
  const [totalCounts, setTotalCount] = useState(10);
  const [refreshPostList, setRefreshPostList] = useState(false);
  const [newList, setNewList] = useState([]);
  const [showRenewSubsciptionModal, setShowRenewSubscriptionModal] =
    useState(false);
  const [showLockedPost, setShowLockedPost] = useState(true);
  const { pinnedCollection, subscribedArtistList: followingList } = useSelector(
    (s) => s.collection
  );
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedArtistId, setSelectedArtistId] = useState([]);
  const [loading, setLoading] = useState(false);
  const getActiveArtistList = () => {
    const data = {
      limit: 1000,
      status: "active",
      page: 1,
    };
    dispatch(activeArtistList(data));
  };
  useEffect(() => {
    getActiveArtistList();
  }, []);

  const handleArtistDetails = (dta) => {
    const path = generatePath("/:slug", { slug: dta?.userName });
    navigate(path);
  };

  const fetchMorePost = () => {
    getPostLists(page + 1);
  };

  const getPostLists = (pageNo = page, lockedPostStatus = showLockedPost) => {
    setLoading(true);
    setPage(pageNo);
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      postType: ["quiz", "normal", "other", "poll"],
      filter: "following",
      type: lockedPostStatus ? "" : "free",
      artistIds: selectedArtistId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        if (pageNo === 1) {
          setNewList(data);
        } else {
          if (refreshPostList) {
            setNewList(data);
            setRefreshPostList(false);
          } else {
            setNewList((prev) => [...prev, ...data]);
          }
        }
        setLoading(false);
        setTotalCount(res?.totalcount);
      })
      .catch((err) => {
        setLoading(true);
        console.error(err);
      });
  };

  useEffect(() => {
    getPostLists();
  }, [refreshPostList, selectedArtistId]);

  const handleTabSelection = (tab, dta) => {
    if (tab === "All") {
      setSelectedArtistId([]);
    } else {
      const allEntertainerId = dta?.artistId;
      setSelectedArtistId(allEntertainerId);
    }
    setSelectedTab(tab);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [window]);

  useEffect(() => {
    const query = {
      limit: 1000,
      page: 1,
    };
    dispatch(subscribedArtistList(query));
  }, []);

  const checkSubscribe = (userId) => {
    return followingList?.some((val) => val.followerId === userId) || false;
  };

  return (
    <>
      <Pintohome show={pintohome} onhide={() => setpintohome(false)} />
      <Row className="h-100 align-items-start justify-content-between">
        <Col lg="7" md="12" sm="12" className="px-0 px-lg-3">
          <div
            className="storyTop homemiddleItem rounded  border px-lg-3 px-0"
            id="scrollableDiv"
          >
            <EntertainerCompose
              screen="home"
              setShowProfileBanners={setShowProfileBanners}
              showProfileBanners={showProfileBanners}
              getPostLists={getPostLists}
              setRefreshPostList={setRefreshPostList}
              setShowLockedPost={setShowLockedPost}
              showLockedPost={showLockedPost}
            />
            <div className="slider_tab my-1 mt-3 px-1 d-flex">
              <h6
                className={`rounded-lg pointer text-small px-3 py-1 mx-1 bgcol text-dark ${
                  selectedTab === "All" && "activeTabs"
                }`}
                onClick={() => handleTabSelection("All", {})}
              >
                {t("All")}
              </h6>
              {pinnedCollection?.map((collection, index) => {
                return (
                  <h6
                    className={`rounded-lg pointer text-small px-3 py-1 mx-1 bgcol text-dark  ${
                      selectedTab === collection?.listName && "activeTabs"
                    }`}
                    key={index}
                    onClick={() =>
                      handleTabSelection(collection?.listName, collection)
                    }
                  >
                    {collection?.listName}
                  </h6>
                );
              })}
              <Link to="" onClick={() => setpintohome(true)}>
                <h6 className="rounded-circle text-small editIcon mx-2  text-light ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M7.03 4.51L7.49 4.97L2.96 9.5H2.5V9.04L7.03 4.51ZM8.83 1.5C8.705 1.5 8.575 1.55 8.48 1.645L7.565 2.56L9.44 4.435L10.355 3.52C10.4014 3.47374 10.4381 3.4188 10.4632 3.35831C10.4883 3.29783 10.5012 3.23298 10.5012 3.1675C10.5012 3.10202 10.4883 3.03717 10.4632 2.97669C10.4381 2.9162 10.4014 2.86126 10.355 2.815L9.185 1.645C9.085 1.545 8.96 1.5 8.83 1.5ZM7.03 3.095L1.5 8.625V10.5H3.375L8.905 4.97L7.03 3.095Z"
                      fill="black"
                    />
                  </svg>
                </h6>
              </Link>
            </div>

            <Addstory />
            <div
              id="scrollableDiv"
              style={{ height: "100vh", overflow: "auto" }}
            >
              <InfiniteScroll
                dataLength={newList.length}
                next={() => fetchMorePost()}
                hasMore={newList.length !== totalCounts}
                loader={
                  <div className="d-sm-flex justify-content-center d-none">
                    <SmallLoader
                      cstmClass={`d-sm-none`}
                      color={"text-secondary"}
                    />
                  </div>
                  // <div className="d-flex justify-content-center">
                  //   <SmallLoader color={"text-secondary"} />
                  // </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <div className="NewsFeed">
                  {newList?.map((item, index) => {
                    return (
                      <PostComponent
                        key={index}
                        setShowSendTip={setShowSendTip}
                        data={item}
                        {...props}
                        showProfileBanners={showProfileBanners}
                        newList={newList}
                        setNewList={setNewList}
                        setSelectedMedia={setSelectedMedia}
                        isSubscribed={checkSubscribe(item?.userId)}
                      />
                    );
                  })}
                </div>
                {isMobile && newList.length !== totalCounts && (
                  <div className="d-flex d-sm-none justify-content-center mt-2">
                    {loading ? (
                      <div className="loader m-2"></div>
                    ) : (
                      <Button
                        onClick={() => {
                          if (!loading) fetchMorePost();
                        }}
                        variant={"secondary"}
                        className="btnborder surjitload d-flex  align-items-center justify-content-center rounded-pill common-btn btnSm "
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </Col>
        <Col lg="5" md="12" sm="12" className="sticky-top">
          <EntertainerActivity />
        </Col>
      </Row>
      <ImageVideoViewer
        show={showViewer}
        onHide={() => setShowViewer(false)}
        mediaType={selectedMedia?.mediaType}
        url={selectedMedia?.url}
        userName={selectedMedia?.userName}
      />
      <RenewSubscriptionModal
        show={showRenewSubsciptionModal}
        onHide={() => setShowRenewSubscriptionModal(false)}
      />
    </>
  );
};

export default HomeEntertainer;
