import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const artistList = createAsyncThunk(
  "artistList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.getArtistList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const subscriberList = createAsyncThunk(
  "subscriberList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.subscriberList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const activeArtistList = createAsyncThunk(
  "activeArtistList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.activeArtistList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const artistDetails = createAsyncThunk(
  "artistDetails",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(
        endpoints.artist.artistDetails + payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const artistDetailsByUsername = createAsyncThunk(
  "artistDetailsByUsername",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(
        endpoints.artist.artistDetailsByUsername + payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const artistPost = createAsyncThunk(
  "artistPost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.artist.artistPost, payload);
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const createNewList = createAsyncThunk(
  "artist/createNewList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.createNewList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const addArtistToList = createAsyncThunk(
  "artist/addToFav",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.addArtistToList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const removeArtistFromList = createAsyncThunk(
  "artist/removeFromFav",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.removeArtistFromList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const subscribeArtist = createAsyncThunk(
  "artist/subscribe",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.subscribeArtist,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const getCreatedListForArtist = createAsyncThunk(
  "artist/allCreatedList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.artist.allCreatedList,
        payload
      );
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const allArtistGroupList = createAsyncThunk(
  "artist/groupList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.artist.allGroupList, payload);
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const searchArtist = createAsyncThunk(
  "artist/searchArtist",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.artist.searchArtist, payload);
      return response.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
