import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import SmallLoader from "../../ButtonLoader/SmallLoader";
import { useTranslation } from "react-i18next";

const AudioRecorders = (props) => {
  const { t } = useTranslation();
  const [audioUrl, setAudioUrl] = useState(null);
  const { imageLoader } = useSelector((s) => s.common);
  const { saveAudio, audioData, setAudioData } = props;

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
    setAudioData(blob);
  };

  const closeAudioRecorder = () => {
    props.onhide();
    setAudioUrl(null);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="fullModal videoPop"
    >
      <Modal.Body className="videoRecorderContainer">
        <div className="d-flex justify-content-between align-items-center my-2">
          <p>{t("Audio Recorder")}</p>

          <div className="d-flex justify-content-center align-items">
            {imageLoader ? (
              <Button className="mx-3 common-btn d-flex align-items-center justify-content-center">
                <SmallLoader color="text-primary" />
              </Button>
            ) : (
              <Button
                className="mx-3  common-btn d-flex align-items-center justify-content-center"
                onClick={saveAudio}
              >
                {t("Save")}
              </Button>
            )}

            <Button
              className="border-0 p-0  position-absolute closeBtn"
              style={{ top: "10px", right: "10px" }}
              variant="transparent"
              onClick={closeAudioRecorder}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M12.2812 1.71875L1.71875 12.2812M1.71875 1.71875L12.2812 12.2812"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <AudioRecorder
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadOnSavePress={true}
            downloadFileExtension="webm"
          />
          {audioUrl && <audio src={audioUrl} controls />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AudioRecorders;
