import React, { useState, useEffect } from "react";
import Settings from "./Settings";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  handleSubscribePrice,
  removePromtion,
  removeSubscriptionBundle,
  removeTrail,
  stopPromotion,
  trailListing,
} from "../../../redux/actions";
import { useFormik } from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as Yup from "yup";
import { toast } from "react-toastify";
import ButtonLoader from "../../../components/common/ButtonLoader/ButtonLoader";
import StoryPromotionPop from "../../../components/common/Modal/StoryPromotionPop";
import FreeTrialLinkPop from "./Modals/FreeTrialLinkPop";
import SmallLoader from "../../../components/common/ButtonLoader/SmallLoader";
import SubscriptionBundlePop from "../../../components/common/Modal/SubscriptionBundlePop";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ConfirmDeleteSubscriptionBundle from "../../../components/common/Modal/ConfirmDeleteSubscriptionBundle";
import moment from "moment";
import ConfirmDeleteTrailLinkModal from "../../../components/common/Modal/ConfirmDeleteTrailLinkModal";
import { useTranslation } from "react-i18next";

const SubscriptionPriceBundle = () => {
  const { t } = useTranslation();
  const [StoryPromotion, setStoryPromotion] = useState(false);
  const handleStoryPromotion = () => {
    setStoryPromotion(!StoryPromotion);
  };
  const [showTrailLinkModal, setShowTrailLinkModal] = useState(false);
  const [trailLinkList, setTrailLinkList] = useState([]);
  const [showSubscriptionBundle, setShowSubscriptionBundle] = useState(false);
  const [subscriptionBundle, setSubscriptionBundle] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState({});
  const [selectedTrailLink, setSelectedTrailLink] = useState(false);
  const [openConfirmTrailDeleteModal, setOpenConfirmTrailDeleteModal] =
    useState(false);
  const [storyPromotionList, setStoryPromotionList] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState();

  const handleTrialLink = () => {
    setShowTrailLinkModal(!showTrailLinkModal);
  };
  const { user, buttonLoader, trailList } = useSelector((s) => s.setting);

  const dispatch = useDispatch();

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    if (user?.subscribePrice > 0) {
      setSubscriptionType("paid");
    } else {
      setSubscriptionType("free");
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      subscribePrice: "",
    },
    validationSchema: Yup.object({
      subscribePrice: Yup.number()
        .required(t("Subscribe Price is required"))
        .test(
          "is-greater-than-5",
          t("Price must be greater than 5"),
          function (value) {
            return value === 0 || value > 5;
          }
        ),
    }),
    onSubmit: async function (values, { resetForm }) {
      dispatch(handleSubscribePrice(values))
        .unwrap()
        .then((res) => {
          getUserData();
          toast.success(res?.message);
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
  });

  const getTrailsListing = () => {
    const data = {
      limit: 100,
      page: 1,
    };
    dispatch(trailListing(data));
  };

  useEffect(() => {
    getUserData();
    getTrailsListing();
  }, []);

  useEffect(() => {
    if (user) {
      formik.setFieldValue("subscribePrice", user?.subscribePrice);
      setSubscriptionBundle(user?.subscriptionBundles);
      setStoryPromotionList(user?.promotions);
    }
  }, [user]);

  const handleBundleSelect = (dta) => {
    setSelectedBundle(dta);
    setShowConfirmDeleteModal(true);
  };

  const confirmDeleteBundle = () => {
    const data = {
      bundleId: selectedBundle?._id,
    };
    dispatch(removeSubscriptionBundle(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getUserData();
        setShowConfirmDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const confirmDeleteTrailLink = () => {
    const data = {
      data: { trialId: selectedTrailLink?._id },
    };
    dispatch(removeTrail(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getTrailsListing();
        setOpenConfirmTrailDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const stopStoryPromotion = (dta) => {
    const data = {
      promotionId: dta?._id,
    };
    dispatch(stopPromotion(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getUserData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  const deleteStoryPromotion = (dta) => {
    const data = {
      data: {
        promotionId: dta?._id,
      },
    };
    dispatch(removePromtion(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getUserData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const path = `https://fansroom.com/${user?.userName}`;

  return (
    <>
      <Settings>
        <section className="custom_order px-0 pb-3">
          <div>
            <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
              <Link to="/home">
                <div className="notification_left_heading d-flex align-items-center">
                  <h5>{t("Subscription")}</h5>
                </div>
              </Link>
            </div>

            <div className="edit_Profiletab mt-3">
              <div className="bottom_pannel mt-1 pt-3 border-top border-3">
                <div>
                  <label htmlFor="subscriptionType" className="form-label">
                    Subscription Type
                  </label>
                  <div>
                    <FormGroup check inline>
                      <Input
                        style={{ padding: "6px", height: "0px", width: "0px" }}
                        type="radio"
                        name="subscriptionType"
                        id="freeSubscription"
                        checked={subscriptionType === "free"}
                        onChange={() => {
                          setSubscriptionType("free");
                          formik.setFieldValue("subscribePrice", 0);
                        }}
                      />
                      <label
                        htmlFor="freeSubscription"
                        className="form-check-label"
                      >
                        Free Subscription
                      </label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input
                        style={{
                          padding: "6px",
                          height: "0px",
                          width: "0px",
                        }}
                        type="radio"
                        name="subscriptionType"
                        id="paidSubscription"
                        checked={subscriptionType === "paid"}
                        onChange={() => setSubscriptionType("paid")}
                      />
                      <label
                        htmlFor="paidSubscription"
                        className="form-check-label"
                      >
                        Paid Subscription
                      </label>
                    </FormGroup>
                  </div>
                </div>

                <div>
                  {subscriptionType === "paid" && (
                    <>
                      <label htmlFor="" className="form-label">
                        Price Per Month
                      </label>
                      <FormGroup>
                        <Input
                          type="number"
                          name="subscribePrice"
                          id="subscribePrice"
                          placeholder="Price per month"
                          value={formik.values.subscribePrice}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormGroup>
                      {/* <p className="Minimum ">
                  {t("Minimum $4.99 USD or free")}
                  {t("Price must be greater than 5")}
                </p> */}

                      {formik.touched.subscribePrice &&
                        formik.errors.subscribePrice && (
                          <span className="text-danger errorText">
                            {formik.errors.subscribePrice}
                          </span>
                        )}
                    </>
                  )}
                  <div className="btnWrpper d-flex justify-content-end gap-3 align-items-center ">
                    <Button
                      className="btnborder d-inline-flex align-items-center justify-content-center rounded-pill common-btn btnSm"
                      onClick={() => {
                        formik.setFieldValue("subscribePrice", 0);
                        setSubscriptionType("free");
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                    {buttonLoader ? (
                      <Button className=" d-inline-flex align-items-center justify-content-center rounded-pill common-btn btnSm">
                        <SmallLoader color={"text-light"} />
                      </Button>
                    ) : (
                      <Button
                        className=" d-inline-flex align-items-center justify-content-center rounded-pill common-btn btnSm"
                        onClick={formik.handleSubmit}
                      >
                        {t("Save")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="py-3 px-3 mt-3 HeadTitlePara noLastBorder border-top">
                <div className="py-2 InnerWrp">
                  <h6 className="fw-dark m-0">{t("Subscription bundles")}</h6>
                  <p className="m-0 py-1 text-muted fw-sbold">
                    {t(
                      "Offer several months of subscription as a discounted bundle."
                    )}
                  </p>
                  <div className="btnWrp mt-2 border-top py-2">
                    <Button
                      disabled={user?.subscribePrice <= 5}
                      onClick={() => setShowSubscriptionBundle(true)}
                      className="d-flex align-items-center justify-content-center common-btn btnSm w-100 rounded-pill"
                    >
                      {t("Add Bundle")}
                    </Button>
                    {Array.isArray(subscriptionBundle) &&
                      subscriptionBundle?.map((itm, ind) => {
                        const amount = itm?.price;

                        return (
                          <div
                            className="d-flex justify-content-between align-items-center mx-3 my-2 py-2 border-bottom btnWrp border-top"
                            key={ind}
                          >
                            <div className="text-small">
                              $ {amount} {t("USD total for")} {itm?.duration}{" "}
                              {t("months")}
                            </div>
                            <div className="d-flex">
                              - {itm?.discount}%{" "}
                              <AiOutlineCloseCircle
                                size={22}
                                color="gray"
                                className="mx-3 pointer"
                                onClick={() => handleBundleSelect(itm)}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="py-2 InnerWrp">
                  <h6 className="fw-dark m-0">
                    {t("Profile promotion campaign")}
                  </h6>
                  <p className="m-0 py-1 text-muted fw-sbold">
                    {t(
                      " Offer a free trial or a discounted subscription on your profile for a limited number of new or already expired subscribers."
                    )}{" "}
                  </p>
                  <div className="btnWrp mt-2 border-top py-2">
                    <Button
                      onClick={handleStoryPromotion}
                      className="d-flex align-items-center justify-content-center common-btn btnSm w-100 rounded-pill"
                    >
                      {t("Start Promotion Compaign")}
                    </Button>
                  </div>
                </div>
                {storyPromotionList?.map((item, index) => {
                  return (
                    <div className="py-2 InnerWrp" key={index}>
                      <h6 className="fw-dark m-0">
                        {t("Limited offer")} - {item?.campaign}{" "}
                        {t("trial for days!")}
                      </h6>
                      <p className="m-0 py-1 text-muted fw-sbold">
                        {t("For new subscribers ends")}{" "}
                        {moment(item?.finishedAt).format("MMMM DD , YYYY")}
                      </p>
                      <div className="Content">
                        <div className="d-flex align-items-center gap-10 bg-light p-2 rounded profile">
                          <div className="imgWrp">
                            <img
                              src={user?.profileImage}
                              alt=""
                              style={{ height: "40px" }}
                              className="img-fluid rounded-circle"
                            />
                          </div>
                          <div className="contnet">
                            <h6 className="m-0 fw-sbold py-">
                              {item?.message}
                            </h6>
                          </div>
                        </div>
                        <ul className="list-unstyled ps-0 mb-0">
                          <li className="d-flex py-2 my-2 align-items-center justify-content-between gap-10">
                            <p className="text-muted m-0">{t("Started")}</p>
                            <p className="text-dark m-0 fw-bold">
                              {moment(item?.createdAt).format("MMMM DD , YYYY")}
                            </p>
                          </li>
                          <li className="d-flex border-top py-2 my-2 align-items-center justify-content-between gap-10">
                            <p className="text-muted m-0">
                              {t("Claims count")}
                            </p>
                            <p className="text-dark m-0 fw-bold">
                              {item?.claimsCount}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="btnWrp mt-2 border-top py-2 d-flex align-items-center justify-content-center gap-10">
                        {item?.hasRelatedPromo === false ? (
                          <Button
                            className="d-flex align-items-center justify-content-center common-btn btnSm w-100 rounded-pill"
                            onClick={() => stopStoryPromotion(item)}
                          >
                            {t("Stop Compaign")}
                          </Button>
                        ) : (
                          <Button
                            className="d-flex align-items-center justify-content-center common-btn btnSm w-100 rounded-pill"
                            onClick={() => deleteStoryPromotion(item)}
                          >
                            {t("Delete Compaign")}
                          </Button>
                        )}

                        <CopyToClipboard text={path}>
                          <Button className="d-flex align-items-center justify-content-center common-btn btnSm w-100 rounded-pill">
                            {t("Copy Link To Profile")}
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}

                <div className="py-2 InnerWrp">
                  <h6 className="fw-dark m-0">{t("Trial Links")}</h6>
                  <p className="m-0 py-1 text-muted fw-sbold">
                    {t(
                      "Create and share separate links with free trial subscription."
                    )}
                  </p>
                  <div className="btnWrp mt-2 border-top py-2">
                    <Button
                      onClick={handleTrialLink}
                      className="d-flex align-items-center justify-content-center common-btn btnSm w-100 rounded-pill"
                    >
                      {t("Create New Free Trial Link")}
                    </Button>
                  </div>
                  {trailList?.map((itm, ind) => {
                    return (
                      <div className="btnWrp mt-3" key={ind}>
                        <h6 className="text-normal">
                          ({itm?.trialLinkName}) {itm?.subscribeDays}{" "}
                          {t("days free")}
                          {t("trail")}
                        </h6>
                        <div className="border-bottom d-flex justify-content-between align-items-center">
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {t("Link Created")}
                          </h5>
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {moment(itm?.createdAt).format("MMMM DD , YYYY")}
                          </h5>
                        </div>
                        <div className="border-bottom d-flex justify-content-between align-items-center">
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {t("Link Expires")}
                          </h5>
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {moment(itm?.expiredAt).format("MMMM DD , YYYY")}
                          </h5>
                        </div>
                        <div className="border-bottom d-flex justify-content-between align-items-center">
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {t("Offer Limit")}
                          </h5>
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {itm?.subscribeCounts}
                          </h5>
                        </div>
                        <div className="border-bottom d-flex justify-content-between align-items-center">
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {t("Claims Count")}
                          </h5>
                          <h5 className="text-sm mb-0 py-2 mx-2">
                            {itm?.claimCounts}
                          </h5>
                        </div>
                        <div className="d-flex justify-content-end align-items-end my-3">
                          <button
                            className="linkBtn"
                            onClick={() => {
                              setSelectedTrailLink(itm);
                              setOpenConfirmTrailDeleteModal(true);
                            }}
                          >
                            {t("Delete")}
                          </button>

                          <CopyToClipboard text={itm?.url}>
                            <button className="linkBtn">
                              {t("Copy Link")}
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Settings>
      <StoryPromotionPop
        StoryPromotion={StoryPromotion}
        setStoryPromotion={setStoryPromotion}
        getUserData={getUserData}
      />
      <FreeTrialLinkPop
        showTrailLinkModal={showTrailLinkModal}
        // onHide={() => setShowTrailLinkModal(false)}
        getTrailsListing={getTrailsListing}
        setShowTrailLinkModal={setShowTrailLinkModal}
      />
      <SubscriptionBundlePop
        show={showSubscriptionBundle}
        onHide={() => setShowSubscriptionBundle(false)}
        getUserData={getUserData}
        subscriptionBundle={subscriptionBundle}
      />
      <ConfirmDeleteSubscriptionBundle
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
        confirmDelete={confirmDeleteBundle}
      />
      <ConfirmDeleteTrailLinkModal
        show={openConfirmTrailDeleteModal}
        onHide={() => setOpenConfirmTrailDeleteModal(false)}
        confirmDelete={confirmDeleteTrailLink}
      />
    </>
  );
};

export default SubscriptionPriceBundle;
