import React, { Fragment, useDebugValue, useEffect, useState } from "react";
import User1 from "../../../images/user13.png";
import User2 from "../../../images/user14.png";
import User3 from "../../../images/user15.png";
import { useDispatch, useSelector } from "react-redux";
import { allArtistGroupList, deleteList } from "../../../redux/actions";
import { toast } from "react-toastify";
import User from "../../../images/user.png";
import { useTranslation } from "react-i18next";
import ConfirmDeleteList from "../../common/Modal/ConfirmDeleteList";
import { AiOutlineCloseCircle } from "react-icons/ai";

const UserList = (props) => {
  const {
    setUserList,
    setPostList,
    setShowBlockedList,
    showAll,
    handleFollowing,
    handleRestriction,
    handleBlocked,
    handleGroupList,
    setActiveBoxx,
    activeBoxx,
    searchInput,
    allUsers,
  } = props;
  const dispatch = useDispatch();
  const allPosts = useSelector((s) => s.home);
  const { t } = useTranslation();
  const [showDeleteListModal, setShowDeleteModal] = useState(false);
  const [selectedList, setSelectedList] = useState({});
  const { allGroupList } = useSelector((s) => s.artist);
  const { buttonLoader } = useSelector((s) => s.collection);

  const [mainList, setMainList] = useState([]);
  const [secondList, setSecondList] = useState([]);

  const mainList2 = [
    { name: t("All"), title: "all" },
    { name: t("Following"), title: "fallowerList" },
    { name: t("Restricted"), title: "restrictedList" },
    { name: t("Blocked"), title: "blockedList" },
  ];

  const [followingData, setFolowingData] = useState({
    count: 0,
    list: [],
  });
  const [restrictedData, setRestrictedData] = useState({
    count: 0,
    list: [],
  });
  const [blockedData, setBlockedData] = useState({
    count: 0,
    list: [],
  });

  const getAllArtistGroupList = () => {
    const data = {
      limit: 100,
      page: 1,
    };
    dispatch(allArtistGroupList(data));
  };

  useEffect(() => {
    getAllArtistGroupList();
  }, []);

  useEffect(() => {
    var followingCount = 0;
    var restrictedCount = 0;
    var blockedCount = 0;

    allGroupList?.fallowerList?.map((itm) => {
      followingCount = followingCount + Number(itm.post?.length) || 0;
    });
    setFolowingData({
      count: followingCount,
      list: allGroupList?.fallowerList?.slice(0, 3),
    });
    allGroupList?.blockedList?.map((itm) => {
      blockedCount = blockedCount + Number(itm.post?.length) || 0;
    });
    setBlockedData({
      count: blockedCount,
      list: allGroupList?.blockedList?.slice(0, 3),
    });
    allGroupList?.restrictedList?.map((itm) => {
      restrictedCount = restrictedCount + Number(itm.post?.length) || 0;
    });
    setRestrictedData({
      count: restrictedCount,
      list: allGroupList?.restrictedList?.slice(0, 3),
    });
    setSecondList(allGroupList?.favList);
  }, [allGroupList]);

  const confirmDelete = () => {
    const data = {
      favId: selectedList?._id,
    };
    dispatch(deleteList(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setSelectedList({});
        setShowDeleteModal(false);
        getAllArtistGroupList();
      })
      .catch((err) => {
        toast.error(err?.message);
        setShowDeleteModal(false);
        setSelectedList({});
      });
  };

  const handleListRemove = (dta) => {
    setSelectedList(dta);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (searchInput === "") {
      setMainList(mainList2);
      setSecondList(allGroupList?.favList);
    } else {
      const filteredData = mainList2?.filter((itm) => {
        return itm?.name?.toLowerCase()?.includes(searchInput?.toLowerCase());
      });
      const filteredData2 = allGroupList?.favList?.filter((itm) => {
        return itm?.listName
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase());
      });
      setMainList(filteredData);
      setSecondList(filteredData2);
    }
  }, [searchInput]);

  const getImageOrName = (data) => {
    return data?.profileImage ? (
      <img src={data?.profileImage} alt="" className="user1 shadow2" />
    ) : (
      <div
        className="user1 shadow2"
        style={{
          padding: "10px",
          fontSize: "16px",
          borderRadius: "50%",
        }}
      >
        {data?.firstName && data?.lastName
          ? data?.firstName?.slice(0, 1) + data?.lastName?.slice(0, 1)
          : data?.displayName?.slice(0, 1) || data?.name?.slice(0, 1)}
      </div>
    );
  };

  return (
    <div className="px-3 py-1">
      <div className="d-flex flex-wrap justify-content-between align-items-center px-2">
        <h5 className="text-custom">{t("CUSTOM ORDER")}</h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g opacity="0.5">
              <path
                d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                fill="black"
              />
            </g>
          </svg>
        </h5>
      </div>

      {mainList?.map((item, index) => {
        return (
          <Fragment>
            {item?.name === "All" && (
              <div
                onClick={() => {
                  showAll();
                  setActiveBoxx("all");
                }}
                className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer ${
                  activeBoxx === "all" && "activeBox"
                }`}
              >
                <div className="custmUserbox ">
                  <h6 className="text-small mb-0">{t("All")} </h6>
                  <h6 className="mb-0">
                    <span>
                      {allUsers?.length} {t("users")} {allPosts?.list?.length}
                      {t("posts")}
                    </span>
                  </h6>
                </div>
                <div className="cstomImgbox">
                  {followingData?.list?.map((itm) => {
                    return <> {getImageOrName(itm?.followerDetails)}</>;
                  })}
                </div>
              </div>
            )}
            {item?.name === "Following" && (
              <div
                onClick={handleFollowing}
                className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer ${
                  activeBoxx == "following" && "activeBox"
                }`}
              >
                <div className="custmUserbox ">
                  <h6 className="text-small mb-0">{t("Following")}</h6>
                  <h6 className="mb-0">
                    <span>
                      {allGroupList?.fallowerList?.length} {t("users")}{" "}
                      {followingData?.count} {t("posts")}
                    </span>
                  </h6>
                </div>
                <div className="cstomImgbox">
                  {followingData?.list?.map((itm) => {
                    return <>{getImageOrName(itm?.followerDetails)}</>;
                  })}
                </div>
              </div>
            )}
            {item?.name === "Restricted" && (
              <div
                onClick={handleRestriction}
                className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer ${
                  activeBoxx == "restricted" && "activeBox"
                }`}
              >
                <div className="custmUserbox ">
                  <h6 className="text-small mb-0">{t("Restricted")}</h6>
                  <h6 className="mb-0">
                    <span>
                      {allGroupList?.restrictedList?.length} {t("users")}{" "}
                      {restrictedData?.count} {t("posts")}
                    </span>
                  </h6>
                </div>
                <div className="cstomImgbox">
                  {restrictedData?.list?.map((itm) => {
                    return <> {getImageOrName(itm?.artistDetails)}</>;
                  })}
                </div>
              </div>
            )}
            {item?.name === "Blocked" && (
              <div
                onClick={handleBlocked}
                className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer ${
                  activeBoxx == "blocked" && "activeBox"
                }`}
              >
                <div className="custmUserbox ">
                  <h6 className="text-small mb-0">{t("Blocked")}</h6>
                  <h6 className="mb-0">
                    <span>
                      {allGroupList?.blockedList?.length} {t("users")}{" "}
                      {blockedData?.count} {t("posts")}
                    </span>
                  </h6>
                </div>
                <div className="cstomImgbox">
                  {blockedData?.list?.map((itm) => {
                    return <> {getImageOrName(itm?.blockedUserDetails)}</>;
                  })}
                </div>
              </div>
            )}
          </Fragment>
        );
      })}

      {secondList?.map((itm, ide) => {
        return (
          <div
            className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer position-relative ${
              activeBoxx == itm?.listName && "activeBox"
            }`}
            key={ide}
            onClick={() => {
              handleGroupList(itm);
              setActiveBoxx(itm?.listName);
            }}
          >
            <div className="custmUserbox d-flex align-items-center ">
              <div>
                <h6 className="text-small mb-0">{itm?.listName}</h6>
                <h6 className="mb-0">
                  <span>
                    {itm?.artistDetails?.length} {t("users")}{" "}
                    {itm?.post?.length} {t("posts")}
                  </span>
                </h6>
              </div>
            </div>
            <div className="cstomImgbox">
              {itm?.artistDetails?.slice(0, 3)?.map((artist) => {
                return <> {getImageOrName(artist)}</>;
              })}
            </div>
            <div className="removeListIcon pointer">
              <AiOutlineCloseCircle
                size={18}
                onClick={() => handleListRemove(itm)}
              />
            </div>
          </div>
        );
      })}
      <ConfirmDeleteList
        show={showDeleteListModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
        loading={buttonLoader}
      />
    </div>
  );
};

export default UserList;
