import React, { useState } from "react";
import Sidebar from "../components/common/sidebar/Sidebar";
import { Col, Container, Row } from "react-bootstrap";
import Mobilefooter from "../components/common/Mobilefooter";
import { useLocation } from "react-router-dom";

const MainLayout = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);

  let location = useLocation();

  return (
    <>
      <main className="">
        <section className="vierwrap py-lg-3">
          <Container fluid>
            <Row className="align-items-start position-relative">
              <Col
                lg="3"
                className={`${
                  showSideBar
                    ? "sideBarContActive sticky-top"
                    : "sideBarCont sticky-top"
                }`}
              >
                <Sidebar
                  setShowSideBar={setShowSideBar}
                  openState={[showSideBar, setShowSideBar]}
                />
              </Col>
              <Col lg="9" md="12" className="paddid_unset">
                <div
                  className={`${
                    location.pathname.includes("/messages/")
                      ? "chat_mob_padding"
                      : "mobile_pad_bottom"
                  }`}
                >
                  {children}
                </div>
              </Col>
            </Row>

            <div className="mobile_footer">
              <Mobilefooter setShowSideBar={setShowSideBar} />
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default MainLayout;
