import React, { useState } from "react";
import ReactPlayer from "react-player";
const CustomVideoPlayer = ({ mediaItem, setMediaData, setVisible }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const handleShowMedia = () => {
    setMediaData({
      url: mediaItem?.url,
      mediaType: mediaItem?.type,
    });
    setVisible(true);
  };

  const handlePreventDownload = (e) => {
    // Prevent the default context menu to disable the download option
    e.preventDefault();
  };

  const handlePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handleToggleMute = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {/* Custom overlay to prevent download */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          background: "transparent",
        }}
        onClick={handlePreventDownload}
        onContextMenu={handlePreventDownload}
      >
        {/* Play/Pause button at the center */}
        <button
          onClick={handlePlayPause}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            background: "transparent",
            color: "white",
            border: "none",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        >
          {isPlaying ? (
            ""
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="white"
              class="bi bi-play-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5" />
            </svg>
          )}
        </button>
        {/* Mute/Unmute button at the bottom right corner */}
        <button
          onClick={handleToggleMute}
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            zIndex: 2,
            background: "transparent",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          {isMuted ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-volume-mute-fill"
              viewBox="0 0 16 16"
            >
              <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-volume-up-fill"
              viewBox="0 0 16 16"
            >
              <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
              <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89z" />
              <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
            </svg>
          )}
        </button>
      </div>

      {/* ReactPlayer component */}
      <ReactPlayer
        config={{
          file: { attributes: { controlsList: "nodownload" } },
        }}
        onContextMenu={handlePreventDownload}
        url={mediaItem?.url}
        width="100%"
        height="auto"
        playing={isPlaying}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onClick={handleShowMedia}
        volume={isMuted ? 0 : 1}
        className="custom-player"
        
      />
    </div>
  );
};

export default CustomVideoPlayer;
