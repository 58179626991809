import React from "react";
import { Modal } from "react-bootstrap";
import User from "../../../images/user.png";
import { useTranslation } from "react-i18next";
import { BiSolidSend } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

const CommentSection = (props) => {
  const {
    showCommentPop,
    handleCommentPop,
    commentBoxRef,
    msg,
    setMsg,
    data,

    handleKeyPress,
    sendMsg,
  } = props;

  const { t } = useTranslation();

  const { isAuthenticated } = useSelector((s) => s.login);

  return (
    <Modal
      show={showCommentPop && isAuthenticated}
      onHide={handleCommentPop}
      // backdrop="static"
      keyboard={false}
      centered
      scrollable="true"
    >
      <Modal.Body>
        <div className="close-chat" onClick={handleCommentPop}>
          <AiOutlineCloseCircle size={17} />
        </div>

        <div className="commentSection p-0 bg-transparent" ref={commentBoxRef}>
          <div className="d-flex justify-content-between px-3">
            <input
              type="text"
              className="inputComment"
              onChange={(e) => setMsg(e.target.value)}
              value={msg}
              placeholder={t("Leave me a comment")}
              onKeyDown={(e) => handleKeyPress(e, data)}
            />
            <div
              onClick={() => sendMsg(data)}
              className="rounded-circle d-flex justify-content-center align-items-center chatIcn shadow2"
            >
              <BiSolidSend size={20} color />
            </div>
          </div>
          <div className="px-3 py-2 commentList ">
            {data?.comments?.map((comment, index) => {
              const userDetails = comment?.userDetails;
              return (
                <div className="commentItem mb-2">
                  <div className="commentUser">
                    <img src={userDetails?.profileImage || User} alt="" />
                  </div>
                  <div className="commentUserDtls">
                    <h6 className="mb-1">{userDetails?.userName}</h6>
                    <h6 className="contnent">{comment?.comment}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* )} */}
      </Modal.Body>
    </Modal>
  );
};

export default CommentSection;
