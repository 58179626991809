import { createSlice } from "@reduxjs/toolkit";
import { getPostDetails } from "./thunk";
import { status } from "../../constants";

const initialState = {
  loader: false,
  status: status.IDLE,
  postDetails: {},
};

const slice = createSlice({
  name: "postDetails",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPostDetails.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getPostDetails.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.postDetails = action.payload?.data;
    });
    builder.addCase(getPostDetails.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.postDetails = initialState.postDetails;
    });
  },
});

export default slice.reducer;
