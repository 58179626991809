import React from "react";
import LoginComponent from "../../components/AuthComponent/Login";
import AuthLayout from "../../layout/AuthLayout";

const Login = () => {
  return (
    <>
      <AuthLayout>
        <LoginComponent />
      </AuthLayout>
    </>
  );
};

export default Login;
