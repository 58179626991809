import React from "react";
import MainLayout from "../../../layout/MainLayout";
import NewPostComponent from "../../../components/SideTabs/NewPost";

const NewPost = () => {
  return (
    <MainLayout>
      <NewPostComponent />
    </MainLayout>
  );
};

export default NewPost;
