import React, { useState, useEffect, Fragment } from "react";
import MainLayout from "../../../../layout/MainLayout";
import { Button, Col, Dropdown, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import User from "../../../../images/user.png";
import { useDispatch, useSelector } from "react-redux";
import {
  artistList,
  sendMassMessage,
  subscriberList,
  uploadFile,
} from "../../../../redux/actions";
import {
  BsArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { BsTag } from "react-icons/bs";
import { TbTagOff, TbTag } from "react-icons/tb";
import AudioRecorders from "../../../../components/common/Modal/EntertainerModal/AudioRecorderModal";
import VideoRecorederModa from "../../../../components/common/Modal/EntertainerModal/VideoRecorederModa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { BiVideo, BiMicrophone } from "react-icons/bi";
import { MdSlowMotionVideo } from "react-icons/md";
import axios from "axios";
import { endpoints } from "../../../../endpoints";
import { AiOutlineCloseCircle, AiOutlineTag } from "react-icons/ai";
import ReactPlayer from "react-player";
import SmallLoader from "../../../../components/common/ButtonLoader/SmallLoader";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import MessagePrice from "../../../../components/common/Modal/MessagePrice";
import ProgressBarModal from "../../../../components/common/Modal/ProgressBarModal";
import Allmedia from "../../../../components/SideTabs/Vault/Allmedia";
import CustomVideoPlayer from "../../../../components/CustomVideoPlayer";

const NewMessage = () => {
  const { t } = useTranslation();
  const artistDetails = useSelector((s) => s.artist);
  const artistLists = artistDetails?.list;
  const subscriberLists = artistDetails?.subscriberList;
  const [chatSearch, setChatSearch] = useState();
  const [searchInput, setSearchInput] = useState();
  const { imageLoader } = useSelector((s) => s.common);

  const dispatch = useDispatch();

  const [followerListToBeShown, setFollowerListToBeShown] = useState([]);
  const [allFollowedArtist, setAllFollowedArtist] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [showAudioRecorder, setShowAudioRecorder] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState(null);
  const [showMessagePriceModal, setShowMessagePriceModal] = useState(false);
  const [messagePrice, setMessagePrice] = useState(0);
  const [allMedia, setAllMedia] = useState([]);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [messageType, setMessageType] = useState("free");

  const [attachment, setAttachement] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const { buttonLoader } = useSelector((s) => s.message);
  const [chatMessage, setChatMessage] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [activeKey, setActiveKey] = useState({
    following: true,
    follower: false,
  });

  const handleFileUpload = (e) => {
    const files = e.target.files;
    setAttachmentType("image");
    var allFileType = [];
    const formdata = new FormData();
    for (var i = 0; i < files.length; i++) {
      formdata.append("file", files[i]);
      const fileType = files[i]?.type.split("/")[0];
      allFileType.push(fileType);
    }
    setShowProgressModal(true);
    axios
      .post(
        endpoints.common.uploadFile,
        formdata,
        {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent?.loaded * 100) / progressEvent?.total
            );
            setUploadedPercent(percentage);
          },
        },
        (error, response) => {
          if (error) {
            toast.error("Data Uploading Failed");
            setShowProgressModal(false);
          } else {
            toast.success(t("File uploaded successfully"));
          }
        }
      )
      .then((res) => {
        const url = res?.data?.data?.url;
        setShowProgressModal(false);
        setUploadedPercent(0);
        if (Array.isArray(url)) {
          const data = url.map((itm, ind) => {
            return {
              type: allFileType[ind],
              videoKey: "thumbnail",
              url: itm,
              priceType: "free",
            };
          });
          setAllMedia((prev) => [...prev, ...data]);
        } else {
          const data = {
            type: allFileType[0],
            videoKey: "thumbnail",
            url: url,
            priceType: "free",
          };
          setAllMedia((prev) => [...prev, data]);
        }
      })
      .catch((err) => {
        setUploadedPercent(0);
        toast.error(err.message);
        setShowProgressModal(false);
      });
  };

  const getArtistList = () => {
    const data = {
      limit: 100,
      page: 1,
      isFollowing: true,
    };
    dispatch(artistList(data));
  };

  const getSubscriberList = () => {
    const data = {
      limit: 200,
      page: 1,
      search: searchInput,
    };
    dispatch(subscriberList(data));
  };

  useEffect(() => {
    if (activeKey.follower) getSubscriberList();
  }, [activeKey, searchInput]);

  useEffect(() => {
    getArtistList();
  }, []);

  useEffect(() => {
    var followingList = artistLists?.filter((item) => {
      return item?.isFollowing === true;
    });
    setAllFollowedArtist(followingList);
    setFollowerListToBeShown(followingList);
  }, [artistLists]);

  const handleUserSelect = (dta) => {
    const ddd = selectedUser?.find((itm) => {
      return itm?._id === dta?._id;
    });
    if (ddd) {
      const filterDta = selectedUser?.filter((itm) => {
        return itm?._id !== dta?._id;
      });
      setSelectedUser(filterDta);
    } else {
      setSelectedUser((prev) => {
        return [...prev, dta];
      });
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInput(val);
    if (activeKey.following) {
      const filteredData = allFollowedArtist?.filter((itm) => {
        return itm?.name?.toLowerCase().includes(val?.toLowerCase());
      });
      setFollowerListToBeShown(filteredData);
    }
  };

  // here i am write code for uploadin audio and video;

  const saveVideo = () => {
    setAttachmentType("video");
    const formdata = new FormData();
    formdata.append("file", videoData?.[0]);
    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        const data = {
          type: "video",
          videoKey: "thumbnail",
          url: res?.data?.url,
          priceType: "free",
        };
        setAllMedia((rest) => [...rest, data]);
        setShowVideoRecorder(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveAudio = () => {
    if (audioData) {
      setAttachmentType("audio");
      const formdata = new FormData();
      formdata.append("file", audioData);
      dispatch(uploadFile(formdata))
        .unwrap()
        .then((res) => {
          const data = {
            type: "audio",
            videoKey: "thumbnail",
            url: res?.data?.url,
            priceType: "free",
          };
          setAllMedia((rest) => [...rest, data]);
          setShowAudioRecorder(false);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.warning("Please record audio first");
    }
  };

  const handleMessageSend = async () => {
    if (selectedUser?.length !== 0) {
      const selectedUserId = selectedUser?.map((itm) => {
        return itm?._id;
      });
      let data = {
        msg: chatMessage,
        attachment: Array.isArray(allMedia) ? allMedia : [],
        otherUserId: selectedUserId,
        price: Number(messagePrice),
        messageType: messageType,
      };

      dispatch(sendMassMessage(data))
        .unwrap()
        .then((res) => {
          toast.success(t("message sent successfully"));
          setChatMessage("");
          setAllMedia([]);
          setMessageType("free");
          setMessagePrice(0);
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleMessageSend();
    }
  };

  const handleAllSelection = () => {
    if (selectAll) {
      setSelectedUser([]);
      setSelectAll(false);
    } else {
      const data = activeKey?.following
        ? followerListToBeShown
        : subscriberLists?.map((val) => val.userDetails);
      setSelectedUser(data);
      setSelectAll(true);
    }
  };

  const handleRemoveMedia = (id) => {
    const filteredData = allMedia?.filter((itm, index) => {
      return index !== id;
    });
    setAllMedia(filteredData);
  };

  const handleMediaType = (id, type) => {
    const filteredData = allMedia?.filter((itm, index) => {
      return index !== id;
    });
    const selectedData = allMedia?.find((itm, index) => {
      return index === id;
    });
    var ddd = {
      ...selectedData,
      priceType: type,
    };

    setAllMedia([...filteredData, ddd]);
  };

  const getImageOrName = (data) => {
    return data?.profileImage ? (
      <div className="user_img mx-2">
        <img src={data?.profileImage} alt="" />
      </div>
    ) : (
      <div
        className="user_img_name shadow2 mx-2"
        style={{
          height: "50px",
          width: "50px",
          fontSize: "16px",
        }}
      >
        {data.firstName && data?.lastName
          ? data?.firstName?.slice(0, 1) + data?.lastName?.slice(0, 1)
          : data?.displayName?.slice(0, 1) || data?.name?.slice(0, 1)}
      </div>
    );
  };
  return (
    <>
      <MainLayout>
        <Row className="  h-100 d-flex justify-content-between ">
          <Col lg="5" md="12" sm="12">
            <div className="shadow1 rounded border h-100 px-3 ">
              <div className="chatTop border-bottom pt-3">
                <div
                  className="d-flex justify-content-between align-items-center border-bottom"
                  style={{ height: "50px" }}
                >
                  <div className="d-flex align-items-center msgTitle gap-10 justify-content-between">
                    <Link to="/messages" className="pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 12L11 6M5 12L11 18M5 12L19 12"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>
                    <h6 className="mb-0 ms-0">{t("New Message")}</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center msgTitle gap-10 justify-content-between py-2">
                  <div
                    style={{
                      backgroundColor: activeKey.following
                        ? "lightgray"
                        : "white",
                      padding: "2px 20px",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (selectAll) {
                        handleAllSelection();
                      }
                      setActiveKey({
                        following: true,
                        follower: false,
                      });
                    }}
                  >
                    <h6 className="mb-0 ms-0 text-muted">{t("Following")}</h6>
                  </div>
                  <div
                    style={{
                      backgroundColor: activeKey.follower
                        ? "lightgray"
                        : "white",
                      padding: "2px 20px",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (selectAll) {
                        handleAllSelection();
                      }
                      setActiveKey({
                        following: false,
                        follower: true,
                      });
                    }}
                  >
                    <h6 className="mb-0 ms-0 text-muted">{t("Followers")}</h6>
                  </div>
                </div>
                <Form className=" w-100 position-relative h-100 bg-light border-bottom">
                  <div className="position-relative searchForm icon-with-text">
                    <input
                      type="Search"
                      placeholder="Search...."
                      className="form-control border-0 rounded-0 bg-transparent"
                      value={searchInput}
                      onChange={(e) => handleSearch(e)}
                    />
                    <span className="icn position-absolute d-inline-block ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </div>
                </Form>

                <div className="d-flex align-items-center msgTitle gap-10 justify-content-between py-2">
                  <div className="left">
                    <h6 className="mb-0 ms-0 text-muted fw-bold">
                      {t("Select All")}
                    </h6>
                  </div>
                  <div className="right d-flex align-items-center gap-10">
                    <div className="three_dot_dropdown">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="mx-3 pointer"
                        checked={selectAll}
                        onChange={handleAllSelection}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* chat list */}
              <div className="chatCont h-auto">
                {activeKey.following ? (
                  <>
                    {followerListToBeShown?.map((artist, index) => {
                      var checked = selectedUser?.find(
                        (itm) => itm?._id === artist?._id
                      );
                      return (
                        <div
                          key={index}
                          className="my-2 px-2 d-flex justify-content-between align-items-center mx-2 pointer  py-2 false"
                        >
                          <div className="d-flex align-items-center">
                            {getImageOrName(artist)}
                            <div className="">
                              <h5 className="msgUserName capitalize">
                                {artist?.firstName +
                                  " " +
                                  (artist.lastName || "")}
                              </h5>
                              <h6 className="userchat">
                                {t("start chatting..")}
                              </h6>
                            </div>
                          </div>
                          <div className="chatTime d-flex flex-column justify-content-end">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              className="pointer"
                              checked={checked}
                              onChange={() => handleUserSelect(artist)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {subscriberLists?.map((value, index) => {
                      var checked = selectedUser?.find(
                        (itm) => itm?._id === value?.userDetails?._id
                      );
                      return (
                        <div
                          key={index}
                          className="my-2 px-2 d-flex justify-content-between align-items-center mx-2 pointer  py-2 false"
                        >
                          <div className="d-flex align-items-center">
                            {getImageOrName(value?.userDetails)}

                            <div className="">
                              <h5 className="msgUserName capitalize">
                                {value?.userDetails?.firstName +
                                  " " +
                                  (value?.userDetails?.lastName || "")}
                              </h5>
                              <h6 className="userchat">
                                {t("start chatting..")}
                              </h6>
                            </div>
                          </div>
                          <div className="chatTime d-flex flex-column justify-content-end">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              className="pointer"
                              checked={checked}
                              onChange={() =>
                                handleUserSelect(value?.userDetails)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col lg="7" md="12" sm="12">
            <div className="shadow1 rounded  border h-100 px-0 py-2 ChatMessageBox ">
              <div className="Top border-bottom py-3">
                <div className="d-flex align-items-center msgTitle px-3">
                  <span className="pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 12L11 6M5 12L11 18M5 12L19 12"
                        stroke="black"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  {selectedUser?.length !== 0 && (
                    // selectedUser?.map((itm) => {
                    //   return (
                    //     <h6 className="mb-0 text-capitalize">
                    //       {itm?.firstName},
                    //     </h6>
                    //   );
                    // })
                    <h6 className="mb-0 text-capitalize">
                      {t(`New Message To ${selectedUser?.length} Users`)}
                    </h6>
                  )}
                  {selectedUser?.length === 0 && (
                    <h6 className="mb-0 text-capitalize">
                      {t("Select users to send message")}
                    </h6>
                  )}
                </div>
              </div>
              <div className="px-3 chattingArrea" />
              <div className="msgArea">
                <div className="my-2 d-flex justify-content-end">
                  <div className="msgLine"></div>
                </div>
                <div className="msgareaRight my-4 ps-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {messageType === "free" && allMedia.length !== 0 && (
                      <div className="freePaidMsgSection justify-content-start pt-3 freemsgssection rounded-1">
                        {allMedia?.map((media, ind) => {
                          return (
                            <Fragment key={ind}>
                              {media?.type === "image" &&
                                media?.priceType === "free" && (
                                  <div className="freePaidImg">
                                    <img src={media?.url} alt="" />
                                    <AiOutlineCloseCircle
                                      className="closeIcons"
                                      size={18}
                                      color="black"
                                      onClick={() => handleRemoveMedia(ind)}
                                    />
                                  </div>
                                )}
                              {media?.type === "video" &&
                                media?.priceType === "free" && (
                                  <div className="freePaidImg">
                                    <CustomVideoPlayer mediaItem={media} />
                                    {/* <ReactPlayer
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                      onContextMenu={(e) => e.preventDefault()}
                                      url={media?.url}
                                      width={"100%"}
                                      // style={{ minHeight: "130px" }}
                                      // height={"130px"}
                                      controls={false}
                                      volume={false}
                                      className="custom-player"
                                    /> */}
                                    <AiOutlineCloseCircle
                                      className="closeIcons"
                                      size={18}
                                      color="black"
                                      onClick={() => handleRemoveMedia(ind)}
                                    />
                                  </div>
                                )}
                              {media?.type === "audio" &&
                                media?.priceType === "free" && (
                                  <div className="freePaidImg">
                                    <audio
                                      src={media?.url}
                                      controls
                                      style={{ background: "white" }}
                                    />
                                    <AiOutlineCloseCircle
                                      className="closeIcons"
                                      size={18}
                                      color="black"
                                      onClick={() => handleRemoveMedia(ind)}
                                    />
                                  </div>
                                )}
                            </Fragment>
                          );
                        })}
                      </div>
                    )}
                    {messageType === "paid" && (
                      <div className="d-flex  freePaidMsgSection">
                        <div className="leftFree  rounded-1">
                          <div className="d-flex  mb-2 align-items-center">
                            <TbTagOff size={18} color="#101010" />
                            <p className="text-sm mb-0 mx-2">Free Preview</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            {allMedia?.map((media, ind) => {
                              return (
                                <Fragment key={ind}>
                                  {media?.type === "image" &&
                                    media?.priceType === "free" && (
                                      <div className="freePaidImg">
                                        <img src={media?.url} alt="" />
                                        <AiOutlineCloseCircle
                                          className="closeIcons"
                                          size={18}
                                          color="black"
                                          onClick={() => handleRemoveMedia(ind)}
                                        />
                                        <BsArrowRightCircleFill
                                          onClick={() =>
                                            handleMediaType(ind, "paid")
                                          }
                                          size={19}
                                          className="pointer imgLeftArrow"
                                          color={"#00aff0"}
                                        />
                                      </div>
                                    )}
                                  {media?.type === "video" &&
                                    media?.priceType === "free" && (
                                      <div className="freePaidImg">
                                        <CustomVideoPlayer mediaItem={media} />
                                        {/* <ReactPlayer
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                          url={media?.url}
                                          width={"100%"}
                                          // style={{ minHeight: "130px" }}
                                          // height={"130px"}
                                          controls={false}
                                          volume={false}
                                          className="custom-player"
                                        /> */}
                                        <AiOutlineCloseCircle
                                          className="closeIcons"
                                          size={18}
                                          color="black"
                                          onClick={() => handleRemoveMedia(ind)}
                                        />
                                        <BsArrowRightCircleFill
                                          onClick={() =>
                                            handleMediaType(ind, "paid")
                                          }
                                          size={19}
                                          className="pointer imgLeftArrow"
                                          color={"#00aff0"}
                                        />
                                      </div>
                                    )}
                                  {media?.type === "audio" &&
                                    media?.priceType === "free" && (
                                      <div className="freePaidImg">
                                        <audio
                                          src={media?.url}
                                          controls
                                          style={{ background: "white" }}
                                        />
                                        <AiOutlineCloseCircle
                                          className="closeIcons"
                                          size={18}
                                          color="black"
                                          onClick={() => handleRemoveMedia(ind)}
                                        />
                                        <BsArrowRightCircleFill
                                          onClick={() =>
                                            handleMediaType(ind, "paid")
                                          }
                                          size={19}
                                          className="pointer imgLeftArrow"
                                          color={"#00aff0"}
                                        />
                                      </div>
                                    )}
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                        <div className="rightPaid rounded-1">
                          <div className="d-flex justify-content-between mb-2">
                            <div
                              className="d-flex align-items-center pointer"
                              onClick={() => setShowMessagePriceModal(true)}
                            >
                              <TbTag size={18} color="#101010" />
                              <p className="text-sm mb-0 mx-2">Price View</p>
                            </div>
                            <div className="d-flex">
                              <p className="text-sm mb-0 mx-2">
                                {messagePrice} $
                              </p>
                              <AiOutlineCloseCircle
                                size={18}
                                color="#101010"
                                className="pointer"
                                onClick={() => {
                                  setMessageType("free");
                                  setMessagePrice(0);
                                  setAllMedia([]);
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            {allMedia?.map((media, ind) => {
                              return (
                                <Fragment key={ind}>
                                  {media?.type === "image" &&
                                    media?.priceType === "paid" && (
                                      <div className="freePaidImg">
                                        <img src={media?.url} alt="" />
                                        <AiOutlineCloseCircle
                                          className="closeIcons"
                                          size={18}
                                          color="black"
                                          onClick={() => handleRemoveMedia(ind)}
                                        />
                                        <BsFillArrowLeftCircleFill
                                          onClick={() =>
                                            handleMediaType(ind, "free")
                                          }
                                          size={19}
                                          className="pointer imgrightarrow"
                                          color={"#00aff0"}
                                        />
                                      </div>
                                    )}
                                  {media?.type === "video" &&
                                    media?.priceType === "paid" && (
                                      <div className="freePaidImg">
                                        <CustomVideoPlayer mediaItem={media} />
                                        {/* <ReactPlayer
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                          onContextMenu={(e) =>
                                            e.preventDefault()
                                          }
                                          url={media?.url}
                                          width={"100%"}
                                          // style={{ minHeight: "130px" }}
                                          // height={"130px"}
                                          controls={false}
                                          volume={false}
                                          className="custom-player"
                                        /> */}
                                        <AiOutlineCloseCircle
                                          className="closeIcons"
                                          size={18}
                                          color="black"
                                          onClick={() => handleRemoveMedia(ind)}
                                        />
                                        <BsFillArrowLeftCircleFill
                                          onClick={() =>
                                            handleMediaType(ind, "free")
                                          }
                                          size={19}
                                          className="pointer imgrightarrow"
                                          color={"#00aff0"}
                                        />
                                      </div>
                                    )}
                                  {media?.type === "audio" &&
                                    media?.priceType === "paid" && (
                                      <div className="freePaidImg">
                                        <audio
                                          src={media?.url}
                                          controls
                                          style={{ background: "white" }}
                                        />
                                        <AiOutlineCloseCircle
                                          className="closeIcons"
                                          size={18}
                                          color="black"
                                          onClick={() => handleRemoveMedia(ind)}
                                        />
                                        <BsFillArrowLeftCircleFill
                                          onClick={() =>
                                            handleMediaType(ind, "free")
                                          }
                                          size={19}
                                          className="pointer imgrightarrow"
                                          color={"#00aff0"}
                                        />
                                      </div>
                                    )}
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}

                    <input
                      type="text"
                      placeholder={t("Type a message")}
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e)}
                    />
                    <button
                      className={`sendMsg mx-3 ${
                        selectedUser?.length === 0 && "btn-disable"
                      }`}
                      onClick={handleMessageSend}
                      disabled={selectedUser?.length === 0}
                    >
                      {t("Send")}
                    </button>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-4 ">
                    <div className="">
                      <span className="mx-3 pointer">
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/*"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => handleFileUpload(e)}
                        />
                        <label
                          htmlFor="uploadFile"
                          className="pointer"
                          data-tooltip-id="Add media"
                        >
                          {imageLoader && attachmentType === "image" ? (
                            <SmallLoader color="text-primary" />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <g opacity="0.5" clip-path="url(#clip0_92_11593)">
                                <path
                                  d="M17.4443 0H2.55566C1.14645 0 0 1.14648 0 2.5557V17.4443C0 18.8535 1.14645 20 2.55566 20H17.4443C18.8536 20 20 18.8535 20 17.4443V2.5557C20 1.14648 18.8536 0 17.4443 0ZM18.822 17.4443C18.822 18.204 18.2039 18.822 17.4443 18.822H2.55566C1.79602 18.822 1.17801 18.2039 1.17801 17.4443V15.2956L5.05516 11.9968C5.12346 11.9385 5.21018 11.9062 5.29997 11.9057C5.38977 11.9051 5.47686 11.9364 5.54586 11.9938L7.97453 14.0105C8.20871 14.205 8.55246 14.189 8.76766 13.9736L14.5383 8.19414C14.6426 8.08965 14.7642 8.07949 14.8277 8.08273C14.891 8.08598 15.0111 8.10855 15.1042 8.2232L18.822 12.8009L18.822 17.4443ZM18.822 10.9322L16.0186 7.48043C15.8808 7.31097 15.709 7.17231 15.5142 7.07339C15.3195 6.97448 15.1062 6.91751 14.888 6.90617C14.4479 6.88391 14.0163 7.04961 13.7047 7.36172L8.31375 12.761L6.29852 11.0876C6.01634 10.8526 5.66016 10.7249 5.29294 10.7271C4.92571 10.7293 4.57109 10.8612 4.29176 11.0996L1.17801 13.7489V2.5557C1.17801 1.79605 1.79602 1.17805 2.55566 1.17805H17.4443C18.204 1.17805 18.822 1.79605 18.822 2.5557V10.9322Z"
                                  fill="black"
                                />
                                <path
                                  d="M6.29592 2.46094C4.72971 2.46094 3.45557 3.73516 3.45557 5.30129C3.45557 6.86746 4.72975 8.14164 6.29592 8.14164C7.86209 8.14164 9.13627 6.86746 9.13627 5.30129C9.13627 3.73512 7.86213 2.46094 6.29592 2.46094ZM6.29592 6.96363C5.37928 6.96363 4.63357 6.21789 4.63357 5.30129C4.63357 4.38465 5.37928 3.63895 6.29592 3.63895C7.21256 3.63895 7.95826 4.38469 7.95826 5.30129C7.95826 6.21789 7.21256 6.96363 6.29592 6.96363Z"
                                  fill="black"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_92_11593">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </label>
                      </span>
                      <span
                        className="mx-3"
                        data-tooltip-id="Message with tips appear at the top of the recipient box"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g opacity="0.5" clip-path="url(#clip0_92_11589)">
                            <path
                              d="M11 22C8.0618 22 5.29942 20.8558 3.2218 18.7782C1.14417 16.7006 0 13.9382 0 11C0 8.0618 1.14421 5.29947 3.2218 3.2218C5.29938 1.14413 8.0618 0 11 0C13.9382 0 16.7006 1.14417 18.7782 3.2218C20.8558 5.29942 22 8.0618 22 11C22 13.9382 20.8558 16.7005 18.7782 18.7782C16.7006 20.8559 13.9382 22 11 22ZM11 1.375C5.69276 1.375 1.375 5.69276 1.375 11C1.375 16.3072 5.69276 20.625 11 20.625C16.3072 20.625 20.625 16.3072 20.625 11C20.625 5.69276 16.3072 1.375 11 1.375Z"
                              fill="black"
                            />
                            <path
                              d="M11 10.3125C10.0523 10.3125 9.28125 9.54147 9.28125 8.59375C9.28125 7.64603 10.0523 6.875 11 6.875C11.9477 6.875 12.7188 7.64603 12.7188 8.59375C12.7188 8.97342 13.0265 9.28125 13.4062 9.28125C13.786 9.28125 14.0938 8.97342 14.0938 8.59375C14.0938 7.12418 13.0634 5.89153 11.6875 5.57795V4.8125C11.6875 4.43283 11.3797 4.125 11 4.125C10.6203 4.125 10.3125 4.43283 10.3125 4.8125V5.57795C8.93655 5.89153 7.90625 7.12418 7.90625 8.59375C7.90625 10.2997 9.2941 11.6875 11 11.6875C11.9477 11.6875 12.7188 12.4585 12.7188 13.4062C12.7188 14.354 11.9477 15.125 11 15.125C10.0523 15.125 9.28125 14.354 9.28125 13.4062C9.28125 13.0266 8.97346 12.7188 8.59375 12.7188C8.21404 12.7188 7.90625 13.0266 7.90625 13.4062C7.90625 14.8758 8.93655 16.1085 10.3125 16.4221V17.1875C10.3125 17.5672 10.6203 17.875 11 17.875C11.3797 17.875 11.6875 17.5672 11.6875 17.1875V16.4221C13.0634 16.1085 14.0938 14.8758 14.0938 13.4062C14.0938 11.7003 12.7059 10.3125 11 10.3125Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_92_11589">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="mx-3">
                        <input
                          type="file"
                          id="uploadVideo"
                          accept="video/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e)}
                        />
                        <label htmlFor="uploadVideo" className="pointer">
                          {imageLoader && attachmentType === "video" ? (
                            <SmallLoader color="text-primary" />
                          ) : (
                            <MdSlowMotionVideo
                              size={25}
                              color="#9c9c9c"
                              data-tooltip-id="Upload video"
                            />
                          )}
                        </label>
                      </span>
                      <span
                        className="mx-3"
                        onClick={() => setShowVideoRecorder(true)}
                      >
                        <BiVideo
                          size={25}
                          color="#9c9c9c"
                          data-tooltip-id="Record video"
                        />
                      </span>
                      <span
                        className="mx-3"
                        onClick={() => setShowAudioRecorder(true)}
                      >
                        <BiMicrophone
                          size={25}
                          color="#9c9c9c"
                          data-tooltip-id="Record voice"
                        />
                      </span>
                      <span
                        className="mx-2"
                        onClick={() => setShowMessagePriceModal(true)}
                      >
                        <BsTag
                          size={25}
                          color="#9c9c9c"
                          data-tooltip-id="Message Price"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <VideoRecorederModa
                show={showVideoRecorder}
                onhide={() => setShowVideoRecorder(false)}
                saveVideo={saveVideo}
                videoData={videoData}
                setVideoData={setVideoData}
              />
              <AudioRecorders
                show={showAudioRecorder}
                onhide={() => setShowAudioRecorder(false)}
                saveAudio={saveAudio}
                setAudioData={setAudioData}
                audioData={audioData}
              />
              <MessagePrice
                show={showMessagePriceModal}
                onHide={() => setShowMessagePriceModal(false)}
                messagePrice={messagePrice}
                setMessagePrice={setMessagePrice}
                setMessageType={setMessageType}
              />
              <ProgressBarModal
                show={showProgressModal}
                uploadedPercent={uploadedPercent}
                onhide={() => setShowProgressModal(false)}
              />

              {/* here we are adding all the tooltip section */}
              <ReactTooltip
                id="Add media"
                place="top"
                content={t("Add media")}
              />
              <ReactTooltip
                id="Message with tips appear at the top of the recipient box"
                place="top"
                content={t(
                  "Message with tips appear at the top of the recipient box"
                )}
              />
              <ReactTooltip
                id="Upload video"
                place="top"
                content={t("Upload video")}
              />
              <ReactTooltip
                id="Record video"
                place="top"
                content={t("Record video")}
              />
              <ReactTooltip
                id="Record voice"
                place="top"
                content={t("Record voice")}
              />
              <ReactTooltip
                id="Message Price"
                place="top"
                content={t("Message Price")}
              />
            </div>
          </Col>
        </Row>
      </MainLayout>
    </>
  );
};

export default NewMessage;
