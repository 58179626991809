import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { UncontrolledDropdown } from "reactstrap";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import Visscards from "../common/VisaCards";

const scroll = (el) => {
  let position = el.getBoundingClientRect();
  // scrolls to 20px above element
  window.scrollTo(position.left, position.top + window.scrollY - 75);
};

const AuthFooter = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const navigate = useNavigate();
  const handleLanguageChange = (e) => {
    const value = e.target.value;
    localStorage.setItem("I18N_LANGUAGE", value);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
  };

  return (
    <section className="auht_footer py-lg-5 py-2 px-lg-5">
      <Container>
        <Row>
          <div className="footer_ul flex-wrap">
            <ul>
              <div>
                <p>
                  <a href="/">
                    <strong className="linkss pointer">©2024 Fansroom</strong>
                  </a>
                </p>

                <div className="contact_socail">
                  <h5 className="pointer" onClick={() => navigate("/contact")}>
                    {t("Contact")}
                  </h5>
                  <ul className="list-unstyled social_icon ps-0 mb-0 d-flex align-items-center justify-content-start gap-3 mt-3">
                    <li className="mb-1" style={{ paddingRight: "0px" }}>
                      <Link to="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M247.39 68.94A8 8 0 0 0 240 64h-30.43a48.66 48.66 0 0 0-41.47-24a46.91 46.91 0 0 0-33.75 13.7A47.9 47.9 0 0 0 120 88v6.09C79.74 83.47 46.81 50.72 46.46 50.37a8 8 0 0 0-13.65 4.92c-4.31 47.79 9.57 79.77 22 98.18a110.93 110.93 0 0 0 21.88 24.2c-15.23 17.53-39.21 26.74-39.47 26.84a8 8 0 0 0-3.85 11.93c.75 1.12 3.75 5.05 11.08 8.72C53.51 229.7 65.48 232 80 232c70.67 0 129.72-54.42 135.75-124.44l29.91-29.9a8 8 0 0 0 1.73-8.72Zm-45 29.41a8 8 0 0 0-2.32 5.14C196 166.58 143.28 216 80 216c-10.56 0-18-1.4-23.22-3.08c11.51-6.25 27.56-17 37.88-32.48A8 8 0 0 0 92 169.08c-.47-.27-43.91-26.34-44-96c16 13 45.25 33.17 78.67 38.79A8 8 0 0 0 136 104V88a32 32 0 0 1 9.6-22.92A30.94 30.94 0 0 1 167.9 56c12.66.16 24.49 7.88 29.44 19.21a8 8 0 0 0 7.33 4.79h16Z"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link to="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12 7.9a4.1 4.1 0 1 0 4.1 4.1A4.09 4.09 0 0 0 12 7.9Zm0 6.77A2.67 2.67 0 1 1 14.67 12A2.67 2.67 0 0 1 12 14.67Zm5.23-6.94a1 1 0 1 1-1-1a1 1 0 0 1 1 1Zm2.71 1a4.71 4.71 0 0 0-1.29-3.35a4.71 4.71 0 0 0-3.35-1.32C14 4 10 4 8.7 4.06a4.73 4.73 0 0 0-3.35 1.29A4.71 4.71 0 0 0 4.06 8.7C4 10 4 14 4.06 15.3a4.71 4.71 0 0 0 1.29 3.35a4.73 4.73 0 0 0 3.35 1.29c1.32.08 5.28.08 6.6 0a4.71 4.71 0 0 0 3.35-1.29a4.71 4.71 0 0 0 1.29-3.35c.06-1.3.06-5.3 0-6.6Zm-1.7 8a2.7 2.7 0 0 1-1.52 1.52a18 18 0 0 1-4.72.32a17.91 17.91 0 0 1-4.71-.32a2.7 2.7 0 0 1-1.52-1.52c-.42-1.06-.33-3.56-.33-4.72s-.09-3.67.33-4.72a2.65 2.65 0 0 1 1.52-1.53A17.91 17.91 0 0 1 12 5.44a18 18 0 0 1 4.72.32a2.7 2.7 0 0 1 1.52 1.52c.42 1.06.32 3.56.32 4.72s.1 3.67-.32 4.72Z"
                          />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="dropdown_footer mt-3 pt-2">
                  <UncontrolledDropdown>
                    <span className="me-2 d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855c-.143.268-.276.56-.395.872c.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332c.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933a8.853 8.853 0 0 1-.481-1.079a8.38 8.38 0 0 0-1.198.49a7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521a8.376 8.376 0 0 0-1.197-.49a8.853 8.853 0 0 1-.481 1.078a6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855c.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667c.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49a7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"
                        />
                      </svg>

                      <select
                        name=""
                        id=""
                        className="languageCont2"
                        style={{ border: "none", outline: "none" }}
                        onChange={(e) => handleLanguageChange(e)}
                        value={selectedLanguage}
                      >
                        <option value="en">English</option>
                        <option value="hn">Hindi</option>
                        <option value="chn">Chinese</option>
                        <option value="spn">Spanish</option>
                      </select>
                    </span>
                  </UncontrolledDropdown>
                </div>
              </div>
            </ul>

            <ul>
              <li>
                <Link to="/help">{t("Help")}</Link>
              </li>
              {/* <li>
                <Link to="">{t("Store")}</Link>
              </li> */}
              <li>
                <Link to="/cookie-policy">{t("Cookie Notice")} </Link>
              </li>
              <li>
                <Link to="/transparency-center">
                  {t("Fansroom Safety & Transparency Center")}{" "}
                </Link>
              </li>
              <li>
                <Link to="/payouts">{t("Payouts Policy")} </Link>
              </li>
              <li>
                <Link to="/release-policy">{t("Release Policy")} </Link>
              </li>
            </ul>

            <ul>
              <li>
                <Link to="/about"> {t("About")} </Link>
              </li>
              <li>
                <Link to="/terms"> {t("Terms of Service")} </Link>
              </li>
              <li>
                <Link to="/dmca"> {t("DMCA")} </Link>
              </li>
              <li>
                <Link to="/antitraffickingstatement">
                  {t("Anti-Slavery and Anti-Trafficking Statement")}{" "}
                </Link>
              </li>
              <li>
                <Link to="/"> {t("Become a Creator")} </Link>
              </li>
            </ul>

            <ul>
              {/* <li>
                <Link to=""> {t("Blog")} </Link>
              </li> */}
              <li>
                <Link to="/privacy"> {t("Privacy")} </Link>
              </li>
              <li>
                <Link to="/USC"> {t("USC 2257")} </Link>
              </li>
              <li>
                <HashLink to="/terms#acceptable-use-policy" scroll={scroll}>
                  {" "}
                  {t("Acceptable Use Policy")}{" "}
                </HashLink>
              </li>
              <li>
                <Link to="#" style={{ pointerEvents: "none" }}>
                  7901 4th St., N Ste 300 St. Petersburg, FL 33702 USA
                  {/* 18101 Collins Ave Apt 4608 Sunny Isles Beach FL 33160 USA */}
                </Link>
              </li>
            </ul>

            <ul>
              <li>
                <Link to="/brand"> {t("Branding")} </Link>
              </li>
              <li>
                <HashLink to="/terms#complaints-policy" scroll={scroll}>
                  {" "}
                  {t("Complaints Policy")}{" "}
                </HashLink>
              </li>
              <li>
                <Link to="/contract">
                  {" "}
                  {t("Standard Contract between Fan and Creator")}{" "}
                </Link>
              </li>
              <li>
                <Link to="/values"> {t("Our Values")} </Link>
              </li>
            </ul>
          </div>

          <div className="footer_card">
            <Visscards />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default AuthFooter;
