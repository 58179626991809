import React from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  EmojiPicker,
  ToolbarType,
} from "@syncfusion/ej2-react-richtexteditor";

// const items = [
//   "Bold",
//   "Italic",
//   "Underline",
//   "StrikeThrough",
//   "SuperScript",
//   "SubScript",
//   "|",
//   "FontName",
//   "FontSize",
//   "FontColor",
//   "BackgroundColor",
//   "|",
//   "LowerCase",
//   "UpperCase",
//   "|",
//   "Formats",
//   "Alignments",
//   "Blockquote",
//   "|",
//   "NumberFormatList",
//   "BulletFormatList",
//   "|",
//   "Outdent",
//   "Indent",
//   "|",
//   "CreateLink",
//   "Image",
//   "Video",
//   "Audio",
//   "CreateTable",
//   "|",
//   "FormatPainter",
//   "ClearFormat",
//   "|",
//   "EmojiPicker",
//   "Print",
//   "|",
//   "SourceCode",
//   "FullScreen",
//   "|",
//   "Undo",
//   "Redo",
// ];

const items = [
  "Bold",
  "Italic",
  "Underline",
  "StrikeThrough",
  "SuperScript",
  "SubScript",
  "|",
  "EmojiPicker",
  "|",
  "FontName",
  "FontSize",
  "FontColor",
  "BackgroundColor",
  "|",
  "LowerCase",
  "UpperCase",
  "|",
  "Formats",
  "Alignments",
  "Blockquote",
  "|",
  "NumberFormatList",
  "BulletFormatList",
  "|",
  "Outdent",
  "Indent",
  "|",
  "CreateLink",
  "Image",
  "Video",
  "Audio",
  "CreateTable",
  "|",
  "FormatPainter",
  "ClearFormat",
  "|",
  "EmojiPicker",
  "|",
  "SourceCode",
  "FullScreen",
  "|",
  "Undo",
  "Redo",
];
//Rich Text Editor ToolbarSettings
const toolbarSettings = {
  type: ToolbarType.Expand,
  items: items,
  enableFloating: false,
};
// "EmotionIconstRTE"
const RichFullTextEditor = ({ value, handleChange, id }) => {
  return (
    <RichTextEditorComponent
      value={value}
      id={id}
      toolbarSettings={toolbarSettings}
      change={(event) => {
        handleChange(event.value);
      }}
    >
      <Inject
        services={[HtmlEditor, Toolbar, Link, Image, QuickToolbar, EmojiPicker]}
      />
    </RichTextEditorComponent>
  );
};

export default RichFullTextEditor;
