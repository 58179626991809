import React, { useState, useEffect } from "react";
import Settings from "./Settings";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";
import { getProfile, updateEntertainerSetting } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Story = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [replyMessageSubscriber, setReplyMessageSubscriber] = useState(false);
  const { buttonLoader, user } = useSelector((s) => s.setting);

  const handleChange = (dta) => {
    const data = {
      [dta]: !replyMessageSubscriber,
    };
    dispatch(updateEntertainerSetting(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getUserData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (user) {
      setReplyMessageSubscriber(user?.replyMessageSubscriber);
    }
  }, [user]);

  return (
    <Settings>
      <section className="custom_order px-0 pb-3">
        <Form>
          <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
            <Link to="/home">
              <div className="notification_left_heading d-flex align-items-center">
                <h5>{t("Story")}</h5>
              </div>
            </Link>
          </div>

          <div className="privacy_safety mt-4 border-top border-bottom border-3">
            <div className="Reward_part">
              <div className="month_reffer py-3 d-flex align-items-center justify-content-between px-4 py-3">
                <div className="free_content">
                  <h6>{t("Message replies from subscribers")}</h6>
                  <p>
                    {t(
                      "Allow subscribers to send message replies to your stories. This setting does not affect messages with tips."
                    )}
                  </p>
                </div>
                <div className="switches">
                  <label class="switch">
                    <input
                      type="checkbox"
                      className="form-check"
                      checked={replyMessageSubscriber}
                      onChange={() => handleChange("replyMessageSubscriber")}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Settings>
  );
};

export default Story;
