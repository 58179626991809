import React, { useEffect, useLayoutEffect, useState } from "react";
import MyRoutes from "./routers/router";
import ScrollToTop from "./ScrollToTop";
import runAxiosSetup from "./helpers/run-axios-setup";
import { Route, Router, BrowserRouter, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import "video-react/dist/video-react.css";
import { chatList, getProfile } from "./redux/actions";
// import { socket } from "./socket";
import ScreenLoader from "./components/common/ScreenLoader/ScreenLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fullpageloader from "./components/common/Fullpageloader";
import useSocket from "./customHooks/useSocket";
import CookieModal from "./components/common/Modal/CookieModal";
import "./css/style.css";
import "./css/style2.css";
import "./css/commonStyles.css";
import "./css/responsive.css";
import "./css/DarkMode.css";
import {
  getFirebaseToken,
  showForegroundNotification,
  onMessageListener,
} from "./firebase";
import { updateProfile } from "./redux/actions";
import { getCardList } from "./redux/states/card/thunk";
import { updateMsgCount, updateNewMsg } from "./redux/states/messages/slice";
import Notifymodal from "./components/common/Modal/Notifymodal";
import Announcements from "./components/common/Modal/Announcements";

function CheckMessages({ syncChat }) {
  const location = useLocation();

  useEffect(() => {
    syncChat();
  }, [location.pathname]);

  return null;
}

const App = () => {
  const { io } = useSocket();
  const { user, isAuthenticated } = useSelector((state) => state.login) ?? {};
  // const token = user?.token;
  const [setup, setSetup] = useState({});
  // const [themeClr, setThemeClr] = useState("light");
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [fooEvents, setFooEvents] = useState([]);
  const dispatch = useDispatch();
  const [showSplash, setShowSplash] = useState(true);
  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState("");
  const { isDarkMode } = useSelector((s) => s.login);
  const userId = useSelector((s) => s.setting?.user)?._id;

  const [notifymod, setNotifymod] = useState(false);

  const onHideAge = () => {
    setNotifymod(false);
    // window.location.replace("https://google.com");
  };

  useEffect(() => {
    const showAgeRestriction = localStorage.getItem("seenAgeRestriction");
    if (showAgeRestriction !== "true") {
      setNotifymod(true);
    }
  }, [localStorage]);

  useEffect(() => {
    if (firebaseToken) {
      if (isAuthenticated) {
        dispatch(
          updateProfile({
            firebaseToken,
          })
        )
          .unwrap()
          .then((res) => {
            // toast.success(res.message);
          })
          .catch((err) => {
            // toast.error(err.message);
          });
      }
      onMessageListener()
        .then((payload) => {
          showForegroundNotification({
            icon: "https://fansroom.com/logoW.png",
            title: payload.notification.title,
            body: payload.notification.body,
          });
        })
        .catch((err) => console.log("failed: ", err));
    } else {
      getFirebaseToken(setTokenFound)
        .then((token) => {
          if (token) {
            setFirebaseToken(token);
          } else {
            setFirebaseToken("");
          }
        })
        .catch((err) => null);
    }
  }, [firebaseToken, isAuthenticated]);

  const getChatList = (props) => {
    const data = {
      limit: 100,
      orderBy: "createdAt",
      order: -1,
    };
    dispatch(chatList(data))
      .unwrap()
      .then((res) => {
        const chatList = res?.data;

        var newMsgCount = 0;
        chatList.map((itm) => {
          var msgCount =
            itm?.userId === userId
              ? itm?.userUnreadMessagesCount
              : itm?.otherUserUnreadMessagesCount;
          newMsgCount = newMsgCount + msgCount;
        });
        dispatch(updateMsgCount(newMsgCount));
      })
      .catch((err) => {
        console.log(err, "chatlist here");
      });
  };

  useEffect(() => {
    if (io && !io?.hasListeners("newMessage")) {
      io.on("newMessage", (response) => {
        // console.log("response", response);
        getChatList();
        if ("msg" in response) {
          const isChatCreatedbyMe = response?.customerId === userId;
          if (isChatCreatedbyMe) {
            if (response?.byCustomer) {
            } else {
              dispatch(updateNewMsg(response));
            }
          } else {
            if (response?.byCustomer) {
              dispatch(updateNewMsg(response));
            }
          }
        }
      });
    }
  }, [io]);

  const getCardListt = () => {
    const data = {
      page: 1,
      limit: 100,
    };
    dispatch(getCardList(data));
  };

  function syncChat() {
    if (userId) {
      getCardListt();
      getChatList();
    }
  }
  useEffect(() => {
    syncChat();
  }, [userId]);

  useLayoutEffect(() => {
    runAxiosSetup({
      token: user?.token,
      adminId: user?._id,
      headers: {
        token: user?.token,
      },
    });
    setSetup((prev) => ({ ...prev, axios: true }));
    if (isAuthenticated) {
      dispatch(getProfile());
    }
  }, [user]);

  // useEffect(() => {
  //   const data = {
  //     page: 1,
  //     limit: 100,
  //   };
  //   dispatch(getCardList(data));
  // }, []);

  // here we are handling the dark mode  part of the project

  useEffect(() => {
    const bodyElement = document.body;
    if (!isDarkMode) {
      bodyElement.classList.add("light-mode");
      bodyElement.classList.remove("dark-mode");
    } else {
      bodyElement.classList.add("dark-mode");
      bodyElement.classList.remove("light-mode");
    }
  }, [isDarkMode]);

  if (!setup.axios) {
    return null;
  }

  setTimeout(() => {
    setShowSplash(false);
  }, 1000);

  return (
    <BrowserRouter>
      {user?._id && <Announcements />}

      {showSplash ? <Fullpageloader /> : <MyRoutes />}
      <Notifymodal
        show={notifymod}
        setNotifymod={setNotifymod}
        onHide={onHideAge}
      />
      <ScrollToTop />
      <ToastContainer autoClose={1500} position="bottom-center" theme="dark" />
      <ScreenLoader />
      <CookieModal />
      <CheckMessages syncChat={syncChat} />
      {/* {themeClr == "dark" ? (
        <>
          <style jsx="true">
            {`
              :root {
                --bs-theme: #101010;
                --bs-fontClr: #767676;
                --bs-CardCstm: #181818;
                --bs-whiteBlack: #000;
                --bs-BlackWhite: #cdcdcd;
              }
            `}
          </style>
        </>
      ) : (
        <>
          <style jsx="true">
            {`
              :root {
                --bs-theme: #fff;
                --bs-fontClr: #101010;
                --bs-CardCstm: #fff;
                --bs-whiteBlack: #fff;
                --bs-BlackWhite: #000;
              }
            `}
          </style>
        </>
      )} */}
    </BrowserRouter>
  );
};

export default App;
