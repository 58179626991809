import React from "react";
import MainLayout from "../../../layout/MainLayout";
import NotificationComponent from "../../../components/SideTabs/Notification";

const Notification = () => {
  return (
    <MainLayout>
      <NotificationComponent />
    </MainLayout>
  );
};

export default Notification;
