import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import { getPostList } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Vaultdefault = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [active, setactive] = useState(1);
  const { user } = useSelector((s) => s.setting);
  const userId = user?._id;
  const { setValuetab, handleChange, valuetab } = props;
  const [allMediaCount, setAllMediaCount] = useState({
    total: 0,
    images: 0,
    video: 0,
    audio: 0,
  });
  const [postCount, setPostCount] = useState({
    total: 0,
    images: 0,
    video: 0,
    audio: 0,
  });
  const [storyCount, setStoryCount] = useState({
    total: 0,
    images: 0,
    video: 0,
  });
  const [quizCount, setQuizCount] = useState({ total: 0 });
  const [pollCount, setPollCount] = useState({ total: 0 });

  const getAllMedia = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["normal", "quiz", "poll", "story", "other"],
      userId: userId,
    };
    dispatch(getPostList(data))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        const allMedia = data?.map((item) => {
          const media = item?.media;
          return media;
        });
        const mediaList = allMedia?.flat();

        setAllMediaCount({
          total: mediaList.length,
          images: mediaList?.filter((itm) => itm.type === "image")?.length,
          video: mediaList?.filter((itm) => itm.type === "video")?.length,
          audio: mediaList?.filter((itm) => itm.type === "audio")?.length,
        });

        var postList = data?.filter((itm) => itm?.postType !== "story");
        postList = postList?.map((item) => {
          const media = item?.media;
          return media;
        });
        postList = postList.flat();
        setPostCount({
          total: postList.length,
          images: postList?.filter((itm) => itm.type === "image")?.length,
          video: postList?.filter((itm) => itm.type === "video")?.length,
          audio: postList?.filter((itm) => itm.type === "audio")?.length,
        });

        // story section
        var storyList = data?.filter((itm) => itm?.postType === "story");
        storyList = storyList?.map((item) => {
          const media = item?.media;
          return media;
        });
        storyList = storyList.flat();
        setStoryCount({
          total: storyList.length,
          images: storyList?.filter((itm) => itm.type === "image")?.length,
          video: storyList?.filter((itm) => itm.type === "video")?.length,
        });

        // quiz section
        var quizList = data?.filter((itm) => itm?.postType === "quiz");
        setQuizCount({ total: quizList?.length });

        // poll section;
        var pollList = data?.filter((itm) => itm?.postType === "poll");
        setPollCount({ total: pollList?.length });
      });
  };

  useEffect(() => {
    getAllMedia();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-2">
        <div className="left">
          <h6 className="mb-0 ms-0 text-muted fw-bold">{t("CUSTOM ORDER")}</h6>
        </div>
        <div className="right">
          <div className="three_dot_dropdown">
            <Dropdown>
              <Dropdown.Toggle
                variant="transparent"
                className="border-0 p-0"
                id="dropdown-basic"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                    fill="black"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu className="px-3">
                <ul className="list-unstyled ps-0 mb-0 radioSelect">
                  <li className="py-2 position-relative my-2">
                    <input
                      type="radio"
                      name="more"
                      className="position-absolute  w-100 h-100"
                    />
                    <div className="cardCstm">
                      <p className="m-0 fw-bold">
                        {t("Hide all Locked Posts")}
                      </p>
                    </div>
                  </li>
                  <li className="py-2 position-relative my-2">
                    <input
                      type="radio"
                      name="more"
                      className="position-absolute  w-100 h-100"
                    />
                    <div className="cardCstm">
                      <p className="m-0 fw-bold">
                        {t("Hide all Locked Posts")}
                      </p>
                    </div>
                  </li>
                  <li className="py-2 position-relative my-2">
                    <input
                      type="radio"
                      name="more"
                      className="position-absolute  w-100 h-100"
                    />
                    <div className="cardCstm">
                      <p className="m-0 fw-bold">
                        {t("Hide all Locked Posts")}
                      </p>
                    </div>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="customer_order_list mt-3">
        <ul className="custom-nav mr-auto list-unstyled m-0 p-0">
          <li
            onClick={() => handleChange(1)}
            className={valuetab == 1 && "activenew"}
          >
            <a href="javascript:void(0)">
              <div className="custom_order_tabs d-flex align-items-center justify-content-between">
                <div className="left_cusotm_order">
                  <h6>{t("All media")}</h6>
                  <p>
                    {allMediaCount?.total} {t("Total")} .{" "}
                    {allMediaCount?.images} {t("Image")}. {allMediaCount?.video}{" "}
                    {t("Videos")} . {allMediaCount?.audio} {t("Audio")} .{" "}
                  </p>
                  {allMediaCount?.total === 0 && <p>{t("empty")}</p>}
                </div>
              </div>
            </a>
          </li>

          <li
            onClick={() => handleChange(2)}
            className={valuetab == 2 && "activenew"}
          >
            <a href="javascript:void(0)">
              <div className="custom_order_tabs d-flex align-items-center justify-content-between">
                <div className="left_cusotm_order">
                  <h6>Stories</h6>
                  <p>
                    {storyCount?.total} {t("Total")} . {storyCount?.images}{" "}
                    {t("Image")} . {storyCount?.video} {t("Videos")} .{" "}
                  </p>
                  {storyCount?.total === 0 && <p>{t("empty")}</p>}
                </div>
              </div>
            </a>
          </li>

          <li
            onClick={() => handleChange(3)}
            className={valuetab == 3 && "activenew"}
          >
            <a href="javascript:void(0)">
              <div className="custom_order_tabs d-flex align-items-center justify-content-between">
                <div className="left_cusotm_order">
                  <h6>Posts</h6>
                  <p>
                    {postCount?.total} {t("Total")} . {postCount?.images}{" "}
                    {t("Image")} . {postCount?.video} {t("Videos")} .{" "}
                    {postCount?.audio} {t("Audio")} .{" "}
                  </p>
                  {postCount?.total === 0 && <p>{t("empty")}</p>}
                </div>
              </div>
            </a>
          </li>

          <li
            onClick={() => handleChange(4)}
            className={valuetab == 4 && "activenew"}
          >
            <a href="javascript:void(0)">
              <div className="custom_order_tabs d-flex align-items-center justify-content-between">
                <div className="left_cusotm_order">
                  <h6>{t("Quiz")}</h6>
                  <p>
                    {" "}
                    {quizCount?.total} {t("Total")} .
                  </p>
                  {quizCount?.total === 0 && <p>{t("empty")}</p>}
                </div>
              </div>
            </a>
          </li>

          <li
            onClick={() => handleChange(5)}
            className={valuetab == 5 && "activenew"}
          >
            <a href="javascript:void(0)">
              <div className="custom_order_tabs d-flex align-items-center justify-content-between">
                <div className="left_cusotm_order">
                  <h6>{t("Poll")}</h6>
                  <p>
                    {" "}
                    {pollCount?.total} {t("Total")} .
                  </p>
                  {pollCount?.total === 0 && <p>{t("empty")}</p>}
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Vaultdefault;
