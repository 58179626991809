import React from "react";
import MainLayout from "../../../layout/MainLayout";
import StatisticsComponent from "../../../components/SideTabs/Statistics";

const Statistics = () => {
  return (
    <MainLayout>
      <StatisticsComponent />
    </MainLayout>
  );
};

export default Statistics;
