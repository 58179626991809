import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Vaultdefault from "./Vaultdefault";
import Vaultdata from "./Vaultdata";
import { useDispatch, useSelector } from "react-redux";
import { getPostList } from "../../../redux/actions";
import AddNewCategory from "./Component/Modals/AddNewCategory";

const VaultComponent = (props) => {
  const [valuetab, setValuetab] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setValuetab(newValue);
  };
  const [chatSearch, setChatSearch] = useState();
  const handleChatSearch = () => {
    setChatSearch(!chatSearch);
  };

  const [addCategory, setAddCategory] = useState();
  const handleAddCategory = () => {
    setAddCategory(!addCategory);
  };

  return (
    <>
      <Row className="notification_topheader h-100 align-items-start justify-content-between ">
        <Col lg="5" md="12" sm="12">
          <div className="shadow1 rounded border h-100 SecSidebar px-3 border_hide">
            <div className=" py-3 border-bottom SidebarTopHead">
              <div
                className="d-flex justify-content-between align-items-center border-bottom sticky-top bg-white"
                style={{ height: "50px" }}
              >
                {chatSearch ? (
                  <>
                    <Form className=" w-100 position-relative h-100">
                      <Button
                        onClick={handleChatSearch}
                        className="border-0 p-0 position-absolute"
                        variant="transparent"
                        style={{ left: "0", top: "0", zIndex: "9" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M3.64016 2.27L7.50016 6.13L11.3402 2.29C11.425 2.19972 11.5272 2.12749 11.6406 2.07766C11.754 2.02783 11.8763 2.00141 12.0002 2C12.2654 2 12.5197 2.10536 12.7073 2.29289C12.8948 2.48043 13.0002 2.73478 13.0002 3C13.0025 3.1226 12.9797 3.24439 12.9333 3.35788C12.8869 3.47138 12.8178 3.57419 12.7302 3.66L8.84016 7.5L12.7302 11.39C12.895 11.5512 12.9916 11.7696 13.0002 12C13.0002 12.2652 12.8948 12.5196 12.7073 12.7071C12.5197 12.8946 12.2654 13 12.0002 13C11.8727 13.0053 11.7456 12.984 11.6268 12.9375C11.508 12.8911 11.4002 12.8204 11.3102 12.73L7.50016 8.87L3.65016 12.72C3.56567 12.8073 3.46473 12.8769 3.35316 12.925C3.2416 12.9731 3.12163 12.9986 3.00016 13C2.73495 13 2.48059 12.8946 2.29306 12.7071C2.10552 12.5196 2.00016 12.2652 2.00016 12C1.99783 11.8774 2.02058 11.7556 2.06701 11.6421C2.11344 11.5286 2.18257 11.4258 2.27016 11.34L6.16016 7.5L2.27016 3.61C2.10535 3.44876 2.0087 3.23041 2.00016 3C2.00016 2.73478 2.10552 2.48043 2.29306 2.29289C2.48059 2.10536 2.73495 2 3.00016 2C3.24016 2.003 3.47016 2.1 3.64016 2.27Z"
                            fill="black"
                          />
                        </svg>
                      </Button>
                      <div className="position-relative searchForm icon-with-text">
                        <input
                          type="Search"
                          placeholder="Search...."
                          className="form-control border-0 rounded-0"
                        />
                        <span className="icn position-absolute d-inline-block ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center msgTitle gap-10 justify-content-between">
                      <span onClick={() => navigate(-1)} className="pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 12L11 6M5 12L11 18M5 12L19 12"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <h6 className="mb-0 ms-0">
                        <span>{t("VAULT")}</span>
                      </h6>
                    </div>
                    <div className="right">
                      <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10">
                        <li className="">
                          <Button
                            onClick={handleChatSearch}
                            variant="transparent"
                            className="border-0 p-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94413 12.389 3.33329 9.1665 3.33329C5.944 3.33329 3.33317 5.94413 3.33317 9.16663C3.33317 12.3891 5.944 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
                                fill="black"
                              ></path>
                            </svg>
                          </Button>
                        </li>
                        <li className="">
                          <Button
                            onClick={handleAddCategory}
                            variant="transparent"
                            className="border-0 p-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_10_25)">
                                <path
                                  d="M8.75016 16.6667C8.75016 16.9982 8.88186 17.3162 9.11628 17.5506C9.3507 17.785 9.66864 17.9167 10.0002 17.9167C10.3317 17.9167 10.6496 17.785 10.884 17.5506C11.1185 17.3162 11.2502 16.9982 11.2502 16.6667V11.25H16.6668C16.9984 11.25 17.3163 11.1183 17.5507 10.8839C17.7851 10.6495 17.9168 10.3316 17.9168 10C17.9168 9.66852 17.7851 9.35058 17.5507 9.11616C17.3163 8.88174 16.9984 8.75004 16.6668 8.75004H11.2502V3.33337C11.2502 3.00185 11.1185 2.68391 10.884 2.44949C10.6496 2.21507 10.3317 2.08337 10.0002 2.08337C9.66864 2.08337 9.3507 2.21507 9.11628 2.44949C8.88186 2.68391 8.75016 3.00185 8.75016 3.33337V8.75004H3.3335C3.00198 8.75004 2.68403 8.88174 2.44961 9.11616C2.21519 9.35058 2.0835 9.66852 2.0835 10C2.0835 10.3316 2.21519 10.6495 2.44961 10.8839C2.68403 11.1183 3.00198 11.25 3.3335 11.25H8.75016V16.6667Z"
                                  fill="black"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_10_25">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>

              <Vaultdefault
                handleChange={handleChange}
                setValuetab={setValuetab}
                valuetab={valuetab}
              />
            </div>
          </div>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <div className="card_boader h-100 min-vh-100 border_hide">
            <Vaultdata isShowData={valuetab} {...props} />
          </div>
        </Col>
      </Row>
      <AddNewCategory
        addCategory={addCategory}
        setAddCategory={setAddCategory}
      />
    </>
  );
};

export default VaultComponent;
