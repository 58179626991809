import React, { useEffect, useState } from "react";
import "./loader.css";
import { Modal } from "react-bootstrap";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import Loader from "../../../images/loader.gif";

const ScreenLoader = ({ show }) => {
  const allReducers = useSelector((s) => s);
  const [showLoader, setShowLoader] = useState(false);
  const allReducerArr = Object.values(allReducers);

  useEffect(() => {
    if (allReducerArr?.find((itm) => itm?.loader === true)) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [allReducers]);

  return (
    <Modal show={showLoader} size="lg" className="screenLoadr">
      <div className="screenloaderCot">
        <div class="middle">
          {/* <img src={Loader} alt="" /> */}
          <div class="loader"></div>
        </div>
      </div>
    </Modal>
  );
};

export default ScreenLoader;
