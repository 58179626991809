import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimePicker from "react-datetime-picker";
import { useTranslation } from "react-i18next";

export default function Schedulemod(props) {
  const { t } = useTranslation();
  const { formik } = props;
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date());
  const [calendertab, setcalendertab] = useState("Scheduled");
  const handleTabSelectedtion = (tabs) => {
    setcalendertab(tabs);
  };

  const onChange = (val) => {
    setValue(val);
    var date = new Date(val);
    var yy = date.getUTCFullYear();
    var mm =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    const dat = yy + "-" + mm + "-" + dd;
    formik.setFieldValue("scheduleDate", dat);

    var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var mn =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    const time = hh + ":" + mn;
    formik.setFieldValue("scheduleTime", time);
    formik.setFieldValue("scheduledType", "scheduled");
    formik.setFieldValue("savForLater", "");
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Body>
        <div className="calednder_Mod">
          <div className="add_heading d-flex px-4 pt-4 justify-content-between">
            <h5>{t("SCHEDULE POST")}</h5>
            <a href="javascript:void(0)" onClick={props.onhide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </a>
          </div>
          <div className="calender_modal">
            <div className="calender_header border-bottom d-flex aligin-items-center justify-content-between px-4 my-2">
              <h4>{formik.values.scheduleDate}</h4>
              <h4>{formik.values.scheduleTime} </h4>
            </div>

            <div className="calendermod_table px-4 py-3">
              <DateTimePicker
                onChange={onChange}
                value={value}
                style={{ width: "300px" }}
                autoFocus={true}
                isClockOpen
                minDate={new Date()}
              />
            </div>

            <div className="btns_twice d-flex align-items-center justify-content-between px-4 py-3 border-top">
              <Button className="cancel_btn" onClick={props.onhide}>
                {t("Cancel")}
              </Button>
              <Button className="cancel_btn" onClick={props.onhide}>
                {t("SAVE FOR LATER")}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
