import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import PostViewer from "../../../../common/PostViewer/PostViewer";
import Statistics from "../../../../common/Modal/Statistics";
import Heart from "../../../../../images/heart.svg";
import FilledHeart from "../../../../../images/filledHeart.svg";
import Comment from "../../../../../images/comment.svg";
import Chart from "../../../../../images/chart.svg";
import { formatText } from "../../../../../helpers/formatPostText";
import TagUserseList from "../../../../common/Modal/EntertainerModal/TagUsersList";

const EngagementSummary = ({ subTab }) => {
  const { t } = useTranslation();
  const { engagementData, engagementMessage } = useSelector(
    (s) => s.statistics
  );
  const [postStats, setPostStats] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tagcreator, setTagcreator] = useState(false);
  const [topPost, setTopPost] = useState({
    purchases: null,
    tips: null,
    views: null,
    likes: null,
    comments: null,
  });
  const [topPostTab, setTopPostTab] = useState();
  const [totalMessageEarning, setTotalMessageEarning] = useState(0);

  useEffect(() => {
    if (
      engagementData &&
      engagementData.total &&
      engagementData.total.posts &&
      engagementData.total.posts.length > 0
    ) {
      const purchases = engagementData.total.posts.reduce(
        (max, obj) =>
          (obj.totalPurchaseAmount || 0) > (max.totalPurchaseAmount || 0)
            ? obj
            : max,
        engagementData.total.posts[0] || {}
      );

      const tips = engagementData.total.posts.reduce(
        (max, obj) => ((obj.tipCount || 0) > (max.tipCount || 0) ? obj : max),
        engagementData.total.posts[0] || {}
      );
      const views = engagementData.total.posts.reduce(
        (max, obj) => (obj.viewCount > max.viewCount ? obj : max),
        engagementData.total.posts[0]
      );
      const likes = engagementData.total.posts.reduce(
        (max, obj) => (obj.likeCount > max.likeCount ? obj : max),
        engagementData.total.posts[0]
      );
      const comments = engagementData.total.posts.reduce(
        (max, obj) => (obj.commentCount > max.commentCount ? obj : max),
        engagementData.total.posts[0]
      );

      setTopPost({ purchases, tips, views, likes, comments });
    }
  }, [engagementData]);

  useEffect(() => {
    if (topPost.purchases !== null) {
      setTopPostTab("purchases");
    } else if (topPost.tips !== null) {
      setTopPostTab("tips");
    } else if (topPost.views !== null) {
      setTopPostTab("views");
    } else if (topPost.likes !== null) {
      setTopPostTab("likes");
    } else if (topPost.comments !== null) {
      setTopPostTab("comments");
    } else {
      setTopPostTab("purchases");
    }
  }, [topPost]);

  const renderPostCard = (key) => {
    const data = topPost[key];
    return (
      <>
        {data?._id ? (
          <div className="postCard">
            <div className="d-flex justify-content-between align-items-center mt-4  px-4 px-lg-0">
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div className=" fansDetails">
                  <p className=" mb-0 ">
                    {moment(data?.createdAt).format("lll")}
                  </p>
                </div>
              </div>
              {/* <div className="d-flex align-items-center">
                <div className="three_dot_dropdown">
                  <div className="dropdown">
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-secondary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g opacity="0.5" clipPath="url(#clip0_87_10716)">
                          <path
                            d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                            fill="currentColor"
                          />
                          <path
                            d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                            fill="currentColor"
                          />
                          <path
                            d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_87_10716">
                            <rect
                              width={18}
                              height={18}
                              fill="white"
                              transform="translate(18) rotate(90)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <div
                      tabIndex={-1}
                      role="menu"
                      aria-hidden="true"
                      className="dropdown-menu"
                    >
                      <ul className="list-unstyled ps-0 mb-0">
                        <li>
                          <button
                            type="button"
                            tabIndex={0}
                            role="menuitem"
                            className="dropdown-item"
                          >
                            <span>Copy Link to post</span>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            tabIndex={0}
                            role="menuitem"
                            className="dropdown-item"
                          >
                            <span>Add / Remove from list</span>
                          </button>
                        </li>
                      </ul>
                      <ul className="list-unstyled ps-0 mb-0">
                        <li>
                          <button
                            type="button"
                            tabIndex={0}
                            role="menuitem"
                            className="dropdown-item"
                          >
                            I like this post
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="py-2">
              <h6 className="m-0 ">
                <p className="m-0">{formatText(data?.description)}</p>
              </h6>
            </div>
            <div className="NewsFeed" id="66630faf899bafc6dc25d872">
              <PostViewer
                data={data}
                media={data?.media}
                // showViewer={showViewer}
                // setShowViewer={setShowViewer}
                // setSelectedMedia={setSelectedMedia}
              />
            </div>
            {data?.tagUser?.length > 0 && (
              <>
                {/* <div>Tag Users</div> */}
                <div
                  style={{
                    display: "flex",
                    padding: "0rem 0.8rem",
                    marginTop: "16px",
                    color: "#8a96a3",
                    fontWeight: 600,
                  }}
                  className="px-4 px-lg-0"
                >
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() => setTagcreator(!tagcreator)}
                  >
                    <span style={{ color: "#00aff0" }}>@ </span> Tags
                  </div>
                  <div
                    style={{ flexWrap: "wrap", width: "100%", display: "flex" }}
                  >
                    {data?.tagUser?.map((val) => (
                      <div style={{ marginRight: "3px" }}>
                        <div className="username">@{val.userName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="postaction_wrap my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative px-2 px-lg-0">
              <div className="d-flex align-items-center postaction">
                <span className="">
                  {data?.isLiked ? (
                    <img
                      src={FilledHeart}
                      className="actionIcon"
                      // onClick={() => handleUnLikePost(data)}
                    />
                  ) : (
                    <img
                      src={Heart}
                      className="actionIcon"
                      // onClick={() => handleLikePost(data)}
                    />
                  )}
                </span>
                <span
                  className=" tagcol"
                  // onClick={() => handleCommentPop(data)}
                >
                  <img src={Comment} className="actionIcon" />
                </span>

                <span
                  className="pointer tagcol "
                  onClick={() => {
                    setPostStats(!postStats);
                    setSelectedRecord(data);
                  }}
                >
                  <img src={Chart} className="actionIcon" />
                  Post Stats
                </span>
              </div>
            </div>
            <div className="cmt_wrap d-flex">
              <div className="light-text">
                <h6 className="text-muted m-0">{data?.likeCount} Likes</h6>
              </div>
              <div className="light-text mx-2">
                <h6 className="text-muted m-0">
                  {data?.commentCount} comments
                </h6>
              </div>
            </div>
            <div className="my-2 endline w-100" />

            {topPostTab === "purchases" && (
              <div className="fw-bold">
                Total Purchases : $
                {data?.totalPurchaseAmount
                  ? data?.totalPurchaseAmount?.toFixed(2)
                  : "0.00"}
              </div>
            )}
            {topPostTab === "tips" && (
              <div className="fw-bold">
                Total Tips : $
                {data?.totalTipAmount
                  ? data?.totalTipAmount?.toFixed(2)
                  : "0.00"}
              </div>
            )}
            {topPostTab === "views" && (
              <div className="fw-bold">
                Total Views : {data?.viewCount || 0}
              </div>
            )}
            {topPostTab === "likes" && (
              <div className="fw-bold">
                Total Likes : {data?.likeCount || 0}
              </div>
            )}
            {topPostTab === "comments" && (
              <div className="fw-bold">
                Total Comments : {data?.commentCount || 0}
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    let totalAmount = 0;
    if (Array.isArray(engagementMessage)) {
      engagementMessage.forEach((item) => {
        if (
          Array.isArray(item?.messagebuyers) &&
          item.messagebuyers.length > 0
        ) {
          totalAmount += item.messagebuyers.reduce(
            (sum, buyer) => sum + (buyer.amount || 0),
            0
          );
        }
      });
    }
    setTotalMessageEarning(totalAmount);
  }, [engagementMessage]);

  return (
    <>
      {subTab === "Posts" && (
        <div className="earniing_div">
          <div className="py-2 px-2 border-top card_boader">
            <h5 className="sidebar_head">{t("Summary")}</h5>
            <Row>
              <Col sm="6" className="my-2">
                <p className="text-muted m-0">Posts</p>
                <p className="m-0 fw-bold text-dark">
                  {engagementData?.total?.posts?.length || 0}
                </p>
              </Col>
              <Col sm="6" className="my-2">
                <p className="text-muted m-0">Posts views</p>
                <p className="m-0 fw-bold text-dark">
                  {engagementData?.total?.viewersCount || 0}
                </p>
              </Col>
              <Col sm="6" className="my-2">
                <p className="text-muted m-0">Posts earnings</p>
                <p className="m-0 fw-bold text-dark">
                  $
                  {engagementData?.total?.totalNetAmount
                    ? engagementData?.total?.totalNetAmount?.toFixed(2)
                    : "0.00"}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {subTab === "Messages" && (
        <div className="earniing_div">
          <div className="py-2 px-2 border-top card_boader">
            <h5 className="sidebar_head">{t("Summary")}</h5>
            <Row>
              <Col sm="8" className="my-2">
                <p className="text-muted m-0">Messages</p>
                <p className="m-0 fw-bold text-dark">
                  {engagementMessage?.length || 0}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="8" className="my-2">
                <p className="text-muted m-0">Message earnings</p>
                <p className="m-0 fw-bold text-dark">
                  ${totalMessageEarning?.toFixed(2)}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {subTab === "Posts" ? (
        <div className="earniing_div">
          <div className="pt-3 px-2 border-top card_boader my-2">
            <h5 className="sidebar_head pb-2 border-bottom">{t("Top Post")}</h5>
            {topPost ? (
              <div className="CardBody">
                <div className="innner_tabs">
                  <Tab.Container
                    activeKey={topPostTab}
                    id="left-tabs-example"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Nav
                      variant="pills"
                      className="flex-nowrap overflow-scroll"
                      style={{ scrollbarWidth: "none" }}
                    >
                      <Nav.Item
                        className={`me-1 ${
                          topPost.purchases === null && "inactiveTab"
                        }`}
                      >
                        <Nav.Link
                          disabled={!topPost?.purchases}
                          className="bg-transparent border-0 position-relative text-small"
                          eventKey="purchases"
                          onClick={() => setTopPostTab("purchases")}
                        >
                          {t("Purchases")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className={`me-1 ${
                          topPost?.tips === null && "inactiveTab"
                        }`}
                      >
                        <Nav.Link
                          disabled={!topPost?.tips}
                          className="bg-transparent border-0 position-relative text-small"
                          eventKey="tips"
                          onClick={() => setTopPostTab("tips")}
                        >
                          {t("Tips")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className={`me-1 ${
                          topPost?.views === null && "inactiveTab"
                        }`}
                      >
                        <Nav.Link
                          disabled={!topPost?.views}
                          className="bg-transparent border-0 position-relative text-small"
                          eventKey="views"
                          onClick={() => setTopPostTab("views")}
                        >
                          {t("Views")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className={`me-1 ${
                          topPost?.likes === null && "inactiveTab"
                        }`}
                      >
                        <Nav.Link
                          disabled={!topPost?.likes}
                          className="bg-transparent border-0 position-relative text-small"
                          eventKey="likes"
                          onClick={() => setTopPostTab("likes")}
                        >
                          {t("Likes")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className={`me-1 ${
                          topPost?.comments === null && "inactiveTab"
                        }`}
                      >
                        <Nav.Link
                          disabled={!topPost?.comments}
                          className="bg-transparent border-0 position-relative text-small"
                          eventKey="comments"
                          onClick={() => setTopPostTab("comments")}
                        >
                          {t("Comments")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container>
                  <Tab.Content className="">
                    <Tab.Pane
                      eventKey="purchases"
                      active={topPostTab === "purchases"}
                    >
                      <div className="my-3">{renderPostCard("purchases")}</div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tips" active={topPostTab === "tips"}>
                      <div className="my-3">{renderPostCard("tips")}</div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="views" active={topPostTab === "views"}>
                      <div className="my-3">{renderPostCard("views")}</div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="likes" active={topPostTab === "likes"}>
                      <div className="my-3">{renderPostCard("likes")}</div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="comments"
                      active={topPostTab === "comments"}
                    >
                      <div className="my-3">{renderPostCard("comments")}</div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            ) : (
              <p className="m-0">
                {t("No Post activity during selected period")}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="earniing_div">
          <div className="pt-3 px-2 border-top card_boader my-2">
            <h5 className="sidebar_head pb-2 border-bottom">
              {t("Top Messages")}
            </h5>
            <p className="m-0 py-2">
              {t("No Message activity during selected period")}
            </p>
          </div>
        </div>
      )}

      <Statistics
        show={postStats}
        onhide={() => {
          setPostStats(false);
          setSelectedRecord(null);
        }}
        data={selectedRecord}
      />

      <TagUserseList
        show={tagcreator}
        onhide={() => setTagcreator(false)}
        data={topPost[topPostTab]}
      />
    </>
  );
};

export default EngagementSummary;
