import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sendTipToProfile } from "../../../redux/states/card/thunk";
import { useDispatch } from "react-redux";

const ConfirmPayment = (props) => {
  const { onHide, handleMessageSend, sendTipPayload } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handlPay = () => {
    setLoading(true);
    dispatch(sendTipToProfile(sendTipPayload))
      .unwrap()
      .then((res) => {
        handleMessageSend();
        toast.success(`$${sendTipPayload?.amount} tip sent successfully.`);
        setLoading(false);
        onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
        onHide();
      });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <div className="modal_buy-cont">
            <h5 className="fw-sbold text-1xl m-0">{t("Confirm Payment")}</h5>
            <hr class="custom_hr"></hr>
            <div className="pt-2">
              <p className="fw-sbold text-small">
                {t("You will be charged")} ${sendTipPayload?.amount}
              </p>
              <div className="modal_buy_btn d-flex justify-content-center mt-4">
                <Button
                  className="mod_cancelbtn blackBtn border-0 text-small"
                  style={{ marginRight: "16px" }}
                  onClick={() => onHide()}
                >
                  {t("Cancel")}
                </Button>
                {loading ? (
                  <Button
                    className="mod_confirmbtn blackBtn border-0 text-small"
                    style={{ minWidth: "70px" }}
                  >
                    <ButtonLoader
                      style={{ height: "18px", width: "18px" }}
                      color={"text-ligth"}
                    />
                  </Button>
                ) : (
                  <Button
                    className="mod_confirmbtn blackBtn border-0"
                    onClick={handlPay}
                  >
                    {t("Pay")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmPayment;
