import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { unlockMessage } from "../../../redux/states/card/thunk";
import { toast } from "react-toastify";
import SmallLoader from "../ButtonLoader/SmallLoader";
import { Link } from "react-router-dom";
import { IoMdCard } from "react-icons/io";
import Addpaymentmod from "./Addpaymentmod";

const UnlockMessagePaymentModal = (props) => {
  const { t } = useTranslation();
  const { onHide, refreshList, selectedMessage } = props;
  const { cardList, buttonLoader } = useSelector((s) => s.card);
  const [selectedIndex, setSelectedIndex] = useState();
  const dispatch = useDispatch();
  const [addpaymentmod, setaddpaymentmod] = useState(false);
  const [payData, setPayData] = useState({
    messageId: "",
    paymentMethod: "card",
    paymentSourceRefNo: "",
    paySourceId: "",
    lastd: "",
  });

  const refreshForm = () => {
    setPayData({
      messageId: "",
      paymentMethod: "",
      paymentSourceRefNo: "",
      paySourceId: "",
      lastd: "",
    });
  };

  const payForPost = () => {
    if (payData?.paymentMethod === "") {
      toast.warning(t("Please select payment method"));
    } else {
      dispatch(unlockMessage(payData))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          onHide();
          refreshForm();
          refreshList();
        })
        .catch((err) => {
          toast.error(err?.message);
          onHide();
        });
    }
  };

  const handleCardSelection = (card, paymentType, index, messageId) => {
    setSelectedIndex(index);
    if (paymentType === "card") {
      setPayData((rest) => ({
        ...rest,
        messageId,
        paymentSourceRefNo: card?._id,
        paymentMethod: "card",
        paySourceId: card?.paySourceId,
        lastd: card?.cardLast4,
      }));
    } else if (paymentType === "wallet") {
      setPayData((rest) => ({
        ...rest,
        messageId,
        paymentMethod: "wallet",
      }));
    }
  };

  const cardLogo = (item) => {
    if (item === "VISA") {
      return "https://splash-ark.s3.us-east-2.amazonaws.com/16608181755941614744039133visacard.png";
    } else if (item === "MASTERCARD") {
      return "https://fansroom.s3.ap-south-1.amazonaws.com/1697708129991";
    } else if (item === "DISCOVER") {
      return "/images/discover.png";
    } else {
      return "/images/amex.png";
    }
  };

  return (
    <>
      {" "}
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="buy_modal"
      >
        <Modal.Body>
          <div className="modal_inner_main position-relative">
            <div className="modal_buy-cont">
              <h4 className="fw-sbold m-0 text-normal">
                {t("Confirm Message Purchase")}
              </h4>
              <hr class="custom_hr"></hr>
              <div>
                <div
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                  className="mb-3 mt-2"
                >
                  {cardList?.map((itm, ind) => {
                    return (
                      <div
                        className="payementCrdd shadow2"
                        key={ind}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCardSelection(
                            itm,
                            "card",
                            ind,
                            selectedMessage?._id
                          );
                        }}
                      >
                        <input
                          type="radio"
                          id={itm?.token}
                          name="payment"
                          checked={selectedIndex === ind}
                          onClick={() =>
                            handleCardSelection(
                              itm,
                              "card",
                              ind,
                              selectedMessage?._id
                            )
                          }
                        />
                        <label
                          htmlFor={itm?.token}
                          className="d-flex align-items-center"
                        >
                          <img
                            src={cardLogo(itm?.cardType)}
                            alt=""
                            className="mx-3"
                          />
                          <p className="mb-0 text-small d-flex align-items-center">
                            {" "}
                            <span style={{ fontSize: "30px" }}>
                              {" "}
                              ···· ···· ····
                            </span>{" "}
                            <p className="mb-0 mx-2">{itm?.cardLast4}</p>
                          </p>
                        </label>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="payementCrdd shadow2 py-2 pointer">
              <input
                type="radio"
                id={"wallet"}
                name="payment"
                onClick={() => handleCardSelection({}, "wallet")}
              />
              <label htmlFor="wallet" className="d-flex align-items-center ">
                <IoMdWallet size={23} color="#5e5c5c" className="mx-4" />
                <h6 className="text-small mb-0 mx-2">
                  {t("Pay from wallet")}
                </h6>
              </label>
            </div> */}
                <div className="payementCrdd shadow2 py-2 pointer d-flex justify-content-center">
                  <label
                    htmlFor="wallet"
                    className="d-flex align-items-center "
                    onClick={() => setaddpaymentmod(true)}
                  >
                    <IoMdCard size={23} color="#5e5c5c" className="" />
                    <h6 className="text-small mb-0 mx-2">{t("Add Card")}</h6>
                  </label>
                </div>
                <h4
                  className="privacy_links text-black text-small"
                  style={{ fontSize: 12 }}
                >
                  {t("By making payment you agree to our")}{" "}
                  <Link to="/terms" className="text-dark">
                    {t("Terms of Service")}
                  </Link>{" "}
                  {t("and")}
                  <Link to="/privacy" className="text-dark">
                    {" "}
                    {t("Privacy Policy")}
                  </Link>
                  .
                </h4>
                <div className="modal_buy_btn d-flex justify-content-end mt-4">
                  <Button
                    className="mod_cancelbtn blackBtn border-0"
                    style={{ marginRight: "16px" }}
                    onClick={() => {
                      onHide();
                      refreshForm();
                      setSelectedIndex(null);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  {buttonLoader ? (
                    <Button className="mod_confirmbtn blackBtn border-0">
                      <SmallLoader />
                    </Button>
                  ) : (
                    <Button
                      className="mod_confirmbtn blackBtn border-0"
                      onClick={payForPost}
                    >
                      {t("Pay")} {selectedMessage?.price} $
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Addpaymentmod
        show={addpaymentmod}
        onhide={() => setaddpaymentmod(false)}
        hideBack
      />
    </>
  );
};

export default UnlockMessagePaymentModal;
