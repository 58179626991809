// This is the normal post component ;

import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BsBookmark, BsFillSendFill, BsFillBookmarkFill } from "react-icons/bs";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import User1 from "../../../../images/user1.svg";
import LogoW from "../../../../images/logoW.png";
import SendTip from "../../../common/Modal/SendTip";
import { getTimeAgo } from "../../../../helpers/time";
import User from "../../../../images/user.png";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPoll,
  addQuiz,
  bookmarkPost,
  commentPost,
  likePost,
  unlikePost,
} from "../../../../redux/actions";
import { PiDotsThreeCircleVerticalBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { BiSolidSend } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import DummmyImg from "../../../../images/dummyBack.jpeg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommentSection from "../../../common/CommentSection/CommentSection";
import PostViewer from "../../../common/PostViewer/PostViewer";
import Heart from "../../../../images/heart.svg";
import FilledHeart from "../../../../images/filledHeart.svg";
import Bookmark from "../../../../images/bookmark.svg";
import FilledBookmark from "../../../../images/filledBookmark.svg";
import Tip from "../../../../images/dollar.svg";
import Comment from "../../../../images/comment.svg";
import { formatText } from "../../../../helpers/formatPostText";
import TagUserseList from "../../../common/Modal/EntertainerModal/TagUsersList";

const EntertainerPostSection = (props) => {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const {
    data,
    key,
    getPostLists,
    showProfileBanners,
    handleDelete,
    handlePostEdit,
    newList,
    setNewList,
  } = props;
  const userId = useSelector((s) => s.setting)?.user?._id;
  const [msg, setMsg] = useState("");
  const [tagcreator, setTagcreator] = useState(false);
  const commentBoxRef = useRef();
  const media = data?.media;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const dta = {
          ...itm,
          isLiked: true,
          likes: [...itm?.likes, { userId: userId }],
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        getPostLists();
      })
      .catch((err) => {
        if (newList) {
          const ddd = newList?.map((itm) => {
            if (itm?._id === dta?._id) {
              const filteredDta = newList((it) => it?._id !== dta?._id);
              const dta = { ...itm, isLiked: false, likes: filteredDta };
              return dta;
            } else return itm;
          });
          setNewList(ddd);
        }
      });
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const filterDta = itm?.likes?.filter((it) => it?.userId !== userId);
        const dta = {
          ...itm,
          isLiked: false,
          likes: filterDta,
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {});
  };

  const handleBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const dta = {
          ...itm,
          isBookmarked: true,
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        const ddd = newList?.map((itm) => {
          if (itm?._id === dta?._id) {
            const dta = {
              ...itm,
              isBookmarked: false,
            };
            return dta;
          } else return itm;
        });
        setNewList(ddd);
      });
  };

  // br28c0109

  const handleUnBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    const ddd = newList?.map((itm) => {
      if (itm?._id === dta?._id) {
        const dta = {
          ...itm,
          isBookmarked: false,
        };
        return dta;
      } else return itm;
    });
    setNewList(ddd);
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        const ddd = newList?.map((itm) => {
          if (itm?._id === dta?._id) {
            const dta = {
              ...itm,
              isBookmarked: true,
            };
            return dta;
          } else return itm;
        });
        setNewList(ddd);
      });
  };

  var isLiked = false;
  var isBookmarked = false;

  const bookmarkdta = data?.bookmarks?.find((item, id) => {
    return item?.userId == userId;
  });

  const userDta = data?.likes?.find((item, id) => {
    return item?.userId == userId;
  });

  if (userDta?.userId) {
    isLiked = true;
  } else {
    isLiked = false;
  }

  if (bookmarkdta?.userId) {
    isBookmarked = true;
  } else {
    isBookmarked = false;
  }

  const sendMsg = (dta) => {
    if (msg === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: msg,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          setMsg("");
          toast.success(t("comment posted"));
          getPostLists();
        })
        .catch((err) => {
          toast.error(err.message);
          setMsg("");
        });
    }
  };

  const baseUrl = window.location.protocol + "//" + window.location.host;

  const handleKeyPress = (event, dta) => {
    if (event.key === "Enter") {
      sendMsg(dta);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  const clickOutside = (e) => {
    if (!commentBoxRef.current?.contains(e.target)) {
      setShowCommentBox(false);
    } else {
      setShowCommentBox(true);
    }
  };

  const handlePollSubmit = (poll, post) => {
    const data = {
      postId: post?._id,
      optionId: poll?._id,
    };
    dispatch(addPoll(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleQuizSubmit = (quiz, post) => {
    const data = {
      postId: post?._id,
      optionId: quiz?._id,
    };
    dispatch(addQuiz(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const [showCommentPop, setCommentPop] = useState(false);
  const [showSendTip, setShowSendTip] = useState(false);
  const [selectedPostForTip, setSelectedPostForTip] = useState({});
  const handleCommentPop = () => setCommentPop(!showCommentPop);

  const handleTip = (dta) => {
    setSelectedPostForTip(dta);
    setShowSendTip(true);
  };

  return (
    <>
      <div className="postCard" key={key}>
        <CommentSection
          showCommentPop={showCommentPop}
          handleCommentPop={handleCommentPop}
          commentBoxRef={commentBoxRef}
          msg={msg}
          setMsg={setMsg}
          data={data}
          handleKeyPress={handleKeyPress}
          sendMsg={sendMsg}
        />
        <SendTip
          show={showSendTip}
          onHide={() => setShowSendTip(false)}
          data={selectedPostForTip}
        />
        {data?.isPosted && (
          <>
            <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
              <div className="d-flex align-items-center">
                <img
                  src={data?.userDetails?.profileImage || LogoW}
                  alt=""
                  className="postUserPic"
                />
                <div className="mx-3 fansDetails">
                  <h6 className="text-bold mb-0 ">
                    {data?.userDetails?.firstName
                      ? data?.userDetails?.firstName +
                        " " +
                        (data?.userDetails?.lastName || "")
                      : data?.userDetails?.name}
                  </h6>
                  <h6 className="text-small mb-0">
                    {data?.userDetails?.userName}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h6 className="text-small mx-2 mb-0">
                  {getTimeAgo(data?.createdAt)}
                </h6>

                <div className="three_dot_dropdown">
                  <UncontrolledDropdown>
                    <DropdownToggle>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                          <path
                            d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                            fill="black"
                          />
                          <path
                            d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                            fill="black"
                          />
                          <path
                            d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_87_10716">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(18) rotate(90)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </DropdownToggle>
                    <DropdownMenu>
                      <CopyToClipboard
                        text={baseUrl + "/post-details/" + data?.slug}
                        onCopy={() =>
                          toast.success(
                            t("Link to post has been copied to clipboard!")
                          )
                        }
                      >
                        <DropdownItem>
                          <span>{t("Copy Link to post")}</span>
                        </DropdownItem>
                      </CopyToClipboard>

                      <DropdownItem onClick={() => handlePostEdit(data)}>
                        <span>{t("Edit Post")}</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDelete(data)}>
                        <span>{t("Delete Post")}</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
            <div className="PostQuiz shadow1 p-3 mb-3">
              <div className="my-2 top">
                <h6 className="m-0 fw-bold">
                  {data?.caption}
                  {formatText(data?.description)}
                </h6>
              </div>
              {data?.postType === "quiz" && (
                <div className="my-2">
                  {data?.quizOption?.map((option, index) => {
                    var totalValue = 0;
                    const quizValue = option?.quizCount || 0;
                    totalValue = totalValue + quizValue;
                    return (
                      <div
                        key={index}
                        className="py-1 d-flex align-items-center position-relative"
                      >
                        <input
                          type="radio"
                          className="position-absolute w-100 h-100"
                          name="quiz"
                          checked={option?.isSelected}
                          id={option?.option}
                          onChange={() => handleQuizSubmit(option, data)}
                        />
                        <label
                          htmlFor={option?.option}
                          className=" label px-3 py-2 d-block w-100 position-relative"
                        >
                          {option?.option}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
              {data?.postType === "poll" && (
                <div className="mb-2">
                  {data?.pollOption?.map((option, index) => {
                    return (
                      <div
                        key={index}
                        className="py-1 d-flex align-items-center position-relative "
                      >
                        <input
                          type="radio"
                          className="position-absolute text-white w-100 h-100"
                          name="poll"
                          id={option?.option}
                          onChange={() => handlePollSubmit(option, data)}
                          checked={option?.isSelected}
                        />
                        <label
                          className=" label px-3 py-2 text-white d-block w-100 position-relative"
                          htmlFor={option?.option}
                        >
                          <span
                            className="fill position-absolute w-100 h-100"
                            style={{ maxWidth: option?.option }}
                          ></span>
                          {option?.option}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="GallaryMedia">
              <PostViewer media={media} data={data} />
            </div>
            {data?.tagUser?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  padding: "0rem 0.8rem",
                  marginTop: "16px",
                  color: "#8a96a3",
                  fontWeight: 600,
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginRight: "10px",
                  }}
                  onClick={() => setTagcreator(!tagcreator)}
                >
                  <span style={{ color: "#00aff0" }}>@</span> Tags
                </div>
                <div
                  style={{ flexWrap: "wrap", width: "100%", display: "flex" }}
                >
                  {data?.tagUser?.map((val) => (
                    <div style={{ display: "flex", marginRight: "3px" }}>
                      {/* <img
                      src={val.profileImage}
                      alt="Profile"
                      onClick={() => navigate(`/${val?.userName}`)}
                      style={{
                        height: "24px",
                        width: "24px",
                        borderRadius: "50%",
                        marginRight: "3px",
                        cursor: "pointer",
                      }}
                    /> */}
                      <div className="username">@{val.userName} </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative">
              <div className="d-flex align-items-center postaction">
                <span>
                  {data?.isLiked ? (
                    <img
                      src={FilledHeart}
                      className="actionIcon"
                      onClick={() => handleUnLikePost(data)}
                    />
                  ) : (
                    <img
                      src={Heart}
                      className="actionIcon"
                      onClick={() => handleLikePost(data)}
                    />
                  )}
                </span>
                <span onClick={handleCommentPop} className="pointer">
                  <img src={Comment} className="actionIcon" />
                </span>

                {/* <span className="pointer" onClick={() => handleTip(data)}>
                  <img src={Tip} className="actionIcon" />
                </span>
                <span>{t("SEND TIPS")} </span> */}
              </div>
              <div className="">
                {data?.isBookmarked ? (
                  <img
                    src={FilledBookmark}
                    className="actionIcon"
                    onClick={() => handleUnBookmarkPost(data)}
                  />
                ) : (
                  <img
                    src={Bookmark}
                    className="actionIcon"
                    onClick={() => handleBookmarkPost(data)}
                  />
                )}
              </div>
            </div>

            <div className="d-flex">
              <div className="light-text">
                {data?.likes?.length} {t("Likes")}
              </div>
              <div className="light-text mx-2">
                {data?.comments?.length} {t("comments")}
              </div>
            </div>
            <div className="my-4 endline full-width"></div>

            <TagUserseList
              show={tagcreator}
              onhide={() => setTagcreator(false)}
              data={data?.tagUser}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EntertainerPostSection;
