import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import User from "../../../images/user.png";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  sendPostTipAmount,
  sendTipToProfile,
} from "../../../redux/states/card/thunk";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { IoMdCard, IoMdWallet } from "react-icons/io";
import { toast } from "react-toastify";
import { getProfile } from "../../../redux/states/settings/thunk";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import Addpaymentmod from "../Modal/Addpaymentmod";

// const stripePromise = loadStripe(
//   "pk_test_51O2gNNLI2EI2TtmrOCtzqsiaFTbzEA6Pn3JlN1vhFWTm9mQU6zIWgjuxXbWTFBHOMf0fGN5EsBcMysihhxwztlcm00nl8e4dlh"
// );

const SendTip = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, type, sendMessageFunc, setSendTipPayload, postId } = props;
  const [cardSelected, setCardSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [payData, setPayData] = useState({
    postId: null,
    profileId: null,
    amount: null,
    cardType: null,
    cardLast4: null,
    paySourceId: null,
    paymentMethod: "card",
    paymentSourceRefNo: "",
  });

  const [addpaymentmod, setaddpaymentmod] = useState(false);
  const { cardList, buttonLoader } = useSelector((s) => s.card);

  const refreshForm = () => {
    setPayData({
      postId: null,
      profileId: null,
      amount: null,
      cardType: null,
      cardLast4: null,
      paySourceId: null,
      paymentMethod: "",
      paymentSourceRefNo: "",
    });
  };

  const onSuccess = () => {
    refreshForm();
    dispatch(getProfile());
    toast.success(
      `$${payData?.amount} tip send to ${
        data?.firstName + " " + (data?.lastName || " ")
      } successfully.`
    );
    props.onHide();
  };

  const payTip = () => {
    if (type === "postTip") {
      dispatch(sendPostTipAmount(payData))
        .unwrap()
        .then(() => {
          onSuccess();
        })
        .catch((err) => {
          toast.error(err?.message);
          props.onHide();
        });
    } else {
      dispatch(sendTipToProfile(payData))
        .unwrap()
        .then(() => {
          onSuccess();
        })
        .catch((err) => {
          toast.error(err?.message);
          props.onHide();
        });
    }
  };

  const handleCardSelection = (card, paymentType) => {
    let id = {};
    if (paymentType === "card" && data) {
      if (type === "postTip" && postId) {
        id = { postId: postId };
      } else {
        id = {
          profileId: data?._id,
        };
      }
      setPayData((rest) => ({
        ...rest,
        ...id,
        cardType: card?.cardType,
        cardLast4: card?.cardLast4,
        paySourceId: card?.paySourceId,
        paymentMethod: "card",
      }));
    }
    // else if (paymentType === "wallet") {
    //   setPayData((rest) => ({ ...rest, paymentMethod: "wallet" }));
    // }
  };

  // useEffect(() => {
  //   if (type === "profile") {
  //     setPayData({
  //       profileId: userId,
  //       amount: "",
  //       paymentMethod: "card",
  //       paymentSourceRefNo: "",
  //     });
  //   } else {
  //     setPayData((rest) => ({ ...rest }));
  //   }
  // }, [data, type, props.show]);

  const close = () => {
    refreshForm();
    props.onHide();
  };

  function onSuccessAddCard() {}

  if (addpaymentmod) {
    return (
      <>
        {" "}
        <Addpaymentmod
          show={addpaymentmod}
          onhide={() => setaddpaymentmod(false)}
          onSuccess={() => {
            onSuccessAddCard();
          }}
          hideBack
        />
      </>
    );
  }

  const cardLogo = (item) => {
    if (item === "VISA") {
      return "https://splash-ark.s3.us-east-2.amazonaws.com/16608181755941614744039133visacard.png";
    } else if (item === "MASTERCARD") {
      return "https://fansroom.s3.ap-south-1.amazonaws.com/1697708129991";
    } else if (item === "DISCOVER") {
      return "/images/discover.png";
    } else {
      return "/images/amex.png";
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pin_modal"
    >
      <Modal.Body>
        <div className="modal_header d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <h6 className="m-0 py-2">{t("SEND TIP")} </h6>
          {/* <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 3v2H3V3h9Zm4 16v2H3v-2h13Zm6-8v2H3v-2h19Z"
              />
            </svg>
          </span> */}
        </div>

        <div className="enter_tip px-3 py-2">
          {data && (
            <div className="d-flex align-items-center my-1">
              <img
                src={data?.profileImage || User}
                alt=""
                className="sendTipUserPic"
              />
              <div className="mx-2 sendTipAritstDetail">
                <h6 className="text-bold mb-0 ">
                  {data?.firstName
                    ? data?.firstName + " " + (data?.lastName || " ")
                    : data?.name}
                </h6>
                <h6 className="text-small mb-0">@{data?.userName}</h6>
              </div>
            </div>
          )}

          <div>
            <FormGroup>
              <label className="text-small my-2">{t("Enter Tip")}</label>
              <InputGroup>
                <Input
                  type="number"
                  name="radio1"
                  className="text-small"
                  placeholder="$ Tip Amount"
                  value={payData?.amount}
                  onChange={(e) => {
                    setPayData((rest) => ({ ...rest, amount: e.target.value }));
                  }}
                  onBlur={() => {
                    if (payData.amount <= 0) {
                      setShowError(true);
                    } else {
                      setShowError(false);
                    }
                  }}
                />
                <InputGroupText>$</InputGroupText>
              </InputGroup>
              {showError && (
                <span className="text-danger errorText">
                  {t("Amount should be greater than 0")}
                </span>
              )}
            </FormGroup>

            <div className="tip_dropdown mb-3">
              <UncontrolledDropdown>
                <DropdownToggle className="position-relative">
                  <span className="tip_arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </span>
                  {payData?.cardType ? (
                    <div className="select_here d-flex align-item-center">
                      <div className="tip_slect-img me-2">
                        <img
                          src={cardLogo(payData?.cardType)}
                          alt=""
                          className=""
                        />
                      </div>
                      <p className="mb-0 d-flex align-items-center">
                        <span className="payment-slect "> ···· ···· ····</span>{" "}
                        <p className="mb-0 mx-2 payment-slect">
                          {payData?.cardLast4}
                        </p>
                      </p>
                    </div>
                  ) : (
                    "Payment Method"
                  )}
                </DropdownToggle>
                <DropdownMenu>
                  {cardList?.map((itm, ind) => {
                    return (
                      <div className="payementCrdd" key={ind}>
                        <label
                          htmlFor={itm?.token}
                          className="d-flex align-items-center pointer w-100"
                        >
                          <input
                            checked={itm?.cardLast4 === payData?.cardLast4}
                            type="radio"
                            id={itm?.token}
                            name="payment"
                            onClick={() => {
                              setCardSelected(!cardSelected);
                              handleCardSelection(itm, "card");
                            }}
                          />
                          <img
                            src={cardLogo(itm?.cardType)}
                            alt=""
                            className="mx-3"
                          />
                          <p className="mb-0 d-flex align-items-center">
                            <span className="payment-slect ">
                              {" "}
                              ···· ···· ····
                            </span>{" "}
                            <p className="mb-0 mx-2 payment-slect">
                              {itm?.cardLast4}
                            </p>
                          </p>
                        </label>
                      </div>
                    );
                  })}

                  <div className="payementCrdd shadow2 py-2 pointer d-flex justify-content-center">
                    <label
                      htmlFor="wallet"
                      className="d-flex align-items-center "
                      onClick={() => {
                        refreshForm();
                        setaddpaymentmod(true);
                      }}
                    >
                      <IoMdCard size={23} color="#5e5c5c" className="" />
                      <h6 className="text-small mb-0 mx-2">{t("Add Card")}</h6>
                    </label>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            {/* <div className="payementCrdd shadow2 py-2 pointer">
              <input
                type="radio"
                id={"wallet"}
                name="payment"
                onClick={() => handleCardSelection({}, "wallet")}
              />
              <label htmlFor="wallet" className="d-flex align-items-center ">
                <IoMdWallet size={23} color="#5e5c5c" className="mx-4" />
                <h6 className="text-small mb-0 mx-2">{t("Pay from wallet")}</h6>
              </label>
            </div> */}

            <h4
              className="privacy_links text-black text-small"
              style={{ fontSize: 12 }}
            >
              {t("By making payment you agree to our")}{" "}
              <Link to="/terms" className="text-dark">
                {t("Terms of Service")}
              </Link>{" "}
              {t("and")}
              <Link to="/privacy" className="text-dark">
                {" "}
                {t("Privacy Policy")}
              </Link>
              .
            </h4>
          </div>
        </div>

        <div className="modal_footer d-flex align-items-center justify-content-end px-3 py-2 border-top">
          <Button onClick={close} className="close_text">
            {t("Close")}
          </Button>
          {buttonLoader ? (
            <Button className="tip_btn ms-2">
              <SmallLoader />
            </Button>
          ) : (
            <Button
              className={`tip_btn ms-2 ${
                (!payData?.amount || !payData?.cardType || showError) &&
                "btn-disable "
              } m-0`}
              disabled={!payData?.amount || !payData?.cardType}
              onClick={() => {
                if (sendMessageFunc && !showError) {
                  setSendTipPayload(payData);
                  close();
                } else if (!showError) {
                  payTip();
                }
              }}
            >
              {sendMessageFunc ? t("Done") : t("Submit")}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendTip;
