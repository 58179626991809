import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { udpateThemeMode } from "../../../redux/states/auth/slice";

const Lightmode = ({darkicontoggle}) => {

  const dispatch = useDispatch()
  const {isDarkMode} = useSelector((s) => s.login) 

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
   dispatch(udpateThemeMode(newDarkMode))
    darkicontoggle(newDarkMode);
  };

 
  return (
    <div className="toggle-theme-wrapper d-flex align-items-center justify-content-between">
      <label className="switch">
        <input type="checkbox" checked={isDarkMode} onClick={toggleDarkMode} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default Lightmode;
