import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import {
  connectOtherAccount,
  getProfile,
  updateProfile,
} from "../../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SocialAccount = () => {
  const { t } = useTranslation();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      instagramLink: "",
      twitterLink: "",
      youtubeLink: "",
    },
    onSubmit: async function (values, { resetForm }) {
      // Check if any of the fields have values
      if (values.instagramLink || values.twitterLink || values.youtubeLink) {
        dispatch(updateProfile(values))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            getUserData();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        // Handle the case when none of the fields have values
        toast.warning("Please provide at least one social media link.");
      }
    },
    validationSchema: Yup.object({
      instagramLink: Yup.string().url(t("Invalid Url")),
      twitterLink: Yup.string().url(t("Invalid Url")),
      youtubeLink: Yup.string().url(t("Invalid Url")),
    }),
  });

  useEffect(() => {
    formik.setFieldValue("instagramLink", user.instagramLink);
    formik.setFieldValue("twitterLink", user.twitterLink);
    formik.setFieldValue("youtubeLink", user.youtubeLink);
  }, [user]);

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>SOCIAL ACCOUNT</span>
        </h4>
      </div>
      <div className="endline my-2"></div>
      <div className="mx-4">
        <div className="Login_input_warp form-floating mt-4 ">
          <input
            type="text"
            class="form-control"
            placeholder="Instagram Link"
            name="instagramLink"
            id="floatingInput"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.instagramLink}
          />
          <label for="floatingInput">Instagram Link</label>
          {formik.touched.instagramLink && formik.errors.instagramLink && (
            <span className="text-danger errorText">
              {formik.errors.instagramLink}
            </span>
          )}
        </div>
        <div className="Login_input_warp form-floating mt-4 ">
          <input
            type="text"
            class="form-control"
            placeholder={"Twitter Link"}
            name="twitterLink"
            id="floatingInput"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.twitterLink}
          />
          <label for="floatingInput">Twitter Link</label>
          {formik.touched.twitterLink && formik.errors.twitterLink && (
            <span className="text-danger errorText">
              {formik.errors.twitterLink}
            </span>
          )}
        </div>
        <div className="Login_input_warp form-floating mt-4 ">
          <input
            type="text"
            class="form-control"
            placeholder={"Youtube Link"}
            name="youtubeLink"
            id="floatingInput"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.youtubeLink}
          />
          <label for="floatingInput">Youtube Link</label>
          {formik.touched.youtubeLink && formik.errors.youtubeLink && (
            <span className="text-danger errorText">
              {formik.errors.youtubeLink}
            </span>
          )}
        </div>

        <div className="mt-2 ">
          {buttonLoader ? (
            <button className="loginBtn text-light">
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="loginBtn text-light"
              onClick={formik.handleSubmit}
            >
              {t("Save")}
            </button>
          )}
        </div>
      </div>
    </Settings>
  );
};

export default SocialAccount;
