import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import UserList from "./UserList";
import Bookmarks from "./Bookmarks";
import Users from "./Users";
import Posts from "./Posts";
import { useSelector } from "react-redux";
import SaveToList from "../../common/Modal/SaveToList";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubscriptionsComponent = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tab, setTab] = useState("like-post");
  const { list, loader } = useSelector((s) => s.artist);
  const allPosts = useSelector((s) => s.home);

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
  };

  const [userList, setUserList] = useState([]);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    setUserList(list);
  }, [list]);

  useEffect(() => {
    setPostList(allPosts?.list);
  }, [allPosts?.list]);

  return (
    <Row className="  h-100 justify-content-between ">
      <Col lg="5" md="12" sm="12">
        <div className="shadow1 rounded border h-100 py-4 ">
          <div className="d-flex justify-content-between align-items-center px-4">
            <h4 className="text-bold">
              <span className="pointer" onClick={() => navigate(-1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span style={{ marginLeft: "7px" }}>{t("COLLECTIONS")}</span>
            </h4>
            <h6 className="d-flex align-items-center">
              <span className="mx-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M15.3536 14.6465L11.1614 10.4544C12.0557 9.39963 12.5969 8.0365 12.5969 6.54862C12.5968 3.21338 9.8835 0.5 6.54838 0.5C3.21325 0.5 0.5 3.21338 0.5 6.54862C0.5 9.88375 3.21325 12.597 6.54838 12.597C8.03638 12.597 9.39963 12.0559 10.4543 11.1615L14.6465 15.3536C14.7441 15.4513 14.8721 15.5001 15.0001 15.5001C15.1281 15.5001 15.256 15.4513 15.3538 15.3536C15.4475 15.2598 15.5001 15.1326 15.5001 15C15.5001 14.8674 15.4474 14.7403 15.3536 14.6465ZM1.5 6.54862C1.5 3.76475 3.76463 1.5 6.54838 1.5C9.33212 1.5 11.5969 3.76475 11.5969 6.54862C11.5969 9.33237 9.33212 11.597 6.54838 11.597C3.76463 11.5969 1.5 9.33225 1.5 6.54862Z"
                    fill="black"
                  />
                </svg>
              </span>
              <span className="collPlus ">+</span>
            </h6>
          </div>

          <div className="CardBody py-3 commonTabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="LikePosts"
              className="d-flex justify-content-between align-items-center"
            >
              <Nav variant="pills" className="">
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small"
                    eventKey="LikePosts"
                    onClick={() => handleTabSelectedtion("like-post")}
                    active={tab === "like-post"}
                  >
                    {t("USER LISTS")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small "
                    eventKey="CommentPost"
                    onClick={() => handleTabSelectedtion("comment-post")}
                    active={tab === "comment-post"}
                  >
                    {t("BOOKMARKS")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
          <Tab.Content className="">
            <Tab.Pane eventKey="LikePosts" active={tab === "like-post"}>
              <UserList setUserList={setUserList} setPostList={setPostList} />
            </Tab.Pane>
            <Tab.Pane eventKey="CommentPost" active={tab === "comment-post"}>
              <Bookmarks setPostList={setPostList} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Col>
      <Col lg="7" md="12" sm="12">
        <div className="shadow1 rounded border h-100 py-4 ">
          <div className="d-flex justify-content-between align-items-center px-4">
            <h4 className="text-bold">
              <span className="pointer" onClick={() => navigate(-1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span style={{ marginLeft: "10px" }}>{t("FOLLOWING")}</span>
            </h4>
            <h6 className="d-flex align-items-center">
              <span className="mx-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <g opacity="0.5" clip-path="url(#clip0_96_11791)">
                    <path
                      d="M9 14C7.89542 14 6.99998 14.8954 6.99998 16C6.99998 17.1046 7.89542 18 9 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9 14Z"
                      fill="black"
                    />
                    <path
                      d="M9 6.99997C7.89542 6.99997 6.99998 7.89541 6.99998 8.99998C6.99998 10.1046 7.89542 11 9 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 9 6.99997Z"
                      fill="black"
                    />
                    <path
                      d="M9 -3.21229e-05C7.89542 -3.22195e-05 6.99998 0.895405 6.99998 1.99998C6.99998 3.10456 7.89542 4 9 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 9 -3.21229e-05Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_96_11791">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(18 18) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </h6>
          </div>
          <div className="CardBody py-3 commonTabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="LikePosts"
              className="d-flex justify-content-between align-items-center"
            >
              <Nav variant="pills" className="">
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small"
                    eventKey="LikePosts"
                    onClick={() => handleTabSelectedtion("like-post")}
                    active={tab === "like-post"}
                  >
                    {t("USERS")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 py-3 px-2 position-relative text-small "
                    eventKey="CommentPost"
                    onClick={() => handleTabSelectedtion("comment-post")}
                    active={tab === "comment-post"}
                  >
                    {t("POSTS")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
          <Tab.Content className="collectionScrol">
            <Tab.Pane eventKey="LikePosts" active={tab === "like-post"}>
              <Users userList={userList} />
            </Tab.Pane>
            <Tab.Pane eventKey="CommentPost" active={tab === "comment-post"}>
              <Posts postList={postList} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Col>
    </Row>
  );
};

export default SubscriptionsComponent;
