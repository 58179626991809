import { useState, useEffect } from "react";
// import Button from "react-bootstrap/Button";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { announcementList, announcementRead } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextTransition, { presets } from "react-text-transition";

// images
import Glitter from "../../../../images/512.webp";
import Partypop from "../../../../images/513.webp";

function Announcements(props) {
  const { user } = useSelector((state) => state.login) ?? {};
  const announcementData = useSelector((s) => s.common.announcementList);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);

  const TEXTS = announcementData?.map((val) => val.message);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 8000);
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    if (user?._id) {
      dispatch(announcementList());
    }
  }, [user]);

  return (
    <>
      {user?._id && announcementData?.length > 0 && (
        <div className="text_bgwrap text-center">
          <TextTransition springConfig={presets.slow}>
            <img src={Glitter} alt="" className="img-fluid width20" />
            <img src={Partypop} alt="" className="img-fluid width20" />

            {TEXTS[index % TEXTS.length]}
            <img src={Partypop} alt="" className="img-fluid width20" />

            <img src={Glitter} alt="" className="img-fluid width20" />
          </TextTransition>
        </div>
      )}
    </>
  );
}

export default Announcements;
