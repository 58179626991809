import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePostStats } from "../../../redux/actions";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${value?.toFixed(2)}`;
        },
      },
    },
  },
};

const Statistics = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = props;
  const [labelData, setLabelData] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [activeGraph, setActiveGraph] = useState({
    Views: true,
    Tips: true,
    Likes: true,
    Comments: true,
    Purchases: true,
  });
  const { singlePostStats } = useSelector((s) => s.statistics);

  const graphData = {
    labels: labelData,
    datasets: dataSets,
  };
  const updateGraph = () => {
    const { viewers, tips, likes, comments, buyers } = singlePostStats;
    const allData = [
      ...viewers,
      ...tips,
      ...likes,
      ...comments,
      ...buyers,
    ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const uniqueDates = [
      ...new Set(allData.map((val) => moment(val.createdAt).format("ll"))),
    ];

    // Initialize the count or amount for each date
    const dateCounts = uniqueDates.reduce((acc, date) => {
      acc[date] = {
        viewCount: 0,
        tipAmount: 0,
        likeCount: 0,
        commentCount: 0,
        purchaseAmount: 0,
      };
      return acc;
    }, {});

    // Update counts or amounts for each date
    viewers?.forEach((item) => {
      const date = moment(item.createdAt).format("ll");
      dateCounts[date].viewCount += 1;
    });

    tips?.forEach((item) => {
      const date = moment(item.createdAt).format("ll");
      dateCounts[date].tipAmount += item.amount;
    });

    likes?.forEach((item) => {
      const date = moment(item.createdAt).format("ll");
      dateCounts[date].likeCount += 1;
    });

    comments?.forEach((item) => {
      const date = moment(item.createdAt).format("ll");
      dateCounts[date].commentCount += 1;
    });

    buyers?.forEach((item) => {
      const date = moment(item.createdAt).format("ll");
      dateCounts[date].purchaseAmount += item.amountPaid;
    });

    const labels = Object.keys(dateCounts);
    const viewData = labels.map((date) => dateCounts[date].viewCount);
    const tipData = labels.map((date) => dateCounts[date].tipAmount);
    const likeData = labels.map((date) => dateCounts[date].likeCount);
    const commentData = labels.map((date) => dateCounts[date].commentCount);
    const buyerData = labels.map((date) => dateCounts[date].purchaseAmount);

    // Set label data for the graph
    setLabelData(labels);

    const allDatasets = [
      {
        label: "Views",
        data: viewData,
        borderColor: "#3b8ca0", // Darkened version of "#5cc3f0"
        backgroundColor: "#5cc3f0",
      },
      {
        label: "Tips",
        data: tipData,
        borderColor: "#7c579f", // Darkened version of "#b093d6"
        backgroundColor: "#b093d6",
      },
      {
        label: "Likes",
        data: likeData,
        borderColor: "#c52169", // Darkened version of "#ef589c"
        backgroundColor: "#ef589c",
      },
      {
        label: "Comments",
        data: commentData,
        borderColor: "#d47939", // Darkened version of "#fdb669"
        backgroundColor: "#fdb669",
      },
      {
        label: "Purchases",
        data: buyerData,
        borderColor: "#7e8e4f", // Darkened version of "#9dc97c"
        backgroundColor: "#9dc97c",
      },
    ];

    const activeDatasets = allDatasets.filter(
      (dataset) => activeGraph[dataset.label]
    );

    setDataSets(activeDatasets);
  };

  useEffect(() => {
    if (singlePostStats && data) {
      updateGraph();
    }
  }, [singlePostStats, activeGraph]);

  useEffect(() => {
    if (data?._id) {
      dispatch(getSinglePostStats({ id: data._id }));
    }
  }, [data]);

  return (
    <Modal
      {...props}
      keyboard={false}
      centered
      size="md"
      className="single_subscribe"
    >
      <Modal.Body>
        <Modal.Header className="border-top position-relative">
          <div className="full_headeing">
            <h5>POST STATISTICS</h5>
            <p>Some statistics may be delayed or approximated</p>
          </div>
        </Modal.Header>
        <div className="full_stats px-3 py-2">
          <div className="grphh_sethere my-3 w-full">
            <Line options={options} data={graphData} />
          </div>

          <div className="full_stat_list">
            <ul>
              <li>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={activeGraph?.Purchases}
                      type="checkbox"
                      name="radio1"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActiveGraph({ ...activeGraph, Purchases: true });
                        } else {
                          setActiveGraph({ ...activeGraph, Purchases: false });
                        }
                      }}
                    />
                    <div className="full_statbox">
                      <p>Purchases</p>
                      <p>{`($${
                        singlePostStats?.totalPurchaseAmount
                          ? singlePostStats?.totalPurchaseAmount?.toFixed(2)
                          : "0.00"
                      })`}</p>
                    </div>
                  </Label>
                </FormGroup>
              </li>

              <li>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={activeGraph?.Views}
                      type="checkbox"
                      name="radio1"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActiveGraph({ ...activeGraph, Views: true });
                        } else {
                          setActiveGraph({ ...activeGraph, Views: false });
                        }
                      }}
                    />
                    <div className="full_statbox">
                      <p>Views</p>
                      <p>{singlePostStats?.viewCount}</p>
                    </div>
                  </Label>
                </FormGroup>
              </li>

              <li>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={activeGraph?.Likes}
                      type="checkbox"
                      name="radio1"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActiveGraph({ ...activeGraph, Likes: true });
                        } else {
                          setActiveGraph({ ...activeGraph, Likes: false });
                        }
                      }}
                    />
                    <div className="full_statbox">
                      <p>Likes</p>
                      <p>{singlePostStats?.likeCount}</p>
                    </div>
                  </Label>
                </FormGroup>
              </li>

              <li>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={activeGraph?.Comments}
                      type="checkbox"
                      name="radio1"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActiveGraph({ ...activeGraph, Comments: true });
                        } else {
                          setActiveGraph({ ...activeGraph, Comments: false });
                        }
                      }}
                    />
                    <div className="full_statbox">
                      <p>Comments</p>
                      <p>{singlePostStats?.commentCount}</p>
                    </div>
                  </Label>
                </FormGroup>
              </li>

              <li>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={activeGraph?.Tips}
                      type="checkbox"
                      name="radio1"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActiveGraph({ ...activeGraph, Tips: true });
                        } else {
                          setActiveGraph({ ...activeGraph, Tips: false });
                        }
                      }}
                    />
                    <div className="full_statbox">
                      <p>Tips</p>
                      <p>
                        {" "}
                        {`($${
                          singlePostStats?.totalTipAmount
                            ? singlePostStats?.totalTipAmount?.toFixed(2)
                            : "0.00"
                        })`}
                      </p>
                    </div>
                  </Label>
                </FormGroup>
              </li>

              <li>
                <div className="full_statbox ms-4">
                  <p>
                    Duration / <span>Average</span>
                  </p>
                  <p>
                    00:00:02 / <span>00:00:02 </span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top position-relative text-end">
        <a
          href="javascript:void(0)"
          onClick={props.onhide}
          className="close_text"
        >
          {t("Close")}
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default Statistics;
