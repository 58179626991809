// This is for the confirmation of deleting the list ;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addMoneyToWallet } from "../../../redux/states/card/thunk";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { IoMdWallet } from "react-icons/io";
import { toast } from "react-toastify";
import { getProfile } from "../../../redux/actions";

const AddMoneyToWalletModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [payData, setPayData] = useState({
    paymentSourceRef: "",
    paymentType: "stripe",
    amount: "",
  });

  const { cardList, buttonLoader } = useSelector((s) => s.card);

  const refreshForm = () => {
    setPayData({
      amount: "",
      paymentType: "stripe",
      paymentSourceRef: "",
    });
  };

  const addFund = () => {
    dispatch(addMoneyToWallet(payData))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        props.onHide();
        refreshForm();
        dispatch(getProfile());
      })
      .catch((err) => {
        toast.error(err?.message);
        props.onHide();
      });
  };

  const handleCardSelection = (card, paymentType) => {
    setPayData((rest) => ({
      ...rest,
      paymentSourceRef: card?._id,
    }));
  };

  const close = () => {
    refreshForm();
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pin_modal"
    >
      <Modal.Body>
        <div className="modal_header d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <h6>{t("ADD MONEY TO YOUR WALLET")} </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 3v2H3V3h9Zm4 16v2H3v-2h13Zm6-8v2H3v-2h19Z"
              />
            </svg>
          </span>
        </div>

        <div className="middle_content px-3 py-2">
          <div>
            <FormGroup>
              <label className="text-small my-2">{t("Enter Amount")}</label>
              <Input
                type="number"
                name="radio1"
                className="text-small mt-1"
                placeholder="$ Topup Amount"
                value={payData?.amount}
                onChange={(e) =>
                  setPayData((rest) => ({ ...rest, amount: e.target.value }))
                }
              />{" "}
            </FormGroup>

            {cardList?.map((itm, ind) => {
              return (
                <div className="payementCrdd shadow2" key={ind}>
                  <input
                    type="radio"
                    id={itm?.token}
                    name="payment"
                    onClick={() => handleCardSelection(itm, "card")}
                  />
                  <label
                    htmlFor={itm?.token}
                    className="d-flex align-items-center"
                  >
                    <img src={itm?.logo} alt="" className="mx-3" />
                    <p className="mb-0 text-small d-flex align-items-center">
                      {" "}
                      <span style={{ fontSize: "30px" }}>
                        {" "}
                        ···· ···· ····
                      </span>{" "}
                      <p className="mb-0 mx-2">{itm?.lastd}</p>
                    </p>
                  </label>
                </div>
              );
            })}

            <div className="d-flex mt-2 justify-content-center">
              {buttonLoader ? (
                <button className="updateNotificationBtn">
                  <SmallLoader />
                </button>
              ) : (
                <button
                  className={`updateNotificationBtn ${
                    (payData?.amount === "" || payData?.paymentMethod === "") &&
                    "btn-disable "
                  }updateNotificationBtn`}
                  disabled={
                    payData?.amount === "" || payData?.paymentMethod === ""
                  }
                  onClick={addFund}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="modal_footer d-flex align-items-center justify-content-end px-3 py-2 border-top">
          <Button onClick={close}>{t("Close")}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddMoneyToWalletModal;
