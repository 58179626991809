import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoMdCard, IoMdWallet } from "react-icons/io";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import SmallLoader from "../ButtonLoader/SmallLoader";
import { Link } from "react-router-dom";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import Addpaymentmod from "../Modal/Addpaymentmod";
import { subscribeArtist } from "../../../redux/actions";

// const stripePromise = loadStripe(
//   "pk_test_51O2gNNLI2EI2TtmrOCtzqsiaFTbzEA6Pn3JlN1vhFWTm9mQU6zIWgjuxXbWTFBHOMf0fGN5EsBcMysihhxwztlcm00nl8e4dlh"
// );
const SubscriptionPayment = (props) => {
  const radioRef = useRef(null);
  const { t } = useTranslation();
  const { onHide, data, onSubscribeSuccess, followerId, handleSubscribe } =
    props;
  const { cardList, buttonLoader } = useSelector((s) => s.card);
  const dispatch = useDispatch();
  const [payData, setPayData] = useState({
    paymentMethod: "card",
    paymentSourceRefNo: "",
    paySourceId: "",
    lastd: "",
  });
  const [selectedPlan, setSelectedPlan] = useState({});
  const [error, setError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const [addpaymentmod, setaddpaymentmod] = useState(false);
  const refreshForm = () => {
    setPayData({
      paymentMethod: "",
      paymentSourceRefNo: "",
      paySourceId: "",
      lastd: "",
    });
  };
  useEffect(() => {
    setSelectedPlan({
      duration: 1,
      price: data?.subscribePrice,
      discount: 0,
    });
  }, [props?.show]);

  const payForSubscription = () => {
    if (
      data?.subscriptionBundles?.length > 0 &&
      !!selectedPlan?.price === false
    ) {
      setError(true);
      toast.warning(t("Please select any subscription plan"));
    } else {
      if (payData?.paymentMethod === "") {
        toast.warning(t("Please select payment method"));
      } else {
        const plan =
          data?.subscriptionBundles?.length > 0
            ? selectedPlan
            : {
                duration: 1,
                price: data?.subscribePrice,
                discount: 0,
              };
        const payload = {
          ...plan,
          ...payData,
          followerId,
        };
        dispatch(subscribeArtist(payload))
          .unwrap()
          .then((res) => {
            toast.success(res?.message);
            onSubscribeSuccess();
            refreshForm();
            onHide();
          })
          .catch((err) => {
            toast.error(err?.message);
            onHide();
          });
      }
    }
  };

  const handleCardSelection = (card, paymentType, index) => {
    setSelectedIndex(index);
    if (paymentType === "card") {
      setPayData((rest) => ({
        ...rest,
        paymentSourceRefNo: card?._id,
        paySourceId: card?.paySourceId,
        lastd: card?.cardLast4,
        paymentMethod: "card",
      }));
    } else if (paymentType === "wallet") {
      setPayData((rest) => ({ ...rest, paymentMethod: "wallet" }));
    }
  };

  if (addpaymentmod) {
    return (
      <>
        {/* {stripePromise && (
          <Elements stripe={stripePromise}> */}
        <Addpaymentmod
          show={addpaymentmod}
          onhide={() => setaddpaymentmod(false)}
          hideBack
          onSuccess={() => {}}
        />
        {/* </Elements>
        )} */}
      </>
    );
  }

  const cardLogo = (item) => {
    if (item === "VISA") {
      return "https://splash-ark.s3.us-east-2.amazonaws.com/16608181755941614744039133visacard.png";
    } else if (item === "MASTERCARD") {
      return "https://fansroom.s3.ap-south-1.amazonaws.com/1697708129991";
    } else if (item === "DISCOVER") {
      return "/images/discover.png";
    } else {
      return "/images/amex.png";
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <h4 className="fw-sbold m-0 text-normal">
            {t("Confirm Subscription")}
          </h4>
          <div className="pt-4">
            {data?.subscriptionBundles?.length > 0 && (
              <div style={{ zIndex: "9", paddingBottom: "16px" }}>
                <label className="form-label">
                  {t("Select Subscription Plan")}
                </label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    const filteredPlan = data?.subscriptionBundles.find(
                      (i) => i.price === Number(e.target.value)
                    );
                    if (!!filteredPlan === true) {
                      setSelectedPlan({
                        duration: filteredPlan?.duration,
                        price: filteredPlan?.price,
                        discount: filteredPlan?.discount,
                      });
                    } else {
                      setSelectedPlan({
                        duration: 1,
                        price: data?.subscribePrice,
                        discount: 0,
                      });
                    }
                  }}
                >
                  <option
                    value={parseFloat(data?.subscribePrice)}
                  >{`$ ${data?.subscribePrice} for 1 month`}</option>
                  {data?.subscriptionBundles?.map((val) => (
                    <option
                      key={val?.price}
                      value={parseFloat(val?.price)}
                    >{`$ ${val?.price} for ${val?.duration} months`}</option>
                  ))}
                </select>

                {error && (
                  <span className="text-danger">
                    Please select the subscription plan
                  </span>
                )}
              </div>
            )}

            <div
              style={{ maxHeight: "300px", overflowY: "auto" }}
              className="mb-3 mt-2"
            >
              {cardList?.map((itm, ind) => {
                return (
                  <div
                    className="payementCrdd shadow2"
                    key={ind}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCardSelection(itm, "card", ind);
                    }}
                  >
                    <input
                      ref={radioRef}
                      type="radio"
                      id={itm?.token}
                      name="payment"
                      checked={selectedIndex === ind}
                      onClick={() => handleCardSelection(itm, "card", ind)}
                    />
                    <label
                      htmlFor={itm?.token}
                      className="d-flex align-items-center"
                    >
                      <img
                        src={cardLogo(itm?.cardType)}
                        alt=""
                        className="mx-3"
                      />
                      <p className="mb-0 text-small d-flex align-items-center">
                        {" "}
                        <span style={{ fontSize: "30px" }}>
                          {" "}
                          ···· ···· ····
                        </span>{" "}
                        <p className="mb-0 mx-2">{itm?.cardLast4}</p>
                      </p>
                    </label>
                  </div>
                );
              })}
            </div>

            {/* <div className="payementCrdd shadow2 py-2 pointer">
              <input
                type="radio"
                id={"wallet"}
                name="payment"
                onClick={() => handleCardSelection({}, "wallet")}
              />
              <label htmlFor="wallet" className="d-flex align-items-center ">
                <IoMdWallet size={23} color="#5e5c5c" className="mx-4" />
                <h6 className="text-small mb-0 mx-2">{t("Pay from wallet")}</h6>
              </label>
            </div> */}
            <div className="payementCrdd shadow2 py-2 pointer d-flex justify-content-center">
              <label
                htmlFor="wallet"
                className="d-flex align-items-center "
                onClick={() => setaddpaymentmod(true)}
              >
                <IoMdCard size={23} color="#5e5c5c" className="" />
                <h6 className="text-small mb-0 mx-2">{t("Add Card")}</h6>
              </label>
            </div>
            <h4
              className="privacy_links text-black text-small"
              style={{ fontSize: 12 }}
            >
              {t("By making payment you agree to our")}{" "}
              <Link to="/terms" className="text-dark">
                {t("Terms of Service")}
              </Link>{" "}
              {t("and")}
              <Link to="/privacy" className="text-dark">
                {" "}
                {t("Privacy Policy")}
              </Link>
              .
            </h4>
            <div className="modal_buy_btn d-flex justify-content-end mt-4">
              <Button
                className="mod_cancelbtn blackBtn border-0"
                style={{ marginRight: "16px" }}
                onClick={() => {
                  onHide();
                  refreshForm();
                  setSelectedIndex(null);
                  setSelectedPlan({
                    duration: null,
                    price: null,
                    discount: null,
                  });
                }}
              >
                {t("Cancel")}
              </Button>
              {buttonLoader ? (
                <Button className="mod_confirmbtn blackBtn border-0">
                  <SmallLoader />
                </Button>
              ) : (
                <Button
                  className="mod_confirmbtn blackBtn border-0"
                  onClick={payForSubscription}
                >
                  {t("Pay")}{" "}
                  {data?.subscriptionBundles?.length > 0
                    ? selectedPlan?.price
                    : data?.subscribePrice}{" "}
                  $
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionPayment;
