import { t } from "i18next";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Nav,
  Accordion,
  Form,
  FormGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// components
import Vaultdefault from "./Vaultdefault";
import Vaultdata from "./Vaultdata";
import Currentrefferal from "./Currentrefferal";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import WithdrawlMoneyModal from "../../common/Modal/WithdrawlMoneyModal";
import { bankAccountList } from "../../../redux/states/card/thunk";

const StatementComponent = (props) => {
  const { defaultTab } = props;
  const { t } = useTranslation();
  const [valuetab, setValuetab] = useState(defaultTab || 1);
  const { transaction } = useSelector((s) => s.statement);
  const [showWithdrawlModal, setShowWithdrawlModal] = useState(false);
  const { walletCredit } = useSelector((s) => s.setting?.user);
  const dispatch = useDispatch();
  const handleChange = (newValue) => {
    setValuetab(newValue);
  };

  useEffect(() => {
    dispatch(bankAccountList());
  }, []);
  return (
    <Row className="h-100 justify-content-between ">
      <Col lg="4" md="12" sm="12" className="padding_tabet">
        <div className="shadow1 rounded border h-100  border_hide">
          <div className="border-bottom pb-3">
            <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
              <Link to="/home">
                <div className="notification_left_heading d-flex align-items-center">
                  <span className="me-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                    >
                      <path
                        d="M1 7L7 1M1 7L7 13M1 7L15 7"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <h5>{t("Statements")}</h5>
                </div>
              </Link>
            </div>

            {valuetab == 4 ? (
              <Currentrefferal />
            ) : (
              <>
                <Accordion defaultActiveKey="0" className="px-3 mt-3 ">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="all_times d-flex justify-content-between  w-100">
                        <h4>{t("Current Balance")}</h4>
                        <h4>$ {walletCredit || 0}</h4>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="current_balnce_card">
                        <div className="pending_balnce d-flex align-items-center justify-content-between border-bottom py-2">
                          <h6>{t("PENDING BALANCE")}</h6>
                          <h6>$0.00</h6>
                        </div>

                        <Form>
                          <FormGroup></FormGroup>
                          <p className="mininum_info text-center">
                            {t("Minimum withdrawal amount is $20")}
                          </p>
                          <div className="text-end mt-3">
                            <Button
                              className="request_widraw"
                              onClick={() => setShowWithdrawlModal(true)}
                            >
                              {t("Request Withdrawal")}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* <div className="currnt_wallelt d-flex align-items-center justify-content-between mx-3 mt-3">
                  <h6>CURRENT BALANCE</h6>
                  <h5>
                    ${" "}
                    {transaction?.currentNetBalance &&
                      transaction?.currentNetBalance.toFixed(2)}
                  </h5>
                </div> */}
              </>
            )}
          </div>

          <div className="CardBody pb-3 pt-2 commonTabs colleciton_tabs">
            <Vaultdefault
              handleChange={handleChange}
              setValuetab={setValuetab}
              valuetab={valuetab}
            />
          </div>
        </div>
      </Col>
      <Col lg={8} md={12} sm={12} className="padding_tabet">
        <div className="card_boader h-100 min-vh-100 border-0">
          <Vaultdata isShowData={valuetab} />
        </div>
      </Col>
      <WithdrawlMoneyModal
        show={showWithdrawlModal}
        onHide={() => setShowWithdrawlModal(false)}
      />
    </Row>
  );
};

export default StatementComponent;
