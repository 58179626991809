import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Card2 from "../../../images/card2.png";
import { CiWallet } from "react-icons/ci";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { Tab, Nav, Button } from "react-bootstrap";
import YourCards from "./YourCards";
import Payments from "./Payments";
import { useDispatch, useSelector } from "react-redux";
import { transactionList } from "../../../redux/states/card/thunk";
import LatestTransactions from "../../common/LatestTransactions/LatestTransactions";
import {BsFillPlusSquareFill} from "react-icons/bs"
import AddMoneyToWalletModal from "../../common/Modal/AddMoneyToWalletModal";

import Visscards from "../../common/VisaCards";

const PaymentsComponent = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [paymentTab, setPaymentTab] = useState("yourCards");
  const { showAddCardModal, setShowAddCardModal } = props;
  const dispatch = useDispatch();
  const walletCredit = useSelector((s) => s.setting?.user)?.walletCredit
  const [showAddMoneyWalletModal , setShowMoneyWallet] = useState(false)

  const handleTabSelectedtion = (tab) => {
    setPaymentTab(tab);
  };

  const getTransactionList = () =>{
    dispatch(transactionList())
  }

  useEffect(() =>{
    getTransactionList()
  },[])
  
  return (
    <Row className="  h-100 align-items-start justify-content-between ">
      <Col lg="8" md="12" sm="12">
        <div className="shadow1 rounded border h-100 px-3 py-4 ">
          <div className="d-flex justify-content-between align-items-center px-2">
            <h4 className="text-bold">
              <span onClick={() => navigate(-1)} className="pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span style={{ marginLeft: "7px" }}>{t("PAYMENTS CARD")}</span>
            </h4>
            <h6 onClick={() => navigate("/add-card")} className="pointer">
              <BsCreditCard2BackFill size={24} color="#101010" />
            </h6>
          </div>

          {/* payment section started; */}
          <div className="CardBody pb-3 pt-2 commonTabs ">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="Users"
              className="d-flex justify-content-between align-items-center"
            >
              <Nav variant="pills" className="">
                <Nav.Item className="">
                  <Nav.Link
                    className={`bg-transparent border-0 position-relative text-sm ${
                      paymentTab == "yourCards" && "active"
                    }`}
                    eventKey="yourCards"
                    onClick={() => handleTabSelectedtion("yourCards")}
                    active={paymentTab === "yourCards"}
                  >
                    {t("YOUR CARDS")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-sm "
                    eventKey="payments"
                    onClick={() => handleTabSelectedtion("payments")}
                    active={paymentTab === "payments"}
                  >
                    {t("PAYMENTS")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>

            <Tab.Content className="lg-p-4 p-2">
              <Tab.Pane
                eventKey="yourCards"
                active={paymentTab === "yourCards"}
              >
                <div className="NewsFeed">
                  <div className="d-flex justify-content-between align-items-center">
                    <YourCards />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="payments" active={paymentTab === "payments"}>
                <Payments />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Col>
      <Col lg="4" md="12" sm="12">
        <div className="shadow1 rounded border py-4">
          <div className="px-4 pb-3 cardWlt">
            <h5 className="text-bold mb-0">${walletCredit}</h5>
            <h6 className="text-small">{t("Wallet Credits")}</h6>
          </div>
          <div className="py-3 px-3">
            <h6 className="light-text mb-1 mx-2 pointer" onClick={() => setShowMoneyWallet(true)}>
              {t("ADD FUNDS TO YOUR WALLET")}
              <BsFillPlusSquareFill size={32} className="mx-2 pointer"/>
            </h6>
           
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="addMoreBtn "
                style={{ height: "41px" }}
                onClick={() => setShowAddCardModal(true)}
              >
                {t("Add a Payment Card")}
              </button>
            </div>

            
            <div className="pt-4 d-flex align-items-center justify-content-between">
              <h6 className="text-xtr-sm mb-0">
                {t("Make wallet primary method for rebills")}
              </h6>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                className="custom-switch"
              />
            </div>
            

            <Visscards/>

            
          </div>
        </div>
       <LatestTransactions/>
      </Col>
      <AddMoneyToWalletModal show={showAddMoneyWalletModal} onHide={() => setShowMoneyWallet(false)}/>
    </Row>
  );
};

export default PaymentsComponent;
