import React from "react";
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Postlabels = (props) => {
  const {
    setPostLabelActiveBox,
    archiveItemCount,
    privateArchiveItemCount,
    postLabelActiveBox,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="px-3 py-1">
      <div className="d-flex justify-content-between align-items-center px-2">
        <h5 className="text-custom">{t("RECENT")}</h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g opacity="0.5">
              <path
                d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                fill="black"
              />
            </g>
          </svg>
        </h5>
      </div>
      <div
        className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer ${
          postLabelActiveBox == "privateArchive" && "activeBox"
        }`}
        onClick={() => setPostLabelActiveBox("privateArchive")}
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">{t("Private Archive")}</h6>
          <h6 className="mb-0">
            <span>
              {privateArchiveItemCount} {t("posts")}{" "}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox"></div>
      </div>
      <div
        className={`my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer ${
          postLabelActiveBox == "archive" && "activeBox"
        }`}
        onClick={() => setPostLabelActiveBox("archive")}
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">{t("Archive")}</h6>
          <h6 className="mb-0">
            <span>
              {archiveItemCount} {t("posts")}{" "}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox"></div>
      </div>
    </div>
  );
};

export default Postlabels;
