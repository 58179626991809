import React from "react";
import Settings from "./Settings";
import { Link, useNavigate } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const Chats = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Settings>
      <section className="custom_order px-0 pb-3">
        <Form>
          <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
            <Link to="/home">
              <div className="notification_left_heading d-flex align-items-center">
                <h5>{t("Chats")}</h5>
              </div>
            </Link>
          </div>

          <div className="privacy_safety mt-4 border-top border-bottom border-3">
            {/* <div className="Reward_part">
              <div className="month_reffer py-3 d-flex align-items-center justify-content-between px-4 py-3">
                <div className="free_content">
                  <p>
                    Don't accept messages without a Tip from free followers whom
                    you don't follow back
                  </p>
                </div>
                <div className="switches">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div> */}

            <div className="Reward_part">
              <p className="px-4 py-3 border-top m-0 wlc_msg">
                <strong>{t("Welcome message")}</strong>
              </p>
              <div className="month_reffer border-top border-1 py-3 d-flex align-items-center justify-content-between px-4 py-3">
                <div className="free_content">
                  <p>{t("Send a welcome message to new fans")}</p>
                </div>
                <div className="switches">
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => navigate("/messages")}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="month_reffer border-bottom border-3 py-2 d-flex align-items-center justify-content-between 
          px-4"
          >
            <div className="free_content">
              <h4>{t("Filters")}</h4>
            </div>
            <div className="arrow">
              <img
                src="/images/profilearrow.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div
            className="month_reffer border-bottom border-1 py-2 d-flex align-items-center justify-content-between 
            px-4"
          >
            <div className="free_content">
              <h4>{t("Restricted")}</h4>
            </div>
            <div className="arrow">
              <img
                src="/images/profilearrow.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </Form>
      </section>
    </Settings>
  );
};

export default Chats;
