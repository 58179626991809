import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../../endpoints";
import { REACT_APP_API_BASE_URL, REACT_APP_API_VERSION } from "../../../config";
import parse from "html-react-parser";

export default function CreatorAgreement() {
  const API_URL = REACT_APP_API_BASE_URL;
  const API_VERSION = REACT_APP_API_VERSION;
  const baseURL = API_URL + API_VERSION;

  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const getContentData = () => {
    setLoading(true);
    const agreementId = "657c2e624f00293e6030be69";
    fetch(baseURL + endpoints.common.getContent + agreementId, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        setData({ ...result.data, slug });
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getContentData();
  }, []);

  return (
    <div className=" px-3 py-2 my-2">
      {loading ? "Loading...Please wait" : parse(data?.content || "")}
    </div>
  );
}
