import { useMediaQuery } from "react-responsive";

export default function useResponsive() {
  const isMobileView = useMediaQuery({
    query: "(max-width: 991.98px)",
  });
  const isDesktopView = useMediaQuery({
    query: "(min-width: 992px)",
  });

  return { isMobileView, isDesktopView };
}
