import { createSlice } from "@reduxjs/toolkit";
import {
  addCardData,
  addMoneyToWallet,
  addW9Form,
  bankAccountList,
  connectStripe,
  deleteCard,
  disconnectStripe,
  getCardList,
  renewSubscription,
  sendPostTipAmount,
  transactionList,
  unlockPost,
  withDrawMoneyFromWallet,
} from "./thunk";
import { status } from "../../constants";

const initialState = {
  status: status.IDLE,
  buttonLoader: false,
  isCardAdded: false,
  cardList: [],
  transactionListt: [],
  accountList: [],
};

const slice = createSlice({
  name: "addcarddata",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCardData.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addCardData.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(addCardData.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(getCardList.pending, (state, action) => {
      state.status = status.LOADING;
      // state.buttonLoader = true;
    });
    builder.addCase(getCardList.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      // state.buttonLoader = false;
      state.cardList = action.payload?.data;
      state.isCardAdded = action.payload?.data?.length > 0;
    });
    builder.addCase(getCardList.rejected, (state, action) => {
      state.status = status.FAILED;
      // state.buttonLoader = false;
      state.cardList = initialState?.cardList;
    });
    builder.addCase(sendPostTipAmount.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(sendPostTipAmount.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(sendPostTipAmount.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(connectStripe.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(connectStripe.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(connectStripe.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(deleteCard.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(deleteCard.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(deleteCard.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(transactionList.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(transactionList.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
      state.transactionListt = action.payload?.data;
    });
    builder.addCase(transactionList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
      state.transactionListt = initialState.transactionListt;
    });
    builder.addCase(unlockPost.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(unlockPost.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(unlockPost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addW9Form.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addW9Form.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(addW9Form.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addMoneyToWallet.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addMoneyToWallet.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(addMoneyToWallet.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(disconnectStripe.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(disconnectStripe.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(disconnectStripe.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(withDrawMoneyFromWallet.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(withDrawMoneyFromWallet.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(withDrawMoneyFromWallet.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(bankAccountList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(bankAccountList.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.loader = false;
      state.accountList = action.payload?.data;
    });
    builder.addCase(bankAccountList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.accountList = initialState.accountList;
    });
    builder.addCase(renewSubscription.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(renewSubscription.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(renewSubscription.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
  },
});

export default slice.reducer;
