import React from "react";
import MainLayout from "../../../layout/MainLayout";

const ReleaseForm = () => {
  const imageUrl =
    "https://slushr.s3.us-east-2.amazonaws.com/1693913001583test.jpg";

  const handleImageClick = () => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloaded_image.png");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <>
      <MainLayout>
        <div>
          <button onClick={handleImageClick}> download</button>
          {/* <img
            src={imageUrl}
            // alt={imageAlt}
            
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default ReleaseForm;
