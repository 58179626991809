import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import {
  getEarningStatistics,
  getTransaction,
  getLastOneYearTransaction,
} from "./thunk";

const initialState = {
  status: status.IDLE,
  loader: false,
  transaction: [],
  earningStatistics: [],
  lastOneYearTransactions: [],
};

const slice = createSlice({
  name: "transaction",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransaction.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.loader = false;
      state.transaction = action.payload?.data;
    });
    builder.addCase(getTransaction.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.transaction = initialState.transaction;
    });
    builder.addCase(getEarningStatistics.pending, (state, action) => {
      state.status = status.PENDING;
      state.loader = true;
    });
    builder.addCase(getEarningStatistics.fulfilled, (state, action) => {
      state.status = status.FULFILLED;
      state.loader = false;
      state.earningStatistics = action.payload?.data;
    });
    builder.addCase(getEarningStatistics.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.earningStatistics = initialState.earningStatistics;
    });

    builder.addCase(getLastOneYearTransaction.pending, (state, action) => {
      state.status = status.PENDING;
      state.loader = true;
    });
    builder.addCase(getLastOneYearTransaction.fulfilled, (state, action) => {
      state.status = status.FULFILLED;
      state.loader = false;
      state.lastOneYearTransactions = action.payload?.data;
    });
    builder.addCase(getLastOneYearTransaction.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.lastOneYearTransactions = initialState.lastOneYearTransactions;
    });
  },
});

export default slice.reducer;
