import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { getProfile, updateEntertainerSetting } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../../../components/common/ButtonLoader/ButtonLoader";

const FansFollowing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { buttonLoader, user } = useSelector((s) => s.setting);
  const [isUnfollowSubscribe, setIsUnfollowSubscribe] = useState(false);
  const [isMonthRefferal, setIsMonthRefferal] = useState(false);
  const [isTopFansOne, setIsTopFansOne] = useState(false);
  const [isTopFansTwo, setIsTopFansTwo] = useState(false);

  const handleChange = (dta) => {
    const data = {
      [dta]: !dta,
    };
    dispatch(updateEntertainerSetting(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getUserData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (user) {
      setIsUnfollowSubscribe(user?.isUnfollowSubscribe);
      setIsMonthRefferal(user?.isMonthRefferal);
      setIsTopFansOne(user?.isTopFansOne);
      setIsTopFansTwo(user?.isTopFansTwo);
    }
  }, [user]);

  return (
    <Settings>
      <section className="custom_order px-0 pb-3">
        <Form>
          <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
            <Link to="/home">
              <div className="notification_left_heading d-flex align-items-center">
                <h5>{t("Fans and Following")}</h5>
              </div>
            </Link>
          </div>

          <div className="privacy_safety mt-4 border-top border-bottom border-3">
            {/* <div className="profile_toggle border-bottom  d-flex justify-content-between align-items-center px-4 py-2">
              <p>Show activity status</p>
              <div className="switches">
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div> */}

            <div className="profile_toggle d-flex justify-content-between align-items-center px-4 py-2">
              <p>{t("Show subscription offers")}</p>
              <div className="switches">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={isUnfollowSubscribe}
                    onChange={() => handleChange("isUnfollowSubscribe")}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="Reward_part">
            <h6 className="px-4 py-3">
              <strong>{t("Reward for subscriber referrals")}</strong>
            </h6>

            <div className="month_reffer border-top border-bottom py-3 d-flex align-items-center justify-content-between px-4 py-3">
              <div className="free_content">
                <h4>{t("1 Free month for referrer")}</h4>
                <p>
                  {t(
                    "Your Fans will be able to share your profile link and for every new subscriber they refer to you, your Fans will receive an additional 1 month subscription to your profile."
                  )}
                </p>
              </div>
              <div className="switches">
                <label class="switch">
                  <input
                    type="checkbox"
                    className=""
                    checked={isMonthRefferal}
                    onChange={() => handleChange("isMonthRefferal")}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div
              className="month_reffer border-bottom border-3 py-2 d-flex align-items-center justify-content-between 
          px-4"
            >
              <div className="free_content">
                <h4>{t("Following Price")}</h4>
                <p>{t("Free")}</p>
              </div>
              <div className="arrow">
                <img
                  src="/images/profilearrow.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>

            <div
              className="month_reffer border-bottom  py-2 d-flex align-items-center justify-content-between 
          px-4"
            >
              <div className="free_content">
                <h4>{t("Notify Top Fans")}</h4>
                <p>
                  {" "}
                  {t(
                    "Notify your top fans that they have made it to the Top 1 or 5% every month."
                  )}
                </p>
              </div>
              <div className="arrow">
                <img
                  src="/images/profilearrow.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="privacy_safety border-top border-bottom">
              <div className="profile_toggle border-bottom border-1 d-flex justify-content-between align-items-center px-4 py-2">
                <p>{t("Top 1%")}</p>
                <div className="switches">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={isTopFansOne}
                      onChange={() => handleChange("isTopFansOne")}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="profile_toggle d-flex justify-content-between align-items-center px-4 py-2">
                <p>{t("Top 5%")}</p>
                <div className="switches">
                  <label class="switch">
                    <input
                      type="checkbox"
                      className="form-check"
                      checked={isTopFansTwo}
                      onChange={() => handleChange("isTopFansTwo")}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Settings>
  );
};

export default FansFollowing;
