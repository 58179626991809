import React, { useEffect } from "react";
import Posts from "./Posts";
import BookmarkPosts from "./BookMarkPost";
import { allBookMarkedPost } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const AllBookmarks = (props) => {
  const { bookmarkList } = useSelector((s) => s.collection);
  const { user, buttonLoader } = useSelector((s) => s.setting);

  const dispatch = useDispatch();

  const getAllBookmarkedPost = () => {
    const data = {
      limit: 100,
      page: 1,
      userId: user?._id,
    };
    dispatch(allBookMarkedPost(data));
  };

  useEffect(() => {
    getAllBookmarkedPost();
  }, []);
  return (
    <div>
      <BookmarkPosts
        postList={bookmarkList || []}
        getAllBookmarkedPost={getAllBookmarkedPost}
      />
    </div>
  );
};

export default AllBookmarks;
