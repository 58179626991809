import React from "react";
import MainLayout from "../../../layout/MainLayout";
import CollectionComponent from "../../../components/SideTabs/Collections";
import { useSelector } from "react-redux";
import EntertainerCollectionComponent from "../../../components/SideTabs/EntertainerCollection";

const Collections = () => {
  const { role } = useSelector((s) => s.login);
  return (
    <MainLayout>
      {role === "ARTIST" && <EntertainerCollectionComponent />}
      {role === "USER" && <CollectionComponent />}
    </MainLayout>
  );
};

export default Collections;
