import React from "react";
import { useTranslation } from "react-i18next";
import { IoWarningOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../ButtonLoader/SmallLoader";
import { verifyEmail } from "../../../redux/actions";
import { toast } from "react-toastify";
import { FcInfo } from "react-icons/fc";

const VerifyEmail = ({ showPrivacy }) => {
  const { user } = useSelector((s) => s.setting);
  const { t } = useTranslation();
  const { buttonLoader } = useSelector((s) => s.common);
  const dispatch = useDispatch();

  const handleEmailVerify = () => {
    dispatch(verifyEmail())
      .unwrap()
      .then((res) => {
        toast.success(t("Link has been sent to your email to verify!"));
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <div className="w-100">
      {user?.isEmailVerified === false && (
        <>
          <div className=" border rounded-1 py-2 px-2 my-2 ">
            <div className="d-flex align-items-center">
              <IoWarningOutline color="#ed332d" size={28} className="mx-1" />
              <p className="text-small mx-2 mb-0">
                {t("Please verify your e-mail address")}
              </p>
            </div>

            <div className="d-flex justify-content-end my-2">
              {buttonLoader ? (
                <button className="sendMsg px-4" style={{ width: "180px" }}>
                  <SmallLoader color={"text-light"} />
                </button>
              ) : (
                <button
                  className="sendMsg px-4"
                  style={{ width: "180px" }}
                  onClick={() => handleEmailVerify()}
                >
                  {t("Verify Email")}
                </button>
              )}
            </div>
          </div>
          {showPrivacy && (
            <div className=" border rounded-1 py-2 px-2 my-2">
              <div className="d-flex align-items-center my-2">
                <FcInfo size={23} className="mx-1" />
                <p className="text-small mx-2 mb-0">
                  {t("We've updated our privacy policy which can be viewed")}{" "}
                  <a href="/privacy">{t("here")}</a>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="mx-3"></p>
                <p className="text-small mx-2 mb-0 mb-2">
                  {t(
                    "At fansroom we respect our privacy . We are commited to protecting your personal data and being transparent about how it is used"
                  )}
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
