import React from "react";

const SmallLoader = ({ color, cstmClass }) => {
  return (
    <div
      className={`${color ? color : "text-light"} ${cstmClass} spinner-border`}
      text-light
      role="status"
      style={{ height: "20px", width: "20px" }}
    >
      <span class="sr-only"></span>
    </div>
  );
};

export default SmallLoader;
