import { createSlice } from "@reduxjs/toolkit";
import { becameCreator, getVerificationStatus } from "./thunk";
import { status } from "../../constants";

const initialState = {
  status: status.IDLE,
  loader: false,
  buttonLoader: false,
  verificationData: {},
  reVerify: false,
};

const slice = createSlice({
  name: "becomecreator",
  initialState: { ...initialState },
  reducers: {
    updateReVerify: (state, payload) => {
      state.reVerify = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(becameCreator.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(becameCreator.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(becameCreator.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(getVerificationStatus.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getVerificationStatus.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.verificationData = action.payload;
    });
    builder.addCase(getVerificationStatus.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.verificationData = initialState.verificationData;
    });
  },
});

export default slice.reducer;

export const { updateReVerify } = slice.actions;
