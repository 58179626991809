export const endpoints = {
  auth: {
    login: "/user/login",
    signup: "/user/register",
    forgotPassword: "/user/forgotpassword",
    verifyOtp: "/user/verifyOtp",
    resetPassword: "/user/resetpassword",
    changePassword: "/user/changepassword",
    socialLogin: "/user/socialLogin",
    getGoogleProfile:
      "https://cors.bridged.cc/https://oauth2.googleapis.com/token",
    getTwitterProfile:
      "https://cors.bridged.cc/https://api.twitter.com/1.1/account/verify_credentials.json",
  },
  post: {
    postDetails: "/post/detail/",
    viewPost: "/post/addViewer",
  },
  common: {
    uploadFile: "/adminSetting/uploadFile",
    helpSupport: "",
    legalInquiry: "/admin/addLegalInquiry",
    featuredPostList: "/post/getLatestPostFilters",
    privacyPolicy: "/admin/privacypolicy",
    addCard: "/payment/addpos",
    termsCondition: "/admin/termsandconditions",
    cardList: "/payment/poslist",
    sendTip: "/post/add/tip",
    sendTipToProfile: "/post/add/profile/tip",
    connectStripe: "/payment/stripe/connect?id=",
    deleteCard: "/payment/deletepos",
    transactionList: "/payment/transactionsByUser",
    unlockPost: "/post/buyPaidPost",
    unlockMessage: "/chat/buymessage",
    addW9FormData: "/artist/addw9form",
    addMoneyToWallet: "/payment/addtowallet",
    disconnectStripe: "/user/removeBankAccount",
    withdrawMoneyFromWallet: "/payment/withdraw_money",
    bankAccountList: "/user/getBankAccountList",
    planlist: "/subscription/getWithFilters",
    renewSubscription: "/user/renewSubscription",
    verifyEmail: "/user/sendLink",
    contactUs: "/admin/sendsupportemail",
    getContent: "/admin/contents/edit/",
    fetchFaq: "/admin/getAllFaqs",
    campaignDetail: "/event/campaign/detail",
    announcementList: "/announcement/getWithFilters",
    announcementRead: "/announcement/",
    subscriptionDetail: "/user/subscriptions/detail",
  },
  settings: {
    updateProfile: "/user/updateprofile",
    getProfile: "/user/profile",
    connectOtherAccount: "/user/connectOtherAccount",
    deleteAccount: "/user/useraccountdelete",
    verifictionWithEmail: "/user/verificationwithemail",
    verifyEmailOtp: "/user/verifyOtp",
    verifyPhoneForSms: "/user/verificationwithmobilenumber",
    updateNotification: "/user/notificationsupdate",
    becomeCreator: "/user/becomecreator",
    publicPrivate: "/artist/publicprivate",
    entertainerSetting: "/artist/changed",
    subscribePrice: "/artist/subscribeprice",
    getVerificationStatus: "/user/status",
    addSubscriptionBundle: "/artist/subscriptionbundle",
    removeSubscriptionBundle: "/artist/bundleremove",
    addTrail: "/artist/addtrials",
    trailListing: "/artist/trialslisting",
    removeTrailLink: "/artist/removetrials",
    addStoryPromotion: "/artist/promotions",
    stopPromotion: "/artist/stoppromotion",
    removePromotion: "/artist/removepromotion",
  },
  home: {
    postList: "/post/getWithFilters",
    likePost: "/post/like",
    unlikePost: "/post/unlike",
    commentPost: "/post/add/comment",
    bookmarkPost: "/post/add/bookmark/",
  },
  artist: {
    subscriberList: "/artist/subscribers",
    getArtistList: "/artist/artistsWithFilter",
    artistDetails: "/artist/profile/",
    artistDetailsByUsername: "/user/user_detail/",
    artistPost: "/post/allpostbyartistid",
    createNewList: "/user/addlist",
    addArtistToList: "/user/addTofavourite",
    removeArtistFromList: "/user/deleteTofavlist",
    subscribeArtist: "/user/userFollowUnflow",
    allGroupList: "/user/getgroupuserlist",
    allCreatedList: "/user/getFavListWithFilter",
    activeArtistList: "/artist/onlineartistList",
    searchArtist: "/user/usersWithFilter",
  },
  notifiation: {
    notificationList: "/user/notificationsWithFilter",
    readNotification: "/user/markNotificationsAsRead",
  },
  messages: {
    chatDetails: "/chat/detail/",
    messageHistory: "/chat/messageHistory",
    chatList: "/chat/getWithFilters",
    deleteChat: "/chat/remove/",
    massMessage: "/chat/massmessage",
    massMessageList: "/chat/massmessagelisting",
  },
  collections: {
    allBookMarkPost: "/post/bookmarkswithfilter",
    blockArtist: "/user/entertainerBlockedByuser",
    unBlockArtist: "/user/entertainerUnblockedByuser",
    restrictArtist: "/user/artistRestictedByUser",
    subscribedArtistList: "/artist/userfollowinglist",
    restrictArtistList: "/artist/userrestrictedlist",
    blockedArtistList: "/user/entertainerblockedlist",
    selectedGroupList: "/user/getFavListWithFilter",
    removeList: "/user/deleteTofavouritelist",
  },
  entertainer: {
    post: {
      createPost: "/post/add",
      addPoll: "/post/addpoll",
      addQuiz: "/post/addquiz",
      storyList: "/post/storylist",
      updatePost: "/post/update",
      deletePost: "/post/remove",
      postDetails: "/post/detail/",
      publishPost: "/post/publish",
    },
    postLabel: {
      addPostToArchive: "/post/addarchive",
      addPostToPrivateArchive: "/post/addprivatearchive",
      archivePostList: "/post/pinpostlist",
      restorePost: "/post/postrestore",
    },
    statements: {
      transaction: "/payment/transactions",
      earningStatistics: "/payment/transactionsgroupby",
      lastOneYearTransaction: "/payment/lastOneYearTransaction",
    },
    statistics: {
      fansSubscription: "/payment/fansSubscription",
      singlePostStats: "/post/stats/",
      engagement: "/post/engagement",
      engagementMessage: "/chat/messageStats",
      statements: "/payment/statisticstatement",
      fansStatistics: "/payment/subscriberstatistic",
      topFansStats: "/payment/topFans",
    },
  },
};
