import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const getPostList = createAsyncThunk(
  "post/List",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.home.postList, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const getStoryList = createAsyncThunk(
  "story/List",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.post.storyList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const likePost = createAsyncThunk(
  "like/post",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.home.likePost, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const unlikePost = createAsyncThunk(
  "unlike/post",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.home.unlikePost, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const commentPost = createAsyncThunk(
  "comment/post",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.home.commentPost, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const bookmarkPost = createAsyncThunk(
  "bookmark/post",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.home.bookmarkPost, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
