import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TopFans = () => {
  const [tab, setTab] = useState("Total");
  const { t } = useTranslation();
  const { topFansStats } = useSelector((s) => s.statistics);

  return (
    <section>
      <div className="CardBody mt-3 py-3 innner_tabs commonBox border">
        <div className="innner_tabs border-bottom pb-3 px-2 mb-3">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Total"
            className="d-flex justify-content-between align-items-center"
          >
            <Nav
              variant="pills"
              className="flex-nowrap overflow-scroll"
              style={{ scrollbarWidth: "none" }}
            >
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Total"
                  onClick={() => setTab("Total")}
                  active={tab === "Total"}
                >
                  {t("Total")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Subscriptions"
                  onClick={() => setTab("Subscriptions")}
                  active={tab === "Subscriptions"}
                >
                  {t("Subscriptions")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="me-1">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Tips"
                  onClick={() => setTab("Tips")}
                  active={tab === "Tips"}
                >
                  {t("Tips")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="me-1">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Messages"
                  onClick={() => setTab("Messages")}
                  active={tab === "Messages"}
                >
                  {t("Messages")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="me-1">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Posts"
                  onClick={() => setTab("Posts")}
                  active={tab === "Posts"}
                >
                  {t("Posts")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="text-muted px-2 fw-normal">Name</th>
                {tab === "Subscriptions" && (
                  <th className="text-muted px-2 fw-normal">Subscription</th>
                )}
                {tab === "Tips" && (
                  <th className="text-muted px-2 fw-normal">Tips</th>
                )}
                {tab === "Messages" && (
                  <th className="text-muted px-2 fw-normal">Messages</th>
                )}
                {tab === "Posts" && (
                  <th className="text-muted px-2 fw-normal">Posts</th>
                )}
                <th className="text-muted px-2 fw-normal">Total</th>
              </tr>
            </thead>
            <tbody>
              {topFansStats?.map((val, index) => {
                let tabValue = 0;
                const transactionType =
                  tab === "Subscriptions" ? "Subscribe" : tab;
                const array = val?.transactions?.filter(
                  (i) => i.transactionType === transactionType
                );

                if (array?.length > 0) {
                  tabValue = array.reduce((a, b) => a + b.netAmount, 0);
                }

                const total = val?.transactions?.reduce(
                  (a, b) => a + b.netAmount,
                  0
                );

                return (
                  <tr key={index}>
                    <td className="px-2">
                      <div className="d-flex align-items-center gap-10">
                        <div className="imgWrp flex-shrink-0">
                          {val?.otherUserDetails?.profileImage ? (
                            <img
                              src={val?.otherUserDetails?.profileImage}
                              alt=""
                              style={{ height: 50, width: 50 }}
                              className="img-fluid rounded-circle object-fit-cover"
                            />
                          ) : (
                            <div className="user_img_fansstats shadow2">
                              {val?.otherUserDetails?.firstName &&
                              val?.otherUserDetails?.lastName
                                ? `${val?.otherUserDetails?.firstName.slice(
                                    0,
                                    1
                                  )}${val?.otherUserDetails?.lastName.slice(
                                    0,
                                    1
                                  )}`
                                : val?.otherUserDetails?.name?.slice(0, 1)}
                            </div>
                          )}
                        </div>
                        <div className="content">
                          <p className="m-0 text-dark fw-bold">
                            {val?.otherUserDetails?.firstName +
                              (val?.otherUserDetails?.lastName || "")}
                          </p>
                          <p className="m-0 text-muted">
                            @{val?.otherUserDetails?.userName}
                          </p>
                        </div>
                      </div>
                    </td>
                    {["Subscriptions", "Tips", "Messages", "Posts"].includes(
                      tab
                    ) && (
                      <td className="px-2">
                        ${tabValue > 0 ? tabValue.toFixed(2) : "0.00"}
                      </td>
                    )}
                    <td className="px-2">
                      ${total > 0 ? total.toFixed(2) : "0.00"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default TopFans;
