import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AuthLayout from "../../../layout/AuthLayout";
import "../../../css/style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, login } from "../../../redux/actions";
import { toast } from "react-toastify";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

const ChangePasswordComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buttonLoader, selectedEmail } = useSelector((s) => s.login);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      currentPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string("E-mail must be a string")
        .required("Password is required")
        .min(3, "Password must be greater than 3 character")
        .max(10, "Password must be less than 10 character"),
      password: Yup.string("E-mail must be a string")
        .required("Password is required")
        .min(3, "Password must be greater than 3 character"),
      // .max(10, "Password must be less than 10 character"),
      confirmPassword: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password"), null], "Password does not match")
        .min(3, "Password must be greater than 3 character"),
      // .max(10, "Password must be less than 10 character"),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(changePassword(values))
        .unwrap()
        .then((res) => {
          if (res) {
            navigate("/");
            toast.success("Password changed successfully");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  return (
    <Container className=" w-100 px-5 py-4 d-flex justify-content-center">
      <Row className="loginCont">
        <Col lg="12" md="12" className="mt-4">
          <h3 className="text-black text-medium mb-4">Change Password</h3>

          <div className="mt-4 position-relative loginInput">
            <input
              type={showCurrentPassword ? "text" : "password"}
              className="loginInputPassword"
              placeholder="Current Password"
              name="currentPassword"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="eyeIcon">
              {showCurrentPassword ? (
                <AiOutlineEye
                  color=""
                  size={23}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  color=""
                  size={23}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              )}
            </div>
          </div>
          {formik.touched.currentPassword && formik.errors.currentPassword && (
            <span className="text-danger errorText">
              {formik.errors.currentPassword}
            </span>
          )}
          <div className="mt-4 position-relative loginInput">
            <input
              type={showPassword ? "text" : "password"}
              className="loginInputPassword"
              placeholder="New Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="eyeIcon">
              {showPassword ? (
                <AiOutlineEye
                  color=""
                  size={23}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  color=""
                  size={23}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <span className="text-danger errorText">
              {formik.errors.password}
            </span>
          )}
          <div className="mt-4 position-relative loginInput">
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="loginInputPassword"
              placeholder="Confirm New Password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="eyeIcon">
              {showConfirmPassword ? (
                <AiOutlineEye
                  color=""
                  size={23}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  color=""
                  size={23}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <span className="text-danger errorText">
              {formik.errors.confirmPassword}
            </span>
          )}
          <div className="mt-4">
            {buttonLoader ? (
              <button className="loginBtn2 text-light" ß>
                <ButtonLoader />
              </button>
            ) : (
              <button
                className="loginBtn2 text-light"
                onClick={formik.handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
          <div className="mt-4 px-5 d-flex justify-content-between align-items-center">
            <h4
              className="text-black text-normal pointer"
              onClick={() => navigate("/")}
            >
              {" "}
              Login
            </h4>
            <h4
              className="text-black text-normal pointer"
              onClick={() => navigate("/signup")}
            >
              Sign up for Fansroom
            </h4>
          </div>

          {/*Social Login */}
          
          {/* <div className="mt-4">
            <button className="socialBtn bg-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                className="socailIcon"
              >
                <g clip-path="url(#clip0_126_3132)">
                  <path
                    d="M36.5652 15.6961L21.0654 15.6953C20.381 15.6953 19.8262 16.25 19.8262 16.9345V21.886C19.8262 22.5703 20.381 23.1251 21.0653 23.1251H29.7939C28.8381 25.6056 27.0542 27.6829 24.7782 29.0028L28.5 35.4457C34.4703 31.9928 38 25.9344 38 19.1524C38 18.1868 37.9289 17.4965 37.7865 16.7192C37.6783 16.1286 37.1656 15.6961 36.5652 15.6961Z"
                    fill="#167EE6"
                  />
                  <path
                    d="M18.9998 30.5648C14.7282 30.5648 10.9991 28.231 8.99634 24.7773L2.55371 28.4908C5.83232 34.1731 11.9742 37.9996 18.9998 37.9996C22.4463 37.9996 25.6983 37.0717 28.4998 35.4545V35.4457L24.7779 29.0028C23.0755 29.9902 21.1054 30.5648 18.9998 30.5648Z"
                    fill="#12B347"
                  />
                  <path
                    d="M28.5 35.4557V35.4468L24.7782 29.0039C23.0757 29.9912 21.1058 30.566 19 30.566V38.0008C22.4465 38.0008 25.6987 37.0728 28.5 35.4557Z"
                    fill="#0F993E"
                  />
                  <path
                    d="M7.43479 19.0009C7.43479 16.8954 8.00939 14.9256 8.99657 13.2232L2.55394 9.50977C0.927883 12.3024 0 15.5456 0 19.0009C0 22.4563 0.927883 25.6995 2.55394 28.4921L8.99657 24.7786C8.00939 23.0763 7.43479 21.1064 7.43479 19.0009Z"
                    fill="#FFD500"
                  />
                  <path
                    d="M18.9998 7.43479C21.7853 7.43479 24.3439 8.42457 26.3424 10.071C26.8354 10.4771 27.552 10.4478 28.0036 9.99615L31.5119 6.48783C32.0243 5.97543 31.9878 5.13668 31.4405 4.66183C28.0921 1.75698 23.7354 0 18.9998 0C11.9742 0 5.83232 3.8265 2.55371 9.50883L8.99634 13.2223C10.9991 9.76867 14.7282 7.43479 18.9998 7.43479Z"
                    fill="#FF4B26"
                  />
                  <path
                    d="M26.3426 10.071C26.8356 10.4771 27.5523 10.4478 28.0038 9.99615L31.5122 6.48783C32.0245 5.97543 31.988 5.13668 31.4407 4.66183C28.0923 1.75691 23.7357 0 19 0V7.43479C21.7854 7.43479 24.3441 8.42457 26.3426 10.071Z"
                    fill="#D93F21"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_126_3132">
                    <rect width="38" height="38" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Sign In With Google
            </button>
          </div>
          <div className="mt-4">
            <button className="socialBtn twitt">
              <svg
                className="socailIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <g clip-path="url(#clip0_126_3140)">
                  <path
                    d="M38 7.21763C36.5869 7.8375 35.0811 8.24837 33.5112 8.44788C35.1262 7.48362 36.3589 5.96837 36.9384 4.142C35.4326 5.03975 33.7701 5.67388 31.9984 6.02775C30.5686 4.50538 28.5309 3.5625 26.3079 3.5625C21.9949 3.5625 18.5226 7.06325 18.5226 11.3549C18.5226 11.9724 18.5749 12.5661 18.7031 13.1314C12.2265 12.8155 6.49563 9.71138 2.64575 4.98275C1.97362 6.14887 1.57938 7.48362 1.57938 8.9205C1.57938 11.6185 2.96875 14.0101 5.03975 15.3947C3.78813 15.371 2.56025 15.0076 1.52 14.4353V14.5208C1.52 18.3065 4.22037 21.451 7.7615 22.1754C7.12737 22.3487 6.43625 22.4319 5.719 22.4319C5.22025 22.4319 4.71675 22.4034 4.24412 22.2989C5.2535 25.384 8.11775 27.6521 11.5235 27.7257C8.873 29.7991 5.50763 31.0484 1.86438 31.0484C1.2255 31.0484 0.61275 31.0199 0 30.9415C3.45087 33.1669 7.54063 34.4375 11.951 34.4375C26.2865 34.4375 34.124 22.5625 34.124 12.2692C34.124 11.9249 34.1121 11.5924 34.0955 11.2623C35.6416 10.165 36.9408 8.79462 38 7.21763Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_126_3140">
                    <rect width="38" height="38" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Sign In With Twitter
            </button>
          </div>
          <div className="my-4">
            <button className="socialBtn face">
              <svg
                className="socailIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M16.1748 22.0666C16.0492 22.0666 13.4144 22.0674 12.1992 22.0662C11.5736 22.0658 11.3504 21.841 11.3504 21.2114C11.3494 19.5942 11.3494 17.977 11.3504 16.3598C11.3508 15.7382 11.5868 15.501 12.2044 15.5006C13.4196 15.4998 16.04 15.5002 16.1748 15.5002V11.9794C16.1756 10.3898 16.4592 8.86825 17.2668 7.47505C18.0936 6.04905 19.2968 5.07225 20.8388 4.50745C21.8264 4.14545 22.8516 4.00105 23.8996 4.00025C25.2108 3.99945 26.522 4.00065 27.8336 4.00305C28.3972 4.00385 28.6472 4.25305 28.6484 4.82065C28.6508 6.34185 28.6508 7.86305 28.6484 9.38385C28.6476 9.95745 28.408 10.1878 27.8316 10.1942C26.7568 10.2058 25.6812 10.1986 24.6076 10.2418C23.5232 10.2418 22.9528 10.7714 22.9528 11.8938C22.9268 13.081 22.942 14.2694 22.942 15.4998C23.0436 15.4998 26.1296 15.4994 27.572 15.4998C28.2272 15.4998 28.4512 15.725 28.4512 16.3838C28.4512 17.9922 28.4508 19.601 28.4496 21.2094C28.4492 21.8586 28.2388 22.0658 27.5796 22.0662C26.1372 22.067 23.0632 22.0666 22.9288 22.0666V35.0846C22.9288 35.7786 22.7104 35.9998 22.0256 35.9998H17.0164C16.4112 35.9998 16.1752 35.7646 16.1752 35.1594L16.1748 22.0666Z"
                  fill="white"
                />
              </svg>
              Sign In With Facebook
            </button>
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePasswordComponent;
