import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import { persistor } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = createRoot(document.getElementById("root"));

if (process.env.NODE_ENV !== "development") {
  console.error = () => null;
}

// document.addEventListener("contextmenu", function (e) {
//   e.preventDefault();
// });
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
