import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import ConfirmDeleteCard from "../../common/Modal/ConfirmDeleteCard";
import { deleteCard, getCardList } from "../../../redux/states/card/thunk";
import { toast } from "react-toastify";
import { BiInfoCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const YourCards = () => {
  const navigate = useNavigate();
  const { cardList } = useSelector((s) => s.card);
  const [selectedCard, setSelectedCard] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCardDelete = (dta) => {
    setSelectedCard(dta);
    setShowDeleteModal(true);
  };

  const getCardListt = () => {
    const data = {
      page: 1,
      limit: 100,
    };
    dispatch(getCardList(data));
  };
  useEffect(() => {
    getCardListt();
  }, []);

  const confirmDelete = () => {
    const data = {
      _id: selectedCard?._id,
    };
    dispatch(deleteCard(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getCardListt();
        setShowDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const cardLogo = (item) => {
    if (item === "VISA") {
      return "https://splash-ark.s3.us-east-2.amazonaws.com/16608181755941614744039133visacard.png";
    } else if (item === "MASTERCARD") {
      return "https://fansroom.s3.ap-south-1.amazonaws.com/1697708129991";
    } else if (item === "DISCOVER") {
      return "/images/discover.png";
    } else {
      return "/images/amex.png";
    }
  };

  return (
    <div className="w-100 ">
      {cardList?.map((itm, ind) => {
        return (
          <div className="payementCrdd shadow2 " key={ind}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center">
                <img src={cardLogo(itm.cardType)} alt="" className="mx-3" />
                <p className="mb-0 text-small d-flex align-items-center">
                  {" "}
                  <span style={{ fontSize: "30px" }}> ···· ···· ····</span>{" "}
                  <p className="mb-0 mx-2">{itm?.cardLast4}</p>
                </p>
              </div>
              {/* <div className="" onClick={() => handleCardDelete(itm)}>
                <MdDelete size={23} className="pointer" color="#f5392c" />
              </div> */}
            </div>
          </div>
        );
      })}

      <div className="endline"></div>
      <p className="my-3 mx-4 text-small">
        {t(
          "We are fully compliant with Payment Card Industry Data Security Standards."
        )}
      </p>
      <div className="d-flex border rounded-1 py-3 px-2 my-4">
        <BiInfoCircle color="#00aff0" size={25} className="mx-1" />
        <p className="text-small mx-2 mb-0">
          {t(
            "The charges on your credit card statement will appear as Fansroom"
          )}
        </p>
      </div>
      <ConfirmDeleteCard
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default YourCards;
