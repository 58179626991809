// This is the push notification section.j
// This is the username section inside the acount section;

import React, { useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import {
  getProfile,
  updateNotification,
  updateProfile,
} from "../../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ToastNotification = () => {
  const { t } = useTranslation();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  const formik = useFormik({
    initialValues: {
      toastNewComments: false,
      toastNewLikes: false,
      isNewStream: false,
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateNotification(values))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
  });

  useEffect(() => {
    const toastNotifications = user?.notifications?.toastNotifications;

    formik.setValues({
      toastNewComments: toastNotifications?.relatedYourPost?.newComments,
      toastNewLikes: toastNotifications?.relatedYourPost?.newLikes,
      isNewStream: toastNotifications?.subscriptionAndFollowing?.newStream,
    });
  }, [user]);

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("TOAST NOTIFICATIONS")}</span>
        </h4>
      </div>
      <div className="endline mt-2"></div>
      <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
        <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
          {t("Related to you and your posts")}
        </h6>
      </div>
      <div className="endline"></div>
      <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
        <Form.Check
          type="checkbox"
          id={`disabled-defau`}
          checked={formik.values?.toastNewComments}
          onChange={() =>
            formik.setFieldValue(
              "toastNewComments",
              formik?.values?.toastNewComments ? false : true
            )
          }
        />
        <label htmlFor="" className="text-nrml mx-3">
          {t("New Comment")}
        </label>
      </div>
      <div className="endline"></div>
      <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
        <Form.Check
          type="checkbox"
          id={`disabled-defau`}
          checked={formik.values?.toastNewLikes}
          onChange={() =>
            formik.setFieldValue(
              "toastNewLikes",
              formik?.values?.toastNewLikes ? false : true
            )
          }
        />
        <label htmlFor="" className="text-nrml mx-3">
          {t("New Likes")}
        </label>
      </div>
      <div className="endline"></div>
      <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
        <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
          {t("Subscriptions and following")}
        </h6>
      </div>
      <div className="endline"></div>

      <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
        <Form.Check
          type="checkbox"
          id={`disabled-defau`}
          checked={formik.values?.isNewStream}
          onChange={() =>
            formik.setFieldValue(
              "isNewStream",
              formik?.values?.isNewStream ? false : true
            )
          }
        />
        <label htmlFor="" className="text-nrml mx-3">
          {t("New Stream")}
        </label>
      </div>
      <div className="endline"></div>
      <div className="d-flex my-3 justify-content-center align-items-center w-full">
        {buttonLoader ? (
          <button className="updateNotificationBtn text-light">
            <ButtonLoader />
          </button>
        ) : (
          <button
            className="updateNotificationBtn"
            onClick={formik.handleSubmit}
          >
            {t("New Stream")}
          </button>
        )}
      </div>
    </Settings>
  );
};

export default ToastNotification;
