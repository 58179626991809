import React, { Fragment, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPollToClipboard } from "../../../redux/states/post/slice";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// components

const Poll = (props) => {
  const { t } = useTranslation();
  const { listData, title } = props;
  const [selectedItems, setSelectedItems] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { screenForPost } = useSelector((s) => s.post);

  const handlePollSelect = (dta) => {
    const allPollOptions = dta?.pollOption?.map((itm, index) => {
      const dd = {
        option: itm?.option,
      };
      return dd;
    });
    const data = {
      description: dta?.description,
      postType: "poll",
      pollOption: allPollOptions,
    };
    setSelectedItems(data);
  };

  const handleAddPost = () => {
    dispatch(addPollToClipboard(selectedItems));
    if (screenForPost === "home") {
      navigate("/home");
    } else {
      navigate("/new-post");
    }
  };

  return (
    <section className="custom_order px-0 pb-3">
      {/* topheaderbar */}
      <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
        <Link to="/home">
          <div className="notification_left_heading d-flex align-items-center">
            <span className="me-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
              >
                <path
                  d="M1 7L7 1M1 7L7 13M1 7L15 7"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <h5>{props.title}</h5>
          </div>
        </Link>

        <div className=" d-flex align-items-center gap-3">
          {selectedItems && (
            <Button
              className="mx-3 w-full bg-primary border-0"
              onClick={handleAddPost}
            >
              {t("Add to post")} (1 {t("item")})
            </Button>
          )}
          <div className="three_dot_btnvar">
            <UncontrolledDropdown>
              <DropdownToggle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <g opacity="0.5" clip-path="url(#clip0_711_1706)">
                    <path
                      d="M8.99998 14C7.89541 14 6.99997 14.8954 6.99997 16C6.99997 17.1046 7.89541 18 8.99998 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 8.99998 14Z"
                      fill="black"
                    />
                    <path
                      d="M8.99998 6.99997C7.89541 6.99997 6.99997 7.89541 6.99997 8.99998C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 8.99998 6.99997Z"
                      fill="black"
                    />
                    <path
                      d="M8.99998 -3.21229e-05C7.89541 -3.22195e-05 6.99997 0.895405 6.99997 1.99998C6.99997 3.10456 7.89541 4 8.99998 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 8.99998 -3.21229e-05Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_711_1706">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(18 18) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
                <DropdownItem>Hide all Locked Posts</DropdownItem>

                <DropdownItem>Hide all Locked Posts</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
      {/* topheaderbar */}
      <div className="endline my-3"></div>

      <div className="Achrive_part px-lg-4 px-3 mt-5 homeMiddleCont">
        <Row className="align-items-center align-middle position-relative">
          <Col lg="12" className="my-2">
            {listData?.length === 0 && (
              <div className="no_bookmarks_yet text-center h-100 vh-100 mt-5">
                <img src="/images/allmedia.png" alt="" className="img-fluid" />
                <p>{t("This category is empty")}</p>
              </div>
            )}

            {listData?.map((data, ind) => {
              return (
                <div className="PostQuiz shadow1 p-3 mb-3">
                  <div className="top my-2 d-flex align-items-center justify-content-between">
                    <h6 className="text-small">{data?.description}</h6>
                    <input
                      type="radio"
                      name="poll"
                      id={ind}
                      className="itemCheck"
                      onChange={() => handlePollSelect(data)}
                    />
                  </div>
                  <div className="my-2" htmlFor={ind}>
                    {data?.pollOption?.map((option, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className="py-1 d-flex align-items-center position-relative"
                          >
                            <input
                              type="radio"
                              className="position-absolute  w-100 h-100"
                              id={option?.option}
                            />
                            <label
                              className=" label px-3 text-white py-2 d-block  w-100 position-relative"
                              htmlFor={option?.option}
                            >
                              <span
                                className="fill position-absolute  w-100 h-100"
                                style={{ maxWidth: option?.option }}
                              ></span>
                              {option?.option}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Poll;
