import React from 'react'
import MainLayout from '../../../layout/MainLayout'
import MyProfileComponent from '../../../components/SideTabs/MyProfile'

const MyProfile = () => {
  return (
    <MainLayout>
       <MyProfileComponent />
    </MainLayout>
  )
}

export default MyProfile;