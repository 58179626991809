import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// components
import CustomDatefillter from "../../../CustomDatefillter";
import {
  getFansStatistics,
  getFansSubscriptions,
  getTopFansStats,
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Subscriptions from "./Subscriptions";
import TopFans from "./TopFans";

const Fans = () => {
  const dispatch = useDispatch();
  const [opendropdown, setopendropdown] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const userId = useSelector((s) => s.login)?.user?._id;
  const [tab, setTab] = useState("Subscriptions");
  const { t } = useTranslation();
  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
  };

  const getFansData = () => {
    let date = {};
    if (startDate && endDate) {
      date = {
        startDate,
        endDate,
      };
    }

    let subscription = {
      limit: 1000,
      page: 1,
      order: 1,
      followerId: userId,
    };
    dispatch(getFansSubscriptions({ ...subscription, ...date }));
    let topfans = {
      limit: 1000,
      page: 1,
      order: 1,
      userId: userId,
      type: "earnings",
    };
    dispatch(getTopFansStats({ ...topfans, ...date }));
  };

  useEffect(() => {
    getFansData();
  }, [tab, startDate, endDate, userId]);

  const toggledropdown = () => {
    setopendropdown(!opendropdown);
  };

  return (
    <>
      {opendropdown && (
        <div className="backdrops" onClick={toggledropdown}></div>
      )}
      <section className="Overviewtab_tab">
        <div className="CardBody px-2 mt-3 py-3 innner_tabs commonBox border">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Userlist"
            className="d-flex justify-content-between align-items-center"
          >
            <Nav variant="pills" className="">
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small"
                  eventKey="Subscriptions"
                  onClick={() => handleTabSelectedtion("Subscriptions")}
                  active={tab === "Subscriptions"}
                >
                  {t("Subscriptions")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  className="bg-transparent border-0 position-relative text-small "
                  eventKey="Topfans"
                  onClick={() => handleTabSelectedtion("Topfans")}
                  active={tab === "Topfans"}
                >
                  {t("Top fans")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
        <CustomDatefillter
          toggledropdown={toggledropdown}
          setStartDateValue={setStartDate}
          startDateValue={startDate}
          setEndDateValue={setEndDate}
          endDateValue={endDate}
        />
        <Tab.Content className="">
          <Tab.Pane eventKey="Subscriptions" active={tab === "Subscriptions"}>
            <div className="mt-3">
              <Subscriptions />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Topfans" active={tab === "Topfans"}>
            <div className="my-2">
              <TopFans />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </section>
    </>
  );
};

export default Fans;
