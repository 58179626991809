import React from "react";
import AuthLayout from "../../layout/AuthLayout";
import ForgotPasswordComponent from "../../components/AuthComponent/ForgotPassword";

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <ForgotPasswordComponent />
    </AuthLayout>
  );
};

export default ForgotPassword;
