import React, { useState } from "react";
import Searchpost from "./searchpost";
import Subscriptioncard from "./subscriptioncard";
import Scheduleevent from "./scheduleevent";
import Ppmessagesbox from "./ppmessagesbox";
import ArtistSection from "../ArtistSection/ArtistSection";

const EntertainerActivity = () => {
  const [entertainerSerach, setEntertainerSerach] = useState("");
  return (
    <div className="wrapping_side_comp">
      <Searchpost setEntertainerSerach={setEntertainerSerach} />

      {!!entertainerSerach === false ? (
        <>
          <Subscriptioncard />
          <Scheduleevent />
          <Ppmessagesbox />
        </>
      ) : (
        <ArtistSection entertainerSerach={entertainerSerach}/>
      )}
    </div>
  );
};

export default EntertainerActivity;
