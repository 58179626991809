import React, { useDebugValue, useEffect, useState } from "react";
import User1 from "../../../images/user13.png";
import User2 from "../../../images/user14.png";
import User3 from "../../../images/user15.png";
import { useDispatch, useSelector } from "react-redux";
import {
  allArtistGroupList,
  blockedArtistList,
  getSelectedGoupList,
  restirctedArtistList,
  subscribedArtistList,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import User from "../../../images/user.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserList = (props) => {
  const {
    setUserList,
    setPostList,
    setShowBlockedList,
    showAll,

    allUsers,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allPosts = useSelector((s) => s.home);
  const navigate = useNavigate();
  const { allGroupList } = useSelector((s) => s.artist);
  const [followingData, setFolowingData] = useState({
    count: 0,
    list: [],
  });
  const [restrictedData, setRestrictedData] = useState({
    count: 0,
    list: [],
  });
  const [blockedData, setBlockedData] = useState({
    count: 0,
    list: [],
  });

  useEffect(() => {
    const data = {
      limit: 100,
      page: 1,
    };
    dispatch(allArtistGroupList(data));
  }, []);

  useEffect(() => {
    var followingCount = 0;
    var restrictedCount = 0;
    var blockedCount = 0;

    allGroupList?.fallowerList?.map((itm) => {
      followingCount = followingCount + Number(itm.post?.length) || 0;
    });
    setFolowingData({
      count: followingCount,
      list: allGroupList?.fallowerList?.slice(0, 3),
    });
    allGroupList?.blockedList?.map((itm) => {
      blockedCount = blockedCount + Number(itm.post?.length) || 0;
    });
    setBlockedData({
      count: blockedCount,
      list: allGroupList?.blockedList?.slice(0, 3),
    });
    allGroupList?.restrictedList?.map((itm) => {
      restrictedCount = restrictedCount + Number(itm.post?.length) || 0;
    });
    setRestrictedData({
      count: restrictedCount,
      list: allGroupList?.restrictedList?.slice(0, 3),
    });
  }, [allGroupList]);

  const handleFollowing = () => {
    setShowBlockedList(false);

    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(subscribedArtistList(data))
      .unwrap()
      .then((res) => {
        const subcribeArtistList = res?.data?.map((itm) => {
          return itm?.followerDetails;
        });
        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });

        const flattenedData = [].concat(...postList);

        setPostList(flattenedData);
        setUserList(subcribeArtistList);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleRestriction = () => {
    setShowBlockedList(false);

    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(restirctedArtistList(data))
      .unwrap()
      .then((res) => {
        const restrictedList = res?.data?.map((itm) => {
          return itm?.artistDetails;
        });

        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });
        const flattenedData = [].concat(...postList);
        setPostList(flattenedData);
        setUserList(restrictedList);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleBlocked = () => {
    setShowBlockedList(true);
    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(blockedArtistList(data))
      .unwrap()
      .then((res) => {
        const restrictedList = res?.data?.map((itm) => {
          return itm?.blockedUserDetails;
        });
        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });

        const flattenedData = [].concat(...postList);
        setPostList(flattenedData);
        setUserList(restrictedList);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleGroupList = (dta) => {
    setShowBlockedList(false);

    const data = {
      limit: 1000,
      page: 1,
      fieldName: "listName",
      fieldValue: dta?.listName,
    };

    dispatch(getSelectedGoupList(data))
      .unwrap()
      .then((res) => {
        const list = res?.data?.map((itm) => {
          return itm?.artistDetails;
        });
        const postList = res?.data?.map((itm) => {
          return itm?.post;
        });
        const flattenedData = [].concat(...postList);
        setPostList(flattenedData);
        setUserList(list?.[0]);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleClick = () => {
    navigate("/collections");
  };

  return (
    <div className="px-3 py-1">
      <div className="d-flex justify-content-between align-items-center px-2">
        <h5 className="text-custom">{t("CUSTOM ORDER")}</h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g opacity="0.5">
              <path
                d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                fill="black"
              />
            </g>
          </svg>
        </h5>
      </div>
      <div
        onClick={handleClick}
        className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer"
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">All </h6>
          <h6 className="mb-0">
            <span>
              {allUsers?.length} {t("users")} {allPosts?.list?.length}{" "}
              {t("posts")}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox">
          {followingData?.list?.map((itm) => {
            return (
              <img
                src={itm?.followerDetails?.profileImage || User}
                alt=""
                className="user1 shadow2"
              />
            );
          })}
        </div>
      </div>
      <div
        onClick={handleClick}
        className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer"
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">{t("Following")}</h6>
          <h6 className="mb-0">
            <span>
              {allGroupList?.fallowerList?.length} {t("users")}{" "}
              {followingData?.count} {t("posts")}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox">
          {followingData?.list?.map((itm) => {
            return (
              <img
                src={itm?.followerDetails?.profileImage || User}
                alt=""
                className="user1 shadow2"
              />
            );
          })}
        </div>
      </div>
      <div
        onClick={handleClick}
        className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer"
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">{t("Restricted")}</h6>
          <h6 className="mb-0">
            <span>
              {allGroupList?.restrictedList?.length} {t("users")}{" "}
              {restrictedData?.count} {t("posts")}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox">
          {restrictedData?.list?.map((itm) => {
            return (
              <img
                src={itm?.artistDetails?.profileImage || User}
                alt=""
                className="user1 shadow2"
              />
            );
          })}
        </div>
      </div>
      <div
        onClick={handleClick}
        className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer"
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">{t("Blocked")}</h6>
          <h6 className="mb-0">
            <span>
              {allGroupList?.blockedList?.length} {t("users")}{" "}
              {blockedData?.count} {t("posts")}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox">
          {blockedData?.list?.map((itm) => {
            return (
              <img
                src={itm?.blockedUserDetails?.profileImage}
                alt=""
                className="user1"
              />
            );
          })}
        </div>
      </div>

      {allGroupList?.favList?.map((itm, ide) => {
        return (
          <div
            className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2 pointer"
            key={ide}
            onClick={handleClick}
          >
            <div className="custmUserbox d-flex align-items-center ">
              <div>
                <h6 className="text-small mb-0">{itm?.listName}</h6>
                <h6 className="mb-0">
                  <span>
                    {itm?.artistDetails?.length} {t("users")}{" "}
                    {itm?.post?.length} {t("posts")}
                  </span>
                </h6>
              </div>
            </div>
            <div className="cstomImgbox">
              {itm?.artistDetails?.slice(0, 3)?.map((artist) => {
                return (
                  <img
                    src={artist?.profileImage || User}
                    alt=""
                    className="user1 shadow2"
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserList;
