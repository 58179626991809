import React, { useState, useEffect, useDebugValue } from "react";
import { Col, Container, Row } from "react-bootstrap";
import useSocket from "../../../customHooks/useSocket";
import { useDispatch, useSelector } from "react-redux";
import ChatList from "../../../components/SideTabs/Messages/ChatList";
import UserMessageSection from "../../../components/SideTabs/Messages/UserMessageSection";
import EntertainerMessageSection from "../../../components/SideTabs/Messages/EntertainerMessageSection";
import { chatList } from "../../../redux/actions";
import MainLayout from "../../../layout/MainLayout";
// import SocketIo from "socket.io-client";
import { useParams } from "react-router-dom";
import {
  updateChatHistory,
  updateChatId,
  updateNewMsg,
} from "../../../redux/states/messages/slice";
import useResponsive from "../../../customHooks/useResponsive";

const Message2 = () => {
  const dispatch = useDispatch();
  const { io } = useSocket();
  // const [socketMessages, setSocketMessages] = useState([]);
  const [isNewChat, setIsNewChat] = useState(true);
  const [allFollowedArtist, setAllFollowedArtist] = useState([]);
  const { role } = useSelector((s) => s.setting);
  // const { messageHistory, currentChatId } = useSelector((s) => s.message);
  const userId = useSelector((s) => s.setting?.user)?._id;
  const { isMobileView, isDesktopView } = useResponsive();
  const { artistId, chatId } = useParams();

  useEffect(() => {
    if (io && !io?.hasListeners("newMessage")) {
      console.trace("socketall");
      io.on("newMessage", (response) => {
        getChatList();
        if ("msg" in response) {
          const isChatCreatedbyMe = response?.customerId === userId;
          if (isChatCreatedbyMe) {
            if (response?.byCustomer) {
            } else {
              dispatch(updateNewMsg(response));
            }
          } else {
            if (response?.byCustomer) {
              dispatch(updateNewMsg(response));
            }
          }
        }
      });
    }
  }, [io]);

  const getChatList = (props) => {
    const data = {
      limit: 100,
      orderBy: "createdAt",
      order: -1,
    };
    dispatch(chatList(data));
  };

  useEffect(() => {
    getChatList();
  }, []);

  useEffect(() => {
    if (chatId) {
      dispatch(updateChatId(chatId));
    }
  }, [chatId]);
  return (
    <MainLayout>
      <Row className="  h-100 d-flex justify-content-between ">
        {(!(isMobileView && artistId) || isDesktopView) && (
          <Col lg="5" md="12" sm="12">
            <div className="secondChatList">
              <ChatList
                isNewChat={isNewChat}
                allFollowedArtist={allFollowedArtist}
                getChatList={getChatList}
                setIsNewChat={setIsNewChat}
                setAllFollowedArtist={setAllFollowedArtist}
              />
            </div>
          </Col>
        )}
        {((isMobileView && artistId) || isDesktopView) && (
          <Col lg="7" md="12" sm="12" className="secondMsgSectionCol">
            {role === "USER" && (
              <div className="SecondMsgSection">
                <UserMessageSection
                  isNewChat={isNewChat}
                  setIsNewChat={setIsNewChat}
                  getChatList={getChatList}
                  allFollowedArtist={allFollowedArtist}
                />
              </div>
            )}
            {role === "ARTIST" && (
              <div className="SecondMsgSection">
                <EntertainerMessageSection
                  isNewChat={isNewChat}
                  setIsNewChat={setIsNewChat}
                  getChatList={getChatList}
                  allFollowedArtist={allFollowedArtist}
                />
              </div>
            )}
          </Col>
        )}
      </Row>
    </MainLayout>
  );
};

export default Message2;
