import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PostComponent from "../../Posts/PostComponent";
import { useDispatch, useSelector } from "react-redux";
import EntertainerPostComponent from "../../EntertainerCreatePost/EntertainerPostComponent";
import { getPostList } from "../../../../redux/actions";
import SmallLoader from "../../../common/ButtonLoader/SmallLoader";
import { useTranslation } from "react-i18next";

export default function AllPostListModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedItems, setSelectedItems, handleAddPost } = props;
  const navigate = useNavigate();
  const { list } = useSelector((s) => s.home);
  const { buttonLoader } = useSelector((s) => s.postLabel);

  const getPostLists = () => {
    const data = {
      limit: 1000,
      orderBy: "createdAt",
      order: -1,
      page: 1,
      postType: ["quiz", "normal", "other", "poll"],
    };
    dispatch(getPostList(data));
  };

  useEffect(() => {
    getPostLists();
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="valut_modal"
    >
      <Modal.Body>
        <div className="valut_mod pt-3">
          <section className="custom_order px-0 pb-3">
            {/* topheaderbar */}
            <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-1">
              <div className="notification_left_heading d-flex align-items-center">
                <h5>{t("All Post List")} </h5>
              </div>

              <div className=" d-flex align-items-center gap-3">
                {selectedItems?.length !== 0 && (
                  <>
                    {buttonLoader ? (
                      <Button className="mx-3 px-5 w-full">
                        <SmallLoader />
                      </Button>
                    ) : (
                      <Button className="mx-3 w-full" onClick={handleAddPost}>
                        {t("Add to Archive")} ({selectedItems?.length}{" "}
                        {t("items")})
                      </Button>
                    )}
                  </>
                )}
                <div className="three_dot_btnvar">
                  <div className="closeBtn pointer" onClick={props.onhide}>
                    <AiOutlineCloseCircle size={25} color="black" />
                  </div>
                </div>
              </div>
            </div>
            {/* topheaderbar */}
            <div className="endline my-3"></div>
            {/*all  body data here */}
            <div className="my-3 mx-3 w-full addPostModalCont">
              {list?.map((item, index) => {
                return (
                  <EntertainerPostComponent
                    key={index}
                    setShowSendTip={false}
                    data={item}
                    showProfileBanners={false}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                  />
                );
              })}
            </div>
          </section>
          <div className="btns_twice d-flex align-items-center justify-content-end px-4 py-3 border-top">
            <Button className="cancel_btn" onClick={props.onhide}>
              {t("CLOSE")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
