import React, { useEffect, useState } from "react";
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getEarningStatistics,
  getLastOneYearTransaction,
} from "../../../redux/states/statements/thunk";
import EarningStatisticsDatePicker from "../../common/Modal/EntertainerModal/EarningStatisticsDatePicker";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import CustomDatefillter from "../../CustomDatefillter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `$ ${value?.toFixed(2)}`;
        },
      },
    },
  },
};

// components

const Earningsstatics = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((s) => s.setting);
  const userId = user?._id;
  const { earningStatistics, lastOneYearTransactions } = useSelector(
    (s) => s.statement
  );
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allTimeData, setAllTimeData] = useState();
  const [opendropdown, setopendropdown] = useState(false);
  const [monthsData, setMonthsData] = useState([]);

  const getEarnningStasticsList = () => {
    let data = {
      limit: 10000,
      page: 1,
      userId: userId,
      type: "earnings",
    };
    let date = {};
    if (startDate && endDate) {
      date = {
        startDate,
        endDate,
      };
    }

    dispatch(getEarningStatistics({ ...data, ...date }));
  };

  const getLastYearMonths = () => {
    const currentDate = new Date();
    const data = [];
    const options = { month: "long", year: "numeric" };

    for (let i = 0; i < 12; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const newData = lastOneYearTransactions.find(
        (val) => val.year === year && val.month === month
      );
      data.push({
        monthLabel: date.toLocaleDateString("en-US", options),
        netAmount: newData ? newData?.totalNetAmount : 0,
        grossAmount: newData ? newData?.totalGrossAmount : 0,
        data: newData ? newData?.transactionTypes : [],
      });
    }
    setMonthsData(data.reverse());
    return data.reverse();
  };

  const toggledropdown = () => {
    setopendropdown(!opendropdown);
  };

  useEffect(() => {
    dispatch(
      getLastOneYearTransaction({
        limit: 10000,
        page: 1,
        // userId: userId,
        type: "earnings",
      })
    );
  }, [userId]);

  useEffect(() => {
    getEarnningStasticsList();
  }, [userId, startDate, endDate]);

  useEffect(() => {
    if (lastOneYearTransactions) {
      getLastYearMonths();
    }
  }, [lastOneYearTransactions]);

  useEffect(() => {
    if (earningStatistics?.length > 0) {
      const ddd = earningStatistics?.[0]?.data;

      let labelData = new Set();
      ddd?.forEach((k) => {
        k?.records?.forEach((l) =>
          labelData.add(moment(l.createdAt).format("ll"))
        );
      });

      // Convert Set to Array and sort the dates
      labelData = Array.from(labelData).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      const datasets = ddd?.map((k) => {
        const recordMap = {};
        // Aggregate net amounts by date
        k?.records?.forEach((record) => {
          const date = moment(record.createdAt).format("ll");
          if (recordMap[date]) {
            recordMap[date] += record.netAmount;
          } else {
            recordMap[date] = record.netAmount;
          }
        });
        const data = labelData.map((date) => recordMap[date] || 0);
        const borderColor = `rgb(${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )})`;
        const backgroundColor = borderColor
          .replace("rgb", "rgba")
          .replace(")", ", 0.5)");

        return {
          label: k?.transactionType,
          data: data,
          borderColor: borderColor,
          backgroundColor: backgroundColor,
        };
      });

      const data = {
        labels: labelData,
        datasets: datasets,
      };
      setAllTimeData(data);
    }
  }, [earningStatistics]);

  return (
    <>
      {opendropdown && (
        <div className="backdrops" onClick={toggledropdown}></div>
      )}
      <section className="custom_order px-0 pb-3">
        <CustomDatefillter
          toggledropdown={toggledropdown}
          setStartDateValue={setStartDate}
          startDateValue={startDate}
          setEndDateValue={setEndDate}
          endDateValue={endDate}
        />

        <div className="common_table statearning_table mt-4 border-0 border_hide">
          <Accordion defaultActiveKey={0}>
            <Accordion.Item eventKey={0}>
              <Accordion.Header>
                <div className="all_times d-flex justify-content-between  w-100">
                  <h4>{t("All times")}</h4>
                  <h4>
                    ${" "}
                    {earningStatistics?.[0]?.totalNetAmount
                      ? earningStatistics?.[0]?.totalNetAmount?.toFixed(2)
                      : "0.00"}
                  </h4>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {earningStatistics?.length > 0 ? (
                  <>
                    {allTimeData ? (
                      <Line options={options} data={allTimeData} />
                    ) : null}
                    {/* <div className="chart_line"></div> */}
                    <Table>
                      <thead>
                        <tr>
                          <th>{t("Earnings")}</th>
                          <th>{t("Gross")}</th>
                          <th>{t("Net")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {earningStatistics?.[0]?.data?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.transactionType}</td>
                              <td>$ {item?.grossAmount}</td>
                              <td>$ {item?.netAmount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="total_here d-flex aling-items-center justify-content-between">
                      <p>{t("TOTAL")}</p>
                      <p>
                        {t("NET")} :{" "}
                        <strong>
                          ${" "}
                          {earningStatistics?.[0]?.totalNetAmount
                            ? earningStatistics?.[0]?.totalNetAmount?.toFixed(2)
                            : "0.00"}
                        </strong>
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="noGraphData">
                    {t("No data during selected period")}
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>

            {monthsData?.map((i, index) => {
              let labelData = new Set();

              i?.data?.forEach((k) => {
                k?.records?.forEach((l) =>
                  labelData.add(moment(l.createdAt).format("ll"))
                );
              });
              // Convert Set to Array and sort the dates
              labelData = Array.from(labelData).sort(
                (a, b) => new Date(a) - new Date(b)
              );

              const datasets = i?.data?.map((k) => {
                const recordMap = {};
                // Aggregate net amounts by date
                k?.records?.forEach((record) => {
                  const date = moment(record.createdAt).format("ll");
                  if (recordMap[date]) {
                    recordMap[date] += record.netAmount;
                  } else {
                    recordMap[date] = record.netAmount;
                  }
                });
                const data = labelData.map((date) => recordMap[date] || 0);
                const borderColor = `rgb(${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )})`;
                const backgroundColor = borderColor
                  .replace("rgb", "rgba")
                  .replace(")", ", 0.5)");

                return {
                  label: k?.transactionType,
                  data: data,
                  borderColor: borderColor,
                  backgroundColor: backgroundColor,
                };
              });

              const data = {
                labels: labelData,
                datasets: datasets,
              };

              return (
                <Accordion.Item eventKey={index + 1}>
                  <Accordion.Header>
                    <div className="all_times d-flex justify-content-between  w-100">
                      <h4>{i?.monthLabel}</h4>
                      <h4>$ {i?.netAmount?.toFixed(2)}</h4>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {i?.data?.length > 0 ? (
                      <>
                        <Line options={options} data={data} />
                        {/* <div className="chart_line"></div> */}
                        <Table>
                          <thead>
                            <tr>
                              <th>{t("Earnings")}</th>
                              <th>{t("Gross")}</th>
                              <th>{t("Net")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {i?.data?.map((item) => {
                              return (
                                <tr>
                                  <td>{item?.transactionType}</td>
                                  <td>$ {item?.grossAmount?.toFixed(2)}</td>
                                  <td>$ {item?.netAmount?.toFixed(2)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="total_here d-flex aling-items-center justify-content-between">
                          <p>{t("TOTAL")}</p>
                          <p>
                            {t("NET")} :{" "}
                            <strong>$ {i?.netAmount?.toFixed(2)}</strong>
                          </p>
                          {/* <p>
                {t("GROSS")}
                <strong> ${i?.grossAmount}</strong>
              </p> */}
                        </div>
                      </>
                    ) : (
                      <div className="noGraphData">
                        {t("No data during selected period")}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
        <EarningStatisticsDatePicker
          show={showDatePickerModal}
          onhide={() => setShowDatePickerModal(false)}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          getEarnningStasticsList={getEarnningStasticsList}
        />
      </section>
    </>
  );
};

export default Earningsstatics;
