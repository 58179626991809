import React from "react";
import AuthLayout from "../../layout/AuthLayout";
import ChangePasswordComponent from "../../components/AuthComponent/ChangePassword";

const ChangePassword = () => {
  return (
    <AuthLayout>
      <ChangePasswordComponent />
    </AuthLayout>
  );
};

export default ChangePassword;
