import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import UserCard from "./UserCard";
import { useSelector, useDispatch } from "react-redux";
import SaveToList from "../../common/Modal/SaveToList";
import AddList from "../../common/Modal/AddList";
import { useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const Users = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userList, showBlockedList, commonCall, getArtistList } = props;

  const [activeTab, setActiveTab] = useState("all");
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState({});
  const [usersToBeShown, setUsersToBeShown] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [activeCount, setActiveCount] = useState(0);
  const [showAddList, setShowAddList] = useState(false);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const { user } = useSelector((state) => state.setting);

  const handleAddToFavourite = (dta) => {
    setSelectedArtist(dta);
    setShowSaveToList(true);
  };

  const showSubscriptions = () => {
    navigate("/subscriptions");
  };

  useEffect(() => {
    setUpdatedUserList(
      userList?.filter((it) => {
        return user.role === "ARTIST" ? it?._id !== user?._id : true;
      })
    );
  }, [userList]);

  useEffect(() => {
    setUsersToBeShown(updatedUserList);
    const data = updatedUserList?.filter((it) => {
      return it?.status === "active";
    });
    setActiveCount(data?.length);
    setActiveTab("all");
  }, [updatedUserList]);

  const showActiveUsers = () => {
    const data = updatedUserList?.filter((it) => {
      return it?.status === "active";
    });
    setUsersToBeShown(data);
    setActiveTab("active");
  };

  const showAllUsers = () => {
    setActiveTab("all");
    setUsersToBeShown(updatedUserList);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const value = e.target.value.toLowerCase();
    if (activeTab === "all") {
      const filterData = updatedUserList?.filter((itm) => {
        return itm?.name?.toLowerCase().includes(value);
      });
      setUsersToBeShown(filterData);
    } else if (activeTab === "active") {
      const data = updatedUserList?.filter((it) => {
        return it?.status === "active";
      });
      const filterData = data?.filter((itm) => {
        return itm?.name?.toLowerCase().includes(value);
      });
      setUsersToBeShown(filterData);
    }
    if (e.target.value === "") {
      if (activeTab === "all") {
        setUsersToBeShown(updatedUserList);
      } else if (activeTab === "active") {
        const data = updatedUserList?.filter((it) => {
          return it?.status === "active";
        });
        setUsersToBeShown(data);
      }
    }
  };
  return (
    <div className="px-3 py-1">
      <div className="d-flex justify-content-between align-items-center px-2">
        <h5 className="text-custom">{t("CUSTOM ORDER")}</h5>
        <h6 className="d-flex align-items-center">
          <div className="inputSearch">
            <input
              type="text"
              placeholder={t("Search...")}
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
            <BiSearch color="#636363" size={20} />
          </div>

          <span className="collPlus mx-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                  fill="black"
                />
              </g>
            </svg>
          </span>
        </h6>
      </div>
      <div className="d-flex align-items-center usersTitle my-4">
        <h5
          className={`${activeTab == "all" && "activeTab"}`}
          onClick={showAllUsers}
        >
          {t("All")} {updatedUserList.length}
        </h5>
        <h5
          className={`${activeTab == "active" && "activeTab"}`}
          onClick={showActiveUsers}
        >
          {t("Active")} {activeCount}
        </h5>
        <h5 onClick={showSubscriptions}>{t("Expired")}</h5>
        <h5 onClick={showSubscriptions}>{t("Attention request")} 0</h5>
      </div>

      {/*  */}
      <Row>
        {usersToBeShown.length != 0 &&
          usersToBeShown?.map((item, index) => {
            return (
              <UserCard
                key={index}
                showBlockedList={showBlockedList}
                data={item}
                getArtistList={getArtistList}
                handleAddToFavourite={handleAddToFavourite}
                commonCall={commonCall}
              />
            );
          })}
      </Row>
      <SaveToList
        show={showSaveToList}
        onHide={() => setShowSaveToList(false)}
        selectedArtist={selectedArtist}
        setShowAddList={setShowAddList}
        commonCall={commonCall}
      />
      <AddList
        show={showAddList}
        setShowAddList={setShowAddList}
        selectedArtist={selectedArtist}
        commonCall={commonCall}
      />
    </div>
  );
};

export default Users;
