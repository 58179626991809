import { createSlice } from "@reduxjs/toolkit";
import { status } from "../../constants";
import {
  addPoll,
  addQuiz,
  createPost,
  deletePost,
  postDetails,
  postViewed,
  publishPost,
  updatePost,
} from "./thunk";

const initialState = {
  status: status.IDLE,
  loader: false,
  buttonLoader: false,
  preSelectedPost: [],
  isPreSelected: false,
  preSelectedQuiz: {},
  preSelectedPoll: {},
  type: "",
  screenForPost: "",
  scheduledDates: {},
  isDateScheduled: false,
  saveForLaterAfter: "",
  postDetailsDta: {},
  allViewedPost: [],
};

const slice = createSlice({
  name: "post",
  initialState: { ...initialState },
  reducers: {
    createSheduleDate: (state, action) => {
      state.scheduledDates = action.payload;
      state.isDateScheduled = true;
    },
    removeScheduleDate: (state, action) => {
      state.scheduledDates = initialState.scheduledDates;
      state.isDateScheduled = false;
    },
    addPostToClipboard: (state, action) => {
      state.isPreSelected = true;
      state.preSelectedPost = action.payload;
      state.type = "media";
    },
    addQuizToClipboard: (state, action) => {
      state.isPreSelected = true;
      state.preSelectedQuiz = action.payload;
      state.type = "quiz";
    },
    addPollToClipboard: (state, action) => {
      state.isPreSelected = true;
      state.preSelectedPoll = action.payload;
      state.type = "poll";
    },
    removePostFromClipboard: (state, action) => {
      state.isPreSelected = false;
      state.preSelectedPost = initialState.preSelectedPost;
      state.preSelectedPoll = initialState.preSelectedPoll;
      state.preSelectedQuiz = initialState.preSelectedQuiz;
      state.type = "";
    },
    updatePostScreen: (state, action) => {
      state.screenForPost = action.payload;
    },
    updateSaveForLater: (state, action) => {
      state.saveForLaterAfter = action.payload;
    },
    updateViewedPostList: (state, action) => {
      state.allViewedPost = [...state.allViewedPost, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPost.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(createPost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(publishPost.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(publishPost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(publishPost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(postDetails.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(postDetails.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.postDetailsDta = action.payload?.data;
    });
    builder.addCase(postDetails.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.postDetailsDta = initialState.postDetailsDta;
    });
    builder.addCase(updatePost.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(updatePost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(updatePost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(deletePost.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(deletePost.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addPoll.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addPoll.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addPoll.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addQuiz.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addQuiz.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addQuiz.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(postViewed.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(postViewed.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
    });
    builder.addCase(postViewed.rejected, (state, action) => {
      state.status = status.FAILED;
    });
  },
});

export default slice.reducer;
export const {
  createSheduleDate,
  addPostToClipboard,
  removePostFromClipboard,
  addPollToClipboard,
  addQuizToClipboard,
  updatePostScreen,
  removeScheduleDate,
  updateSaveForLater,
  updateViewedPostList,
} = slice.actions;
