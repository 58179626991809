import React, { useState, useMemo, useEffect, Fragment } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  becameCreator,
  campaignDetail,
  planList,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SmallLoader from "../../../components/common/ButtonLoader/SmallLoader";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import { updateReVerify } from "../../../redux/states/becomeCreator/slice";
import AddCardNotifier from "../../../components/common/Modal/AddCardNotifier";
import VerifyEmail from "../../../components/common/VerifyEmail/VerifyEmail";
import EditProfile from "./EditProfile";
import CreatorAgreement from "./CreatorAgreement";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateFormat } from "../../../helpers/getDateformat";

const BecomeCreator = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reVerify } = useSelector((s) => s.becomeCreator);
  const { buttonLoader } = useSelector((s) => s.becomeCreator);
  // const options = useMemo(() => countryList().getData(), []);
  const [options, setOptions] = useState([]);
  const campaignData = useSelector((s) => s.common.campaignDetail);
  const [selectedCountry, setSelectedCOuntry] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [showAddCardNotifierModal, setShowAddCardNotifier] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [showUnapproval, setShowUnapproval] = useState(false);
  const [showCreatorAgreement, setShowCreatorAgreement] = useState(false);
  const [countryFocused, setCountryFocused] = useState(false);

  const { user } = useSelector((s) => s.setting);
  const dispatch = useDispatch();

  useEffect(() => {
    // from  konnective CRM
    dispatch(campaignDetail());
  }, []);

  useEffect(() => {
    if (campaignData && campaignData?.[0]?.countries) {
      const list = campaignData?.[0]?.countries?.map((val) => {
        return {
          label: val?.countryName,
          value: val?.countryCode,
        };
      });
      setOptions(list);
    }
  }, [campaignData]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      instagramLink: "",
      twitterLink: "",
      other: "",
      dob: "",
      releaseForm: "test",
      paymentMethod: "",
      subscriptionId: "",
      paymentSourceRefNo: "",
      agreed: {
        termsAndPrivacy: false,
        payouts: false,
        release: false,
      },
    },
    onSubmit: async function (values, { resetForm }) {
      if (!selectedPackage?._id && false) {
        toast.warning(t("Please select subscription plan !"));
      } else if (!selectedPayment?.paymentMethod && false) {
        toast.warning(t("Please select payment method !"));
      } else {
        if (user?.isEmailVerified === false) {
          toast.warning(t("Please verify your e-mail address"));
        } else {
          delete values["agreed"];
          dispatch(becameCreator(values))
            .unwrap()
            .then((res) => {
              // toast.success(res?.message);
              dispatch(updateReVerify(false));
              const verficationUrl = res?.data?.verification_url;
              window.location.href = verficationUrl;
            })
            .catch((err) => {
              toast.error(err?.message);
            });
        }
      }
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("First name is required")),
      lastName: Yup.string().required(t("Last name is required")),
      country: Yup.string().required(t("Country is required")),
      address: Yup.string().required(t("Address is required")),
      city: Yup.string().required(t("City is required")),
      state: Yup.string().required(t("State is required")),
      zipCode: Yup.string().required(t("Pin Code is required")),
      twitterLink: Yup.string().url(t("Invalid Url format")),
      instagramLink: Yup.string().url(t("Invalid Url format")),
      dob: Yup.date()
        .required(t("DOB is required"))
        .max(new Date(), t("DOB can't be in the future"))
        .required(t("DOB is required"))
        .test(
          "is-18-or-older",
          t("You must be 18 years or older"),
          function (value) {
            const today = new Date();
            const dob = new Date(value);
            const age = today.getFullYear() - dob.getFullYear();
            if (age < 18) {
              return false;
            }
            return true;
          }
        ),
      agreed: Yup.object().shape({
        termsAndPrivacy: Yup.boolean().isTrue(
          "Please accept Terms & Privacy Policy"
        ),
        payouts: Yup.boolean().isTrue("Please accept payouts policy"),
        release: Yup.boolean().isTrue("Please accept release policy"),
      }),
    }),
  });
  const setAgreement = (name, value) =>
    formik.setFieldValue("agreed." + name, value);

  const handleCountrySelect = (val) => {
    setSelectedCOuntry(val);
    formik.setFieldValue("country", val?.value);
  };

  const getPlanList = () => {
    const data = {
      limit: 1000,
      page: 1,
    };
    dispatch(planList(data));
  };

  useEffect(() => {
    getPlanList();
  }, []);

  useEffect(() => {
    if (
      user?._id &&
      typeof user?.becomeAcreator === "string" &&
      reVerify === false
    ) {
      navigate("/verification-status");
    }
  }, [user]);

  const { cardList } = useSelector((s) => s.card);

  // useEffect(() => {
  //   if (cardList.length === 0) {
  //     setShowAddCardNotifier(true);
  //   } else {
  //     setShowAddCardNotifier(false);
  //   }
  // }, [cardList]);

  var showProfileForm = true;

  showProfileForm = ["userName", "bio", "profileImage", "coverImage"].some(
    (key) => !user[key]
  );

  function onClickProceed() {
    formik
      .validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          setShowCreatorAgreement(true);
        }
      })
      .catch((error) => {
        console.log("simmba1 ", error);
      });
  }

  return (
    <>
      <MainLayout>
        <section className="becomeCreator">
          <Container>
            <Row className="align-items-start">
              {!showCreatorAgreement ? (
                <Col lg="7" className="my-2">
                  <div className="border px-0 pt-0 commonBox">
                    <div className="topHead py-2 border-bottom d-flex align-items-center gap-10 px-3 py-3">
                      <Link
                        to="/home"
                        className="text-dark"
                        onClick={() => navigate(-1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                        >
                          <path
                            d="M1 7L7 1M1 7L7 13M1 7L15 7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                      <h5 className="m-0 fw-sbold">{t("BANKING")}</h5>
                    </div>

                    {showProfileForm ? (
                      <EditProfile />
                    ) : (
                      <>
                        <VerifyEmail />
                        <div className="FormBody px-3 py-2">
                          <div className="py-2">
                            <h6 className="m-0 text-dark py-1 fw-sbold">
                              {t("ENTER PERSONAL INFO")}
                            </h6>
                            <p className="m-0 py-1">
                              {t(
                                "Fill in your legal name, address and attach your government issued picture ID."
                              )}
                            </p>
                          </div>
                          {showUnapproval && (
                            <div className="my-2 shadow1 rounded  border px-3 pt-0">
                              <div className="py-2">
                                <p className="m-0 py-1">
                                  {t(
                                    "Your account has not been approved.We are unable to accept your recent application for the following reason(s):"
                                  )}{" "}
                                  <br />
                                  {t(
                                    "1. Missing or poor quality selfie with a government issued, non-expired photo ID. Please make sure all information on the ID is readable and that your face is clearly visible. Do not use  any filters, visual effects, or editing software  on the image."
                                  )}
                                </p>
                              </div>
                              <div className="py-2">
                                <p className="m-0 py-1">
                                  {t(
                                    " Images may not be edited, cropped or re-sized;  must be in color; the entire government issued,  non-expired ID must be shown; all 4 corners of the  document must be visible. Electronic documents or  a photo of a photo will be rejected."
                                  )}
                                </p>
                              </div>
                              <div className="py-2">
                                <p className="m-0 py-1">
                                  {t(
                                    "2. Verification by additional social media is required at this time; you may complete this step by linking your Twitter account or by showing us your profile on another social media platform such as Instagram or Facebook. Please, note that links are visible to our team only, so make sure that your social media account is public during verification process."
                                  )}
                                </p>
                              </div>
                              <div className="py-2">
                                <p className="m-0 py-1">
                                  {t(
                                    "Please, note if your submitted application do not meet ALL of the above requirements, it will not be processed and additional information will be requested. This will delay your application process 24-48 hours.Thank you!"
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                          <Form className="mt-4">
                            <Row>
                              <Col lg="6" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    onBlur={formik.handleBlur}
                                    name="firstName"
                                    id="firstName"
                                  />
                                  <label for="floatingInput">
                                    {t("First Name")}
                                  </label>
                                </div>
                                {formik.touched.firstName &&
                                  formik.errors.firstName && (
                                    <span className="text-danger">
                                      {formik.errors.firstName}
                                    </span>
                                  )}
                              </Col>
                              <Col lg="6" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    onBlur={formik.handleBlur}
                                    name="lastName"
                                    id="lastName"
                                  />
                                  <label for="floatingInput">
                                    {t("Last Name")}
                                  </label>
                                </div>
                                {formik.touched.lastName &&
                                  formik.errors.lastName && (
                                    <span className="text-danger">
                                      {formik.errors.lastName}
                                    </span>
                                  )}
                              </Col>
                              <Col lg="12" className="my-2">
                                <label className={"select-label"}>
                                  {t("Country")}
                                </label>
                                <div className="cstmSelect position-relative ">
                                  <Select
                                    placeholder={false}
                                    options={options}
                                    classNamePrefix={"fansroom"}
                                    value={selectedCountry}
                                    showDefaultOption={true}
                                    onChange={(val) => handleCountrySelect(val)}
                                    onFocus={() => setCountryFocused(true)}
                                    onBlur={() => setCountryFocused(false)}
                                  />

                                  {formik.touched.country &&
                                    formik.errors.country && (
                                      <span className="text-danger">
                                        {formik.errors.country}
                                      </span>
                                    )}
                                </div>
                                <p className="m-0 pt-2 small-text">
                                  {t(
                                    " If you would like to change your country please contact customer support"
                                  )}
                                </p>
                              </Col>
                              <Col lg="6" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    onBlur={formik.handleBlur}
                                    name="address"
                                    id="address"
                                  />
                                  <label for="floatingInput">
                                    {t("Address")}
                                  </label>
                                </div>
                                {formik.touched.address &&
                                  formik.errors.address && (
                                    <span className="text-danger">
                                      {formik.errors.address}
                                    </span>
                                  )}
                              </Col>
                              <Col lg="6" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    onBlur={formik.handleBlur}
                                    name="city"
                                    id="city"
                                  />
                                  <label for="floatingInput">{t("City")}</label>
                                </div>
                                {formik.touched.city && formik.errors.city && (
                                  <span className="text-danger">
                                    {formik.errors.city}
                                  </span>
                                )}
                              </Col>
                              <Col lg="6" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    onBlur={formik.handleBlur}
                                    name="state"
                                    id="state"
                                  />
                                  <label for="floatingInput">
                                    {t("State / Province")}
                                  </label>
                                </div>
                                {formik.touched.state &&
                                  formik.errors.state && (
                                    <span className="text-danger">
                                      {formik.errors.state}
                                    </span>
                                  )}
                              </Col>
                              <Col lg="6" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.zipCode}
                                    onBlur={formik.handleBlur}
                                    name="zipCode"
                                    id="zipCode"
                                  />
                                  <label for="floatingInput">
                                    {t("ZIP / postal Code")}
                                  </label>
                                </div>
                                {formik.touched.state &&
                                  formik.errors.state && (
                                    <span className="text-danger">
                                      {formik.errors.state}
                                    </span>
                                  )}
                              </Col>
                              <Col lg="12" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterLink}
                                    onBlur={formik.handleBlur}
                                    name="twitterLink"
                                    id="twitterLink"
                                  />
                                  <label for="floatingInput">
                                    {t("Twitter (optional)")}
                                  </label>
                                </div>
                                {formik.touched.twitterLink &&
                                  formik.errors.twitterLink && (
                                    <span className="text-danger">
                                      {formik.errors.twitterLink}
                                    </span>
                                  )}
                                <p className="m-0 pt-2 small-text">
                                  {t(
                                    "This information will not be shown on your public profile"
                                  )}
                                </p>
                              </Col>
                              <Col lg="12" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.instagramLink}
                                    onBlur={formik.handleBlur}
                                    name="instagramLink"
                                    id="instagramLink"
                                  />
                                  <label for="floatingInput">
                                    {t("Instagram (optional)")}
                                  </label>
                                </div>
                                {formik.touched.instagramLink &&
                                  formik.errors.instagramLink && (
                                    <span className="text-danger">
                                      {formik.errors.instagramLink}
                                    </span>
                                  )}
                                <p className="m-0 pt-2 small-text">
                                  {t(
                                    "This information will not be shown on your public profile"
                                  )}
                                </p>
                              </Col>
                              <Col lg="12" className="my-2">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.other}
                                    onBlur={formik.handleBlur}
                                    name="other"
                                    id="other"
                                  />
                                  <label for="floatingInput">
                                    {t("Other")}
                                  </label>
                                </div>
                                <p className="m-0 pt-2 small-text">
                                  {" "}
                                  {t(
                                    "This information will not be shown on your public profile"
                                  )}
                                </p>
                              </Col>
                              <Col lg="12" className="my-2">
                                <div className="form-floating">
                                  <DatePicker
                                    placeholderText={getDateFormat(
                                      selectedCountry?.value || "US"
                                    )}
                                    dateFormat={getDateFormat(
                                      selectedCountry?.value || "US"
                                    )}
                                    showYearDropdown
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    wrapperClassName="datepickerWrapper"
                                    selected={
                                      formik.values.dob &&
                                      new Date(formik.values.dob)
                                    }
                                    onChange={(date) => {
                                      formik.setFieldValue("dob", date);
                                    }}
                                  />
                                  <label for="floatingInput">
                                    {t("Date of Birth")}
                                  </label>
                                </div>
                                {(formik.touched.dob || formik.errors.dob) && (
                                  <span className="text-danger">
                                    {formik.errors.dob}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div className="bottom FormBody px-3 py-2 border-top">
                          <div className="py-2 my-2">
                            <h6 className="fw-bold text-muted m-0">
                              {t("RELEASE FORMS")}
                            </h6>
                            <p className="m-0 py-2 fw-sbold text-muted">
                              {t(
                                "If your content contains someone other than you, you will need to provide a copy of their photo ID and release documents before the content can be posted. Submitted documents must be a high quality photo or a scanned copy, signed by the additional participant for the account holder."
                              )}
                            </p>
                          </div>
                          <div className="px-3">
                            <div className="my-3 mx-1 d-flex align-items">
                              <input
                                type="checkbox"
                                className=""
                                checked={
                                  formik.values?.agreed["termsAndPrivacy"]
                                }
                                onClick={() =>
                                  setAgreement(
                                    "termsAndPrivacy",
                                    !formik.values?.agreed["termsAndPrivacy"]
                                  )
                                }
                                style={{ width: "20px", height: "20px" }}
                              />
                              <h6 className="text-sm mx-3 mb-0">
                                {t("I agree to all ")}
                                <Link to="/terms"> Terms </Link> {t("and")}
                                <Link to="/privacy"> Privacy Policy</Link>
                              </h6>
                            </div>
                            <div className="my-3 mx-1 d-flex align-items">
                              <input
                                type="checkbox"
                                className=""
                                checked={formik.values?.agreed["payouts"]}
                                onClick={() =>
                                  setAgreement(
                                    "payouts",
                                    !formik.values?.agreed["payouts"]
                                  )
                                }
                                style={{ width: "20px", height: "20px" }}
                              />
                              <h6 className="text-sm mx-3 mb-0">
                                {t("I agree to ")}
                                <Link to="/payouts"> Payouts Policy </Link>
                              </h6>
                            </div>
                            <div className="my-3 mx-1 d-flex align-items">
                              <input
                                type="checkbox"
                                className=""
                                checked={formik.values?.agreed["release"]}
                                onClick={() =>
                                  setAgreement(
                                    "release",
                                    !formik.values?.agreed["release"]
                                  )
                                }
                                style={{ width: "20px", height: "20px" }}
                              />
                              <h6 className="text-sm mx-3 mb-0">
                                {t("I agree to ")}
                                <Link to="/release-policy">
                                  {" "}
                                  Release Policy{" "}
                                </Link>
                              </h6>
                            </div>
                          </div>
                          {Object.values(formik.touched?.agreed || {})?.some(
                            (item) => item
                          ) &&
                            Object.values(formik.values?.agreed || {})?.some(
                              (item) => !item
                            ) && (
                              <div className="text-danger px-3">
                                Please accept all terms & Policies
                              </div>
                            )}
                          <div className="py-2 my-2">
                            <div className="py-2 mt-3 m-0 d-flex align-items-center justify-content-end ">
                              {buttonLoader ? (
                                <Button className="common-btn px-5 d-flex align-items-center justify-content-center rounded-pill">
                                  <SmallLoader />
                                </Button>
                              ) : (
                                <Button
                                  onClick={onClickProceed}
                                  className="common-btn px-3 d-flex align-items-center justify-content-center rounded-pill"
                                >
                                  {t("Proceed")}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              ) : (
                <Col lg="7" className="my-2">
                  <div className="border px-2 pt-0 commonBox">
                    <div className="topHead py-2 border-bottom d-flex align-items-center gap-10 px-3 py-3">
                      <Link
                        to=""
                        className="text-dark"
                        onClick={(e) => (
                          e.preventDefault(), setShowCreatorAgreement(false)
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                        >
                          <path
                            d="M1 7L7 1M1 7L7 13M1 7L15 7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                      <h5 className="m-0 fw-sbold">
                        {t("IMPORTANT INFORMATION - PLEASE READ")}
                      </h5>
                    </div>
                    <CreatorAgreement />

                    <div className="py-2 my-2">
                      <div className="py-2 mt-3 m-0 d-flex align-items-center justify-content-end ">
                        {buttonLoader ? (
                          <Button className="common-btn px-5 d-flex align-items-center justify-content-center rounded-pill">
                            <SmallLoader />
                          </Button>
                        ) : (
                          <Button
                            onClick={formik.handleSubmit}
                            className="common-btn px-3 d-flex align-items-center justify-content-center rounded-pill"
                          >
                            {t("Accept & Submit")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              <Col lg="5" className="border my-2 sticky-top">
                <div className="commonBox px-3">
                  <div className="d-flex align-items-center justify-content-between gap-10 py-3 border-bottom">
                    <p className="text-muted fw-bold m-0">
                      {t("YOUR ACCOUNT IS PENDING FOR APPROVAL")}
                    </p>
                    <span className="icn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        class="bi bi-hourglass-split"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"
                          fill="#FFA500"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="bottom border-bottom border-bottom-0 pt-3 ">
                    <p className="text-muted fw-bold m-0">
                      {t("YOUR ACCOUNT IS PENDING FOR APPROVAL")}
                    </p>
                    <span className="icn mt-3 d-block text-center my-3 pt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="74"
                        height="74"
                        viewBox="0 0 74 74"
                        fill="none"
                      >
                        <g opacity="0.5">
                          <path
                            d="M70.8069 31.6997H3.19287C2.92765 31.6997 2.6733 31.5944 2.48576 31.4068C2.29823 31.2193 2.19287 30.9649 2.19287 30.6997V23.4057C2.19287 23.2335 2.23733 23.0642 2.32195 22.9143C2.40657 22.7643 2.52848 22.6387 2.67587 22.5497L36.4759 2.14371C36.6317 2.04969 36.8103 2 36.9924 2C37.1744 2 37.353 2.04969 37.5089 2.14371L71.3229 22.5497C71.4703 22.6387 71.5922 22.7643 71.6768 22.9143C71.7614 23.0642 71.8059 23.2335 71.8059 23.4057V30.6997C71.8059 30.9648 71.7006 31.219 71.5133 31.4065C71.326 31.594 71.0719 31.6995 70.8069 31.6997ZM4.19387 29.6997H69.8069V23.9697L36.9939 4.16771L4.19387 23.9677V29.6997Z"
                            fill="black"
                          />
                          <path
                            d="M37 24.3136C35.8171 24.3136 34.6608 23.9628 33.6772 23.3056C32.6936 22.6484 31.927 21.7143 31.4743 20.6214C31.0216 19.5285 30.9032 18.3259 31.134 17.1657C31.3647 16.0055 31.9344 14.9398 32.7708 14.1034C33.6073 13.2669 34.673 12.6973 35.8332 12.4665C36.9934 12.2357 38.196 12.3542 39.2889 12.8068C40.3818 13.2595 41.3159 14.0261 41.9731 15.0097C42.6303 15.9933 42.981 17.1496 42.981 18.3326C42.9792 19.9183 42.3485 21.4385 41.2272 22.5597C40.106 23.681 38.5857 24.3117 37 24.3136ZM37 14.3506C36.2127 14.3506 35.443 14.584 34.7883 15.0215C34.1337 15.4589 33.6234 16.0807 33.3221 16.8081C33.0208 17.5355 32.9419 18.336 33.0955 19.1082C33.2491 19.8805 33.6283 20.5898 34.1851 21.1466C34.7418 21.7033 35.4512 22.0825 36.2234 22.2361C36.9956 22.3897 37.7961 22.3108 38.5235 22.0095C39.2509 21.7082 39.8727 21.198 40.3101 20.5433C40.7476 19.8886 40.981 19.1189 40.981 18.3316C40.9797 17.2761 40.5599 16.2643 39.8136 15.518C39.0673 14.7717 38.0555 14.3519 37 14.3506ZM64.972 38.0176H54.331C53.7582 38.0168 53.2092 37.7888 52.8042 37.3837C52.3993 36.9786 52.1716 36.4294 52.171 35.8566V30.7006C52.171 30.4353 52.2764 30.181 52.4639 29.9935C52.6515 29.8059 52.9058 29.7006 53.171 29.7006H66.132C66.3973 29.7006 66.6516 29.8059 66.8391 29.9935C67.0267 30.181 67.132 30.4353 67.132 30.7006V35.8596C67.1307 36.4318 66.9027 36.9803 66.4978 37.3847C66.093 37.7892 65.5443 38.0168 64.972 38.0176ZM54.172 31.6976V35.8566C54.172 35.8991 54.1889 35.9399 54.2188 35.9701C54.2488 36.0002 54.2895 36.0173 54.332 36.0176H64.972C65.0146 36.0173 65.0553 36.0002 65.0852 35.9701C65.1152 35.9399 65.132 35.8991 65.132 35.8566V31.7006L54.172 31.6976Z"
                            fill="black"
                          />
                          <path
                            d="M64.0638 58.2156H55.2378C54.9726 58.2156 54.7182 58.1103 54.5307 57.9227C54.3431 57.7352 54.2378 57.4808 54.2378 57.2156V37.0156C54.2378 36.7504 54.3431 36.4961 54.5307 36.3085C54.7182 36.121 54.9726 36.0156 55.2378 36.0156H64.0638C64.329 36.0156 64.5834 36.121 64.7709 36.3085C64.9584 36.4961 65.0638 36.7504 65.0638 37.0156V57.2156C65.0638 57.4808 64.9584 57.7352 64.7709 57.9227C64.5834 58.1103 64.329 58.2156 64.0638 58.2156ZM56.2378 56.2156H63.0638V38.0156H56.2378V56.2156Z"
                            fill="black"
                          />
                          <path
                            d="M66.132 64.5352H53.171C52.9058 64.5352 52.6514 64.4299 52.4639 64.2423C52.2764 64.0548 52.171 63.8004 52.171 63.5352V58.3762C52.1715 57.8034 52.3993 57.2542 52.8042 56.8491C53.2091 56.444 53.7582 56.216 54.331 56.2152H64.972C65.5448 56.216 66.0939 56.444 66.4988 56.8491C66.9038 57.2542 67.1315 57.8034 67.132 58.3762V63.5352C67.132 63.8004 67.0267 64.0548 66.8391 64.2423C66.6516 64.4299 66.3972 64.5352 66.132 64.5352ZM54.171 62.5352H65.132V58.3762C65.132 58.3337 65.1152 58.2929 65.0852 58.2627C65.0552 58.2326 65.0145 58.2155 64.972 58.2152H54.331C54.2885 58.2155 54.2478 58.2326 54.2178 58.2627C54.1878 58.2929 54.171 58.3337 54.171 58.3762V62.5352ZM42.32 38.0162H31.68C31.1072 38.0154 30.5581 37.7875 30.1532 37.3823C29.7483 36.9772 29.5205 36.428 29.52 35.8552V30.6992C29.52 30.434 29.6254 30.1796 29.8129 29.9921C30.0004 29.8046 30.2548 29.6992 30.52 29.6992H43.48C43.7452 29.6992 43.9996 29.8046 44.1871 29.9921C44.3747 30.1796 44.48 30.434 44.48 30.6992V35.8582C44.4787 36.4305 44.2506 36.9789 43.8458 37.3834C43.4409 37.7879 42.8923 38.0154 42.32 38.0162ZM31.52 31.6992V35.8582C31.52 35.9007 31.5368 35.9415 31.5668 35.9717C31.5968 36.0019 31.6375 36.019 31.68 36.0192H42.32C42.3625 36.019 42.4032 36.0019 42.4332 35.9717C42.4632 35.9415 42.48 35.9007 42.48 35.8582V31.6992H31.52Z"
                            fill="black"
                          />
                          <path
                            d="M41.4001 58.2156H32.6001C32.3349 58.2156 32.0805 58.1103 31.893 57.9227C31.7055 57.7352 31.6001 57.4808 31.6001 57.2156V37.0156C31.6001 36.7504 31.7055 36.4961 31.893 36.3085C32.0805 36.121 32.3349 36.0156 32.6001 36.0156H41.4001C41.6653 36.0156 41.9197 36.121 42.1072 36.3085C42.2947 36.4961 42.4001 36.7504 42.4001 37.0156V57.2156C42.4001 57.4808 42.2947 57.7352 42.1072 57.9227C41.9197 58.1103 41.6653 58.2156 41.4001 58.2156ZM33.6001 56.2156H40.4001V38.0156H33.6001V56.2156Z"
                            fill="black"
                          />
                          <path
                            d="M43.4802 64.5352H30.5202C30.2549 64.5352 30.0006 64.4299 29.8131 64.2423C29.6255 64.0548 29.5202 63.8004 29.5202 63.5352V58.3762C29.5207 57.8034 29.7484 57.2542 30.1533 56.8491C30.5583 56.444 31.1074 56.216 31.6802 56.2152H42.3202C42.893 56.216 43.4421 56.444 43.847 56.8491C44.2519 57.2542 44.4796 57.8034 44.4802 58.3762V63.5352C44.4802 63.8004 44.3748 64.0548 44.1873 64.2423C43.9997 64.4299 43.7454 64.5352 43.4802 64.5352ZM31.5192 62.5352H42.4802V58.3762C42.4802 58.3337 42.4633 58.2929 42.4334 58.2627C42.4034 58.2326 42.3627 58.2155 42.3202 58.2152H31.6802C31.6376 58.2155 31.5969 58.2326 31.567 58.2627C31.537 58.2929 31.5202 58.3337 31.5202 58.3762L31.5192 62.5352ZM20.6692 38.0162H10.0282C9.45537 38.0154 8.90627 37.7875 8.50134 37.3823C8.0964 36.9772 7.86869 36.428 7.86816 35.8552V30.6992C7.86816 30.434 7.97352 30.1796 8.16106 29.9921C8.34859 29.8046 8.60295 29.6992 8.86816 29.6992H21.8292C22.0944 29.6992 22.3487 29.8046 22.5363 29.9921C22.7238 30.1796 22.8292 30.434 22.8292 30.6992V35.8582C22.8278 36.4305 22.5998 36.9789 22.1949 37.3834C21.7901 37.7879 21.2414 38.0154 20.6692 38.0162ZM9.86816 31.6992V35.8582C9.86816 35.9007 9.88499 35.9415 9.91497 35.9717C9.94494 36.0019 9.98564 36.019 10.0282 36.0192H20.6692C20.7117 36.019 20.7524 36.0019 20.7824 35.9717C20.8123 35.9415 20.8292 35.9007 20.8292 35.8582V31.6992H9.86816Z"
                            fill="black"
                          />
                          <path
                            d="M19.7999 58.2156H10.8999C10.6347 58.2156 10.3803 58.1103 10.1928 57.9227C10.0053 57.7352 9.8999 57.4808 9.8999 57.2156V37.0156C9.8999 36.7504 10.0053 36.4961 10.1928 36.3085C10.3803 36.121 10.6347 36.0156 10.8999 36.0156H19.7999C20.0651 36.0156 20.3195 36.121 20.507 36.3085C20.6945 36.4961 20.7999 36.7504 20.7999 37.0156V57.2156C20.7999 57.4808 20.6945 57.7352 20.507 57.9227C20.3195 58.1103 20.0651 58.2156 19.7999 58.2156ZM11.8999 56.2156H18.7999V38.0156H11.8999V56.2156Z"
                            fill="black"
                          />
                          <path
                            d="M21.8292 64.5348H8.86816C8.60295 64.5348 8.34859 64.4295 8.16106 64.2419C7.97352 64.0544 7.86816 63.8001 7.86816 63.5348V58.3758C7.86869 57.803 8.0964 57.2538 8.50134 56.8487C8.90627 56.4436 9.45537 56.2156 10.0282 56.2148H20.6692C21.242 56.2156 21.7911 56.4436 22.196 56.8487C22.6009 57.2538 22.8286 57.803 22.8292 58.3758V63.5348C22.8292 63.8001 22.7238 64.0544 22.5363 64.2419C22.3487 64.4295 22.0944 64.5348 21.8292 64.5348ZM9.86816 62.5348H20.8292V58.3758C20.8292 58.3333 20.8123 58.2925 20.7824 58.2624C20.7524 58.2322 20.7117 58.2151 20.6692 58.2148H10.0282C9.98564 58.2151 9.94494 58.2322 9.91497 58.2624C9.88499 58.2925 9.86816 58.3333 9.86816 58.3758V62.5348Z"
                            fill="black"
                          />
                          <path
                            d="M71 71.9992H3C2.73478 71.9992 2.48043 71.8938 2.29289 71.7063C2.10536 71.5187 2 71.2644 2 70.9992V63.5352C2 63.2699 2.10536 63.0156 2.29289 62.828C2.48043 62.6405 2.73478 62.5352 3 62.5352H71C71.2652 62.5352 71.5196 62.6405 71.7071 62.828C71.8946 63.0156 72 63.2699 72 63.5352V70.9992C72 71.2644 71.8946 71.5187 71.7071 71.7063C71.5196 71.8938 71.2652 71.9992 71 71.9992ZM4 69.9992H70V64.5352H4V69.9992Z"
                            fill="black"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <AddCardNotifier
            show={showAddCardNotifierModal}
            hide={() => setShowAddCardNotifier(false)}
          />
        </section>
      </MainLayout>
    </>
  );
};

export default BecomeCreator;
