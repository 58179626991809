import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const allBookMarkedPost = createAsyncThunk(
  "bookmarkPost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.allBookMarkPost,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const blockArtist = createAsyncThunk(
  "blockArtist",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.blockArtist,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const unBlockArtist = createAsyncThunk(
  "unblockArtist",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.unBlockArtist,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const restirctArtist = createAsyncThunk(
  "restrictArtist",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.restrictArtist,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const subscribedArtistList = createAsyncThunk(
  "subscribedArtistList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.subscribedArtistList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const restirctedArtistList = createAsyncThunk(
  "restrictedArtistList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.restrictArtistList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const blockedArtistList = createAsyncThunk(
  "blockedArtistList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.blockedArtistList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const getSelectedGoupList = createAsyncThunk(
  "getgroupuserlist",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.selectedGroupList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const deleteList = createAsyncThunk(
  "deleteList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.collections.removeList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
