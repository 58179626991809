import React from "react";

const ButtonLoader = ({ color = "text-light", style = {} }) => {
  return (
    <div
      style={style}
      className={`${color} spinner-border`}
      text-light
      role="status"
    >
      <span class="sr-only" style={{ transform: "scale(0.8)" }}></span>
    </div>
  );
};

export default ButtonLoader;
