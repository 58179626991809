import React, { Fragment, useTransition } from "react";
import { useTranslation } from "react-i18next";
import Card2 from "../../../images/card2.png";
import { CiWallet } from "react-icons/ci";
import { useSelector } from "react-redux";
import moment from "moment";
import { BsCheckLg } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";

const Payments = () => {

  const { t } = useTranslation();
  const { transactionListt } = useSelector((s) => s.card);

  return (
    <>
      <div className="shadow1 rounded  border py-4 mt-3">
        <div className="px-4 pb-3 d-flex justify-content-between">
          <div>
            <h6 className="light-text mb-1 mx-0">
              {t("ALL TRANSACTIONS LIST")}
            </h6>
            <h6 className="light-xtr-sm">{t("Date")}</h6>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <svg
              className="mb-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M19.2899 2.75895C18.8601 2.71977 18.4801 3.03637 18.4409 3.46605L18.3374 4.60156C16.5812 1.84352 13.5326 0 10.0002 0C6.20016 0 2.8513 2.16883 1.18887 5.42398C0.992623 5.80824 1.14505 6.27883 1.5293 6.47508C1.91364 6.67133 2.38415 6.51895 2.5804 6.13465C3.98731 3.3798 6.81258 1.5625 10.0002 1.5625C12.9056 1.5625 15.5275 3.07543 17.0296 5.44563L15.9157 4.65004C15.5645 4.3993 15.0766 4.48063 14.8259 4.83172C14.5751 5.18285 14.6565 5.67074 15.0075 5.92152L18.3855 8.33406C18.8806 8.68699 19.5634 8.36391 19.6175 7.76926L19.9969 3.60793C20.0361 3.1782 19.7195 2.79813 19.2899 2.75895ZM18.4711 13.5249C18.0868 13.3287 17.6163 13.4811 17.42 13.8654C16.0131 16.6202 13.1878 18.4375 10.0002 18.4375C7.09485 18.4375 4.4729 16.9246 2.97079 14.5544L4.08473 15.35C4.43587 15.6007 4.92376 15.5194 5.17454 15.1683C5.42528 14.8171 5.34395 14.3293 4.99286 14.0785L1.61493 11.6659C1.1259 11.3166 0.437545 11.63 0.382858 12.2307L0.00352178 16.3921C-0.0356579 16.8218 0.280944 17.2018 0.710592 17.241C1.14083 17.2801 1.5204 16.9632 1.55954 16.5339L1.66305 15.3984C3.41922 18.1565 6.46782 20 10.0002 20C13.8002 20 17.1491 17.8312 18.8115 14.576C19.0078 14.1918 18.8554 13.7212 18.4711 13.5249Z"
                fill="black"
              />
            </svg>
            <h6 className="light-xtr-sm">{t("Amount")}</h6>
          </div>
        </div>
        <div className="endline"></div>
        <div className="storyTop">
        {transactionListt?.map((item, index) => {
          return (
            <Fragment>
              <div className=" py-3 px-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="text-sm2 mb-0">
                      {moment(item?.createdAt).format("MMMM DD , YYYY HH:MM")}
                    </h5>
                    {item?.paymentMethod === "card" && (
                      <div className="d-flex align-items-center">
                        <img src={item?.logo} alt="" style={{width : "40px" }}/>
                        <h6 className="text-xtr-sm mb-0 mx-3">
                          ···· ···· ···· {item?.lastd}
                        </h6>
                      </div>
                    )}
                    {item?.paymentMethod === "wallet" && (
                      <div className="d-flex align-items-center">
                        <CiWallet size={20} color="black" />
                        <h6 className="text-xtr-sm mb-0 mx-2">
                          {t("Wallet Credit")}
                        </h6>
                      </div>
                    )}
                  </div>
                  <h6 className="text-sm2">
                    {item?.currencySymbol} {item?.amount}
                  </h6>
                </div>
                <div className="endline my-2"></div>
                <div className="d-flex justify-content-between align-items-center mt-3 px-2">
                  {/* <h6 className="text-xtr-sm">{t("Wallet Top-Up For")} $100</h6> */}
                  {item?.transactionType === "Tips" && (
                    <h6 className="text-xtr-sm">
                      {t("Tip to")} {item?.userDetails?.name}
                    </h6>
                  )}
                  {item?.transactionType === "Profile" && (
                    <h6 className="text-xtr-sm">
                      {t("Added to Wallet")}
                    </h6>
                  )}
                  {item?.transactionType === "Posts" && (
                    <h6 className="text-xtr-sm">{t("Post Unlocked")}</h6>
                  )}
                  {item?.transactionType === "Messages" && (
                    <h6 className="text-xtr-sm">{t("Message Unlocked")}</h6>
                  )}
                  {item?.status === "success" && (
                    <BsCheckLg size={25} color={"#09BD3C"} />
                  )}
                  {item?.status === "failed" && (
                    <IoCloseSharp size={25} color={"#f5392c"} />
                  )}
                </div>
              </div>
              <div className="endline "></div>
            </Fragment>
          );
        })}
      </div>
      </div>
    </>
  );
};

export default Payments;
