import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import Earning from "./Earning";
import EarninginnerallReferalls from "./Referrals";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomDatefillter from "../../../CustomDatefillter";

const Statementtab = () => {
  const [state, setState] = useState({ tab: "Earnings" });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { t } = useTranslation();

  const location = useLocation();

  const [opendropdown, setopendropdown] = useState(false);
  const toggledropdown = () => {
    setopendropdown(!opendropdown);
  };

  useEffect(() => {
    if (location?.search === "?tab=referrals") {
      setState({ tab: "Referrals" });
    }
  }, [location]);

  return (
    <>
      {opendropdown && (
        <div className="backdrops" onClick={toggledropdown}></div>
      )}
      <section className="statement_tab my-3">
        <div className="tab-frame innner_tabs">
          <div className="clearfix commonBox border p-3">
            <input
              type="radio"
              name="tab"
              id="Earnings"
              checked={state.tab == "Earnings"}
              onClick={() => setState({ tab: "Earnings" })}
            />
            <label for="Earnings">{t("Earnings")}</label>

            <input
              type="radio"
              name="tab"
              id="Payout"
              checked={state.tab == "Payout"}
              onClick={() => setState({ tab: "Payout" })}
            />
            <label for="Payout">{t("Payout Requests")}</label>

            {/* <input
            type="radio"
            name="tab"
            id="Chargebacks"
            checked={state.tab == "Chargebacks"}
            onClick={() => setState({ tab: "Chargebacks" })}
          />
          <label for="Chargebacks">Chargebacks</label> */}

            <input
              type="radio"
              name="tab"
              id="Referrals"
              checked={state.tab == "Referrals"}
              onClick={() => setState({ tab: "Referrals" })}
            />
            <label for="Referrals">{t("Referrals")}</label>
          </div>
          <CustomDatefillter
            toggledropdown={toggledropdown}
            setStartDateValue={setStartDate}
            startDateValue={startDate}
            setEndDateValue={setEndDate}
            endDateValue={endDate}
          />
          <div className="earningtabing_wrapping card_boader mt-3 p-3">
            {state.tab == "Earnings" && (
              <Fade>
                <div className="my-3">
                  <Earning startDate={startDate} endDate={endDate} />
                </div>
              </Fade>
            )}

            {state.tab == "Payout" && (
              <Fade>
                <div className="">Subtab 2</div>
              </Fade>
            )}

            {/* {state.tab == "Chargebacks" && (
              <Fade>
                <div className="">Subtab 3</div>
              </Fade>
            )} */}

            {state.tab == "Referrals" && (
              <Fade>
                <div className="my-3">
                  <EarninginnerallReferalls />
                </div>
              </Fade>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Statementtab;
