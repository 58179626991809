import { createSlice } from "@reduxjs/toolkit";
import LegalInquiry from "../../../pages/common/legalInquiry";
import { status } from "../../constants";
import { helpSupport, legalEnquiry } from "./thunk";

const initialState = {
  loader: false,
  status: status.IDLE,
  data: null,
};

const slice = createSlice({
  name: "help and support",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(helpSupport.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = false;
    });
    builder.addCase(helpSupport.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.data = action.payload?.data;
    });
    builder.addCase(helpSupport.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.data = initialState.data;
    });

    builder.addCase(legalEnquiry.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(legalEnquiry.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(legalEnquiry.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
  },
});

export default slice.reducer;
