import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import EntertainerNewPost from "./EntertainerNewPost";
import UserNewPost from "./UserNewPost";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EntertainerActivity from "../../common/EntertainerActivity";
import ArtistSection from "../../common/ArtistSection/ArtistSection";

const NewPostComponent = () => {
  const { t } = useTranslation();
  const { role } = useSelector((s) => s.setting);

  const navigate = useNavigate();

  return (
    <Fragment>
      <Row className="  h-100 align-items-start justify-content-between ">
        <Col lg="7" md="12" sm="12">
          <div className="shadow1 rounded  border h-100 lg-px-5 py-4 border_hide">
            {role === "ARTIST" && <EntertainerNewPost />}
            {role === "USER" && <UserNewPost />}
          </div>
        </Col>
        <Col lg="5" md="12" sm="12" className="sticky-top">
          {role === "ARTIST" && <EntertainerActivity />}
          {role === "USER" && <ArtistSection />}
        </Col>
      </Row>
    </Fragment>
  );
};

export default NewPostComponent;
