import React, { useEffect, useState, useTransition } from "react";
import HomeUser from "./HomeUser";
import { useSelector } from "react-redux";
import HomeEntertainer from "./HomeEntertainer";

const HomeComponent = (props) => {
  const { role } = useSelector((s) => s.setting);
  return (
    <>
      {role === "ARTIST" && <HomeEntertainer {...props} />}
      {role === "USER" && <HomeUser {...props} />}
    </>
  );
};

export default HomeComponent;
