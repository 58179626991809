// import moment from "moment";

// export const getTimeAgo = (time) => {
//   const momentTime = moment.utc(time).local();
//   const now = moment();

//   // Check if the time is within a certain threshold (e.g., 5 seconds)
//   const thresholdSeconds = 5;
//   if (now.diff(momentTime, "seconds") < thresholdSeconds) {
//     return "now";
//   }

//   // If not within the threshold, use fromNow as usual
//   const newTime = momentTime.startOf("seconds").fromNow();
//   return newTime;
// };

import moment from "moment";

export const getTimeAgo = (time) => {
  const momentTime = moment.utc(time).local();
  const now = moment();

  // Check if the time is within a certain threshold (e.g., 5 seconds)
  const thresholdSeconds = 5;
  if (now.diff(momentTime, "seconds") < thresholdSeconds) {
    return "now";
  }

  // If not within the threshold, use fromNow as usual
  const newTime = momentTime.startOf("seconds").fromNow();

  // Replace "minutes" with "min"
  return newTime.replace("minutes", "min");
};
