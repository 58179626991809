import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function TagUserseList(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="Tagcreatormod_modal"
    >
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-between align-items-center add_heading px-3 pt-3">
          <div className="d-flex align-items-center msgTitle gap-10">
            <h5>{t("TAG CREATORS")}</h5>
          </div>
        </div>
        <div
          className="calednder_Mod"
          style={{ height: "400px", maxHeight: "400px", overflowY: "scroll" }}
        >
          {props?.data?.length === 0 && (
            <div className="no_mentions border-top border-5">
              <div className="innertag text-center">
                <h1>@</h1>
                <p>{t("No mentions or tags yet")}</p>
              </div>
            </div>
          )}

          {props?.data?.length > 0 && (
            <div
              style={{
                marginTop: "16px",
              }}
            >
              {props?.data?.map((user) => (
                <div
                  key={user._id}
                  className="user-container"
                  style={{ padding: "10px" }}
                  onClick={() => navigate(`/${user.userName}`)}
                >
                  <img
                    src={user.profileImage}
                    alt="Profile"
                    className="profile-image"
                  />
                  <div className="user-details">
                    <div className="user-name">
                      {user.name ? user.name : user.firstName}
                    </div>
                    <div className="username">@{user.userName}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="btns_twice d-flex align-items-center justify-content-between px-4 py-3 border-top">
          <div></div>
          <Button className="cancel_btn" onClick={props.onhide}>
            {t("CLOSE")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
