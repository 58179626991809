import React from "react";
import MainLayout from "../../../layout/MainLayout";
import SettingsComponent from "../../../components/SideTabs/Settings";

const Settings = ({ children }) => {
  return (
    <MainLayout>
      <SettingsComponent>{children}</SettingsComponent>
    </MainLayout>
  );
};

export default Settings;
