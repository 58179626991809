import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProgressBarModal = (props) => {
  const { loading, onhide, uploadedPercent } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
    
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <div className="modal_buy-cont">
            <h6 className=" text-normal m-0">{t("Uploading")}</h6>
            <hr class="custom_hr"></hr>
            <div className="pt-2">
              <p className="fw-sbold text-small my-3">
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    style={{ width: `${uploadedPercent}%` }}
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </p>
            </div>
            <p className="text-small">{t("Please Wait...")}</p>
            <div className="modal_buy_btn d-flex justify-content-end mt-4">
              <Button
                className="mod_confirmbtn blackBtn border-0"
                onClick={onhide}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProgressBarModal;
