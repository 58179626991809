// This is the normal post component ;

import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import LogoW from "../../../images/logoW.png";
import Post1 from "../../../images/post1.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import User5 from "../../../images/user3.png";
import { getTimeAgo } from "../../../helpers/time";
import ReactPlayer from "react-player";
import User from "../../../images/user.png";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPoll, addQuiz } from "../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DummmyImg from "../../../images/dummyBack.jpeg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWatermark from "../../../helpers/add-watermark";
import CustomVideoPlayer from "../../CustomVideoPlayer";
import { formatText } from "../../../helpers/formatPostText";

function SampleNextArrow(props) {
  const { className, style, onClick, storyimg, data } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.62 2.99a1.25 1.25 0 0 0-1.77 0L6.54 11.3a.996.996 0 0 0 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
        />
      </svg>
    </div>
  );
}

const PostViewer = (props) => {
  const { media, data } = props;
  const watermark = useWatermark();
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <div className="postCard Post">
        <div className="my-2 rounded overflow-hidden">
          <Slider
            {...settings}
            className="border-1 d-flex justify-content-center align-items-center"
          >
            {media?.map((mediaItem, index) => (
              <div className="inner">
                <div
                  className="PostMedia d-flex align-items-center position-relative"
                  style={{ aspectRatio: "1/1" }}
                >
                  {mediaItem?.type === "image" && (
                    <>
                      <div className="MediaBg position-absolute w-100 h-100">
                        <img
                          src={
                            watermark(
                              mediaItem?.url,
                              "fansroom.com/" + data?.userDetails?.userName
                            ) || mediaItem?.url
                          }
                          alt=""
                          className="rounded img-fluid w-100 h-100"
                        />
                      </div>
                      <div
                        className=" px-2 my-1 overflow-hidden rounded bg-transparent w-100 position-relative"
                        style={{ zIndex: "9" }}
                        key={index}
                      >
                        <img
                          src={
                            watermark(
                              mediaItem?.url,
                              "fansroom.com/" + data?.userDetails?.userName
                            ) || mediaItem?.url
                          }
                          alt=""
                          className="rounded img-fluid"
                        />
                      </div>
                    </>
                  )}
                  <>
                    {mediaItem?.type === "video" && (
                      //  <CustomVideoPlayer mediaItem={mediaItem} />
                      <ReactPlayer
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                        url={mediaItem?.url}
                        width={"100%"}
                        controls
                        volume={true}
                        className="custom-player"
                      />
                    )}
                  </>
                  <>
                    {mediaItem?.type === "audio" && (
                      <audio src={mediaItem?.url} controls />
                    )}
                  </>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

const QueuePostSection = (props) => {
  const {
    data,
    key,
    getPostLists,
    handleDelete,
    handleEdit,
    handlePublishPost,
  } = props;
  const media = data?.media;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handlePollSubmit = (poll, post) => {
    const data = {
      postId: post?._id,
      optionId: poll?._id,
    };
    dispatch(addPoll(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleQuizSubmit = (quiz, post) => {
    const data = {
      postId: post?._id,
      optionId: quiz?._id,
    };
    dispatch(addQuiz(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getPostLists();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <div className="postCard" key={key}>
      {data && (
        <>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <div className="d-flex align-items-center">
              <img src={LogoW} alt="" className="postUserPic" />
              <div className="mx-3 fansDetails">
                <h6 className="text-bold mb-0 ">{t("Fansroom")}</h6>
                <h6 className="text-small mb-0">@fansroom23</h6>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="text-small mx-2 mb-0">
                {getTimeAgo(data?.createdAt)}
              </h6>

              <div className="three_dot_dropdown">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                        <path
                          d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                          fill="black"
                        />
                        <path
                          d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                          fill="black"
                        />
                        <path
                          d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_87_10716">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handlePublishPost(data)}>
                      {t("Publish Post")}
                    </DropdownItem>
                    <DropdownItem onClick={() => handleEdit(data)}>
                      {t("Edit Post")}
                    </DropdownItem>
                    <DropdownItem onClick={() => handleDelete(data)}>
                      {t("Delete Post")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>

          <div className="PostQuiz shadow1 p-3 mb-3">
            <div className="my-2 top">
              <h6 className="m-0 fw-bold">
                {data?.caption}
                {formatText(data?.description)}
              </h6>
            </div>
            {data?.postType === "quiz" && (
              <div className="my-2">
                {data?.quizOption?.map((option, index) => {
                  var totalValue = 0;
                  const quizValue = option?.quizCount || 0;
                  totalValue = totalValue + quizValue;
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative"
                    >
                      <input
                        type="radio"
                        className="position-absolute w-100 h-100"
                        name="quiz"
                        checked={option?.isSelected}
                        id={option?.option}
                        onChange={() => handleQuizSubmit(option, data)}
                      />
                      <label
                        htmlFor={option?.option}
                        className=" label px-3 py-2 d-block w-100 position-relative"
                      >
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            {data?.postType === "poll" && (
              <div className="mb-2">
                {data?.pollOption?.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className="py-1 d-flex align-items-center position-relative "
                    >
                      <input
                        type="radio"
                        className="position-absolute text-white w-100 h-100"
                        name="poll"
                        id={option?.option}
                        onChange={() => handlePollSubmit(option, data)}
                        checked={option?.isSelected}
                      />
                      <label
                        className=" label px-3 py-2 text-white d-block w-100 position-relative"
                        htmlFor={option?.option}
                      >
                        <span
                          className="fill position-absolute w-100 h-100"
                          style={{ maxWidth: option?.option }}
                        ></span>
                        {option?.option}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {media?.length !== 0 && (
            <div className="GallaryMedia">
              <PostViewer media={media} data={data} />
            </div>
          )}

          <div className="my-4 endline full-width"></div>
        </>
      )}
    </div>
  );
};

export default QueuePostSection;
