// This is the normal post component ;

import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BsBookmark, BsFillSendFill, BsFillBookmarkFill } from "react-icons/bs";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import User1 from "../../../images/user1.svg";
import LogoW from "../../../images/logoW.png";
import Post1 from "../../../images/post1.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import User5 from "../../../images/user3.png";
import { getTimeAgo } from "../../../helpers/time";
import User from "../../../images/user.png";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bookmarkPost,
  commentPost,
  likePost,
  unlikePost,
} from "../../../redux/actions";
import { PiDotsThreeCircleVerticalBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { BiSolidSend } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import DummmyImg from "../../../images/dummyBack.jpeg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CgRemove, CgUnblock } from "react-icons/cg";
import { BiBlock } from "react-icons/bi";
import { MdAddLink } from "react-icons/md";
import { VscVerified } from "react-icons/vsc";
import SendTip from "../../common/Modal/SendTip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommentSection from "../../common/CommentSection/CommentSection";
import PostViewer from "../../common/PostViewer/PostViewer";
import Heart from "../../../images/heart.svg";
import FilledHeart from "../../../images/filledHeart.svg";
import Bookmark from "../../../images/bookmark.svg";
import FilledBookmark from "../../../images/filledBookmark.svg";
import Tip from "../../../images/dollar.svg";
import Comment from "../../../images/comment.svg";
import { VscVerifiedFilled } from "react-icons/vsc";
import DummyPost from "../../common/DummyPost";

const PostComponent = (props) => {
  const [showActionBox, setShowActionBox] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);

  const { data, key, commonCall, getPostLists, showProfileBanners } = props;
  const userDetials = data?.userDetails;
  const userId = useSelector((s) => s.setting)?.user?._id;
  const [msg, setMsg] = useState("");
  const [showOptionBox, setShowOptionBox] = useState(false);
  const commentBoxRef = useRef();
  const media = data?.media;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUserDetails = (dta) => {
    if (dta?.userDetails?._id) {
      const userDetails = dta?.userDetails;
      const path = generatePath("/:slug", {
        slug: userDetails?.userName,
      });
      navigate(path);
    }
  };

  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post liked"));
        commonCall();
      })
      .catch((err) => {});
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post unliked"));
        commonCall();
      })
      .catch((err) => {});
  };

  const handleBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Post Bookmarked"));
        commonCall();
      })
      .catch((err) => {});
  };

  const handleUnBookmarkPost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(bookmarkPost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Removed from bookmark"));
        commonCall();
      })
      .catch((err) => {});
  };

  var isLiked = false;
  var isBookmarked = false;

  const bookmarkdta = data?.bookmarks?.find((item, id) => {
    return item?.userId == userId;
  });

  const userDta = data?.likes?.find((item, id) => {
    return item?.userId == userId;
  });

  if (userDta?.userId) {
    isLiked = true;
  } else {
    isLiked = false;
  }

  if (bookmarkdta?.userId) {
    isBookmarked = true;
  } else {
    isBookmarked = false;
  }

  const sendMsg = (dta) => {
    if (msg === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: msg,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          setMsg("");
          toast.success(t("comment posted"));
          commonCall();
        })
        .catch((err) => {
          toast.error(err.message);
          setMsg("");
        });
    }
  };

  const baseUrl = window.location.protocol + "//" + window.location.host;

  const handleKeyPress = (event, dta) => {
    if (event.key === "Enter") {
      sendMsg(dta);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  const clickOutside = (e) => {
    if (!commentBoxRef.current?.contains(e.target)) {
      setShowCommentBox(false);
    } else {
      setShowCommentBox(true);
    }
  };

  const [showCommentPop, setCommentPop] = useState(false);

  const handleCommentPop = () => setCommentPop(!showCommentPop);
  const [showSendTip, setShowSendTip] = useState(false);
  const [selectedPostForTip, setSelectedPostForTip] = useState({});

  const handleTip = (dta) => {
    setSelectedPostForTip(dta);
    setShowSendTip(true);
  };

  return (
    <div key={key}>
      <CommentSection
        showCommentPop={showCommentPop}
        handleCommentPop={handleCommentPop}
        commentBoxRef={commentBoxRef}
        msg={msg}
        setMsg={setMsg}
        setCommentPop={setCommentPop}
        data={data}
        handleKeyPress={handleKeyPress}
        sendMsg={sendMsg}
      />
      <SendTip
        show={showSendTip}
        onHide={() => setShowSendTip(false)}
        data={selectedPostForTip}
      />
      {data && (
        <>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <div className="d-flex align-items-center">
              <img
                src={data?.userDetails?.profileImage || User}
                alt=""
                className="postUserPic"
              />
              <div className="mx-3 fansDetails">
                <h6 className="text-bold mb-0 ">{data?.userDetails?.name}</h6>
                <h6 className="text-small mb-0">
                  {data?.userDetails?.userName}
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="text-small mx-2 mb-0">
                {getTimeAgo(data?.createdAt)}
              </h6>

              <div className="three_dot_dropdown">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_87_10716)">
                        <path
                          d="M14 9.00002C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9.00002C18 7.89544 17.1046 7 16 7C14.8954 7 14 7.89544 14 9.00002Z"
                          fill="currentColor"
                        />
                        <path
                          d="M6.99997 9.00002C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 9.00002C11 7.89544 10.1046 7 8.99998 7C7.89541 7 6.99997 7.89544 6.99997 9.00002Z"
                          fill="currentColor"
                        />
                        <path
                          d="M-3.20355e-05 9.00002C-3.20838e-05 10.1046 0.895405 11 1.99998 11C3.10456 11 4 10.1046 4 9.00002C4 7.89544 3.10456 7 1.99998 7C0.895405 7 -3.19872e-05 7.89544 -3.20355e-05 9.00002Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_87_10716">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="list-unstyled ps-0 mb-0">
                      <li>
                        <CopyToClipboard
                          text={baseUrl + "/post-details/" + data?.slug}
                          onCopy={() =>
                            toast.success(
                              t("Link to post has been copied to clipboard!")
                            )
                          }
                        >
                          <DropdownItem>
                            <span>{t("Copy Link to post")}</span>
                          </DropdownItem>
                        </CopyToClipboard>
                      </li>
                      {/* <li>
                        <DropdownItem>
                          {t("Add to / Remove from List")}
                        </DropdownItem>
                      </li>*/}
                    </ul>
                    <ul className="list-unstyled ps-0 mb-0">
                      {data?.isLiked ? (
                        <li>
                          <DropdownItem onClick={() => handleUnLikePost(data)}>
                            {t("I don't like this post")}
                          </DropdownItem>
                        </li>
                      ) : (
                        <li>
                          <DropdownItem onClick={() => handleLikePost(data)}>
                            {t("I like this post")}
                          </DropdownItem>
                        </li>
                      )}
                      {/* <li>
                        <DropdownItem>
                          {t("Show user's posts in feed")}
                        </DropdownItem>
                      </li> */}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className="px-3 my-3">
            <h6 className="text-small" style={{ fontSize: "15px" }}>
              {data?.caption}
            </h6>
          </div>
          <PostViewer media={media} data={data}/>

          {showProfileBanners && (
            <div className=" w-100 lg-px-2 my-1 position-relative mainPost">
              <img
                src={
                  userDetials?.coverImage ? userDetials?.coverImage : DummmyImg
                }
                alt=""
                className="rounded object-cover"
                style={{ height: "150px" }}
              />
              <div
                className="position-absolute postUserDetails pointer "
                onClick={() => handleUserDetails(data)}
              >
                <img
                  src={
                    userDetials?.profileImage ? userDetials?.profileImage : User
                  }
                  alt=""
                  className="img-fliud"
                />
              </div>
              <div
                className="postUserText pointer"
                onClick={() => handleUserDetails(data)}
              >
                <div>
                  <h6 className="capitalize text-small ">
                    {userDetials?.name}{" "}
                    <VscVerifiedFilled
                      color="var(--primaryDarkColor)"
                      size={23}
                      className="verifiedIcons mx-2 rounded-circle"
                    />
                  </h6>
                  <h6>{userDetials?.userName}</h6>
                </div>
              </div>
              <Dropdown
                className="position-absolute"
                style={{ right: "10px", top: "10px" }}
              >
                <Dropdown.Toggle
                  variant="transaprent"
                  className="border-0 p-0"
                  id="dropdown-basic"
                >
                  <BiDotsVerticalRounded
                    color="white"
                    size={18}
                    className="threeDots"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <ul className="list-unstyled ps-0 mb-0">
                    <li>
                      <Dropdown.Item onClick={() => handleUserDetails(data)}>
                        {t("View Artist Details")}
                      </Dropdown.Item>
                    </li>
                    <li>
                      <CopyToClipboard
                        text={baseUrl + "/" + userDetials?.userName}
                      >
                        <Dropdown.Item>
                          <span>{t("Copy Link to profile")}</span>
                        </Dropdown.Item>
                      </CopyToClipboard>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div className="postaction_wrap my-2 d-flex justify-content-between align-items-center mt-4 mb-3 position-relative">
            <div className="d-flex align-items-center postaction">
              <span>
                {data?.isLiked ? (
                  <img
                    src={FilledHeart}
                    className="actionIcon"
                    onClick={() => handleUnLikePost(data)}
                  />
                ) : (
                  <img
                    src={Heart}
                    className="actionIcon"
                    onClick={() => handleLikePost(data)}
                  />
                )}
              </span>
              <span onClick={handleCommentPop} className="pointer tagcol">
                <img src={Comment} className="actionIcon" />
              </span>

              <span className="pointer" onClick={() => setShowSendTip(true)}>
                <img src={Tip} className="actionIcon" />
              </span>
              <span>{t("SEND TIPS")} </span>
            </div>
            <div className="">
              {data?.isBookmarked ? (
                <img
                  src={FilledBookmark}
                  className="actionIcon"
                  onClick={() => handleUnBookmarkPost(data)}
                />
              ) : (
                <img
                  src={Bookmark}
                  className="actionIcon"
                  onClick={() => handleBookmarkPost(data)}
                />
              )}
            </div>
          </div>
          {showCommentBox && (
            <div
              className="commentSection rounded shadow2 mb-3"
              ref={commentBoxRef}
            >
              <div
                className="close-chat"
                onClick={() => setShowCommentBox(false)}
              >
                <AiOutlineCloseCircle size={17} />
              </div>
              <div className="d-flex justify-content-between px-3">
                <input
                  type="text"
                  className="inputComment"
                  onChange={(e) => setMsg(e.target.value)}
                  value={msg}
                  placeholder={t("Write me a comment")}
                  onKeyDown={(e) => handleKeyPress(e, data)}
                />
                <div
                  onClick={() => sendMsg(data)}
                  className="rounded-circle d-flex justify-content-center align-items-center chatIcn shadow2"
                >
                  <BiSolidSend size={20} color />
                </div>
              </div>
              <div className="px-3 py-2 commentList">
                {data?.comments?.map((comment, index) => {
                  const userDetails = comment?.userDetails;
                  return (
                    <div className="commentItem mb-2">
                      <div className="commentUser">
                        <img src={userDetails?.profileImage || User} alt="" />
                      </div>
                      <div className="commentUserDtls">
                        <h6 className="mb-1">{userDetails?.userName}</h6>
                        <h6 className="contnent">{comment?.comment}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="d-flex">
            <div className="light-text">
              {data?.likes?.length} {t("Likes")}
            </div>
            <div className="light-text mx-2">
              {data?.comments?.length} {t("comments")}
            </div>
          </div>
          <div className="my-4 endline w-100"></div>
        </>
      )}
    </div>
  );
};

export default PostComponent;
