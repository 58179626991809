import React, { useEffect } from "react";
import User1 from "../../../images/user13.png";
import User2 from "../../../images/user14.png";
import User3 from "../../../images/user15.png";
import { useDispatch, useSelector } from "react-redux";
import { allBookMarkedPost } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

const Bookmarks = (props) => {
  const { t } = useTranslation();
  const { setPostList } = props;
  const { bookmarkList } = useSelector((s) => s.collection);
  const dispatch = useDispatch();
  const { user, buttonLoader } = useSelector((s) => s.setting);

  const getAllBookmarkedPost = () => {
    const data = {
      limit: 100,
      page: 1,
      userId: user?._id,
    };
    dispatch(allBookMarkedPost(data));
  };

  useEffect(() => {
    getAllBookmarkedPost();
  }, []);

  useEffect(() => {
    const data = bookmarkList?.map((itm) => {
      return itm?.postDetails;
    });
    const flattenedData = [].concat.apply([], data);
    setPostList(flattenedData);
  }, [bookmarkList]);
  
  return (
    <div className="px-3 py-1">
      <div className="d-flex justify-content-between align-items-center px-2">
        <h5 className="text-custom">{t("RECENT")}</h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g opacity="0.5">
              <path
                d="M7.77778 16.6673H12.2222V14.4451H7.77778V16.6673ZM0 3.33398V5.55621H20V3.33398H0ZM3.33333 11.1118H16.6667V8.88954H3.33333V11.1118Z"
                fill="black"
              />
            </g>
          </svg>
        </h5>
      </div>
      <div
        className="my-2 px-3 py-2 d-flex justify-content-between align-items-center shadow2"
        onClick={getAllBookmarkedPost}
      >
        <div className="custmUserbox ">
          <h6 className="text-small mb-0">{t("All bookmarks")}</h6>
          <h6 className="mb-0">
            <span>
              {bookmarkList?.length} {t("posts")}{" "}
            </span>
          </h6>
        </div>
        <div className="cstomImgbox"></div>
      </div>
    </div>
  );
};

export default Bookmarks;
