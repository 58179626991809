import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AuthFooter from "../../../components/Footer";
import Logo from "../../../images/logoW.png";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "../../../redux/actions";
import axios from "axios";
import { endpoints } from "../../../endpoints";
import { REACT_APP_API_BASE_URL, REACT_APP_API_VERSION } from "../../../config";
import parse from "html-react-parser";
import Fullpageloader from "../../../components/common/Fullpageloader";
import { LegalInquiryForm } from "../legalInquiry";
import UserDetails from "../../UserDetails/UserDetails";

const pageMap = {
  "cookie-policy": {
    id: "651bbe1f91533c139382829b",
  },
  terms: {
    id: "64f5aafae78268f792d1e1c8",
  },
  privacy: {
    id: "64f5ab54e78268f792d1e1c9",
  },
  antitraffickingstatement: {
    id: "6554b175291e1e7ec55e9ade",
  },
  contract: {
    id: "6554b18a291e1e7ec55e9b85",
  },
  dmca: {
    id: "651bc1f591533c139382829d",
  },
  USC: {
    id: "651bc29d91533c139382829e",
  },
  about: {
    id: "651bba3b91533c1393828298",
  },
  legalguide: {
    id: "655608d8291e1e7ec5699bc4",
    showLegalInquiryForm: true,
  },
  "transparency-center": {
    id: "651bd6bf91533c139382829f",
  },
  values: {
    id: "657b037e291e1e7ec5966f69",
  },
  payouts: {
    id: "6582c44a291e1e7ec5d6b8a9",
  },
  "release-policy": {
    id: "658431a9291e1e7ec5e273f2",
  },
};

function ContentPage() {
  const API_URL = REACT_APP_API_BASE_URL;
  const API_VERSION = REACT_APP_API_VERSION;
  const baseURL = API_URL + API_VERSION;

  const navigate = useNavigate();
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const getContentData = () => {
    setLoading(true);
    const url = fetch(
      baseURL + endpoints.common.getContent + pageMap[slug].id,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setData({ ...result.data, slug });
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!!pageMap[slug]?.id) {
      getContentData();
    }
  }, [slug]);

  if (!pageMap[slug]?.id) {
    return <UserDetails />;
    // return <Navigate to="/" />;
  }

  if (loading) {
    return <Fullpageloader />;
  }

  if (!data && !loading) {
    return <Navigate to="/" />;
  }

  if (data?.slug !== slug) {
    return null;
  }

  return (
    <section className="CookiesPolicy privay_poilicy position-relative p-0">
      <div className="header_only_logo py-3 px-4">
        <div className="logo_box">
          <Link to="/">
            <img src={Logo} alt="" className="img-fluid" />
          </Link>
        </div>s
      </div>
      <Container fluid>
        <Row>
          <Col lg="12" className="my-2 sticky-top bg-white">
            <div className="top border-bottom pb-2">
              <h4 className="m-0 py-3 fw-bold">{data && data.name}</h4>
            </div>
          </Col>
          <Col lg="12" className="my-2">
            <div className="py-4 content_pages_text">
              {data && parse(data.content)}
            </div>
          </Col>
        </Row>
        {pageMap[slug]?.showLegalInquiryForm && <LegalInquiryForm />}
        <AuthFooter />
      </Container>
    </section>
  );
}

export default ContentPage;
