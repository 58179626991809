import React, { useState } from "react";
import "../../../css/style.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { login, signUp } from "../../../redux/actions";
import { toast } from "react-toastify";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import SocialLogin from "../SocialLogin/SocialLogin";
import { useLocation } from "react-router-dom";

const SignupComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { buttonLoader } = useSelector((s) => s.login);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userName = queryParams.get("userName");
  const link = queryParams.get("link");

  const noNumbersRegex = /^[^\d]+$/; // Regular expression to match strings without numbers

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      loginType: "email",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(noNumbersRegex, {
          message: "First name should not contain numbers",
        })
        .required("First name is required")
        .min(3, "First name should be at least 3 character long")
        .max(24, "First name should not exceed 24 characters"),

      lastName: Yup.string()
        .matches(noNumbersRegex, {
          message: "Last name should not contain numbers",
        })
        .required("Last name is required")
        .min(3, "Last name should be at least 3 character long")
        .max(24, "Last name should not exceed 24 characters"),
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid email")),
      password: Yup.string()
        .required(t("Password is required"))
        .min(3, t("Password must be greater than 3 character")),
      confirmPassword: Yup.string()
        .required(t("Confirm Password isn't matched with password"))
        .oneOf([Yup.ref("password"), null], t("Password does not match"))
        .min(3, t("Password must be greater than 3 character")),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(signUp(values))
        .unwrap()
        .then((res) => {
          const data = {
            email: values.email,
            password: values.password,
            loginType: "email",
          };
          dispatch(login(data))
            .unwrap()
            .then((res) => {
              if (res) {
                if (typeof state?.redirectTo === "string") {
                  navigate(state?.redirectTo);
                } else if (link) {
                  navigate(link);
                } else if (userName) {
                  navigate(`/${userName}`);
                } else {
                  navigate("/home");
                }
                // toast.success(res.message);
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });
  return (
    <Container className=" w-100 px-lg-5 py-lg-4 d-flex justify-content-center px-0">
      <Row className="loginCont">
        <Col lg="12" md="12" className="">
          <h3 className="text-black text-medium mb-2">
            {t("Create your account")}
          </h3>
          <div className="Login_input_warp form-floating">
            <input
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder="First name"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            <label for="floatingInput">{t("First Name")}</label>

            {formik.touched.firstName && formik.errors.firstName && (
              <span className="text-danger errorText">
                {formik.errors.firstName}
              </span>
            )}
          </div>
          <div className=" mt-lg-3 mt-2 Login_input_warp form-floating">
            <input
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder="Last name"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            <label for="floatingInput">{t("Last Name")}</label>

            {formik.touched.lastName && formik.errors.lastName && (
              <span className="text-danger errorText">
                {formik.errors.lastName}
              </span>
            )}
          </div>
          <div className="mt-lg-3 mt-2 Login_input_warp form-floating">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <label for="floatingInput">{t("Email")}</label>
            {formik.touched.email && formik.errors.email && (
              <span className="text-danger errorText">
                {formik.errors.email}
              </span>
            )}
          </div>
          <div className="mt-lg-3 mt-2 Login_input_warp form-floating">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control loginInputPassword"
              id="floatingInput"
              placeholder={t("Password")}
            />
            <label for="floatingInput">{t("Password")}</label>
            <div className="eyeIcon">
              {showPassword ? (
                <AiOutlineEye
                  color=""
                  size={23}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  color=""
                  size={23}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
          </div>

          {formik.touched.password && formik.errors.password && (
            <span className="text-danger errorText">
              {formik.errors.password}
            </span>
          )}
          <div className="mt-lg-3 mt-2 Login_input_warp form-floating">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control loginInputPassword"
              id="floatingInput"
              placeholder={t("Confirm Password")}
            />
            <label for="floatingInput"> {t("Confirm Password")}</label>
            <div className="eyeIcon">
              {showConfirmPassword ? (
                <AiOutlineEye
                  color=""
                  size={23}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  color=""
                  size={23}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
          </div>

          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <span className="text-danger errorText">
              {formik.errors.confirmPassword}
            </span>
          )}
          <div className="mt-lg-3 mt-2">
            {buttonLoader ? (
              <button
                onClick={formik.handleSubmit}
                className="loginBtn2 text-light "
              >
                <ButtonLoader />
              </button>
            ) : (
              <button
                onClick={formik.handleSubmit}
                className={` text-light ${
                  !formik.isValid || !formik.dirty
                    ? "disabled-btn"
                    : "loginBtn2"
                }`}
              >
                {t("Sign Up")}
              </button>
            )}
          </div>
          <div className="forgot_pass mt-lg-3 mt-2 px-lg-4 d-flex justify-content-center flex-column align-items-center">
            <h4 className="privacy_links text-black text-small">
              {t("By Signing up you agree to our")}{" "}
              <Link to="/terms" className="text-dark">
                {t("Terms of Service")}
              </Link>
              ,
              <Link to="/privacy" className="text-dark">
                {" "}
                {t("Privacy Policy")}
              </Link>
              ,
              <Link to="/payouts" className="text-dark">
                {" "}
                {t("Payouts Policy")}
              </Link>
              , {t("and confirm that you are atleast 18 years old.")}
            </h4>
            <h4 className="text-black text-normal mt-lg-4 mt-2">
              {t("Already have an account?")}{" "}
              <b onClick={() => navigate("/")} className="pointer">
                {t("Log In")}
              </b>
            </h4>
          </div>
          {/* <SocialLogin /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default SignupComponent;
