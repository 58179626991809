import { createSlice } from "@reduxjs/toolkit";
import {
  contactUs,
  getPrivacyPolicy,
  getTermsCondition,
  planList,
  uploadFile,
  verifyEmail,
  fetchFaq,
  campaignDetail,
  announcementList,
  announcementRead,
  subscriptionDetails,
} from "./thunk";
import { status } from "../../constants";

const initialState = {
  imageLoader: false,
  status: status.IDLE,
  privacyPolicy: null,
  loader: false,
  termsCondition: null,
  planListt: [],
  buttonLoader: false,
  faqList: null,
  campaignDetail: null,
  announcementList: [],
  announcementRead: null,
  subscriptionData: null,
};

const slice = createSlice({
  name: "uploadImg",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (state, action) => {
      state.status = status.LOADING;
      state.imageLoader = true;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.imageLoader = false;
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.status = status.FAILED;
      state.imageLoader = false;
    });
    builder.addCase(getPrivacyPolicy.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getPrivacyPolicy.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.privacyPolicy = action?.payload?.data;
    });
    builder.addCase(getPrivacyPolicy.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.privacyPolicy = initialState.privacyPolicy;
    });
    builder.addCase(getTermsCondition.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getTermsCondition.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.loader = false;
      state.termsCondition = action.payload?.data;
    });
    builder.addCase(getTermsCondition.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.termsCondition = initialState?.termsCondition;
    });
    builder.addCase(planList.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(planList.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.loader = false;
      state.planListt = action.payload?.data;
    });
    builder.addCase(planList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.planListt = initialState?.planListt;
    });
    builder.addCase(verifyEmail.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(contactUs.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(contactUs.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.buttonLoader = false;
    });
    builder.addCase(contactUs.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(fetchFaq.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(fetchFaq.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.faqList = action.payload.data;
    });
    builder.addCase(fetchFaq.rejected, (state, action) => {
      state.status = status.FAILED;
      state.faqList = null;
    });
    builder.addCase(campaignDetail.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(campaignDetail.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.campaignDetail = action.payload.data;
    });
    builder.addCase(campaignDetail.rejected, (state, action) => {
      state.status = status.FAILED;
      state.campaignDetail = null;
    });

    builder.addCase(announcementList.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(announcementList.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.announcementList = action.payload.data.result;
    });
    builder.addCase(announcementList.rejected, (state, action) => {
      state.status = status.FAILED;
      state.announcementList = null;
    });

    builder.addCase(announcementRead.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(announcementRead.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.announcementRead = action.payload.data;
    });
    builder.addCase(announcementRead.rejected, (state, action) => {
      state.status = status.FAILED;
      state.announcementRead = null;
    });

    builder.addCase(subscriptionDetails.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(subscriptionDetails.fulfilled, (state, action) => {
      state.status = status.SUCCESS;
      state.subscriptionData = action.payload.data;
    });
    builder.addCase(subscriptionDetails.rejected, (state, action) => {
      state.status = status.FAILED;
      state.subscriptionData = null;
    });
  },
});

export default slice.reducer;
