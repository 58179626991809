import { status } from "../../constants";
import { createSlice } from "@reduxjs/toolkit";
import {
  addStoryPromotion,
  addSubscriptionBundle,
  addTrail,
  connectOtherAccount,
  deleteAccount,
  getProfile,
  handlePublicPrivate,
  handleSubscribePrice,
  removePromtion,
  removeSubscriptionBundle,
  removeTrail,
  stopPromotion,
  trailListing,
  updateEntertainerSetting,
  updateNotification,
  updateProfile,
  verifictionWithEmail,
  verifyEmailOtpForAuthentication,
  verifyPhoneForSms,
} from "./thunk";

const initialState = {
  user: {},
  status: status.IDLE,
  showLoader: false,
  buttonLoader: false,
  role: "",
  trailList: [],
};

const slice = createSlice({
  name: "settings",
  initialState: { ...initialState },
  reducers: {
    removeUser: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfile.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(handlePublicPrivate.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(handlePublicPrivate.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(handlePublicPrivate.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });

    builder.addCase(getProfile.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showLoader = false;
      state.user = action.payload?.data?.[0];
      state.role = action.payload?.data?.[0]?.role;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showLoader = false;
      state.user = initialState.user;
      state.role = initialState.role;
    });
    builder.addCase(connectOtherAccount.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(connectOtherAccount.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(connectOtherAccount.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(deleteAccount.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(verifictionWithEmail.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(verifictionWithEmail.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
    });
    builder.addCase(verifictionWithEmail.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
    });
    builder.addCase(
      verifyEmailOtpForAuthentication.pending,
      (state, action) => {
        state.status = status.LOADING;
        state.buttonLoader = true;
      }
    );
    builder.addCase(
      verifyEmailOtpForAuthentication.fulfilled,
      (state, action) => {
        state.status = status.SUCCEDED;
        state.buttonLoader = false;
      }
    );
    builder.addCase(
      verifyEmailOtpForAuthentication.rejected,
      (state, action) => {
        state.status = status.FAILED;
        state.buttonLoader = false;
      }
    );
    builder.addCase(verifyPhoneForSms.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(verifyPhoneForSms.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(verifyPhoneForSms.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(updateNotification.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(updateNotification.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(updateNotification.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(updateEntertainerSetting.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(updateEntertainerSetting.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(updateEntertainerSetting.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(handleSubscribePrice.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(handleSubscribePrice.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(handleSubscribePrice.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addSubscriptionBundle.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addSubscriptionBundle.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addSubscriptionBundle.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(removeSubscriptionBundle.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(removeSubscriptionBundle.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(removeSubscriptionBundle.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addTrail.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addTrail.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addTrail.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(trailListing.pending, (state, action) => {
      state.status = status.LOADING;
    });
    builder.addCase(trailListing.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.trailList = action.payload?.data;
    });
    builder.addCase(trailListing.rejected, (state, action) => {
      state.status = status.FAILED;
      state.trailList = initialState.trailList;
    });
    builder.addCase(removeTrail.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(removeTrail.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(removeTrail.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(addStoryPromotion.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(addStoryPromotion.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(addStoryPromotion.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(stopPromotion.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(stopPromotion.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(stopPromotion.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
    builder.addCase(removePromtion.pending, (state, action) => {
      state.status = status.LOADING;
      state.buttonLoader = true;
    });
    builder.addCase(removePromtion.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.buttonLoader = false;
    });
    builder.addCase(removePromtion.rejected, (state, action) => {
      state.status = status.FAILED;
      state.buttonLoader = false;
    });
  },
});

export default slice.reducer;
export const removeUser = slice.actions;
