import React, { useState, useEffect } from "react";
import { Tab, Nav, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { formatText } from "../../../../../helpers/formatPostText";
import Statistics from "../../../../common/Modal/Statistics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${value?.toFixed(2)}`;
        },
      },
    },
  },
};

const Posts = (props) => {
  const [tab, setTab] = useState("Purchases");
  const { t } = useTranslation();
  const { engagementData } = useSelector((s) => s.statistics);
  const [labelData, setLabelData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [postStats, setPostStats] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const updateGraph = (tab) => {
    if (tab === "Purchases") {
      const ddd = engagementData?.records?.map((item) => {
        return item?.buyersCount;
      });
      setGraphData(ddd);
    } else if (tab === "Tips") {
      const ddd = engagementData?.records?.map((item) => {
        return item?.tipsCount;
      });
      setGraphData(ddd);
    } else if (tab === "Views") {
      const ddd = engagementData?.records?.map((item) => {
        return item?.viewersCount;
      });
      setGraphData(ddd);
    } else if (tab === "Likes") {
      const ddd = engagementData?.records?.map((item) => {
        return item?.likesCount;
      });
      setGraphData(ddd);
    } else if (tab === "Comments") {
      const ddd = engagementData?.records?.map((item) => {
        return item?.commentCount;
      });
      setGraphData(ddd);
    }
    const allDate = engagementData?.records?.map((itm) => {
      return itm?._id;
    });
    setLabelData(allDate);

    const posst = engagementData?.records?.map((itm) => {
      return itm?.postCount;
    });
    setPostData(posst);
  };

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
    updateGraph(tabs);
  };

  useEffect(() => {
    handleTabSelectedtion("Purchases");
  }, [engagementData]);

  const data = {
    labels: labelData,
    datasets: [
      {
        label: tab,
        data: graphData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "posts",
        data: postData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <section className="Earninginnerall_tab">
      <div className="common_table statearning_table alltime_table mt-4 border-0">
        <div className="postEngage">
          <div className="CardBody py-1  innner_tabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="Userlist"
              className="d-flex justify-content-between align-items-center"
            >
              <Nav variant="pills" className="">
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-small"
                    eventKey="Purchases"
                    onClick={() => handleTabSelectedtion("Purchases")}
                    active={tab === "Purchases"}
                  >
                    {t("Purchases")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-small "
                    eventKey="Tips"
                    onClick={() => handleTabSelectedtion("Tips")}
                    active={tab === "Tips"}
                  >
                    {t("Tips")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-small "
                    eventKey="Views"
                    onClick={() => handleTabSelectedtion("Views")}
                    active={tab === "Views"}
                  >
                    {t("Views")}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-small "
                    eventKey="Likes"
                    onClick={() => handleTabSelectedtion("Likes")}
                    active={tab === "Likes"}
                  >
                    {t("Likes")}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="">
                  <Nav.Link
                    className="bg-transparent border-0 position-relative text-small "
                    eventKey="Comments"
                    onClick={() => handleTabSelectedtion("Comments")}
                    active={tab === "Comments"}
                  >
                    {t("Comments")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
          {engagementData?.records?.length > 0 ? (
            <Tab.Content className="">
              <Tab.Pane eventKey="Purchases" active={tab === "Purchases"}>
                <div className="my-3 w-full">
                  <Line options={options} data={data} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Tips" active={tab === "Tips"}>
                <div className="my-3  w-full">
                  <Line options={options} data={data} />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="Views" active={tab === "Views"}>
                <div className="my-3  w-full">
                  <Line options={options} data={data} />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="Likes" active={tab === "Likes"}>
                <div className="my-3  w-full">
                  <Line options={options} data={data} />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="Comments" active={tab === "Comments"}>
                <div className="my-3  w-full">
                  <Line options={options} data={data} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          ) : (
            <div className="noGraphData">
              {t("No data during selected period")}
            </div>
          )}

          {engagementData?.total?.posts?.length > 0 && (
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{t("Date")}</th>
                    <th>{t("Views")}</th>
                    <th>{t("Tips")}</th>
                    <th>{t("Price")}</th>
                    <th>{t("Purchases")}</th>
                  </tr>
                </thead>
                <tbody>
                  {engagementData?.total?.posts?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{moment(item?.createdAt).format("lll")}</td>
                          <td>{item?.viewCount}</td>
                          <td>
                            {" "}
                            {item?.currencySymbol}{" "}
                            {item?.totalTipAmount > 0
                              ? item?.totalTipAmount?.toFixed(2)
                              : "0.00"}
                          </td>
                          <td>
                            {item?.currencySymbol}{" "}
                            {item?.price > 0 ? item?.price?.toFixed(2) : "0.00"}
                          </td>
                          <td>
                            {item?.currencySymbol}{" "}
                            {item?.totalPurchaseAmount > 0
                              ? item?.totalPurchaseAmount?.toFixed(2)
                              : "0.00"}
                          </td>
                        </tr>

                        <td colSpan="5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="statics_imgset d-flex align-items-center">
                              <div className="static_img_wrap">
                                {item?.media?.length ? (
                                  <img
                                    src={
                                      item?.media?.length
                                        ? item?.media[0]?.url
                                        : ""
                                    }
                                    className="rounded"
                                  />
                                ) : null}
                              </div>

                              <div className="statics_contentset">
                                <p className="px-4 m-0">
                                  {formatText(item?.description)}
                                </p>
                              </div>
                            </div>
                            <div
                              className="full_stat d-bold pointer"
                              onClick={() => {
                                setPostStats(!postStats);
                                setSelectedRecord(item);
                              }}
                            >
                              {t("FULL STAT")}
                            </div>
                          </div>
                        </td>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
      <Statistics
        show={postStats}
        onhide={() => {
          setPostStats(false);
          setSelectedRecord(null);
        }}
        data={selectedRecord}
      />
    </section>
  );
};

export default Posts;
