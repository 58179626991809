import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addTrail } from "../../../../redux/actions";
import { toast } from "react-toastify";

const FreeTrialLinkPop = ({
  showTrailLinkModal,
  setShowTrailLinkModal,
  getTrailsListing,
}) => {
  const handleTrialLink = () => {
    setShowTrailLinkModal(!showTrailLinkModal);
  };
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      trialLinkName: "",
      subscribeCounts: 10,
      subscribeDays: 1,
      expiredAt: 1,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    validationSchema: Yup.object({
      trialLinkName: Yup.string().required(t("Trial LinK Name is required")),
    }),
    onSubmit: async function (values, { resetForm }) {
      dispatch(addTrail(values))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          setShowTrailLinkModal(false);
          getTrailsListing();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
  });

  return (
    <>
      <Modal
        show={showTrailLinkModal}
        className="StoryPromotion"
        onHide={handleTrialLink}
        backdrop="static"
        keyboard={false}
        centered
        scrollable="true"
      >
        <Modal.Header className="pt-3 pb-0 border-0" closeButton>
          <Modal.Title>Free Trial Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="border-top mt-4 pt-3">
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label">
                  Trial Link Name
                </label>
                <input
                  type="text"
                  placeholder="Trial Link Name"
                  className="form-control"
                  value={formik.values.trialLinkName}
                  onChange={formik.handleChange}
                  name="trialLinkName"
                  onBlur={formik.onBlur}
                />
                {formik.touched.trialLinkName &&
                  formik.errors.trialLinkName && (
                    <span className="text-danger errorText">
                      {formik.errors.trialLinkName}
                    </span>
                  )}
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label">
                  Offer Limit
                </label>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  value={formik.values.subscribeCounts}
                  onChange={formik.handleChange}
                  name="subscribeCounts"
                >
                  <option value={1}>1 Subscribers</option>
                  <option value={2}>2 Subscribers</option>
                  <option value={3}>3 Subscribers</option>
                  <option value={4}>4 Subscribers</option>
                  <option value={5}>5 Subscribers</option>
                  <option value={6}>6 Subscribers</option>
                  <option value={7}>7 Subscribers</option>
                  <option value={8}>8 Subscribers</option>
                  <option value={9}>9 Subscribers</option>
                  <option value={10}>10 Subscribers</option>
                  <option value={20}>20 Subscribers</option>
                  <option value={50}>50 Subscribers</option>
                </Form.Select>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label">
                  Offer Expiration
                </label>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  value={formik.values.expiredAt}
                  onChange={formik.handleChange}
                  name="expiredAt"
                >
                  <option value={1}> 1 day</option>
                  <option value={2}> 2 days</option>
                  <option value={3}> 3 days</option>
                  <option value={4}> 4 days</option>
                  <option value={5}> 5 days</option>
                  <option value={6}> 6 days</option>
                  <option value={7}> 7 days</option>
                  <option value={8}> 8 days</option>
                  <option value={8}> 9 days</option>
                </Form.Select>
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label">
                  Free Trial Duration
                </label>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  value={formik.values.subscribeDays}
                  onChange={formik.handleChange}
                  name="subscribeDays"
                >
                  <option value={1}> 1 day</option>
                  <option value={3}> 3 days</option>
                  <option value={7}> 7 days</option>
                  <option value={14}> 14 days</option>
                  <option value={28}> 28 days</option>
                </Form.Select>
                <p className="text-muted m-0 formText">
                  Promotional subscription $0.00 USD for 7 days. <br /> User
                  will not be subscribed for $30 automatically, only by choice
                </p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="transparent"
            className="border-0 p-0 px-3 fw-bold"
            onClick={handleTrialLink}
          >
            Cancel
          </Button>
          <Button
            variant="transparent"
            className="border-0 p-0 px-3 fw-bold"
            onClick={formik.handleSubmit}
          >
            Create Link
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FreeTrialLinkPop;
