import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const notificationList = createAsyncThunk(
  "notification/list",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.notifiation.notificationList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const readNotification = createAsyncThunk(
  "notification/read",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.notifiation.readNotification,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
