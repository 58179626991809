import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const createPost = createAsyncThunk(
  "createPost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.post.createPost,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const postDetails = createAsyncThunk(
  "postDetails",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(
        endpoints.entertainer.post.postDetails + payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const publishPost = createAsyncThunk(
  "publishPost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.post.publishPost,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const updatePost = createAsyncThunk(
  "updatePost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.post.updatePost,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
export const deletePost = createAsyncThunk(
  "deletePost",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.entertainer.post.deletePost,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const addPoll = createAsyncThunk("addPoll", async (payload, Thunk) => {
  try {
    const response = await axios.post(
      endpoints.entertainer.post.addPoll,
      payload
    );
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});

export const addQuiz = createAsyncThunk("addQuiz", async (payload, Thunk) => {
  try {
    const response = await axios.post(
      endpoints.entertainer.post.addQuiz,
      payload
    );
    return response?.data;
  } catch (err) {
    return Thunk.rejectWithValue(err);
  }
});
export const postViewed = createAsyncThunk(
  "postViewed",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.post.viewPost, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
