import React, { useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthFooter from "../../../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../../components/common/ButtonLoader/SmallLoader";
import { contactUs } from "../../../redux/states/common/thunk";
import { toast } from "react-toastify";

const Contact = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { buttonLoader } = useSelector((s) => s.common);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "Registration And Verification",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(contactUs(values))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          resetForm();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required").email("Invalid Email"),
      message: Yup.string().required("Message is required"),
      subject: Yup.string().required("Subject is required"),
    }),
  });


  return (
    <>
      <MainLayout>
        <Row className="  h-100 align-items-start justify-content-between ">
          <Col lg="12" md="12" sm="12">
            <div className="shadow1 rounded border h-100 px-3 py-4 ">
              <div className="d-flex justify-content-between align-items-center px-2">
                <h4 className="text-bold">
                  <span onClick={() => navigate(-1)} className="pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 12L11 6M5 12L11 18M5 12L19 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ marginLeft: "7px" }}>{t("Contact")}</span>
                </h4>
              </div>
              <div className="endline my-3"></div>
              <div className="contactCont">
                <p
                  className="text-sm text-center my-3"
                  style={{ fontSize: "18px" }}
                >
                  {t(
                    "If you have any questions, please fill out the form below to contact us."
                  )}
                </p>
                <div className="contactform">
                  <div className="Login_input_warp form-floating">
                    <input
                      type="text"
                      placeholder={t("Enter your name")}
                      class="form-control"
                      id="floatingInput"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      name="name"
                      onBlur={formik.onBlur}
                    />
                    <label for="floatingInput">{t("Enter your name")}</label>
                    {formik.touched.name && formik.errors.name && (
                      <span className="text-danger errorText">
                        {formik.errors.name}
                      </span>
                    )}
                  </div>
                  <div className="Login_input_warp form-floating mt-3">
                    <input
                      type="email"
                      placeholder={t("Enter your e-mail")}
                      class="form-control"
                      id="floatingInput"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      name="email"
                      onBlur={formik.onBlur}
                    />
                    <label for="floatingInput">{t("Enter your e-mail")}</label>
                    {formik.touched.email && formik.errors.email && (
                      <span className="text-danger errorText">
                        {formik.errors.email}
                      </span>
                    )}
                  </div>
                  <div className="Login_input_warp form-floating mt-3">
                    <select
                      type="select"
                      id="floatingInput"
                      value={formik.values.subject}
                      onChange={(e) =>
                        formik.setFieldValue("subject", e.target.value)
                      }
                      class="form-control"
                    >
                      <option value={"Registration And Verification"}>
                        {t("Registration And Verification")}{" "}
                      </option>
                      <option value={"Profile Access"}>
                        {t("Profile Access")}{" "}
                      </option>
                      <option value={"Credit Card Payments"}>
                        {t("Credit Card Payments")}{" "}
                      </option>
                      <option value={"Payouts"}>{t("Payouts")} </option>
                      <option value={"Cancel my payout request"}>
                        {t(" Cancel my payout request ")}{" "}
                      </option>
                      <option value={"Technical Questions"}>
                        {t(" Technical Questions ")}{" "}
                      </option>
                      <option value={"Reporting Stolen Content"}>
                        {t(" Reporting Stolen Content ")}{" "}
                      </option>
                      <option value={"Law Enforcement / Legal Matter"}>
                        {t(" Law Enforcement / Legal Matter ")}{" "}
                      </option>
                      <option value={"Report Illegal Material or Behavior"}>
                        {t(" Report Illegal Material or Behavior ")}{" "}
                      </option>
                      <option value={"Other"}>{t(" Other ")} </option>
                    </select>
                    <label for="floatingInput text-sm">
                      {t("Select Subject")}
                    </label>
                    {formik.touched.subject && formik.errors.subject && (
                      <span className="text-danger errorText">
                        {formik.errors.subject}
                      </span>
                    )}
                  </div>
                  <div className="Login_input_warp form-floating mt-3">
                    <textarea
                      type="text"
                      placeholder={t("Enter your e-mail")}
                      class="form-control"
                      id="floatingInput"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      name="message"
                      onBlur={formik.onBlur}
                      rows={4}
                      style={{ height: "unset" }}
                    />
                    <label for="floatingInput">{t("Enter your message")}</label>
                    {formik.touched.message && formik.errors.message && (
                      <span className="text-danger errorText">
                        {formik.errors.message}
                      </span>
                    )}
                  </div>

                  <div className="d-flex justify-content-end mx-2 mt-4 ">
                    {buttonLoader ? (
                      <button className="sendMsg">
                        <SmallLoader />
                      </button>
                    ) : (
                      <button className="sendMsg" onClick={formik.handleSubmit}>
                        {t("Send")}
                      </button>
                    )}
                  </div>

                  <div className="mt-5">
                    <p className="text-secondary text-xtr-sm mb-1 text-center">
                    18101 Collins Ave Apt 4608 Sunny Isles Beach FL 33160 USA ,
                      <br />
                      Mobile No. : 877-343-3267
                    </p>
                    <div className="endline mt-2"></div>
                  </div>
                  <div className="mt-4">
                    <p className="text-secondary text-xtr-sm mb-0 text-center">
                      {t("Please direct any Law Enforcement inquiries here:")}
                    </p>
                    <p className="text-center">
                      <Link
                        to="/legalinquiry"
                        className="text-xtr-sm text-center linkss"
                      >
                        https://fansroom.com/legalinquiry
                      </Link>
                    </p>
                  </div>
                  <div className="mt-4">
                    <p className="text-secondary text-xtr-sm mb-0 text-center">
                      {t(
                        "Additional information regarding Law Enforcement requests can be found in our"
                      )}
                    </p>
                    <p className="text-center">
                      <Link
                        to="/legalguide"
                        className="text-xtr-sm text-center linkss"
                      >
                        {t("Law Enforcement Guide")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </MainLayout>
      <AuthFooter />
    </>
  );
};

export default Contact;
