import React from "react";
import { Form, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";

const Searchpost = ({ setEntertainerSerach }) => {
  const { t } = useTranslation();
  return (
    // search_post
    //search_input
    <section className="">
      <Form className="">
        <FormGroup>
          {/* <Input
            type="search"
            name="searchpost"
            id="searchpost"
            placeholder={t("Search user")}
            className="commonBox border"
            onChange={(e) => setEntertainerSerach(e.target.value)}
          /> */}
          <div className="d-flex align-items-center border rounded ">
            <Input
              type="search"
              name="searchpost"
              placeholder={t("Search user")}
              className=" border-0"
              onChange={(e) => setEntertainerSerach(e.target.value)}
            />
            <span style={{ marginRight: "10px" }}>
              <BsSearch />
            </span>
          </div>
        </FormGroup>
      </Form>
    </section>
  );
};

export default Searchpost;
