import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getStatements } from "../../../../../redux/states/statistics/thunk";
import { useTranslation } from "react-i18next";
import moment from "moment";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `$ ${value?.toFixed(2)}`;
        },
      },
    },
  },
};

const Earning = ({ startDate, endDate }) => {
  const { t } = useTranslation();
  const { statementData } = useSelector((s) => s.statistics);
  const { user } = useSelector((s) => s.login);
  const [labelData, setLabelData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [tab, setTab] = useState("All");
  const [totalAmount, setTotalAmount] = useState({
    gross: 0,
    net: 0,
    fee: 0,
  });

  const dispatch = useDispatch();

  const getStatementsData = () => {
    const data = {
      type: "earnings",
      userId: user?._id,
      startDate,
      endDate,
    };
    dispatch(getStatements(data));
  };

  const sortData = (data) => {
    if (!data || data.length === 0) {
      setLabelData([]);
      setGraphData([]);
      return;
    }
    const combinedData = data?.map((item) => ({
      date: new Date(item?._id?.date),
      netAmount: item?.netAmount,
    }));

    combinedData?.sort((a, b) => a.date - b.date);

    if (combinedData.length === 0) {
      setLabelData([]);
      setGraphData([]);
      return;
    }

    const startDate = combinedData[0]?.date;
    const endDate = combinedData[combinedData.length - 1]?.date;

    const dataMap = new Map();
    combinedData.forEach((item) => {
      const dateString = item.date.toLocaleDateString();
      if (dataMap.has(dateString)) {
        dataMap.set(dateString, dataMap.get(dateString) + item.netAmount);
      } else {
        dataMap.set(dateString, item.netAmount);
      }
    });

    const dateRange = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const sortedLabelData = dateRange.map((date) => moment(date).format("ll"));
    const sortedGraphData = dateRange.map(
      (date) => dataMap.get(date.toLocaleDateString()) || 0
    );

    setLabelData(sortedLabelData);
    setGraphData(sortedGraphData);
  };

  const handleTabSelectedtion = (tabs) => {
    setTab(tabs);
    if (tabs === "Subscriptions") {
      const ddd = statementData?.data?.find((itm) => itm?._id === "Subscribe");
      sortData(ddd?.data);

      setTotalAmount({
        gross: ddd?.totalGrossAmount,
        net: ddd?.totalNetAmount,
      });
    } else if (tabs === "Tips") {
      const ddd = statementData?.data?.find((itm) => itm?._id === "Tips");
      sortData(ddd?.data);

      setTotalAmount({
        gross: ddd?.totalGrossAmount,
        net: ddd?.totalNetAmount,
      });
    } else if (tabs === "Posts") {
      const ddd = statementData?.data?.find((itm) => itm?._id === "Posts");
      sortData(ddd?.data);

      setTotalAmount({
        gross: ddd?.totalGrossAmount,
        net: ddd?.totalNetAmount,
      });
    } else if (tabs === "Messages") {
      const ddd = statementData?.data?.find((itm) => itm?._id === "Messages");
      sortData(ddd?.data);

      setTotalAmount({
        gross: ddd?.totalGrossAmount,
        net: ddd?.totalNetAmount,
      });
    } else if (tabs === "Streams") {
      const ddd = statementData?.data?.find((itm) => itm?._id === "Streams");
      sortData(ddd?.data);

      setTotalAmount({
        gross: ddd?.totalGrossAmount,
        net: ddd?.totalNetAmount,
      });
    } else {
      let allArray = [];
      statementData?.data?.map((itm) => {
        allArray = [...allArray, ...itm?.data];
      });
      sortData(allArray);

      setTotalAmount({
        gross: statementData?.totalGrossAmountAll,
        net: statementData?.totalNetAmountAll,
      });
    }
  };

  useEffect(() => {
    getStatementsData();
  }, [user, startDate, endDate]);

  useEffect(() => {
    handleTabSelectedtion("All");
  }, [statementData]);

  const data = {
    labels: labelData,
    datasets: [
      {
        label: tab,
        data: graphData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const totalAmountSection = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th>
              {t("Net Total")} : {totalAmount?.net?.toFixed(2)}
            </th>
            {/* <th>
              {t("Gross")} : {totalAmount?.gross?.toFixed(2)}
            </th> */}
            {/* <th>
              {t("Net")} : {totalAmount?.net?.toFixed(2)}
            </th> */}
          </tr>
        </thead>
      </Table>
    );
  };

  return (
    <section className="Earninginnerall_tab">
      <div className="CardBody pb-1 innner_tabs">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="Userlist"
          className="d-flex justify-content-between align-items-center"
        >
          <Nav variant="pills" className="">
            <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small"
                eventKey="All"
                onClick={() => handleTabSelectedtion("All")}
                active={tab === "All"}
              >
                {t("All")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small "
                eventKey="Subscriptions"
                onClick={() => handleTabSelectedtion("Subscriptions")}
                active={tab === "Subscriptions"}
              >
                {t("Subscriptions")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small "
                eventKey="Tips"
                onClick={() => handleTabSelectedtion("Tips")}
                active={tab === "Tips"}
              >
                {t("Tips")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small "
                eventKey="Posts"
                onClick={() => handleTabSelectedtion("Posts")}
                active={tab === "Posts"}
              >
                {t("Posts")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small "
                eventKey="Messages"
                onClick={() => handleTabSelectedtion("Messages")}
                active={tab === "Messages"}
              >
                {t("Messages")}
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item className="">
              <Nav.Link
                className="bg-transparent border-0 position-relative text-small "
                eventKey="Streams"
                onClick={() => handleTabSelectedtion("Streams")}
                active={tab === "Streams"}
              >
                {t("Streams")}
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Tab.Container>
      </div>
      {!graphData || graphData?.length === 0 ? (
        <>
          <div className="noGraphData">
            {t("No data during selected period")}
          </div>
        </>
      ) : (
        <Tab.Content className="">
          <Tab.Pane eventKey="All" active={tab === "All"}>
            <div className="my-3">
              <Line options={options} data={data} />
            </div>
            {totalAmountSection()}
          </Tab.Pane>
          <Tab.Pane eventKey="Subscriptions" active={tab === "Subscriptions"}>
            <div className="my-3">
              <Line options={options} data={data} />
            </div>
            {totalAmountSection()}
          </Tab.Pane>

          <Tab.Pane eventKey="Tips" active={tab === "Tips"}>
            <div className="my-3">
              <Line options={options} data={data} />
            </div>
            {totalAmountSection()}
          </Tab.Pane>

          <Tab.Pane eventKey="Posts" active={tab === "Posts"}>
            <div className="my-3">
              <Line options={options} data={data} />
            </div>
            {totalAmountSection()}
          </Tab.Pane>

          <Tab.Pane eventKey="Messages" active={tab === "Messages"}>
            <div className="my-3">
              <Line options={options} data={data} />
            </div>
            {totalAmountSection()}
          </Tab.Pane>

          {/* <Tab.Pane eventKey="Streams" active={tab === "Streams"}>
          <div className="my-3">
            <Line options={options} data={data} />
          </div>
        </Tab.Pane> */}
        </Tab.Content>
      )}
    </section>
  );
};

export default Earning;
