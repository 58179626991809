import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import PostCard from "../common/PostCard/PostCard";
import { useTranslation } from "react-i18next";
import SendTip from "../common/Modal/SendTip";

const Post = (props) => {
  const { t } = useTranslation();
  const {
    data,
    getArtistPostList,
    getArtistDetails,
    isSubscribed,
    handleSubscribe,
    artistDetailss,
  } = props;
  const [postData, setPostData] = useState([data]);
  const [searchInput, setSearchInput] = useState("");
  const [showSendTip, setShowSendTip] = useState(false);
 

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const value = e.target.value?.toLowerCase();
    const filterData = data?.filter((itm) => {
      return itm?.description?.toLowerCase()?.includes(value);
    });
    setPostData(filterData);
  };

  useEffect(() => {
    setPostData(data);
  }, [data]);



  return (
    <div className="px-lg-3 px-0 py-3">
      <div className="d-flex justify-content-between align-items-center px-4 px-lg-0">
        <h6 className="text-custom">{t("RECENT")}</h6>
        <div className="inputSearch">
          <input
            type="text"
            placeholder={t("Search...")}
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />
          <BiSearch color="#636363" size={20} />
        </div>
      </div>
      <div className="my-1 px-1 d-flex px-4 px-lg-0">
        <h6 className="rounded-lg text-small px-3 py-1 mx-1 bg-black text-light ">
          {t("All")}
        </h6>
        <h6 className="rounded-lg text-small px-3 py-1 mx-2 bg-black text-light ">
          {t("Archive")}
        </h6>
      </div>
      <div>
        <div className="NewsFeed">
          {postData?.map((item, index) => {
            return (
              <PostCard
                data={item}
                key={index}
                getArtistPostList={getArtistPostList}
                getArtistDetails={getArtistDetails}
                setShowSendTip={setShowSendTip}
                newList={postData}
                setNewList={setPostData}
                isSubscribed={isSubscribed}
                handleSubscribe={handleSubscribe}
              />
            );
          })}
        </div>
      </div>
      <SendTip show={showSendTip} onHide={() => setShowSendTip(false)} />
    </div>
  );
};

export default Post;
