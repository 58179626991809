import React, { Fragment, useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from "reactstrap";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Post from "../../../images/post1.png";
import ReactPlayer from "react-player";
import ImageVideoViewer from "../../common/Modal/ImageVideoViewer";
import { addPostToClipboard } from "../../../redux/states/post/slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomVideoPlayer from "../../CustomVideoPlayer";
// components

const Allmedia = (props) => {
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const { allMedia, title } = props;
  const [showViewer, setShowViewer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { screenForPost } = useSelector((s) => s.post);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleShowMedia = (dta) => {
    setSelectedMedia({
      url: dta?.url,
      mediaType: dta?.type,
      userName: "",
    });
    setShowViewer(true);
  };

  const handleMediaSelect = (dta) => {
    const { thumbnailKey, ...mediaData } = dta;
    setShowViewer(false);
    const ddd = selectedItems?.find((itm) => {
      return itm?._id === dta?._id;
    });
    if (ddd) {
      const filteredData = selectedItems?.filter(
        (itm) => itm?._id !== dta?._id
      );
      setSelectedItems(filteredData);
    } else {
      setSelectedItems((prev) => [...prev, mediaData]);
    }
  };

  const handleAddPost = () => {
    const data = selectedItems?.map((item) => {
      const { _id, ...otherData } = item;
      return otherData;
    });
    dispatch(addPostToClipboard(data));
    if (screenForPost === "home") {
      navigate("/home");
      props?.onhide();
    } else {
      navigate("/new-post");
    }
  };

  return (
    <section className="custom_order px-0 pb-3">
      {/* topheaderbar */}
      <div className="notification_topheader d-flex align-items-center justify-content-between px-4 pt-4">
        <Link to="/home">
          <div className="notification_left_heading d-flex align-items-center">
            <span className="me-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
              >
                <path
                  d="M1 7L7 1M1 7L7 13M1 7L15 7"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <h5>{title}</h5>
          </div>
        </Link>

        <div className=" d-flex align-items-center gap-3">
          {selectedItems?.length !== 0 && (
            <Button className="mx-3 w-full" onClick={handleAddPost}>
              {t("Add to post")} ({selectedItems?.length} {t("items")})
            </Button>
          )}
          <div className="three_dot_btnvar">
            <UncontrolledDropdown>
              <DropdownToggle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <g opacity="0.5" clip-path="url(#clip0_711_1706)">
                    <path
                      d="M8.99998 14C7.89541 14 6.99997 14.8954 6.99997 16C6.99997 17.1046 7.89541 18 8.99998 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 8.99998 14Z"
                      fill="black"
                    />
                    <path
                      d="M8.99998 6.99997C7.89541 6.99997 6.99997 7.89541 6.99997 8.99998C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 8.99998 6.99997Z"
                      fill="black"
                    />
                    <path
                      d="M8.99998 -3.21229e-05C7.89541 -3.22195e-05 6.99997 0.895405 6.99997 1.99998C6.99997 3.10456 7.89541 4 8.99998 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 8.99998 -3.21229e-05Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_711_1706">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(18 18) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </DropdownToggle>
              {/* <DropdownMenu>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
                <DropdownItem>Hide all Locked Posts</DropdownItem>
              </DropdownMenu> */}
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
      {/* topheaderbar */}
      <div className="endline my-3"></div>
      <div className="Achrive_part lg-px-3 mt-3">
        <Row className="align-items-center align-middle position-relative">
          {allMedia?.length === 0 && (
            <div className="no_bookmarks_yet text-center h-100 vh-100 mt-5">
              <img src="/images/allmedia.png" alt="" className="img-fluid" />
              <p>{t("This category is empty")}</p>
            </div>
          )}
          <div className="no_bookmarks_yet text-center h-100 vh-100 homeMiddleCont px-4 py-2">
            <Row className="">
              {allMedia?.map((media, index) => {
                return (
                  <Col sm={6} xs={6} className="my-2">
                    {media?.type === "image" && (
                      <div
                        className="mediaItem"
                        onClick={() => handleShowMedia(media)}
                      >
                        <img
                          src={media?.url}
                          alt=""
                          className="item img-fluid  w-100 h-100"
                          style={{ objectFit: "cover" }}
                        />
                        <input
                          type="checkbox"
                          name=""
                          id={index}
                          className="itemCheck"
                          onChange={() => handleMediaSelect(media)}
                        />
                      </div>
                    )}
                    {media?.type === "video" && (
                      <div
                        className="mediaItem"
                        onClick={() => handleShowMedia(media)}
                      >
                          <CustomVideoPlayer mediaItem={media} />
                        {/* <ReactPlayer
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          onContextMenu={(e) => e.preventDefault()}
                          url={media?.url}
                          width={"100%"}
                          // height={"100%"}
                          controls={false}
                          className="custom-player"
                        /> */}
                        <input
                          type="checkbox"
                          name=""
                          id={index}
                          className="itemCheck"
                          onChange={() => handleMediaSelect(media)}
                        />
                      </div>
                    )}
                    {media?.type === "audio" && (
                      <div className="mediaItem">
                        <audio src="" controls></audio>
                        <input
                          type="checkbox"
                          name=""
                          id={index}
                          className="itemCheck"
                          onChange={() => handleMediaSelect(media)}
                        />
                      </div>
                    )}
                  </Col>
                );
              })}
            </Row>
          </div>
        </Row>
      </div>
      <ImageVideoViewer
        show={showViewer}
        onHide={() => setShowViewer(false)}
        mediaType={selectedMedia?.mediaType}
        url={selectedMedia?.url}
        userName={selectedMedia?.userName}
      />
    </section>
  );
};

export default Allmedia;
