export const formatText = (text) => {
  if (!text) return null;

  // Regular expression to match usernames starting with '@'
  const usernameRegex = /@([A-Za-z0-9_-]+)/g;
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Function to replace usernames with clickable links
  const replaceUsernames = (match) => (
    <a
      key={match}
      href={`/${match}`}
      style={{ color: "#00aff0", textDecoration: "underline" }}
    >
      @{match}
    </a>
  );

  // Function to replace URLs with clickable links
  const replaceURLs = (match) => (
    <a
      key={match}
      href={match}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#00aff0", textDecoration: "underline" }}
    >
      {match}
    </a>
  );

  // Split text by usernames and URLs, then map them to JSX elements
  const formattedText = text.split(usernameRegex).map((part, index) => {
    if (index % 2 === 0) {
      return part.split(urlRegex).map((subpart, subindex) => {
        if (subindex % 2 === 0) {
          return subpart;
        } else {
          return replaceURLs(subpart);
        }
      });
    } else {
      return replaceUsernames(part);
    }
  });

  return <p className="m-0">{formattedText}</p>;
};
