import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const helpSupport = createAsyncThunk(
  "helpandsupport/helpSupport",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.helpSupport, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);


export const legalEnquiry = createAsyncThunk(
  "helpandsupport/legalInquiry",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.common.legalInquiry, payload);
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

