export const sendMessage = (
  io,
  message,
  userId,
  otherUserId,
  messageType,
  allMedia,
  chatDetail,
  messagePrice,
  attachmentType,
  tipAmount
) => {
  const byCustomerValue = chatDetail?.userId
    ? userId === chatDetail?.userId
      ? true
      : false
    : true;
  let data = {
    userId: byCustomerValue ? userId : otherUserId,
    otherUserId: !byCustomerValue ? userId : otherUserId,
    msg: message,
    byCustomer: byCustomerValue,
    byOtherCustomer: !byCustomerValue,
    attachment: allMedia || [],
    repliedOnStory: false,
    price: Number(messagePrice),
    messageType: messageType,
    attachmentType: attachmentType,
    tipAmount: tipAmount,
  };

  return new Promise((resolve, reject) => {
    io?.emit("sendMessage", data, (response) => {
      if (response) {
        resolve(response); // Resolve the promise with the response
      } else {
        reject(new Error("Failed to send message")); // Reject with an error if unsuccessful
      }
    });
  });
};
