import { combineReducers, createReducer } from "@reduxjs/toolkit";
import loginReducer from "./states/auth/slice";
import commonReducer from "./states/common/slice";
import settingReducer from "./states/settings/slice";
import homeReducer from "./states/home/slice";
import artistReducer from "./states/artist/slice";
import NotificationReducer from "./states/notification/slice";
import messageReducer from "./states/messages/slice";
import collectionReducer from "./states/collection/slice";
import helpSupportReducer from "./states/help&Support/slice";
import featuredPostReducer from "./states/FeaturedPost/slice";
import postDetailsReducer from "./states/PostDetails/slice";
import postReducer from "./states/post/slice";
import becomeCreatorReducer from "./states/becomeCreator/slice";
import postLabelReducer from "./states/postLabel/slice";
import statementsReducer from "./states/statements/slice";
import statisticsReducer from "./states/statistics/slice";
import cardReducer from "./states/card/slice";

const rootReducer = combineReducers({
  login: loginReducer,
  common: commonReducer,
  setting: settingReducer,
  home: homeReducer,
  artist: artistReducer,
  notification: NotificationReducer,
  message: messageReducer,
  collection: collectionReducer,
  helpSupport: helpSupportReducer,
  featurePost: featuredPostReducer,
  postDetails: postDetailsReducer,
  post: postReducer,
  becomeCreator: becomeCreatorReducer,
  postLabel: postLabelReducer,
  statement: statementsReducer,
  card: cardReducer,
  statistics: statisticsReducer,
});

export default rootReducer;
