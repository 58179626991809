import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { BsSendFill } from "react-icons/bs";
import { commentPost, likePost, postViewed } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import Stories from "react-insta-stories";
import { updateViewedPostList } from "../../../../redux/states/post/slice";
import { useSelector } from "react-redux";
import CustomVideoPlayer from "../../../CustomVideoPlayer";

function SampleNextArrow(props) {
  const { className, style, onClick, storyimg, data } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.62 2.99a1.25 1.25 0 0 0-1.77 0L6.54 11.3a.996.996 0 0 0 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
        />
      </svg>
    </div>
  );
}

export default function Storymodal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, getAllStory } = props;
  const { allViewedPost } = useSelector((s) => s.post);

  const storyViewed = (id) => {
    const data = {
      postId: id,
      type: "story",
    };

    if (!allViewedPost.includes(id)) {
      dispatch(postViewed(data))
        .unwrap()
        .then((res) => {
          dispatch(updateViewedPostList(id));
        })
        .catch((err) => {});
    }
  };

  const navigate = useNavigate();
  const [storyComment, setStoryComment] = useState("");
  const [isLikedStory, setIsLikedStory] = useState(false);
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => {
      storyViewed(data[current]?._id);
    },
  };

  const handleLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Story liked"));
        getAllStory();
        setIsLikedStory(true);
      })
      .catch((err) => {});
  };

  const handleUnLikePost = (dta) => {
    const data = {
      postSlug: dta?.slug,
    };
    dispatch(likePost(data))
      .unwrap()
      .then((res) => {
        toast.success(t("Story unliked"));
        getAllStory();
        setIsLikedStory(false);
      })
      .catch((err) => {});
  };

  const commentStory = (dta) => {
    if (storyComment === "") {
      toast.warning(t("Please write message"));
    } else {
      const dtsa = {
        postSlug: dta?.slug,
        comment: storyComment,
      };
      dispatch(commentPost(dtsa))
        .unwrap()
        .then((res) => {
          setStoryComment("");
          toast.success(t("comment posted"));
          getAllStory();
        })
        .catch((err) => {
          toast.error(err.message);
          setStoryComment("");
        });
    }
  };

  useEffect(() => {
    if (props.show) {
      storyViewed(data[0]?._id);
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="story_modal"
      fullscreen
      scrollable="true"
    >
      <Modal.Body>
        <div className="Story_slider_img position-relative bg-secondary">
          <a
            className="close_btn p-0 m-0 bg-transparent border-0"
            onClick={props.onhide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z"
              />
            </svg>
          </a>
          <div>
            <Slider {...settings}>
              {Array.isArray(data) &&
                data.map((itm, idx) => {
                  var storyimg = itm?.media;
                  storyimg = storyimg[0];
                  return (
                    <div
                      className="position-relative"
                      style={{ zIndex: 999999 }}
                    >
                      <div className="story_big" key={idx}>
                        {storyimg?.type === "image" && (
                          <img
                            src={storyimg.url}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                        {storyimg?.type === "video" && (
                          <CustomVideoPlayer mediaItem={storyimg} />
                          // <ReactPlayer
                          //   config={{
                          //     file: {
                          //       attributes: { controlsList: "nodownload" },
                          //     },
                          //   }}
                          //   onContextMenu={e => e.preventDefault()}
                          //   url={storyimg?.url}
                          //   width={"100%"}
                          //   // height={"100%"}
                          //   volume={true}
                          //   playing={true}
                          //   className="custom-player"
                          // />
                        )}
                      </div>
                      <div className="position-absolute storyActivitySection  my-1 d-flex justify-content-between align-items-center">
                        <span>
                          {isLikedStory || itm?.isLiked ? (
                            <AiFillHeart
                              size={28}
                              color="#ff5050"
                              onClick={() => handleUnLikePost(itm)}
                              className="pointer"
                            />
                          ) : (
                            <AiOutlineHeart
                              size={28}
                              color={"white"}
                              onClick={() => handleLikePost(itm)}
                              className="pointer"
                            />
                          )}
                        </span>
                        {/* <div className="inputComment bg-light d-flex justify-content-between align-items-center">
                          <input
                            type="text"
                            className="w-9/12 px-1 py-2"
                            onChange={(e) => setStoryComment(e.target.value)}
                            value={storyComment}
                            placeholder={t("Write me a comment")}
                          />
                          <BsSendFill
                            size={30}
                            color="green"
                            className=" bg-light p-1 pointer"
                            onClick={() => commentStory(itm)}
                          />
                        </div> */}
                      </div>
                      <div className="position-absolute storyViewerSection ">
                        <div className="d-flex align-items-center">
                          <h6 className="text-bold mx-2 text-light mb-0">
                            {itm?.viewCount}
                          </h6>
                          <AiOutlineEye size={30} color="white" />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
