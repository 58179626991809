import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import Calendermod from "../Modal/Calendermod";
import { useTranslation } from "react-i18next";

const Scheduleevent = () => {
  const { t } = useTranslation();
  const [showCalendarMod, setShowCalendarMod] = useState(false);
  return (
    <Fade duration={3000}>
      <section className="scheduling_event_card commonBox border mt-3 py-3 px-4">
        <div className="event_head text-start">
          <h5 className="m-0 py-1">{t("Scheduled Event")}</h5>
          <p className="m-0 py-1">
            {t(
              "Now you can schedule Posts, Messages and Streams to grow your online presence, and view it in Calendar"
            )}
          </p>
        </div>
        <div className="add_scheduling_event p-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            opacity="0.5"
          >
            <g opacity="0.5" clip-path="url(#clip0_709_83)">
              <path
                d="M7.85773 52H44.1422C47.9459 52 51.037 48.9089 51.037 45.1052V10.7466C51.037 6.94292 47.9459 3.85178 44.1422 3.85178H41.4074V1.92589C41.4074 0.866618 40.5406 0 39.4813 0C38.4221 0 37.5554 0.866618 37.5554 1.92589V3.85178H14.4444V1.92589C14.4444 0.866618 13.5777 0 12.5184 0C11.4591 0 10.5925 0.866618 10.5925 1.92589V3.85178H7.85773C4.05403 3.85178 0.962891 6.94292 0.962891 10.7466V45.1052C0.962891 48.9089 4.05403 52 7.85773 52ZM4.81483 10.7466C4.81483 9.07111 6.18222 7.70372 7.85773 7.70372H10.5925V9.62961C10.5925 10.6889 11.4591 11.5555 12.5184 11.5555C13.5777 11.5555 14.4443 10.6889 14.4443 9.62961V7.70372H37.5554V9.62961C37.5554 10.6889 38.4221 11.5555 39.4813 11.5555C40.5406 11.5555 41.4072 10.6889 41.4072 9.62961V7.70372H44.142C45.8175 7.70372 47.1849 9.07111 47.1849 10.7466V16.3704H4.81483V10.7466ZM4.81483 20.2222H47.1852V45.1052C47.1852 46.7807 45.8178 48.1481 44.1423 48.1481H7.85773C6.18222 48.1481 4.81483 46.7807 4.81483 45.1052V20.2222Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_709_83">
                <rect width="52" height="52" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <p>{t("You have no scheduled events.")}</p>
          <Button className="add_plus" onClick={() => setShowCalendarMod(true)}>
            +
          </Button>
        </div>

        <div className="view_queue text-end">
          <Link to="/queue">{t("View Queue")}</Link>
        </div>
      </section>
      <Calendermod
        show={showCalendarMod}
        onhide={() => setShowCalendarMod(false)}
      />
    </Fade>
  );
};

export default Scheduleevent;
