// This is the username section inside the acount section;

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import { getProfile, updateProfile } from "../../../../redux/actions";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import useGeoLocation from "react-ipgeolocation";

const PhoneNumber = () => {
  const { t, i18n } = useTranslation();
  const { user, buttonLoader } = useSelector((s) => s.setting);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useGeoLocation();

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string().required("userName is required"),
    }),
  });

  useEffect(() => {
    formik.setFieldValue("mobileNumber", `+${user?.mobileNumber}`);
  }, [user]);

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("CHANGE PHONE NUMBER")}</span>
        </h4>
      </div>
      <div className="endline my-2"></div>
      <div className="mx-4">
        <div className="formSection ">
          <div className="d-flex justify-content-center mt-4">
            <PhoneInput
              disableCountryGuess={location.country?.toLowerCase()}
              value={formik.values.mobileNumber}
              onChange={(value) => formik.setFieldValue("mobileNumber", value)}
              className="phoneInputCont"
            />
          </div>
        </div>
        {formik.touched.mobileNumber && formik.errors.mobileNumber && (
          <span className="text-danger errorText">
            {formik.errors.mobileNumber}
          </span>
        )}

        <div className="my-3">
          {buttonLoader ? (
            <button className="loginBtn text-light" ß>
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="loginBtn text-light"
              onClick={formik.handleSubmit}
            >
              {t("Submit")}
            </button>
          )}
        </div>
      </div>
    </Settings>
  );
};

export default PhoneNumber;
