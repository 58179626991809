// This is the username section inside the acount section;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import { getProfile, updateProfile } from "../../../../redux/actions";
import { toast } from "react-toastify";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { useTranslation } from "react-i18next";

const Google = () => {
  const {t}  = useTranslation();
  const navigate = useNavigate();
  function onSocialLogin({ provider, data: providerData, ...rest }) {

    const gid = provider === "google" ? providerData.sub : undefined;
    const fbid = provider === "facebook" ? providerData.id : undefined;

    const callBack = (data) => {

      if (data.status === "success") {
        if (!data.exist) {
          // onCustomerSignup({
          //   email: providerData.email,
          //   name: providerData.name,
          //   gid,
          //   fbid,
          // });
        } else {
          toast.success("Login Successful");
          setTimeout(() => {}, 2000);
        }
      } else {
        toast.error(data.message);
      }
    };

    // props.socialLogin({ gid, fbid, email: providerData.email }, callBack);
  }

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("SIGN IN WITH GOOGLE")}</span>
        </h4>
      </div>
      <div className="endline my-2"></div>
      <div className="mx-4">
        <LoginSocialGoogle
          client_id={
            "170029986863-qvs7f9pni5i1h1mtpp058rnhkiih7k17.apps.googleusercontent.com"
          }
          onLoginStart={() => {}}
          redirect_uri={"/"}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={onSocialLogin}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div className="mt-4">
            <button className="socialBtn bg-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                className="socailIcon"
              >
                <g clip-path="url(#clip0_126_3132)">
                  <path
                    d="M36.5652 15.6961L21.0654 15.6953C20.381 15.6953 19.8262 16.25 19.8262 16.9345V21.886C19.8262 22.5703 20.381 23.1251 21.0653 23.1251H29.7939C28.8381 25.6056 27.0542 27.6829 24.7782 29.0028L28.5 35.4457C34.4703 31.9928 38 25.9344 38 19.1524C38 18.1868 37.9289 17.4965 37.7865 16.7192C37.6783 16.1286 37.1656 15.6961 36.5652 15.6961Z"
                    fill="#167EE6"
                  />
                  <path
                    d="M18.9998 30.5648C14.7282 30.5648 10.9991 28.231 8.99634 24.7773L2.55371 28.4908C5.83232 34.1731 11.9742 37.9996 18.9998 37.9996C22.4463 37.9996 25.6983 37.0717 28.4998 35.4545V35.4457L24.7779 29.0028C23.0755 29.9902 21.1054 30.5648 18.9998 30.5648Z"
                    fill="#12B347"
                  />
                  <path
                    d="M28.5 35.4557V35.4468L24.7782 29.0039C23.0757 29.9912 21.1058 30.566 19 30.566V38.0008C22.4465 38.0008 25.6987 37.0728 28.5 35.4557Z"
                    fill="#0F993E"
                  />
                  <path
                    d="M7.43479 19.0009C7.43479 16.8954 8.00939 14.9256 8.99657 13.2232L2.55394 9.50977C0.927883 12.3024 0 15.5456 0 19.0009C0 22.4563 0.927883 25.6995 2.55394 28.4921L8.99657 24.7786C8.00939 23.0763 7.43479 21.1064 7.43479 19.0009Z"
                    fill="#FFD500"
                  />
                  <path
                    d="M18.9998 7.43479C21.7853 7.43479 24.3439 8.42457 26.3424 10.071C26.8354 10.4771 27.552 10.4478 28.0036 9.99615L31.5119 6.48783C32.0243 5.97543 31.9878 5.13668 31.4405 4.66183C28.0921 1.75698 23.7354 0 18.9998 0C11.9742 0 5.83232 3.8265 2.55371 9.50883L8.99634 13.2223C10.9991 9.76867 14.7282 7.43479 18.9998 7.43479Z"
                    fill="#FF4B26"
                  />
                  <path
                    d="M26.3426 10.071C26.8356 10.4771 27.5523 10.4478 28.0038 9.99615L31.5122 6.48783C32.0245 5.97543 31.988 5.13668 31.4407 4.66183C28.0923 1.75691 23.7357 0 19 0V7.43479C21.7854 7.43479 24.3441 8.42457 26.3426 10.071Z"
                    fill="#D93F21"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_126_3132">
                    <rect width="38" height="38" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {t("Sign In With Google")}
            </button>
          </div>
        </LoginSocialGoogle>
      </div>
    </Settings>
  );
};

export default Google;
