// This is the push notification section.j
// This is the username section inside the acount section;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../components/common/ButtonLoader/ButtonLoader";
import { getProfile, updateNotification } from "../../../../redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const EmailNotification = () => {
  const { t } = useTranslation();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const { buttonLoader, user } = useSelector((s) => s.setting);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserData = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    getUserData();
  }, []);

  const formik = useFormik({
    initialValues: {
      status: true,
      isShowFullText: false,
      isMonthlyNewsLetter: true,
      newPostSummary: true,
      postFrequency: false,
      newStream: true,
      upcommingStreamReminder: false,
      privateMessageSummary: false,
      privateMessageFrequency: true,
      receiveLessNotification: false,
      isSubscription: false,
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateNotification(values))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          getUserData();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
  });

  const handleEmailNotification = () => {
    setShowEmailForm(!showEmailForm);
    formik.setFieldValue("status", !showEmailForm);
  };

  useEffect(() => {
    const emailNotifications = user?.notifications?.emailNotifications;
    formik.setValues({
      status: emailNotifications?.status,
      isShowFullText: emailNotifications?.emailTemplate?.isShowFullText,
      isMonthlyNewsLetter:
        emailNotifications?.emailTemplate?.isMonthlyNewsLetter,
      newPostSummary:
        emailNotifications?.emailTemplate?.subscriptionAndFollowing
          ?.newPostSummary,
      postFrequency:
        emailNotifications?.emailTemplate?.subscriptionAndFollowing
          ?.postFrequency,
      newStream:
        emailNotifications?.emailTemplate?.subscriptionAndFollowing?.newStream,
      upcommingStreamReminder:
        emailNotifications?.emailTemplate?.subscriptionAndFollowing
          ?.upcommingStreamReminder,
      privateMessageSummary:
        emailNotifications?.emailTemplate?.newMessage?.privateMessageSummary,
      privateMessageFrequency:
        emailNotifications?.emailTemplate?.newMessage?.privateMessageFrequency,
      receiveLessNotification:
        emailNotifications?.emailTemplate?.newMessage?.receiveLessNotification,
      isSubscription: emailNotifications?.isSubscription,
    });
    setShowEmailForm(emailNotifications?.status);
  }, [user]);

  return (
    <Settings>
      <div className="d-flex justify-content-between align-items-center mx-3 pt-4">
        <h4 className="text-bold">
          <span className="pointer" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 12L11 6M5 12L11 18M5 12L19 12"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ marginLeft: "8px" }}>{t("EMAIL NOTIFICATIONS")}</span>
        </h4>
      </div>
      <div className="endline mt-2"></div>

      <div className="endline"></div>
      <div className="mx-3 px-2 py-1" onClick={handleEmailNotification}>
        <div className="d-flex justify-content-between align-items-center py-1">
          <h6 className="text-small fw-sbold mb-0 px-2">
            {t("Email Notification")}
          </h6>
          <Form>
            <Form.Check
              type="switch"
              id="toggle-switch"
              checked={showEmailForm}
            />
          </Form>
        </div>
        <h6 className="light-text pb-2">
          {t(
            "Get emails to find out what’s going on when you’re not on Fansroom."
          )}{" "}
          <br />
          {t("You can turn them off anytime.")}
        </h6>
      </div>
      <div className="endline"></div>
      {showEmailForm && (
        <>
          <div className="mx-3 px-2 py-1">
            <div className="d-flex justify-content-between align-items-center py-1">
              <h6 className="text-small fw-sbold mb-0 px-2">
                {t("Show full text of the message in the notification email")}
              </h6>
              <Form>
                <Form.Check
                  type="switch"
                  id="toggle-switch"
                  checked={formik.values?.isShowFullText}
                  onChange={() =>
                    formik.setFieldValue(
                      "isShowFullText",
                      formik?.values?.isShowFullText ? false : true
                    )
                  }
                />
              </Form>
            </div>
          </div>
          <div className="endline"></div>

          <div className="mx-3 px-2 py-1">
            <div className="d-flex justify-content-between align-items-center py-1">
              <h6 className="text-small fw-sbold mb-0 px-2">
                {t("Monthly Newsletter")}
              </h6>
              <Form>
                <Form.Check
                  type="switch"
                  id="toggle-switch"
                  checked={formik.values?.isMonthlyNewsLetter}
                  onChange={() =>
                    formik.setFieldValue(
                      "isMonthlyNewsLetter",
                      formik?.values?.isMonthlyNewsLetter ? false : true
                    )
                  }
                />
              </Form>
            </div>
          </div>
          <div className="endline"></div>
          <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
            <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
              {t("Subscriptions and following")}
            </h6>
          </div>
          <div className="endline"></div>
          <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
            <Form.Check
              type="checkbox"
              id={`disabled-defau`}
              checked={formik?.values?.newPostSummary}
              onChange={() =>
                formik.setFieldValue(
                  "newPostSummary",
                  formik?.values?.newPostSummary ? false : true
                )
              }
            />
            <label htmlFor="" className="text-nrml mx-3">
              {t("New Posts Summary")}
            </label>
          </div>
          <Form.Select
            aria-label="Default select example"
            className="notificatinSelect"
            value={formik?.values?.postFrequency}
            onChange={(e) =>
              formik.setFieldValue("postFrequency", e.target.value)
            }
          >
            <option>{t("Update Frequency")}</option>
            <option value="Every 24 Hours">{t("Every 24 Hours")}</option>
            <option value="Every 12 Hours">{t("Every 12 Hours")}</option>
            <option value="Every 6 Hours">{t("Every 6 Hours")}</option>
          </Form.Select>
          {/* <div className="endline"></div> */}
          {/* <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
            <Form.Check
              type="checkbox"
              id={`disabled-defau`}
              checked={formik?.values?.newStream}
              onChange={() =>
                formik.setFieldValue(
                  "newStream",
                  formik?.values?.newStream ? false : true
                )
              }
            />
            <label htmlFor="" className="text-nrml mx-3">
              {t("New Stream")}
            </label>
          </div>
          <div className="endline"></div>
          <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
            <Form.Check
              type="checkbox"
              id={`disabled-defau`}
              checked={formik?.values?.upcommingStreamReminder}
              onChange={() =>
                formik.setFieldValue(
                  "upcommingStreamReminder",
                  formik?.values?.upcommingStreamReminder ? false : true
                )
              }
            />
            <label htmlFor="" className="text-nrml mx-3">
              {t("Upcoming stream reminders")}
            </label>
          </div> */}
          <div className="endline"></div>
          <div className="mx-3 d-flex justify-content-between align-items-center px-2 py-2 pointer">
            <h6 className="text-normal fw-sbold mb-0 px-2 py-2">
              {t("New messages")}
            </h6>
          </div>
          <div className="endline"></div>
          <div className="mx-3 px-2 py-3 d-flex align-items-center settingform">
            <Form.Check
              type="checkbox"
              id={`disabled-defau`}
              checked={formik?.values?.privateMessageSummary}
              onChange={() =>
                formik.setFieldValue(
                  "privateMessageSummary",
                  formik?.values?.privateMessageSummary ? false : true
                )
              }
            />
            <label htmlFor="" className="text-nrml mx-3">
              {t("New Private Message Summary")}
            </label>
          </div>
          <Form.Select
            aria-label="Default select example"
            className="notificatinSelect"
            value={formik?.values?.privateMessageFrequency}
            onChange={(e) =>
              formik.setFieldValue("privateMessageFrequency", e.target.value)
            }
          >
            <option>{t("Update Frequency")}</option>
            <option value="Instantly">{t("Instantly")}</option>
            <option value="Every Hour">{t("Every Hour")}</option>
            <option value="Every 3 Hours">{t("Every 3 Hours")}</option>
          </Form.Select>
          <div className="endline"></div>
          <div className="mx-3 px-2 py-1">
            <div className="d-flex justify-content-between align-items-center py-1">
              <h6 className="text-small fw-sbold mb-0 px-2">
                {t("Receive less notifications")}
              </h6>
              <Form>
                <Form.Check
                  type="switch"
                  id="toggle-switch"
                  checked={formik?.values?.receiveLessNotification}
                  onChange={() =>
                    formik.setFieldValue(
                      "receiveLessNotification",
                      formik?.values?.receiveLessNotification ? false : true
                    )
                  }
                />
              </Form>
            </div>
            <h6 className="light-text pb-2">
              {t("Receive email notifications for direct messages only.")}
            </h6>
          </div>
          <div className="endline"></div>
        </>
      )}
      <div className="mx-3 px-2 py-1">
        <div className="d-flex justify-content-between align-items-center py-1">
          <h6 className="text-small mb-0 px-2">
            {t("Important subscription notifications")}
          </h6>
          <Form>
            <Form.Check
              type="switch"
              id="toggle-switch"
              checked={formik?.values?.isSubscription}
              onChange={() =>
                formik.setFieldValue(
                  "isSubscription",
                  formik?.values?.isSubscription ? false : true
                )
              }
            />
          </Form>
        </div>
        <h6 className="light-text pb-2">
          {t(
            "Notifications about upcoming subscription renewals and expirations"
          )}
        </h6>
      </div>
      <div className="endline"></div>
      <div className="d-flex my-3 justify-content-center align-items-center w-full">
        {buttonLoader ? (
          <button className="updateNotificationBtn text-light">
            <ButtonLoader />
          </button>
        ) : (
          <button
            className="updateNotificationBtn"
            onClick={formik.handleSubmit}
          >
            {t("Update")}
          </button>
        )}
      </div>
    </Settings>
  );
};

export default EmailNotification;
