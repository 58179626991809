import React, { useState, useEffect, Fragment, useRef } from "react";
import useSocket from "../../../customHooks/useSocket";
import EmojiPicker from "emoji-picker-react";
import BlackDollerSVG from "../../../images/blackDoller.svg";
import WhiteDollerSVG from "../../../images/whiteDoller.svg";
import HeartSVG from "../../../images/heartDouble.svg";
import BlackHeartSVG from "../../../images/blackHeartDouble.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../../helpers/sendMessage";
import { uploadFile } from "../../../redux/states/common/thunk";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  BsArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import {
  artistDetails,
  chatDetails,
  chatHistory,
  deleteChat,
} from "../../../redux/actions";
import { getTimeAgo } from "../../../helpers/time";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteChat from "../../common/Modal/ConfirmDeleteChat";
import ImageVideoViewer from "../../common/Modal/ImageVideoViewer";
import { BiVideo, BiMicrophone } from "react-icons/bi";
import { MdSlowMotionVideo } from "react-icons/md";
import AudioRecorders from "../../common/Modal/EntertainerModal/AudioRecorderModal";
import VideoRecorederModa from "../../common/Modal/EntertainerModal/VideoRecorederModa";
import { Dropdown } from "react-bootstrap";
import {
  clearChatHistory,
  updateLastMessage,
  updateNewMsg,
} from "../../../redux/states/messages/slice";
import { BsTag } from "react-icons/bs";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { TbTagOff, TbTag } from "react-icons/tb";
import MessagePrice from "../../common/Modal/MessagePrice";
import ProgressBarModal from "../../common/Modal/ProgressBarModal";
import axios from "axios";
import { endpoints } from "../../../endpoints";
import ReceiverSidePostViewer from "./ReceiverSidePostViewer";
import SenderSidePostViewer from "./SenderSidePostViewer";
import InfiniteScroll from "react-infinite-scroll-component";
import TextareaAutosize from "react-textarea-autosize";
import CustomVideoPlayer from "../../CustomVideoPlayer";

const EntertainerMessageSection = (props) => {
  const [selectedMedia, setSelectedMedia] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showViewer, setShowViewer] = useState(false);
  const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);
  const navigate = useNavigate();
  const { isNewChat, setIsNewChat, getChatList, allFollowedArtist } = props;
  const { t } = useTranslation();
  const { artistId, chatId } = useParams();
  const dispatch = useDispatch();
  const { imageLoader } = useSelector((s) => s.common);
  const { user } = useSelector((s) => s.login);
  const userId = user?._id;
  const [chatMessage, setChatMessage] = useState("");
  const [attachment, setAttachement] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const { messageHistory, chatLists, chatDetail, newMsg } = useSelector(
    (s) => s.message
  );
  const { artistDetailss } = useSelector((s) => s.artist);
  const [allMessages, setAllMessages] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [showAudioRecorder, setShowAudioRecorder] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState(null);
  const [showMessagePriceModal, setShowMessagePriceModal] = useState(false);
  const [messagePrice, setMessagePrice] = useState(0);
  const [allMedia, setAllMedia] = useState([]);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [messageType, setMessageType] = useState("free");
  const [page, setPage] = useState(1);
  const [totalCounts, setTotalCounts] = useState(120);
  const chatSectionRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const executeScrollBottom = () => {
    chatSectionRef?.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };
  const executeScrollTop = () => {
    // chatSectionRef.current.scrollIntoView({  block : "start",  behavior: "smooth" });
  };

  const handleMessageSend = async () => {
    const result = await sendMessage(
      io,
      chatMessage,
      userId,
      artistId,
      messageType,
      allMedia,
      chatDetail,
      messagePrice
    );

    dispatch(updateLastMessage(chatMessage));

    setAllMessages((prev) => [...prev, result]);

    if (isNewChat) {
      getChatList();
      setIsNewChat(false);

      if (!chatId) {
        navigate(`/messages/${artistId}/chat/${result.chatId}`);
      }
    }
    setAllMedia([]);
    setMessagePrice(0);
    setMessageType("free");
    setChatMessage("");
    setAttachement("");
    setAttachmentType("");

    setTimeout(() => {
      executeScrollBottom();
    }, 500);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    setAttachmentType("image");
    var allFileType = [];
    const formdata = new FormData();
    for (var i = 0; i < files.length; i++) {
      formdata.append("file", files[i]);
      const fileType = files[i]?.type.split("/")[0];
      allFileType.push(fileType);
    }
    setShowProgressModal(true);
    axios
      .post(
        endpoints.common.uploadFile,
        formdata,
        {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent?.loaded * 100) / progressEvent?.total
            );
            setUploadedPercent(percentage);
          },
        },
        (error, response) => {
          if (error) {
            toast.error("Data Uploading Failed");
            setShowProgressModal(false);
          } else {
            toast.success(t("File uploaded successfully"));
          }
        }
      )
      .then((res) => {
        const url = res?.data?.data?.url;
        setShowProgressModal(false);
        setUploadedPercent(0);
        if (Array.isArray(url)) {
          const data = url.map((itm, ind) => {
            return {
              type: allFileType[ind],
              videoKey: "thumbnail",
              url: itm,
              priceType: "free",
            };
          });
          setAllMedia((prev) => [...prev, ...data]);
        } else {
          const data = {
            type: allFileType[0],
            videoKey: "thumbnail",
            url: url,
            priceType: "free",
          };
          setAllMedia((prev) => [...prev, data]);
        }
      })
      .catch((err) => {
        setUploadedPercent(0);
        toast.error(err.message);
        setShowProgressModal(false);
      });
    e.target.value = "";
  };

  const getChatHistory = (pageNo = page) => {
    setPage(pageNo);
    const data = {
      chatId: chatId,
      limit: 0,
      page: pageNo,
    };
    if (pageNo !== 1) {
      setLoading(true);
    }
    dispatch(chatHistory(data))
      .unwrap()
      .then((res) => {
        const dtaList = res?.data;
        if (pageNo === 1) {
          setAllMessages(dtaList);
          setTimeout(() => {
            executeScrollBottom();
          }, 500);
        } else {
          setAllMessages((prev) => [...dtaList, ...prev]);
          setTimeout(() => {
            executeScrollTop();
          }, 500);
          setLoading(false);
        }
        setTotalCounts(res?.totalcount);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getChatDetails = () => {
    dispatch(chatDetails(chatId));
  };

  useEffect(() => {
    if (chatId) {
      setAllMessages([]);
      getChatHistory(1);
      getChatDetails();
      // setIsNewChat(false);
    } else {
      setIsNewChat(true);
      setAllMessages([]);
      dispatch(clearChatHistory());
    }
  }, [chatId]);

  useEffect(() => {
    if (artistId) {
      dispatch(artistDetails(artistId));
    }
  }, [artistId]);

  const confirmDeleteChat = () => {
    dispatch(deleteChat(chatId))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);

        navigate("/messages");
        setShowDeleteChatModal(false);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleMessageSend();
    }
  };

  const handleShowOnlyMedia = () => {
    const filterData = messageHistory?.filter((itm) => {
      return itm?.attachmentType === "image" || itm.attachment === "video";
    });
    setAllMessages(filterData);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setInputSearch(value);
    const filterData = messageHistory?.filter((itm) => {
      return itm?.msg.includes(value);
    });
    setAllMessages(filterData);
  };

  // here i am write code for uploadin audio and video;

  const saveVideo = () => {
    setAttachmentType("video");
    const formdata = new FormData();
    formdata.append("file", videoData?.[0]);
    dispatch(uploadFile(formdata))
      .unwrap()
      .then((res) => {
        const data = {
          type: "video",
          videoKey: "thumbnail",
          url: res?.data?.url,
          priceType: "free",
        };
        setAllMedia((rest) => [...rest, data]);
        setShowVideoRecorder(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveAudio = () => {
    if (audioData) {
      setAttachmentType("audio");
      const formdata = new FormData();
      formdata.append("file", audioData);
      dispatch(uploadFile(formdata))
        .unwrap()
        .then((res) => {
          const data = {
            type: "audio",
            videoKey: "thumbnail",
            url: res?.data?.url,
            priceType: "free",
          };
          setAllMedia((rest) => [...rest, data]);
          setShowAudioRecorder(false);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.warning("Please record audio first");
    }
  };

  const handleRemoveMedia = (id) => {
    const filteredData = allMedia?.filter((itm, index) => {
      return index !== id;
    });
    setAllMedia(filteredData);
  };

  const handleMediaType = (id, type) => {
    const filteredData = allMedia?.filter((itm, index) => {
      return index !== id;
    });
    const selectedData = allMedia?.find((itm, index) => {
      return index === id;
    });
    var ddd = {
      ...selectedData,
      priceType: type,
    };

    setAllMedia([...filteredData, ddd]);
  };

  const fetchMoreMessage = () => {
    getChatHistory(page + 1);
  };

  useEffect(() => {
    if (newMsg) {
      setAllMessages((prev) => [...prev, newMsg]);
      setTimeout(() => {
        executeScrollBottom();
      }, 500);
    }
  }, [newMsg]);

  const handleScroll = (e) => {
    const element = e.target;

    if (element.scrollTop === 0) {
      // Reached the top, fetch more messages
      if (totalCounts > allMessages?.length) {
        fetchMoreMessage();
      }
    }
  };

  // writing code for listening the server everytime
  const { io } = useSocket();

  useEffect(() => {
    if (io && !io?.hasListeners("newMessage")) {
      io.on("newMessage", (response) => {
        getChatList();
        if ("msg" in response) {
          const isChatCreatedbyMe = response?.customerId === userId;
          if (isChatCreatedbyMe) {
            if (response?.byCustomer) {
            } else {
              dispatch(updateNewMsg(response));
            }
          } else {
            if (response?.byCustomer) {
              dispatch(updateNewMsg(response));
            }
          }
        }
      });
    }
  }, [io]);

  const getProfileImg = (data, side) => {
    let dataValue;

    if (side === "left") {
      dataValue = data;
    } else {
      dataValue = user;
    }

    return (
      <>
        {dataValue.profileImage ? (
          <div className="chatuserprofile_img">
            <img
              src={dataValue.profileImage}
              alt=""
              className="img-fluid logo"
            />
          </div>
        ) : (
          <div className="chatuserprofile_img">
            {dataValue.firstName && dataValue.lastName
              ? `${dataValue.firstName.slice(0, 1)}${dataValue.lastName.slice(
                  0,
                  1
                )}`
              : dataValue.displayName?.slice(0, 1) ||
                dataValue.name?.slice(0, 1)}
          </div>
        )}
      </>
    );
  };

  const handleEmojiClick = (emojiObject) => {
    setChatMessage((prevText) => prevText + emojiObject.emoji);
    setShowEmojiPicker(false);
  };
  return (
    <div className="shadow1 rounded  border h-100 px-0 py-2 x-chat-wrapper">
      {artistId ? (
        <>
          <div className="px-0">
            <div className="d-flex justify-content-between align-items-center px-3">
              <div className="d-flex align-items-center msgTitle">
                <span onClick={() => navigate("/messages")} className="pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12L11 6M5 12L11 18M5 12L19 12"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <h6 className="mb-0 capitalize">
                  {artistDetailss?.firstName
                    ? artistDetailss?.firstName +
                      " " +
                      (artistDetailss?.lastName || "")
                    : artistDetailss?.name}
                </h6>
              </div>
              <Dropdown className="noAfter">
                <Dropdown.Toggle
                  variant="transparent"
                  className="border-0 p-0"
                  id="dropdown-basic"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g opacity="0.5" clip-path="url(#clip0_96_11624)">
                      <path
                        d="M8.99998 14C7.89541 14 6.99997 14.8954 6.99997 16C6.99997 17.1046 7.89541 18 8.99998 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 8.99998 14Z"
                        fill="black"
                      />
                      <path
                        d="M8.99998 6.99997C7.89541 6.99997 6.99997 7.89541 6.99997 8.99998C6.99997 10.1046 7.89541 11 8.99998 11C10.1046 11 11 10.1046 11 8.99998C11 7.89541 10.1046 6.99997 8.99998 6.99997Z"
                        fill="black"
                      />
                      <path
                        d="M8.99998 -3.21229e-05C7.89541 -3.22195e-05 6.99997 0.895405 6.99997 1.99998C6.99997 3.10456 7.89541 4 8.99998 4C10.1046 4 11 3.10456 11 1.99998C11 0.895405 10.1046 -3.20264e-05 8.99998 -3.21229e-05Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_96_11624">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(18 18) rotate(-180)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowDeleteChatModal(true)}>
                    {t("Delete Chat")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="px-4">
              <div className="msgTopbar d-flex align-items-center">
                {artistDetailss?.status === "active" && (
                  <div className="available d-flex align-items-center">
                    {artistDetailss?.status == "active" && (
                      <div className=""></div>
                    )}
                    <h6 className="available_text mb-0 ms-2">
                      {t("Available Now")}
                    </h6>
                  </div>
                )}
                <div
                  className="chathead d-flex align-items-center mx-2 pointer"
                  onClick={handleShowOnlyMedia}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_96_11637)">
                        <path
                          d="M1.33337 8.00065C1.33337 4.85798 1.33337 3.28665 2.30937 2.30998C3.28671 1.33398 4.85737 1.33398 8.00004 1.33398C11.1427 1.33398 12.714 1.33398 13.69 2.30998C14.6667 3.28732 14.6667 4.85798 14.6667 8.00065C14.6667 11.1433 14.6667 12.7147 13.69 13.6907C12.7147 14.6673 11.1427 14.6673 8.00004 14.6673C4.85737 14.6673 3.28604 14.6673 2.30937 13.6907C1.33337 12.7153 1.33337 11.1433 1.33337 8.00065Z"
                          stroke="black"
                        />
                        <path
                          d="M10.6667 6.66667C11.4031 6.66667 12 6.06971 12 5.33333C12 4.59695 11.4031 4 10.6667 4C9.93033 4 9.33337 4.59695 9.33337 5.33333C9.33337 6.06971 9.93033 6.66667 10.6667 6.66667Z"
                          stroke="black"
                        />
                        <path
                          d="M1.33337 6.77069L1.98671 6.67669C6.64004 6.00802 10.6167 10.0214 9.90537 14.668"
                          stroke="black"
                          stroke-linecap="round"
                        />
                        <path
                          d="M14.6666 8.9248L14.018 8.8348C12.122 8.57213 10.4066 9.51613 9.52332 11.0015"
                          stroke="black"
                          stroke-linecap="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_96_11637">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <h6 className="mx-2 mb-0 available_text">{t("Gallery")}</h6>
                </div>
                <div
                  className="chathead d-flex align-items-center mx-2 pointer"
                  onClick={() => setAllMessages(messageHistory)}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M15.2666 13.5285L14.2467 5.95509C14.1894 5.52913 13.981 5.13994 13.6599 4.85922C13.3389 4.5785 12.9254 4.42391 12.4956 4.42391H11.3734V3.84212C11.3734 1.98203 9.86015 0.46875 8.00006 0.46875C6.13996 0.46875 4.62665 1.98203 4.62665 3.84212V4.42391H3.50453C3.07471 4.42391 2.66118 4.5785 2.34015 4.85922C2.01912 5.13991 1.81071 5.52913 1.75334 5.95509L0.733558 13.5285C0.663526 14.0486 0.80912 14.5324 1.15456 14.9273C1.5 15.3224 1.95996 15.5312 2.48475 15.5312H13.5154C14.0401 15.5312 14.5001 15.3224 14.8456 14.9273C15.191 14.5323 15.3366 14.0485 15.2666 13.5285ZM5.62665 3.84212C5.62665 2.53344 6.69137 1.46875 8.00006 1.46875C9.30875 1.46875 10.3734 2.53344 10.3734 3.84212V4.42391H5.62665V3.84212ZM14.0928 14.2691C13.9386 14.4455 13.7497 14.5312 13.5154 14.5312H2.48475C2.25046 14.5312 2.06159 14.4455 1.90731 14.2691C1.75309 14.0927 1.69334 13.8941 1.72459 13.6619L2.7444 6.08853C2.79624 5.70341 3.11593 5.42391 3.50453 5.42391H4.62665V7.15325C4.62665 7.42938 4.85053 7.65325 5.12665 7.65325C5.40278 7.65325 5.62665 7.42938 5.62665 7.15325V5.42391H10.3734V7.15325C10.3734 7.42938 10.5973 7.65325 10.8734 7.65325C11.1496 7.65325 11.3734 7.42938 11.3734 7.15325V5.42391H12.4956C12.8842 5.42391 13.2038 5.70341 13.2557 6.08853L14.2755 13.6619C14.3068 13.8941 14.247 14.0927 14.0928 14.2691Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h6 className="mx-2 mb-0 available_text">{t("Chat")}</h6>
                </div>
                <div className="searchchat_wrap d-flex align-items-center mx-2">
                  <input
                    type="text"
                    className="msgSearch"
                    placeholder={t("Search here..")}
                    value={inputSearch}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
            <div className="msgLine w-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="573"
                height="1"
                viewBox="0 0 573 1"
                fill="none"
              >
                <path opacity="0.2" d="M0 0.5H573" stroke="black" />
              </svg>
            </div>
          </div>
          <div className="px-3 chattingArrea" id="messagereadd">
            {loading && (
              <div className="d-flex justify-content-center align-items-center">
                <SmallLoader color={"text-secondary"} />
              </div>
            )}
            <div>
              {chatId && (
                <InfiniteScroll
                  inverse={true}
                  dataLength={allMessages.length}
                  next={() => fetchMoreMessage()}
                  hasMore={totalCounts > allMessages?.length}
                  loader={
                    <div className="d-flex justify-content-center">
                      <SmallLoader color={"text-secondary"} />
                    </div>
                  }
                  scrollableTarget="messagereadd"
                  scrollThreshold={0.5}
                  onScroll={handleScroll}
                >
                  {allMessages?.length > 0 &&
                    allMessages?.map((message, index) => {
                      const customerId =
                        typeof message?.customerId === "object"
                          ? message?.customerId?._id
                          : message?.customerId;
                      const creator = customerId === userId ? true : false;
                      var check = creator
                        ? message?.byCustomer
                        : message?.byOtherCustomer;
                      const previousChatTime = `${new Date(
                        allMessages[index - 1]?.createdAt
                      ).getHours()}:${new Date(
                        allMessages[index - 1]?.createdAt
                      ).getMinutes()}`;
                      const chatTime = `${new Date(
                        message.createdAt
                      ).getHours()}:${new Date(
                        message.createdAt
                      ).getMinutes()}`;

                      const isCountinousMessage =
                        allMessages[index - 1]?.customerId._id ===
                          message.customerId._id &&
                        previousChatTime === chatTime;

                      return (
                        <Fragment key={index}>
                          {check ? (
                            <div className="chat-right">
                              <div style={{ marginLeft: "auto" }}>
                                {message?.attachment?.length > 0 && (
                                  <SenderSidePostViewer
                                    attachment={message?.attachment}
                                    artistDetailss={artistDetailss}
                                  />
                                )}
                                {message?.msg && (
                                  <>
                                    {message?.attachmentType === "tip" &&
                                    Number(message?.tipAmount) > 0 ? (
                                      <div className="rounded p-1">
                                        <div className="d-flex align-items-center">
                                          <span className="mx-2 pointer tagcol">
                                            <img
                                              src={WhiteDollerSVG}
                                              className="actionIcon"
                                            />
                                          </span>
                                          <span>{`I sent you a $${
                                            message?.tipAmount &&
                                            Number(message?.tipAmount)?.toFixed(
                                              2
                                            )
                                          } tip`}</span>
                                          <span className="px-2">
                                            <img
                                              src={HeartSVG}
                                              className="actionIcon"
                                            />
                                          </span>
                                        </div>
                                        <h6 className="text-small py-1 px-2">
                                          {message?.msg}
                                        </h6>
                                      </div>
                                    ) : (
                                      <div className="rounded">
                                        <h6 className="text-small py-1 px-2">
                                          {message?.msg}
                                        </h6>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!isCountinousMessage && (
                                  <h6
                                    className="timing_col my-1 mb-2 mx-2 text-start"
                                    style={{ color: "rgb(164, 164, 164)" }}
                                  >
                                    {getTimeAgo(message?.createdAt)}
                                  </h6>
                                )}
                              </div>
                              <span className="ms-2">
                                {message?.otherCustomerId?.firstName
                                  ? getProfileImg(
                                      message?.otherCustomerId,
                                      "right"
                                    )
                                  : getProfileImg(
                                      message?.otherCustomerData,
                                      "right"
                                    )}
                              </span>
                            </div>
                          ) : (
                            <div className="chat-left">
                              <span className="me-2">
                                {message?.customerId?.firstName
                                  ? getProfileImg(message?.customerId, "left")
                                  : getProfileImg(
                                      message?.customerData,
                                      "left"
                                    )}
                              </span>

                              <div style={{ marginRight: "auto" }}>
                                {message?.attachment?.length > 0 && (
                                  <ReceiverSidePostViewer
                                    attachment={message?.attachment}
                                    price={message?.price}
                                    data={message}
                                    refreshList={getChatHistory}
                                  />
                                )}
                                {message?.msg !== "" && (
                                  <>
                                    {message?.attachmentType === "tip" &&
                                    Number(message?.tipAmount) > 0 ? (
                                      <div className="rounded p-1">
                                        <div className="d-flex align-items-center">
                                          <span className="mx-2 pointer tagcol">
                                            <img
                                              src={BlackDollerSVG}
                                              className="actionIcon"
                                            />
                                          </span>
                                          <span>{`I sent you a $${
                                            message?.tipAmount &&
                                            Number(message?.tipAmount)?.toFixed(
                                              2
                                            )
                                          } tip`}</span>
                                          <span className="px-2">
                                            <img
                                              src={BlackHeartSVG}
                                              className="actionIcon"
                                            />
                                          </span>
                                        </div>
                                        <h6 className="text-small py-1 px-2">
                                          {message?.msg}
                                        </h6>
                                      </div>
                                    ) : (
                                      <div className="rounded">
                                        <h6 className="text-small py-1 px-2">
                                          {message?.msg}
                                        </h6>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!isCountinousMessage && (
                                  <h6
                                    className="timing_col my-1 mb-2 mx-2 text-start"
                                    style={{ color: "rgb(164, 164, 164)" }}
                                  >
                                    {getTimeAgo(message?.createdAt)}
                                  </h6>
                                )}
                              </div>
                            </div>
                          )}
                          <div ref={chatSectionRef} />
                        </Fragment>
                      );
                    })}
                </InfiniteScroll>
              )}
            </div>
          </div>
          <div className="msgArea">
            <div className="d-flex justify-content-end">
              <div className="msgLine w-100"></div>
            </div>
            <div className="msgareaRight  px-3 pt-3">
              {messageType === "paid" && (
                <div className="d-flex  freePaidMsgSection mb-2 position-static">
                  <div className="leftFree  ">
                    <div className="d-flex  mb-2 align-items-center">
                      <TbTagOff size={18} color="#101010" />
                      <p className="text-sm mb-0 mx-2">Free Preview</p>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      {allMedia?.map((media, ind) => {
                        return (
                          <Fragment key={ind}>
                            {media?.type === "image" &&
                              media?.priceType === "free" && (
                                <div className="freePaidImg">
                                  <img src={media?.url} alt="" />
                                  <AiOutlineCloseCircle
                                    className="closeIcons"
                                    size={18}
                                    color="black"
                                    onClick={() => handleRemoveMedia(ind)}
                                  />
                                  <BsArrowRightCircleFill
                                    onClick={() => handleMediaType(ind, "paid")}
                                    size={19}
                                    className="pointer imgLeftArrow"
                                    color={"#00aff0"}
                                  />
                                </div>
                              )}
                            {media?.type === "video" &&
                              media?.priceType === "free" && (
                                <div className="freePaidImg">
                                  <CustomVideoPlayer mediaItem={media} />
                                  {/* <ReactPlayer
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                    onContextMenu={(e) => e.preventDefault()}
                                    url={media?.url}
                                    width={"100%"}
                                    // style={{ minHeight: "130px" }}
                                    // height={"130px"}
                                    controls={false}
                                    volume={false}
                                    className="custom-player"
                                  /> */}
                                  <AiOutlineCloseCircle
                                    className="closeIcons"
                                    size={18}
                                    color="black"
                                    onClick={() => handleRemoveMedia(ind)}
                                  />
                                  <BsArrowRightCircleFill
                                    onClick={() => handleMediaType(ind, "paid")}
                                    size={19}
                                    className="pointer imgLeftArrow"
                                    color={"#00aff0"}
                                  />
                                </div>
                              )}
                            {media?.type === "audio" &&
                              media?.priceType === "free" && (
                                <div className="freePaidImg">
                                  <audio
                                    src={media?.url}
                                    controls
                                    style={{ background: "white" }}
                                  />
                                  <AiOutlineCloseCircle
                                    className="closeIcons"
                                    size={18}
                                    color="black"
                                    onClick={() => handleRemoveMedia(ind)}
                                  />
                                  <BsArrowRightCircleFill
                                    onClick={() => handleMediaType(ind, "paid")}
                                    size={19}
                                    className="pointer imgLeftArrow"
                                    color={"#00aff0"}
                                  />
                                </div>
                              )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="rightPaid ">
                    <div className="d-flex justify-content-between mb-2">
                      <div
                        className="d-flex align-items-center pointer"
                        onClick={() => setShowMessagePriceModal(true)}
                      >
                        <TbTag size={18} color="#101010" />
                        <p className="text-sm mb-0 mx-2">Paid View</p>
                      </div>
                      <div className="d-flex">
                        <p className="text-sm mb-0 mx-2">{messagePrice} $</p>
                        <AiOutlineCloseCircle
                          size={18}
                          color="#101010"
                          className="pointer"
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      {allMedia?.map((media, ind) => {
                        return (
                          <Fragment key={ind}>
                            {media?.type === "image" &&
                              media?.priceType === "paid" && (
                                <div className="freePaidImg">
                                  <img src={media?.url} alt="" />
                                  <AiOutlineCloseCircle
                                    className="closeIcons"
                                    size={18}
                                    color="black"
                                    onClick={() => handleRemoveMedia(ind)}
                                  />
                                  <BsFillArrowLeftCircleFill
                                    onClick={() => handleMediaType(ind, "free")}
                                    size={19}
                                    className="pointer imgrightarrow"
                                    color={"#00aff0"}
                                  />
                                </div>
                              )}
                            {media?.type === "video" &&
                              media?.priceType === "paid" && (
                                <div className="freePaidImg">
                                  <CustomVideoPlayer mediaItem={media} />
                                  {/* <ReactPlayer
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                    onContextMenu={(e) => e.preventDefault()}
                                    url={media?.url}
                                    width={"100%"}
                                    // style={{ minHeight: "130px" }}
                                    // height={"130px"}
                                    controls={false}
                                    volume={false}
                                    className="custom-player"
                                  /> */}
                                  <AiOutlineCloseCircle
                                    className="closeIcons"
                                    size={18}
                                    color="black"
                                    onClick={() => handleRemoveMedia(ind)}
                                  />
                                  <BsFillArrowLeftCircleFill
                                    onClick={() => handleMediaType(ind, "free")}
                                    size={19}
                                    className="pointer imgrightarrow"
                                    color={"#00aff0"}
                                  />
                                </div>
                              )}
                            {media?.type === "audio" &&
                              media?.priceType === "paid" && (
                                <div className="freePaidImg">
                                  <audio
                                    src={media?.url}
                                    controls
                                    style={{ background: "white" }}
                                  />
                                  <AiOutlineCloseCircle
                                    className="closeIcons"
                                    size={18}
                                    color="black"
                                    onClick={() => handleRemoveMedia(ind)}
                                  />
                                  <BsFillArrowLeftCircleFill
                                    onClick={() => handleMediaType(ind, "free")}
                                    size={19}
                                    className="pointer imgrightarrow"
                                    color={"#00aff0"}
                                  />
                                </div>
                              )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {messageType === "free" && allMedia.length !== 0 && (
                <div className="freePaidMsgSection justify-content-start pt-3 freemsgssection position-static rounded-1">
                  {allMedia?.map((media, ind) => {
                    return (
                      <Fragment key={ind}>
                        {media?.type === "image" &&
                          media?.priceType === "free" && (
                            <div className="freePaidImg">
                              <img src={media?.url} alt="" />
                              <AiOutlineCloseCircle
                                className="closeIcons"
                                size={18}
                                color="black"
                                onClick={() => handleRemoveMedia(ind)}
                              />
                            </div>
                          )}
                        {media?.type === "video" &&
                          media?.priceType === "free" && (
                            <div className="freePaidImg">
                              <CustomVideoPlayer mediaItem={media} />
                              {/* <ReactPlayer
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                                url={media?.url}
                                width={"100%"}
                                // style={{ minHeight: "130px" }}
                                // height={"130px"}
                                controls={false}
                                volume={false}
                                className="custom-player"
                              /> */}
                              <AiOutlineCloseCircle
                                className="closeIcons"
                                size={18}
                                color="black"
                                onClick={() => handleRemoveMedia(ind)}
                              />
                            </div>
                          )}
                        {media?.type === "audio" &&
                          media?.priceType === "free" && (
                            <div className="freePaidImg">
                              <audio
                                src={media?.url}
                                controls
                                style={{ background: "white" }}
                              />
                              <AiOutlineCloseCircle
                                className="closeIcons"
                                size={18}
                                color="black"
                                onClick={() => handleRemoveMedia(ind)}
                              />
                            </div>
                          )}
                      </Fragment>
                    );
                  })}
                </div>
              )}

              <div className="d-flex justify-content-between align-items-center">
                <TextareaAutosize
                  placeholder={t("Type a message")}
                  value={chatMessage}
                  onChange={(e) => {
                    setChatMessage(e.target.value);
                  }}
                  onKeyDown={(e) => handleKeyPress(e)}
                  minRows={1}
                  maxRows={3}
                />
                <button className="sendMsg ms-3" onClick={handleMessageSend}>
                  {t("Send")}
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2 lg-my-4 mx-2 lg-mx-4">
                <div>
                  <span className="pointer mx-1">
                    <input
                      type="file"
                      id="uploadFile"
                      accept="image/*"
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <label
                      htmlFor="uploadFile"
                      className="pointer"
                      data-tooltip-id="Add media"
                    >
                      {imageLoader && attachmentType === "image" ? (
                        <SmallLoader color="text-primary" />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g opacity="0.5" clip-path="url(#clip0_92_11593)">
                            <path
                              d="M17.4443 0H2.55566C1.14645 0 0 1.14648 0 2.5557V17.4443C0 18.8535 1.14645 20 2.55566 20H17.4443C18.8536 20 20 18.8535 20 17.4443V2.5557C20 1.14648 18.8536 0 17.4443 0ZM18.822 17.4443C18.822 18.204 18.2039 18.822 17.4443 18.822H2.55566C1.79602 18.822 1.17801 18.2039 1.17801 17.4443V15.2956L5.05516 11.9968C5.12346 11.9385 5.21018 11.9062 5.29997 11.9057C5.38977 11.9051 5.47686 11.9364 5.54586 11.9938L7.97453 14.0105C8.20871 14.205 8.55246 14.189 8.76766 13.9736L14.5383 8.19414C14.6426 8.08965 14.7642 8.07949 14.8277 8.08273C14.891 8.08598 15.0111 8.10855 15.1042 8.2232L18.822 12.8009L18.822 17.4443ZM18.822 10.9322L16.0186 7.48043C15.8808 7.31097 15.709 7.17231 15.5142 7.07339C15.3195 6.97448 15.1062 6.91751 14.888 6.90617C14.4479 6.88391 14.0163 7.04961 13.7047 7.36172L8.31375 12.761L6.29852 11.0876C6.01634 10.8526 5.66016 10.7249 5.29294 10.7271C4.92571 10.7293 4.57109 10.8612 4.29176 11.0996L1.17801 13.7489V2.5557C1.17801 1.79605 1.79602 1.17805 2.55566 1.17805H17.4443C18.204 1.17805 18.822 1.79605 18.822 2.5557V10.9322Z"
                              fill="black"
                            />
                            <path
                              d="M6.29592 2.46094C4.72971 2.46094 3.45557 3.73516 3.45557 5.30129C3.45557 6.86746 4.72975 8.14164 6.29592 8.14164C7.86209 8.14164 9.13627 6.86746 9.13627 5.30129C9.13627 3.73512 7.86213 2.46094 6.29592 2.46094ZM6.29592 6.96363C5.37928 6.96363 4.63357 6.21789 4.63357 5.30129C4.63357 4.38465 5.37928 3.63895 6.29592 3.63895C7.21256 3.63895 7.95826 4.38469 7.95826 5.30129C7.95826 6.21789 7.21256 6.96363 6.29592 6.96363Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_92_11593">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </label>
                  </span>
                  {/* <span
                    className="mx-2"
                    data-tooltip-id="Message with tips appear at the top of the recipient box"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_92_11589)">
                        <path
                          d="M11 22C8.0618 22 5.29942 20.8558 3.2218 18.7782C1.14417 16.7006 0 13.9382 0 11C0 8.0618 1.14421 5.29947 3.2218 3.2218C5.29938 1.14413 8.0618 0 11 0C13.9382 0 16.7006 1.14417 18.7782 3.2218C20.8558 5.29942 22 8.0618 22 11C22 13.9382 20.8558 16.7005 18.7782 18.7782C16.7006 20.8559 13.9382 22 11 22ZM11 1.375C5.69276 1.375 1.375 5.69276 1.375 11C1.375 16.3072 5.69276 20.625 11 20.625C16.3072 20.625 20.625 16.3072 20.625 11C20.625 5.69276 16.3072 1.375 11 1.375Z"
                          fill="black"
                        />
                        <path
                          d="M11 10.3125C10.0523 10.3125 9.28125 9.54147 9.28125 8.59375C9.28125 7.64603 10.0523 6.875 11 6.875C11.9477 6.875 12.7188 7.64603 12.7188 8.59375C12.7188 8.97342 13.0265 9.28125 13.4062 9.28125C13.786 9.28125 14.0938 8.97342 14.0938 8.59375C14.0938 7.12418 13.0634 5.89153 11.6875 5.57795V4.8125C11.6875 4.43283 11.3797 4.125 11 4.125C10.6203 4.125 10.3125 4.43283 10.3125 4.8125V5.57795C8.93655 5.89153 7.90625 7.12418 7.90625 8.59375C7.90625 10.2997 9.2941 11.6875 11 11.6875C11.9477 11.6875 12.7188 12.4585 12.7188 13.4062C12.7188 14.354 11.9477 15.125 11 15.125C10.0523 15.125 9.28125 14.354 9.28125 13.4062C9.28125 13.0266 8.97346 12.7188 8.59375 12.7188C8.21404 12.7188 7.90625 13.0266 7.90625 13.4062C7.90625 14.8758 8.93655 16.1085 10.3125 16.4221V17.1875C10.3125 17.5672 10.6203 17.875 11 17.875C11.3797 17.875 11.6875 17.5672 11.6875 17.1875V16.4221C13.0634 16.1085 14.0938 14.8758 14.0938 13.4062C14.0938 11.7003 12.7059 10.3125 11 10.3125Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_92_11589">
                          <rect width="22" height="22" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span> */}
                  <span className="uploadvideo_icon mx-1">
                    <input
                      type="file"
                      id="uploadVideo"
                      accept="video/*"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <label htmlFor="uploadVideo" className="pointer">
                      {imageLoader && attachmentType === "video" ? (
                        <SmallLoader color="text-primary" />
                      ) : (
                        <MdSlowMotionVideo
                          size={25}
                          color="#9c9c9c"
                          data-tooltip-id="Upload video"
                        />
                      )}
                    </label>
                  </span>
                  <span
                    className="mx-1 pointer"
                    onClick={() => setShowVideoRecorder(true)}
                  >
                    <BiVideo
                      size={25}
                      color="#9c9c9c"
                      data-tooltip-id="Record video"
                    />
                  </span>
                  <span
                    className="mx-1 pointer"
                    onClick={() => setShowAudioRecorder(true)}
                  >
                    <BiMicrophone
                      size={25}
                      color="#9c9c9c"
                      data-tooltip-id="Record voice"
                    />
                  </span>

                  <span
                    className="mx-1 pointer"
                    onClick={() => setShowMessagePriceModal(true)}
                  >
                    <BsTag
                      size={25}
                      color="#9c9c9c"
                      data-tooltip-id="Message Price"
                    />
                  </span>
                  {/* <span className="mx-1">
                    <MdOutlineEmojiEmotions
                      size={25}
                      color="#9c9c9c"
                      data-tooltip-id="Emoji"
                    >
                      <EmojiPicker />
                    </MdOutlineEmojiEmotions>
                  </span> */}

                  <span
                    className="mx-1 pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowEmojiPicker(!showEmojiPicker);
                    }}
                  >
                    <MdOutlineEmojiEmotions
                      size={25}
                      color="#9c9c9c"
                      data-tooltip-id="Emoji"
                    />
                    {showEmojiPicker && (
                      <div
                        style={{
                          position: "absolute",
                          left: 14,
                          bottom: 80,
                          zIndex: 1,
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <EmojiPicker
                          theme="auto"
                          onEmojiClick={handleEmojiClick}
                        />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {allFollowedArtist.length == 0 && chatLists?.length == 0 && (
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ minHeight: "calc(100vh - 60px)" }}
            >
              <h6 className="text-normal">
                {t("Please subscribe to a creator to access this feature.")}
              </h6>
            </div>
          )}
          {!artistId &&
            (allFollowedArtist?.length !== 0 || chatLists?.length !== 0) && (
              <div
                className="d-flex justify-content-center align-items-center "
                style={{ minHeight: "calc(100vh - 60px)" }}
              >
                <h6 className="text-normal">
                  {t("Start your chatting with your favorite")}
                </h6>
              </div>
            )}
        </>
      )}

      <ConfirmDeleteChat
        confirmDelete={confirmDeleteChat}
        show={showDeleteChatModal}
        onHide={() => setShowDeleteChatModal(false)}
      />

      <ImageVideoViewer
        show={showViewer}
        onHide={() => setShowViewer(false)}
        mediaType={selectedMedia?.mediaType}
        url={selectedMedia?.url}
        userName={selectedMedia?.userName}
      />

      <VideoRecorederModa
        show={showVideoRecorder}
        onhide={() => setShowVideoRecorder(false)}
        saveVideo={saveVideo}
        videoData={videoData}
        setVideoData={setVideoData}
      />
      <AudioRecorders
        show={showAudioRecorder}
        onhide={() => setShowAudioRecorder(false)}
        saveAudio={saveAudio}
        setAudioData={setAudioData}
        audioData={audioData}
      />
      <MessagePrice
        show={showMessagePriceModal}
        onHide={() => setShowMessagePriceModal(false)}
        messagePrice={messagePrice}
        setMessagePrice={setMessagePrice}
        setMessageType={setMessageType}
      />
      <ProgressBarModal
        show={showProgressModal}
        uploadedPercent={uploadedPercent}
        onhide={() => setShowProgressModal(false)}
      />
      {/* here we are adding all the tooltip section */}
      <ReactTooltip id="Add media" place="top" content={t("Add media")} />
      <ReactTooltip
        id="Message with tips appear at the top of the recipient box"
        place="top"
        content={t("Message with tips appear at the top of the recipient box")}
      />
      <ReactTooltip id="Upload video" place="top" content={t("Upload video")} />
      <ReactTooltip id="Record video" place="top" content={t("Record video")} />
      <ReactTooltip id="Record voice" place="top" content={t("Record voice")} />
      <ReactTooltip
        id="Message Price"
        place="top"
        content={t("Message Price")}
      />
    </div>
  );
};

export default EntertainerMessageSection;
