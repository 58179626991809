import React from "react";
import Logo from "../../../images/logoW.png";

const Fullpageloader = () => {
  return (
    <section className="Fullpageloader">
      <div className="innner_logo zoom-in-zoom-out">
        <img src={Logo} alt="" className="img-fluid" />
      </div>
    </section>
  );
};

export default Fullpageloader;
