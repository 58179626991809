import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AuthLayout from "../../../layout/AuthLayout";
import "../../../css/style.css";
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/actions";
import { toast } from "react-toastify";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import SocialLogin from "../SocialLogin/SocialLogin";
import { useTranslation } from "react-i18next";

export default function Loginsubscribe(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { buttonLoader } = useSelector((s) => s.login);
  const [showPassword, setShowPassword] = useState(false);
  const { role } = useSelector((s) => s.setting);
  const { artistDetailss } = props;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      loginType: "email",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid Email")),
      password: Yup.string()
        .required(t("Password is required"))
        .min(3, t("Password must be greater than 3 character")),
      // .max(10, t("Password must be less than 10 character")),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(login(values))
        .unwrap()
        .then((res) => {
          if (res) {
            props.onhide();
            const path = generatePath("/:slug", {
              slug: slug,
            });
            navigate(`/${artistDetailss?.userName}`);
            toast.success(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  return (
    <div className="loginCont modal_loginCont w-100">
      <h5 className="text-black text-normal mb-4">{t("Log in")}</h5>
      <div className="Login_input_warp form-floating">
        <input
          type="email"
          class="form-control"
          id="floatingInput"
          placeholder="Email"
          name={t("email")}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <label for="floatingInput">{t("Email")}</label>
        {formik.touched.email && formik.errors.email && (
          <span className="text-danger errorText">{formik.errors.email}</span>
        )}
      </div>
      <div className="mt-4 position-relative Login_input_warp form-floating">
        <input
          type={showPassword ? "text" : "password"}
          name={t("password")}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="form-control"
          id="floatingInput"
          placeholder="Password"
        />
        <label for="floatingInput">{t("Password")}</label>

        <div className="eyeIcon">
          {showPassword ? (
            <AiOutlineEye
              color=""
              size={23}
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <AiOutlineEyeInvisible
              color=""
              size={23}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
      </div>
      <div className="mt-4">
        {buttonLoader ? (
          <button className="loginBtn2 text-light">
            <ButtonLoader />
          </button>
        ) : (
          <button
            className={` text-light ${
              !formik.isValid || !formik.dirty ? "disabled-btn" : "loginBtn2"
            }`}
            onClick={formik.handleSubmit}
          >
            {t("Login")}
          </button>
        )}
      </div>
      <div className="mt-4 px-lg-5 d-flex justify-content-center align-items-center">
        {/* <h4
          className="text-black text-normal pointer"
          onClick={() => navigate("/forgot-password")}
        >
          {" "}
          {t("Forgot Password?")}
        </h4> */}
        <h4
          className="text-black text-normal pointer"
          onClick={props.toggleform}
        >
          {t("Sign up for Fansroom")}
        </h4>
      </div>

      {/* <SocialLogin /> */}
    </div>
  );
}
