import React from "react";
import SubscriptionsComponent from "../../../components/SideTabs/Subscriptions";
import MainLayout from "../../../layout/MainLayout";

const Subscriptions = () => {
  return (
    <MainLayout>
      <SubscriptionsComponent />
    </MainLayout>
  );
};

export default Subscriptions;
