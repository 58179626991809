import React from "react";
import AuthLayout from "../../layout/AuthLayout";
import ResetPasswordComponent from "../../components/AuthComponent/ResetPassword";

const ResetPassword = () => {
  return (
    <AuthLayout>
      <ResetPasswordComponent />
    </AuthLayout>
  );
};

export default ResetPassword;
