import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addStoryPromotion } from "../../../redux/actions";
import { toast } from "react-toastify";
import SmallLoader from "../ButtonLoader/SmallLoader";

const StoryPromotionPop = ({
  StoryPromotion,
  setStoryPromotion,
  getUserData,
}) => {
  const { buttonLoader } = useSelector((s) => s.setting);
  const { t } = useTranslation();
  const handleStoryPromotion = () => {
    setStoryPromotion(!StoryPromotion);
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      type: ["all"],
      campaign: "free",
      subscribeDays: 5,
      subscribeCounts: 10,
      finishedAt: 6,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      message: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(addStoryPromotion(values))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          setStoryPromotion(false);
          getUserData();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
  });

  return (
    <>
      <Modal
        show={StoryPromotion}
        className="StoryPromotion"
        onHide={handleStoryPromotion}
        backdrop="static"
        keyboard={false}
        centered
        scrollable="true"
      >
        <Modal.Header className="pt-3 pb-0 border-0" closeButton>
          <Modal.Title>{t("Promotion Campaign For")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled ps-0 mb-0 radioFilter radioSelect gap-10 d-flex align-items-center justify-content-start">
            <li className="position-relative">
              <input
                type="radio"
                name="type"
                className="position-absolute  w-100 h-100"
                checked={formik.values.type.includes("all")}
                onChange={() => formik.setFieldValue("type", ["all"])}
              />
              <div className="filterBtn rounded-pill">
                <h6 className="m-0 fw-bold">{t("New subscriber")}</h6>
              </div>
            </li>
            <li className="position-relative">
              <input
                type="radio"
                name="type"
                className="position-absolute  w-100 h-100"
                checked={formik.values.type.includes("expired")}
                onChange={() => formik.setFieldValue("type", ["expired"])}
              />
              <div className="filterBtn rounded-pill">
                <h6 className="m-0 fw-bold">{t("Expired subscribers")}</h6>
              </div>
            </li>
            <li className="position-relative">
              <input
                type="radio"
                name="type"
                className="position-absolute  w-100 h-100"
                checked={
                  formik.values.type.includes("expired") &&
                  formik.values.type.includes("all")
                }
                onChange={() =>
                  formik.setFieldValue("type", ["expired", "all"])
                }
              />
              <div className="filterBtn rounded-pill">
                <h6 className="m-0 fw-bold">{t("Both New and Expired")} </h6>
              </div>
            </li>
          </ul>
          <Form className="border-top mt-4 pt-3">
            <Row>
              <Col lg="12" className="my-2">
                <ul className="list-unstyled ps-0 mb-0 radioFilter radioSelect gap-10 d-flex align-items-center justify-content-start">
                  <li className="position-relative">
                    <input
                      type="radio"
                      name="filter"
                      className="position-absolute  w-100 h-100"
                      checked={formik.values.campaign === "free"}
                      onChange={() => formik.setFieldValue("campaign", "free")}
                    />
                    <div className="filterBtn rounded-pill">
                      <h6 className="m-0 fw-bold">{t("Free Trial")}</h6>
                    </div>
                  </li>
                  <li className="position-relative">
                    <input
                      type="radio"
                      name="filter"
                      className="position-absolute  w-100 h-100"
                      checked={formik.values.campaign === "month"}
                      onChange={() => formik.setFieldValue("campaign", "month")}
                    />
                    <div className="filterBtn rounded-pill">
                      <h6 className="m-0 fw-bold">
                        {t("First Month Discount")}
                      </h6>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label">
                  {t("Offer Limit")}
                </label>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  name="subscribeCounts"
                  value={formik.values.subscribeCounts}
                  onChange={formik.handleChange}
                >
                  <option value={1}> 1 {t("subscriber")}</option>
                  <option value={2}> 2 {t("subscriber")}</option>
                  <option value={3}> 3 {t("subscriber")}</option>
                  <option value={4}> 4 {t("subscriber")}</option>
                  <option value={5}> 5 {t("subscriber")}</option>
                  <option value={6}> 6 {t("subscriber")}</option>
                  <option value={7}> 7 {t("subscriber")}</option>
                  <option value={8}> 8 {t("subscriber")}</option>
                  <option value={9}> 9 {t("subscriber")}</option>
                  <option value={10}> 10 {t("subscriber")}</option>
                  <option value={20}> 20 {t("subscriber")}</option>
                  <option value={50}> 50 {t("subscriber")}</option>
                </Form.Select>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label">
                  Offer Expiration
                </label>
                <Form.Select
                  className="form-control"
                  aria-label="Default select example"
                  value={formik.values.finishedAt}
                  name="finishedAt"
                  onChange={formik.handleChange}
                >
                  <option value={1}>1 {t("days")}</option>
                  <option value={2}>2 {t("days")}</option>
                  <option value={3}>3 {t("days")}</option>
                  <option value={4}>4 {t("days")}</option>
                  <option value={5}>5 {t("days")}</option>
                  <option value={6}>6 {t("days")}</option>
                  <option value={7}>7 {t("days")}</option>
                  <option value={8}>8 {t("days")}</option>
                  <option value={9}>9 {t("days")}</option>
                  <option value={10}>10 {t("days")}</option>
                  <option value={11}>11 {t("days")}</option>
                  <option value={15}>15 {t("days")}</option>
                  <option value={30}>30 {t("days")}</option>
                </Form.Select>
              </Col>
              {formik.values.campaign === "month" && (
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label">
                    Discount Percent
                  </label>
                  <Form.Select
                    className="form-control"
                    aria-label="Default select example"
                    value={formik.values.subscribeDays}
                    name="subscribeDays"
                    onChange={formik.handleChange}
                  >
                    <option value={5}>5 {t("percent")}</option>
                    <option value={10}>10 {t("percent")}</option>
                    <option value={15}>15 {t("percent")}</option>
                    <option value={20}>20 {t("percent")}</option>
                    <option value={25}>25 {t("percent")}</option>
                    <option value={30}>30 {t("percent")}</option>
                    <option value={35}>35 {t("percent")}</option>
                    <option value={40}>40 {t("percent")}</option>
                    <option value={45}>45 {t("percent")}</option>
                    <option value={50}>50 {t("percent")}</option>
                    <option value={55}>55 {t("percent")}</option>
                    <option value={60}>60 {t("percent")}</option>
                    <option value={65}>65 {t("percent")}</option>
                    <option value={70}>70 {t("percent")}</option>
                    <option value={75}>75 {t("percent")}</option>
                    <option value={80}>80 {t("percent")}</option>
                    <option value={85}>85 {t("percent")}</option>
                    <option value={90}>90 {t("percent")}</option>
                    <option value={95}>95 {t("percent")}</option>
                  </Form.Select>
                  <p className="text-muted formText pt-2 m-0">
                    {t("Promotional subscription $28.50 USD per month.")} <br />
                    {/* {t("be subscribed for $23 automatically, only by choice")} */}
                  </p>
                </Col>
              )}
              {formik.values.campaign === "free" && (
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label">
                    {t("Free trail duration")}
                  </label>
                  <Form.Select
                    className="form-control"
                    aria-label="Default select example"
                    value={formik.values.subscribeDays}
                    name="subscribeDays"
                    onChange={formik.handleChange}
                  >
                    <option value={1}>1 {t("days")}</option>
                    <option value={2}>2 {t("days")}</option>
                    <option value={3}>3 {t("days")}</option>
                    <option value={4}>4 {t("days")}</option>
                    <option value={5}>5 {t("days")}</option>
                    <option value={6}>6 {t("days")}</option>
                    <option value={7}>7 {t("days")}</option>
                    <option value={8}>8 {t("days")}</option>
                    <option value={9}>9 {t("days")}</option>
                    <option value={10}>10 {t("days")}</option>
                    <option value={11}>11 {t("days")}</option>
                    <option value={15}>15 {t("days")}</option>
                    <option value={30}>30 {t("days")}</option>
                  </Form.Select>
                  <p className="text-muted formText pt-2 m-0">
                    {t("Promotional subscription $0.00 USD for 29 days.")}{" "}
                    <br />
                    {t("be subscribed for $23 automatically, only by choice")}
                  </p>
                </Col>
              )}
              <Col lg="12" className="my-2">
                <input
                  type="text"
                  placeholder="Message (Optional)"
                  className="form-control"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  name="message"
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button
            variant="transparent"
            className="border-0 p-0 px-3 fw-bold"
            onClick={handleStoryPromotion}
          >
            {t("Cancel")}
          </Button>
          {buttonLoader ? (
            <Button
              variant="transparent"
              className="border-0 p-0 px-3 fw-bold"
              style={{ width: "100%" }}
            >
              <SmallLoader color={"text-primary"} />
            </Button>
          ) : (
            <Button
              variant="transparent"
              className="border-0 p-0 px-3 fw-bold"
              onClick={formik.handleSubmit}
            >
              {t("Start Campaign")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StoryPromotionPop;
