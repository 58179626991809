import React, { useState, useMemo, useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addCardData, getCardList } from "../../../redux/states/card/thunk";
import SmallLoader from "../../common/ButtonLoader/SmallLoader";
import Visscards from "../VisaCards";
import { campaignDetail } from "../../../redux/actions";

const useOptions = () => {
  const fontSize = "18px";
  const options = useMemo(
    () => ({
      style: {
        border: "2px solid green",
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          height: "45px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const Addpaymentmod = (props) => {
  const { onSuccess, hideBack } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const stripe = useStripe();
  // const elements = useElements();
  // const options2 = useOptions();
  const [country, setCountry] = useState("");
  // const options = useMemo(() => countryList().getData(), []);
  const [options, setOptions] = useState([]);
  const campaignData = useSelector((s) => s.common.campaignDetail);
  const [isAdult, setIsAdult] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // from  konnective CRM
    if (props?.show) {
      dispatch(campaignDetail());
    }
  }, [props?.show]);

  useEffect(() => {
    if (campaignData && campaignData?.[0]?.countries) {
      const list = campaignData?.[0]?.countries?.map((val) => {
        return {
          label: val?.countryName,
          value: val?.countryCode,
        };
      });
      setOptions(list);
    }
  }, [campaignData]);

  const formik = useFormik({
    initialValues: {
      token: "",
      email: "",
      lastd: "",
      name: "",
      country: "",
      state: "",
      city: "",
      street: "",
      postalCode: "",
      cardHolder: "",
      type: "card",
    },
    onSubmit: async function (values, { resetForm }) {
      setLoading(true);

      dispatch(addCardData(values))
        .unwrap()
        .then((res) => {
          toast?.success(res?.message);
          resetForm();
          setIsAdult(false);
          setAgreed(false);
          setLoading(false);
          dispatch(getCardList())
            .unwrap()
            .then(() => {
              if (typeof onSuccess === "function") {
                onSuccess();
              } else {
                navigate("/payments");
              }
              props.onhide();
            });
        })
        .catch((err) => {
          toast.error(err?.message);
          setLoading(false);
        });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("Email is required"))
        .email(t("Invalid Email")),
      cardHolder: Yup.string().required("Card holder name is required"),
      country: Yup.string().required(t("Country is required")),
      state: Yup.string().required(t("State is required")),
      city: Yup.string().required(t("City is required")),
      street: Yup.string().required(t("Street is required")),
      postalCode: Yup.string().required(t("Postal Code is required")),
      cardNumber: Yup.string()
        .length(16, "Card number must be exactly 16 digits")
        .matches(/^\d+$/, "Card number must contain only numeric digits")
        .required("Card number is required"),
      cardMonth: Yup.string()
        .length(2, "Card expiry month be exactly 2 digits")
        .test(
          "is-valid-month",
          "Card expiry month must be a number between 1 and 12",
          (value) => {
            const monthNumber = parseInt(value, 10);
            return !isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12;
          }
        )
        .required("Card expiry month is required"),

      cardYear: Yup.string()
        .length(4, "Card expiry year must be exactly 4 digits")
        .matches(/^\d+$/, "Card expiry year must be a valid number")
        .test(
          "is-not-in-past",
          "Card expiry year must not be in the past",
          function (value) {
            const year = parseInt(value, 10);
            return !isNaN(year) && year >= new Date().getFullYear();
          }
        )
        .required("Card expiry year is required"),
      cardSecurityCode: Yup.string()
        .length(3, "Card security code must be exactly 3 digits")
        .matches(/^\d+$/, "Card security code must be a valid number")
        .required("Card expiry year is required"),
    }),
  });

  useEffect(() => {
    formik.setFieldValue("country", country?.value);
  }, [country]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(formik.errors).length === 0) {
      setLoading(true);
      formik.handleSubmit();
      // if (!stripe || !elements) {
      //   return;
      // }
      // const { token, error } = await stripe.createToken(
      //   elements.getElement(CardNumberElement)
      // );
      // if (error) {
      //   setLoading(false);
      //   toast.error(error?.message);
      // } else {
      //   formik.setFieldValue("token", token?.id);
      //   formik.setFieldValue("lastd", token?.card?.last4);
      //   formik.setFieldValue("type", token?.type);
      //   formik.setFieldValue("name", token?.card?.brand);

      //   setTimeout(() => {
      //     formik.handleSubmit();
      //   }, 1000);
      // }
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <Modal
      {...props}
      keyboard={false}
      centered
      size="md"
      className="add_payment"
    >
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center px-2 border-bottom pb-3">
          <h4 className="text-bold m-0">
            {!hideBack && (
              <span onClick={() => navigate(-1)} className="pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 12L11 6M5 12L11 18M5 12L19 12"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            )}
            <span>{t("ADD CARD")}</span>
          </h4>
        </div>
        <div className="modal_Addpayment">
          <Col lg="12">
            <div className="rounded modal_addpayment">
              <div className="my-2">
                <h6 className="light-text mb-1 mx-2">{t("BILLING DETAILS")}</h6>
                <p className="text-sm mx-2 mb-2">
                  {t(
                    "We are fully compliant with Payment Card Industry Data Security Standards."
                  )}
                </p>
              </div>

              {/* form started here */}
              <div className="my-3">
                <Row>
                  <Col lg="12" md="12" sm="12" className="my-2">
                    <div className="addinput_filed form-floating">
                      <input
                        type="text"
                        placeholder={t("Street")}
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.street}
                        onChange={formik.handleChange}
                        name="street"
                        onBlur={formik.onBlur}
                      />
                      <label for="floatingInput">{t("Street")}</label>
                      {formik.touched.street && formik.errors.street && (
                        <span className="text-danger errorText">
                          {formik.errors.street}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="my-2">
                    <div className="addinput_filed form-floating">
                      <input
                        type="text"
                        placeholder={t("City")}
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        name="city"
                        onBlur={formik.onBlur}
                      />
                      <label for="floatingInput">{t("City")}</label>
                      {formik.touched.city && formik.errors.city && (
                        <span className="text-danger errorText">
                          {formik.errors.city}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="my-2">
                    <div className="addinput_filed form-floating">
                      <input
                        type="text"
                        placeholder="State / Province"
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        name="state"
                        onBlur={formik.onBlur}
                      />
                      <label for="floatingInput">{t("State / Province")}</label>
                      {formik.touched.state && formik.errors.state && (
                        <span className="text-danger errorText">
                          {formik.errors.state}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="my-2">
                    <div className="addinput_filed form-floating">
                      <input
                        type="number"
                        placeholder={t("Postal Code")}
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        name="postalCode"
                        onBlur={formik.onBlur}
                      />
                      <label for="floatingInput">{t("Postal Code")}</label>
                      {formik.touched.postalCode &&
                        formik.errors.postalCode && (
                          <span className="text-danger errorText">
                            {formik.errors.postalCode}
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="my-2">
                    <div className="addinput_filed form-floating">
                      <Select
                        placeholder="Select country"
                        options={options}
                        value={country}
                        id="floatingInput"
                        onChange={(value) => setCountry(value)}
                        name="country"
                        class="form-control"
                        onBlur={formik.onBlur}
                      />
                      {/* <label for="floatingInput">Country</label> */}
                      {formik.touched.country && formik.errors.country && (
                        <span className="text-danger errorText">
                          {formik.errors.country}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="my-4">
                <h6 className="light-text mb-1 mx-2">{t("CARD DETAILS")}</h6>
              </div>

              <div className="my-3">
                <Row>
                  <Col lg="6" md="6" sm="12" className="">
                    <div className="Login_input_warp inputLabelNew mt-3 credit-card position-relative">
                      <label
                        className="form-label m-0 position-absolute px-2 bg-white d-inline-block"
                        style={{
                          top: -7,
                          left: 2,
                          zIndex: 9,
                          opacity: 1,
                          color: "#353935",
                        }}
                        for="floatingInput"
                      >
                        {t("Email")}
                      </label>
                      <input
                        style={{ height: 43 }}
                        type="email"
                        placeholder=""
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        name="email"
                        onBlur={formik.onBlur}
                      />
                      {/* <label for="floatingInput">Email</label> */}
                      {formik.touched.email && formik.errors.email && (
                        <span className="text-danger errorText">
                          {formik.errors.email}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="">
                    <div className="Login_input_warp inputLabelNew mt-3 credit-card position-relative">
                      <label
                        className="form-label m-0 position-absolute px-2 bg-white d-inline-block"
                        style={{
                          top: -7,
                          left: 2,
                          zIndex: 9,
                          opacity: 1,
                          color: "#353935",
                        }}
                        for="floatingInput"
                      >
                        {t("Name")}
                      </label>
                      <input
                        style={{ height: 55 }}
                        type="text"
                        placeholder=""
                        class="form-control text-uppercase"
                        id="floatingInput"
                        value={formik.values.cardHolder}
                        onChange={formik.handleChange}
                        name="cardHolder"
                        onBlur={formik.onBlur}
                      />
                      {/* <label for="floatingInput">{t("Name on the card")}</label> */}
                      {formik.touched.cardHolder &&
                        formik.errors.cardHolder && (
                          <span className="text-danger errorText">
                            {formik.errors.cardHolder}
                          </span>
                        )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" sm="12" className="my-2">
                    {/* <div className="">
                      <label className="text-small">{t("Card Number")}</label>
                      <div className=" w-full form-control  mt-1 ">
                        <CardNumberElement
                          class="form-control cardElement"
                          options={options2}
                          onChange={(event, val) => {
                          }}
                        />
                      </div>
                    </div> */}
                    {/* <div className= form-floating"> */}
                    <div className="Login_input_warp inputLabelNew mt-3 credit-card position-relative">
                      <label
                        className="form-label m-0 position-absolute px-2 bg-white d-inline-block"
                        style={{
                          top: -7,
                          left: 2,
                          zIndex: 9,
                          opacity: 1,
                          color: "#353935",
                        }}
                        for="floatingInput"
                      >
                        {t("Card Number")}
                      </label>
                      <input
                        style={{ height: 55 }}
                        type="number"
                        placeholder="XXXX XXXX XXXX XXXX"
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.cardNumber}
                        name="cardNumber"
                        onBlur={formik.onBlur}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const maxLengthValue = sanitizedValue?.slice(0, 16);
                          formik.handleChange({
                            target: {
                              name: "cardNumber",
                              value: maxLengthValue,
                            },
                          });
                        }}
                      />
                      {/* <label for="floatingInput">{t("Card Number")}</label> */}
                      {formik.touched.cardNumber &&
                        formik.errors.cardNumber && (
                          <span className="text-danger errorText">
                            {formik.errors.cardNumber}
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col lg="6" md="6" sm="12" className="">
                    {/* <div className="">
                      <label className="text-small">{t("Expiration")}</label>
                      <div className=" w-full form-control  mt-1 ">
                        <CardExpiryElement
                          class="form-control cardElement"
                          options={options2}
                        />
                      </div>
                    </div> */}
                    {/* <div className="Login_input_warp form-floating"> */}
                    <div className="Login_input_warp inputLabelNew mt-3 credit-card position-relative">
                      <label
                        className="form-label m-0 position-absolute px-2 bg-white d-inline-block"
                        style={{
                          top: -7,
                          left: 2,
                          zIndex: 9,
                          opacity: 1,
                          color: "#353935",
                        }}
                        for="floatingInput"
                      >
                        {t("Card Expiry Month")}
                      </label>
                      <input
                        style={{ height: 55 }}
                        type="number"
                        placeholder="MM"
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.cardMonth}
                        name="cardMonth"
                        onBlur={formik.onBlur}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const maxLengthValue = sanitizedValue?.slice(0, 2);
                          formik.handleChange({
                            target: {
                              name: "cardMonth",
                              value: maxLengthValue,
                            },
                          });
                        }}
                      />
                      {/* <label for="floatingInput">
                        {t("Card Expire Month")}
                      </label> */}
                      {formik.touched.cardMonth && formik.errors.cardMonth && (
                        <span className="text-danger errorText">
                          {formik.errors.cardMonth}
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col lg="6" md="6" sm="12" className="">
                    {/* <div className="">
                      <label className="text-small">{t("CVC")}</label>
                      <div
                        className=" w-full  form-control  mt-1 "
                        style={{ padding: "9px !important" }}
                      >
                        <CardCvcElement
                          class="form-control cardElement"
                          options={options2}
                        />
                      </div>
                    </div> */}

                    <div className="Login_input_warp inputLabelNew mt-3 credit-card position-relative">
                      <label
                        className="form-label m-0 position-absolute px-2 bg-white d-inline-block"
                        style={{
                          top: -7,
                          left: 2,
                          zIndex: 9,
                          opacity: 1,
                          color: "#353935",
                        }}
                        for="floatingInput"
                      >
                        {t("Card Expiry Year")}
                      </label>
                      <input
                        style={{ height: 55 }}
                        type="number"
                        placeholder="YYYY"
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.cardYear}
                        name="cardYear"
                        onBlur={formik.onBlur}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const maxLengthValue = sanitizedValue?.slice(0, 4);
                          formik.handleChange({
                            target: {
                              name: "cardYear",
                              value: maxLengthValue,
                            },
                          });
                        }}
                      />
                      {/* <label for="floatingInput">{t("Card Expire Year")}</label> */}
                      {formik.touched.cardYear && formik.errors.cardYear && (
                        <span className="text-danger errorText">
                          {formik.errors.cardYear}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="my-2">
                    <div className="Login_input_warp  inputLabelNew mt-3 credit-card position-relative">
                      <label
                        className="form-label m-0 position-absolute px-2 bg-white d-inline-block"
                        style={{
                          top: -7,
                          left: 2,
                          zIndex: 9,
                          opacity: 1,
                          color: "#353935",
                        }}
                        for="floatingInput"
                      >
                        {t("Card Security Code (CVV)")}
                      </label>
                      <input
                        style={{ height: 55 }}
                        type="number"
                        placeholder="***"
                        class="form-control"
                        id="floatingInput"
                        value={formik.values.cardSecurityCode}
                        name="cardSecurityCode"
                        onBlur={formik.onBlur}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const maxLengthValue = sanitizedValue?.slice(0, 3);
                          formik.handleChange({
                            target: {
                              name: "cardSecurityCode",
                              value: maxLengthValue,
                            },
                          });
                        }}
                      />
                      {/* <label for="floatingInput">
                        {t("Card Security Code (CVV)")}
                      </label> */}
                      {formik.touched.cardSecurityCode &&
                        formik.errors.cardSecurityCode && (
                          <span className="text-danger errorText">
                            {formik.errors.cardSecurityCode}
                          </span>
                        )}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="my-2 mx-1 d-flex align-items">
                {/* <input
                  type="radio"
                  className="addCrdCheck"
                  checked={isAdult}
                  onClick={() => setIsAdult(!isAdult)}
                  style={{ width: "24px", height: "24px" }}
                />                 */}
                <input
                  type="checkbox"
                  className=""
                  checked={isAdult}
                  onClick={() => setIsAdult(!isAdult)}
                  style={{ width: "32px", height: "32px" }}
                />

                <h6 className="text-sm mx-3">
                  {t(
                    "Tick here to confirm that you are at least 18 years old and the age of majority in your place of residence"
                  )}
                </h6>
              </div>
              <div className="my-2 mx-1 d-flex align-items">
                <input
                  type="checkbox"
                  className=""
                  checked={agreed}
                  onClick={() => setAgreed(!agreed)}
                  style={{ width: "20px", height: "20px" }}
                />
                <h6 className="text-sm mx-3 mb-0">
                  {t("I agree to all ")}
                  <Link to="/terms"> Terms </Link> and{" "}
                  <Link to="/privacy"> Privacy Policy </Link>
                </h6>
              </div>
              <div className="mx-1 w-50">
                <Visscards />
              </div>
            </div>
          </Col>
        </div>

        <Modal.Footer className="border-top p-1 position-relative d-flex justify-content-end align-items-center">
          <Button onClick={props.onhide} className="close_text">
            {t("Close")}
          </Button>{" "}
          {loading ? (
            <Button className={`sendMsg tip_btn`}>
              <SmallLoader color={"text-light"} />
            </Button>
          ) : (
            <Button
              className={`tip_btn ${
                (!agreed ||
                  !isAdult ||
                  Object.keys(formik.errors).length !== 0) &&
                "btn-disable"
              } `}
              onClick={handleSubmit}
              disabled={
                !agreed || !isAdult || Object.keys(formik.errors).length !== 0
              }
            >
              {t("Submit")}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default Addpaymentmod;
