import { useSelector } from "react-redux";
import UserProfile from "./UserProfile";
import EntertainerProfile from "./EntertainerProfile";

const MyProfileComponent = () => {
  const { role } = useSelector((s) => s.setting);

  return (
    <>
      {role === "USER" && <UserProfile />}
      {role === "ARTIST" && <EntertainerProfile />}
    </>
  );
};

export default MyProfileComponent;
