import React from "react";
import EntertainerCompose from "../../common/EntertainerCreatePost/EntertainerCompose";

const EntertainerNewPost = () => {
  return (
    <div>
      <EntertainerCompose screen="new-post" />
    </div>
  );
};

export default EntertainerNewPost;
