import React from "react";
import MainLayout from "../../layout/MainLayout";
import UserDetailsComponent from "../../components/UserDetails";

const UserDetails = () => {
  return (
    <MainLayout>
      <UserDetailsComponent />
    </MainLayout>
  );
};

export default UserDetails;
