import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

function Example(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [nonEssential, setNonEssential] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    const essential = localStorage.getItem("cookie-essential");
    const nonessential = localStorage.getItem("cookie-nonessential");
    if (!essential && !nonessential) {
      setShow(true);
    }
  };

  useEffect(() => {
    handleShow();
  }, []);

  function onAcceptAll() {
    localStorage.setItem("cookie-essential", "yes");
    localStorage.setItem("cookie-nonessential", "yes");
    handleClose();
  }

  function onSave() {
    localStorage.setItem("cookie-essential", "yes");
    localStorage.setItem("cookie-nonessential", nonEssential ? "yes" : "no");
    handleClose();
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="top"
        backdrop={false}
        className="cokiee_modal"
      >
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body>
          {t(
            "We use cookies to give you the best experience and to ensure the safety of our users. The only non-essential cookies we use are for any personal referrals you make. We do not track you across other sites. You can see our"
          )}
          <Link to="/cookie-policy">{t(" Cookie Policy ")}</Link>
          {t("here, and our")} <Link to="/privacy">{t("Privacy Notice")}</Link>{" "}
          {t("here.")}
          {!customize ? (
            <div className="d-flex justify-content-end mt-2">
              <Button
                variant="primary"
                onClick={() => setCustomize(true)}
                className="me-2 cookie_btn"
              >
                {t("Customize")}
              </Button>
              <Button variant="primary" onClick={onAcceptAll} className="cookie_btn">
                {t("Accept All")}
              </Button>
            </div>
          ) : (
            <div className="mt-5 d-flex justify-content-end align-items-center">
              <Form.Check
                type={"checkbox"}
                id={`essential-cookie`}
                className="me-5"
              >
                <Form.Check.Input type={"checkbox"} checked />
                <Form.Check.Label>{t("Essential Cookies")}</Form.Check.Label>
              </Form.Check>
              <Form.Check
                type={"checkbox"}
                id={`non-essential-cookie`}
                className="me-5"
              >
                <Form.Check.Input
                  type={"checkbox"}
                  checked={nonEssential}
                  onChange={() => setNonEssential((prev) => !prev)}
                />
                <Form.Check.Label>
                  {t("Non-Essential Cookies (Referral Cookies)")}
                </Form.Check.Label>
              </Form.Check>
              <Button variant="primary" onClick={onSave}>
                {t("Save Selected")}
              </Button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Example;
