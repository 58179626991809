import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const MessagePrice = (props) => {
  const { t } = useTranslation();
  const { messagePrice, setMessagePrice, setMessageType } = props;

  const close = () => {
    props.onHide();
  };

  const handlePrice = (e) => {
    const val = e.target.value;
    setMessagePrice(val);
    if (val > 0) {
      setMessageType("paid");
    } else {
      setMessageType("free");
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pin_modal"
    >
      <Modal.Body>
        <div className="modal_header d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <h6>{t("MESSAGE PRICE")} </h6>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 3v2H3V3h9Zm4 16v2H3v-2h13Zm6-8v2H3v-2h19Z"
              />
            </svg>
          </span>
        </div>

        <div className="middle_content px-3 py-2">
          <div>
            <FormGroup>
              <label className="text-small my-2">
                {t("Enter Message Price")}
              </label>
              <Input
                type="number"
                name="radio1"
                className="text-small mt-1"
                placeholder="Enter Price in $"
                value={messagePrice}
                onChange={handlePrice}
                onFocus={() => {
                  if (messagePrice === 0 || messagePrice === "0") {
                    setMessagePrice("");
                  }
                }}
              />{" "}
            </FormGroup>

            <div className="d-flex justify-content-center align-items-center">
              <button className="updateNotificationBtn" onClick={close}>
                {t("Submit")}
              </button>
            </div>
          </div>
        </div>

        <div className="modal_footer d-flex align-items-center justify-content-end px-3 py-2 border-top">
          <Button onClick={close}>{t("Close")}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MessagePrice;
