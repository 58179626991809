import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SmallLoader from "../ButtonLoader/SmallLoader";

const ConfirmDisconnectStripe = (props) => {

  const { loading, onHide, confirm } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {buttonLoader} = useSelector((s) => s.card)

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <div className="modal_buy-cont">
            <h5 className="fw-sbold text-1xl m-0">{t("Disconnnect Stripe")}</h5>
            <hr class="custom_hr"></hr>
            <div className="pt-2">
              <p className="fw-sbold text-small">
                {t("Are you sure you want to disconnect your stripe ?")} 
              </p>
              <div className="modal_buy_btn d-flex justify-content-center mt-4">
                <Button
                  className="mod_cancelbtn blackBtn border-0 text-small"
                  style={{ marginRight: "16px" }}
                  onClick={() => onHide()}
                >
                  {t("Cancel")}
                </Button>
                {buttonLoader ? (
                  <Button
                    className="mod_confirmbtn blackBtn border-0 text-small"
                    style={{ minWidth: "100px" }}
                  >
                    <SmallLoader color={"text-ligth"} />
                  </Button>
                ) : (
                  <Button
                    className="mod_confirmbtn blackBtn border-0"
                    onClick={confirm}
                  >
                    {t("Confirm")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDisconnectStripe;
