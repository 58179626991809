// This is for the confirmation of deleting the list ;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import Addpaymentmod from "../../../components/common/Modal/Addpaymentmod";

// const stripePromise = loadStripe(
//   "pk_test_51O2gNNLI2EI2TtmrOCtzqsiaFTbzEA6Pn3JlN1vhFWTm9mQU6zIWgjuxXbWTFBHOMf0fGN5EsBcMysihhxwztlcm00nl8e4dlh"
// );

const AddCardNotifier = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [addpaymentmod, setaddpaymentmod] = useState(false);

  if (addpaymentmod) {
    return (
      // <Elements stripe={stripePromise}>
      <Addpaymentmod
        show={addpaymentmod}
        onhide={() => setaddpaymentmod(false)}
        onSuccess={() => {
          props.hide();
        }}
        hideBack
      />
      /* </Elements> */
    );
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <div className="modal_buy-cont">
            <h4 className="fw-sbold m-0">{t("Add Card")}</h4>
            <hr class="custom_hr"></hr>
            <div className="pt-2">
              <p className="fw-sbold ">
                {t("You have not added card , Please add")}
              </p>
              <div className="modal_buy_btn d-flex justify-content-end">
                <Button
                  className="mod_confirmbtn blackBtn border-0"
                  // onClick={() => navigate("/add-card")}
                  onClick={() => setaddpaymentmod(true)}
                >
                  {t("Ok")}
                </Button>{" "}
                <Button
                  className="mod_confirmbtn blackBtn border-0 ms-2"
                  // onClick={() => navigate("/add-card")}
                  onClick={() => navigate("/", { replace: true })}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCardNotifier;
