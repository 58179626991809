import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PinToHomePop = ({ pinPop, setPinPop }) => {
  const handlePinPop = () => {
    setPinPop(!pinPop);
  };
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={pinPop}
        className="pinToHome"
        onHide={handlePinPop}
        backdrop="static"
        keyboard={false}
        centered
        scrollable="true"
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>{t("Pin To Home")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled ps-0 mb-0 noLastBorder radioSelect">
            <li className="py-2 position-relative">
              <input
                type="radio"
                name="pin"
                className="position-absolute  w-100 h-100"
              />
              <div className="cardCstm">
                <h6 className="m-0 fw-bold">{t("Friends")}</h6>
                <p className="text-muted m-0">{t("empty")}</p>
              </div>
            </li>
            <li className="py-2 position-relative">
              <input
                type="radio"
                name="pin"
                className="position-absolute  w-100 h-100"
              />
              <div className="cardCstm">
                <h6 className="m-0 fw-bold">{t("Tagged")}</h6>
                <p className="text-muted m-0">{t("empty")}</p>
              </div>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button
            variant="transparent"
            className="border-0 p-0 fw-bold"
            onClick={handlePinPop}
          >
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PinToHomePop;
